import React, { memo, useCallback, useEffect } from "react";
import classes from "./MobilePastView.module.scss";
import VideoCell from "@components/VideosTable/components/VideoCell";
import FlagCountry from "@components/FlagCountry";
import { ReactComponent as Play } from "@assets/icons/play-line.svg";
import { ReactComponent as Pause } from "@assets/icons/pause-line.svg";
import { withTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Checkbox from "@components/Checkbox";
import { countriesSelector } from "@redux/language/selectors";
import { getCountriesAction } from "@redux/language/actions";
import Dropdown from "@components/Dropdown";
import { Tooltip } from "@mui/material";
import DeleteModal from "@components/DeleteModal";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { lotStatuses, sortOptions } from "@pages/Dashboard/constants";
import moment from "moment/moment";

const MobilePastView = ({
  lots,
  t,
  onSelect,
  selected,
  onGoToTheStatistic,
  isVisibleGoBtn,
}) => {
  const dispatch = useDispatch();

  const data = React.useMemo(() => lots, [lots]);

  const countries = useSelector(countriesSelector, shallowEqual);

  const handleSelect = useCallback(
    (id, isSelected) => {
      let list;
      if (!isSelected) {
        list = [...selected].filter(v => v !== id);
      } else {
        list = [...selected, id];
      }
      if (onSelect) {
        onSelect(list);
      }
    },
    [onSelect, selected],
  );

  useEffect(() => {
    if (!countries.length) {
      dispatch(getCountriesAction());
    }
  }, []);

  return (
    <div className={classes.container}>
      {data.length > 0 ? (
        data.map(lot => (
          <div
            key={lot.id}
            onClick={e => {
              handleSelect(lot.id, !selected.find(id => id === lot.id));
            }}
            className={classes.videoContainer}
          >
            <div className={classes.firstRow}>
              <Checkbox
                className={"auction-select-checkbox"}
                role="checkbox"
                value={!!selected.find(id => id === lot.id)}
                onChange={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSelect(lot.id, e.target.checked);
                }}
              />
              <div>{lot.video.title}</div>
              <div>
                <span style={{ color: "grey" }}>ID:</span>
                <span style={{ fontWeight: "600" }}>{lot.id}</span>
              </div>
            </div>
            <VideoCell isMobile value={lot.video.filepath_480_p} />

            <div className={classes.description}>{lot.video.description}</div>
            <div className={classes.country}>
              <FlagCountry
                className={classes.flag}
                code={
                  countries.find(c => c.name === lot.auction.country)?.iso_2
                }
              />
              <span>{t(lot.auction.country)}</span>
            </div>
            <div className={classes.propertyWithDesc}>
              <span style={{ fontWeight: "600" }}>{t("Place")}: №</span>
              <span>{lot.playlist_number}</span>
            </div>
            <div className={classes.propertyWithDesc}>
              <span style={{ fontWeight: "600" }}>
                {" "}
                {t("Expected views")}:{" "}
              </span>
              <span>
                {lot.expect_views < 1000
                  ? lot.expect_views
                  : `${lot.expect_views / 1000}K`}
              </span>
            </div>
            <div className={classes.propertyWithDesc}>
              <span style={{ fontWeight: "600" }}> {t("Views")}: </span>
              <span>
                {lot.views < 1000 ? lot.views : `${lot.views / 1000}K`}
              </span>
            </div>
            <div className={classes.propertyWithDesc}>
              <span style={{ fontWeight: "600" }}>
                {" "}
                {t("Remaining views")}:{" "}
              </span>
              <span>
                {lot.reserve_views < 1000
                  ? lot.reserve_views
                  : `${lot.reserve_views / 1000}K`}
              </span>
            </div>
            <div className={classes.date}>
              {moment(lot.updated_at).format("DD.MM.YYYY")}
            </div>
          </div>
        ))
      ) : (
        <div
          style={{ height: "200px", justifyContent: "center" }}
          className={classes.videoContainer}
        >
          {t("There are no lots")}
        </div>
      )}
      <div
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          onGoToTheStatistic();
        }}
        style={{ visibility: isVisibleGoBtn ? "visible" : "hidden" }}
        className={classes.goToStatContainer}
      >
        <KeyboardArrowDownIcon className={classes.arrow} />
      </div>
    </div>
  );
};

export default withTranslation()(memo(MobilePastView));
