import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {getCountriesAction} from "src/redux/language/actions";


const useCountries = () => {
    const dispatch = useDispatch();
    const {countries} = useSelector((state) => state.language);

    useEffect(() => {



        (async () => {
            if(!countries.length) {
                await dispatch(getCountriesAction());
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return countries;
};


export {useCountries};