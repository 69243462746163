import React, { memo, useCallback } from "react";
import Select, { components } from "react-select";

import FlagCountry from "@components/FlagCountry";
import { Typography } from "../../../../views/blocks";

import "../index.scss";

const { Option, SingleValue } = components;

const CustomSingleValue = memo(({ data: { value }, ...props }) => {
  return (
    <SingleValue {...props}>
      <FlagCountry code={value} />
    </SingleValue>
  );
});

const CustomOption = memo(({ value, label, ...props }) => {
  return (
    <Option {...props}>
      <FlagCountry code={value} />
      <Typography variant='body2'>{label}</Typography>
    </Option>
  );
});

const PhoneSelect = ({ onChange, value, options, ...rest }) => {
  const handleChange = useCallback(
    option => {
      if (onChange) {
        onChange(option);
      }
    },
    [onChange],
  );

  return (
    <Select
      value={value}
      components={{
        DropdownIndicator: null,
        IndicatorSeparator: null,
        Option: CustomOption,
        SingleValue: CustomSingleValue,
      }}
      isClearable={false}
      isSearchable={false}
      classNamePrefix="select"
      className="react-select phone-select"
      options={options}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default memo(PhoneSelect);
