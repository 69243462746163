import Modal from "@components/Modal";
import { termOfUse, privacy, offer } from "data/privacy";
import { useMemo, useState } from "react";
import classes from "./styled.module.scss";
import Button from "@components/Button";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const ModalDocuments = ({ show, setShow, isSupport = false }) => {
  const [seePrivacy, setSeePrivacy] = useState(1);

  const { i18n, t } = useTranslation();

  const translates = useMemo(
    () => ({
      de: i18n.getFixedT("de"),
      en: i18n.getFixedT("en"),
      uk: i18n.getFixedT("uk"),
    }),
    [i18n],
  );

  const [language, setLanguage] = useState(
    translates[i18n.language] ? i18n.language : "en",
  );

  const onChangeLanguage = value => {
    setLanguage(value);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      headerText={t("Terms of Use and Privacy")}
    >
      <div className={classes.modal_language}>
        {t("Document language: ")}
        <span
          onClick={() => onChangeLanguage("uk")}
          className={language === "uk" ? `${classes.active_language}` : ""}
        >
          UKR
        </span>
        /
        <span
          onClick={() => onChangeLanguage("en")}
          className={language === "en" ? `${classes.active_language}` : ""}
        >
          ENG
        </span>
        /
        <span
          onClick={() => onChangeLanguage("de")}
          className={language === "de" ? `${classes.active_language}` : ""}
        >
          GER
        </span>
      </div>
      <div
        className="d-flex"
        style={{
          flexWrap: "wrap",
          width: "100%",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "calc(100% / 3)",
            flex: "1 1 auto",
            margin: "5px",
          }}
        >
          <Button
            onClick={() => setSeePrivacy(1)}
            variant={seePrivacy === 1 ? "white" : "primary"}
            className={clsx(classes.btnSize, {
              [classes.activeTab]: !seePrivacy,
            })}
          >
            {t("Terms of Use")}
          </Button>
        </div>
        {isSupport && (
          <div
            style={{
              width: "calc(100% / 3)",
              flex: "1 1 auto",
              margin: "5px",
            }}
          >
            <Button
              onClick={() => setSeePrivacy(3)}
              variant={seePrivacy === 3 ? "white" : "primary"}
              id="buttonSee_2"
              className={clsx(classes.btnSize, {
                [classes.activeTab]: !seePrivacy,
              })}
            >
              {t("Public Agreement")}
            </Button>
          </div>
        )}
        <div
          style={{
            width: "calc(100% / 3)",
            flex: "1 1 auto",
            margin: "5px",
          }}
        >
          <Button
            onClick={() => setSeePrivacy(2)}
            variant={seePrivacy === 2 ? "white" : "primary"}
            className={clsx(classes.btnSize, {
              [classes.activeTab]: !seePrivacy,
            })}
          >
            {t("Privacy Policy")}
          </Button>
        </div>
      </div>
      {seePrivacy === 1 && (
        <div
          className={classes.privacyText}
          dangerouslySetInnerHTML={{
            __html: termOfUse[language] ? termOfUse[language] : termOfUse["en"],
          }}
        />
      )}
      {seePrivacy === 2 && (
        <div
          className={classes.privacyText}
          dangerouslySetInnerHTML={{
            __html: privacy[language] ? privacy[language] : privacy["en"],
          }}
        />
      )}
      {seePrivacy === 3 && (
        <div
          className={classes.privacyText}
          dangerouslySetInnerHTML={{
            __html: offer[language] ? offer[language] : offer["en"],
          }}
        />
      )}
      <Button
        onClick={() => setShow(false)}
        containerClassName={classes.privacyBtn}
      >
        {t("Close")}
      </Button>
    </Modal>
  );
};

export default ModalDocuments;
