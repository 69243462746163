import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RenderField, VideoField } from "../../../../views/fields";
import DataTable from "../../../../views/blocks/DataTable";
import { useDispatch, useSelector } from "react-redux";
import {
  playlistLotStatisticSelector,
  playlistStatisticSelector,
} from "@redux/dashboard/selectors";
import {
  getLotStatisticInPlaylistAction,
  getPlaylistStatisticAction,
} from "@redux/dashboard/dashboardActions";
import moment from "moment";
import PieStatistic from "@pages/Dashboard/components/PieStatistic";
import classes from "./playlistStatistic.module.scss";
import { useTranslation } from "react-i18next";
import StatItem from "@pages/Dashboard/components/PastStatistic/components/StatItem";
import { ReactComponent as Bet } from "@assets/icons/auction-bet.svg";
import { ReactComponent as Like } from "@assets/icons/like.svg";
import { ReactComponent as Alert } from "@assets/icons/alert-line.svg";
import { ReactComponent as Interaction } from "@assets/icons/interactions.svg";
import { ReactComponent as Ctr } from "@assets/icons/ctr.svg";
import Loader from "@components/Loader";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import { ReactComponent as Play } from "src/assets/icons/play-line.svg";
import { ReactComponent as Pause } from "src/assets/icons/pause-line.svg";
import { deleteLot } from "@services/api/auctions";
import { toast } from "react-toastify";
import DeleteModal from "@components/DeleteModal";
import clsx from "clsx";
import Button from "@components/Button";
import Modal from "@components/Modal";
import { Tooltip } from "@mui/material";
import { setLotStatusAction } from "@redux/auction/actions";
import { createAgeData, createTimeData } from "@utils/statisticData";
import MobileDashboardView from "@pages/Dashboard/components/PlaylistStatistic/components/MobilePlaylistView";
import Dropdown from "@components/Dropdown";
import { lotStatuses, sortOptions } from "@pages/Dashboard/constants";

const PlaylistStatistic = () => {
  const dispatch = useDispatch();

  const [statisticLoading, setStatisticLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const [lotToPause, setLotOnPause] = useState(false);

  const [selectedLot, setSelectedLot] = useState([]);
  const [isVisibleGoBtn, setVisibleGoBtn] = useState(true);

  const [limit, setLimit] = useState(30);

  const { data, count } = useSelector(playlistStatisticSelector);

  const lot = useSelector(playlistLotStatisticSelector);

  const ref = useRef();

  const ageData = useMemo(
    () => createAgeData(lot.age_audience),
    [lot.age_audience],
  );
  const timeData = useMemo(
    () => createTimeData(lot.views_by_time),
    [lot.views_by_time],
  );

  const { t } = useTranslation();

  useEffect(() => {
    setPageLoading(true);
    dispatch(getPlaylistStatisticAction({ limit, offset: 0 }))
      .then(lots => {
        if (lots.length > 0) {
          setSelectedLot([lots[0].id]);
          dispatch(getLotStatisticInPlaylistAction([lots[0].id]))
            .then(() => {
              setPageLoading(false);
            })
            .catch(() => {
              setPageLoading(false);
            });
        }
        setPageLoading(false);
      })
      .catch(() => {
        setPageLoading(false);
      }); //eslint-disable-next-line
  }, []);

  const onStatusChange = useCallback(
    async (id, status) => {
      setStatisticLoading(true);
      try {
        await dispatch(setLotStatusAction({ id, status }));

        await dispatch(getPlaylistStatisticAction({ limit, offset: 0 }));

        setStatisticLoading(false);
      } catch (e) {
        setStatisticLoading(false);
      }
    }, //eslint-disable-next-line
    [],
  );

  const onDeleteLot = useCallback(async id => {
    setStatisticLoading(true);
    try {
      await deleteLot(id);
      await dispatch(getPlaylistStatisticAction({ limit, offset: 0 }));
    } catch (e) {
      toast.error(t("Error of deleting lot"));
    } finally {
      setStatisticLoading(false);
    } //eslint-disable-next-line
  }, []);

  const onLotSelect = useCallback(id => {
    setSelectedLot(id);
    setStatisticLoading(true);
    dispatch(getLotStatisticInPlaylistAction(id))
      .then(() => {
        setStatisticLoading(false);
      })
      .catch(() => {
        setStatisticLoading(false);
      }); //eslint-disable-next-line
  }, []);

  const onSortChange = useCallback(
    limit => {
      setLimit(limit);
      setPageLoading(true);
      dispatch(
        getPlaylistStatisticAction({
          limit: limit === "full" ? count : limit,
          offset: 0,
        }),
      ).then(() => {
        setPageLoading(false);
      });
    },
    [count],
  );
  const onGoToTheStatistic = useCallback(() => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
    };
    let observer = new IntersectionObserver(([entry]) => {
      setVisibleGoBtn(!entry.isIntersecting);
    }, options);
    if (ref.current) observer.observe(ref.current);

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, [ref.current]);

  return pageLoading ? (
    <Loader width={150} height={150} isPageLoader />
  ) : (
    <div>
      <div className={classes.mobile}>
        <MobileDashboardView
          onDelete={onDeleteLot}
          onStatusChange={onStatusChange}
          selected={selectedLot}
          lots={data}
          onSelect={onLotSelect}
          limit={limit}
          onSortChange={onSortChange}
          setLotOnPause={setLotOnPause}
          onGoToTheStatistic={onGoToTheStatistic}
          isVisibleGoBtn={isVisibleGoBtn}
        />
      </div>
      <div className={classes.desktop}>
        <div className={classes.sortContainer}>
          <Dropdown
            value={limit}
            onSelect={onSortChange}
            options={sortOptions}
          />
        </div>
        <DataTable
          selectable
          bulkSelect
          selected={selectedLot}
          onSelect={onLotSelect}
          items={data}
        >
          <RenderField
            render={(record, id = "") => {
              return (
                <div className="d-flex firstRow">
                  <div>{id}</div>
                </div>
              );
            }}
            align="center"
            label="Id"
            name="id"
          />
          <RenderField
            render={(record, playlistNumber = "") => {
              return <div>{playlistNumber}</div>;
            }}
            align="center"
            label="Place"
            name="playlist_number"
          />
          <RenderField
            render={record => {
              return record.video.title;
            }}
            align="center"
            label="Video title"
            name="video.title"
          />
          <VideoField
            isAuction
            align="center"
            label="Preview"
            titleName="Video"
            name="video.filepath_480_p"
          />
          <RenderField
            render={record => {
              let color;
              switch (record.status) {
                case "active":
                  color = "#10be83";
                  break;
                case "paused":
                  color = "#1d1d1f";
                  break;
                case "deleted":
                  color = "#EE1717";
                  break;
                default:
                  color = "black";
                  break;
              }
              return (
                <div
                  style={{
                    fontWeight: 600,
                    color,
                  }}
                >
                  {t(lotStatuses[record.status])}
                </div>
              );
            }}
            align="center"
            label="Status"
            name="status"
          />
          <RenderField
            render={record => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "23px 13px",
                  }}
                >
                  <Tooltip title={t("Play")}>
                    <Play
                      className={clsx(
                        classes.managementIcon,
                        record.status === "active" && classes.activeIcon,
                      )}
                      onClick={e => {
                        e.stopPropagation();
                        record.status !== "active" &&
                          onStatusChange(record.id, "active");
                      }}
                    />
                  </Tooltip>
                  <Tooltip title={t("Pause")}>
                    <Pause
                      onClick={e => {
                        e.stopPropagation();
                        record.status !== "paused" && setLotOnPause(record.id);
                      }}
                      className={clsx(
                        classes.managementIcon,
                        record.status === "paused" && classes.activeIcon,
                      )}
                    />
                  </Tooltip>
                  <Tooltip title={t("Delete")}>
                    <div>
                      <DeleteModal
                        onSuccessDelete={() => onDeleteLot(record.id)}
                        trigger={
                          <CropSquareIcon
                            className={clsx(
                              classes.deleteIcon,
                              record.status === "deleted" && classes.activeIcon,
                            )}
                          />
                        }
                        question={t("Are you sure you want to delete lot?")}
                      />
                    </div>
                  </Tooltip>
                </div>
              );
            }}
            align="center"
            label="Management"
            name="status"
          />
          <RenderField
            render={record => {
              return record.auction.country;
            }}
            align="center"
            label="Country"
            name="auction.country"
          />
          <RenderField
            render={record => {
              return moment(record.updated_at).format("DD.MM.YYYY");
            }}
            align="center"
            label="Date of change"
            name="updated_at"
          />
          <RenderField
            isDashed
            render={record => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "23px 13px",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Tooltip title={t("Amount")}>
                    <div className={classes.budgetItem}>
                      {parseFloat(record.price_per_view).toFixed(3)} IC
                    </div>
                  </Tooltip>
                  <Tooltip title={t("Budget")}>
                    <div className={classes.budgetItem}>
                      {parseFloat(record.budget)
                        .toString()
                        .match(/\.(\d+)/)?.[1].length > 3
                        ? record.budget
                        : `${parseFloat(record.budget).toFixed(3)} IC`}
                    </div>
                  </Tooltip>
                  <Tooltip title={t("Remaining budget")}>
                    <div className={classes.budgetItem}>
                      {parseFloat(record.current_budget)
                        .toString()
                        .match(/\.(\d+)/)?.[1].length > 3
                        ? record.current_budget
                        : `${parseFloat(record.current_budget).toFixed(3)} IC`}
                    </div>
                  </Tooltip>
                  <Tooltip title={t("Paid out")}>
                    <div className={classes.budgetItem}>
                      {parseFloat(record.budget) -
                        parseFloat(record.current_budget) !==
                      0
                        ? (
                            parseFloat(record.budget) -
                            parseFloat(record.current_budget)
                          ).toFixed(3)
                        : 0}
                    </div>
                  </Tooltip>
                </div>
              );
            }}
            align="center"
            label="Finance"
            name="views_in_prev_auction"
          />
          <RenderField
            isDashed
            render={record => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "23px 13px",
                  }}
                >
                  <Tooltip title={t("Expected views")}>
                    <div>
                      {record.expect_views < 1000
                        ? record.expect_views
                        : `${record.expect_views / 1000}K`}
                    </div>
                  </Tooltip>
                  <Tooltip title={t("Views")}>
                    <div style={{ marginLeft: "27px" }}>
                      {record.views < 1000
                        ? record.views
                        : `${record.views / 1000}K`}
                    </div>
                  </Tooltip>
                  <Tooltip title={t("Remaining views")}>
                    <div style={{ marginLeft: "27px" }}>
                      {record.reserve_views < 1000
                        ? record.reserve_views
                        : `${record.reserve_views / 1000}K`}
                    </div>
                  </Tooltip>
                </div>
              );
            }}
            align="center"
            label="Displays"
            name="views_in_prev_auction"
          />
        </DataTable>
      </div>
      <Modal
        size="small"
        show={lotToPause}
        headerClassName={classes.pauseHeader}
        headerText={t("Are you sure you want to stop the lot?")}
        onHide={() => {
          setLotOnPause(null);
        }}
      >
        <div className={classes.buttonContainer}>
          <Button
            variant="white"
            onClick={() => setLotOnPause(null)}
            className={classes.cancel}
            containerClassName={classes.button}
          >
            {t("No")}
          </Button>
          <Button
            containerClassName={classes.deleteBtn}
            onClick={() => {
              setLotOnPause(null);
              onStatusChange(lotToPause, "paused");
            }}
          >
            {t("Yes")}
          </Button>
        </div>
      </Modal>
      {statisticLoading ? (
        <Loader className={classes.loader} />
      ) : (
        <div ref={ref}>
          <div className={classes.statContainer}>
            <div className={classes.statItem}>
              <StatItem
                statistic={lot.max_auction_amount}
                label={t("First place bet")}
                Icon={Bet}
              />
            </div>
            <div className={classes.statItem}>
              <StatItem
                statistic={lot.likes_count}
                label={t("Like")}
                Icon={Like}
              />
            </div>
            <div className={classes.statItem}>
              <StatItem
                statistic={
                  lot.interactions_email_count + lot.interactions_url_count
                }
                label={t("Interaction")}
                Icon={Interaction}
              />
            </div>
            <div className={classes.statItem}>
              <StatItem
                statistic={lot.intolerable_count}
                label={t("Complaint")}
                Icon={Alert}
              />
            </div>
            <div className={classes.statItem}>
              <StatItem
                statistic={lot.click_through_rate}
                label={t("CTR")}
                Icon={Ctr}
              />
            </div>
          </div>
          <div className={classes.piesContainer}>
            {lot.audience && (
              <PieStatistic
                label={t("Gender")}
                data={[
                  { label: t("Men"), value: Math.round(lot.audience.male) },
                  { label: t("Women"), value: Math.round(lot.audience.female) },
                  {
                    label: t("Not binary"),
                    value: Math.round(lot.audience.not_binary || 0),
                  },
                ]}
              />
            )}
            {lot.age_audience && (
              <PieStatistic label={t("Age")} data={ageData} />
            )}
            {lot.views_by_time && (
              <PieStatistic label={t("Watch time")} data={timeData} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(PlaylistStatistic);
