export const notificationsSelector = ({ notifications: { items } }) => items;

export const playlistStatisticSelector = ({
  dashboard: { playlist, playlistCount },
}) => ({ data: playlist, count: playlistCount });

export const playlistLotStatisticSelector = ({
  dashboard: { playlistSelectedLot },
}) => playlistSelectedLot;

export const pastStatisticSelector = ({ dashboard: { past, pastCount } }) => ({
  data: past,
  count: pastCount,
});

export const pastLotStatisticSelector = ({ dashboard: { pastSelectedLot } }) =>
  pastSelectedLot;
