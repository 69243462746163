import React, { memo, Fragment, useCallback } from "react";
import { Typography } from "../../../../views/blocks";

import s from "./index.module.scss";

const UploadAvatar = ({ label, onChange, ...rest }) => {
  const handleChange = useCallback(event => {
    const file = event.target.files[0];

    const extensions = file.name.split(".")[file.name.split(".").length - 1];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (onChange) {
          onChange(reader.result, extensions);
        }
      };
      reader.readAsDataURL(file);
    }
  }, []);

  return (
    <Fragment>
      <input
        {...rest}
        type="file"
        className={s.field}
        id="avatar-upload"
        accept="image/*"
        onChange={handleChange}
      />
      <label htmlFor="avatar-upload">
        <Typography
          color="primary"
          variant="body2"
          weight="semi-bold"
          className="hover-opacity pointer"
        >
          {label}
        </Typography>
      </label>
    </Fragment>
  );
};

export default memo(UploadAvatar);
