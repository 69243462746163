import * as Yup from "yup";
import { getCurrentAge } from "@utils/getCurrentAge";

import { isValidPhoneNumber } from "react-phone-number-input/min";

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Field is required"),
  name: Yup.string().required("Field is required"),
  country: Yup.string().required("Field is required"),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&_-])[A-Za-z\d@$#!%*?&_-]{8,}$/,
      {
        message:
          "The password must consist of: at least 8 characters, a lowercase character, an uppercase character, a number and a special character (!,@,#,$,%,*,&,?)",
      },
    )
    .required("Field is required"),
  confirmPassword: Yup.string()
    .required("Field is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  acceptPrivacy: Yup.bool()
    .oneOf([true], "Accept Terms & Conditions is required")
    .required("Field is required"),
  acceptAgreement: Yup.bool()
    .oneOf([true], "Accept Privacy Agreement is required")
    .required("Field is required"),
  date_of_birth: Yup.string()
    .test(
      "dob",
      "Your age is under 16, which does not meet the norm for using the INFOCOIN platform.",
      value => getCurrentAge(value) > 16,
    )
    .required("Field is required"),
});

export const InviteSchema = () =>
  Yup.object().shape({
    code: Yup.string()
      .length(4, "Not valid code")
      .required("Field is required"),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&_-])[A-Za-z\d@$#!%*?&_-]{8,}$/,
        {
          message:
            "The password must consist of: at least 8 characters, a lowercase character, an uppercase character, a number and a special character (!,@,#,$,%,*,&,?)",
        },
      )
      .required("Field is required"),
    confirmPassword: Yup.string()
      .required("Field is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

export const LoginSchema = () =>
  Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Field is required"),
    password: Yup.string().required("Field is required"),
    acceptPrivacy: Yup.bool()
      .oneOf([true], "Accept Terms & Conditions is required")
      .required("Field is required"),
    acceptAgreement: Yup.bool()
      .oneOf([true], "Accept Privacy Agreement is required")
      .required("Field is required"),
  });

export const ActivateAccountSchema = () =>
  Yup.object().shape({
    code: Yup.string()
      .length(4, "Not valid code")
      .required("Field is required"),
  });

export const resetPasswordSchema = step => {
  return step === 0
    ? Yup.object().shape({
        email: Yup.string()
          .email("invalid email")
          .required("Field is required"),
      })
    : step === 1
    ? Yup.object().shape({
        code: Yup.string()
          .length(4, "Not valid code")
          .required("Field is required"),
      })
    : Yup.object().shape({
        password: Yup.string()
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&_-])[A-Za-z\d@$#!%*?&_-]{8,}$/,
            {
              message:
                "The password must consist of: at least 8 characters, a lowercase character, an uppercase character, a number and a special character (!,@,#,$,%,*,&,?)",
            },
          )
          .required("Field is required"),
        confirmPassword: Yup.string()
          .required("Field is required")
          .oneOf([Yup.ref("password"), null], "Passwords must match"),
      });
};

export const SupportSchema = () =>
  Yup.object().shape({
    topic: Yup.string()
      .min(1, "min", { num: 1 })
      .max(16, "max", { num: 16 })
      .required("Field is required"),
    description: Yup.string()
      .min(1, "min", { num: 1 })
      .max(16, "max", { num: 16 })
      .required("Field is required"),
  });

export default SignupSchema;

export const FirstStepSchema = () =>
  Yup.object().shape({
    title: Yup.string().required("Field is required"),
    description: Yup.string().max(172, "The maximum length is 172 characters"),
    category_id: Yup.string().required("Select Category"),
    goal: Yup.string().required("Field is required"),
    url: Yup.string().when("goal", {
      is: "link",
      then: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Invalid URL",
        )
        .required("Field is required"),
    }),
    email: Yup.string()
      .email("Invalid email")
      .when("goal", {
        is: "email",
        then: Yup.string().email("Invalid email").required("Field is required"),
      }),
  });
export const LoadVideoFromTemplateSchema = Yup.object().shape({
  company_id: Yup.string().required("Select Company"),
});
export const UpdateSchema = () =>
  Yup.object().shape({
    description: Yup.string().max(172, "The maximum length is 172 characters"),
    interaction: Yup.string().required("The field cannot be empty"),
    email: Yup.string().required("The field cannot be empty"),
    link: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Please enter a valid url",
      )
      .required("The field cannot be empty"),
    age_min: Yup.string()
      .required("The field cannot be empty")
      .min(1, "Too short")
      .max(3, "Too long")
      .matches(/^[0-9\b]+$/, "Only numbers are allowed to be entered"),
    age_max: Yup.string()
      .required("The field cannot be empty")
      .min(1, "Too short")
      .max(3, "Too long")
      .matches(/^[0-9\b]+$/, "Only numbers are allowed to be entered"),
    /*        country: Yup.object().required("Нужно сделать выбор!"),
        region: Yup.object().required("Нужно сделать выбор!"),
        city: Yup.object().required("Нужно сделать выбор!"),*/
    language: Yup.string().required("Field is required"),
    gender: Yup.string().required("Field is required"),
  });

export const IndividualCompanySchema = Yup.object().shape({
  title: Yup.string().required("Field is required"),
  description: Yup.string().required("Field is required"),
  header_file: Yup.string().required("Field is required"),
  avatar: Yup.string().required("Field is required"),
  country: Yup.string().required("Field is required"),
});

export const CompanySchema = Yup.object().shape({
  title: Yup.string().required("Field is required"),
  country: Yup.string().required("Field is required"),
  description: Yup.string().required("Field is required"),
  itn_edrpou: Yup.string().when("is_legal_entity", {
    is: true,
    then: Yup.string().required("Field is required"),
  }),
  legal_address: Yup.string().when("is_legal_entity", {
    is: true,
    then: Yup.string().required("Field is required"),
  }),
  contact_person: Yup.string().when("is_legal_entity", {
    is: true,
    then: Yup.string().required("Field is required"),
  }),
  vat_number: Yup.string().when("is_legal_entity", {
    is: true,
    then: Yup.string().required("Field is required"),
  }),
});
export const legalCompanySchema = Yup.object().shape({
  title: Yup.string().required("Field is required"),
  header_file: Yup.string().required("Field is required"),
  avatar: Yup.string().required("Field is required"),
  description: Yup.string().required("Field is required"),
  itn_edrpou: Yup.string().required("Field is required"),
  legal_address: Yup.string().required("Field is required"),
  contact_person: Yup.string().required("Field is required"),
  country: Yup.string().required("Field is required"),
  vat_number: Yup.string().required("Field is required"),
});

export const updateLegalCompanySchema = Yup.object().shape({
  title: Yup.string().required("Field is required"),
  header_file: Yup.string().required("Field is required"),
  avatar: Yup.object().required("Field is required"),
  description: Yup.string().required("Field is required"),
  itn_edrpou: Yup.string().required("Field is required"),
  legal_address: Yup.string().required("Field is required"),
  contact_person: Yup.string().required("Field is required"),
});

export const UpdateIndividualCompanySchema = Yup.object().shape({
  title: Yup.string().required("Field is required"),
  description: Yup.string().required("Field is required"),
  header_filename: Yup.string().required("Field is required"),
  avatar: Yup.string().required("Field is required"),
});

export const PaymentSchema = () =>
  Yup.object().shape({
    amount: Yup.number()
      .min(0, "Field must be greater than 0")
      .required("The field cannot be empty"),
  });

export const CharityPaymentSchema = () =>
  Yup.object().shape({
    budget: Yup.number()
      .min(0, "Field must be greater than 0")
      .required("The field cannot be empty"),
  });

export const WalletRefillSchema = () =>
  Yup.object().shape({
    amount: Yup.string().matches(/^[0-9]{1,10}.?[0-9]{0,7}$/, {
      message: "Max length after decimal point 7 numbers",
    }),
    euro: Yup.string()
      .test("DOB", euro => {
        if (Number(euro) > 15 || Number(euro) === 15) {
          return true;
        } else {
          return false;
        }
      })
      .matches(/^[0-9]{1,10}.?[0-9]{0,7}$/, {
        message: "Max length after decimal point 7 numbers",
      })
      .required("The field cannot be empty"),
    company: Yup.string().required("The field cannot be empty"),
    payment: Yup.string().required("The field cannot be empty"),
    country: Yup.string().required("The field cannot be empty"),
  });

export const WalletRefillLegalSchema = () =>
  Yup.object().shape({
    amount: Yup.number()
      .min(0, "Field must be greater than 0")
      .required("The field cannot be empty"),
    company: Yup.string().required("The field cannot be empty"),
    egrpou: Yup.string().required("The field cannot be empty"),
    email: Yup.string()
      .email("invalid email")
      .required("The field cannot be empty"),
    iban: Yup.string().required("The field cannot be empty"),
  });

export const UpdatePasswordSchema = Yup.object().shape({
  password: Yup.string().required("Field is required"),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&_-])[A-Za-z\d@$#!%*?&_-]{8,}$/,
      {
        message:
          "The password must consist of: at least 8 characters, a lowercase character, an uppercase character, a number and a special character (!,@,#,$,%,*,&,?)",
      },
    )
    .required("Field is required"),
  confirmPassword: Yup.string()
    .required("Field is required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});
export const ProfileUserSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Field is required"),
    phone: Yup.string().test("DOB", "Incorrect phone", value => {
      return value ? isValidPhoneNumber(value) : true;
    }),
    date_of_birth: Yup.string().test(
      "dob",
      "Your age is under 16, which does not meet the norm for using the INFOCOIN platform.",
      value => getCurrentAge(value) > 16,
    ),
  });
