import React, { isValidElement, useCallback, Children } from "react";
import { TableHead, TableRow, TableCell, TableSortLabel } from "@mui/material";

import "./index.scss";
import { useTranslation } from "react-i18next";
import { onCloseAccardion } from "@pages/Content/components/auctionStatusHelper";
const DataTableHead = props => {
  const {
    children,
    selectable,
    contentComponent,
    orderBy,
    orderWay,
    onOrderByChange,
    onOrderWayChange,
    items,
  } = props;

  const { t } = useTranslation();

  const onCloseRows = () => {
    onCloseAccardion();
  };

  const handleClick = useCallback(
    order => {
      onCloseRows();
      if (order !== orderBy) {
        if (onOrderByChange) {
          onOrderByChange(order);
        }

        if (onOrderWayChange) {
          onOrderWayChange("asc");
        }
      } else {
        if (onOrderWayChange) {
          onOrderWayChange(orderWay === "asc" ? "desc" : "asc");
        }
      }
    },
    [orderBy, orderWay, onOrderByChange, onOrderWayChange],
  );

  return (
    <TableHead className="data-table-head">
      <TableRow className="data-table-head__row">
        {items.length > 0 && selectable && (
          <TableCell className="data-table-head__cell" />
        )}
        {items.length > 0 && contentComponent && (
          <TableCell className="data-table-head__cell" />
        )}

        {Children.map(children, child => {
          if (!isValidElement(child)) {
            return null;
          }

          const {
            props: { label, name, align },
          } = child;

          return (
            <TableCell
              key={name}
              className="data-table-head__cell"
              align={align}
              style={{ textAlign: align }}
            >
              <TableSortLabel
                active={orderBy === name}
                direction={orderWay}
                onClick={() => handleClick(name)}
              >
                {t(label)}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export { DataTableHead };
