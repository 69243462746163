import {
  DemoFirstStep,
  FirstStep,
} from "../../../../views/blocks/VideoStepForm/blocks";
import React, { useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  getVideosSpeechLanguagesAction,
  getVideosCategoriesAction,
  getVideosAction,
  getDemoVideosAction,
} from "@redux/content/contentActions";

import { useTranslation } from "react-i18next";

import { useShowMessage } from "src/hooks";

import "./index.scss";
import { updateDemoVideo, updateVideo } from "@services/api/content";
import { getDemoCompaniesAction } from "@redux/companies/actions";

const EditForm = ({ data, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showMessage = useShowMessage();
  const { categories, speech_languages } = useSelector(state => state.content);
  const { companies } = useSelector(state => state.companies);

  const role = useSelector(state => state.user.user.role);

  const { countries } = useSelector(state => state.language);

  useEffect(() => {
    if (role !== "demo_user") {
      if (!categories.length) {
        dispatch(getVideosCategoriesAction());
      }

      if (!speech_languages.length) {
        dispatch(getVideosSpeechLanguagesAction());
      }
    } else {
      if (!companies.length) {
        dispatch(getDemoCompaniesAction());
      }
    }
  }, []);

  const handleSubmit = useCallback(
    async data => {
      const {
        goal,
        link,
        email,
        video,
        categories,
        regions,
        is_all_country,
        id,
        cities,
        ...sendData
      } = {
        ...data,
      };

      try {
        const res = await updateVideo(
          {
            ...sendData,
            categories_ids: categories.map(category => {
              return category.value;
            }),
            genders_ids: sendData.genders,
            is_all_country,
            ...(regions ? { regions_ids: regions.map(r => r.value) } : {}),
            ...(cities ? { cities_ids: cities.map(c => c.value) } : {}),
            ...(goal === "link" ? { link } : {}),
            is_include_voice_speech: !!sendData.speech_language,
            ...(goal === "email" ? { email } : {}),
          },
          id,
        );

        if (res.status === 200) {
          handleClose();
          showMessage({
            status: "OK",
            message: t("Video updated successfully"),
          });
          await dispatch(getVideosAction());
        }
      } catch (err) {
        showMessage({
          status: "ERROR",
          message: err?.response?.data?.error || err.message,
        });
      }
    },
    [showMessage, t, dispatch, handleClose],
  );

  const handleDemoSubmit = useCallback(
    async ({ id, country_id, ...data }) => {
      try {
        const res = await updateDemoVideo(
          {
            ...data,
            country_id: Number.isInteger(country_id)
              ? country_id
              : countries.find(c => c.name === country_id).id,
          },
          id,
        );

        if (res.status === 200) {
          handleClose();
          showMessage({
            status: "OK",
            message: t("Video updated successfully"),
          });
          await dispatch(getDemoVideosAction());
        }
      } catch (err) {
        showMessage({
          status: "ERROR",
          message: err.message,
        });
      }
    },
    [showMessage, t, dispatch, handleClose, countries],
  );

  if (role !== "demo_user") {
    return (
      <FirstStep
        isEdit
        data={data}
        companies={companies}
        languages={speech_languages}
        categories={categories}
        onSubmit={handleSubmit}
      />
    );
  } else {
    return (
      <DemoFirstStep
        data={data}
        onSubmit={handleDemoSubmit}
        companies={companies}
        isEdit
      />
    );
  }
};

export { EditForm };
