import React, {useState, useEffect} from 'react'
import {Link} from "react-router-dom";
import moment from "moment";
import * as XLSX from 'xlsx'
import {Container} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ReactComponent as BackIcon} from "@assets/icons/Arrow 2.svg";
import Form from 'react-bootstrap/Form'
import {toast} from "react-toastify";
import {Checkbox} from "src/views/inputs";
import Button from "src/components/Button";
import Select from 'react-select'
import ModalPDFReport from '../ModalPDFReport/ModalPDFReport';

import {
    getTransactionsHistoryByTypeByPeriod,
    getTransactionsHistoryEveryDayByPeriod,
    getPaymentSystemTransaction,
    getUserInfo
} from '@services/api/wallet';

import "src/styles/components/Wallet.scss";

const WalletDetails = () => {

    const {t} = useTranslation();

    const [startDate, setStartDate] = useState(moment().set({date: 1}).format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
    const [viewStartDate, setViewStartDate] = useState(moment().set({date: 1}).format('YYYY-MM-DD'))
    const [viewEndDate, setViewEndDate] = useState(moment().format('YYYY-MM-DD'))

    const [prevBalance, setPrevBalance] = useState(0)
    // const [curBalance, setCurBalance] = useState(0)
    const [totalBalance, setTotalBalance] = useState(0)
    const [currentDebit, setCurrentDebit] = useState(0)
    const [currentCredit, setCurrentCredit] = useState(0)
    const [dataTable, setDataTable] = useState([])

    const [full_Report, setFull_Report] = useState(false)
    const [daylyReportDate, setDaylyReportDate] = useState(null)
    const [weeklyReportDate, setWeeklyReportDate] = useState(null)
    const [monthlyReportDate, setMonthlyReportDate] = useState(null)
    const [exportValue, setExportValue] = useState(null)

    const [searchValue, setSearchValue] = useState('')

    const [modalPdfReport, setModalPdfReport] = useState(false)
    const [userInfo, setUserInfo] = useState('')

    useEffect(() => {
        makeDataReport(startDate, endDate, full_Report)
        getUserInfo().then(res => {
            setUserInfo(res.data)
        }).catch(() => {
            toast.error("Error of get history");
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const modalHide = () => {
        setExportValue(null)
        setModalPdfReport(false)
    }

    function makeDataReport(start_Date, end_Date, full_report) {

        const nullDate = new Date(null).toISOString()
        const endDatePrevPeriod = moment(start_Date).subtract(1, 'days').endOf('day').toISOString()
        const startDateCurrPeriod = moment(start_Date).startOf('day').toISOString()
        const endDateCurrPeriod = moment(end_Date).endOf('day').toISOString()

        setViewStartDate(start_Date)
        setViewEndDate(end_Date)

        //console.log('nullDate', nullDate)
        //console.log('endDatePrevPeriod', endDatePrevPeriod)
        //console.log('startDateCurrPeriod', startDateCurrPeriod)
        //console.log('endDateCurrPeriod', endDateCurrPeriod)

        let dataArr = []

        const identPay = (id_trans, transData) => {
            const ident = transData.find(trans => trans.transaction_id === id_trans)
            if (ident !== undefined) {
                return `${ident.type}. ${moment(ident.updated_at).format("DD MMM YYYY HH:mm:ss")} id: ${ident.payment_id} ${ident.company.title} ${ident.country.name}`
            } else return 'not found or superAdmin transfer'
        }
        if (full_report) {
            Promise.all([
                getTransactionsHistoryByTypeByPeriod('income', startDateCurrPeriod, endDateCurrPeriod, true),
                getTransactionsHistoryByTypeByPeriod('recipient', startDateCurrPeriod, endDateCurrPeriod, true),
                getTransactionsHistoryByTypeByPeriod('sender', startDateCurrPeriod, endDateCurrPeriod, true),
                getTransactionsHistoryByTypeByPeriod('income', nullDate, endDatePrevPeriod, false),
                getTransactionsHistoryByTypeByPeriod('recipient', nullDate, endDatePrevPeriod, false),
                getTransactionsHistoryByTypeByPeriod('sender', nullDate, endDatePrevPeriod, false),
                getPaymentSystemTransaction()
            ]).then((res) => {
                if (res[0].data?.transactions) {
                    dataArr = res[0].data.transactions.map(tr => {
                        return ({
                            id_tr: tr.id,
                            date_tr: tr.updated_at,
                            details_tr: identPay(tr.id, res[6].data),
                            summ_tr: tr.sum
                        })
                    })
                }
                if (res[1].data?.transactions) {
                    res[1].data.transactions.forEach(tr => {
                        dataArr.push({
                            id_tr: tr.id,
                            date_tr: tr.updated_at,
                            details_tr: `Bank system transfer from: ${tr.sender.email} id: ${tr.sender.id}`,
                            summ_tr: tr.sum
                        })
                    })
                }
                if (res[2].data?.transactions) {
                    res[2].data.transactions.forEach(tr => {
                        dataArr.push({
                            id_tr: tr.id,
                            date_tr: tr.updated_at,
                            details_tr: `Payment to: ${tr.recipient.email} id: ${tr.recipient.id}`,
                            summ_tr: `-${tr.sum}`
                        })
                    })
                }
                // console.log('Data ARR ==>>', dataArr)
                setDataTable(dataArr.sort((a, b) => b.date_tr.localeCompare(a.date_tr)))
                setPrevBalance(Number.parseFloat(res[3].data.total) + Number.parseFloat(res[4].data.total) - Number.parseFloat(res[5].data.total))
                // setCurBalance(Number.parseFloat(res[0].data.total) + Number.parseFloat(res[1].data.total) - Number.parseFloat(res[2].data.total))
                setTotalBalance((Number.parseFloat(res[0].data.total) + Number.parseFloat(res[1].data.total) - Number.parseFloat(res[2].data.total)) +
                    (Number.parseFloat(res[3].data.total) + Number.parseFloat(res[4].data.total) - Number.parseFloat(res[5].data.total)))
                setCurrentCredit(Number.parseFloat(res[0].data.total) + Number.parseFloat(res[1].data.total))
                setCurrentDebit(Number.parseFloat(res[2].data.total))
            }).catch(() => {
                toast.error("Error of get history");
            })
        } else {
            Promise.all([
                getTransactionsHistoryByTypeByPeriod('income', startDateCurrPeriod, endDateCurrPeriod, true),
                getTransactionsHistoryByTypeByPeriod('recipient', startDateCurrPeriod, endDateCurrPeriod, true),
                getTransactionsHistoryEveryDayByPeriod('sender', startDateCurrPeriod, endDateCurrPeriod),
                getTransactionsHistoryByTypeByPeriod('income', nullDate, endDatePrevPeriod, false),
                getTransactionsHistoryByTypeByPeriod('recipient', nullDate, endDatePrevPeriod, false),
                getTransactionsHistoryByTypeByPeriod('sender', nullDate, endDatePrevPeriod, false),
                getPaymentSystemTransaction()
            ]).then((res) => {
                if (res[0].data?.transactions) {
                    dataArr = res[0].data.transactions.map(tr => {
                        return ({
                            id_tr: tr.id,
                            date_tr: tr.updated_at,
                            details_tr: identPay(tr.id, res[6].data),
                            summ_tr: tr.sum
                        })
                    })
                }
                if (res[1].data?.transactions) {
                    res[1].data.transactions.forEach(tr => {
                        dataArr.push({
                            id_tr: tr.id,
                            date_tr: tr.updated_at,
                            details_tr: `Bank system transfer from: ${tr.sender.email} id: ${tr.sender.id}`,
                            summ_tr: tr.sum
                        })
                    })
                }
                if (res[2].data?.transactions) {
                    const tempSend = Object.entries(res[2].data.transactions)
                    tempSend.forEach(tr => {
                        dataArr.push({
                            id_tr: '',
                            date_tr: moment(tr[0]).endOf('day').toISOString(),
                            details_tr: 'Payments to users',
                            summ_tr: `-${tr[1]}`
                        })
                    })
                }
                // console.log('Data ARR ==>>', dataArr)
                setDataTable(dataArr.sort((a, b) => b.date_tr.localeCompare(a.date_tr)))
                setPrevBalance(Number.parseFloat(res[3].data.total) + Number.parseFloat(res[4].data.total) - Number.parseFloat(res[5].data.total))
                // setCurBalance(Number.parseFloat(res[0].data.total) + Number.parseFloat(res[1].data.total) - Number.parseFloat(res[2].data.total))
                setTotalBalance((Number.parseFloat(res[0].data.total) + Number.parseFloat(res[1].data.total) - Number.parseFloat(res[2].data.total)) +
                    (Number.parseFloat(res[3].data.total) + Number.parseFloat(res[4].data.total) - Number.parseFloat(res[5].data.total)))
                setCurrentCredit(Number.parseFloat(res[0].data.total) + Number.parseFloat(res[1].data.total))
                setCurrentDebit(Number.parseFloat(res[2].data.total))
            }).catch(() => {
                toast.error("Error of get history");
            })
        }
    }

    const setDaylyReport = (selOpt) => {
        setDaylyReportDate(selOpt)
        if (selOpt === null) {
            setStartDate(moment().set({date: 1}).format('YYYY-MM-DD'))
            setEndDate(moment().format('YYYY-MM-DD'))
        } else {
            setWeeklyReportDate(null)
            setMonthlyReportDate(null)
            setStartDate(selOpt.value)
            setEndDate(selOpt.value)
        }
    }

    const setWeeklyReport = (selOpt) => {
        setWeeklyReportDate(selOpt)
        if (selOpt === null) {
            setStartDate(moment().set({date: 1}).format('YYYY-MM-DD'))
            setEndDate(moment().format('YYYY-MM-DD'))
        } else {
            setDaylyReportDate(null)
            setMonthlyReportDate(null)
            setStartDate(moment(selOpt.value).startOf('isoWeek').format('YYYY-MM-DD'))
            setEndDate(selOpt.value)
        }
    }

    const setMonthlyReport = (selOpt) => {
        //console.log(selOpt)
        setMonthlyReportDate(selOpt)
        if (selOpt === null) {
            setStartDate(moment().set({date: 1}).format('YYYY-MM-DD'))
            setEndDate(moment().format('YYYY-MM-DD'))
        } else {
            setDaylyReportDate(null)
            setWeeklyReportDate(null)
            setStartDate(moment(selOpt.value).startOf('month').format('YYYY-MM-DD'))
            setEndDate(selOpt.value)
        }
    }

    const setExportReport = (selOpt) => {

        setExportValue(selOpt)
        if (selOpt === null) {
           //  console.log(selOpt)
        } else {
            switch (selOpt.value) {
                case 'pdf':
                    setModalPdfReport(true)
                    //console.log(userInfo)
                    break;
                case 'xlsx':
                    handleXlsxReport('xlsx')
                    break;
                case 'csv':
                    handleXlsxReport('csv')
                    break;
                default:
                    console.log(selOpt)
            }
        }
    }

    const clearSetReport = () => {
        setDaylyReportDate(null)
        setWeeklyReportDate(null)
        setMonthlyReportDate(null)
    }

    const filteredDataTable = dataTable.filter(data => {
        return (
            data.details_tr.toLowerCase().includes(searchValue.toLowerCase())
        )
    })

    const handleXlsxReport = (format) => {
        let wb = XLSX.utils.book_new()
        let ws = XLSX.utils.aoa_to_sheet([
            [t('Wallet report')],
            [`${t('from')} ${moment(startDate).format('DD.MM.YYYY')} ${t('to')} ${moment(endDate).format('DD.MM.YYYY')}`],
            [undefined, undefined, `${t('Balance as of')} ${moment(endDate).format('DD.MM.YYYY')}:`, Number(totalBalance.toFixed(4))],
            [undefined, undefined, `${t("Total debit for the period")}:`, Number(`-${currentDebit.toFixed(4)}`)],
            [undefined, undefined, `${t("Total credit for the period")}:`, Number(currentCredit.toFixed(4))],
            [undefined, undefined, `${t('Balance as of')} ${moment(startDate).format('DD.MM.YYYY')}:`, Number(prevBalance.toFixed(4))],
            [],
            ['id.', t("Date"), t("Payment details"), t("Sum")]
        ])
        let str = filteredDataTable.map((trans) => {
            return (
                [trans.id_tr, moment(trans.date_tr).format("DD MMM YYYY HH:mm:ss"), trans.details_tr, Number((Number.parseFloat(trans.summ_tr)).toFixed(4))]
            )
        })
        XLSX.utils.sheet_add_aoa(ws, str, {origin: "A9"})
        XLSX.utils.book_append_sheet(wb, ws, 'Report')
        XLSX.writeFile(wb, `Report_from_${moment(startDate).format('DD-MM-YYYY')}_to_${moment(endDate).format('DD-MM-YYYY')}.${format}`)
    }

    // console.log('DATA Table Main >>>', dataTable)
    // console.log('DATA Table Filter >>>', filteredDataTable)
    //    console.log('Prev Balance>>>', prevBalance)
    //    console.log('Curr Balance>>>', curBalance)
    //    console.log('Total Balance>>>', totalBalance)

    return (
        <Container className='wallet'>

            <div className='d-flex wallet-text-primary'>
                <Link to="/wallet">
                    <BackIcon className='icon--size-large wallet-svg-fill'/>{t("Back")}
                </Link>
            </div>
            <h4 className="main-title wallet-page__title">{t("Wallet report")}</h4>
            <div className='row justify-content-between'>
                <div className='col-sm-3'>
                    <Select options={[
                        {label: 'pdf report', value: 'pdf'},
                        {label: 'Excel File', value: 'xlsx'},
                        {label: 'csv File', value: 'csv'}
                    ]}
                            placeholder={t("Export report to...")}
                            isClearable
                            value={exportValue}
                            onChange={setExportReport}
                    />
                </div>
                <div className='col-sm-4'></div>
                <div className='col-sm-2'>
                    <Checkbox label={t("Full report")}
                              readOnly
                              checked={full_Report}
                              onClick={() => setFull_Report(!full_Report)}/>
                </div>
                <div className='col-sm-auto'>
                    <Button
                        onClick={() => makeDataReport(startDate, endDate, full_Report)}>{t("Get a report")}</Button>
                </div>
            </div>

            <div className='row mt-3 mb-3 justify-content-sm-center'>
                <div className='col-sm-auto'>
                    <Form.Control type='date' value={startDate} onChange={(e) => setStartDate(e.target.value)}
                                  onFocus={clearSetReport}/>
                </div>
                <div className='col-sm-auto'>
                    <Form.Control type='date' value={endDate} onChange={(e) => setEndDate(e.target.value)}
                                  onFocus={clearSetReport}/>
                </div>
                <div className='col-sm-auto'>
                    <Select options={[
                        {label: 'this day', value: moment().format('YYYY-MM-DD')},
                        {label: 'previous day', value: moment().subtract(1, 'days').format('YYYY-MM-DD')}
                    ]}
                            placeholder={t("Daily report")}
                            isClearable
                            value={daylyReportDate}
                            onChange={setDaylyReport}
                    />
                </div>
                <div className='col-sm-auto'>
                    <Select options={[
                        {label: 'this week', value: moment().format('YYYY-MM-DD')},
                        {
                            label: 'previous week',
                            value: moment().startOf('isoWeek').subtract(1, 'days').format('YYYY-MM-DD')
                        }
                    ]}
                            placeholder={t("Weekly report")}
                            isClearable
                            value={weeklyReportDate}
                            onChange={setWeeklyReport}
                    />
                </div>
                <div className='col-sm-auto'>
                    <Select options={[
                        {label: 'this month', value: moment().format('YYYY-MM-DD')},
                        {
                            label: 'previous month',
                            value: moment().startOf('month').subtract(1, 'days').format('YYYY-MM-DD')
                        }
                    ]}
                            placeholder={t("Monthly report")}
                            isClearable
                            value={monthlyReportDate}
                            onChange={setMonthlyReport}
                    />
                </div>
            </div>
            <div className="table-container">
                <div className="row mr-1 mt-1">
                    <div className="col-10 text-end">{t("Balance as of")} {moment(viewEndDate).format('DD.MM.YYYY')}:
                    </div>
                    <div className="col-2 text-end wallet-trans-field wallet-field-mftb">
                        {totalBalance && totalBalance.toFixed(4)}
                    </div>
                </div>
                <div className="row mr-1 mt-1">
                    <div className="col-10 text-end">{t("Total debit for the period")}:</div>
                    <div className="col-2 text-end wallet-trans-field wallet-field-mftb">
                        {currentDebit && `-${currentDebit.toFixed(4)}`}
                    </div>
                </div>
                <div className="row mr-1 mt-1">
                    <div className="col-10 text-end">{t("Total credit for the period")}:</div>
                    <div className="col-2 text-end wallet-trans-field wallet-field-mftb">
                        {currentCredit && currentCredit.toFixed(4)}
                    </div>
                </div>
                <div className="row mr-1 mt-1">
                    <div className='col-3'></div>
                    <div className='col-4 text-end'>
                        {t('Search')} : <input type='text' value={searchValue}
                                               onChange={(e) => setSearchValue(e.target.value)}/>
                    </div>
                    <div className="col-3 text-end">{t("Balance as of")} {moment(viewStartDate).format('DD.MM.YYYY')}:
                    </div>
                    <div className="col-2 text-end wallet-trans-field wallet-field-mftb">
                        {prevBalance && prevBalance.toFixed(4)}
                    </div>
                </div>
                <div className="wallet-trans-field mt-1">
                    <div className="row mr-1">
                        <div className="col-1 text-center">id.</div>
                        <div className="col-2 text-center">{t("Date")}</div>
                        <div className="col-5 text-center">{t("Payment details")}</div>
                        <div className="col-4 text-end">{t("Sum")}</div>
                    </div>
                </div>
                <div className="table-body wallet-rtls-main"> {/* wallet-rtls-main */}

                    <div className='wallet-rtls-blk-report'> {/* className='wallet-rtls-block' */}
                        {filteredDataTable.length ? (
                            filteredDataTable.map((trans, key) => {
                                return (
                                    <div key={key} className="row mr-1">
                                        <div className="col-1 text-center">{trans.id_tr}</div>
                                        <div
                                            className="col-3 wallet-field-mft">{moment(trans.date_tr).format("DD MMM YYYY HH:mm:ss")}</div>
                                        <div className="col-6">{trans.details_tr}</div>
                                        <div
                                            className="col-2 text-end wallet-field-mft">{(Number.parseFloat(trans.summ_tr)).toFixed(4)}</div>
                                    </div>
                                )
                            })) : (
                            <div className="row mr-1">
                                <div className="col-12 text-center">
                                    {t("You haven't had any transactions yet")}
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </div>
            <ModalPDFReport
                show={modalPdfReport}
                onHide={modalHide}
                userInfo={userInfo}
                dataTable={filteredDataTable}
                viewStartDate={viewStartDate}
                viewEndDate={viewEndDate}
                prevBalance={prevBalance}
                totalBalance={totalBalance}
                currentCredit={currentCredit}
                currentDebit={currentDebit}
            />
        </Container>
    )
}

export default WalletDetails