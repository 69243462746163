export const OfferDE = `
<!DOCTYPE html>
<html>
<head>
\t<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
\t<title></title>
\t<meta name="generator" content="LibreOffice 7.4.2.3 (Linux)"/>
\t<meta name="author" content="Vika"/>
\t<meta name="created" content="2022-06-23T12:09:00"/>
\t<meta name="changed" content="00:00:00"/>
\t<meta name="GrammarlyDocumentId" content="9b0bac3af706d21a58b0e18dcb4394cc86131ff8d4e267294e84edc8b2f35a31"/>
\t<style type="text/css">
\t\t@page { size: 8.27in 11.69in; margin-left: 0.98in; margin-right: 0.59in; margin-top: 0.79in; margin-bottom: 1.08in }
\t\t@page p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
\t\t@page a:link { color: #0563c1; text-decoration: underline }
\t</style>
</head>
<body lang="uk-UA" link="#0563c1" vlink="#800000" dir="ltr"><p align="center" style="line-height: 100%; margin-bottom: 0in; ">
<br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>ÖFFENTLICHER
AUFTRAG (ANGEBOT)</b></font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>FÜR
DIE BENUTZER-CONTENT-PLATZIERUNG</b></font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; ">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font>&nbsp;</font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Dieser
Auftrag (unten definiert) wird durch die Annahme dieses Angebots, das
alle wesentlichen Bedingungen des Auftrags enthält, durch Sie,
Rechtsperson oder natürliche Person, die: (i) diese Vereinbarung
online annimmt; oder (ii) als </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>„Auftraggeber“</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
identifiziert ist und den Auftrag (im Folgenden – </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>„Kunde“
/ „Sie“ / „Auftraggeber“</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">)
unterzeichnet, und Infocoin Limited in der Person des Direktors
Mykhailo Mikhalis, handelnd aufgrund der Satzung (im Folgenden – </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>„
Agent&quot;, &quot;Firma&quot;</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">)
(im Folgenden zusammen - die </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>&quot;Parteien&quot;</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
oder jede einzeln - eine </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>&quot;Partei&quot;</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">)
abgeschlossen. Dieser Auftrag regelt Ihre Teilnahme am Programm
(unten definiert).</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>In
diesem Auftrag verwendete Begriffe:</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>„Infocoin“</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
– eine unter dem Namen Infocoin vereinte Anwendung und Website, die
eine gemeinsame Funktionalität haben und der Firma Infocoin Limited
gehören;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
„Vertrauliche Informationen“ – Informationen, die gemäß der
Datenschutzrichtlinie unserer Firma als solche definiert sind;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>„Datenschutzrichtlinie“</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
– ein Dokument, das für alle Benutzer der Website und der
Infocoin-Dienste verbindlich ist und auf der Website unter folgendem
Link veröffentlicht wird: (https://www.infocoin.online/docs/).
Abschnitt 7 dieses Auftrags ist nicht erschöpfend und verweist auf
die vollständige Datenschutzrichtlinie, die unsere Zusammenwirkung
mit ihnen regelt und die ohre Zusammenwirkung mit uns regeln musst;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>„Hosting-Unternehmen“
</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">bezeichnet
das Hosting von Benuther-Content und den entsprechenden Geldbetrag.
Ein Unternehmen – ein Hosting;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>„Regeln“</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
bezeichnet die unter (https://www.infocoin.online/docs/)
veröffentlichten Programmregeln, einschließlich der unter
(https://www.infocoin.online/docs/) veröffentlichten
Datenschutzrichtlinie;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>„Infocoin-Programm“</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
bezeichnet das Online-Content-Platzierung-Programm.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>1.
Auftragsgegenstand</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.1.
Der Agent verpflichtet sich, gegen eine Vergütung, alle
erforderlichen Maßnahmen im Namen des Auftraggebers durchzuführen,
um das Benutzer-Content des Auftraggebers in eigenem Namen, jedoch
auf Kosten des Auftraggebers, über das Infocoin-System zu
platzieren.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.2.
Der Agent erwirbt Rechte und verpflichtet sich aus dem mit einem
Dritten im Namen des Agenten und auf Kosten des Auftraggebers
abgeschlossenen Vertrag.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.3.
Gemäß dem Auftrag, den der Agent mit einem Dritten im Namen und auf
Kosten des Auftraggebers schließt, gehen Rechte und Pflichten vom
Auftraggeber aus.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.4.
Die Vergütung des Agenten kann in Abhängigkeit von vielen Faktoren
variieren. Die aktuelle Höhe der Agentenvergütung ist im
persönlichen Kabinett von Infocoin einsehbar und gilt nur zum
Zeitpunkt der Auftragsunterzeichnung. Die Vergütung des Agenten kann
sich jederzeit ändern, Sie stimmen zu, dass Sie über diese
Vergütung ausreichend informiert sind, wenn sie zumindest im
persönlichen Kabinett von Infocoin angegeben wird.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>2.
Розміщення і таргетування</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.1.
Infocoin Limited wird alle Anstrengungen unternehmen, um das
Benutzer-Content des Auftraggebers gemäß den vom Auftraggeber
bereitgestellten und ausgewählten Hosting-Parametern zu platzieren.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.2.
Infocoin Limited garantiert nicht: (a) die Platzierung, der Position
oder die Geschwindigkeit, mit das Benuther-Content gefunden wird,
oder (b) die Anzahl (falls vorhanden) der Klicks pro Link und die
Anzahl der Veröffentlichungen, Modifizierungen oder Klicks in Bezug
auf das Content, das auf der Website oder in der Infocoin-Wendung
oder Abschnitten dieser Website/Wendung veröffentlicht wird.</font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in; ">
<br/>
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.
Rechte und Pflichten der Parteien</b></font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.1.
Der Auftraggeber ist verpflichtet:</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
den vom Auftraggeber für eine Firma zugeteilten Betrag zu zahlen.
Die Zahlung erfolgt durch Handlungen auf der Website im
Infocoin-System und Überweisung von Geldern auf das entsprechende
Konto von Infocoin Limited. Diese Verpflichtungen des Agenten gelten
als vollständig erfüllt, nachdem der Agent einen Bericht für den
entsprechenden Zeitraum oder einen Bericht über die Erfüllung von
Verpflichtungen für einzelne von dem Agenten erfüllten
Vereinbarungen vorgelegt hat;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
ähnliche Aufträge mit anderen Personen nicht abzuschließen;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
bestehende Einwände gegen den vorgelagenen Bericht dem Agenten
innerhalb von 5 (fünf) Tagen nach dessen Vorlegen zu mitteilen.
Werden keine Einwände innerhalb der gesetzten Frist mitteilt, gilt
der Bericht als vom Auftraggeber angenommen.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.2.
Der Auftraggeber ist berichtet auf:</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
die Anforderung der Rückzahlung der für die Übertragung des
betreffenden Videos reservierten Gelder vom Support-Service über den
Link https://business.infocoin.online/support vor Beginn der
Übertragung von Benutzer-Content;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
die Anforderung der Zurückzahlung des nicht abgeschriebenen
Restbetrags, der der für die Übertragung des betreffenden Videos
reserviert wurde, vom Support-Service über den Link
https://business.infocoin.online/support nach dem Ende der
Übertragung des Videos (nach 24 Stunden).</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.3.
Der Agent ist verpflichtet:</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
dem Auftraggeber über die Auftragserfüllung Bericht zu erstatten,
jedoch mindestens einmal pro Monat. Dem Bericht des Agenten müssen
die erforderlichen Kostennachweise beigefügt werden. Alle Daten im
persönlichen Kabinett des Auftraggebers sowie die an die E-Mail des
Auftraggebers gesendeten Berichte sind ausreichende Beweise für die
Kostenaufwände und die Erbringung von Dienstleistungen;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
die Weisungen des Auftraggebers bezüglich der vom Agenten
getroffenen Vereinbarungen und sonstigen Verpflichtungen auszuführen,
sofern diese Weisungen nicht den gesetzlichen Anforderungen
widersprechen;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
im Falle einer Anfrage des Auftraggebers aus den in Ziffer 3.2 dieses
Auftrags genannten Gründen  diese Anfrage innerhalb von 72 Stunden
zu überprüfen und um die Ergebnisse der Prüfung zu berichten, via
einen Brief an die E-Mail-Adresse des Auftraggebers.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
alle Verpflichtungen gewissenhaft zu erfüllen.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.4.
Der Agent ist berechtigt auf:</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
im Rahment der Erfüllung des Auftrages mit einer anderen Person
einen Subagentenvertrag abschließen und damit für die Handlungen
des Subagenten gegenüber dem Auftraggeber verantwortlich bleiben,
sofern die Parteien nichts anderes vereinbaren. Der Subagent ist
außer in den im Subagentenvertrag ausdrücklich vorgesehenen Fällen
nicht berechtigt, im Namen des Auftraggebers Verträge mit Dritten
abzuschließen. Der Agent ist verpflichtet, die Bedingungen des
Subagentenvertrages mit dem Auftraggeber zu vereinbaren;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
dieser Auftrag einseitig zu kündigen, wenn der Auftraggeber Regeln
der Veröffentlichung vom Content, diesen Auftrag, entsprechende
Gesetze der Republik Zypern bricht, die Maßnahmen der Russischen
Föderation unterstützt, oder die Aggression gegen die Ukraine
leugnet.</font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in; ">
<br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in; ">
<br/>
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>4.
Umstände höherer Gewalt</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.1.
Keine Partei haftet gegenüber der anderen Partei für Verzögerungen
oder Nichterfüllung ihrer Verpflichtungen aufgrund von Umständen
höherer Gewalt, die außerhalb des Willens und Wollens der Parteien
entstanden sind und die nicht vorhersehbar oder vermeidbar waren,
einschließlich erklärter oder tatsächlicher Krieg, Bürgerunruhen,
Epidemien, Blockade, Embargo, sowie Erdbeben, Überschwemmungen,
Brände und andere Naturkatastrophen.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.2.
Die Partei, die ihren Verpflichtungen aufgrund von Umständen höherer
Gewalt nicht erfüllen kann, ist verpflichtet, die andere Partei
unverzüglich über die genannten Umstände zu informieren.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.3.
In Zusammenhang mit Umständen höherer Gewalt müssen die Parteien
ein Protokoll über die Kündigung dieses Auftrags unterzeichnen oder
gemeinsame Maßnahmen vereinbaren, um die nachteiligen Folgen dieser
Umstände zu überwinden.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<br/>

</p>
<p align="center" style="line-height: 100%; margin-left: -0.59in; margin-bottom: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>5.
Widerruf der Benutzer-Content-Platzierung</b></font></font></font></p>
<p align="center" style="line-height: 100%; margin-left: -0.59in; margin-bottom: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>und
Rückzahlungen</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.1.
Sofern in den Regeln oder der Vereinbarung (falls vorhanden) oder
einer anderen Online-Schnittstelle, die auf die
Content-Platzierung-Bedingungen oder diesen Auftrag verweist, nichts
anderes bestimmt ist, kann jede Partei jederzeit jede Platzierung von
Benutzer-Content vor der Platzierung widerrufen. Der Auftraggeber
kann die Platzierung von Benutzer-Content im Voraus widerrufen, indem
er Infocoin Limited schriftlich benachrichtigt, einschließlich, aber
nicht beschränkt auf E-Mail, durch den Support-Dienst unter
https://business.infocoin.online/support kontaktiert.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.2.
Die Platzierung von Benutzer-Content wird kurz nach dem Widerruf
eingestellt. Alle anderen widerrufenen Postings von Benutzer-Content
können veröffentlicht werden, obwohl alle Maßnahmen ergriffen
wurden, um sie zu widerrufen, hauptsächlich aufgrund fehlender Zeit
für den Agenten, um zu reagieren, wenn solche Postings
veröffentlicht werden und Aufrufe haben und dementsprechend von nun
an Agentenzahlungsverpflichtungen nach sich ziehen. Der Auftraggeber
muss Infocoin Limited solches platzierten Benutzer-Content bezahlen.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.3.
Im Falle einer Entscheidung zur Rückzahlung auf Antrag des
Auftraggebers gemäß Ziffer 3.2. dieses Auftrags sendet der Agent an
die E-Mail-Adresse des Auftraggebers ein Vergleichsprotokoll der
gegenseitigen Rechnungsstellung. Das angegebene Protokoll gilt als
vom Agenten vereinbart, wenn: i) sie mit einer elektronischen
digitalen Signatur unterzeichnet wird; ii) erforderliche Handlungen
im persönlichen Kabinett vorgenommen wurden iii) eine
unterschriebene Scan-Kopie dieses Protokoll rückgesendet wurde.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.4.
Nach derAbstimmung des Vergleichsprotokolls zahlt der Agent durch
Überweisung von Geldern auf das Konto des Auftraggebers, von dem die
entsprechende Zahlung vorübergewiesen wurde, die Gelder an den
Auftraggeber zurück. Alle Kosten im Zusammenhang mit der Überweisung
von Geldern gehen zu Lasten des Auftraggebers.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.5.
Der Agent muss die Gelder dem Auftraggeber innerhalb von 60
Kalendertagen zurückzahlen. Der Zeitpunkt der Rückzahlung wird
durch die Geschwindigkeit der Transaktionsverarbeitung durch die
entsprechenden Banken und/oder Zahlungssysteme beeinflusst, und in
dieser Hinsicht kann der Rückzahlungszeitraum verlängert werden.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.6.
Rüczahlungsanträge werden während der Übertragung des Videos
nicht akzeptiert.</font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><a name="_heading=h.gjdgxs"></a>
<br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>6.
Erklärungen und Garantien</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.1.
Der Auftraggeber erklärt und garantiert, dass (i) alle
Informationen, die der Auftraggeber Infocoin Limited zur Verfügung
gestellt hat, vollständig, genau und aktuell sind; (ii) der
Auftraggeber hat alle notwendigen Rechte, um die Erlaubnis zu
erteilen, und gewährt Infocoin Limited und allen Partnern hiermit
alle Rechte, die für Infocoin Limited und alle Partner (je nach
Fall) erforderlich sind, um das Benutzer-Content des Auftraggebers zu
verwenden, zu hosten, zwischenzuspeichern, zu verfolgen, zu
speichern, zu kopieren, zu modifizieren, zu verteilen, zu
neuformatieren, zu reproduzieren, zu veröffentlichen, zu
wiedergeben, zu übertragen und zu ausbreiten von Benutzer-Content
des Auftraggebers (einschließlich aller Kontextobjekte)
(&quot;Content-Nutzung&quot;); und (iii) die Nutzung des Contents
gemäß dieses Auftrags sowie der Websites und/oder Zielseiten, zu
denen die Übertragungskanäle für das Benutzer-Content des
Auftraggebers führen, darf nicht: (a) gegen irgendwelche Gesetze
oder anwendbare Regulierungs- oder Verfahrensnormen verstoßen oder
zu deren Verletzung ermutigen; (b) geistige Eigentumsrechte Dritter
verletzen oder Material enthalten, das schädlich, beleidigend,
obszön, bedrohlich oder ehrenrührig sein kann.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.1.
Принципал заявляє та гарантує, що (i) вся
інформація, надана Принципалом для
</font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">,
є повною, точною і оновленою; (ii) він має
всі необхідні права, щоб надати дозвіл
і цим надає </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
і будь-яким Партнерам всі такі права,
що необхідні для </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
та для будь-якого Партнера (Партнерів)
(в залежності від випадку) на використання,
хостинг, розміщення в кеш-пам'яті,
відстеження, зберігання, копіювання,
зміну, розповсюдження, зміну формату,
відтворення, оприлюднення, демонстрацію,
передачу та поширення користувацького
контенту Принципала (включаючи будь-які
Контекстні об'єкти) («Використання
Контенту»); і (iii) Використання Контенту
у відповідності до цього Договору, а
також веб-сайтів та/або цільових сторінок,
на які виходять канали передачі з
користувацького контенту Принципала
не повинно: (a) порушувати або заохочувати
порушення будь-якого законодавства або
чинних регуляторних або процесуальних
норм; (б) порушувати будь-які права
інтелектуальної власності будь-якої
третьої особи або містити будь-який
матеріал, який може завдати шкоди, бути
образливим, непристойним, погрожуючим
або таким, що принижує гідність.</font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>7.
Geheimhaltung</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.1.
Gemäss diesem Auftrag unten verpflichten sich die Parteien, während
der Laufzeit dieses Auftrags und für zwei Jahre nach ihrer
Beendigung die vertraulichen Informationen der anderen Partei nicht
ohne vorherige schriftliche Zustimmung (einschließlich E-Mail) an
Dritte weiterzugeben, mit Ausnahme von die in diesem Auftrag
vorgesehenen Fälle. Der Auftraggeber ist allein verantwortlich für
die Online-Verwendung von Berichten des Auftraggebers durch Dritte.
„Vertrauliche Informationen“ umfassen (i) Passwörter des
Auftraggebers; (ii) Benutzer-Content (einschließlich Kontextobjekte)
vor seiner Veröffentlichung; und (iv) alle anderen
Programminformationen oder Zugang zu Technologie vor ihrer
Veröffentlichung, die dem Auftraggeber von Infocoin Limited zur
Verfügung gestellt wurden und zum Zeitpunkt der schriftlichen
Offenlegung als vertraulich und/oder innergeschäftlich bestimmt
wurden; (v) Funktionen des Programms, die nicht öffentlich sind und
als „Beta“ oder „Testincontent“ definiert sind. Diese
vertraulichen Informationen beinhalten keine Informationen, die nicht
wegen einer Verpflichtungsverletzung durch eine Partei öffentlich
bekannt geworden sind, oder (a) die unabhängig ohne Zugang zu den
vertraulichen Informationen der anderen Partei entwickelt wurden; (b)
rechtmäßig von einem Dritten erhalten wurden oder (c) gesetzlich
oder behördlich offengelegt werden müssen.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.2.
Infocoin kann die vertraulichen Informationen des Auftraggebers an:
(i) jeden das Content platzierenden Dritten oder gegebenenfalls den
jeweiligen Prinzipal; oder (ii) eine Holdinggesellschaft des
Auftraggebers oder eine Tochtergesellschaft des Auftraggebers oder
eine Tochtergesellschaft der Holdinggesellschaft des Auftraggebers
offenlegen.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.3.
Der Auftraggeber anerkennt an und stimmt zu, dass alle Konto-,
Kreditkarten-, Abrechnungs- und Rechnungzahlungsinformationen, die
der Auftraggeber Infocoin Limited zur Verfügung stellt, von Infocoin
Limited mit Firmen, die im Namen von Infocoin Limited handeln,
ausschließlich zum Zweck der Überprüfung von Kontoständen und
Zahlungen an Infocoin Limited und/oder Führung des Kontos des
Auftraggebers geteilt werden können.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.4.
Dieser Abschnitt des Auftrags ist nicht erschöpfend und verweist auf
die Datenschutzrichtlinie, die für uns gilt, und Sie stimmen zu,
dass Sie auch während Ihrer Zusammenwirkung mit uns davon betroffen
sein müssen.</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; background: #f8f9fa">
<br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>8.
Laufzeit und Kündigung</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><br/>
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.1.
Die Laufzeit dieses Auftrags beginnt mit dem Datum der Unterzeichnung
(einschließlich Online-Unterzeichnung durch Begehen von Handlungen
auf der Website) und dauert bis zur Kündigung des Auftrags oder bis
zum Ablaufdatum des Auftrags. Die Auftragsdauer beträgt in der Regel
1 Kalenderjahr. Der Auftraggeber kann, vorbehaltlich der vorherigen
Zustimmung von Infocoin Limited per E-Mail, die Gültigkeit dieses
Auftrags verlängern, indem er eine entsprechende Benachrichtigung
per E-Mail übermittelt oder Online-Handlungen auf unserer Website
begeht, um die Laufzeit dieses Austrags für nächsten Zeitraum von 1
Kalenderjahr oder für einen anderen Zeitraum, der von Infocoin
Limited schriftlich vereinbart werden kann, zu verlängern.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.2.
Diese Bedingungen gelten als Angebot zum Abschluss eines Auftrags
gemäß der Gesetzgebung der Republik Zypern. Der Auftrag kommt zum
Zeitpunkt der Annahme der Bedingungen online zustande der als
Annahmedatum gilt, falls in der Vereinbarung für die Erbringung von
Dienstleistungen (falls vorhanden) kein anderes Annahmedatum
angegeben ist.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.3.
Dieser Auftrag ist schriftlich erstellt, durch den Austausch
elektronischer Nachrichten zwischen den Parteien, sofern keine zuvor
abgeschlossene Vereinbarung für die Erbringung von Dienstleistungen
vorhanden ist. Auf Antrag einer Auftragspartei können die
Auftragsparteien eine Papierkopie des Auftrags erstellen. Zu diesem
Zweck wird die Papierkopie mit dem Annahmedatum datiert, und der
Ausführungsort ist die Ort, in der sich Infocoin Limited befindet.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.4.
Für die Zwecke der geltenden Verbraucherschutzgesetze gilt dieser
Auftrag, die vor Abschluss des Auftrags online verfügbar ist, als
Information über Dienstleistungen, die von Infocoin Limited und/oder
Infocoin aus der Ferne erbracht werden.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.5.
Dieser Auftrag kann von jeder Partei durch schriftliche Anmeldung
(einschließlich, aber nicht beschränkt auf E-Mail-Benachrichtigung)
an die andere Partei mit sofortiger Wirkung gekündigt werden.</font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><br/>
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>9.
Folgen der Kündigung und Einstellung der Content-Platzierung</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">9.1.
Im Falle des Ablaufs oder der Kündigung dieses Aufvertrags: (i) wenn
der Auftraggeber neue Kampagnen erstellt oder das Programm auf andere
Weise weiter nutzt, bleibt er weiterhin an die in diesem Auftrag
vorgesehenen Verpflichtungen gebunden, in gleicher Weise, es sei die
Laufzeit dieses Auftrags noch nicht abgelaufen ist oder es sei der
Auftrag nicht gekündigt wurde (einschließlich, aber nicht
beschränkt auf Verpflichtungen zur Zahlung von mit dem Programm
gebungenen Zuschlagen); (ii) bis der Auftraggeber seine Platzierungen
von Benutzer-Content widerruft, bleibt der Auftraggeber weiterhin an
die in diesem Auftrag festgelegten Verpflichtungen gebunden und muss
die damit verbundenen Zuschlagen zahlen; (iii) der Auftraggeber
haftet weiterhin für die Zahlung aller Beträge, die zum Ablauf-
oder Kündigungsdatum unbezahlt bleiben; (iv) jede Partei gibt die
vertraulichen Informationen auf Anfrage anderer Partei zurück; und
(v) Infocoin Limited kann nach eigenem Ermessen jedes Konto des
Auftraggebers im Programm sperren oder löschen.</font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><br/>
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>10.
Abtretung</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">10.1.
Die Parteien sind berechtigt, ihre Rechte und/oder Pflichten gemäß
diesem Auftrag nur mit schriftlicher Zustimmung der anderen Partei
abzutreten oder auf andere Weise zu übertragen (eine solche
Zustimmung kann nicht ohne ausreichende Gründe abgelehnt oder
verzögert werden), mit Ausnahme von Infocoin Limited ist berechtigt,
seine Rechte und/oder Pflichten gemäß diesem Auftrag an Dritte
abzutreten. Durch Unterzeichnung dieses Auftrags stimmt der
Auftraggeber der Abtretung von eventuell entstehenden Rechten
und/oder Pflichten durch Infocoin Limited zu.</font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>11.
Streitbeilegungsverfahren. Verantwortlichkeiten der Parteien</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.1.
Alle Streitigkeiten oder Meinungsverschiedenheiten, die zwischen den
Parteien aus oder im Zusammenhang mit diesem Auftrag entstehen,
werden durch Verhandlungen beigelegt.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.2.
Im Falle der Unmöglichkeit, Meinungsverschiedenheiten durch
Verhandlungen zwischen den Parteien zu lösen, werden die
Streitigkeiten vor dem zuständigen Gericht gemäß den Normen der
geltenden Gesetzgebung behandelt.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.3.
Im Falle eines Verstoßes gegen die Bestimmungen dieses Auftrags ist
die schuldige Partei verpflichtet, der anderen Partei den
verursachten Schaden, einschließlich entgangenen Gewinns, zu
ersetzen.</font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in; ">
<br/>
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>12.
Sonstige Bestimmungen</b></font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.1.
Dieser Auftrag kann in Bezug auf mehrere Hosting-Firmen abgeschlossen
werden. In Bezug auf eine Platzierungskampagne können die Parteien
mehrere aufeinanderfolgende Aufträge abschließen, einschließlich
der vollständigen Nutzung des Budgets im Rahmen des Auftrags.</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.2.
Die Parteien vereinbaren, dass Informationen aus dem Control Panel
oder aus dem Online-Konto des Infocoin-Systems, die schriftlich von
Infocoin Limited und/oder Infocoin bestätigt wurden, ein
angemessener Beweis für den Abschluss oder die Änderung der
Auftragsbedingungen sind.</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.3.
Indem Sie im Rahmen dieses Auftrags personenbezogene Daten angeben,
stimmen Sie deren Verarbeitung durch Infocoin gemäß der
Datenschutzrichtlinie zu, die Sie unter:
(https://www.infocoin.online/docs/) finden.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.4.
Dieser Auftrag setzt alle zwischen den Parteien vereinbarten
Bedingungen in Bezug auf ihren Gegenstand fest und ersetzt alle
vorherigen Vereinbarungen, Erklärungen und jede Art von Zustimmungen
zwischen den Parteien in Bezug auf seinen Gegenstand.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.5.
Die Regeln können jederzeit von Infocoin Limited einseitig geändert
werden, um Änderungen daran widerzuspiegeln, wie Infocoin die
allgemeine kommerzielle Zweckhaftigkeit des Programms bereitstellt.
Ergänzungen oder Änderungen an den Bestimmungen dieses Auftrags
sind für die Parteien nicht bindend, bis sie im Online-System
bestätigt wurden oder durch ein schriftliches Dokument, das von den
Parteien ausdrücklich schriftlich und bilateral vereinbart wurde
(einschließlich, aber nicht beschränkt auf per Mausklick und
Annahme oder elektronischer Nachrichtenübermittlung), ausgeführt
wurden.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.6.
Keine Nichtausübung oder keine Verzögerung bei der Ausübung eines
Rechts oder Rechtsbehelfs im Rahmen dieses Auftrags gilt als Verzicht
auf dieses Recht oder Rechtsbehelf, und keine einzelne oder teilweise
Ausübung eines Rechts oder Rechtsbehelfs im Rahmen dieses Auftrags
verzichtet auf die Möglichkeit einer späteren Ausübung eines
Rechts oder Rechtsbehelfs oder die Ausübung eines anderen Rechts
oder Rechtsbehelfs. Die in diesem Auftrag vorgesehenen Rechte und
Rechtsbehelfe sind kumulativ und schließen nicht die Möglichkeit
aus, gesetzlich vorgesehene Rechte oder Rechtsbehelfe anzuwenden.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.7.
Die Ungültigkeit, Rechtswidrigkeit oder Nichtanwendbarkeit einer
Bestimmung dieses Auftrags soll andere Bestimmungen dieses Auftrags
nicht rechtsungültig machen oder beeinträchtigen.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.8.
Keine Bestimmung dieses Auftrags darf so ausgelegt werden, dass sie
eine Partnerschaft (offene Handelsgesellschaft) oder ein gemeinsames
Unternehmen jeglicher Art zwischen den Auftragsparteien gründet oder
eine Agentur- oder Vertretungsbeziehung zwischen den Parteien zur
Erreichung eines beliebigen Zwecks begründet. Gleichzeitig hat keine
der Parteien das Recht und die Befugnis, die andere Partei mit
Verpflichtungen zu binden oder im Namen dieser Partei
Vertragsbeziehungen einzugehen oder in irgendeiner Weise und für
jeden Bedarf Verpflichtungen gegenüber einem Dritten zu begründen.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.9.
Sofern in diesem Auftrag nichts anderes bestimmt ist, müssen alle
Anmeldungen an die in diesem Auftrag (oder im Online-System)
angegebenen Adressen gesendet werden, mit einer Kopie an die
Rechtsabteilung, durch (i) Senden per Fax mit Empfangsbestätigung,
vorbehaltlich Versand einer Kopie per erstklassiger Post oder
Luftpost; (ii) Versand per Kurierdienst, und solche Anmeldungen
gelten zum Zeitpunkt ihres Empfangs als geliefert; oder (iii) per
E-Mail.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.10.
Der Agent ist ein Körperschaftsteuerzahler gemäß den Gesetzen der
Republik Zypern.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; ">
<br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in; ">
<br/>
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>13.
Juristische Anschrift und Bankverbindung des Agenten</b></font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in; ">
<br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in; ">
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>INFOCOIN
LIMITED</b></font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Anexartisias
&amp; Athinon, </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">NORA
COURT, 2nd floor, Office 203, 3040, Limassol, Cyprus</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">e-mail:
info@infocoin.online</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Mykhailo
Michalis</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><a name="_heading=h.30j0zll"></a>
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Direktor.</font></font></p>
</body>
</html>
`;
