import React, { memo } from "react";
import classes from "./StatItem.module.scss";
const StatItem = ({ Icon, label, statistic }) => {
  return (
    <div className={classes.container}>
      <Icon className={classes.icon} />
      <div className={classes.label}>{label}</div>
      <div className={classes.stat}>{statistic || "-"}</div>
    </div>
  );
};

export default memo(StatItem);
