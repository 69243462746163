import React from "react";
import classes from "./SuccessAction.module.scss";
import Logo from "@assets/icons/logo.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";

const SuccessAction = ({text = "Video uploaded successfully!"}) => {
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <img src={Logo} alt="Infocoin" />
      </div>
      <div className={classes.text}>{t(text)}</div>
      <CheckCircleIcon className={classes.done} />
    </div>
  );
};

export default SuccessAction;
