import {
  SET_IS_COUNTRY_EMERGENCY,
  SET_IS_MY_VIDEO_CHOSEN,
  SET_LOADING,
  SET_MY_CONTENT,
  SET_READY_CONTENT,
} from "@redux/emergency/constants";

const initialState = {
  isEmergency: false,
  isMyVideoChosen: true,
  myContent: [],
  readyContent: [],
  isLoading: false,
};

export const emergencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_COUNTRY_EMERGENCY:
      return { ...state, isEmergency: action.payload };
    case SET_IS_MY_VIDEO_CHOSEN:
      return { ...state, isMyVideoChosen: action.payload };
    case SET_MY_CONTENT:
      return { ...state, isLoading: false, myContent: action.payload };
    case SET_READY_CONTENT:
      return { ...state, readyContent: action.payload };
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};
