import React from "react";

import { Icon } from "src/views/blocks";

import "./index.scss";

const InfoIcon = props => {
  const { className = "", ...rest } = props;

  return <Icon {...rest} className={`info-icon ${className}`} background />;
};

export { InfoIcon };
