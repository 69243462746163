import { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getBalanceAction,
  getExchangeRateAction,
  getHistoryAction,
  getTransactionsAction,
  setPendingTransactionsAction,
} from "@redux/wallet/actions";
import {
  walletBalanceSelector,
  walletCompanyBalanceSelector,
  walletExchangeSelector,
  walletLoadingSelector,
  walletPendingTransactionsSelector,
  walletTransactionsSelector,
  walletCommonBalanceSelector,
} from "@redux/wallet/selectors";
import { userCountrySelector } from "@redux/user/selectors";

export const useWallet = () => {
  const dispatch = useDispatch();
  const { hasHistory = false } = useSelector(
    state => ({
      hasHistory: state.wallet.hasHistory,
    }),
    shallowEqual,
  );

  const userCountry = useSelector(userCountrySelector, shallowEqual);

  const loading = useSelector(walletLoadingSelector, shallowEqual);

  const balance = useSelector(walletBalanceSelector, shallowEqual);

  const companyBalance = useSelector(walletCompanyBalanceSelector, shallowEqual);

  const commonBalance = useSelector(walletCommonBalanceSelector, shallowEqual);

  const pendingTransactions = useSelector(
    walletPendingTransactionsSelector,
    shallowEqual,
  );

  const transactions = useSelector(walletTransactionsSelector, shallowEqual);

  const exchangeRate = useSelector(walletExchangeSelector, shallowEqual);

  const updateBalance = useCallback(() => {
    dispatch(getBalanceAction());
  }, [dispatch]);


  const updateSchedule = useCallback(() => {
    dispatch(getHistoryAction());
  }, [dispatch]);

  const updateExchangeRate = useCallback(() => {
    dispatch(getExchangeRateAction(userCountry));
  }, [dispatch, userCountry]);

  const updateTransactions = useCallback(async () => {
    const newTransactions = await dispatch(getTransactionsAction());
    const array = [...pendingTransactions];
    dispatch(
      setPendingTransactionsAction(
        array.filter(
          tr => !newTransactions.find(nt => nt.payment_id === tr.id),
        ),
      ),
    );
  }, [dispatch, pendingTransactions]);

  const addPendingTransaction = useCallback(
    ({ id, amount, created_at }) => {
      dispatch(
        setPendingTransactionsAction([
          {
            id,
            status: "PENDING",
            type: "PayPal",
            amount,
            created_at,
          },
          ...pendingTransactions,
        ]),
      );
    },
    [dispatch, pendingTransactions],
  );

  useEffect(() => {
    updateExchangeRate();

    updateTransactions();

    updateBalance();


    //eslint-disable-next-line
  }, []);
  return {
    balance,
    commonBalance,
    hasHistory,
    transactions,
    updateTransactions,
    pendingTransactions,
    updateSchedule,
    addPendingTransaction,
    loading,
    exchangeRate,
    updateBalance,
    companyBalance,
  };
};
