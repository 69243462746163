import { AUTH_LOGOUT } from "src/redux/auth/constants";
import {
  ADD_NEW_INVOICE,
  LOADING_END,
  SET_AMOUNT_INVOICE,
  SET_BALANCE,
  SET_COMMON_BALANCE,
  SET_COMPANIES_WALLETS,
  SET_EXCHANGE_RATE,
  SET_HISTORY,
  SET_INVOICES,
  SET_LOADING,
  SET_PENDING_TRANSACTIONS,
  SET_TRANSACTIONS,
} from "src/redux/wallet/constants";

const initialState = {
  balance: 0,
  commonBalance: 0,
  companyBalance: undefined,
  pendingTransactions: [],
  history: [
    {
      name: "January",
      sum: 0,
      id: 0,
    },
    {
      name: "February",
      sum: 0,
      id: 1,
    },
    {
      name: "March",
      sum: 0,
      id: 2,
    },
    {
      name: "April",
      sum: 0,
      id: 3,
    },
    {
      name: "May",
      sum: 0,
      id: 4,
    },
    {
      name: "June",
      sum: 0,
      id: 5,
    },
    {
      name: "July",
      sum: 0,
      id: 6,
    },
    {
      name: "August",
      sum: 0,
      id: 7,
    },
    {
      name: "September",
      sum: 0,
      id: 8,
    },
    {
      name: "October",
      sum: 0,
      id: 9,
    },
    {
      name: "November",
      sum: 0,
      id: 10,
    },
    {
      name: "December",
      sum: 0,
      id: 11,
    },
  ],
  exchangeRate: {
    input: 1,
    output: 0,
  },
  transactions: [],
  loading: false,
  error: {},
  invoices: [],
  invoiceInfo: { amount: null, company: {} },
};

export default function walletReducer(
  state = initialState,
  { type, payload } = {},
) {
  switch (type) {
    case SET_BALANCE:
      return {
        ...state,
        balance: payload.commonBalance,
        commonBalance: payload.balance,
        wallets: payload.wallets,
      };
    case SET_COMMON_BALANCE: {
      return {
        ...state,
        balance: payload,
      };
    }
    case SET_COMPANIES_WALLETS:
      return {
        ...state,
        companyBalance: payload,
      };
    case SET_HISTORY: {
      const { history, hasHistory } = payload;

      return {
        ...state,
        history,
        hasHistory,
      };
    }
    case SET_EXCHANGE_RATE:
      return {
        ...state,
        exchangeRate: payload,
      };
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: payload,
      };
    case SET_PENDING_TRANSACTIONS:
      return {
        ...state,
        pendingTransactions: payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_INVOICES:
      return {
        ...state,
        invoices: payload,
      };
    case ADD_NEW_INVOICE:
      return {
        ...state,
        invoices: [...state.invoices, payload],
      };
    case SET_AMOUNT_INVOICE:
      return {
        ...state,
        invoiceInfo: payload,
      };
    case LOADING_END:
      return {
        ...state,
        loading: false,
      };
    case AUTH_LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
}
