import React, { useState, useCallback, memo, Fragment } from "react";
import { Steps } from "intro.js-react";
import { connect } from "react-redux";
import { updateUserAction } from "@redux/user/actions/userActions";

import { introIds } from "../classes";

import { useTranslation, withTranslation } from "react-i18next";
import { Typography } from "../../../views/blocks";
import Modal from "@components/Modal";
import { Grid } from "@mui/material";
import Button from "@components/Button";

const IntroWallet = ({ t }) => {
  const [show, setShow] = useState(true);
  const intro = {
    initialStep: 0,
    stepsEnabled: true,
    options: {
      nextLabel: t("Next"),
      prevLabel: t("Back"),
      doneLabel: t("Next step"),
      disableInteraction: true,
      exitOnOverlayClick: false,
    },
    steps: [
      {
        element: `#${introIds.wallet.element1}`,
        position: "left",
        intro: (
          <Fragment>
            <Typography variant="h5" weight="normal-bold" align="center">
              {t(
                "You need to select a company to deposit the account in the wallet",
              )}
            </Typography>
            <Typography
              variant="body1"
              color="gray"
              align="center"
              className="mt-2"
            >
              {t(
                "(if the company is from a legal entity, the invoice will be sent to you by email, if the company is from an individual, the payment is made by credit card)",
              )}
            </Typography>
          </Fragment>
        ),
      },
      {
        element: `#${introIds.wallet.element2}`,
        position: "left",
        intro: (
          <Fragment>
            <Typography variant="h5" weight="normal-bold" align="center">
              {t(
                "On the line below, select the country in which the content will be shown",
              )}
            </Typography>
          </Fragment>
        ),
      },
      {
        element: `#${introIds.wallet.element3}`,
        position: "left",
        intro: (
          <Fragment>
            <Typography variant="h5" weight="normal-bold" align="center">
              {t("You must also enter the amount you are planning to pay")}
            </Typography>
            <Typography
              variant="body1"
              color="gray"
              align="center"
              className="mt-2"
            >
              {t("(the minimum deposit on the platform is 15 euros)")}
            </Typography>
          </Fragment>
        ),
      },
      {
        element: `#${introIds.wallet.element4}`,
        position: "left",
        intro: (
          <Fragment>
            <Typography variant="h5" weight="normal-bold" align="center">
              {t(
                "In the nearby line, the system will convert this amount into the platform's internal currency, infocoins, which you will later use to pay for your advertising",
              )}
            </Typography>
          </Fragment>
        ),
      },
      {
        element: `#${introIds.wallet.element5}`,
        position: "left",
        intro: (
          <Fragment>
            <Typography variant="h5" weight="normal-bold" align="center">
              {t(
                "Finally, you select whether to pay via payment system or bank account",
              )}
            </Typography>
            <Typography
              variant="body1"
              color="gray"
              align="center"
              className="mt-2"
            >
              {t("and then click on the deposit button.")}
            </Typography>
          </Fragment>
        ),
      },
      {
        element: `#${introIds.wallet.element6}`,
        position: "left",
        intro: (
          <Fragment>
            <Typography variant="h5" weight="normal-bold" align="center">
              {t(
                'After you click "deposit", you will receive an electronic invoice from Infocoin Limited, which will contain the purpose of payment and the amount of payment',
              )}
            </Typography>
            <Typography
              variant="body1"
              color="gray"
              align="center"
              className="mt-2"
            >
              {t(
                '(which includes the actual deposit and a 20% fee). If you agree to all the terms and conditions, press the "pay" button.',
              )}
            </Typography>
          </Fragment>
        ),
      },
    ],
  };

  const handleClose = useCallback(async () => {
    if (intro.stepsEnabled) {
      // const data = { ...user, is_tutorial_passed: true };
      // delete data.phone;
      // await updateUserAction(data);
    }
  }, [intro.stepsEnabled]);

  const handleSubmit = useCallback(() => {
    // setIntro(intro => ({
    //   ...intro,
    //   stepsEnabled: false,
    // }));
  }, []);

  const onHide = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <Fragment>
      {show ? (
        <Modal
          show={show}
          onHide={onHide}
          contentClassName="modal-intro"
          dialogClassName="modal-intro-dialog"
        >
          <Grid container>
            <Grid item>
              <Typography variant="h5" weight="normal-bold" align="center">
                {t(
                  "To place their content on the Infocoin platform, an advertiser needs to form a deposit, from which payment for broadcasting information will be charged. To do this, follow the steps below: ",
                )}
              </Typography>
            </Grid>

            <Grid
              item
              mt={2}
              display="flex"
              justifyContent="center"
              className="modal-intro__actions"
              width="100%"
            >
              <Button variant="primary" onClick={onHide}>
                <Typography variant="body2">{t("Continue")}</Typography>
              </Button>
            </Grid>
          </Grid>
        </Modal>
      ) : (
        <Steps
          enabled={intro.stepsEnabled}
          steps={intro.steps}
          initialStep={intro.initialStep}
          options={intro.options}
          onExit={handleClose}
          onComplete={handleSubmit}
          onBeforeChange={nextStepIndex => {
            document
              .querySelector(intro.steps[nextStepIndex].element)
              .scrollIntoView({ block: "center" });
          }}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ user }) => ({
  user: user.user,
});

const mapDispatchToProps = {
  updateUserAction,
};

export default withTranslation()(memo(IntroWallet));
