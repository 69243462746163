import React, { memo } from "react";

import { Grid } from "@mui/material";
import Input from "@components/Forms/components/Input";
import { Typography } from "../../../../../views/blocks";
import { AuthButton } from "@components/Forms/components/ui";
import { useFormik } from "formik";
import { resetPasswordSchema } from "@helpers/Formik/validation";

import { useTranslation } from "react-i18next";

import Loader from "@components/Loader";

const ResetPasswordEmailForm = ({ defaultEmail, onSubmit }) => {
  const { t } = useTranslation();

  const {
    values,
    touched,
    isValid,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: { email: defaultEmail },
    validationSchema: resetPasswordSchema(0),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onSubmit(values, 0);
      setSubmitting(false);
    },
  });

  return (
    <Grid component="form">
      <Grid item mt={6}>
        <Input
          label={t("Email")}
          name="email"
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.email}
          value={values.email}
          error={t(errors.email)}
        />
        <Typography color="gray" variant="caption2">
          {t("Enter the e-mail you used during registration")}
        </Typography>
      </Grid>
      <Grid item mt={15}>
        <AuthButton
          fullWidth="100%"
          onClick={handleSubmit}
          disabled={!isValid || isSubmitting}
        >
          {isSubmitting ? (
            <Loader color="white" width={31.5} height={31.5} />
          ) : (
            t("Submit")
          )}
        </AuthButton>
      </Grid>
    </Grid>
  );
};

export default memo(ResetPasswordEmailForm);
