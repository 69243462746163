import React, { useMemo } from "react";
import { Container } from "react-bootstrap";
import About from "@components/About";
import TermsUse from "@components/TermsUse";
import PrivacyPolicy from "@components/PrivacyPolicy";
import Copyright from "@components/Copyright";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const Information = () => {
  const menuItems = useMemo(
    () => [
      { name: "О InfoCoin", Component: About },
      { name: "Условия пользования", Component: TermsUse },
      { name: "Политика конфиденциальности", Component: PrivacyPolicy },
      { name: "Авторские права", Component: Copyright },
    ],
    [],
  );
  return (
    <div className="wrapper page">
      <Container className="my-3 d-flex">
        {/* <img className="me-3" src={info} alt="" /> */}
        <h4>Информация</h4>
      </Container>
      <Container>
        <Accordion style={{ marginTop: "200px" }}>
          {menuItems.map(item => (
            <AccordionItem className="my-4">
              <AccordionItemHeading>
                <AccordionItemButton>{item.name}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <item.Component />
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Container>
    </div>
  );
};

export default Information;
