import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { videosSelector } from "@redux/content/selectors";
import {
  getDemoVideosAction,
  getVideosAction,
} from "@redux/content/contentActions";
import clsx from "clsx";
import { Container } from "react-bootstrap";
import classes from "./EditVideo.module.scss";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Loader from "@components/Loader";

import { EditForm } from "./blocks/EditForm";

const EditVideo = () => {
  const params = useParams();
  const history = useHistory();

  const { videos, loading } = useSelector(videosSelector);
  const role = useSelector(state => state.user.user.role);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const video = useMemo(
    () => videos.find(v => v.id === Number(params.id)) || {},
    [params.id, videos],
  );

  const [data, setData] = useState({});

  useEffect(() => {
    if (role !== "demo_user") {
      dispatch(getVideosAction()).then(videos => {
        const video = videos.find(v => v.id === Number(params.id));
        setData({
          id: video.id,
          video: video.filepath_480_p,
          country: video.country || "",
          categories: video.categories
            ? video.categories.map(c => ({
                label: t(c.title),
                value: c.id,
              }))
            : [],
          description: video?.description || "",
          speech_language: video?.speech_language || "",
          email: video?.email || "",
          goal: video.email ? "email" : video.url ? "url" : "",
          title: video?.title || "",
          url: video?.url || "",
          age_min: video?.age_min || "",
          cities: video.cities
            ? video.cities.map(c => ({
                label: t(c.name),
                value: c.id,
              }))
            : [],
          regions: video.regions
            ? video.regions.map(r => ({
                label: t(r.name),
                value: r.id,
              }))
            : [],
          age_max: video?.age_max || "",
          genders: video?.genders.map(v => v.id) || [],
          is_all_country: video?.is_all_country,
          company_id: video?.company?.id || "",
        });
      });
    } else {
      dispatch(getDemoVideosAction()).then(videos => {
        const video = videos.find(v => v.id === Number(params.id));
        setData({
          ...video,
          company_id: video.company.id,
          country_id: video.country.name,
          goal: video.email || video.url ? (video.email ? "email" : "url") : "",
        });
      });
    }
  }, []);

  const handleClose = useCallback(() => {
    history.push("/content");
  }, [history]);

  return (
    <Container className={clsx(classes.container)}>
      {loading || !video ? (
        <Loader />
      ) : (
        <>
          <div className={classes.titleRow}>
            <ArrowBackRoundedIcon
              className={classes.arrow}
              onClick={handleClose}
            />
            <h1 className={clsx("auction-page-title")}>{t("Update video")}</h1>
          </div>
          <p className={classes.warning}>
            {t(
              "After changing the data in any field, the video must be sent for moderation",
            )}
          </p>
          {data.id ? (
            <EditForm data={data} handleClose={handleClose} />
          ) : (
            <Loader isPageLoader />
          )}
        </>
      )}
    </Container>
  );
};

export default EditVideo;
