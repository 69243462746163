import React, { useCallback, useState } from "react";
import Popover from "@mui/material/Popover";
import { useTranslation } from "react-i18next";
import "./index.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { Calendar } from "react-date-range";
import * as locales from "react-date-range/dist/locale";

import { TextInput } from "../../../../views/inputs";

import { ArrowDropDown } from "@mui/icons-material";

import moment from "moment";

const nameMapper = {
  en: "enGB",
};
const DatePicker = ({ date, onDataChange, today, placeholder = "" }) => {
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const maxDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1),
  );

  const [value, setValue] = useState(date);

  const open = Boolean(anchorEl);
  const handleToggleOpen = useCallback(
    event => {
      open ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
    },
    [open, setAnchorEl],
  );

  const id = open ? "simple-popover" : undefined;

  const handleChange = useCallback(
    date => {
      setValue(date);
      handleToggleOpen();
      onDataChange(date);
    },
    [handleToggleOpen, onDataChange, moment],
  );

  return (
    <div className="calendar-container">
      <div role="button" onClick={handleToggleOpen}>
        <TextInput
          readOnly
          fullWidth
          placeholder={placeholder}
          value={
            moment(date).format("DD.MM.YYYY") === today
              ? ""
              : moment(date).format("DD.MM.YYYY")
          }
          rightAddons={
            <div
              className={`input-arrow-svg ${
                anchorEl ? "input-arrow-svg__active" : ""
              }`}
            >
              <ArrowDropDown />
            </div>
          }
        />
      </div>
      <Popover
        id={id}
        open={open}
        className="calendar-popover"
        anchorEl={anchorEl}
        onClose={handleToggleOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="auction-calendar-content">
          <Calendar
            showMonthAndYearPickers={false}
            maxDate={maxDate}
            minDate={new Date()}
            date={value}
            locale={locales[nameMapper[i18n.language] || i18n.language]}
            onChange={handleChange}
          />
        </div>
      </Popover>
    </div>
  );
};

export default DatePicker;
