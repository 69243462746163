import shortid from 'shortid';
import cover from '@assets/icons/catecory_cover.svg';
import avatar from '@assets/icons/upload.svg';

export const stories = [
  {
    url: 'https://dev-api.infocoin.online/public/1.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/2.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: avatar,
      cover: cover,
      name: 'Red Bull Racing',
      tags: ['#drigt', '#redbull', '#rbwings', '#rbenergy'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/3.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/4.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/5.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/6.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/7.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/8.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/9.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/10.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/11.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/12.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/13.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/14.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/15.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/16.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/17.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/18.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/19.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/20.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/21.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/22.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/23.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/24.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/25.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/26.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/27.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/28.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/29.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/30.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/31.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/32.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/33.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/34.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/35.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/36.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/37.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/38.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/39.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/40.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/41.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/42.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/43.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/44.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/45.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/46.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/47.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/48.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/49.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
  {
    url: 'https://dev-api.infocoin.online/public/50.mp4',
    type: 'video/mp4',
    id: shortid.generate(),
    isLiked: false,
    author: {
      id: shortid.generate(),
      avatar: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      cover: 'https:www.zvuki.ru/images/photo/51/51227.jpg',
      name: 'Beyonce',
      tags: ['#beyonce', '#singer', '#usa', '#music'],
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!',
    },
  },
];
