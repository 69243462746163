import { AUTH_LOGOUT } from "@redux/auth/constants";
import {
  ADD_NEW_NOTIFICATION,
  DELETE_ALL_NOTIFICATIONS,
  READ_ALL_NOTIFICATIONS,
  SAVE_NEW_NOTIFICATION,
} from "@redux/notifiactions/constants";

const initialState = {
  items: [],
  loading: false,
  unread: 0,
  error: {},
};

export default function notificationReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case SAVE_NEW_NOTIFICATION:
      return {
        ...state,
        items: payload,
        unread: payload.length,
      };
    case ADD_NEW_NOTIFICATION:
      return {
        ...state,
        items: [...state.items, payload],
        unread: state.unread + 1,
      };
    case READ_ALL_NOTIFICATIONS:
      return {
        ...state,
        unread: 0,
      };
    case DELETE_ALL_NOTIFICATIONS:
      return {
        ...state,
        unread: 0,
        items: [],
      };
    case AUTH_LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
}
