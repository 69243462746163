import {
  ADD_NEW_NOTIFICATION,
  DELETE_ALL_NOTIFICATIONS,
  READ_ALL_NOTIFICATIONS,
  SAVE_NEW_NOTIFICATION,
} from "@redux/notifiactions/constants";

export const saveNotificationsAction = data => {
  return {
    type: SAVE_NEW_NOTIFICATION,
    payload: data,
  };
};

export const addNewNotificationsAction = noti => {
  return {
    type: ADD_NEW_NOTIFICATION,
    payload: noti,
  };
};

export const deleteNotificationAction = id => async (dispatch, getState) => {
  const {
    notifications: { items },
  } = getState();
  const result = items.filter((n, index) => index !== id);
  dispatch({
    type: SAVE_NEW_NOTIFICATION,
    payload: result,
  });
};

export const deleteAllNotifications = () => async dispatch => {
  dispatch({
    type: DELETE_ALL_NOTIFICATIONS,
  });
};
export const readAllNotificationsAction = () => async dispatch => {
  dispatch({
    type: READ_ALL_NOTIFICATIONS,
  });
};
