import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { LogoLarge, Typography } from "src/views/blocks";
import { SuccessIcon } from "src/views/icons";

import "./index.scss";

const SuccessBlock = props => {
  const { children, text = "Video uploaded successfully!" } = props;

  const { t } = useTranslation();

  return (
    <Grid
      container
      className="success-block"
      direction="column"
      spacing={4}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <LogoLarge className="success-block__logo" />
      </Grid>

      <Grid item>
        <Typography variant="h3" align="center" weight="semi-bold">
          {t(text)}
        </Typography>
      </Grid>

      <Grid item>
        <SuccessIcon size="large" />
      </Grid>

      <Grid item>{children}</Grid>
    </Grid>
  );
};

export { SuccessBlock };
