const VIDEO_STATUS_VERIFIED = "verified";
const VIDEO_STATUS_NOT_VERIFIED = "not_verified";
const VIDEO_STATUS_REJECTED = "rejected";
const VIDEO_STATUS_VERIFYING_POSTPONED = "verifying_postponed";
const VIDEO_STATUS_SENT_TO_VERIFICATION = "sent_to_verification";

const mapStatuses = {
  [VIDEO_STATUS_VERIFIED]: "Verified",
  [VIDEO_STATUS_NOT_VERIFIED]: "Not Verified",
  [VIDEO_STATUS_REJECTED]: "Rejected",
  [VIDEO_STATUS_VERIFYING_POSTPONED]: "Verifying Postponed",
  [VIDEO_STATUS_SENT_TO_VERIFICATION]: "Sent to verification",
};

export {
  mapStatuses,
  VIDEO_STATUS_VERIFIED,
  VIDEO_STATUS_NOT_VERIFIED,
  VIDEO_STATUS_VERIFYING_POSTPONED,
  VIDEO_STATUS_REJECTED,
  VIDEO_STATUS_SENT_TO_VERIFICATION,
};

export * from "./actions";
