import React, { useState, useCallback, useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { DefaultPlayer as Video } from "react-html5video";
import Loader from "@components/Loader";
import Modal from "src/components/Modal";
import { ReactComponent as NewIcon } from "src/assets/icons/new_icon.svg";
import moment from "moment";

const VideoCell = ({ titleName, isAuction, value, isMobile, ...cell }) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const isNew = useMemo(() => {
    if (cell.row) {
      let date1 = moment();
      let date2 = moment(cell.row.original.created_at);

      let diffInHours = date1.diff(date2, "hours");
      return diffInHours < 2;
    }
    return false;
  }, [cell]);

  return (
    <React.Fragment>
      {!value && (
        <div>
          {isAuction ? (
            ""
          ) : (
            <div>
              <p className="mb-2" style={{ fontSize: "10px" }}>
                {t("Please wait while the video is loading")}
              </p>
              <Loader width={30} height={30} />
            </div>
          )}
        </div>
      )}

      {value && (
        <div
          className="position-relative"
          style={{
            width: isMobile ? "100%" : "71px",
            height: isMobile ? undefined : "34px",
            margin: "0 auto",
          }}
          onClick={handleOpen}
        >
          {isNew && <NewIcon className={"video-table-new"} />}
          <Video
            className={isMobile ? "video-mobile" : "video-table"}
            play={false}
            controls={false}
          >
            <source type="video/webm" src={value} />
            <track
              default
              label="English"
              kind="subtitles"
              srcLang="en"
              src={value}
            />
          </Video>

          <div className="video-overlay" />
        </div>
      )}

      <div
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Modal
          show={isOpen}
          overlay={true}
          headerText={titleName ? t(titleName) : ""}
          contentClassName="video-modal"
          onHide={handleClose}
          closeButtonClassName="video-modal-close"
        >
          <Video
            className=""
            controls={["PlayPause", "Seek", "Time", "Fullscreen", "Volume"]}
          >
            <source type="video/webm" src={value} />

            <track
              default
              label="English"
              kind="subtitles"
              srcLang="en"
              src={value}
            />
          </Video>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default memo(VideoCell);
