import Ukraine from "src/assets/maps/Ukraine";
import France from "src/assets/maps/France";
import Poland from "src/assets/maps/Poland";
import Austria from "src/assets/maps/Austria";
import Belgium from "src/assets/maps/Belgium";
import Bulgaria from "src/assets/maps/Bulgaria";
import Hungary from "src/assets/maps/Hungary";
import Germany from "src/assets/maps/Germany";
import Greece from "src/assets/maps/Greece";
import Denmark from "src/assets/maps/Denmark";
import Ireland from "src/assets/maps/Ireland";
import Turkey from "src/assets/maps/Turkey";
import Spain from "src/assets/maps/Spain";
import Cyprus from "src/assets/maps/Cyprus";
import Italy from "src/assets/maps/Italy";
import Latvia from "src/assets/maps/Latvia";
import Lithuania from "src/assets/maps/Lithuania";
import Croatia from "src/assets/maps/Croatia";
import Czech from "src/assets/maps/Czech-Republic";
import Estonia from "src/assets/maps/Estonia";
import Finland from "src/assets/maps/Finland";
import Luxembourg from "src/assets/maps/Luxembourg";
import Malta from "src/assets/maps/Malta";
import Netherlands from "src/assets/maps/Netherlands";
import Romania from "src/assets/maps/Romania";
import Slovakia from "src/assets/maps/Slovakia";
import Slovenia from "src/assets/maps/Slovenia";
import Sweden from "src/assets/maps/Sweden";
import UK from "src/assets/maps/UK";
import Portugal from "src/assets/maps/Portugal";

export const Maps = {
  Ukraine,
  France,
  Poland,
  Austria,
  Belgium,
  Bulgaria,
  Hungary,
  Germany,
  Greece,
  Denmark,
  Ireland,
  Turkey,
  Spain,
  Cyprus,
  Italy,
  Latvia,
  Lithuania,
  Croatia,
  "Czech Republic": Czech,
  Estonia,
  Finland,
  Luxembourg,
  Malta,
  Netherlands,
  Romania,
  Slovakia,
  Slovenia,
  Sweden,
  "United Kingdom": UK,
  Portugal,
};

// For creating maps use  npx @svg-maps/cli generate [map].svg [name].js
