import {
  CREATE_COMPANY,
  CREATE_COMPANY_ERROR,
  CREATE_COMPANY_SUCCESS,
  DELETE_COMPANY,
  DELETE_COMPANY_ERROR,
  DELETE_COMPANY_SUCCESS,
  GET_COMPANIES,
  GET_COMPANIES_ERROR,
  GET_COMPANIES_SUCCESS,
  UPDATE_COMPANY,
  UPDATE_COMPANY_ERROR,
  UPDATE_COMPANY_SUCCESS,
} from "@redux/companies/constants";

import { axiosInstance } from "@helpers/http/httpInstance";

import {
  fetchCreateCompany,
  fetchDeleteCompany,
  deleteDemoCompany,
  getCompanies,
  getDemoCompanies,
  createDemoCompany,
} from "@services/api/compnaies";
import { refreshToken } from "@services/api/auth";
import { setTokenData } from "@utils/tokens";
import { toast } from "react-toastify";

export const getCompaniesAction = () => async dispatch => {
  dispatch({
    type: GET_COMPANIES,
  });
  try {
    const { data: companies } = await getCompanies();
    dispatch({
      type: GET_COMPANIES_SUCCESS,
      payload: companies,
    });
    return companies;
  } catch (e) {
    dispatch({
      type: GET_COMPANIES_ERROR,
      payload: e,
    });
    throw e;
  }
};

export const getDemoCompaniesAction = () => async dispatch => {
  dispatch({
    type: GET_COMPANIES,
  });
  try {
    const { data: companies } = await getDemoCompanies();
    dispatch({
      type: GET_COMPANIES_SUCCESS,
      payload: companies,
    });
    return companies;
  } catch (e) {
    dispatch({
      type: GET_COMPANIES_ERROR,
      payload: e,
    });
    throw e;
  }
};

export const createDemoCompanyAction = data => async dispatch => {
  dispatch({
    type: CREATE_COMPANY,
  });
  try {
    const { data: company } = await createDemoCompany(data);
    dispatch({
      type: CREATE_COMPANY_SUCCESS,
      payload: company,
    });
  } catch (e) {
    dispatch({
      type: CREATE_COMPANY_ERROR,
      payload: e,
    });
    throw e;
  }
};

export const createCompanyAction = data => async dispatch => {
  dispatch({
    type: CREATE_COMPANY,
  });
  try {
    const { data: company } = await fetchCreateCompany(data);
    dispatch({
      type: CREATE_COMPANY_SUCCESS,
      payload: company,
    });
    return;
  } catch (e) {
    dispatch({
      type: CREATE_COMPANY_ERROR,
      payload: e,
    });
    return e;
  }
};

export const updateCompanyAction = (data, id) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_COMPANY,
  });
  await axiosInstance.put(`api/company/${id}`, data);
  try {
    const {
      companies: { companies },
    } = getState();

    const updatedCompanies = companies.map(obj => {
      if (obj.id === data.id) {
        return { ...data };
      }

      return obj;
    });

    dispatch({
      type: UPDATE_COMPANY_SUCCESS,
      payload: updatedCompanies,
    });
  } catch (e) {
    dispatch({
      type: UPDATE_COMPANY_ERROR,
      payload: e,
    });
    throw e;
  }
};

export const updateDemoCompanyAction =
  (data, id) => async (dispatch, getState) => {
    dispatch({
      type: UPDATE_COMPANY,
    });
    await axiosInstance.put(`demo/companies/${id}`, data);
    try {
      const {
        companies: { companies },
      } = getState();

      const updatedCompanies = companies.map(obj => {
        if (obj.id === data.id) {
          return { ...data };
        }

        return obj;
      });

      dispatch(getDemoCompaniesAction());

      dispatch({
        type: UPDATE_COMPANY_SUCCESS,
        payload: updatedCompanies,
      });
    } catch (e) {
      dispatch({
        type: UPDATE_COMPANY_ERROR,
        payload: e,
      });
      throw e;
    }
  };
export const deleteCompanyAction = id => async dispatch => {
  dispatch({
    type: DELETE_COMPANY,
  });
  try {
    await fetchDeleteCompany(id);
    dispatch({
      type: DELETE_COMPANY_SUCCESS,
    });
    dispatch(getCompaniesAction());
  } catch (e) {
    dispatch({
      type: DELETE_COMPANY_ERROR,
      payload: e,
    });
    throw e;
  }
};

export const deleteDemoCompanyAction = id => async dispatch => {
  dispatch({
    type: DELETE_COMPANY,
  });
  try {
    await deleteDemoCompany(id);
    dispatch({
      type: DELETE_COMPANY_SUCCESS,
    });
    dispatch(getDemoCompaniesAction());
  } catch (e) {
    dispatch({
      type: DELETE_COMPANY_ERROR,
      payload: e,
    });
    throw e;
  }
};

export const createDemoFirstCompanyAction =
  () => async (dispatch, getState) => {
    try {
      const companies = await dispatch(getDemoCompaniesAction());

      if (companies.length < 1) {
        await dispatch(
          createDemoCompanyAction({
            title: "First company",
            country: "Poland",
          }),
        );

        const newRefresh = await refreshToken();
        const isRemember = localStorage.getItem("isRemember");

        setTokenData(newRefresh.data, isRemember === "true");
      }
    } catch (e) {
      toast.error(e.response.data.error || e.message);
    }
  };

export const createFirstCompanyAction = () => async (dispatch, getState) => {
  try {
    const {
      user: {
        user: { name, country },
      },
    } = getState();

    const companies = await dispatch(getCompaniesAction());

    if (companies.length < 1) {
      await dispatch(createCompanyAction({ title: name, country }));

      const newRefresh = await refreshToken();
      const isRemember = localStorage.getItem("isRemember");

      setTokenData(newRefresh.data, isRemember === "true");
    }
  } catch (e) {
    toast.error(e.response.data.error || e.message);
  }
};
