import { axiosInstance } from "@helpers/http/httpInstance";
export const getUser = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.get(`api/user`);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const getDemoUser = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.get(`demo/user`);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const updateUser = async data => {
  return await axiosInstance.put(`/api/user`, data);
};

export const updateDemoUser = async data => {
  return await axiosInstance.put(`/demo/user`, data);
};

export const postDemoFullUser = async () => {
  return await axiosInstance.post(`/demo/user/role`);
};

export const postUpdatePassword = async data => {
  return await axiosInstance.post(`/api/change-password`, data);
};

export const getCompanies = async () => {
  return await axiosInstance.get(`api/companies`);
};

export const getMinAmount = async country => {
  return await axiosInstance.get(`api/auctions/lot/min-amount/${country}`);
};

export const getTransaction = async transactionId => {
  return await axiosInstance.get(`api/transaction/${transactionId}`);
};

export const getActiveUsersByPeriod = async (from, to) => {
  return await axiosInstance.get(`/api/active-user`, {
    params: {
      start_time: from + "T00:00:00Z",
      end_time: to + "T00:00:00Z",
    },
  });
};

export const getNotifications = async () => {
  return await axiosInstance.get(`/api/notifications`);
};

export const postNotifications = async data => {
  return await axiosInstance.post(`/api/notification/sub`, data);
};

export const deleteNotifications = async data => {
  return await axiosInstance.delete(
    `/api/notification/unsub?message_type=${data}`,
  );
};

export const updateUserEmail = async email => {
  return await axiosInstance.post(`/api/user/email`, {
    email,
  });
};

export const confirmUserEmail = async code => {
  return await axiosInstance.post(`/api/user/confirm-email`, {
    code,
  });
};
