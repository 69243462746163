import { useState, useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

import {
  getPaymentSystemTransaction,
  getTransactionsHistoryByTypeByPeriod,
  getWallet,
} from "@services/api/wallet";

const TransactionCCWTable = ({ companySelect }) => {
  const { t } = useTranslation();

  const [isShowAll, setIsShowAll] = useState(false);
  const [dataArray, setDataArray] = useState([]);

  useEffect(() => {
    const identPay = (id_trans, transData) => {
      const ident = transData.find(trans => trans.transaction_id === id_trans);
      if (ident !== undefined) {
        return {
          details_tr: `${ident.type} ${ident.amount} ${ident.currency} ${moment(
            ident.updated_at,
          ).format("DD MMM YYYY HH:mm")} id:${ident.payment_id}`,
          company_name: ident.company.title,
          company_id: ident.company.id,
          country_name: ident.country.name,
        };
      } else return { details_tr: "not found or superAdmin transfer" };
    };

    let tempData = [];
    let tempCountry = [];
    let totalData = [];
    Promise.all([
      getTransactionsHistoryByTypeByPeriod(
        "income",
        new Date(null).toISOString(),
        new Date().toISOString(),
        true,
      ),
      getPaymentSystemTransaction(),
      getWallet(),
    ])
      .then(res => {
        //console.log('Transaction CCW', res[0].data)
        //console.log('Details CCW', res[1].data)
        //console.log('Wallet  >>>>>', res[2])
        if (res[0].data?.transactions && companySelect) {
          tempData = res[0].data.transactions.map(income => {
            return {
              id_tr: income.id,
              date_tr: income.created_at,
              summa_tr: Number(parseFloat(income.sum).toFixed(6)), // Определиться с количеством значащих 0??
              ...identPay(income.id, res[1].data),
            };
          });
          res[2]?.companies_countries_wallets?.forEach(wallet => {
            if (wallet.lots) {
              wallet.lots.forEach(lots => {
                //console.log(lots.budget, lots.created_at, wallet.company.title, wallet.country.name)
                tempData.push({
                  id_tr: lots.id,
                  date_tr: lots.created_at,
                  summa_tr: -Number(parseFloat(lots.budget).toFixed(6)),
                  company_name: wallet.company.title,
                  country_name: wallet.country.name,
                  company_id: wallet.company.id,
                  details_tr: `Hold to lot ${lots.id}`,
                });
              });
            }
          });
          //console.log('Transaction CCW unfiltered', tempData)
          tempData = tempData.filter(
            company => company.company_name === companySelect,
          );
          //console.log('Transaction CCW filter', tempData)
          tempCountry = tempData
            .filter(Boolean)
            .map(country => country.country_name);
          //console.log('Country CCW >>>', tempCountry)
          tempCountry = [...new Set(tempCountry)];
          //console.log('Country CCW >>>', tempCountry)
          tempCountry.forEach(country => {
            const filterData = tempData.filter(
              data => data.country_name === country,
            );
            filterData.sort((a, b) => a.date_tr.localeCompare(b.date_tr));
            let ball = 0;
            //console.log(filterData)
            const resData = filterData.map(data => {
              ball = ball + data.summa_tr;
              return { ...data, balance: ball };
            });
            resData.sort((a, b) => b.date_tr.localeCompare(a.date_tr));
            //console.log(resData)
            totalData = totalData.concat(resData);
          });
        }
        totalData.sort((a, b) => b.date_tr.localeCompare(a.date_tr));
        //console.log('TOTAL DATA >>>>>', totalData)
        setDataArray(totalData);
      })
      .catch(() => {
        toast.error("Error of get Data");
      });
  }, [companySelect]);

  return (
    <div className="transaction-wrapper">
      <div>
        <div className={`transaction-table mt-4`}>
          <div className="table-head tr-table-row">
            <div>{t("Date")}</div>
            <div>{t("Country")}</div>
            <div>{t("Credit")}</div>
            <div>{t("Debit")}</div>
            <div>{t("Balance")}</div>
            <div>{t("Cause")}</div>
          </div>
          <div className="table-body">
            {dataArray.length ? (
              dataArray.slice(0, isShowAll ? 10000 : 3).map((trans, index) => {
                return (
                  <div key={index} className="table-body__row tr-table-row">
                    <div>
                      {moment(trans.date_tr).format("DD MMM YYYY HH:mm")}
                    </div>
                    <div>{trans.country_name}</div>
                    <div className="green">
                      {Number.parseFloat(trans.summa_tr).toFixed(3) > 0 &&
                        Number.parseFloat(trans.summa_tr).toFixed(3)}
                    </div>
                    <div className="red">
                      {Number.parseFloat(trans.summa_tr).toFixed(3) <= 0 &&
                        Number.parseFloat(trans.summa_tr).toFixed(3)}
                    </div>
                    <div>{Number.parseFloat(trans.balance).toFixed(3)}</div>
                    <div>{trans.details_tr}</div>
                  </div>
                );
              })
            ) : (
              <div className="dont-transactions-block">
                {t("You haven't had any transactions yet")}
              </div>
            )}
          </div>
          {dataArray.length > 3 && (
            <div
              className="arrow-button"
              onClick={() => setIsShowAll(!isShowAll)}
            >
              {!isShowAll ? (
                  <FontAwesomeIcon icon={faSortDown} size={'2x'} style={{marginBottom: "8px", marginLeft: "1px"}}/>
              ) : (
                  <FontAwesomeIcon icon={faSortUp} size={'2x'} style={{marginTop: "8px", marginLeft: "1px"}}/>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionCCWTable;
