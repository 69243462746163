import React, { memo, useState, useCallback } from "react";
import { Grid, IconButton } from "@mui/material";
import s from "../index.module.scss";
import { Typography } from "../../../views/blocks";
import { useTranslation } from "react-i18next";
import CodeInput from "@components/Forms/components/CodeInput";
import { AuthButton } from "@components/Forms/components/ui";

import { useTimer } from "react-timer-hook";

import { registerUserAction } from "@redux/auth/actions";

import { toast } from "react-toastify";

import { activateAccountApi, resendRegisterApi } from "@services/api/auth";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Loader from "@components/Loader";
import { connect } from "react-redux";
import SuccessVerify from "@components/Forms/ActivateAccount/SuccessVerify";

import { signUpLeadEvent } from "@helpers/facebookPixelEvent";

const Timer = memo(({ onExpire, t, status, onResend }) => {
  const time = useCallback(seconds => {
    const result = new Date();
    result.setSeconds(result.getSeconds() + seconds);
    return result;
  }, []);

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: time(600),
    onExpire: onExpire,
  });

  const handleResend = useCallback(async () => {
    const approval = await onResend();

    approval && restart(time(600));
  }, [onResend]);

  return (
    <React.Fragment>
      <Grid item mt={4}>
        <Typography
          variant="caption1"
          color="primary"
          className="pointer hover-opacity"
          weight="normal-bold"
          onClick={handleResend}
        >
          {t("Didn`t get a verification code")}
        </Typography>
      </Grid>
      <Grid item mt={6}>
        <Typography variant="caption1" color="gray">
          {t("Code validity")}:
        </Typography>
        {status === "disabled" ? (
          <Typography
            variant="body2"
            weight="normal-bold"
            color="primary"
            className="pointer hover-opacity"
            onClick={handleResend}
          >
            {t("Send again")}
          </Typography>
        ) : (
          <Typography variant="body1" weight="normal-bold">
            {minutes < 10 ? `0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </Typography>
        )}
      </Grid>
    </React.Fragment>
  );
});

const ActivateAccount = ({
  authData,
  setStage,
  STAGES,
  registerUserAction,
}) => {
  const { email, password } = authData;

  const { t } = useTranslation();

  const [code, setCode] = useState("");

  const [status, setStatus] = useState("");

  const [resubmissions, setResubmissions] = useState(3);

  const handleChange = useCallback(
    code => {
      setCode(code);

      status && setStatus("");
    },
    [status],
  );

  const onExpire = () => setStatus("disabled");

  const handleResendCode = useCallback(async () => {
    if (resubmissions === 0) {
      toast.warning(t("The number of code resubmissions is limited!"));
      return false;
    }

    try {
      await resendRegisterApi({ email });

      toast.success(t("Code resubmitted"));

      setResubmissions(resubmissions - 1);

      status === "disabled" && setStatus("");

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }, [resubmissions, status, t, email]);

  const registration = useCallback(async () => {
    setTimeout(() => setStatus("finish"), 1500);

    try {
      signUpLeadEvent();

      setTimeout(async () => {
        await registerUserAction({
          email,
          password,
        });
      }, 3000);
    } catch (e) {
      toast(t(e?.response?.data?.error || e?.response?.message || e));
      console.error(e);
    }
  }, [email, password, t, registerUserAction, signUpLeadEvent]);

  const handleSubmit = useCallback(async () => {
    setStatus("loading");

    try {
      await activateAccountApi({
        code,
        email,
      });

      setStatus("success");
    } catch (e) {
      toast.error(t("Incorrect Verification Code"));
      return setStatus("error");
    }

    await registration();
  }, [email, code, t, registration]);

  return (
    <Grid className={s.form}>
      {status === "finish" ? (
        <SuccessVerify />
      ) : (
        <React.Fragment>
          <Grid item className={s.back}>
            <IconButton
              className={s.back__button}
              onClick={() => setStage(STAGES.SIGN_UP)}
            >
              <ArrowBackIosNewIcon htmlColor="var(--primary-color)" />
            </IconButton>
            <Typography variant="h2" bold>
              {t("Join to INFOCOIN!")}
            </Typography>
          </Grid>
          <Grid item mt={8}>
            <CodeInput
              onChange={handleChange}
              value={code}
              label="Two-Factor Authentication"
              status={status}
            />
          </Grid>
          <Grid item>
            <Typography variant="caption2" color="gray">
              {t(
                "A message with a verification code has been send to your email.",
              )}
            </Typography>
            <Typography variant="caption2" color="gray">
              {t("Enter the code to continue.")}
            </Typography>
          </Grid>

          <Timer
            t={t}
            onExpire={onExpire}
            status={status}
            onResend={handleResendCode}
          />

          <Grid item mt={8}>
            <AuthButton
              disabled={
                status === "disabled" ||
                status === "success" ||
                code.length < 4 ||
                status === "loading"
              }
              fullWidth
              onClick={handleSubmit}
            >
              {status === "loading" ? (
                <Loader color="white" width={31.5} height={31.5} />
              ) : (
                t("Register account")
              )}
            </AuthButton>
          </Grid>

          <Grid item mt={2}>
            <Typography variant="body2" align="center">
              {t("Already have an account?")}
              <Typography
                variant="body2"
                weight="normal-bold"
                color="primary"
                className="pointer hover-opacity"
                component="span"
                onClick={() => setStage(STAGES.SIGN_IN)}
              >
                &nbsp;
                {t("Sign In")}
              </Typography>
            </Typography>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

export default connect(null, { registerUserAction })(memo(ActivateAccount));
