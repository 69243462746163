//react
import React, { useCallback, useState, memo } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

//api
import { resendRegisterApi } from "@services/api/auth";

//actions
import { loginUserAction } from "@redux/auth/actions";

//components
import Loader from "@components/Loader";
import { Typography } from "../../../views/blocks";
import Input from "../components/Input";
import { EyeLineIcon, EyeOffLineIcon } from "views/icons";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import {
  AuthSwitch,
  AuthFormControlLabel,
  AuthButton,
} from "@components/Forms/components/ui";
import AuthCheckboxDocuments from "@components/Forms/components/CheckboxDocuments";

//style
import s from "../index.module.scss";

//other
import { useFormik } from "formik";
import { LoginSchema } from "@helpers/Formik/validation";

import { toast } from "react-toastify";

const SignIn = ({
  setStage,
  STAGES,
  setAuthData,
  loginUserAction,
  authData,
}) => {
  const { t } = useTranslation();

  const {
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    handleSubmit,
    isValid,
    isSubmitting,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      email: authData.email || "",
      password: authData.password || "",
      remember: true,
      acceptPrivacy: true,
      acceptAgreement: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const { email, password, remember } = values;

      setSubmitting(true);

      const result = await loginUserAction({
        email,
        password,
        remember,
      });

      if (!result.error) {
        return setSubmitting(false);
      }

      const { error } = result;

      console.log(error);

      if (error === "user not activated") {
        try {
          await resendRegisterApi({ email });

          setAuthData(data => ({
            ...data,
            email,
            password,
          }));

          toast.warning(t("Check your email"));

          setStage(STAGES.AUTH);
        } catch (e) {
          console.error(error);
        }
      }

      if (error.includes("record not found")) {
        setAuthData(data => ({
          ...data,
          email,
          password,
        }));
        // return setStage(STAGES.SIGN_UP);
      }

      setSubmitting(false);
    },
  });

  const handleSwitchRemember = useCallback(e => {
    setFieldValue("remember", e.target.checked);
  }, []);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() =>
    setShowPassword(show => !show),
  );

  const handleMouseDownPassword = useCallback(event => event.preventDefault());

  return (
    <Grid component="form" className={s.form}>
      <Grid item>
        <Typography variant="h2" bold>
          {t("Welcome to INFOCOIN!")}
        </Typography>
      </Grid>
      <Grid item mt={6}>
        <Input
          name="email"
          type="email"
          label={t("Email")}
          onChange={handleChange}
          value={values.email}
          error={t(errors.email)}
          onBlur={handleBlur}
          touched={touched.email}
        />
      </Grid>
      <Grid item mt={2}>
        <Input
          name="password"
          type={showPassword ? "text" : "password"}
          label={t("Password")}
          onChange={handleChange}
          value={values.password}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? (
                  <EyeLineIcon size="medsmall" />
                ) : (
                  <EyeOffLineIcon size="medsmall" />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </Grid>
      <Grid
        item
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={1}
      >
        <Grid>
          <AuthFormControlLabel
            control={
              <AuthSwitch
                inputProps={{ "aria-label": "controlled" }}
                checked={values.remember}
                onChange={handleSwitchRemember}
              />
            }
            label={t("Remember me")}
          />
        </Grid>
        <Grid>
          <Typography
            onClick={() => setStage(STAGES.RESET)}
            className={`${s.form__forgot} pointer hover-opacity`}
          >
            {t("Forgot password?")}
          </Typography>
        </Grid>
      </Grid>
      <AuthCheckboxDocuments
        onChange={setFieldValue}
        values={{
          acceptAgreement: values.acceptAgreement,
          acceptPrivacy: values.acceptPrivacy,
        }}
        errors={{
          acceptAgreement: errors.acceptAgreement,
          acceptPrivacy: errors.acceptPrivacy,
        }}
      />
      <Grid item mt={3}>
        <AuthButton
          fullWidth
          type="submit"
          size="large"
          onClick={handleSubmit}
          disabled={!isValid || isSubmitting}
        >
          {isSubmitting ? (
            <Loader color="white" width={31.5} height={31.5} />
          ) : (
            t("Sign in to account")
          )}
        </AuthButton>
      </Grid>
      <Grid item mt={2}>
        <Typography variant="body2" align="center">
          {t("Don`t have an account?")}
          <Typography
            variant="body2"
            weight="normal-bold"
            color="primary"
            className="pointer hover-opacity"
            component="span"
            onClick={() => setStage(STAGES.SIGN_UP)}
          >
            &nbsp;
            {t("Sign Up")}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default connect(null, { loginUserAction })(memo(SignIn));
