import React, {Children} from "react";

import "./index.scss";


const InputGroup = (props) => {
    const {
        label,
        children
    } = props;

    return (
        <div className="input-group">
            {label && (
                <div className="input-group__label">
                    {label}
                </div>
            )}

            <div className="input-group__wrapper">
                {Children.map(children, (child, index) => {
                    return (
                        <React.Fragment key={index}>
                            {index > 0 && (
                                <div className="input-group__divider" />
                            )}

                            {child}
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};


export {InputGroup};