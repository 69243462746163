import styled from "styled-components";

export const Styles = styled.div`
  display: block;
  overflow: auto;
  .content-status {
    font-weight: bold;
    color: #6daa50;
  }

  .table {
    font-family: "Inter", sans-serif;
    border-spacing: 0;
    border-radius: 10px;
    overflow-y: hidden;
    .ps__rail-x {
      border-top: 0;
    }
    .thead {
      @media (max-width: 1725px) {
        width: 1200px !important;
      }
      position: relative;
      z-index: 3;

      .th {
        width: unset;
        padding: 0 !important;
      }
    }

    .tbody {
      border-top: 0;
      margin-top: -10px;
      font-size: 11px;
      width: 100%;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      @media (max-width: 1725px) {
        width: 1200px !important;
      }
      .tr {
        background-color: #f9fafc;
        border-bottom: 1px solid #e2e2ea;

        :first-child {
          padding-top: 10px !important;
        }

        :last-child {
          .td {
            border-bottom: 0;
          }
        }
      }
    }

    .headRow {
      font-weight: 500;
      border-radius: 10px;
      background-color: #e2e2ea;
      white-space: nowrap;
      padding: 16px 0 13px;
      color: #9596a2;
      font-size: 12px;
      width: 100%;
    }

    .td {
      margin: 0;
      text-align: center;
      padding: 11px 5px 7px !important;
      position: relative;
      height: 83px;
      width: unset;
      overflow: hidden;
      white-space: pre-wrap;
      text-overflow: ellipsis;
      :first-child {
        padding-top: 20px;
      }

      :last-child {
        border-right: 0;
      }
    }
  }
`;
