import { styled } from "@mui/material/styles";
import {
  InputBase,
  InputLabel,
  Switch,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";

export const AuthInput = styled(InputBase)(() => ({
  "& .MuiInputAdornment-root": {
    position: "absolute",
    right: 15,
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "white",
    border: "1px solid #e2e2ea",
    fontSize: 16,
    width: "100%",
    padding: "13px 10px",
    "&:focus": {
      borderColor: "#819bea",
      boxShadow: "0 1px 4px 0 #819bea",
    },
  },
}));

export const AuthLabel = styled(InputLabel)(() => ({
  "&.MuiInputLabel-root": {
    position: "static",
    fontSize: 15,
    transform: "none",
    fontWeight: 500,
    color: "#9596a2",
    marginBottom: 6,
  },
  "& .Mui-focused": {
    color: "#9596a2",
  },
}));

export const AuthSwitch = styled(Switch)(() => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "white",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "var(--primary-color)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#9596a2",
    boxSizing: "border-box",
  },
  ".MuiFormControlLabel-label": {
    color: "gray",
    fontSize: 14,
  },
}));

export const AuthFormControlLabel = styled(FormControlLabel)(() => ({
  marginLeft: "0",
  marginRight: "0",
  "& .MuiFormControlLabel-label": {
    fontSize: 13,
    color: "#9596a2",
    marginLeft: "1rem",
  },
}));

export const AuthCheckbox = styled(Checkbox)(() => ({
  color: "var(--primary-color)",
  "&.Mui-checked": {
    color: "var(--primary-color)",
  },
}));

export const AuthButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "var(--primary-color)",
  textTransform: "none",
  fontSize: 18,
  fontWeight: 500,
  borderRadius: 5,
  "@media (max-width: 426px)": {
    fontSize: 16,
    lineHeight: 1.95,
  },
  "&:hover": {
    backgroundColor: "var(--primary-color)",
    opacity: 0.9,
  },
  "&.Mui-disabled": {
    background: "#9596a2",
    color: "white",
    cursor: "not-allowed !important",
  },
}));
