export const userCountrySelector = ({
    user: {
      user: { country },
    },
}) => country;

export const userSelector = ({
                                      user: {
                                        user,
                                      },
                                    }) => user;
