import React, { memo, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
  getCompaniesAction,
  getDemoCompaniesAction,
} from "@redux/companies/actions";
import Select from "@components/Form/Select";

import { useSelector } from "react-redux";

const CompanySelect = ({
  placeholder,
  littleLabel,
  label,
  name,
  value,
  onChange,
  handleBlur,
  error,
  touched,
  companies,
  getCompaniesAction,
}) => {
  const role = useSelector(state => state.user.user.role);
  useEffect(() => {
    if (companies.length < 1) {
      if (role === "demo_user") {
        getDemoCompaniesAction();
      } else {
        getCompaniesAction();
      }
    }
    //eslint-disable-next-line
  }, [companies]);

  const options = useMemo(
    () =>
      companies.map(company => ({
        value: company.id,
        label: company.title,
      })),
    [companies],
  );
  return (
    <Select
      littleLabel={littleLabel}
      placeholder={placeholder}
      label={label}
      name={name}
      translateOptions={false}
      value={value}
      onChange={onChange}
      handleBlur={handleBlur}
      error={error}
      touched={touched}
      options={options}
    />
  );
};

const mapStateToProps = ({ companies }) => ({
  companies: companies.companies,
});
export default connect(mapStateToProps, {
  getCompaniesAction,
})(memo(CompanySelect));
