import React, { memo, useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { InviteSchema } from "@helpers/Formik/validation";
import Loader from "@components/Loader";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { toast } from "react-toastify";

import { InviteAPI } from "@services/api/auth";

import s from "../index.module.scss";

import { loginDemoUserAction } from "@redux/auth/actions";

import { Grid, IconButton, InputAdornment, Tooltip } from "@mui/material";
import { Typography } from "../../../views/blocks";
import Input from "@components/Forms/components/Input";
import { EyeLineIcon, EyeOffLineIcon } from "../../../views/icons";
import { AuthButton } from "@components/Forms/components/ui";

const Invite = ({ loginDemoUserAction, defaultValue }) => {
  const { t } = useTranslation();


  const {
    values,
    handleChange,
    handleSubmit,
    isValid,
    isSubmitting,
    handleBlur,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      email: defaultValue.email,
      password: "",
      confirmPassword: "",
      code: defaultValue.code,
    },
    validationSchema: InviteSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const { email, password } = values;

      try {
        await InviteAPI(values);

        await loginDemoUserAction({ email, password });
      } catch (e) {
        toast.error(t(e?.response?.data?.error || e?.response?.message || e));
        console.error(e);
      }

      setSubmitting(false);
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() =>
    setShowPassword(show => !show),
  );

  const handleMouseDown = useCallback(event => event.preventDefault());

  return (
    <Grid className={s.form} component="form">
      <Grid item>
        <Typography variant="h2" bold>
          {t("Invite to INFOCOIN!")}
        </Typography>
      </Grid>
      <Grid item mt={6}>
        <Input
          name="code"
          type="text"
          label={t("Code")}
          onChange={handleChange}
          value={values.code}
          error={t(errors.code)}
          onBlur={handleBlur}
          touched={touched.code}
        />
      </Grid>
      <Grid item mt={2}>
        <Input
          name="password"
          type={showPassword ? "text" : "password"}
          label={t("Password")}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDown}
                edge="end"
              >
                {showPassword ? (
                  <EyeLineIcon size="medsmall" />
                ) : (
                  <EyeOffLineIcon size="medsmall" />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <Typography
          variant="caption2"
          color={touched.password && errors.password ? "error" : "gray"}
          className="mt-1"
        >
          {t(
            "The password must consist of: at least 8 characters, a lowercase character, an uppercase character, a number and a special character (!,@,#,$,% etc.)",
          )}
        </Typography>
      </Grid>
      <Grid item mt={2}>
        <Input
          name="confirmPassword"
          type={showPassword ? "text" : "password"}
          label={t("Confirm password")}
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.confirmPassword}
          error={t(errors.confirmPassword)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDown}
                edge="end"
              >
                {showPassword ? (
                  <EyeLineIcon size="medsmall" />
                ) : (
                  <EyeOffLineIcon size="medsmall" />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item mt={3}>
        <AuthButton
          fullWidth
          type="submit"
          size="large"
          onClick={handleSubmit}
          disabled={!isValid || isSubmitting}
        >
          {isSubmitting ? (
            <Loader color="white" width={31.5} height={31.5} />
          ) : (
            t("Submit")
          )}
        </AuthButton>
      </Grid>
    </Grid>
  );
};

export default connect(null, { loginDemoUserAction })(memo(Invite));
