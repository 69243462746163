import { axiosInstance } from "@helpers/http/httpInstance";
import { getTokenData } from "@utils/tokens";

export const loginAPI = ({ password, email }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post(`auth/sign-in`, {
        email,
        password,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const signUpAPI = data => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post(`auth/register`, data);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const InviteAPI = data => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post(`auth/register/invite`, data);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const activateAccountApi = data => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post(`auth/sign-up`, data, {
        headers: new Headers({ "Content-Type": "application/json" }),
      });
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const getUserActivatedStatus = email => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.post(
        `auth/user/status`,
        {
          email,
        },
        {
          headers: new Headers({ "Content-Type": "application/json" }),
        },
      );
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const resetUserApi = data => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.post(`auth/reset-password`, data);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const resendRegisterApi = email => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.post(`auth/temp-code`, email);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const setNewPasswordApi = data => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.post(`auth/new-password`, data);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const refreshToken = async () => {
  const { refreshToken } = getTokenData();
  return axiosInstance.post(`auth/refresh-token`, {
    refresh_token: refreshToken,
  });
};

export const signOut = async session_id => {
  return axiosInstance.post(`api/sign-out`, { session_id });
};

export const getSessions = async () => {
  return axiosInstance.get("/api/sessions");
};
