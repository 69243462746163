export const introIds = {
  companies: {
    element1: "companies-first-company",
    element2: "companies-create-company",
  },
  company: {
    element1: "company-form-switch",
    element2: "company-form-header",
    element3: "company-form-avatar",
    element4: "company-form-name",
    element5: "company-form-description",
    element6: "company-form-country",
    element7: "company-form-edrpou",
    element8: "company-form-vat",
    element9: "company-form-person",
  },
  wallet: {
    element1: "wallet-select-company",
    element2: "wallet-select-country",
    element3: "wallet-field-euro",
    element4: "wallet-field-ic",
    element5: "wallet-select-payment",
    element6: "wallet-button-deposit",
  },
  content: {
    element1: "content-button-upload-video",
  },
};
