import { axiosInstance } from "@helpers/http/httpInstance";
import { prepareAxiosError } from "@utils/prepareAxiosError";

export const getVideos = async (limit, offset) => {
  return await axiosInstance.get(`api/videos`, {
    params: {
      limit,
      offset,
    },
  });
};

export const getDemoVideos = async () => {
  return await axiosInstance.get(`demo/demo-videos`);
};

export const createVideo = async data => {
  try {
    const { age_min, age_max, ...otherData } = data;

    const res = await axiosInstance.post(
      `/api/video`,
      {
        age_min: parseInt(age_min) || 0,
        age_max: parseInt(age_max) || 0,
        ...otherData,
      },
      {
        validateStatus: () => true,
      },
    );

    if ([200, 201].includes(res.status)) {
      return {
        status: "OK",
        data: res.data,
      };
    }

    return prepareAxiosError(res);
  } catch (err) {
    return {
      status: "ERROR",
      message: err.message,
    };
  }
};

export const updateVideo = async (data, id) => {
  return await axiosInstance.put(`api/video/${id}`, data);
};

export const updateDemoVideo = async (data, id) => {
  return await axiosInstance.put(`/demo/demo-videos/${id}`, data);
};

export const deleteVideo = async id => {
  return await axiosInstance.delete(`api/video/${id}`);
};

export const delDeleteVideo = async id => {
  return await axiosInstance.delete(`api/video/${id}`);
};

export const DemoDeleteVideo = async id => {
  return await axiosInstance.delete(`demo/demo-videos/${id}`);
};

export const putSentVerifedVideo = async (id, data) => {
  return await axiosInstance.put(`api/video/${id}`, data);
};

export const putDemoSentVerifedVideo = async (id, data) => {
  return await axiosInstance.put(`demo/demo-videos/${id}`, data);
};
