import React, { useEffect, useMemo, memo, useState, useCallback } from "react";

import Select from "react-select";

import { Grid } from "@mui/material";

import { AuthFormControlLabel } from "../ui";

import { useTranslation } from "react-i18next";

import "./index.scss";
import clsx from "clsx";
import { Typography } from "../../../../views/blocks";

const DateSelect = ({
  onChange,
  label,
  value,
  touched,
  name = "",
  onBlur,
  error,
}) => {
  const { t } = useTranslation();

  const monthOptions = useMemo(
    () => [
      {
        label: t("January"),
        value: 1,
      },
      {
        label: t("February"),
        value: 2,
      },
      {
        label: t("March"),
        value: 3,
      },
      {
        label: t("April"),
        value: 4,
      },
      {
        label: t("May"),
        value: 5,
      },
      {
        label: t("June"),
        value: 6,
      },
      {
        label: t("July"),
        value: 7,
      },
      {
        label: t("August"),
        value: 8,
      },
      {
        label: t("September"),
        value: 9,
      },
      {
        label: t("October"),
        value: 10,
      },
      {
        label: t("November"),
        value: 11,
      },
      {
        label: t("December"),
        value: 12,
      },
    ],
    [t],
  );

  const [focus, setFocus] = useState(false);

  const [date, setDate] = useState({
    day: value ? new Date(value).getDate() : null,
    month: value ? new Date(value).getMonth() + 1 : null,
    year: value ? new Date(value).getFullYear() : null,
  });

  const handleChange = useCallback(
    (type, value) => {
      const result = { ...date, [type]: value };

      setDate(result);

      const { year, month, day } = result;

      onChange(new Date(year, month, day, 12, 0, 0).toISOString());
    },
    [date],
  );

  function daysInMonth(date) {
    return new Date(
      new Date(date).getFullYear(),
      new Date(date).getMonth() + 1,
      0,
    ).getDate();
  }

  const yearOptions = useMemo(() => {
    const years = [];
    for (let i = new Date().getFullYear(); i >= 1920; i--) {
      years.push({
        label: i,
        value: i,
      });
    }
    return years;
  }, []);

  const daysOptions = useMemo(() => {
    const days = [];
    if (date) {
      const numberOfDays = daysInMonth(date) || 31;
      for (let i = 1; i <= numberOfDays; i++) {
        days.push({
          label: i,
          value: i,
        });
      }
    }
    return days;
  }, [date]);

  const modificationsContainer = clsx("auth-date-group__container", {
    "auth-date-group__container--is-focused": focus,
  });

  const handleFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocus(false);

    onBlur();
  }, []);

  const noOptionsMessage = useCallback(() => {
    return t("Not found");
  }, [t]);

  return (
    <Grid className="auth-date-group">
      {label && <label className="auth-date-group__label">{label}</label>}
      <Grid className={modificationsContainer}>
        <Select
          value={daysOptions.find(option => option.value === date.day) || ""}
          className="auth-date-group__select"
          classNamePrefix="auth-date-group__select"
          onFocus={handleFocus}
          onBlur={handleBlur}
          noOptionsMessage={noOptionsMessage}
          options={daysOptions}
          isSearchable={false}
          placeholder=""
          onChange={({ value }) => handleChange("day", value)}
          name={name}
        />
        <Select
          value={monthOptions.find(option => option.value === date.month) || ""}
          className="auth-date-group__select"
          classNamePrefix="auth-date-group__select"
          onFocus={handleFocus}
          onBlur={handleBlur}
          isSearchable={false}
          options={monthOptions}
          noOptionsMessage={noOptionsMessage}
          placeholder=""
          onChange={({ value }) => handleChange("month", value)}
          name={name}
        />
        <Select
          value={yearOptions.find(option => option.value === date.year) || ""}
          className="auth-date-group__select"
          classNamePrefix="auth-date-group__select"
          onFocus={handleFocus}
          onBlur={handleBlur}
          options={yearOptions}
          isSearchable={false}
          noOptionsMessage={noOptionsMessage}
          placeholder=""
          onChange={({ value }) => handleChange("year", value)}
          name={name}
        />
      </Grid>
      {touched && error && (
        <Typography variant="caption2" color="error">
          {error}
        </Typography>
      )}
    </Grid>
  );
};

export default memo(DateSelect);
