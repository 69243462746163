import React, {useRef, useState} from "react";
import {useFlexLayout, useSortBy, useTable} from "react-table";
import clsx from "clsx";
import {Styles} from "src/components/VideosTable/styles";
import {useTranslation} from "react-i18next";
import ScrollBar from "../../views/blocks/ScrollBar";
import TableSortLabel from "@mui/material/TableSortLabel";

const headerProps = myProps => props => {
    return getStyles(props, myProps);
};

const cellProps = myProps => props => getStyles(props, myProps);

const getStyles = (props, myProps) => [
    props,
    {
        title: undefined,
        style: {
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            ...myProps,
        },
    },
];

function Table({columns, videos, height}) {
    const data = React.useMemo(() => videos, [videos]);
    const columnsData = React.useMemo(() => columns, [columns]);

    const tableRef = useRef();
    const rowRef = useRef();

    const [isActive, setIsActive] = useState("");

    const {t} = useTranslation();

    const handleToggleSort = React.useCallback(
        id => {
            if (id === isActive) {
                setIsActive("");
            } else {
                setIsActive(id);
            }
        },
        [setIsActive, isActive],
    );

    const {getTableProps, headerGroups, rows, prepareRow} = useTable(
        {
            columns: columnsData,
            data,
        },
        useFlexLayout,
        useSortBy,
    );

    return (
        <Styles>
            <ScrollBar
                ref={tableRef}
                className="table"
                {...getTableProps()}
                options={{
                    suppressScrollY: true,
                    suppressScrollX: window.innerWidth > 1725,
                }}
            >
                <div className="thead">
                    {headerGroups.map(headerGroup => (
                        <div
                            {...headerGroup.getHeaderGroupProps({title: undefined})}
                            ref={rowRef}
                            className={clsx("tr headRow")}
                        >
                            {headerGroup.headers.map(column => {
                                const style = {
                                    flexShrink: 1,
                                    flexGrow: 1,
                                    flexBasis: "120px",
                                };
                                if (column.isSmall) {
                                    style.flexBasis = column.flexWidth;
                                    style.flexGrow = 0;
                                }
                                if (column.isFullColumn) {
                                    style.flexBasis = column.flexWidth;
                                    style.flexShrink = 0;
                                }

                                return (
                                    <div
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps(headerProps(style)),
                                        )}
                                        className="th"
                                    >
                                        <TableSortLabel
                                            sx={{marginLeft: "24px"}}
                                            onClick={() => handleToggleSort(column.id)}
                                            active={column.id === isActive}
                                            direction={column.id === isActive ? "desc" : "asc"}
                                        >
                                            {typeof column.Header === "string"
                                                ? t(column.Header)
                                                : column.render("Header")}
                                        </TableSortLabel>
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
                {rows.length > 0 ? (
                    <ScrollBar
                        style={{
                            height: height || data.length * 83 + 14,
                            maxHeight: "55vh",
                            borderTop: 0,
                            paddingRight: 0,
                        }}
                        className={clsx("tbody")}
                        options={{
                            suppressScrollX: window.innerWidth <= 1725,
                        }}
                    >
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <div {...row.getRowProps()} className="tr">
                                    {row.cells.map(cell => {
                                        const style = {
                                            flexShrink: 1,
                                            flexGrow: 1,
                                            flexBasis: "120px",
                                        };
                                        if (cell.column.isSmall) {
                                            style.flexBasis = cell.column.flexWidth;
                                            style.flexGrow = 0;
                                        }
                                        if (cell.column.isFullColumn) {
                                            style.flexBasis = cell.column.flexWidth;
                                            style.flexShrink = 0;
                                        }
                                        return (
                                            <div
                                                {...cell.getCellProps(cellProps(style))}
                                                className="td"
                                            >
                                                {cell.render("Cell")}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </ScrollBar>
                ) : (
                    <div
                        className={clsx("tbody")}
                        style={{
                            padding: "40px",
                            display: "flex",
                            justifyContent: "center",
                            borderTop: "none",
                            alignItems: "center",
                            fontWeight: "bold",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            marginTop: "-10px",
                            height: height || "45vh",
                        }}
                    >
                        {t("No items")}
                    </div>
                )}
            </ScrollBar>
        </Styles>
    );
}

export default Table;
