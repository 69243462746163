import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { TextareaAutosize } from "@mui/material";

import "./index.scss";


const Textarea = (props) => {
    const {
        variant,
        label,
        warningLabel = false,
        fullWidth = false,
        error = false,
        helperText,
        ...rest
    } = props;

    const { t } = useTranslation();

    const modifications = clsx({
        "textarea--error": error,
        "textarea--full-width": fullWidth,
        [`textarea--variant-${variant}`]: !!variant
    });

    return (
        <div className={`textarea ${modifications}`}>
            {label && (
                <div className={`textarea__label ${warningLabel ? 'textarea__label--warrning' : ''}`}>
                    {t(label)}
                </div>
            )}

            <TextareaAutosize
                {...rest}
                className="textarea__input" />

            {error && helperText && (
                <div className="red-error">
                    {helperText}
                </div>
            )}
        </div>
    );
};


export { Textarea };