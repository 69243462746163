import Logo from "@assets/images/INFOCOIN_LIMITED.png";
import call from "@assets/images/call.png";
import mail from "@assets/images/mail.png";
import place from "@assets/images/place.png";
import footer from "@assets/images/footterPDF.png";
import MontBlanc from "@assets/fonts/MontBlanc/MONT-BLANC-REGULAR.OTF";
import MontBlancB from "@assets/fonts/MontBlanc/MONT-BLANC-BOLD.OTF";
import MontBlancS from "@assets/fonts/MontBlanc/MONT-BLANC-SEMIBOLD.OTF";
import MontBlancL from "@assets/fonts/MontBlanc/MONT-BLANC-LIGHT.OTF";
import { Document, Page, Text, View, Font, Image } from "@react-pdf/renderer";
import moment from "moment";
import { styles } from "./styles";

Font.register({
  family: "MontBlanc",
  src: MontBlanc,
  fonts: [
    { src: MontBlanc },
    { src: MontBlancS, fontWeight: 500 },
    { src: MontBlancB, fontWeight: "bold" },
    { src: MontBlancS, fontWeight: 500 },
    { src: MontBlancL, fontWeight: "light" },
  ],
});

const MyDocument = ({ user, company, amount, tax, invoiceId, bankInfo }) => {
  return (
    <Document creator={"Infocoin limited"}>
      <Page size="A4" style={styles.page}>
        <View
          style={[
            styles.section,
            { alignItems: "flex-start", marginBottom: "20px" },
          ]}
        >
          <Image style={styles.logo} src={Logo} />
          <View style={styles.topRightSection}>
            <View style={styles.contactItem}>
              <Text style={styles.secondText}>
                {bankInfo.phone || "+3577-778-88-51"}
              </Text>
              <View style={styles.contactImgContainer}>
                <Image style={styles.contactImg} src={call} />
              </View>
            </View>
            <View style={[styles.contactItem, { marginTop: "8px" }]}>
              <Text style={styles.secondText}>
                {bankInfo.email || "info@infocoin.online"}
              </Text>
              <View style={styles.contactImgContainer}>
                <Image style={styles.contactImg} src={mail} />
              </View>
            </View>
            <View style={[styles.contactItem, { marginTop: "8px" }]}>
              <View
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  flexDirection: "column",
                }}
              >
                <Text style={styles.secondText}>
                  {bankInfo.company_name || "INFOCOIN LIMITED"}
                </Text>
                <Text style={styles.secondText}>
                  {bankInfo.legal_address ||
                    "Anexartisias & Athinon, NORA COURT 2nd floor, Flat/Office 203, 3040 Limassol, Cyprus"}
                </Text>
                <Text style={styles.secondText}>
                  Registration Number: {bankInfo.registration_number}
                </Text>
                <Text style={styles.secondText}>
                  VAT Number: {bankInfo.vat_number}
                </Text>
              </View>
              <View style={styles.contactImgContainer}>
                <Image
                  style={{
                    height: "15px",
                  }}
                  src={place}
                />
              </View>
            </View>
          </View>
        </View>

        <View
          style={[styles.tableHeader, { width: "200px", marginLeft: "auto" }]}
        >
          <Text style={[styles.rightSmallTableHead]}>INVOICE#</Text>
          <Text style={styles.rightSmallTableHead}>DATE</Text>
          <Text
            style={[
              styles.rightSmallTableHead,
              { color: "black", backgroundColor: "white" },
            ]}
          >
            {invoiceId}
          </Text>
          <Text
            style={[
              styles.rightSmallTableHead,
              { color: "black", backgroundColor: "white" },
            ]}
          >
            {moment().format("MM[/]DD[/]YYYY")}
          </Text>
        </View>
        <View
          style={[styles.section, { alignItems: "unset", marginTop: "20px" }]}
        >
          <View style={{ flexGrow: 1, marginTop: "-40px" }}>
            <Text style={styles.billHead}>BILL TO</Text>
            {company.vat_number && (
              <Text style={styles.smallText}>{company.vat_number}</Text>
            )}
            {company.itn_edrpou && (
              <Text style={styles.smallText}>{company.itn_edrpou}</Text>
            )}
            <Text style={styles.smallText}>{company.title}</Text>
            {company.legal_address && (
              <Text style={styles.smallText}>{company.legal_address}</Text>
            )}
            <Text style={styles.smallText}>{`${user.city && user.city + ", "}${
              user.region && user.region + ", "
            }${user.country}`}</Text>
            <Text style={styles.smallText}>{user.phone}</Text>
            <Text style={styles.smallText}>{user.email}</Text>
          </View>
          <View>
            <View style={[styles.tableHeader]}>
              <Text
                style={[
                  styles.rightSmallTableHead,
                  { borderTopLeftRadius: "10px" },
                ]}
              >
                CUSTOMER ID
              </Text>
              <Text style={styles.rightSmallTableHead}>TERMS</Text>
              <Text
                style={[
                  styles.rightSmallTableHead,
                  { color: "black", backgroundColor: "white" },
                ]}
              >
                {user.id}
              </Text>
              <Text
                style={[
                  styles.rightSmallTableHead,
                  { color: "black", backgroundColor: "white" },
                ]}
              >
                Due Upon Receipt
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.mainTableHead}>
          <Text
            style={[
              styles.mainTableHeadCell,
              [{ textAlign: "left", width: "270px", paddingLeft: "5px" }],
            ]}
          >
            Description
          </Text>
          <Text style={[styles.mainTableHeadCell, { width: "45px" }]}>QTY</Text>
          <Text style={[styles.mainTableHeadCell, { width: "77px" }]}>
            UNIT PRICE
          </Text>
          <Text style={[styles.mainTableHeadCell, { flexGrow: 1 }]}>
            AMOUNT
          </Text>
        </View>
        <View style={styles.row}>
          <View
            style={[
              styles.mainTableHeadCell,
              styles.dataCell,
              { width: "270px", paddingLeft: "5px", textAlign: "left" },
            ]}
          >
            <Text>Information services in accordance with</Text>
            <Text>PUBLIC AGREEMENT (PROPOSAL)</Text>
          </View>
          <Text
            style={[
              styles.mainTableHeadCell,
              styles.dataCell,
              { width: "45px" },
            ]}
          >
            1
          </Text>
          <Text
            style={[
              styles.mainTableHeadCell,
              styles.dataCell,
              { width: "77px" },
            ]}
          >
            {Number(amount).toFixed(2).toLocaleString("en-US")}
          </Text>
          <Text
            style={[styles.mainTableHeadCell, styles.dataCell, styles.lastCell]}
          >
            {Number(amount).toFixed(2).toLocaleString("en-US")}
          </Text>
        </View>
        <View
          style={[
            styles.section,
            { alignItems: "unset", justifyContent: "flex-start" },
          ]}
        >
          <View style={styles.leftFooterSection}>
            <View wrap={false} style={styles.description}>
              <Text wrap={false} style={styles.thanks}>
                Thank you for your business!
              </Text>
            </View>
          </View>
          <View style={styles.rightFooterSection}>
            <View
              style={{
                width: "122px",
                paddingLeft: "2px",
                paddingTop: "15px",
              }}
            >
              <Text style={styles.totalType}>SUBTOTAL</Text>
              <Text style={styles.totalType}>TAX RATE</Text>
              <Text style={styles.totalType}>TAX</Text>
              <Text style={styles.total}>TOTAL</Text>
            </View>
            <View
              style={{
                borderLeftColor: "black",
                borderLeftStyle: "solid",
                borderLeftWidth: "1px",
                paddingRight: "8px",
                width: "122px",
                paddingTop: "15px",
              }}
            >
              <Text style={[styles.totalType, styles.totalValue]}>
                {amount.toFixed(2).toLocaleString("en-US")}
              </Text>
              <Text style={[styles.totalType, styles.totalValue]}>
                {(tax * 100).toFixed(0)}%
              </Text>
              <Text style={[styles.totalType, styles.totalValue]}>
                {(amount * tax).toFixed(2).toLocaleString("en-US")}
              </Text>
              <View
                style={[
                  styles.totalType,
                  styles.totalValue,
                  {
                    fontWeight: "bold",
                    display: "flex",
                    fontSize: "11px",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  },
                ]}
              >
                <Text>EUR</Text>
                <Text>
                  {(amount + amount * tax).toFixed(2).toLocaleString("en-US")}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            paddingBottom: "30px",
          }}
        >
          <View style={styles.bankInfo}>
            <Text style={{ marginBottom: "15px" }}>
              Beneficiary's bank details
            </Text>
            {bankInfo.account_number && (
              <Text>ACCOUNT NUMBER EUR: {bankInfo.account_number}</Text>
            )}
            {bankInfo.iban && <Text>IBAN: {bankInfo.iban}</Text>}
            {bankInfo.swift && <Text>SWIFT: {bankInfo.swift}</Text>}
            {bankInfo.bank_name && <Text>BANK NAME: {bankInfo.bank_name}</Text>}
            {bankInfo.bank_country && (
              <Text>BANK COUNTRY: {bankInfo.bank_country}</Text>
            )}
            {bankInfo.bank_address && (
              <Text
                style={{
                  width: "270px",
                }}
              >
                BANK ADDRESS: {bankInfo.bank_address}
              </Text>
            )}
            {bankInfo.post_code && <Text>POST CODE: {bankInfo.post_code}</Text>}
            {bankInfo.po_box && <Text>P.O. Box: {bankInfo.po_box}</Text>}
          </View>
          <View style={styles.correct}>
            <Text>For the correct transaction,</Text>
            <Text>
              please indicate the number and date of the invoice in the purpose
              of payment
            </Text>
          </View>
        </View>
        <Image src={footer} style={styles.footerImg} />
        <View style={styles.footerText}>
          <Text>
            All charges of correspondent banks are at the Client`s expenses.
          </Text>
          <Text>
            If you have any questions about this invoice, please contact.
          </Text>
          <Text
            style={{
              fontWeight: "bold",
            }}
          >
            {bankInfo.email}
          </Text>
          <Text
            style={{
              fontWeight: "bold",
            }}
          >
            {bankInfo.phone}
          </Text>
        </View>
      </Page>
    </Document>
  );
};
export default MyDocument;
