import { getDemoVideos, getVideos } from "src/services/api/content";
import {
  GET_VIDEOS,
  GET_VIDEOS_SUCCESS,
  GET_VIDEOS_ERROR,
  GET_VIDEOS_CATEGORIES,
  GET_VIDEOS_SPEECH_LANGUAGES,
  GET_GENDERS,
} from "./constants";

import { toast } from "react-toastify";
import {
  getGenders,
  getSpeechLanguages,
  getVideoCategories,
} from "@services/api/video/video-api";

export const getVideosAction =
  ({ limit, offset } = { limit: 100, offset: 0 }) =>
  async dispatch => {
    dispatch({
      type: GET_VIDEOS,
    });
    try {
      const { data: videos } = await getVideos(limit, offset);

      const result = videos.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

      dispatch({
        type: GET_VIDEOS_SUCCESS,
        payload: result,
      });
      return videos;
    } catch (e) {
      dispatch({
        type: GET_VIDEOS_ERROR,
        payload: e,
      });
      throw e;
    }
  };

export const getDemoVideosAction = () => async dispatch => {
  dispatch({
    type: GET_VIDEOS,
  });
  try {
    const { data: videos } = await getDemoVideos();

    const result = videos.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

    dispatch({
      type: GET_VIDEOS_SUCCESS,
      payload: result,
    });
    return videos;
  } catch (e) {
    dispatch({
      type: GET_VIDEOS_ERROR,
      payload: e,
    });
    throw e;
  }
};

export const getVideosCategoriesAction = () => async dispatch => {
  try {
    const { data: categories } = await getVideoCategories();
    dispatch({
      type: GET_VIDEOS_CATEGORIES,
      payload: categories,
    });
    return categories;
  } catch (e) {
    toast.error(e);
    throw e;
  }
};

export const getVideosSpeechLanguagesAction = () => async dispatch => {
  try {
    const { data: speech_languages } = await getSpeechLanguages();
    dispatch({
      type: GET_VIDEOS_SPEECH_LANGUAGES,
      payload: speech_languages,
    });
    return speech_languages;
  } catch (e) {
    toast.error(e);
    throw e;
  }
};
export const getGendersAction = () => async dispatch => {
  try {
    const { data: genders } = await getGenders();
    dispatch({
      type: GET_GENDERS,
      payload: genders,
    });
    return genders;
  } catch (e) {
    toast.error(e);
    throw e;
  }
};
