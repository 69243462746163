import React from "react";

import {Icon} from "src/views/blocks";

import "./index.scss";


const SuccessIcon = (props) => {
    const {
        className,
        ...rest
    } = props;

    return (
        <Icon
          {...rest}
          className={`success-icon ${className}`}
          background />
    );
};


export {SuccessIcon};