import shortid from "shortid";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function onCloseAccardion() {
  const row = document.getElementsByClassName("data-table-row--open");
  const rowData = document.getElementsByClassName("data-table-row__data");
  const arrayRow = [...row];
  const arrayRowData = [...rowData];
  arrayRow.length &&
    arrayRow.map((r, i) => r.classList.remove("data-table-row--open"));
  arrayRowData.length && arrayRowData.map((r, i) => (r.style.display = "none"));
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export const headCells = [
  {
    id: "index",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "video",
    numeric: false,
    disablePadding: true,
    label: "Loading video",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "company",
    numeric: false,
    disablePadding: false,
    label: "Company",
  },
  {
    id: "category",
    numeric: true,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "description",
    numeric: true,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "purpose",
    numeric: true,
    disablePadding: false,
    label: "Interaction",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
];

export const auctions = [
  {
    url: "https://dev-api.infocoin.online/public/1.mp4",
    type: "video/mp4",
    id: shortid.generate(),
    price: 123,
    likes: 672,
    complaints: 7,
    expects: 20000,
    data: "11/11/2021",
    percent: 56,
    bet: 0.123,
    views: 12.33,
    involve: 89,
    title: "Название видео",
    more: "Краткое описание видео",
    lot: {
      id: 1,
      bet: 0.123,
      views: 12.33,
      involve: 89,
      title: "Название видео",
      more: "Краткое описание видео",
    },
    author: {
      id: shortid.generate(),
      avatar: "author.jpeg",
      cover: "author.jpeg",
      name: "Beyonce",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sint, ad quasi ex illo voluptatem tempora temporibus ratione delectus id. Ex, aliquam corrupti. Asperiores ullam dicta aut, impedit ipsam necessitatibus!",
    },
  },
];
