import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { DefaultPlayer as Video } from "react-html5video";
// import './LinkBrowsingHistoryItem.css';

const AuctionLink = ({ props, arrowRight }) => {
  const history = useHistory();
  const valueItem = history.location.state;
  const lonelyPlayer = useRef();
  const buttonRef = useRef();
  const [videoPlaying, setVideoPlaying] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoHovering, setVideoHovering] = useState(null);
  const [buttonHoverig, setButtonHoverig] = useState(false);

  useEffect(() => {
    video !== null &&
      video.addEventListener("play", () => {
        setVideoPlaying(true);
      });
    videoPlaying === true &&
      video !== null &&
      video.addEventListener("pause", () => {
        setVideoPlaying(false);
      });
    return () => {
      video !== null &&
        video.removeEventListener("play", () => {
          setVideoPlaying(null);
        }) &&
        video.removeEventListener("pause", () => {
          setVideoPlaying(null);
        });
    };
  }, [video, videoPlaying]);

  const handlerGoBack = (evt) => {
    evt &&
      history.push({
        pathname: `${"/auctions"}`,
      });
  };

  const getHovering = (bool, evt) => {
    evt.target.nodeName === "DIV" && setVideoHovering(bool);
  };

  const getButtonState = (bool, evt) => {
    evt.target.nodeName === "BUTTON" ? setButtonHoverig(bool) : setButtonHoverig(bool);
  };

  return (
    <div className="history-wrapper" style={{ padding: "50px" }}>
      <Col lg={12} md={12} sm={12} xs={12} className="flex-row-center-align info">
        <div className="flex-row-center-align">
          <Link
          // to={{
          //   pathname: `${'/profile'}/${valueItem.author.id}`,
          //   state: valueItem?.author,
          // }}
          >
            <img
              src={valueItem.author.avatar}
              alt="profile avatar"
              className="profile-icon"
            />
          </Link>
          <div className="text-left">
            <h2 className="title">
              <Link
                to={{
                  pathname: `${"/profile"}/${valueItem.author.id}`,
                  state: valueItem?.author,
                }}
              >
                {valueItem.author.name}
              </Link>{" "}
            </h2>
            <small>{valueItem.author.description}</small>
            {/* <ul className="hashtag">
              {props.author.tags.map(tag => (
                <li key={tag} className="hashtag-item">
                  {tag}
                </li>
              ))}
            </ul> */}
          </div>
        </div>
        <img className="icon-small ms-2" src={arrowRight} alt="" />
      </Col>
      <div
        className={`${"lonely-player-wrapper"} ${videoHovering && "hovering"}`}
        onMouseEnter={(evt) => getHovering(true, evt)}
        onMouseLeave={(evt) => getHovering(false, evt)}
      >
        <Video
          ref={lonelyPlayer}
          playsInline
          autoPlay={true}
          muted={false}
          loop={false}
          controls={["PlayPause", "Volume"]}
          console={[]}
          onCanPlayThrough={(evt) => setVideo(evt.target)}
          className={`${
            (videoPlaying && buttonHoverig) || (videoPlaying && videoHovering)
              ? "play"
              : "pause"
          }`}
        >
          <source defer src={valueItem.url} type="video/MP4" className="video" />
        </Video>

        <Row>
          <Col
            lg={3}
            md={3}
            sm={3}
            xs={3}
            className="d-flex flex-column focus-black"
            tabindex="1"
          >
            <p>237</p>
            <p>Просмотры</p>
          </Col>
          <Col
            lg={3}
            md={3}
            sm={3}
            xs={3}
            className="d-flex flex-column focus-black"
            tabindex="1"
          >
            <p>237</p>
            <p>Лайки</p>
          </Col>
          <Col
            lg={3}
            md={3}
            sm={3}
            xs={3}
            className="d-flex flex-column focus-black"
            tabindex="1"
          >
            <p>237</p>
            <p>Показы</p>
          </Col>
          <Col
            lg={3}
            md={3}
            sm={3}
            xs={3}
            className="d-flex flex-column focus-black"
            tabindex="1"
          >
            <p>237</p>
            <p>Жалобы</p>
          </Col>
        </Row>
        <Row>
          <Col
            lg={3}
            md={3}
            sm={3}
            xs={3}
            className="d-flex flex-column focus-black"
            tabindex="1"
          >
            <p>237</p>
            <p>Затраты IC</p>
          </Col>
          <Col
            lg={3}
            md={3}
            sm={3}
            xs={3}
            className="d-flex flex-column focus-black"
            tabindex="1"
          >
            <p>50%</p>
            <p>Охват</p>
          </Col>
          <Col
            lg={3}
            md={3}
            sm={3}
            xs={3}
            className="d-flex flex-column focus-black"
            tabindex="1"
          >
            <p>237</p>
            <p>Взаимодействий</p>
          </Col>
          <Col
            lg={3}
            md={3}
            sm={3}
            xs={3}
            className="d-flex flex-column focus-black"
            tabindex="1"
          >
            <p>11.11.2021</p>
            <p>Дата проведения</p>
          </Col>
        </Row>
        {videoHovering === true && (
          <button
            ref={buttonRef}
            onClick={handlerGoBack}
            className={`${"goBack"}`}
            onMouseEnter={(evt) => getHovering(true, evt)}
            onMouseLeave={(evt) => getHovering(true, evt)}
            onMouseOut={(evt) => getButtonState(false, evt)}
            onMouseOver={(evt) => getButtonState(true, evt)}
          ></button>
        )}
      </div>
    </div>
  );
};

export default AuctionLink;
