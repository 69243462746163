import { memo, useCallback } from "react";

import { Grid, Popover } from "@mui/material";

import classes from "./index.module.scss";

import { useTranslation } from "react-i18next";
import { Typography } from "../../../../views/blocks";

import clsx from "clsx";
import { connect } from "react-redux";

import {
  deleteNotificationAction,
  deleteAllNotifications,
  readAllNotificationsAction,
} from "@redux/notifiactions/notificationsActions";

const UserInfoNotifications = ({
  id,
  notifications,
  anchorEl,
  open,
  onClose,
  deleteAllNotifications,
  deleteNotificationAction,
  readAllNotificationsAction,
}) => {
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    onClose();

    readAllNotificationsAction();
  }, []);

  const clearAll = useCallback(async () => {
    await deleteAllNotifications();

    handleClose();
    //eslint-disable-next-line
  }, [handleClose]);

  const deleteNotification = useCallback(
    async id => {
      await deleteNotificationAction(id);
    }, //eslint-disable-next-line
    [],
  );

  return (
    <Popover
      id={id}
      anchorEl={anchorEl}
      sx={{
        marginTop: "10px",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      classes={{
        root: classes.overlay,
        paper: classes.paper,
      }}
      marginThreshold={12}
      open={open}
      onClose={handleClose}
    >
      <Grid>
        {notifications.length ? (
          <Grid item>
            <Grid className={clsx(classes.items, "pro-sidebar-layout")}>
              {notifications.map((msg, id) => (
                <Grid key={id} className={classes.item}>
                  <span>{msg}</span>
                  <Typography
                    color="error"
                    weight="semi-bold"
                    variant="body2"
                    className="pointer hover-opacity"
                    onClick={() => deleteNotification(id)}
                  >
                    {t("Clear")}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Grid key={id} className={classes.item}>
              <Typography
                color="error"
                weight="semi-bold"
                variant="body2"
                align="center"
                className="pointer hover-opacity"
                onClick={clearAll}
              >
                {t("Clear notifications")}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Typography variant="body2" align="center" weight="normal-bold">
            {t("You don't have notifications")}
          </Typography>
        )}
      </Grid>
    </Popover>
  );
};

const mapDispatchToProps = {
  deleteNotificationAction,
  deleteAllNotifications,
  readAllNotificationsAction,
};

export default connect(null, mapDispatchToProps)(memo(UserInfoNotifications));
