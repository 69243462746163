import React, { memo, useRef } from "react";
import classes from "./SwitchToggle.module.scss";
import clsx from "clsx";
const SwitchToggle = ({
  options,
  onChange,
  activeIndex,
  optionClassName,
  ...props
}) => {
  const container = useRef();

  return (
    <div className={classes.container} {...props} ref={container}>
      {options.map((option, index) => (
        <div
          className={clsx(classes.option, optionClassName, {
            [classes.activeOption]: activeIndex === index,
          })}
          style={{
            width: `calc(100% / ${options.length})`,
          }}
          onClick={() => {
            onChange(option.value, index);
          }}
        >
          {option.label}
        </div>
      ))}
      <div
        className={clsx(classes.activePoint)}
        style={{
          width: `calc(100% / ${options.length})`,
          left: `${
            activeIndex === 0
              ? 2
              : activeIndex === options.length - 1
              ? (options.length - 1) *
                  (container.current.clientWidth / options.length) -
                2
              : activeIndex * (container.current.clientWidth / options.length)
          }px`,
          right: activeIndex === options.length - 1 ? 2 + "px" : undefined,
        }}
      />
    </div>
  );
};

export default memo(SwitchToggle);
