import React, { useCallback, useMemo, useState, memo } from "react";
import classes from "./DaySelect.module.scss";
import Select from "@components/Form/Select";
import "./DaySelect.module.scss";
import clsx from "clsx";
import { withTranslation } from "react-i18next";
const monthOptions = [
  {
    label: "January",
    value: 1,
  },
  {
    label: "February",
    value: 2,
  },
  {
    label: "March",
    value: 3,
  },
  {
    label: "April",
    value: 4,
  },
  {
    label: "May",
    value: 5,
  },
  {
    label: "June",
    value: 6,
  },
  {
    label: "July",
    value: 7,
  },
  {
    label: "August",
    value: 8,
  },
  {
    label: "September",
    value: 9,
  },
  {
    label: "October",
    value: 10,
  },
  {
    label: "November",
    value: 11,
  },
  {
    label: "December",
    value: 12,
  },
];

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

const DaySelect = ({
  onChange,
  containerClassName,
  label,
  value,
  onBlur,
  error,
  t,
  name = "",
  touched,
}) => {
  const [date, setDate] = useState({
    day: value ? new Date(value).getDate() : null,
    month: value ? new Date(value).getMonth() + 1 : null,
    year: value ? new Date(value).getFullYear() : 1990,
  });

  const yearOptions = useMemo(() => {
    const years = [];
    for (let i = new Date().getFullYear(); i >= 1920; i--) {
      years.push({
        label: i,
        value: i,
      });
    }
    return years;
  }, []);

  const daysOptions = useMemo(() => {
    const days = [];
    if (date) {
      const numberOfDays = daysInMonth(date.month, date.year) || 31;
      for (let i = 1; i <= numberOfDays; i++) {
        days.push({
          label: i,
          value: i,
        });
      }
    }
    return days;
  }, [date]);

  const handleChange = useCallback(
    date => {
      setDate(date);

      if (onChange) {
        onChange(
          new Date(date.year, date.month - 1, date.day, 4).toISOString(),
        );
      }
    },
    [onChange],
  );

  return (
    <div className={clsx(classes.container, containerClassName)}>
      <div className={classes.label}>{t(label)}</div>
      <div className={clsx(classes.dateContainer)}>
        <Select
          value={date.day ? date.day : ""}
          options={daysOptions}
          translateOptions={false}
          placeholder={t("Day")}
          containerClassName={clsx(classes.day)}
          onChange={value => {
            handleChange({ ...date, day: value.target.value });
          }}
          onBlur={onBlur}
          isSearchable={false}
        />
        <Select
          value={date.month ? date.month : ""}
          options={monthOptions}
          placeholder={t("Month")}
          containerClassName={clsx(classes.month)}
          onChange={value => {
            handleChange({ ...date, month: value.target.value });
          }}
          onBlur={onBlur}
          isSearchable={false}
        />
        <Select
          value={date.year ? date.year : ""}
          options={yearOptions}
          translateOptions={false}
          placeholder={t("Year")}
          containerClassName={clsx(classes.year)}
          isSearchable={false}
          onChange={value => {
            handleChange({ ...date, year: value.target.value });
          }}
          onBlur={onBlur}
        />
      </div>
      <div>{touched && error && <p className={classes.error}>{error}</p>}</div>
    </div>
  );
};

export default withTranslation()(memo(DaySelect));
