import React from "react";
import "./Loader.css";
import clsx from "clsx";

const Loader = ({
  width = 80,
  height = 80,
  isPageLoader = false,
  className,
  color,
}) => {
  return (
    <div
      className={clsx(
        "loader-container",
        { pageHeight: isPageLoader },
        className,
      )}
    >
      <div style={{ width, height }} className="lds-ring">
        <div
          style={{
            margin:`${width *0.1}px`,
            borderWidth:`${width *0.1}px`,
            width: width * 0.8,
            height: height * 0.8,
            borderColor: `${color} transparent transparent transparent`,
          }}
        />
        <div
          style={{
            margin:`${width *0.1}px`,
            borderWidth:`${width *0.1}px`,
            width: width * 0.8,
            height: height * 0.8,
            borderColor: `${color} transparent transparent transparent`,
          }}
        />
        <div
          style={{
            margin:`${width *0.1}px`,
            borderWidth:`${width *0.1}px`,
            width: width * 0.8,
            height: height * 0.8,
            borderColor: `${color} transparent transparent transparent`,
          }}
        />
        <div
          style={{
            margin:`${width *0.1}px`,
            borderWidth:`${width *0.1}px`,
            width: width * 0.8,
            height: height * 0.8,
            borderColor: `${color} transparent transparent transparent`,
          }}
        />
      </div>
    </div>
  );
};
export default Loader;
