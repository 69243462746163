import React, { memo, useCallback, useContext } from "react";
import { FormControlLabel, Grid } from "@mui/material";
import { AuthCheckbox } from "@components/Forms/components/ui";
import { Typography } from "../../../../views/blocks";
import s from "@components/Forms/index.module.scss";

import { DocumentsContext } from "@containers/AuthContainer/context/providers/documents";

import { useTranslation } from "react-i18next";

const AuthCheckboxDocuments = ({ values, errors, onChange }) => {
  const { acceptPrivacy = true, acceptAgreement = true } = values;

  const { setShowModalDocuments, setShowModalDocumentsPublic } =
    useContext(DocumentsContext);

  const { t } = useTranslation();

  const handleChange = useCallback(event => {
    const {
      target: { name, checked },
    } = event;

    onChange(name, checked);
  }, []);

  const handleToggleModal = useCallback(event => {
    event.preventDefault();
    setShowModalDocuments();
  });

  const handleToggleModalPublic = useCallback(event => {
    event.preventDefault();
    setShowModalDocumentsPublic();
  });

  return (
    <React.Fragment>
      <Grid item mt={3}>
        <FormControlLabel
          control={
            <AuthCheckbox
              checked={acceptPrivacy}
              onChange={handleChange}
              name="acceptPrivacy"
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={
            <>
              <Typography
                className={s.form__documentsLabel}
                variant="caption2"
                color="gray"
              >
                {t("I have read and agree to")}
                <Typography
                  variant="caption2"
                  color="primary"
                  component="span"
                  weight="normal-bold"
                  onClick={handleToggleModal}
                >
                  {" "}
                  {t("Terms of Service on provider login page", {
                    context: "Terms of Service",
                  })}
                </Typography>{" "}
                {t("and")}{" "}
                <Typography
                  variant="caption2"
                  color="primary"
                  component="span"
                  weight="normal-bold"
                  onClick={handleToggleModal}
                >
                  {t("Privacy Policy on provider login page", {
                    context: "Privacy Policy",
                  })}
                </Typography>
              </Typography>
            </>
          }
        />
      </Grid>
      <Grid item mt={1}>
        <FormControlLabel
          control={
            <AuthCheckbox
              checked={acceptAgreement}
              onChange={handleChange}
              name="acceptAgreement"
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={
            <>
              <Typography variant="caption2" color="gray">
                {t("I have read and agree to")}
                <Typography
                  variant="caption2"
                  color="primary"
                  component="span"
                  weight="normal-bold"
                  onClick={handleToggleModalPublic}
                >
                  {" "}
                  {t("Public Agreement on provider login page", {
                    context: "Public Agreement",
                  })}
                </Typography>
              </Typography>
            </>
          }
        />
      </Grid>
      {(errors.acceptPrivacy || errors.acceptAgreement) && (
        <Typography variant="caption2" color="error">
          {t(
            "The user is responsible for the completeness and accuracy of the information provided",
          )}
        </Typography>
      )}
    </React.Fragment>
  );
};

export default memo(AuthCheckboxDocuments);
