import { useEffect } from "react";
import { getTokenData } from "@utils/tokens";
import { toast } from "react-toastify";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { isAuthenticatedSelector } from "@redux/auth/selectors";
import { WS_URL } from "../config";
import { addNewNotificationsAction } from "@redux/notifiactions/notificationsActions";
import moment from "moment";
import { getMyVideosAction } from "@redux/emergency/actions";
import { getAuctionsAction } from "@redux/auction/actions";
import { getVideosAction } from "@redux/content/contentActions";
import {
  mapStatuses,
  VIDEO_STATUS_NOT_VERIFIED,
  VIDEO_STATUS_REJECTED,
  VIDEO_STATUS_VERIFIED,
  VIDEO_STATUS_VERIFYING_POSTPONED,
} from "@redux/video";
import i18n from "src/translates";

import { Typography } from "../views/blocks";

let notificationsSocket;
export const onToggleNotifictions = async (message_type, active) => {
  if (notificationsSocket) {
    await notificationsSocket.send(
      JSON.stringify({
        message_type: message_type,
        active: active,
      }),
    );
  }
};

export const useWbSc = () => {
  const isAuth = useSelector(isAuthenticatedSelector, shallowEqual);
  const { user } = useSelector(state => state.user);
  const { token } = getTokenData();

  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuth) {
      notificationsSocket = new WebSocket(
        `wss://${WS_URL}/ws/notifications?token=${token}`,
      );
      notificationsSocket.onmessage = function (event) {
        const action = JSON.parse(event.data);
        if (action.message_type === "auction_closed") {
          toast.warning(i18n.t("Auction is closed"));
          dispatch(getAuctionsAction(user.country));
          dispatch(
            addNewNotificationsAction(
              <div>
                {i18n.t("Auction for")}{" "}
                {moment(action.ended_at).format("MMMM Do")}{" "}
                {i18n.t("is closed")}
              </div>,
            ),
          );
        }
        if (action.message_type === "auction_active") {
          toast.warning(i18n.t("Auction is open"));
          dispatch(getAuctionsAction(user.country));
          dispatch(
            addNewNotificationsAction(
              `${i18n.t("Auction for")} ${moment(action.started_at).format(
                "MMMM Do",
              )} ${i18n.t("is open")}`,
            ),
          );
        }
        if (action.message_type === "content_moderation_status_changes") {
          switch (action.status) {
            case VIDEO_STATUS_NOT_VERIFIED: {
              toast.error(i18n.t("Videos status was changed to not verified"));
              break;
            }
            case VIDEO_STATUS_VERIFIED: {
              toast.success(i18n.t("Videos status was changed to verified"));
              break;
            }
            case VIDEO_STATUS_REJECTED: {
              toast.error(i18n.t("Videos status was changed to rejected"));
              break;
            }
            case VIDEO_STATUS_VERIFYING_POSTPONED: {
              toast.warning(
                i18n.t("Videos status was changed to verifying postponed"),
              );
              break;
            }
            default: {
              toast.warning(i18n.t("Videos status was changed"));
            }
          }

          dispatch(getVideosAction());
          dispatch(
            addNewNotificationsAction(
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span>
                  {i18n.t("Video")} ({action.id}), {action.title},{" "}
                  {i18n.t("status changed to")}
                  <br />
                  <span
                    style={{
                      color:
                        action.status === "not_verified" ||
                        action.status === "rejected"
                          ? "red"
                          : "green",
                    }}
                  >
                    {i18n.t(mapStatuses[action.status])}
                  </span>
                </span>
                {moment(action.updated_at).format("YYYY-MM-DD LT")}
              </div>,
            ),
          );
        }
        if (action.message_type === "end_view_content") {
          toast.warning(i18n.t("Video show ended"));
          dispatch(getVideosAction());
          dispatch(addNewNotificationsAction(i18n.t("Video show ended")));
        }
        if (action.message_type === "video_uploaded") {
          toast.success(
            i18n.t(`Video {{id}}, {{name}} is uploaded`, {
              id: action.id,
              name: action.title,
            }),
          );
          dispatch(getVideosAction());
          dispatch(
            addNewNotificationsAction(
              i18n.t(`Video {{id}}, {{name}} is uploaded`, {
                id: action.id,
                name: action.title,
              }),
            ),
          );
        }
        if (action.message_type === "video_blocked") {
          toast.warning(
            i18n.t("Video blocked due to INFOCOIN rights violation"),
          );
          dispatch(getVideosAction());
          dispatch(
            addNewNotificationsAction(
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span>
                  {i18n.t("Video")} ({action.id}), {action.title}, <br />
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {i18n.t("blocked due to violation of rights")}
                  </span>
                </span>
                {moment(action.updated_at).format("YYYY-MM-DD LT")}
              </div>,
            ),
          );
        }

        if (action.message_type === "service_notification") {
          toast.warning(`${action.topic}, ${action.body}`);
          dispatch(
            addNewNotificationsAction(
              <div>
                <Typography variant="body2" weight="normal-bold">
                  {action.topic}
                </Typography>
                <Typography variant="body2" color="gray">
                  {action.body}
                </Typography>
              </div>,
            ),
          );
        }
      };
      if (!isAuth) {
        notificationsSocket.close();
      }

      return () => {
        notificationsSocket.close();
      };
    } //eslint-disable-next-line
  }, [isAuth, token]);
};
