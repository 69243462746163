const range = (len) => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

const newPerson = () => {
  return {
    video: "url",
    lot: "1",
    bet: "0.123 IC",
    views: "12.33 млн",
    involves: "89%",
    title: "Название видео",
    description: "Краткое описание видео",
  };
};

export default function makeData(...lens) {
  const makeDataLevel = (depth = 0) => {
    const len = lens[depth];
    return range(len).map((d) => {
      return {
        ...newPerson(),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
      };
    });
  };

  return makeDataLevel();
}

export function makeData2() {
  return [
    {
      video: "Лот 2-16",
      showImage: false,
      lot: "0.1233 -",
      bet: "12.421 IC",
      views: "11k - 15kk",
      involves: "89%-99%",
      title: "-",
      description: "-",
      subRows: [
        {
          video: "url",
          lot: "3",
          bet: "0.124 IC",
          views: "12.34 млн",
          involves: "90%",
          title: "Название видео 3",
          description: "Краткое описание видео 3",
          btn: "",
        },
      ],
    },
    {
      video: "Лот 72-163",
      showImage: false,
      lot: "0.1233 -",
      bet: "12.421 IC",
      views: "11k - 15kk",
      involves: "89%-99%",
      title: "-",
      description: "-",
      subRows: [
        {
          video: "url",
          lot: "3",
          bet: "0.124 IC",
          views: "12.34 млн",
          involves: "90%",
          title: "Название видео 3",
          description: "Краткое описание видео 3",
          btn: "",
        },
      ],
    },
  ];
}
