import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import Select, { components } from "react-select";

import "./index.scss";

import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getCountriesAction } from "@redux/language/actions";
import FlagCountry from "@components/FlagCountry";

const { Option, SingleValue, DropdownIndicator } = components;

const CustomOption = memo(({ data: { iso_2, name }, ...props }) => (
  <Option {...props} isSelected={props.selectProps?.value?.name === name}>
    <FlagCountry code={iso_2} />
    {name}
  </Option>
));

const CustomSingleValue = memo(({ data: { iso_2, name }, ...props }) => (
  <SingleValue {...props}>
    <FlagCountry code={iso_2} />
    <span>{name}</span>
  </SingleValue>
));

const CountrySelect = ({
  label,
  placeholder = "",
  getCountriesAction,
  countries,
  name,
  onChange,
  value,
}) => {
  const { t } = useTranslation();

  const noOptionsMessage = useCallback(() => {
    return t("Not found");
  }, [t]);

  const options = useMemo(
    () =>
      countries.sort(
        (a, b) => a.name.toLowerCase() < b.name.toLowerCase() && -1,
      ),
    [countries],
  );

  useEffect(() => {
    !countries.length && getCountriesAction();
  }, []);

  const handleChange = useCallback(country => {
    onChange(name, country.name);
  }, []);

  const valuesCountry = useMemo(() => {
    return countries.find(country => country.name === value);
  }, [countries, value]);

  return (
    <Grid className="auth-country-select">
      {label && <label className="auth-country-select__label">{label}</label>}
      <Select
        components={{
          IndicatorSeparator: null,
          Option: CustomOption,
          SingleValue: props => <CustomSingleValue {...props} />,
        }}
        className="auth-country-select__select"
        classNamePrefix="auth-country-select__select"
        menuPlacement="top"
        filterOption={(candidate, input) =>
          candidate.data.name.toLowerCase().includes(input.toLowerCase())
        }
        noOptionsMessage={noOptionsMessage}
        placeholder={placeholder}
        options={options}
        name={name}
        onChange={handleChange}
        value={valuesCountry}
      />
    </Grid>
  );
};

const mapStateToProps = ({ language }) => ({
  countries: language.countries,
});

export default connect(mapStateToProps, { getCountriesAction })(
  memo(CountrySelect),
);
