import React from 'react';
import { Container } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <Container className="m-mob">
      <h4>Политика конфиденциальности </h4>
    </Container>
  );
};

export default PrivacyPolicy;
