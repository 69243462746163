import React from "react";

import {Icon} from "src/views/blocks";

import "./index.scss";


const CardIcon = (props) => {
    const {
        className = "",
        ...rest
    } = props;

    return (
        <Icon
            {...rest}
            className={`card-icon ${className}`}
            mask />
    );
};


export {CardIcon};