import { axiosInstance } from "@helpers/http/httpInstance";

export const getCountries = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.get(`auth/countries`);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};
export const getRegions = selectedCountryId => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.get(
        `auth/regions/${selectedCountryId}`,
      );
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const getCities = selectedRegionId => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.get(
        `auth/cities/${selectedRegionId}`,
      );
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const getCountryStatus = async country => {
  return await axiosInstance.get(`/api/is-state-of-emergency/${country}`);
};
