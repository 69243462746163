import React from "react";
import lodashGet from "lodash/get";

import {useRecord} from "src/hooks";


const RenderField = (props) => {
    const {
        render,
        name
    } = props;

    const {record} = useRecord();

    const value = lodashGet(record, name);

    return (
        <div>
            {render(record, value)}
        </div>
    );
};


export {RenderField};