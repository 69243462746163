import { useTranslation } from "react-i18next";
import { useState, useMemo } from "react";
import "./index.scss";
import noneLogo from "src/assets/images/default_video_logo.jpg";

const VideoFormPreview = ({ values, companies, isEdit }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState("Laptop");

  const src = useMemo(() => {
    if (isEdit) {
      return values.filepath_480_p;
    }
    if (values.video && values.video instanceof File) {
      return URL.createObjectURL(values.video);
    }

    return "";
  }, [isEdit, values.video, values.filepath_480_p]);

  const company = useMemo(() => {
    if (values.company_id && companies) {
      return companies.find(c => c.id === values.company_id);
    } else {
      return {
        avatar: "",
        title: t("Company"),
      };
    }
  }, [companies, values.company_id, t]);

  return (
    <div className="preview-block">
      <div className="text-input__label">{t("Preview")}</div>
      <div className="preview-navigate">
        <div
          onClick={() => setSelected("Laptop")}
          className={selected === "Laptop" && "preview-navigate__active"}
        >
          {t("Laptop")}
        </div>
        <div
          onClick={() => setSelected("PC")}
          className={selected === "PC" && "preview-navigate__active"}
        >
          {t("PC")}
        </div>
        <div
          onClick={() => setSelected("Mobile")}
          className={selected === "Mobile" && "preview-navigate__active"}
        >
          {t("Mobile")}
        </div>
      </div>
      <div className={`preview-wrapper preview_${selected}`}>
        <div className="preview-wrapper__menu" />
        <div className="preview-wrapper__content">
          <div className="preview-wrapper__content_company">
            {
              <img
                alt={"avatar"}
                src={company?.avatar ? company?.avatar : noneLogo}
              />
            }
            <span>{company?.title}</span>
          </div>
          <div className="preview-wrapper__content_video">
            {src && (
              <video
                style={{ borderRadius: "7px" }}
                muted
                autoPlay
                loop
                width="100%"
                height="100%"
                src={src}
              />
            )}
          </div>
          <div className="preview-wrapper__content_info">
            <p>{values.title ? values.title : t("Title")}</p>
            <span>
              {values.description ? values.description : t("Description")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { VideoFormPreview };
