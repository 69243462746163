import React, { useState, useCallback, memo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import classes from "./UserInfoHeader.module.scss";
import { useTranslation } from "react-i18next";

import { Grid, IconButton, Avatar, Badge } from "@mui/material";

import { Settings, Notifications } from "@mui/icons-material";

import { readAllNotificationsAction } from "@redux/notifiactions/notificationsActions";
import { Typography } from "../../../../views/blocks";
import UserInfoNotifications from "@components/RightPanel/components/Notifications";

import clsx from "clsx";

const UserInfoHeader = ({
  openBar,
  user,
  notifications,
  readAllNotificationsAction,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { unread, items } = notifications;

  const [anchorEl, setAnchorEl] = useState(null);

  const notificationsIsOpen = Boolean(anchorEl);

  const id = notificationsIsOpen ? "notifications-popover" : undefined;

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    readAllNotificationsAction(); //eslint-disable-next-line
  }, []);

  return (
    <Grid className={classes.container}>
      <Grid className={classes.content}>
        <Grid className={classes.actions}>
          <Avatar src={user.avatar} className={classes.actions__avatar}>
            <Typography color="black" weight="semi-bold">
              {user.name[0] || t("Name")}
            </Typography>
          </Avatar>
          <Grid
            className={clsx(classes.actions__info, {
              [classes.actions__info_open]: openBar,
            })}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography
              className={classes.actions__info_text}
              align="center"
              variant="body2"
              weight="semi-bold"
            >
              {user.name || t("Your name")}
            </Typography>
            <Typography
              className={classes.actions__info_text}
              align="center"
              variant="caption2"
              color="gray"
            >
              {user.phone && user.phone[0] === "+"
                ? user.phone
                : `+${user.phone}`}
            </Typography>
          </Grid>
          <IconButton
            className={classes.actions__settings}
            onClick={() => history.push("/account")}
          >
            <Settings htmlColor="black" />
          </IconButton>
          <IconButton
            className={classes.actions__notifications}
            onClick={handleClick}
          >
            <Badge
              badgeContent={unread}
              showZero={false}
              className={classes.actions__badge}
              overlap="circular"
            >
              <Notifications htmlColor="black" />
            </Badge>
          </IconButton>
        </Grid>
        <UserInfoNotifications
          anchorEl={anchorEl}
          id={id}
          onClose={handleClose}
          open={notificationsIsOpen}
          notifications={items}
        />
        <Grid
          className={clsx(classes.info, {
            [classes.info__hidden]: openBar,
          })}
        >
          <Typography align="center" variant="body2" weight="semi-bold">
            {user.name || t("Your name")}
          </Typography>
          <Typography align="center" variant="caption2" color="gray">
            {user.phone && user.phone[0] === "+"
              ? user.phone
              : `+${user.phone}`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ user, notifications }) => ({
  user: user.user,
  notifications: notifications,
});

const mapDispatchToProps = {
  readAllNotificationsAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(UserInfoHeader));
