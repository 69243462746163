import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";

import { useAppInit } from "src/hooks";
import AppRouter from "@components/AppRouter/index.js";
import Loader from "@components/Loader";
import Toast from "@components/Toast";
import Cookie from "@components/Cookie";
import { useWbSc } from "hooks/useWbSc";
import "./App.scss";

const App = () => {
  const { pending } = useSelector(
    ({ authenticationReducer: { pending } }) => ({ pending }),
    shallowEqual,
  );

  useAppInit();

  useWbSc();

  return (
    <>
      <Toast />
      {pending ? (
        <Loader width={260} height={260} isPageLoader />
      ) : (
        <>
          <AppRouter />
          <Cookie />
        </>
      )}
    </>
  );
};

export default App;
