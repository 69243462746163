import React from "react";

const FlagCountry = ({ code, className }) => {
  return (
    <img
      className={className}
      alt={code}
      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`}
    />
  );
};

export default FlagCountry;
