import React, { useCallback, useMemo, useState, Suspense } from "react";
import classes from "./PeopleStatistic.module.scss";
import SwitchToggle from "@components/SwitchToogle";
import clsx from "clsx";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import { createUseStyles } from "react-jss";
import colorByNumber from "@utils/colorByNumber";
import FlagCountry from "@components/FlagCountry";
import { useTranslation } from "react-i18next";
import { Maps } from "@assets/maps";
import World from "@assets/maps/World";

const useStyles = createUseStyles({
  mapItem: {
    fill: props => props.color,
    strokeWidth: props => props.strokeWidth,
    "&:hover": {
      outline: 0,
      fill: props => props.color,
    },
    "&:focus": {
      outline: 0,
      fill: props => props.color,
    },
  },
});

const CustomBar = props => (
  <svg x={props.x} y={props.y}>
    <rect width={props.width} height={props.height} rx="7" fill={props.fill} />
  </svg>
);

const PeopleStatistic = ({
  stats = [
    {
      region: "Zaporizhzhya Oblast",
      views: 5000,
    },
    {
      region: "Volyn Oblast",
      views: 4300,
    },
    {
      region: "Kyiv Oblast",
      views: 8000,
    },
    {
      region: "Kharkiv Oblast",
      views: 3400,
    },
    {
      region: "Ivano-Frankivsk Oblast",
      views: 3400,
    },
  ],
  country = {},
}) => {
  const [activeToggle, setActiveToggle] = useState({
    value: "Table",
    activeIndex: 0,
  });

  const { t } = useTranslation();

  const options = useMemo(
    () => [
      { value: "Table", label: t("Table") },
      { value: "Diagram", label: t("Diagram") },
      { value: "Map", label: t("Map") },
    ],
    [t],
  );

  const data = useMemo(() => {
    if (stats.length) {
      let data;
      if (country === "World") {
        data = stats.map(v => ({
          ...v,
          region: v.country,
        }));
      } else {
        data = stats;
      }
      const filtered = [
        data?.filter((d, index) => index < stats.length / 2),
        data?.filter((d, index) => index >= stats.length / 2),
      ];
      const res = [];
      for (
        let i = 0;
        i <=
        (filtered[0].length > filtered[1].length
          ? filtered[0].length
          : filtered[1].length);
        i++
      ) {
        res.push({
          left: filtered[0][i],
          right: filtered[1][i],
        });
      }
      return res;
    }
    return [];
  }, [country, stats]);

  const onToggleChange = useCallback((value, index) => {
    setActiveToggle({ value, activeIndex: index });
  }, []);

  const Map = useMemo(() => {
    if (country === "World") {
      return World;
    } else return Maps[country.name];
  }, [country]);

  const MapItem = loc => {
    let max = stats[0].views;
    let min = 0;

    stats.forEach(s => {
      if (s.views > max) {
        max = s.views;
      }
    });

    const value = stats.find(s => s.state_code == loc.id);

    const classes = useStyles({
      color: colorByNumber(
        value ? (value.views - min) / (max - min) : 0,
        "rgb(232,227,241)",
        "rgb(84, 22, 144)",
      ),
    });
    return classes.mapItem;
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          {country === "World" ? (
            <div className={classes.world}>{t("The whole world")}</div>
          ) : (
            <>
              <FlagCountry
                className={classes.flag}
                code={country.iso_2}
              ></FlagCountry>
              <div
                style={{
                  marginLeft: "10px",
                }}
              >
                {country.name}
              </div>
            </>
          )}
        </div>
        <SwitchToggle
          options={options}
          onChange={onToggleChange}
          value={activeToggle.value}
          activeIndex={activeToggle.activeIndex}
          optionClassName={classes.option}
        />
      </div>
      <div>
        {activeToggle.value === "Table" && (
          <>
            {data.map((reg, index) => (
              <div
                key={reg}
                className={clsx(classes.row, {
                  [classes.lightRow]: index % 2 === 0,
                })}
              >
                <div className={classes.item}>
                  <div>
                    {reg.left?.region === "not selected"
                      ? t("Not selected")
                      : reg.left?.region}
                  </div>
                  <div>{reg.left?.views}</div>
                </div>
                <div className={classes.item} style={{ marginLeft: "119px" }}>
                  <div>
                    {reg.right?.region === "not selected"
                      ? t("Not selected")
                      : reg.right?.region}
                  </div>
                  <div>{reg.right?.views}</div>
                </div>
              </div>
            ))}
          </>
        )}
        {activeToggle.value === "Diagram" && (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={
                country === "World"
                  ? stats.map(v => ({
                      ...v,
                      region: v.country,
                    }))
                  : stats
              }
            >
              <XAxis
                height={210}
                tickMargin={110}
                tickFormatter={value =>
                  value === "not selected" ? t("Not selected") : value
                }
                dataKey="region"
                angle={90}
                stroke="#6c6c6c"
              />
              <Tooltip
                labelFormatter={value =>
                  value === "not selected" ? t("Not selected") : value
                }
                formatter={value => [`${value}`, "Views"]}
              />
              <YAxis />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <Bar
                alignmentBaseline={"middle"}
                dataKey="views"
                fill="var(--primary-color)"
                barSize={20}
                shape={<CustomBar />}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
        {activeToggle.value === "Map" && (
          <div className={classes.mapInfoContainer} style={{ width: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              {data.map(reg => (
                <div key={reg} className={clsx(classes.mapRow)}>
                  <div className={classes.mapTableItem}>
                    <div>
                      {reg.left?.region === "not selected"
                        ? t("Not selected")
                        : reg.left?.region}
                    </div>
                    <div
                      style={{
                        color: "#541690",
                      }}
                    >
                      {reg.left?.views}
                    </div>
                  </div>
                  <div
                    className={classes.mapTableItem}
                    style={{ marginLeft: "54px" }}
                  >
                    <div>
                      {reg.right?.region === "not selected"
                        ? t("Not selected")
                        : reg.right?.region}
                    </div>
                    <div
                      style={{
                        color: "#541690",
                      }}
                    >
                      {reg.right?.views}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Suspense
              fallback={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "15px",
                    fontWeight: "600",
                    padding: "20px",
                    width: "40%",
                  }}
                >
                  {t("Your country map has not been added yet")}
                </div>
              }
            >
              {Map ? (
                <SVGMap
                  map={Map}
                  className={`${classes.map} svg-map`}
                  locationClassName={MapItem}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "15px",
                    fontWeight: "600",
                    padding: "20px",
                    width: "40%",
                  }}
                >
                  {t("Your country map has not been added yet")}
                </div>
              )}
            </Suspense>
          </div>
        )}
      </div>
    </div>
  );
};

export default PeopleStatistic;
