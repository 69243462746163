import { memo } from "react";
import { FormControl } from "@mui/material";

import { AuthInput, AuthLabel } from "@components/Forms/components/ui";

import { Typography } from "../../../../views/blocks";

const Input = props => {
  const { label, name = "name", error = false, touched = false } = props;

  return (
    <FormControl fullWidth>
      {label && (
        <AuthLabel shrink htmlFor={name}>
          {label}
        </AuthLabel>
      )}
      <AuthInput {...props} name={name} id={name} />

      {touched && error && (
        <Typography variant="caption2" color="error">
          {error}
        </Typography>
      )}
    </FormControl>
  );
};

export default memo(Input);
