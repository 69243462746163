import { PrivacyPolicyDE } from "./privacy/DE";
import { PrivacyPolicyUK } from "./privacy/UK";
import { PrivacyPolicyEN } from "./privacy/EN";
import { TermsOfUseEN } from "./terms-of-use/EN";
import { TermsOfUseUK } from "./terms-of-use/UK";
import { TermsOfUseDE } from "./terms-of-use/DE";
import { OfferDE } from "./offer/DE";
import { OfferEN } from "./offer/EN";
import { OfferUK } from "./offer/UK";

export const termOfUse = {
  en: TermsOfUseEN,
  uk: TermsOfUseUK,
  de: TermsOfUseDE,
};
export const privacy = {
  en: PrivacyPolicyEN,
  uk: PrivacyPolicyUK,
  de: PrivacyPolicyDE,
};
export const offer = {
  en: OfferEN,
  uk: OfferUK,
  de: OfferDE,
};
