import axios from "axios";
import { refreshToken, signOut } from "@services/api/auth";
import { API_URL } from "../../config";
import { clearTokenData, getTokenData, setTokenData } from "@utils/tokens";

let isRefreshing = null;

async function requestInterceptor(config) {
  if (
    !config.headers ||
    !config.headers["Authorization"] ||
    !config.headers["Content-Type"]
  ) {
    if (!config.headers) {
      config.headers = {};
    }

    config.headers["Content-Type"] = "application/json";

    const { token } = getTokenData();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  return config;
}

const responseErrorInterceptor = async error => {
  if (error.response && error.response.status === 401) {
    if (error.config.url === "api/sign-out") {
      clearTokenData();

      return;
    }
    if (error.config.url === "auth/refresh-token") {
      clearTokenData();

      return;
    }
    try {
      if (!isRefreshing) {
        isRefreshing = refreshToken();
      }

      const {
        data: { token, refresh_token, session_id: newSessionId },
        status,
      } = await isRefreshing;

      if (status === 200) {
        setTokenData(
          { token, refresh_token, session_id: newSessionId },
          localStorage.getItem("isRemember") === "true",
        );

        return axiosInstance.request({
          ...error.config,
          headers: {
            ...(error.config.headers || {}),
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        clearTokenData();

        await signOut(newSessionId);
      }
    } catch (e) {
      clearTokenData();
    } finally {
      isRefreshing = null;
    }
  }

  throw error;
};

const createRequestHandler = () => {
  const instance = axios.create({ baseURL: API_URL });

  instance.interceptors.request.use(requestInterceptor);

  instance.interceptors.response.use(
    response => response,
    responseErrorInterceptor,
  );

  return instance;
};

export const axiosInstance = createRequestHandler();
