import React, { useState, useCallback, memo, Fragment } from "react";
import { Steps } from "intro.js-react";
import { connect } from "react-redux";
import { updateUserAction } from "@redux/user/actions/userActions";

import { introIds } from "../classes";

import { withTranslation } from "react-i18next";
import { Typography } from "../../../views/blocks";
import { Grid } from "@mui/material";
import Modal from "@components/Modal";
import Button from "@components/Button";
import { SettingsCompany } from "src/views/pages/CompanyListPage/blocks/CompanyForm/index";

import { useHistory } from "react-router-dom";

const IntroCompany = ({ t }) => {
  const history = useHistory();

  const [show, setShow] = useState(true);

  const intro = {
    initialStep: 0,
    stepsEnabled: true,
    options: {
      nextLabel: t("Next"),
      prevLabel: t("Back"),
      doneLabel: t("Next step"),
      disableInteraction: true,
      exitOnOverlayClick: false,
      scrollToElement: false,
    },
    steps: [
      {
        position: "left",
        element: `#${introIds.company.element1}`,
        intro: (
          <Fragment>
            <Typography variant="h5" weight="normal-bold" align="center">
              {t(
                "To fill out the form for a legal entity, select the tab marked “legal entity” at the top and enter the company information in the fields below.",
              )}
            </Typography>

            <Typography
              variant="body1"
              color="gray"
              align="center"
              className="mt-2"
            >
              {t(
                "(do not worry about the safety of your information, the Infocoin platform guarantees the protection of your privacy using strict protocols and modern technologies):",
              )}
            </Typography>
          </Fragment>
        ),
      },
      {
        element: `#${introIds.company.element2}`,
        intro: (
          <Fragment>
            <Typography variant="h5" weight="normal-bold" align="center">
              {t(
                "Upload a banner to decorate your page and tell more about you and your activities",
              )}
            </Typography>

            <Typography
              variant="body1"
              color="gray"
              align="center"
              className="mt-2"
            >
              {t("(recommended banner size is {{size}} pixels)", {
                size:
                  SettingsCompany.header.RECOMMENDED_SIZE.w +
                  "x" +
                  SettingsCompany.header.RECOMMENDED_SIZE.h,
              })}
            </Typography>
          </Fragment>
        ),
      },
      {
        element: `#${introIds.company.element3}`,
        position: "right",
        intro: (
          <Fragment>
            <Typography variant="h5" weight="normal-bold" align="center">
              {t("Upload an avatar: a photo or a picture describing you")}
            </Typography>

            <Typography
              variant="body1"
              color="gray"
              align="center"
              className="mt-2"
            >
              {t("(avatar size should not exceed {{size}} pixels)", {
                size:
                  SettingsCompany.avatar.FILE_SIZE.w +
                  "x" +
                  SettingsCompany.avatar.FILE_SIZE.h,
              })}
            </Typography>
          </Fragment>
        ),
      },
      {
        element: `#${introIds.company.element4}`,
        intro: (
          <Fragment>
            <Typography variant="h5" weight="normal-bold" align="center">
              {t('In the "company name" field, enter your full name')}
            </Typography>
          </Fragment>
        ),
      },
      {
        element: `#${introIds.company.element5}`,
        intro: (
          <Fragment>
            <Typography variant="h5" weight="normal-bold" align="center">
              {t(
                'In the "company description" field, you can write necessary information about you or your product that will help attract viewers to your company',
              )}
            </Typography>
          </Fragment>
        ),
      },
      {
        element: `#${introIds.company.element6}`,
        intro: (
          <Fragment>
            <Typography variant="h5" weight="normal-bold" align="center">
              {t(
                "Select from the list the country in which your company is registered",
              )}
            </Typography>
          </Fragment>
        ),
      },
      {
        element: `#${introIds.company.element7}`,
        intro: (
          <Fragment>
            <Typography variant="h5" weight="normal-bold" align="center">
              {t("Next, enter your company registration address")}
            </Typography>
          </Fragment>
        ),
      },
      {
        element: `#${introIds.company.element8}`,
        intro: (
          <Fragment>
            <Typography variant="h5" weight="normal-bold" align="center">
              {t(
                "In the line below, enter the company's tax identification number",
              )}
            </Typography>
            <Typography
              variant="body1"
              color="gray"
              align="center"
              className="mt-2"
            >
              {t(
                "(this is the value-added tax identification number or VAT identification number)",
              )}
            </Typography>
          </Fragment>
        ),
      },
      {
        element: `#${introIds.company.element9}`,
        intro: (
          <Fragment>
            <Typography variant="h5" weight="normal-bold" align="center">
              {t(
                "In the last line, enter the name of your company contact person",
              )}
            </Typography>
          </Fragment>
        ),
      },
    ],
  };

  const handleClose = useCallback(async () => {
    if (intro.stepsEnabled) {
      // const data = { ...user, is_tutorial_passed: true };
      // delete data.phone;
      // await updateUserAction(data);
    }
  }, [intro.stepsEnabled]);

  const handleSubmit = useCallback(() => {
    // setIntro(intro => ({
    //   ...intro,
    //   stepsEnabled: false,
    // }));
    // onComplete();
    history.push("/wallet");
  }, []);

  const onHide = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <Fragment>
      {show ? (
        <Modal
          show={show}
          onHide={onHide}
          contentClassName="modal-intro"
          dialogClassName="modal-intro-dialog"
        >
          <Grid container>
            <Grid item>
              <Typography variant="h5" weight="normal-bold">
                {t(
                  "All you need to do is to fill out, if you wish, the additional information about the company.",
                )}
              </Typography>
            </Grid>

            <Grid item mt={2}>
              <Typography variant="body1" color="gray">
                {t(
                  "The difference is that by placing the information as a legal entity, you will be able to pay for the advertisements from the company account. If you place information from an individual, you will only be able to pay for the advertisements using your personal bank card. Remember that the more precisely your company's information card is filled out, the more information the viewer will obtain about you.",
                )}
              </Typography>
            </Grid>
            <Grid
              item
              mt={2}
              display="flex"
              justifyContent="center"
              className="modal-intro__actions"
              width="100%"
            >
              <Button variant="primary" onClick={onHide}>
                <Typography variant="body2">{t("Continue")}</Typography>
              </Button>
            </Grid>
          </Grid>
        </Modal>
      ) : (
        <Steps
          enabled={intro.stepsEnabled}
          steps={intro.steps}
          initialStep={intro.initialStep}
          options={intro.options}
          onExit={handleClose}
          onComplete={handleSubmit}
          onBeforeChange={nextStepIndex => {
            document
              .querySelector(intro.steps[nextStepIndex].element)
              .scrollIntoView({ block: "center" });
          }}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = ({ user }) => ({
  user: user.user,
});

const mapDispatchToProps = {
  updateUserAction,
};

export default withTranslation()(memo(IntroCompany));
