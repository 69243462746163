import {
  CREATE_COMPANY,
  CREATE_COMPANY_SUCCESS,
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  UPDATE_COMPANY,
  UPDATE_COMPANY_ERROR,
  UPDATE_COMPANY_SUCCESS,
} from "@redux/companies/constants";

import { AUTH_LOGOUT } from "@redux/auth/constants";

const initialState = {
  companies: [],
  loading: false,
  error: {},
};

export default function companiesReduce(state = initialState, action = {}) {
  switch (action.type) {
    case GET_COMPANIES:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        loading: false,
      };
    case CREATE_COMPANY:
      return {
        ...state,
        loading: true,
      };
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: [...state.companies, action.payload],
        loading: false,
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        loading: false,
      };
    case UPDATE_COMPANY_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case AUTH_LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
}
