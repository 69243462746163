import values from "./breakpoints.module.scss";


const breakpoints = Object.keys(values).reduce((res, key) => {
    res[key] = parseInt(values[key], 10);

    return res;
}, {});


export {breakpoints};