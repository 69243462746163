import i18n from "../../../translates";

export const sortOptions = [
  { value: 10, label: "10" },
  { value: 20, label: "20" },
  { value: 30, label: "30" },
  { value: "full", label: i18n.t("Full list") },
];
export const lotStatuses = {
  paused: "Paused",
  deleted: "Deleted",
  active: "Online",
};
