import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "src/styles/components/Dashboard.scss";

import { useTranslation } from "react-i18next";
import SwitchToggle from "@components/SwitchToogle";
import PastStatistic from "@pages/Dashboard/components/PastStatistic";
import PlaylistStatistic from "@pages/Dashboard/components/PlaylistStatistic";

const Dashboard = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setActiveToggle(activeToggle => ({ ...activeToggle }));
  }, [t]);

  const [activeToggle, setActiveToggle] = useState({
    value: "Current",
    activeIndex: 0,
  });

  const onToggleChange = useCallback((value, index) => {
    setActiveToggle({ value, activeIndex: index });
  }, []);

  return (
    <Container className="dashboard-container">
      <div className="dashboard-container__actions">
        <h4 className="main-title">{t("Dashboard")}</h4>
        <SwitchToggle
          options={[
            { value: "Current", label: t("Current", { context: "statistic" }) },
            { value: "Past", label: t("Past", { context: "statistic" }) },
          ]}
          onChange={onToggleChange}
          activeIndex={activeToggle.activeIndex}
        />
      </div>
      {activeToggle.value === "Past" && <PastStatistic />}
      {activeToggle.value === "Current" && <PlaylistStatistic />}
    </Container>
  );
};

export default Dashboard;
