import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { StepsProvider, useSteps } from "react-step-builder";
import CreateLotModal from "src/components/CreateLotForm";
import { getAuctionLots } from "src/services/api/auctions";
import AuctionTable from "./components/AuctionTable";
import moment from "moment";
import Modal from "@components/Modal";
import classes from "./Auction.module.scss";
import { getCategories } from "@services/api/categories-api";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DatePicker from "./components/DatePicker";
import clsx from "clsx";
import {
  getAuctionsAction,
  getMinAmountAction,
  loadCategoriesAction,
  loadLotsAction,
  setAuctionAction,
  setDateAction,
  setLotToEditAction,
} from "@redux/auction/actions";

import { Link } from "react-router-dom";
import { getCompaniesAction } from "@redux/companies/actions";
import { useData } from "@pages/Auction/hooks/useData";
import Loader from "@components/Loader";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CountrySelect from "@components/Form/CountrySelect";
import {
  allAuctionsSelector,
  auctionDateSelector,
} from "@redux/auction/selector";
import { userSelector } from "@redux/user/selectors";
import { Grid } from "@mui/material";
import Button from "@components/Button";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const Auction = ({ t }) => {
  const today = moment(new Date()).format("DD.MM.YYYY");
  const { jump } = useSteps();

  const dispatch = useDispatch();

  const activeAuctionId = useSelector(
    state => state.auction.activeAuctionId,
    shallowEqual,
  );
  const companies = useSelector(
    state => state.companies.companies,
    shallowEqual,
  );

  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isShowAll, setIsShowAll] = useState(false);
  const allAuctions = useSelector(allAuctionsSelector, shallowEqual);
  const date = useSelector(auctionDateSelector, shallowEqual);
  const user = useSelector(userSelector, shallowEqual);
  const [auctionCountry, setAuctionCountry] = useState(
    user?.country || "Cyprus",
  );
  const data = useData();

  const handleChangeDate = useCallback(
    date => {
      dispatch(setDateAction(date));
    },
    [dispatch],
  );

  const resultData = useMemo(() => {
    if (data.length > 10 && !isShowAll) {
      return [
        ...data.slice(0, 5),
        { isSeparator: true, category_id: 0 },
        ...data.slice(data.length - 5),
      ];
    }
    return data;
  }, [data, isShowAll]);

  const toggleShowModal = useCallback(() => setShowModal(prev => !prev), []);

  const handleDateChange = useCallback(
    async date => {
      const formatDate = moment(date).format("DD.MM.YYYY");
      const currentAuction = allAuctions.filter(
        auction =>
          moment(auction.started_at).format("DD.MM.YYYY") === formatDate,
      );
      if (currentAuction.length) {
        handleChangeDate(date);
        dispatch(setAuctionAction(currentAuction[0].id));
      } else {
        toast.warning(t("There aren't any auction on this day"));
      }
    },
    [allAuctions, dispatch, t, handleChangeDate],
  );
  const hideEditModal = useCallback(() => {
    toggleShowModal();
    dispatch(setLotToEditAction(null));

    jump(1);
  }, [toggleShowModal, jump]);

  const onCountryChange = useCallback(country => {
    setAuctionCountry(country.name);

    setIsLoading(true);

    dispatch(getMinAmountAction(country.name));

    dispatch(getAuctionsAction(country.name)); //eslint-disable-next-line
  }, []);

  const handleToggleShow = useCallback(async () => {
    setIsShowAll(show => !show);
    //eslint-disable-next-line
  }, []);

  const modalHeaderText = useMemo(() => t("Add Lot"), []);

  useEffect(() => {
    const initialLoad = async () => {
      if (!companies.length) dispatch(getCompaniesAction());
      try {
        setIsLoading(true);
        dispatch(getAuctionsAction(auctionCountry));
        const categories = await getCategories();

        dispatch(getMinAmountAction(auctionCountry));

        dispatch(loadCategoriesAction(categories.data));
      } catch (e) {
        console.log(e);
      }
    };
    initialLoad(); //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const loadLots = async () => {
      if (!activeAuctionId) return;
      const response = await getAuctionLots(activeAuctionId);

      dispatch(loadLotsAction(response.data));
      setIsLoading(false);
    };
    if (activeAuctionId) {
      setIsLoading(true);
      loadLots();
    }

    //eslint-disable-next-line
  }, [activeAuctionId]);

  return (
    <Container className={clsx(classes.auction)}>
      {isLoading && <Loader width={150} height={150} isPageLoader />}

      {/* <div>
        {!isLoading && (
          <h4 className={`main-title ${classes.title}`}>{t("Auction")}</h4>
        )}
      </div> */}

      {!isLoading && companies.length === 0 && (
        <React.Fragment>
          <h3 className="p-2 mt-5 w-100 text-center">
            {t("You are not the owner of any company")}
          </h3>
          <Link to="/companies" className="mt-3 d-flex justify-content-center">
            <button className={classes.auctionButton}>
              {t("Create a company")}
            </button>
          </Link>
        </React.Fragment>
      )}

      {!isLoading && companies.length > 0 && (
        <React.Fragment>
          <h4 className={`main-title ${classes.title}`}>{t("Auction")}</h4>
          <Grid className={classes.actions}>
            <Grid className={classes.actions__left}>
              <Grid>
                <CountrySelect
                  containerClassName={classes.actions__left_country}
                  withNameInValue
                  className={classes.country}
                  onChange={onCountryChange}
                  value={auctionCountry}
                />
              </Grid>
              <Grid>
                <DatePicker
                  date={date}
                  onDataChange={handleDateChange}
                  today={today}
                  placeholder={t("Schedule an auction")}
                />
              </Grid>
            </Grid>
            <Grid className={classes.actions__right}>
              <Button onClick={toggleShowModal}>{t("Add Lot")}</Button>
            </Grid>
          </Grid>
          <AuctionTable
            isLoading={isLoading}
            onAddLot={toggleShowModal}
            data={resultData}
          />
          {resultData.length && resultData.length > 10 ? (
            <Grid display="flex" justifyContent="center" mt={3}>
              <Button
                onClick={handleToggleShow}
                variant="outline"
                className={classes.tableButtonAllShow}
              >
                {isShowAll ? (
                  <>
                    {t("Turn short list")}
                    <KeyboardArrowUp />
                  </>
                ) : (
                  <>
                    {t("Show full list")}
                    <KeyboardArrowDown />
                  </>
                )}
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </React.Fragment>
      )}

      <Modal
        show={showModal}
        onHide={hideEditModal}
        className={classes.auctionModal}
        headerText={modalHeaderText}
        dialogClassName={classes.modalDialog}
        contentClassName={classes.modalContent}
      >
        <StepsProvider>
          <CreateLotModal
            hideEditModal={hideEditModal}
            country={auctionCountry}
          />
        </StepsProvider>
      </Modal>
      {/*<ModalIntro />*/}
    </Container>
  );
};

export default withTranslation()(memo(Auction));
