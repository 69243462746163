import {
  UPDATE_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  GET_USER,
  GET_USER_SUCCESS,
} from "@redux/user/constants";
import { AUTH_LOGOUT } from "@redux/auth/constants";

const initialState = {
  user: {},
  loading: false,
  error: {},
};

export default function userReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case AUTH_LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
}
