import { axiosInstance } from "@helpers/http/httpInstance";
import { prepareAxiosError } from "src/utils";

export const getVideo = id => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosInstance.get(`ads/${id}.mp4`);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const DemoSaveData = async data => {
  try {
    const { ...otherData } = data;

    const res = await axiosInstance.post(
      `/demo/demo-videos`,
      {
        ...otherData,
      },
      {
        validateStatus: () => true,
      },
    );

    if ([200, 201].includes(res.status)) {
      return {
        status: "OK",
        data: res.data,
      };
    }

    return prepareAxiosError(res);
  } catch (err) {
    return {
      status: "ERROR",
      message: err.message,
    };
  }
};

export const uploadVideo = async (id, file) => {
  try {
    const formData = new FormData();

    formData.append("video_id", id);
    formData.append("file", file);

    const res = await axiosInstance.post(`/api/video/upload`, formData, {
      validateStatus: () => true,
    });

    if ([200, 201].includes(res.status)) {
      return {
        status: "OK",
        message: "Video was loaded",
      };
    }

    return prepareAxiosError(res);
  } catch (err) {
    return {
      status: "ERROR",
      message: err.message,
    };
  }
};

export const DemoUploadVideo = async (id, file) => {
  try {
    const formData = new FormData();

    formData.append("video_id", id);
    formData.append("file", file);

    const res = await axiosInstance.post(`/demo/demo-videos/upload`, formData, {
      validateStatus: () => true,
    });

    if ([200, 201].includes(res.status)) {
      return {
        status: "OK",
        message: "Video was loaded",
      };
    }

    return prepareAxiosError(res);
  } catch (err) {
    return {
      status: "ERROR",
      message: err.message,
    };
  }
};

export const getVideoWithStatus = ({ limit, offset }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.get(`api/videos`, {
        params: {
          limit,
          offset,
        },
      });
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const getDemoVideoWithStatus = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.get(`demo/demo-videos`);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const updateInfoVideo = async (data, options) => {
  const { age_min, age_max, ...otherData } = data;

  return axiosInstance.put(
    `/api/video/${data.id}`,
    {
      age_min: parseInt(age_min) || 0,
      age_max: parseInt(age_max) || 0,
      ...otherData,
    },
    options,
  );
};

export const updateDemoInfoVideo = async (data, options) => {
  const { ...otherData } = data;

  return axiosInstance.put(
    `/demo/demo-videos/${data.id}`,
    {
      ...otherData,
    },
    options,
  );
};

export const getVideoCategories = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.get(`api/categories`);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};
export const saveData = async data => {
  try {
    const { age_min, age_max, ...otherData } = data;

    const res = await axiosInstance.post(
      `/api/video`,
      {
        age_min: parseInt(age_min) || 0,
        age_max: parseInt(age_max) || 0,
        ...otherData,
      },
      {
        validateStatus: () => true,
      },
    );

    if ([200, 201].includes(res.status)) {
      return {
        status: "OK",
        data: res.data,
      };
    }

    return prepareAxiosError(res);
  } catch (err) {
    return {
      status: "ERROR",
      message: err.message,
    };
  }
};

export const getSpeechLanguages = async () => {
  return axiosInstance.get(`api/speech-languages`);
};

export const getVideoDurationApi = async country => {
  return axiosInstance.get(`api/video-durations/${country}`);
};

export const getEstimatedViews = async data => {
  return axiosInstance.post(`api/auctions/advance-audience`, data);
};

export const getRecommendedVideo = async country => {
  return axiosInstance.get(`/api/videos/recommended/${country}`);
};

export const createVideoFromTemplate = async data => {
  return axiosInstance.post(`/api/video/template`, data);
};

export const updateVideo = async ({ id, data }) => {
  return await axiosInstance.put(`/api/video/${id}`, data);
};
export const getGenders = () => {
  return axiosInstance.get(`/api/genders`);
};

export const getVideoCountryCategoryDurations = async category_id => {
  return await axiosInstance.get(`/api/vccd/category/${category_id}`);
};
