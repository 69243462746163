import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import ScrollBar from "src/views/blocks/ScrollBar";
import TextField from "../../../../../components/Form/TextField";
import { VideoInput } from "../../../../inputs";
import Select from "@components/Form/Select";
import CountrySelect from "@components/Form/CountrySelect";
import classes from "@pages/Account/Account.module.scss";
import Button from "@components/Button";
import { VideoFormPreview } from "../Preview";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const DemoFirstStep = ({ data, onSubmit, companies, isEdit }) => {
  const { t } = useTranslation();

  const CreateDemoVideoSchema = Yup.object().shape({
    title: Yup.string().required("A title is required"),
    description: Yup.string().required("A description is required"),
    isEdit: Yup.boolean(),
    video: Yup.mixed().when("isEdit", {
      is: false,
      then: Yup.mixed().required("A video is required"),
    }),
    company_id: Yup.number().required("A company is required"),
    country_id: Yup.mixed().required("A country is required"),
    email: Yup.string().email("Invalid email"),
    url: Yup.string().url("Invalid url"),
  });

  const {
    values,
    setFieldValue,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    setFieldTouched,
    setSubmitting,
    isValid,
  } = useFormik({
    initialValues: { ...data, isEdit: isEdit ? true : false },
    validationSchema: CreateDemoVideoSchema,
    onSubmit: onSubmit,
  });

  const handlePublish = useCallback(() => {
    if (isValid) {
      setSubmitting(true);
    } else {
      toast.error(t("Incorrectly filled data"));
      console.log(errors);
    }

    handleSubmit();
  }, [isValid, setSubmitting, handleSubmit, t]);

  const handleChangeGoal = useCallback(
    value => {
      setFieldValue("goal", value);
      if (value === "url") {
        setFieldValue("url", "");
      } else {
        setFieldValue("email", "");
      }
    },
    [setFieldValue],
  );

  useEffect(() => {
    // to avoid bugs in the formik

    if (!values.title) {
      setFieldValue("country", data.country);
    }
  }, []);

  return (
    <Grid container spacing={4} alignItems="stretch">
      <Grid item container md={6}>
        <ScrollBar className="scroll-content-form">
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <TextField
                warningLabel
                onChange={handleChange}
                value={values.title}
                touched={touched.title}
                error={errors.title}
                onBlur={handleBlur}
                fullWidth
                placeholder={t("Content Title")}
                label={t("Title")}
                name="title"
              />
            </Grid>
            <Grid item>
              <TextField
                warningLabel
                multiline={true}
                value={values.description}
                rows={5}
                type="textarea"
                fullWidth
                error={errors.description}
                touched={touched.description}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("Up to 500 characters")}
                label={t("Description")}
                name="description"
              />
            </Grid>
            {!isEdit && (
              <Grid item>
                <VideoInput
                  warningLabel
                  value={values.video}
                  touched={touched.video}
                  error={errors.video}
                  onBlur={() => setFieldTouched("video", true)}
                  onChange={value => setFieldValue("video", value)}
                  label={t("Video")}
                  name="video"
                />
              </Grid>
            )}
            <Grid item>
              <Select
                warningLabel
                options={companies.map(company => {
                  const { description = "" } = company;

                  return {
                    label: company.title,
                    secondary:
                      description.length > 27
                        ? description.substring(0, 27) + "..."
                        : description,
                    value: company.id,
                  };
                })}
                fullWidth
                value={values.company_id}
                placeholder={t("Select company")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.company_id}
                label={t("Company")}
                touched={touched.company_id}
                name="company_id"
              />
            </Grid>
            <Grid item>
              <CountrySelect
                warningLabel
                withNameInValue
                value={values.country_id}
                containerClassName={classes.selectContainer}
                onChange={value => setFieldValue("country_id", value.id)}
                placeholder={t("Select country")}
                label={t("Country of broadcast or show")}
                touched={touched.country_id}
                error={errors.country_id}
                onBlur={handleBlur}
                name="country_id"
              />
            </Grid>

            <Grid item>
              <Select
                fullWidth
                options={[
                  {
                    label: t("Following a link"),
                    value: "url",
                  },
                  {
                    label: t("Sending messages by mail"),
                    value: "email",
                  },
                ]}
                placeholder={t("Select")}
                label={t("Objectives of interaction")}
                name="goal"
                value={values.goal}
                onChange={e => handleChangeGoal(e.target.value)}
                onBlur={handleBlur}
              />
            </Grid>
            {values.goal === "url" && (
              <Grid item>
                <TextField
                  value={values.url}
                  touched={touched.url}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.url}
                  fullWidth
                  type="url"
                  label={t("Link")}
                  name="url"
                />
              </Grid>
            )}
            {values.goal === "email" && (
              <Grid item>
                <TextField
                  value={values.email}
                  touched={touched.email}
                  onChange={handleChange}
                  error={errors.email}
                  onBlur={handleBlur}
                  fullWidth
                  label={t("E-mail")}
                  name="email"
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <Button
                type="submit"
                onClick={handlePublish}
                size="large"
                fullWidth
                loading={isSubmitting}
              >
                {isEdit ? t("Update") : t("Publish")}
              </Button>
            </Grid>
          </Grid>
        </ScrollBar>
      </Grid>
      <Grid item container md={6} className="video-right-content">
        <ScrollBar className="scroll-content-form">
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <VideoFormPreview
                values={values}
                isEdit={isEdit}
                companies={companies}
              />
            </Grid>
          </Grid>
        </ScrollBar>
      </Grid>
    </Grid>
  );
};

export { DemoFirstStep };
