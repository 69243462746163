/* eslint-disable no-nested-ternary */
import React from "react";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputBase,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { Done } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export const Input = styled(InputBase)(
  ({ isTouched, error, style, variant }) => ({
    root: {
      width: "200px",
      position: "relative",
      outline: "none !important",
      fontSize: "16px !important",
      height: "50px",
      padding: "4px 0 5px !important",
      fontFamily: ["Mont Blanc", "-apple-system", "sans-serif"].join(","),
      color: isTouched && error ? "red" : "#1D1D1F",
      ...style,
    },
    "& .MuiInputBase-input": {
      borderRadius: "10px",
      boxSizing: "border-box",
      fontSize: "16px",
      height: "50px",
      color: isTouched && error ? "red" : "#1D1D1F",
      position: "relative",
      backgroundColor: "white",
      padding: "11px 40px 11px 19px",
      transition: "all 0.4s",
      outline: "none !important",
      fontFamily: ["Mont Blanc", "-apple-system", "sans-serif"].join(","),
      background: variant === "grey" ? "#F0F1F7" : "white",
      "&::placeholder": {
        opacity: 1,
        color: "#6C6C6C",
      },
      "&:focus": {
        boxShadow: `${alpha("#1D1D1F", 0.05)} 0 0 0 0.1rem`,
      },
      "&:read-only": {
        background: `${alpha("#1D1D1F", 0.05)}`,
      },
    },
  }),
);

const ValidIcon = ({ valid }) => (
  <InputAdornment
    position={"end"}
    sx={{
      position: "absolute",
      right: "15px",
    }}
  >
    {valid ? <Done color="warning" /> : null}
  </InputAdornment>
);

const TextField = ({
  variant = "outlined",
  label,
  name,
  ref,
  value,
  touched,
  id,
  onChange,
  error,
  sx,
  containerClassName,
  placeholder,
  warningLabel = false,
  onBlur,
  onFocus,
  multiline = false,
  rows = 4,
  helperText,
  endAdornment,
  errorStyle,
  readOnly,
  ...input
}) => {
  const { t } = useTranslation();

  return (
    <FormControl
      className={containerClassName}
      sx={sx}
      fullWidth
      variant="standard"
    >
      {label && (
        <div
          className="mediaLabel warning-label"
          style={{
            fontFamily: "Mont Blanc , sans-serif",
            fontSize: "20px",
            color: "#1D1D1F",
            fontWeight: 600,
            paddingLeft: "18px",
            paddingBottom: "4px",
          }}
        >
          {t(label)}
        </div>
      )}
      <Input
        variant={variant}
        multiline={multiline}
        name={name}
        ref={ref}
        value={value}
        rows={rows}
        id={id}
        fullWidth
        margin="normal"
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder && t(placeholder)}
        error={!!error}
        endAdornment={endAdornment || <ValidIcon valid={!error && touched} />}
        isTouched={!!touched}
        onChange={onChange}
        {...input}
        readOnly={readOnly}
      />
      {touched && error && (
        <FormHelperText
          sx={{
            color: "red",
            fontSize: "12px",
            marginTop: multiline ? "0" : "4px !important",
            marginLeft: "4px !important",
            fontWeight: 500,
            ...errorStyle,
          }}
        >
          {t(error)}
        </FormHelperText>
      )}
      <FormHelperText>{t(helperText)}</FormHelperText>
    </FormControl>
  );
};

export default TextField;
