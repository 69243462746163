import React from "react";

import { Icon } from "src/views/blocks";

import "./index.scss";

const BankIcon = props => {
  const { className = "", ...rest } = props;

  return <Icon {...rest} className={`bank-icon ${className}`} mask />;
};

export { BankIcon };
