import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {DemoSaveData, DemoUploadVideo, saveData, uploadVideo,} from "src/services/api/video/video-api";
import {useShowMessage} from "src/hooks";
import {Button} from "src/views/blocks";

import {DemoFirstStep, FirstStep, SuccessBlock} from "./blocks";

import "./index.scss";

import {
  getDemoVideosAction,
  getVideosAction,
  getVideosCategoriesAction,
  getVideosSpeechLanguagesAction,
} from "@redux/content/contentActions";
import {getDemoCompaniesAction} from "@redux/companies/actions";

const VideoStepForm = props => {
  const {onClose, data, setData, step, setStep} = props;

  const dispatch = useDispatch();
  const {t} = useTranslation();
  const showMessage = useShowMessage();

  const role = useSelector(state => state.user.user.role);
  const {companies} = useSelector(state => state.companies);
  const {categories, speech_languages} = useSelector(state => state.content);

  const handleSubmit = useCallback(
      async result => {
        const {
          goal,
          link,
          email,
          video,
          title,
          description,
          categories,
          company_id,
          regions,
          is_all_country,
          speech_language,
          cities,
          genders,
          ...sendData
        } = {
          ...result,
        };

        try {
          const res = await saveData({
            ...sendData,
            title,
            description,
            categories_ids: categories.map(category => {
              return category.value;
            }),
            is_all_country,
            company_id,
            genders_ids: genders,
            goal,
            ...(regions ? {regions_ids: regions.map(r => r.value)} : {}),
            ...(cities ? {cities_ids: cities.map(c => c.value)} : {}),
            ...(goal === "link" ? {link} : {}),
            ...(goal === "email" ? {email} : {}),
            is_include_voice_speech: !!speech_language,
            speech_language,
          });

          showMessage(res);

          if (res.status === "OK") {
            const resVideo = await uploadVideo(res.data.id, video);

            showMessage(resVideo);

            if (resVideo.status === "OK") {
              setStep(3);
              await dispatch(getVideosAction());
            }
          }
        } catch (err) {
          showMessage({
            status: "ERROR",
            message: err.message,
          });
        }
      },
      [dispatch, showMessage, setStep],
  );

  const handleDemoSubmit = useCallback(
      async result => {
        const {video} = result;
        try {
          const res = await DemoSaveData(result);

          showMessage(res);

          if (res.status === "OK") {
            const vres = await DemoUploadVideo(res.data.id, video);

            showMessage(vres);

            if (vres.status === "OK") {
              await dispatch(getDemoVideosAction());

              setStep(3);
            }
          }
        } catch (err) {
          showMessage({
            status: "ERROR",
            message: err.message,
          });
        }
      },
      [dispatch, showMessage, setStep],
  );

  const handleClose = useCallback(() => {
    onClose()
    setStep(1)
  }, [onClose])

  useEffect(() => {
    if (role !== "demo_user") {
      if (!categories.length) {
        dispatch(getVideosCategoriesAction());
      }

      if (!speech_languages.length) {
        dispatch(getVideosSpeechLanguagesAction());
      }
    } else {
      if (!companies.length) {
        dispatch(getDemoCompaniesAction());
      }
    }
  }, []);

  const demoDefaultData = {
    title: "",
    description: "",
    country_id: "",
    company_id: "",
    video: "",
    email: "",
    url: "",
  };

  return (
      <div className="video-step-form">
        <div className="video-step-form__content">
          {role === "demo_user" ? (
              <>
                {step === 1 && (
                    <DemoFirstStep
                        companies={companies}
                        data={demoDefaultData}
                        categories={categories}
                        onSubmit={handleDemoSubmit}
                    />
                )}
              </>
          ) : (
              <>
                {step === 1 && (
                    <FirstStep
                        data={data}
                        companies={companies}
                        languages={speech_languages}
                        categories={categories}
                        setData={setData}
                        onSubmit={handleSubmit}
                    />
                )}
              </>
          )}
          {step === 3 && (
              <SuccessBlock>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleClose}
                >
                  {t("Close")}
                </Button>
              </SuccessBlock>
          )}
        </div>
      </div>
  );
};

export {VideoStepForm};
