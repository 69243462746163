import React from "react";
import LeftSidebar from "@components/LeftSidebar";
import RightPanel from "@components/RightPanel";
import MobileHeader from "@components/MobileHeader";

const ApplicationContainer = ({ children }) => {
  return (
    <div className="d-flex main-container  justify-content-center bg-main">
      <MobileHeader />
      <LeftSidebar />
      <div className="mobile-padding">
        <div className="d-flex align-items-start h-100 w-100">
          <div
            id={"root-scroll"}
            className="bg-brown w-100 h-100 left-border page"
          >
            {children}
          </div>
          <RightPanel />
        </div>
      </div>
    </div>
  );
};

export default ApplicationContainer;
