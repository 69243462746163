import { SET_AUCTIONS_ERROR } from "@redux/auction/constants";
import { toast } from "react-toastify";
import { createVideoFromTemplate } from "@services/api/video/video-api";
import { getVideosAction } from "@redux/content/contentActions";

export const createVideoFromTemplateAction = data => async dispatch => {
  try {
    const res = await createVideoFromTemplate(data);

    await dispatch(getVideosAction());

    return res.data;
  } catch (e) {
    dispatch({
      type: SET_AUCTIONS_ERROR,
      payload: e.response?.data?.error || e.message,
    });
    toast.error(e.response.data.error || e.message);
  }
};
