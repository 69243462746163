export const PrivacyPolicyEN = `
<!DOCTYPE html>
<html>
<head>
\t<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
\t<title></title>
\t<meta name="generator" content="LibreOffice 7.4.2.3 (Linux)"/>
\t<meta name="author" content="user"/>
\t<meta name="created" content="2021-05-12T06:53:00"/>
\t<meta name="changedby" content="Пользователь Windows"/>
\t<meta name="changed" content="2022-12-09T20:00:00"/>
\t<meta name="AppVersion" content="15.0000"/>
\t<meta name="GrammarlyDocumentId" content="ffa72849aed8fe962652075b1d426b4ee9692c2d287242489dda742c6d06b9b2"/>
\t<style type="text/css">
\t\t@page { size: 8.27in 11.69in; margin-left: 0.98in; margin-right: 0.59in; margin-top: 0.59in; margin-bottom: 0.69in }
\t\t@page p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
\t\t@page a:link { color: #0563c1; text-decoration: underline }
\t</style>
</head>
<body lang="en-US" link="#0563c1" vlink="#800000" dir="ltr"><p style="line-height: 108%; margin-bottom: 0.11in">
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>Privacy
Policy Infocoin Limited </b></font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited takes customer privacy very seriously. We are currently doing
our best to comply with Regulation 2016/679, the General Data
Protection Regulation (hereinafter referred to as GDPR), which sets
the world's highest privacy and information protection standards.
This Privacy Policy sets out the types of information we collect, why
we collect it, how we use it, and whom we may disclose it to. In
addition, it outlines the rights that users have as data subjects and
how they can be implemented.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">This
privacy policy applies to all cases of data processing performed by
us in connection with the provision of our services through Internet
sites, mobile versions of Internet sites (hereinafter - the
&quot;Website&quot;), mobile applications for iOS and Android
(hereinafter – the App</font></font> <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">and
other Internet portals owned, operated, operated under the trademark
INFOCOIN or accessed by Infocoin Limited, identified by the unified
index of the resource Infocoin.online and any other sites on our
domain with anyone under the domain).</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>1.
Who we are</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Our
company Infocoin Limited (a company incorporated in the Republic of
Cyprus, registration number HE 433674, Anexartisias &amp; Athinon,
NORA COURT, 2nd floor, Flat/Office 203, 3040 Limassol, Cyprus) and is
the Operator of Personal Data. </font></font>
</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>2.
Terms used in this privacy policy</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>Personal
Data</u></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
is any information related to a directly or indirectly identified or
identifiable natural person. If our company has the means to identify
the client and even their device, any information in our possession
related to this client is considered Personal Data.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>Personal
Data operator</u></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
– a person who determines the purposes and means of processing
Personal Data. For instance, our company acts as a Personal Data
Operator, processing Customers' Personal Data for the purposes set
out in this Privacy Policy. During the use of our website or
application, we share the user’s Personal Data with another
Personal Data Controller, whether it be an advertiser or other
service provider, who in turn uses the customer's Personal Data for
their own purposes. All Personal Data Controllers are required to
have a Privacy Policy similar to this one to inform customers about
how their Personal Data is processed. The following will provide
general information about the Personal Data Controllers to whom we
may disclose Personal Data.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>The
Personal Data Processor </u></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">is
a third party who only helps to achieve the goals set by the Personal
Data Operator. As a Personal Data Controller, our company uses the
services of numerous third parties, entrusting them with certain
activities we do not carry out for one reason or another (for
example, for reasons of economic efficiency). The Personal Data
Processor is only permitted to process your Personal Data following
our written instructions.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>Third
countries</u></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
are countries that are not subject to the legal regime of the GDPR.
For the time being, under Third countries, we mean all countries
outside the European Economic Area.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.
Personal Data we collect</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">We
receive your Personal Data directly from you when you provide us with
your Personal Data or collect them themselves when you use our
services or interact with us. Depending on the processing purpose, we
can process the following Personal Data categories.</font></font></p>
<table width="650" cellpadding="7" cellspacing="0">
\t<col width="134"/>

\t<col width="486"/>

\t<tr valign="top">
\t\t<td width="134" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Personal
\t\t\tData Category</font></font></p>
\t\t</td>
\t\t<td width="486" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Description</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="134" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Identification
\t\t\tinformation</font></font></p>
\t\t</td>
\t\t<td width="486" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Information
\t\t\tused to identify you as a natural person, such as your first name,
\t\t\tlast name, gender, nationality, billing address and date of birth,
\t\t\tas well our artificially created online identifier, e.g.,
\t\t\tidentifier in Infocoin Limited systems.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="134" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Contact
\t\t\tinformation</font></font></p>
\t\t</td>
\t\t<td width="486" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Information
\t\t\tused to contact you, such as email address or phone number.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="134" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Your
\t\t\tbehavior when interacting with us online</font></font></p>
\t\t</td>
\t\t<td width="486" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Your
\t\t\tbehavior on our Website and in our Application: what you visited,
\t\t\tfor how long you stayed there, what clicked on, etc. We also
\t\t\tmonitor your response to emails and notifications we send – for
\t\t\texample, whether you open emails and click the links they contain.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="134" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Devices
\t\t\tand networks metadata</font></font></p>
\t\t</td>
\t\t<td width="486" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Information
\t\t\tabout the device you use to access our Website or the device where
\t\t\tour App is installed; metadata related to your network connection,
\t\t\tand information flowing from these data. Such information
\t\t\tincludes, in particular, information about your operating system,
\t\t\tweb browser, permission screen, IP address, etc.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="134" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Account
\t\t\tinformation</font></font></p>
\t\t</td>
\t\t<td width="486" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Settings
\t\t\tand other data you create while using Infocoin Account, such how
\t\t\tnotifications, history search, specific settings, image profile,
\t\t\taccount data you use to log in to your Infocoin account, namely,
\t\t\temail address and password (we never store unencrypted passwords),
\t\t\tpreferences, and others data stored in Infocoin Account.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="134" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Payment
\t\t\tinformation</font></font></p>
\t\t</td>
\t\t<td width="486" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">The
\t\t\tinformation you provide for payment. Usually, this is bank cards
\t\t\tdata. We never store unencrypted bank cards data.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="134" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Information
\t\t\tabout your requests and interactions with us</font></font></p>
\t\t</td>
\t\t<td width="486" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">All
\t\t\tcorrespondence between you and Infocoin Limited associated with
\t\t\tyour requests (for example, client support tickets), metadata, and
\t\t\tnotes created by our systems and specialists.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="134" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Your
\t\t\tsettings</font></font></p>
\t\t</td>
\t\t<td width="486" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Some
\t\t\tfrom your settings relating to the Website or Application – for
\t\t\texample, language settings, currency preferences, destination and
\t\t\tother, as well as settings regarding files cookie.</font></font></p>
\t\t</td>
\t</tr>
</table>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>4.
Purposes of data use</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>Purposes
of using User data</u></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.
Every time you access our Website or our App, we process your
Personal Data for the purposes set out below.</font></font></p>
<table width="650" cellpadding="7" cellspacing="0">
\t<col width="89"/>

\t<col width="203"/>

\t<col width="137"/>

\t<col width="163"/>

\t<tr>
\t\t<td width="89" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Name</font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Description</font></font></p>
\t\t</td>
\t\t<td width="137" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Category
\t\t\tof Personal Data</font></font></p>
\t\t</td>
\t\t<td width="163" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Legal
\t\t\tbasis</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="89" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ensuring
\t\t\tthe Website and App functionality</font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">We
\t\t\tprocess your Personal Data to provide you with the functionality
\t\t\tof our Website and App.</font></font></p>
\t\t</td>
\t\t<td width="137" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Your
\t\t\tbehavior when interacting with us online;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"> <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Device
\t\t\tand network metadata;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Your
\t\t\tsettings.</font></font></p>
\t\t</td>
\t\t<td width="163" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Article
\t\t\t6 paragraph 1 (f) – for the purposes of realizing the legitimate
\t\t\tinterests of Infocoin (providing product functionality).</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="89" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Product
\t\t\tdevelopment, service improvement, and business development</font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">We
\t\t\tstrive to continuously improve our products and services. To do
\t\t\tthis, we need accurate data about your interactions with us.
\t\t\tTherefore, we collect data about your device and your network
\t\t\tidentifiers, as well as your behavior on our Website and our App.
\t\t\tWe analyze all this data and use it to create or modify our
\t\t\tfunctionality and processes. We also use data to grow our
\t\t\tbusiness. Every time we need to make a business decision, we look
\t\t\tat the data generated by the most important part of our business –
\t\t\tour customers.</font></font></p>
\t\t</td>
\t\t<td width="137" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Your
\t\t\tbehavior when interacting with us online;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Device
\t\t\tand network metadata.</font></font></p>
\t\t</td>
\t\t<td width="163" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Article
\t\t\t6 paragraph 1 (f) – for the purposes of realizing the legitimate
\t\t\tinterests of Infocoin (design and development of products,
\t\t\tservices, and business).</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="89" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">InfoCoin
\t\t\taccount</font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">When
\t\t\tyou create an Infocoin Account, we process your Personal Data to
\t\t\tprovide you with all of its functionality in accordance with the
\t\t\tTerms of Use. The amount of Personal Data processed varies
\t\t\tdepending on what data you store.</font></font></p>
\t\t</td>
\t\t<td width="137" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Contact
\t\t\tInformation;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Account
\t\t\tInformation;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Identification
\t\t\tinformation;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Payment
\t\t\tinformation.</font></font></p>
\t\t</td>
\t\t<td width="163" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Article
\t\t\t9 paragraph 1 (b) – the need to conclude and implement a
\t\t\tcontract (Terms of Use).</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="89" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Direct
\t\t\tMarketing</font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">We
\t\t\tprocess Customers' Personal Data for direct marketing purposes to
\t\t\tmatch the most relevant offers to customers and maximize marketing
\t\t\teffectiveness (including email offers and related information
\t\t\tprocessing activities). This is done if you have signed up to
\t\t\treceive our offers (based on your consent) or used our services
\t\t\tand have not rejected our offers (have not opted out of our
\t\t\toffers). In addition to your contact information, we also store
\t\t\tthe following data: transaction history, preferences, and other
\t\t\tdata about your interactions with us, which helps us segment
\t\t\tcustomers and personalize offers. Based on the history of feedback
\t\t\tand interaction, we can approach the Client with an individual
\t\t\tpersonally tailored offer.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">In
\t\t\taddition, we may send emails to customers who provide their
\t\t\tdetails. We only store the Personal Data collected in this way for
\t\t\t30 days.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">We
\t\t\tdo not, under any circumstances, disclose the Client's contact
\t\t\tinformation to other Personal Data Operators without their
\t\t\tknowledge and address clients only with the offers related to our
\t\t\tprimary business.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">To
\t\t\tensure you don't miss out on our offers, in addition to direct
\t\t\tmessages, we send push notifications through our Website and App
\t\t\t(with your consent).</font></font></p>
\t\t</td>
\t\t<td width="137" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Your
\t\t\tbehavior when interacting with us online;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Account
\t\t\tInformation;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Device
\t\t\tand network metadata;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Identification
\t\t\tinformation (if entered in the fields);</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Contact
\t\t\tdetails (if entered in the fields);</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Your
\t\t\tsettings.</font></font></p>
\t\t</td>
\t\t<td width="163" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Article
\t\t\t6 paragraph 1 (f) – for the purposes of realizing the legitimate
\t\t\tinterests of Infocoin (direct marketing).</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Article
\t\t\t6, paragraph 1 (a), consent.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">The
\t\t\tClient can always unsubscribe from the mailing list (exercise
\t\t\ttheir right to opt out of receiving direct marketing
\t\t\tcommunications or withdraw consent) and check the subscription
\t\t\tstatus by clicking on one of the links provided at the bottom of
\t\t\teach email sent as part of the email newsletter.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="89" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Online
\t\t\tadvertising</font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Also,
\t\t\tto provide you with the best offers and maximize our marketing
\t\t\tactivities' effectiveness, we display advertisements on the site
\t\t\tInfocoin.online and third-party websites that, based on your
\t\t\tPersonal Data, are most relevant to you. Therefore, there is a
\t\t\tchance that you will see Infocoin business on other resources on
\t\t\tthe Internet.</font></font></p>
\t\t</td>
\t\t<td width="137" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Your
\t\t\tbehavior when interacting with us online;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Account
\t\t\tinformation;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Device
\t\t\tand network metadata;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Your
\t\t\tsettings.</font></font></p>
\t\t</td>
\t\t<td width="163" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Article
\t\t\t6 paragraph 1 (f) – for the purposes of realizing the legitimate
\t\t\tinterests of Infocoin (direct marketing).</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="89" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Marketing
\t\t\tAnalytics</font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">For
\t\t\tthe overall improvement of our marketing campaigns, we provide
\t\t\tanalysis to track the performance of campaigns and their impact on
\t\t\tour advertising performance metrics. We also analyze your
\t\t\tinteraction with Infocoin and send you offers that are relevant to
\t\t\tyou.</font></font></p>
\t\t</td>
\t\t<td width="137" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Your
\t\t\tbehavior when interacting with us online;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Device
\t\t\tand network metadata.</font></font></p>
\t\t</td>
\t\t<td width="163" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Article
\t\t\t6 paragraph 1 (f) – for the purposes of realizing the legitimate
\t\t\tinterests of Infocoin (direct marketing).</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="89" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Substantiation
\t\t\tand presentation of claims and lawsuits, as well as defense
\t\t\tagainst claims and lawsuits</font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">We
\t\t\tsave your Personal Data for at least four years from the time you
\t\t\tuse our Website or App to allow us to bring claims or lawsuits in
\t\t\tconnection with your use of the Website or App in violation of our
\t\t\tTerms of Use or in violation of certain legal obligations, and to
\t\t\tbe able to defend against claims or claims brought by you.</font></font></p>
\t\t</td>
\t\t<td width="137" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Identification
\t\t\tinformation;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Your
\t\t\tbehavior when interacting with us online;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Device
\t\t\tand network metadata;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Account
\t\t\tinformation</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Contact
\t\t\tdetails (if they are entered in the fields);</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Your
\t\t\tsettings.</font></font></p>
\t\t</td>
\t\t<td width="163" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Article
\t\t\t6 paragraph 1 (f) – for the purposes of realizing the legitimate
\t\t\tinterests of Infocoin (protection of our legal rights).</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="89" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Data
\t\t\tprotection</font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">We
\t\t\tneed to protect ourselves from various threats that try to exploit
\t\t\tvulnerabilities in our security system and harm our business. To
\t\t\tdo this, in certain cases, we need to process the Personal Data of
\t\t\tour users.</font></font></p>
\t\t</td>
\t\t<td width="137" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Your
\t\t\tbehavior when interacting with us online;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Device
\t\t\tand network metadata.</font></font></p>
\t\t</td>
\t\t<td width="163" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Article
\t\t\t6, paragraph 1 (f), for the purposes of implementation legitimate
\t\t\tinterests Infocoin (security).</font></font></p>
\t\t</td>
\t</tr>
</table>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>-
Purposes of using the data of the person who posts the content .</u></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
When you order services from us, we continue to process your Personal
Data for the purposes described above. Personal Data processed for
these purposes also includes information about your order(s), your
requests, and information related to communications between us. In
addition, we process your Personal Data for the following purposes.</font></font></p>
<table width="650" cellpadding="7" cellspacing="0">
\t<col width="108"/>

\t<col width="234"/>

\t<col width="128"/>

\t<col width="122"/>

\t<tr valign="top">
\t\t<td width="108" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Name</font></font></p>
\t\t</td>
\t\t<td width="234" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Description</font></font></p>
\t\t</td>
\t\t<td width="128" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Personal
\t\t\tData Category</font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Legal
\t\t\tbasis</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="108" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Services
\t\t\torder and provision</font></font></p>
\t\t</td>
\t\t<td width="234" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">The
\t\t\tmain reason for our collection and use of your Personal Data is
\t\t\tthe conclusion of a contract with you and the subsequent provision
\t\t\tof the ordered services. Depending on the volume of our services
\t\t\tused by the client, we process Personal Data following the need
\t\t\tdictated by the conclusion and execution of agreements on the
\t\t\tprovision of services concluded by using the site's functionality,
\t\t\tpressing the appropriate keys, filling in blocks of information.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">In
\t\t\tthe process of placing an order, the client receives a request to
\t\t\texpress consent to the processing of the specified Personal Data.
\t\t\tThe client always has the right to withdraw his consent by
\t\t\tcontacting the support service at the following links:
\t\t\thttps://business.infocoin.online/support</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font><u><a href="https://view.infocoin.online/help"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">https://view.infocoin.online/help</font></font></a></u></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"> <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">At
\t\t\tthe same time, please keep in mind that after the withdrawal of
\t\t\tconsent to the processing of Personal Data, we will not be able to
\t\t\tprovide the client with further support related to current
\t\t\tservices.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">If
\t\t\tnecessary, we process your Personal Data to conclude an agreement</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">with
\t\t\tyou and provide ordered services; sometimes, third-party Operators
\t\t\tcan process Personal Data, in compliance with all the mentioned
\t\t\tobligations for their preservation and conservation, under our
\t\t\tstrict control and with written consent. A complete list of
\t\t\tthird-party Personal Data Controllers to whom we may disclose
\t\t\tcustomer Personal Data is available upon written request.</font></font></p>
\t\t</td>
\t\t<td width="128" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Identification
\t\t\tinformation;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Contact
\t\t\tInformation;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Information
\t\t\tabout your order(s);</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Information
\t\t\tabout the submitted documents;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Payment
\t\t\tinformation;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Documents
\t\t\trequired for the provision of services entrusted to third parties;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Licenses
\t\t\tand other documents related to the order.</font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Paragraph
\t\t\t1 (b) of article 6 – the need to conclude and implement a
\t\t\tcontract.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="108" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Fraud
\t\t\tPrevention</font></font></p>
\t\t</td>
\t\t<td width="234" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Moreover,
\t\t\tto prevent fraudulent chargebacks, if a client reports a
\t\t\tfraudulent purchase through their bank account, we may examine
\t\t\ttheir information on social networks to check the client for any
\t\t\tconnection with the person who ordered advertising or other
\t\t\tservices to make sure that there are no fraudulent attempts to
\t\t\treturn funds spent on advertising and other services. We process
\t\t\tonly a limited amount of information necessary to verify the
\t\t\tclient's relationship with the person who ordered advertising or
\t\t\tother services and publish any data related to a particular case.</font></font></p>
\t\t</td>
\t\t<td width="128" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Identification
\t\t\tinformation;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Contact
\t\t\tInformation;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Information
\t\t\tabout your order(s);</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Information
\t\t\tabout travel documents;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Payment
\t\t\tInformation;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Your
\t\t\tbehavior when interacting with us online;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Device
\t\t\tand network information;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Public
\t\t\tinformation related to your order.</font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Article
\t\t\t6 paragraph 1 (f) – for the purposes of realizing the legitimate
\t\t\tinterests of Infocoin and the e-commerce and e-advertising
\t\t\tindustry in general (fraud prevention).</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="108" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Customer
\t\t\tSupport</font></font></p>
\t\t</td>
\t\t<td width="234" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Customer
\t\t\tsupport is an integral part of our services. To provide customers
\t\t\twith the services they request, we capture all information in the
\t\t\tcourse of communication with customers through any channels,
\t\t\tincluding email, chat, and telephone.</font></font></p>
\t\t</td>
\t\t<td width="128" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Identification
\t\t\tinformation;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Contact
\t\t\tInformation;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Information
\t\t\tabout your order(s);</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Information
\t\t\tabout travel documents;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Information
\t\t\tabout your requests and interactions with us.</font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Article
\t\t\t6 paragraph 1 (b), the need to conclude and perform a contract</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="108" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Substantiation
\t\t\tand presentation of claims and lawsuits, as well as defense
\t\t\tagainst claims and lawsuits</font></font></p>
\t\t</td>
\t\t<td width="234" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">We
\t\t\tstore and process Customer Personal Data to establish, assert, and
\t\t\tdefend claims. Each time you use the site, we keep all relevant
\t\t\tdata in the event of possible claims or lawsuits that you or us
\t\t\tmay bring, in particular in the context of litigation and other
\t\t\tproceedings, as well as in the exercise or sale of claims
\t\t\ttransferred by you to us, for at least four years from the date of
\t\t\tcreation of the relevant order. When we receive data protection
\t\t\trequests from customers, we also store all the data provided by
\t\t\tthe customers and all information about working with the request
\t\t\tfor the specified purpose.</font></font></p>
\t\t</td>
\t\t<td width="128" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Identification
\t\t\tinformation;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Contact
\t\t\tinformation</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Information
\t\t\tabout your order(s);</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Payment
\t\t\tinformation;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Your
\t\t\tbehavior when interacting with us online;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Device
\t\t\tand network information;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Public
\t\t\tinformation related to your order;</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Information
\t\t\tabout your requests and interactions with us.</font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Article
\t\t\t6 paragraph 1 (f) – for the purposes of realizing the legitimate
\t\t\tinterests of Infocoin (protection of our legal rights).</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="108" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Compliance
\t\t\twith legal obligations</font></font></p>
\t\t</td>
\t\t<td width="234" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Where
\t\t\tapplicable, we must process specific Customer Personal Data to
\t\t\tcomply with our legal obligations. We do not need to obtain
\t\t\tcustomer consent for such processing, as it is a legal
\t\t\trequirement. To this end, we process each client's identification
\t\t\tand contact information, and information about the transactions
\t\t\tmade. The main norms that oblige us to perform these actions are
\t\t\tcontained in the law: the Civil Code, the Consumer Rights
\t\t\tProtection Law, VAT acts, and tax reporting. If a customer sends
\t\t\tus a request to exercise rights relating to their Personal Data,
\t\t\twe ask them to provide certain Personal Data and then process it
\t\t\tto comply with applicable law.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">If
\t\t\tthe legislation that applies to our relationship with you does not
\t\t\texpressly require the collection of such data, we will not collect
\t\t\tthem.</font></font></p>
\t\t</td>
\t\t<td width="128" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Information
\t\t\tabout your order(s);</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Information
\t\t\tabout your requests and interactions with us.</font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Article
\t\t\t6 paragraph 1 (c) – the need to comply with legal obligations
\t\t\tthat apply to Infocoin.</font></font></p>
\t\t</td>
\t</tr>
</table>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>5.
To whom and why we disclose Personal Data</b></font></font></p>
<p style="line-height: 108%; margin-bottom: 0in"><u> <font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
Disclosure of data to other Personal Data Operators</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">In
some cases, we disclose Personal Data to third persons who use them
for their purposes. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Thus,
Clients' Personal Data may be opened up to certain advertisers and
service providers in Third countries. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">All
advertisers use Personal Data according to their Privacy Policies
(published on advertisers' websites). Disclosure of Personal Data to
other service providers is carried out according to current
regulatory legal acts concerning Personal Data. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">In
case of giving you consent through our file cookies settings, we
transfer some of your data to our partners for marketing purposes.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<table width="650" cellpadding="7" cellspacing="0">
\t<col width="410"/>

\t<col width="210"/>

\t<tr valign="top">
\t\t<td width="410" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Recipient
\t\t\tcategory</font></font></p>
\t\t</td>
\t\t<td width="210" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Examples</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="410" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Advertisers</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">To
\t\t\tfulfill our own purposes, such as targeting, your Personal Data is
\t\t\tprocessed accordingly.</font></font></p>
\t\t</td>
\t\t<td width="210" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">An
\t\t\tadvertiser who purchases impressions of their ad</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="410" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Other
\t\t\tservice providers</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Third-party
\t\t\torganizations related to the main activity of the site that
\t\t\tprocess your personal information following their privacy
\t\t\tpolicies, as well as under accepted norms of international law.</font></font></p>
\t\t</td>
\t\t<td width="210" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Service
\t\t\tproviders</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="410" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Operators
\t\t\treceiving and processing payments</font></font></p>
\t\t\t<p align="justify" style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Executing
\t\t\tpayment orders on the merchant's website is just the beginning of
\t\t\ta long chain of technical operations required for admission
\t\t\tpayment to your (bank) account and directions confirmation seller.
\t\t\tThe information must go through various payment operators and
\t\t\tacquiring banks. These subjects act as Personal Data operators
\t\t\tbecause they fully control this data.</font></font></p>
\t\t</td>
\t\t<td width="210" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">PayPal</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="410" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Communication
\t\t\tand social platforms</font></font></p>
\t\t\t<p align="justify" style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">To
\t\t\tsend you communications related to our services or allow you to
\t\t\tlog in to InfocoinAccount through third-party platforms, we need
\t\t\tto transmit your Personal Data to the supplier platform of your
\t\t\tchoice.</font></font></p>
\t\t</td>
\t\t<td width="210" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Facebook
\t\t\tIreland Ltd.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Google
\t\t\tIreland Limited</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="410" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Persons
\t\t\tproviding legal services</font></font></p>
\t\t\t<p align="justify" style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">We
\t\t\tmay use the services of other entities to protect our legal rights
\t\t\tand present claims.</font></font></p>
\t\t</td>
\t\t<td width="210" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Lawyers,
\t\t\tcollection agencies</font></font></p>
\t\t</td>
\t</tr>
</table>
<p style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>-
Disclosure to Personal Data Processors</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Our
company requires numerous activities that we cannot carry out
independently. To execute them, we use the services of third-party
partners to whom we disclose Customer Personal Data, as they often
cannot proceed without it. However, in such cases, our company
retains the status of a Personal Data controller, and third-party
partners act as processors.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Therefore,
they may only process Personal Data for our purposes, and we are
always responsible for the information. Under no circumstances may
they use the data for their own purposes or in a manner contrary to
our agreement.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Moreover,
we cooperate only with partners who give us sufficient guarantees to
comply with legal requirements and fully ensure the security of
customer information.</font></font></p>
<table width="650" cellpadding="7" cellspacing="0">
\t<col width="410"/>

\t<col width="210"/>

\t<tr valign="top">
\t\t<td width="410" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Recipient
\t\t\tCategory</font></font></p>
\t\t</td>
\t\t<td width="210" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Examples</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="410" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Persons
\t\t\tproviding enforcement services</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">When
\t\t\trepresenting you in disputes with carriers, we may use the
\t\t\tservices of third parties to protect your rights and interests.</font></font></p>
\t\t</td>
\t\t<td width="210" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Lawyers,
\t\t\tcollection agencies.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="410" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Online
\t\t\tadvertising services.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Thanks
\t\t\tto these services, we can show your personal advertising on the
\t\t\tInternet.</font></font></p>
\t\t</td>
\t\t<td width="210" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Facebook
\t\t\tIreland Ltd. (Facebook for Business)</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Google
\t\t\tBusiness</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Criteo
\t\t\tSA</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">CityBusiness
\t\t\tMedia, LLc.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="410" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Analytical
\t\t\ttools.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">We
\t\t\tuse data analysis and logging software tools from third-party
\t\t\tvendors to provide a comprehensive view of our customer base and
\t\t\timprove the convenience of our services.</font></font></p>
\t\t</td>
\t\t<td width="210" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Exponea
\t\t\ts.r.o.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Facebook
\t\t\tIreland Ltd.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Google
\t\t\tLLC.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Keboola
\t\t\ts.r.o.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">GoodData
\t\t\tCorporation.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Datadog
\t\t\tInc.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Logmole</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Loggly,
\t\t\tInc. (SolarWinds Worldwide, LLC)</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Functional
\t\t\tSoftware, Inc. dba Sentry</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Smartsupp.com,
\t\t\ts.r.o.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Mapbox,
\t\t\tInc.</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">FullStory,
\t\t\tInc.</font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="410" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Software
\t\t\tDevelopment</font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">We
\t\t\tmay occasionally share your Personal Data with our developers who
\t\t\thelp build our technologies.</font></font></p>
\t\t</td>
\t\t<td width="210" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">We
\t\t\tare currently working with many software developers. We do not
\t\t\tshare their Personal Data to respect their privacy.</font></font></p>
\t\t</td>
\t</tr>
</table>
<p style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>6.
Duration of Personal Data storage </b></font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Usually,
we process your Personal Data for as long as it is no longer
necessary for us to use it for the purposes set out in this Privacy
Policy. We generally process your Personal Data for the statutory
limitation period, which is typically three years, and an additional
one year due to the margin required due to delayed notifications and
our further actions.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">To
comply with legal obligations, we process your Personal Data for as
long as required by applicable law, such as the 10-year account
holding period.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">To
keep the Infocoin client's Account, we store Personal Data for five
years from the date of the last action you took while logged in to
the Account.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">We
periodically send e-mails to customers for personalized offers, each
of which offers a simple and straightforward way to unsubscribe and
thereby opt-out of this type of processing. We store and use Customer
Personal Data for this purpose until you unsubscribe from the mailing
list.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>7.
Gaining access to and control of your Personal Data</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">It
is our concern to let the Clients always control their Personal Data.
Clients have certain rights in this regard. Clients may, under
certain conditions: </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
get acquainted with the comprehensive information about what data we
process and receive its copy; </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
send us removal requests for their data; </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
edit their data if they encountered mistakes in it; </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
limit processing data; </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
object against the processing; </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
receive Personal Data in a standard, machine-readable format and
transmit it to their other supplier. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">To
implement your rights, You can contact support at the following links
https://business.infocoin.online/support or
</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">https://view.infocoin.online/help</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.
</font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Please
note that in order to protect your Personal Data, we only process
requests sent from the account with the email address used during
registration. If you wish to change your email address, please
provide additional information to verify that the customer is the
owner of the Personal Data.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>-
Access to your Personal Data</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">You
can at any time send a request to the support service at the
following links: https://business.infocoin.online/support or
</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">https://view.infocoin.online/help</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
to find out if we process your personal data and receive the
following information:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
The purposes for which we process your Personal Data;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
The categories of Personal Data we process;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
A list of third parties to whom we share your Personal Data,
primarily if third parties are located in third countries;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
The duration of the processing of your Personal Data or the time of
their storage;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
Your rights as a data subject under the GDPR (General Data Protection
Regulation);</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
Your right to file complaints with supervisory authorities;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
Where we received your data if we did not receive it directly from
you;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
Any information about automated decision making (if any) that may
apply to you;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
When the data was transferred to a third country and what security
measures are applied under the GDPR;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Also,
upon your request, we will provide a complete copy of all Personal
Data we process. The first copy of the data is provided free of
charge. However, subsequent copies are subject to an administrative
fee.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">You
can also request data to be provided in a commonly used format for
data portability.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>-
Deletion of Personal Data</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">You
also have the right to complete deletion of Personal Data (or, more
precisely, their complete and irrevocable anonymization) if one of
the following cases applies to you:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
We no longer need your Personal Data for the purposes identified in
this Privacy Policy;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
You have successfully objected to the processing of Personal Data
pursuant to Article 21 of the GDPR, and we have no other purposes for
processing your Personal Data;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
We unlawfully processed your Personal Data, or there is a legal
obligation obliging us to delete your Personal Data;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">However,
you do not have the right to request the deletion of Personal Data if
the processing is necessary for:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
exercising the right to freedom of expression and information;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
compliance with a legally binding obligation obliging us to store
Personal Data;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
we need our Personal Data for the establishment and implementation of
claims, as well as defense against them.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>-
Correction of Personal Data</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">If
you believe that any of your Personal Data that we process is
incorrect, you can report it, and we will do our best to update it.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>-
Restriction of the processing of your Personal Data</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Under
certain conditions, we restrict the processing of your Personal Data.
It means that we will ensure that it is not processed for any other
purpose than archiving or moving to a secure archive. You have the
right to demand a restriction if:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
you have disputed the accuracy of your Personal Data (we will
continue to process it after the issue has been resolved);</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
we unlawfully processed your Personal Data, but instead of deletion,
you requested restriction of their processing;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
the sole remaining purpose of the processing of your Personal Data is
the establishment and implementation of claims, as well as defense
against them;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
you object to the processing of data following Art. 21 para. 1 GDPR,
and we estimate the validity of your request.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>-
Objection to the processing of your Personal Data</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">You
may object to any purpose for which we process your Personal Data
based on a legal basis of a legitimate interest. If you object to
data processing for marketing purposes, we will immediately stop
using your Personal Data for these purposes.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">If
you object to any other purpose based on a legal interest, we will
stop processing your Personal Data for the purposes in question
unless we can demonstrate that our legitimate grounds for processing
it override your personal interests, rights, and freedoms.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>-
Ensuring the portability of your Personal Data</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Finally,
you have the right to receive your Personal Data processed (based on
consent, or the need to enter into a contract or the need to fulfill
obligations under it) in a commonly used and machine-readable format,
and you have the right to transfer this data to other Personal Data
controllers according to your choice.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>8.
Cookies and similar technologies</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Cookies
are small text files placed on your device that allow us to remember
certain information about you for various purposes, such as providing
the Website and App functionality, developing products, improving the
service and business development, ensuring the work of the Infocoin
Account, online advertising, marketing analytics, information
security, fraud prevention.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Our
website uses (or migh be using) three types of cookies: </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
cookies strictly necessary for the operation of the website and the
provision of services (they cannot be disabled);</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
so-called &quot;performance&quot; cookies, used to obtain statistical
data to improve our services;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
cookies used for marketing purposes.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Cookies
used to obtain statistical data and for marketing purposes can be
disabled using the settings.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">You
can learn more about cookies, including how to manage or delete them,
at www.aboutcookies.org, www.allaboutcookies.org, or
www.civicuk.com/cookie-control/browser-settings. However, please note
that some site features may not work if cookie files are deleted. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">DON'T
TRACK: We currently do not respond to your browser's Do Not Track
settings.</font></font></p>
<center>
\t<table width="642" cellpadding="7" cellspacing="0">
\t\t<col width="224"/>

\t\t<col width="388"/>

\t\t<tr valign="top">
\t\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Cookies
\t\t\t\t_</font></font></p>
\t\t\t</td>
\t\t\t<td width="388" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Goal</font></font></p>
\t\t\t</td>
\t\t</tr>
\t\t<tr valign="top">
\t\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">__business.infocoin.online</font></font></p>
\t\t\t</td>
\t\t\t<td width="388" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Files
\t\t\t\tare needed for administration, statistics and other indicators
\t\t\t\timportant to our developers</font></font></p>
\t\t\t</td>
\t\t</tr>
\t\t<tr valign="top">
\t\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">__view.infocoin.online</font></font></p>
\t\t\t</td>
\t\t\t<td width="388" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Files
\t\t\t\tare needed to display the architecture, code, and other metrics
\t\t\t\tour developers need</font></font></p>
\t\t\t</td>
\t\t</tr>
\t\t<tr valign="top">
\t\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">helpcrunch-widget</font></font></p>
\t\t\t</td>
\t\t\t<td width="388" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">The
\t\t\t\tSameSite attribute of the Set-Cookie HTTP response header allows
\t\t\t\tyou to specify whether your cookies should be restricted to the
\t\t\t\tvisited site, third parties, or subdomains of the site.</font></font></p>
\t\t\t</td>
\t\t</tr>
\t\t<tr valign="top">
\t\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">_ga</font></font></p>
\t\t\t</td>
\t\t\t<td width="388" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">User
\t\t\t\tidentification (Google Analytics 4)</font></font></p>
\t\t\t</td>
\t\t</tr>
\t\t<tr valign="top">
\t\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">_gid</font></font></p>
\t\t\t</td>
\t\t\t<td width="388" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">User
\t\t\t\tidentification (Google Analytics 4)</font></font></p>
\t\t\t</td>
\t\t</tr>
\t\t<tr valign="top">
\t\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">_ga_&lt;container-id&gt;</font></font></p>
\t\t\t</td>
\t\t\t<td width="388" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Save
\t\t\t\tsession state (Google Analytics 4)</font></font></p>
\t\t\t</td>
\t\t</tr>
\t\t<tr valign="top">
\t\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">_ga</font></font></p>
\t\t\t</td>
\t\t\t<td width="388" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Universal
\t\t\t\tAnalytics User ID (analytics.js)</font></font></p>
\t\t\t</td>
\t\t</tr>
\t\t<tr valign="top">
\t\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">_gid</font></font></p>
\t\t\t</td>
\t\t\t<td width="388" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Universal
\t\t\t\tAnalytics User ID (analytics.js)</font></font></p>
\t\t\t</td>
\t\t</tr>
\t\t<tr valign="top">
\t\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">_gat</font></font></p>
\t\t\t</td>
\t\t\t<td width="388" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; text-indent: 0.5in">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Limits
\t\t\t\tthe number of requests. If Google Analytics support is
\t\t\t\timplemented using Google Tag Manager, the file will be named
\t\t\t\t_dc_gtm_&lt;property-id&gt;</font></font></p>
\t\t\t</td>
\t\t</tr>
\t\t<tr valign="top">
\t\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">AMP_TOKEN</font></font></p>
\t\t\t</td>
\t\t\t<td width="388" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">contains
\t\t\t\ta token that can be used to get the Client-ID from the AMP
\t\t\t\tservice</font></font></p>
\t\t\t</td>
\t\t</tr>
\t\t<tr valign="top">
\t\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">_gac_&lt;property-id&gt;</font></font></p>
\t\t\t</td>
\t\t\t<td width="388" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; text-indent: 0.5in">
\t\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Contains
\t\t\t\tinformation about the user's company. After establishing a
\t\t\t\tconnection between the Google Analytics and Google Business
\t\t\t\taccounts, the Google Business conversion tags placed on the site
\t\t\t\twill receive data from the cookie file.</font></font></p>
\t\t\t</td>
\t\t</tr>
\t</table>
</center>
<p style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>9.
Transfer of your data outside the European Economic Area</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">If
needed, we can transfer your Personal Data outside European Economic
Area. This happens when a customer wants to buy advertising from a
Third country or orders any of the services from a third country. A
need to transmit customers’ information to such third persons is
natural because providing services would be impossible without it.
Also, we can transfer your Personal Data outside European economic
zones to the Personal Data Processors located in third countries. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">When
transferring data to countries where we cannot rely on the
corresponding level of protection according to Article 45 of the GDPR
and sufficient measures to ensure security under Article 46 of the
GDPR, we transfer Personal Data based on the exception provided in
Art. 49 Paragraph 1 Subparagraph b of the GDPR. All service providers
use Personal Data according to their Privacy Policies (published on
their websites). Disclosure of Personal Data to other service
providers takes place according to current regulatory legal acts
concerning Personal Data.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>10.
Complaints to supervisory authorities</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Data
protection is a serious issue, and implementing the rules is quite
tricky. Nobody is perfect, and it may happen that we make a mistake.
If you discover that we have misused your Personal Data, don't
hesitate to get in touch with us first, and we will do our best to
fix the problem. You can always contact us with any questions
regarding data protection by contacting support at the following
links: https://business.infocoin.online/support or
</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">https://view.infocoin.online/help</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">,
or email to our office, the address of which is listed on the
website.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">However,
you have the right to file complaints with the supervisory
authorities under any circumstances. A person from the European Union
may file a complaint with power in the EU Member State where they
reside, in the EU Member State where they work, and in the EU Member
State where the alleged infringement occurred.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>11.
How to contact us and exercise your rights</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">To
clarify all privacy and security issues data, you can contact support
at the following links: https://business.infocoin.online/support or
</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">https://view.infocoin.online/help</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.
</font></font>
</p>
<p style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>12.
Special conditions for persons residing in certain countries</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Regardless
of where you live, we guarantee your Personal Data protection. This
Privacy Policy contains specific terms that differ from or supplement
the rest of the terms and may apply to persons residing in certain
countries and states under the terms of this Privacy Policy.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>-
For persons residing in California</u></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">These
exceptional provisions apply to you if you reside in California.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">We
comply with the California Consumer Privacy Act (CCPA), which sets
the highest privacy standards in the United States. You can review
this Privacy Policy at any time for information about our processing
of your Personal Data.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">You
have the right to request information about how we process your
Personal Data and receive a copy of it. For 12 months, we provide the
first two copies of the data free of charge. You also have the right
to request your Personal Data to be deleted. For more information
about your rights and how you can exercise them, see this Privacy
Policy (sections &quot;Access and control your Personal Information&quot;
and &quot;How to contact us and exercise your rights&quot;).</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">If
you choose to exercise any of your rights, you will be required to
verify your identity, for instance, by verifying that you are the
owner of your email address. If you exercise your rights through an
authorized agent, we will require your written authorization and
proof of your identity and that of the agent. Under no circumstances
will we discriminate against you or treat you unfavorably because you
have exercised any of your rights.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">In
exchange for your subscription to marketing communications, we may
offer you financial rewards in the form of coupons, credit points, or
other forms of discounts. Details of the specific promotion will be
presented to you as part of the subscription offer. We consider the
estimated value of your subscription to be equal to the value of the
promotion provided, based on our calculation of additional costs.
After accepting our offer, you can cancel it at any time without any
limitation of your rights. For more information, see this Privacy
Policy (in the &quot;Purposes of using Personal Data&quot; section).</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">We
do not sell your Personal Information within the meaning of the CCPA.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">In
addition, you can contact our data protection representative with any
questions related to the processing of your Personal Data and the
exercise of your rights about Personal Data.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>13.
Changes to this Privacy Policy</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Methods
work with Personal Data may change as it develops our business. If
such changes come, we supplement this Politics confidentiality to
comply with the principles of transparency. These rules are subject
to change at any time without notice.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><a name="_heading=h.gjdgxs"></a><a name="_GoBack"></a>
<font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><u>This
edition Privacy Policy valid from </u></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><span lang="ru-RU"><u>09</u></span></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><u>.</u></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><span lang="ru-RU"><u>12</u></span></font></font><font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><u>.2022.</u></font></font></p>
<p style="line-height: 108%; margin-bottom: 0in"><a name="_heading=h.vc6hnp29uic2"></a>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><a name="_heading=h.5eb1ic2es8ie"></a>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0in"><br/>

</p>
</body>
</html>
`;
