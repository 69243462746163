import {useContext} from "react";

import {RecordContext} from "src/views/blocks";


const useRecord = () => {
    return useContext(RecordContext);
};


export {useRecord};