import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import store from "@redux/store.js";

import Loader from "@components/Loader";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { theme } from "src/theme";
import "src/translates";
import "../src/styles/normalize.css";
import "./index.scss";

Sentry.init({
  dsn: "https://832187128ca84f0ba6432f66a65bad18@sentry.infocoin.online/5",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Suspense fallback={<Loader width={260} height={260} isPageLoader />}>
          <App />
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
);
// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
