import React from "react";

import { Icon } from "src/views/blocks";

import "./index.scss";


const EyeOffLineIcon = (props) => {
    const {
        className = "",
        ...rest
    } = props;

    return (
        <Icon
            {...rest}
            className={`eye-off-line-icon ${className}`}
            mask />
    );
};


export { EyeOffLineIcon };