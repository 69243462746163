import React from 'react';
import { Container } from 'react-bootstrap';

const About = () => {
  return (
    <Container className="m-mob">
      <h4>О InfoCoin</h4>
    </Container>
  );
};

export default About;
