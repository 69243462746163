export const LOAD_CATEGORIES = "LOAD_CATEGORIES";
export const LOAD_LOTS = "LOAD_LOTS";
export const SELECT_CATEGORY = "SELECT_CATEGORY";
export const SET_ACTIVE_AUCTION = "SET_ACTIVE_AUCTION";
export const SET_LOT_TO_EDIT = "SET_LOT_TO_EDIT";
export const SET_AUCTIONS_ERROR = "SET_AUCTIONS_ERROR";
export const SET_AUCTION_HISTORY = "SET_AUCTION_HISTORY";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SAVE_NEW_LOT = "SAVE_NEW_LOT";
export const CLEAR_NEW_LOT = "CLEAR_NEW_LOT";
export const SET_MIN_AMOUNT = "SET_MIN_AMOUNT";
export const LOAD_ALL_AUCTIONS = "LOAD_ALL_AUCTIONS";
export const SET_DATE_AUCTION = 'SET_DATE_AUCTION';
