//react
import React, { memo } from "react";

//components
import { Grid } from "@mui/material";
import Button from "@components/Button";
import { TextInput } from "src/views/inputs";
import { Typography } from "src/views/blocks";

//style
import s from "../../Account.module.scss";

//other
import { withTranslation } from "react-i18next";

import { postUpdatePassword } from "@services/api/user";

import { useFormik } from "formik";
import { UpdatePasswordSchema } from "@helpers/Formik/validation";

import { toast } from "react-toastify";

const PasswordForm = ({ t, onToggleFirstNavigation }) => {
  const {
    handleSubmit,
    isSubmitting,
    isValid,
    handleChange,
    handleBlur,
    touched,
    dirty,
    errors,
    values,
  } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      newPassword: "",
    },
    validationSchema: UpdatePasswordSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);

      const data = {
        password_old: values.password,
        password_new: values.newPassword,
      };

      try {
        await postUpdatePassword(data);

        toast.success(t("Password updated successfully!"));

        resetForm();
      } catch (e) {
        toast.error(t(e?.response?.data?.error || e?.message || e));
      }
      setSubmitting(false);
    },
  });

  return (
    <Grid container>
      <Grid>
        <Typography variant="h5" weight="semi-bold">
          {t("Password")}
        </Typography>
        <Typography variant="body2" color="gray">
          {t("Please enter your current password to change your password.")}
        </Typography>
      </Grid>
      <Grid className={s.item} mt={2}>
        <Grid className={s.item__container}>
          <Grid className={s.item__label}>
            <Typography variant="body2" weight="normal-bold">
              {t("Password")}
            </Typography>
          </Grid>
          <Grid className={s.item__field}>
            <TextInput
              fullWidth
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.password}
              helperText={touched.password && t(errors.password)}
              value={values.password}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={s.item}>
        <Grid className={s.item__container}>
          <Grid className={s.item__label}>
            <Typography variant="body2" weight="normal-bold">
              {t("New password")}
            </Typography>
          </Grid>
          <Grid className={s.item__field}>
            <TextInput
              fullWidth
              type="password"
              name="newPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.newPassword}
            />
            <Typography
              variant="caption2"
              color={
                touched.newPassword && errors.newPassword ? "error" : "gray"
              }
              className="mt-1"
            >
              {t(
                "The password must consist of: at least 8 characters, a lowercase character, an uppercase character, a number and a special character (!,@,#,$,% etc.)",
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={s.item}>
        <Grid className={s.item__container}>
          <Grid className={s.item__label}>
            <Typography variant="body2" weight="normal-bold">
              {t("Confirm new password")}
            </Typography>
          </Grid>
          <Grid className={s.item__field}>
            <TextInput
              fullWidth
              onBlur={handleBlur}
              type="password"
              name="confirmPassword"
              onChange={handleChange}
              value={values.confirmPassword}
              error={!!errors.confirmPassword}
              helperText={touched.confirmPassword && t(errors.confirmPassword)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={s.item}>
        <Grid className={s.item__container}>
          <Grid display="flex" width="100%" justifyContent="flex-end">
            <Button
              className={s.cancel}
              containerClassName={s.containerButton}
              variant="gray"
              onClick={onToggleFirstNavigation}
            >
              {t("Cancel")}
            </Button>
            <Button
              className={s.save}
              containerClassName={s.containerButton}
              variant="primary"
              onClick={handleSubmit}
              disabled={isSubmitting || !isValid || !dirty}
              loading={isSubmitting}
            >
              {t("Update password")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(memo(PasswordForm));
