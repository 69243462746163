import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import classes from "./Wallet.module.scss";
import clsx from "clsx";
import { Container } from "react-bootstrap";
import { useWallet } from "src/hooks";

import { CardIcon, BankIcon, VisaIcon, MastercardIcon } from "src/views/icons";

import { WalletRefillSchema } from "src/helpers/Formik/validation";
import Loader from "src/components/Loader";
import Button from "src/components/Button";
import "src/styles/components/Wallet.scss";
import {
  useHistory,
  Link,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setInvoiceInfoAction } from "@redux/wallet/actions";
import CompanySelect from "@components/Form/CompanySelect";
import { companiesSelector } from "@redux/companies/selectors";
import CountrySelect from "@components/Form/CountrySelect";
import { getPaymentSystems } from "@services/api/wallet";
import WalletSelect from "@components/Form/WalletSelect";
import Modal from "@components/Modal";
import SuccessAction from "@components/SuccessAction";
import FailPay from "@pages/FailPay";
import TransactionCCWTable from "@pages/Wallet/components/Transaction/TransactionCCWtable/TransactionCCWTable";
import PendingAction from "@components/PendingAction";
import { Typography } from "../../views/blocks";

import IntroWallet from "@components/introLearning/wallet";
import { introIds } from "@components/introLearning/classes";

const Wallet = () => {
  const [paymentSystems, setPaymentSystems] = useState([]);

  const { t } = useTranslation();

  let { path } = useRouteMatch();

  const bHis = useHistory();

  const companies = useSelector(companiesSelector, shallowEqual);

  const [successWallet, setSuccessWallet] = useState(true);

  const { exchangeRate, balance, companyBalance, commonBalance, loading } =
    useWallet();

  const [selectedBalance, setSelectedBalance] = useState("");

  useEffect(() => {
    if (!selectedBalance && companyBalance) {
      setSelectedBalance(companyBalance[0].company.title);
    }
  }, [companyBalance, selectedBalance]);

  const dispatch = useDispatch();

  const userFormik = useFormik({
    initialValues: {
      amount: "",
      company: companies.length === 1 ? companies[0].id : "",
      country: "",
      payment: "",
      typePayment: "",
      euro: "",
    },
    enableReinitialize: true,
    validationSchema: WalletRefillSchema,
    onSubmit: values => {
      dispatch(
        setInvoiceInfoAction({
          company: companies.find(c => c.id === values.company),
          amount: values.amount,
        }),
      );
      bHis.push("/invoice");
    },
  });

  useEffect(() => {
    userFormik.setFieldValue("amount", "");
  }, []);

  const onChangeCompany = ({ target }) => {
    userFormik.setFieldValue("payment", "");
    userFormik.setFieldValue("typePayment", "");
    userFormik.setFieldValue("company", target.value);
  };

  const company = useMemo(
    () => companies.find(c => c.id === userFormik.values.company),
    [companies, userFormik.values.company],
  );

  const onChangeAmount = useCallback(
    (e, type) => {
      let num = e.target.value.replace(/[^0-9\.]/g, "");

      if (num === "." || num === "," || num.includes("..") || num.length > 10) {
        return;
      }

      if (num[0] === ".") {
        num = "0" + num;
      }

      if (num.length >= 2 && num[0] === "0" && !isNaN(Number(num[1]))) {
        return;
      }

      if (num.match(/\./g) && num.match(/\./g).length === 2) {
        return;
      }

      if (num.indexOf(".") != "-1") {
        num = num.substring(0, num.indexOf(".") + 4);
      }

      userFormik.setFieldValue(type, num);

      if (type === "euro") {
        if (num) {
          userFormik.setFieldValue(
            "amount",
            String(
              parseFloat((Number(num) / Number(exchangeRate.input)).toFixed(3)),
            ),
          );
        } else {
          userFormik.setFieldValue("amount", "");
        }
        return;
      }

      if (type === "amount") {
        if (num) {
          userFormik.setFieldValue(
            "euro",
            String(
              parseFloat((Number(num) * Number(exchangeRate.input)).toFixed(3)),
            ),
          );
        } else {
          userFormik.setFieldValue("euro", "");
        }
        return;
      }
    },
    [userFormik.setFieldValue, exchangeRate.input],
  );

  const onConfirm = useCallback(
    type => () => {
      if (!userFormik.isValid) {
        const arrErrors = Object.keys(userFormik.errors);
        arrErrors.forEach(err => {
          userFormik.setFieldTouched(err);
        });
      } else {
        dispatch(
          setInvoiceInfoAction({
            company: company,
            amount: userFormik.values.amount,
            country: userFormik.values.country,
            type,
            paymentSystem: userFormik.values.payment,
          }),
        );
        bHis.push("/invoice");
      }
    },
    [
      bHis,
      company,
      dispatch,
      userFormik.values.amount,
      userFormik.errors,
      userFormik.isValid,
      userFormik.setFieldTouched,
      userFormik.values.country,
      userFormik.values.payment,
    ],
  );

  const onChangeWallet = e => {
    setSelectedBalance(e.target.value);
  };

  const onChangePayment = useCallback((type, payment) => {
    userFormik.setFieldValue("typePayment", type);
    userFormik.setFieldValue("payment", payment);
  }, []);

  useEffect(() => {
    if (!paymentSystems.length) {
      getPaymentSystems().then(res => {
        const filteredPayments = res.data.filter(
          payment => payment.type === "income",
        );
        setPaymentSystems(filteredPayments);
      });
    }
  }, [paymentSystems.length]);

  const PaymentComponent = useCallback(
    ({ name, isLegal }) => {
      return (
        <>
          {!isLegal && (
            <span>
              <div
                className={
                  (userFormik.values.typePayment === name ||
                    userFormik.values.typePayment === "CreditCard") &&
                  userFormik.values.payment === name &&
                  "active-payment"
                }
                onClick={() => onChangePayment("CreditCard", name)}
              >
                <CardIcon />
                {t("Credit Card")}
              </div>
              <span className="d-flex justify-content-center">
                <VisaIcon />
                <MastercardIcon />
              </span>
            </span>
          )}
          <div
            className={
              userFormik.values.typePayment === "BankInvoice" &&
              userFormik.values.payment === name &&
              "active-payment"
            }
            onClick={() => onChangePayment("BankInvoice", name)}
          >
            <BankIcon size="medsmall" />
            {t("Bank Invoice")}
          </div>
        </>
      );
    },
    [
      onChangePayment,
      t,
      userFormik.values.payment,
      userFormik.values.typePayment,
    ],
  );

  return (
    <>
      {loading && <Loader width={150} height={150} isPageLoader />}
      <Container
        className={clsx(classes.wallet, "wallet", {
          "d-none": loading,
        })}
      >
        <div className="header-info">
          <div className={clsx(classes.walletSelect, "d-flex")}>
            <h4 className="main-title wallet-page__title">{t("Wallet")}</h4>
            {companies && (
              <WalletSelect
                companyBalance={companies}
                littleLabel
                value={selectedBalance}
                onChange={e => onChangeWallet(e)}
                placeholder={t("Select company")}
                name="wallet"
              />
            )}
          </div>
          <h5>
            {t("Currency exchange")} : 1 IC / €{exchangeRate.input}
          </h5>
        </div>
        <div className="wallet-page__grid">
          <div className="wallet-cards mt-4">
            {companyBalance && (
              <div className="wallet-card">
                <div className="wallet-card__title">
                  {t("Available company funds")}
                </div>
                {companyBalance
                  .filter(c => c.company.title === selectedBalance)
                  .map(c => {
                    return (
                      <div key={c.country.id}>
                        <span className="wallet-card__name">
                          {c.country.name}
                        </span>
                        <div className="wallet-card__sum">
                          {parseInt(
                            Number(c.available_balance.replace(" IC", "")) *
                              100,
                          ) /
                            100 +
                            " IC"}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
            <div className="wallet-card">
              <div className="wallet-card__title">
                {t("Company auction balance")}
              </div>
              <div className="wallet-card__sum">
                {parseInt((Number(commonBalance) - Number(balance)) * 100) /
                  100 +
                  " IC"}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column h-100 wallet-page__pay mt-4">
            <div id={introIds.wallet.element1}>
              <CompanySelect
                littleLabel
                value={userFormik.values.company}
                onChange={onChangeCompany}
                placeholder={t("Select company")}
                name="company"
                label="Select a company for deposit"
                handleBlur={userFormik.handleBlur}
                error={userFormik.errors.company}
                touched={userFormik.touched.company}
                // handleBlur={() => userFormik.setFieldTouched("company")}
              />
            </div>
            <div className="mt-4" id={introIds.wallet.element2}>
              <CountrySelect
                withNameInValue
                littleLabel
                placeholder={t("Select country")}
                value={userFormik.values.country}
                onChange={value => {
                  userFormik.setFieldValue("country", value.name);
                }}
                name="country"
                label="Select the country in which the content will be shown"
                onBlur={userFormik.handleBlur}
                error={userFormik.errors.country}
                touched={userFormik.touched.country}
              />
            </div>
            <div
              className="wallet_input-conteiner mt-5"
              id={introIds.wallet.element4}
            >
              <input
                name="amount"
                className="wallet_input_sum"
                onBlur={() => userFormik.setFieldTouched("amount", true, false)}
                placeholder={t("Enter the amount in IC")}
                value={userFormik.values.amount}
                onChange={e => onChangeAmount(e, "amount")}
              />
              {userFormik.values.amount ||
              userFormik.values.amount === "0" ||
              userFormik.values.amount === 0 ? (
                <span>IC</span>
              ) : (
                ""
              )}
            </div>

            {userFormik.touched.amount && userFormik.errors.amount && (
              <div
                className="ml-1"
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginTop: "3px",
                  fontWeight: 500,
                }}
              >
                {t(userFormik.errors.amount)}
              </div>
            )}

            <div
              className="wallet_input-conteiner mt-3"
              id={introIds.wallet.element3}
            >
              <input
                name="euro"
                className="wallet_input_sum"
                onBlur={() => userFormik.setFieldTouched("euro", true, false)}
                placeholder={t("Enter the amount in euro")}
                value={userFormik.values.euro}
                onChange={e => onChangeAmount(e, "euro")}
              />
              {userFormik.values.euro ||
              userFormik.values.euro === "0" ||
              userFormik.values.euro === 0 ? (
                <span>€</span>
              ) : (
                ""
              )}
              <Typography
                className="mt-1"
                align="left"
                variant="caption2"
                weight="normal-bold"
                color={
                  (userFormik.touched.euro || userFormik.touched.amount) &&
                  userFormik.values.euro < 15
                    ? "error"
                    : "grey"
                }
              >
                {t("Minimum deposit 15 euro")}
              </Typography>
            </div>

            {userFormik.values.company && (
              <>
                <div
                  className="wallet-paymentSystem mt-4"
                  id={introIds.wallet.element5}
                >
                  {company &&
                    paymentSystems.map(payment => {
                      const { name } = payment;
                      return (
                        <PaymentComponent
                          key={payment.id}
                          isLegal={company.is_legal_entity}
                          name={name}
                        />
                      );
                    })}
                </div>
                {userFormik.touched.payment && userFormik.errors.payment && (
                  <div
                    className="ml-1"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "3px",
                      fontWeight: 500,
                    }}
                  >
                    {t(userFormik.errors.payment)}
                  </div>
                )}
              </>
            )}
            <Button
              className="wallet-deposit-button mt-4"
              onClick={onConfirm(userFormik.values.typePayment)}
              id={introIds.wallet.element6}
              disabled={!userFormik.isValid}
            >
              {t("Deposit")}
            </Button>
          </div>
        </div>
        <div className="mt-5">
          <TransactionCCWTable companySelect={selectedBalance} />
        </div>
      </Container>
      <Switch>
        <Route path={`${path}/success`}>
          <Modal
            closeButtonClassName="payCloseBtn"
            show={successWallet}
            onHide={() => setSuccessWallet(false)}
          >
            <SuccessAction text="You have successfully replenished the wallet" />
          </Modal>
        </Route>
        <Route path={`${path}/pending`}>
          <Modal
            closeButtonClassName="payCloseBtn"
            show={successWallet}
            onHide={() => setSuccessWallet(false)}
          >
            <PendingAction />
          </Modal>
        </Route>
        <Route path={`${path}/fail`}>
          <Modal
            closeButtonClassName="payCloseBtn"
            show={successWallet}
            onHide={() => setSuccessWallet(false)}
          >
            <FailPay containerClassName={"wallet-fail-pay"} />
          </Modal>
        </Route>
      </Switch>
      {/*<IntroWallet />*/}
    </>
  );
};
export default Wallet;
