export const createAgeData = data => {
  const newData = [
    { label: "16-25", value: 0 },
    { label: "26-45", value: 0 },
    { label: "46+", value: 0 },
  ];
  if (data) {
    data.forEach(audience => {
      if (audience.age >= 16 && audience.age <= 25) {
        newData[0].value += audience.percent;
      }
      if (audience.age >= 26 && audience.age <= 45) {
        newData[1].value += audience.percent;
      }
      if (audience.age >= 46) {
        newData[2].value += audience.percent;
      }
    });
  }
  return newData.map(d => ({ ...d, value: Math.round(d.value) }));
};

export const createTimeData = data => {
  const newData = [
    { label: "22:00-5:00", value: 0 },
    { label: "5:00-10:00", value: 0 },
    { label: "10:00-16:00", value: 0 },
    { label: "16:00-22:00", value: 0 },
  ];
  if (data) {
    data.forEach(audience => {
      if (audience.time >= 22 || audience.time <= 4) {
        newData[0].value += audience.percent;
      }
      if (audience.time >= 5 && audience.time <= 9) {
        newData[1].value += audience.percent;
      }
      if (audience.time >= 10 && audience.time <= 15) {
        newData[2].value += audience.percent;
      }
      if (audience.time >= 16 && audience.time <= 21) {
        newData[3].value += audience.percent;
      }
    });
  }
  return newData.map(d => ({ ...d, value: Math.round(d.value) }));
};
