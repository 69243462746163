import Modal from "@components/Modal";
import { offer } from "data/privacy";
import { useCallback, useMemo, useState } from "react";
import classes from "./styled.module.scss";
import Button from "@components/Button";
import { useTranslation } from "react-i18next";

const PublicAgreementModal = ({ show, setShow }) => {
  const { i18n, t } = useTranslation();

  const translates = useMemo(
    () => ({
      de: i18n.getFixedT("de"),
      en: i18n.getFixedT("en"),
      uk: i18n.getFixedT("uk"),
    }),
    [i18n],
  );

  const [language, setLanguage] = useState(
    translates[i18n.language] ? i18n.language : "en",
  );

  const translate = useCallback(
    lng => translates[lng] || translates["en"],
    [translates],
  );

  const onChangeLanguage = value => {
    setLanguage(value);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      headerText={t("Public Agreement")}
    >
      <div className={classes.modal_language}>
        {t("Document language: ")}
        <span
          onClick={() => onChangeLanguage("uk")}
          className={language === "uk" ? `${classes.active_language}` : ""}
        >
          UKR
        </span>
        /
        <span
          onClick={() => onChangeLanguage("en")}
          className={language === "en" ? `${classes.active_language}` : ""}
        >
          ENG
        </span>
        /
        <span
          onClick={() => onChangeLanguage("de")}
          className={language === "de" ? `${classes.active_language}` : ""}
        >
          GER
        </span>
      </div>
      <div
        className={classes.privacyText}
        dangerouslySetInnerHTML={{
          __html: offer[language] ? offer[language] : offer["en"],
        }}
      />
      <Button
        onClick={() => setShow(false)}
        containerClassName={classes.privacyBtn}
      >
        {t("Close")}
      </Button>
    </Modal>
  );
};

export default PublicAgreementModal;
