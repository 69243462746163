import React, { useMemo } from "react";
import Select from "@components/Form/Select";

const WalletSelect = ({
  placeholder,
  littleLabel,
  label,
  name,
  value,
  onChange,
  handleBlur,
  error,
  touched,
  companyBalance,
}) => {
  const options = useMemo(() => {
    const uniqCompany = new Set();

    if (companyBalance) {
      companyBalance.forEach(item => {
        uniqCompany.add(item.title);
      });

      const resultUniqCompany = Array.from(uniqCompany);

      return resultUniqCompany.map(name => ({
        value: name,
        label: name,
      }));
    } else {
      return [];
    }
  }, [companyBalance]);

  return (
    <Select
      littleLabel={littleLabel}
      placeholder={placeholder}
      label={label}
      name={name}
      translateOptions={false}
      value={value}
      onChange={onChange}
      handleBlur={handleBlur}
      error={error}
      touched={touched}
      options={options}
    />
  );
};

export default WalletSelect;
