import React, { useCallback, useState, Fragment, memo } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Tooltip } from "@mui/material";
import { Typography } from "src/views/blocks";

import { TextInput, Textarea } from "../../../../inputs";

import {
  AddAPhotoOutlined,
  DeleteOutline,
  KeyboardBackspace,
} from "@mui/icons-material";

import CountrySelect from "@components/Form/CountrySelect";
import s from "./style.module.scss";

import { useFormik } from "formik";

import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import { CompanySchema } from "@helpers/Formik/validation";
import { ModalUpload } from "./components/ModalUpload";
import DeleteModal from "@components/DeleteModal";
import SwitchToggle from "@components/SwitchToogle";
import { fileToBase64 } from "@utils/fileToBase64";

import IntroCompany from "@components/introLearning/company";
import { introIds } from "@components/introLearning/classes";

export const SettingsCompany = {
  avatar: {
    FILE_SIZE: {
      h: 300,
      w: 300,
    },
    SUPPORTED_FORMATS: ["JPG", "JPEG", "PNG", "HEIC"],
  },
  header: {
    RECOMMENDED_SIZE: {
      h: 260,
      w: 1920,
    },
    SUPPORTED_FORMATS: ["JPG", "JPEG", "PNG", "HEIC"],
  },
};

const CompanyForm = memo(props => {
  const {
    onBack,
    isEdit,
    data,
    onSubmit,
    deleteCompany,
    is_tutorial_passed = true,
  } = props;

  const { t } = useTranslation();

  const [showModalHeader, setShowModalHeader] = useState(false);
  const [showModalAvatar, setShowModalAvatar] = useState(false);

  const [activeToggle, setActiveToggle] = useState({
    value: "Legal",
    activeIndex: 0,
  });

  const handleToggleShowModalHeader = useCallback(() => {
    setShowModalHeader(show => !show);
  }, [setShowModalHeader]);

  const handleToggleShowModalAvatar = useCallback(() => {
    setShowModalAvatar(show => !show);
  }, [setShowModalAvatar]);

  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
    setFieldTouched,
    handleBlur,
    isSubmitting,
    errors,
    dirty,
  } = useFormik({
    initialValues: data,
    validationSchema: CompanySchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await onSubmit(values);
    },
  });

  const handleChangeCountry = useCallback(country => {
    setFieldValue("country", country.name);
  }, []);

  const handleChangeAvatar = useCallback(async img => {
    const typeFile = img.name.split(".")[img.name.split(".").length - 1];
    setFieldValue("avatar", await fileToBase64(img));
    setFieldValue("extension", typeFile);
  }, []);

  const handleChangeHeader = useCallback(async img => {
    setFieldValue("header_filename", img.name);
    setFieldValue("header_file", await fileToBase64(img));
  }, []);

  const onToggleChange = useCallback((value, index) => {
    setActiveToggle({ value, activeIndex: index });

    index === 0 && setFieldValue("is_legal_entity", true);
    index === 1 && setFieldValue("is_legal_entity", false);
  }, []);

  const handleClearAvatar = useCallback(() => {
    setFieldValue("avatar", "");
    setFieldValue("extension", "");
  }, []);

  const handleClearHeader = useCallback(() => {
    setFieldValue("header_file", "");
    setFieldValue("header_filename", "");
  }, []);

  return (
    <>
      <Grid container className={s.container}>
        <Grid
          mb={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          className={s.navigate}
        >
          <Grid className={s.back} onClick={onBack}>
            <KeyboardBackspace fontSize="small" />
            <Typography className="ml-1" color="primary" weight="normal-bold">
              {t("Companies")}
            </Typography>
          </Grid>
          {!isEdit && (
            <SwitchToggle
              id={introIds.company.element1}
              options={[
                { value: "Legal", label: t("Legal", { context: "company" }) },
                {
                  value: "Individual",
                  label: t("Individual", { context: "company" }),
                },
              ]}
              onChange={onToggleChange}
              value={activeToggle.value}
              activeIndex={activeToggle.activeIndex}
            />
          )}
        </Grid>
        <Grid item className={s.header}>
          {values.header_file ? (
            <img
              id="company-form-header"
              src={values.header_file}
              className={s.header__preview}
              height="100%"
              width="100%"
              alt="header"
            />
          ) : (
            <div className="w-100 h-100" id={introIds.company.element2} />
          )}
          <Grid className={s.header__content}>
            <Grid display="flex" alignItems="center">
              <Grid className={s.avatar} id={introIds.company.element3}>
                {values.avatar ? (
                  <Grid className={s.avatar__preview}>
                    <img
                      src={values.avatar}
                      width="100%"
                      height="100%"
                      alt="avatar"
                    />
                    <DeleteModal
                      onSuccessDelete={handleClearAvatar}
                      trigger={
                        <Tooltip title={t("Delete")}>
                          <Grid className={s.avatar__delete}>
                            <DeleteOutline fontSize="small" />
                          </Grid>
                        </Tooltip>
                      }
                      question={t(
                        "Are you sure you want to delete the avatar?",
                      )}
                    />
                  </Grid>
                ) : (
                  <Tooltip title={t("Avatar upload")}>
                    <Grid
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                      height="100%"
                      onClick={handleToggleShowModalAvatar}
                    >
                      <AddAPhotoOutlined fontSize="large" />
                    </Grid>
                  </Tooltip>
                )}
              </Grid>
              <Grid ml={2} mt={2}>
                <Typography
                  weight="semi-bold"
                  variant="h4"
                  className={s.company_title}
                >
                  {t("Company profile")}
                </Typography>
                <Typography color="gray" variant="body2" weight="normal-bold">
                  {isEdit
                    ? t("Update your company photo and details here.")
                    : t("Add your company photo and details here.")}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={s.actions} mt={2}>
              <Button
                variant="gray"
                containerClassName="mr-3"
                onClick={onBack}
                className={s.button}
              >
                {t("Cancel")}
              </Button>
              <Button
                variant="primary"
                loading={isSubmitting}
                className={s.button}
                onClick={handleSubmit}
                disabled={!dirty}
                className={s.button}
              >
                {isEdit ? t("Save") : t("Create")}
              </Button>
            </Grid>
          </Grid>
          {!values.header_file ? (
            <Tooltip title={t("Upload header company")}>
              <Grid
                className={s.header__upload}
                onClick={handleToggleShowModalHeader}
              >
                <AddAPhotoOutlined fontSize="small" />
              </Grid>
            </Tooltip>
          ) : (
            <DeleteModal
              onSuccessDelete={handleClearHeader}
              trigger={
                <Tooltip title={t("Delete")}>
                  <Grid className={s.header__delete}>
                    <DeleteOutline fontSize="small" />
                  </Grid>
                </Tooltip>
              }
              question={t("Are you sure you want to delete the header?")}
            />
          )}
        </Grid>
        <Grid item className={s.item}>
          <Grid className={s.item__block} id={introIds.company.element4}>
            <Grid className={s.item__left}>
              <Typography variant="body2" weight="normal-bold">
                {t("Company name")}
                <span className={s.required}>*</span>
              </Typography>
              <Typography variant="body2" color="gray">
                {t("Your company name will be displayed here.")}
              </Typography>
            </Grid>
            <Grid className={s.item__right}>
              <TextInput
                name="title"
                onChange={handleChange}
                value={values.title}
                fullWidth
                placeholder={t("Name")}
                error={!!errors.title}
                helperText={touched.title && t(errors.title)}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={s.item}>
          <Grid className={s.item__block} id={introIds.company.element5}>
            <Grid className={s.item__left}>
              <Typography variant="body2" weight="normal-bold">
                {t("Description")}
                <span className={s.required}>*</span>
              </Typography>
              <Typography color="gray" variant="body2">
                {t("Description of your company.")}
              </Typography>
            </Grid>
            <Grid className={s.item__right}>
              <Textarea
                fullWidth
                placeholder={`${t("Add a short description")}...`}
                name="description"
                onChange={handleChange}
                value={values.description}
                error={!!errors.description}
                helperText={touched.description && t(errors.description)}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={s.item}>
          <Grid className={s.item__block} id={introIds.company.element6}>
            <Grid className={s.item__left}>
              <Typography variant="body2" weight="normal-bold">
                {t("Company country")}
                <span className={s.required}>*</span>
              </Typography>
              <Typography color="gray" variant="body2">
                {t("Select or update the country for your company.")}
              </Typography>
            </Grid>
            <Grid className={s.item__right}>
              <CountrySelect
                name="country"
                withNameInValue
                onChange={handleChangeCountry}
                value={values.country}
                onBlur={() => setFieldTouched("country")}
                error={errors.country}
                touched={touched.country}
              />
            </Grid>
          </Grid>
        </Grid>
        {values.is_legal_entity && (
          <Fragment>
            <Grid item className={s.item}>
              <Grid className={s.item__block} id={introIds.company.element7}>
                <Grid className={s.item__left}>
                  <Typography variant="body2" weight="normal-bold">
                    {t("Registration number")}
                    <span className={s.required}>*</span>
                  </Typography>
                  <Typography color="gray" variant="body2">
                    {t(
                      "A company registration number is a unique combination of numbers and letters to enable accurate identification.",
                    )}
                  </Typography>
                </Grid>
                <Grid className={s.item__right}>
                  <TextInput
                    fullWidth
                    placeholder={t("Number")}
                    name="itn_edrpou"
                    onChange={handleChange}
                    value={values.itn_edrpou}
                    error={!!errors.itn_edrpou}
                    helperText={touched.itn_edrpou && t(errors.itn_edrpou)}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={s.item}>
              <Grid className={s.item__block} id={introIds.company.element8}>
                <Grid className={s.item__left}>
                  <Typography variant="body2" weight="normal-bold">
                    {t("Vat Number")}
                    <span className={s.required}>*</span>
                  </Typography>
                  <Typography color="gray" variant="body2">
                    {t(
                      "A value-added tax identification number or VAT identification number.",
                    )}
                  </Typography>
                </Grid>
                <Grid className={s.item__right}>
                  <TextInput
                    fullWidth
                    placeholder={t("Number")}
                    name="vat_number"
                    onChange={handleChange}
                    value={values.vat_number}
                    error={!!errors.vat_number}
                    helperText={touched.vat_number && t(errors.vat_number)}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={s.item}>
              <Grid className={s.item__block} id={introIds.company.element9}>
                <Grid className={s.item__left}>
                  <Typography variant="body2" weight="normal-bold">
                    {t("Registration address")}
                    <span className={s.required}>*</span>
                  </Typography>
                  <Typography color="gray" variant="body2">
                    {t(
                      "In essence, your company’s registered address is where your company is able to receive official notifications and service of process.",
                    )}
                  </Typography>
                </Grid>
                <Grid className={s.item__right}>
                  <TextInput
                    fullWidth
                    placeholder={t("Address")}
                    name="legal_address"
                    onChange={handleChange}
                    value={values.legal_address}
                    error={!!errors.legal_address}
                    helperText={
                      touched.legal_address && t(errors.legal_address)
                    }
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item className={s.item}>
              <Grid className={s.item__block}>
                <Grid className={s.item__left}>
                  <Typography weight="normal-bold" variant="body2">
                    {t("Contact person Name, Surname")}
                    <span className={s.required}>*</span>
                  </Typography>
                </Grid>
                <Grid className={s.item__right}>
                  <TextInput
                    fullWidth
                    placeholder={t("Name, Surname")}
                    name="contact_person"
                    onChange={handleChange}
                    value={values.contact_person}
                    error={!!errors.contact_person}
                    helperText={
                      touched.contact_person && t(errors.contact_person)
                    }
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        )}

        {isEdit && (
          <Grid item className={s.item}>
            <Grid className={s.item__block}>
              <Grid className={s.item__left}>
                <Typography weight="normal-bold" variant="body2">
                  {t("Other")}
                </Typography>
                <Typography color="gray" variant="body2">
                  {t("Other settings for your company.")}
                </Typography>
              </Grid>
              <Grid className={s.item__right}>
                <Grid display="flex" flexDirection="column">
                  {!data.is_legal_entity && (
                    <Grid mb={2} id="company-form-legal-checkbox">
                      <Checkbox
                        value={values.checkbox}
                        onChange={e =>
                          setFieldValue("checkbox", e.target.checked)
                        }
                        label={
                          <Typography variant="body2" color="gray">
                            {t("Make the company legal")}
                          </Typography>
                        }
                      />
                    </Grid>
                  )}
                  <Grid width="fit-content">
                    <DeleteModal
                      onSuccessDelete={() => deleteCompany(values.id)}
                      trigger={
                        <Button className={s.button} variant="delete">
                          <DeleteOutline fontSize="small" />
                          {t("Delete company")}
                        </Button>
                      }
                      question={t(
                        "Are you sure you want to delete the company?",
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item className={s.item} display="flex" justifyContent="center">
          <Grid className={s.actions_mob}>
            <Button
              variant="gray"
              containerClassName="mr-3"
              onClick={onBack}
              className={s.button}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="primary"
              loading={isSubmitting}
              onClick={handleSubmit}
              disabled={!dirty}
              className={s.button}
            >
              {isEdit ? t("Save") : t("Create")}
            </Button>
          </Grid>
        </Grid>
        <ModalUpload
          show={showModalHeader}
          onHide={handleToggleShowModalHeader}
          onChange={handleChangeHeader}
          name="header_file"
          settings={SettingsCompany.header}
        />
        <ModalUpload
          show={showModalAvatar}
          onHide={handleToggleShowModalAvatar}
          onChange={handleChangeAvatar}
          name="avatar"
          settings={SettingsCompany.avatar}
        />
        {/*{!isEdit && is_tutorial_passed && <IntroCompany />}*/}
      </Grid>
    </>
  );
});

export { CompanyForm };
