import React from "react";
import styled from "styled-components";
import { useTable, useExpanded } from "react-table";
import arrow from "@assets/icons/arrow-dropdown.svg";
import arrowDown from "@assets/icons/arrow-down-dropdown.svg";
import { Container, Form } from "react-bootstrap";
import { makeData2 } from "./makeData";
import filter from "@assets/icons/filter-icon.svg";
import videoImg from "@assets/icons/video-image.svg";
import arrowGreen from "@assets/icons/green-arrow.svg";

const Styles = styled.div`
  padding: 1rem;


  table {
    border-collapse: separate;
    border-spacing: 0 15px;
    margin: auto;
    width: 95%;

    tbody {
        tr {
            background: #fff;
            border-radius: 30px;
            font-size: 12px !important;
        }
    }

    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      font-size: 12px;


      :last-child {
        border-right: 0;
      }
    }
  }
`;

function Table({ columns: userColumns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: userColumns,
      data,
    },
    useExpanded // Use the useExpanded plugin hook
  );

  return (
    <>
      <Container>
        <div className="category-container my-3">
          <h6 className="page-heading">Аукцион на 20.20.2022</h6>
          <h6 className="d-flex align-items-center">
            <img src={filter} alt="" />
            <Form.Select className="custom-select" size="sm">
              <img src={filter} alt="" />
              <option>Сортировать по</option>
              <option value="1">дате</option>
            </Form.Select>
          </h6>
        </div>
      </Container>

      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Container>
        <div className="category-container my-3">
          <h6 className="page-heading">Категория: Спорт</h6>
          <h6 className="d-flex align-items-center">
            <img src={filter} alt="" />
            <Form.Select className="custom-select" size="sm">
              <option>Сортировать по</option>
              <option value="1">дате</option>
            </Form.Select>
          </h6>
        </div>
      </Container>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

function AuctionTable() {
  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: "expander", // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) =>
          // <span {...getToggleAllRowsExpandedProps()}>
          //   {isAllRowsExpanded ? (
          //     <img src={arrowDown} width="10" />
          //   ) : (
          //     <img src={arrow} width="7" />
          //   )}
          // </span>
          null,
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}
            >
              {row.isExpanded ? (
                <img src={arrowDown} width="15" alt="down" />
              ) : (
                <img src={arrow} width="10" alt="up" />
              )}
            </span>
          ) : null,
      },
      {
        id: "video",
        Header: "Видео",
        Cell: ({ row }) => {
          if (row.original.showImage === false) {
            return row.original.video;
          } else {
            return <img src={videoImg} width="100" height="50" alt="video"></img>;
          }
        },
      },
      {
        Header: "Лот",
        accessor: "lot",
      },
      // {
      //   UserInfoHeader: "Ставка",
      //   accessor: "bet",
      // },
      {
        id: "bet",
        Header: "Ставка",
        Cell: ({ row }) => {
          if (row.original.showImage === false) {
            return row.original.bet;
          } else {
            return (
              <div className="d-flex flex-column align-items-center">
                <img src={arrowGreen} width="10" height="10" alt="arrow" />
                <span>16.003</span>
              </div>
            );
          }
        },
      },
      {
        Header: "Просмотры",
        accessor: "views",
      },
      {
        Header: "Вовлеченность",
        accessor: "involves",
      },
      {
        Header: "Название",
        accessor: "title",
      },
      {
        Header: "Подробнее",
        accessor: "description",
      },
      {
        id: "btn",
        Header: "",
        Cell: ({ row }) => {
          if (row.original.showImage === false) {
            return null;
          } else {
            return (
              <div className="d-flex flex-column align-items-center">
                <button
                  style={{
                    border: "1px solid #000",
                    padding: "5px 10px",
                    borderRadius: "20px",
                  }}
                >
                  Выбрать лот
                </button>
              </div>
            );
          }
        },
      },
    ],
    []
  );

  const data2 = React.useMemo(() => makeData2(), []);
  return (
    <Styles>
      <Table columns={columns} data={data2} />
    </Styles>
  );
}

export default AuctionTable;
