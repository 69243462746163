import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Navbar, Nav, Collapse } from "react-bootstrap";
import { Link, useHistory, useLocation, NavLink } from "react-router-dom";
// import { ReactComponent as Logo } from '@assets/icons/logo.svg';
import { STAGES } from "@containers/AuthContainer";
// import { ReactComponent as Compass } from '@assets/icons/compass.svg';
// import { ReactComponent as User } from '@assets/icons/user.svg';
// import { ReactComponent as History } from '@assets/icons/history.svg';
// import { ReactComponent as Category } from '@assets/icons/buffer.svg';
// import { ReactComponent as Play } from '@assets/icons/play.svg';
// import { ReactComponent as Add } from '@assets/icons/group.svg';
// import { ReactComponent as Wallet } from '@assets/icons/wallet.svg';
//import { ReactComponent as Coins } from "@assets/icons/coins.svg";
// import { ReactComponent as Settings } from '@assets/icons/settings.svg';
// import { ReactComponent as Web } from '@assets/icons/web.svg';
// import { ReactComponent as Headphones } from '@assets/icons/headphones.svg';
// import { ReactComponent as Info } from '@assets/icons/info.svg';
// import { ReactComponent as DoorOpen } from '@assets/icons/door-open.svg';
import "@styles/components/HeaderContainer.scss";
// import { Burger } from '@components/Burger';
import { connect, useDispatch, useSelector } from "react-redux";
// import logoLightMode from '@assets/icons/logo-light.svg';
import {
  ProSidebar,
  SidebarHeader,
  // SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
// import facebook from '@assets/icons/facebook.svg';
// import twitter from '@assets/icons/twitter.svg';
// import insta from '@assets/icons/insta.svg';
// import youtube from '@assets/icons/youtube.svg';
import fullLogo from "@assets/icons/admin-logo-full.svg";
import lessLogo from "@assets/icons/admin-logo-less.svg";
import arrows from "@assets/icons/sidebar-arrows.svg";
import logoutIcon from "@assets/icons/log-out.svg";
//import { ReactComponent as UserIcon } from "@assets/icons/user-icon.svg";
import { ReactComponent as AuctionIcon } from "@assets/icons/auction.svg";
//import { ReactComponent as UploadIcon } from "@assets/icons/upload-content.svg";
import { ReactComponent as DashboardIcon } from "@assets/icons/dashboard.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ReactComponent as ContentIcon } from "@assets/icons/content.svg";
import { ReactComponent as WalletIcon } from "@assets/icons/wallet-icon.svg";
import { ReactComponent as SupportIcon } from "@assets/icons/support.svg";
import { ReactComponent as CompanyIcon } from "@assets/icons/company.svg";
import { notAuth, authStages } from "@redux/auth/actions";
import { clearTokenData, getTokenData } from "@utils/tokens";

import { signOut } from "@services/api/auth";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import UserInfoHeader from "@components/RightPanel/components/UserInfoHeader";
import SelectTranslation from "@components/SelectTranslation";

let canResize = null;

const LeftSidebar = ({ sessionId, mobile, onCloseMenu }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  let location = useLocation();

  const { t } = useTranslation();

  const { token } = useMemo(() => getTokenData(), []);

  const [expanded, setExpanded] = useState(window.innerWidth >= 1024);

  const isEmergency = useSelector(state => state.emergency.isEmergency);

  const role = useSelector(state => state.user.user.role);

  const toggleSidebar = useCallback(() => {
    setExpanded(prev => !prev);
  }, []);

  const closeOnResize = useCallback(async () => {
    setExpanded(false);
  }, []);

  useEffect(() => {
    const onResize = res => {
      if (res.currentTarget.innerWidth <= 1024 && expanded && !canResize) {
        canResize = closeOnResize;
        canResize().finally(() => {
          canResize = null;
        });
      }
      canResize = null;
    };

    if (expanded) {
      window.addEventListener("resize", onResize);
    } else {
      window.removeEventListener("resize", onResize);
    }

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [closeOnResize, expanded]);

  const logout = useCallback(async () => {
    if (role !== "demo_user") {
      await signOut(sessionId);
    }
    dispatch(notAuth());
    clearTokenData();
    dispatch(authStages(STAGES.SIGN_IN));
    localStorage.removeItem("demo-user");
    history.push("/auth");
  }, [dispatch, history, sessionId, role]);

  useEffect(() => {
    onCloseMenu && onCloseMenu();
  }, [location.pathname, onCloseMenu]);

  return (
    <div className={clsx({ "mob-d-none": !mobile }, "sidebar-wrapper")}>
      <ProSidebar>
        <SidebarHeader className={"d-flex justify-content-between"}>
          <Link to="/auction" className="d-flex">
            <Navbar.Brand to="/auction">
              <img
                src={mobile ? fullLogo : expanded ? fullLogo : lessLogo}
                alt=""
                width="70%"
                className="left-bar-logo"
              />
            </Navbar.Brand>
          </Link>
          {mobile && (
            <CloseRoundedIcon
              onClick={onCloseMenu}
              className="mobile-sidebar-close"
            />
          )}
        </SidebarHeader>
        <SidebarContent className="d-flex flex-column justify-content-between">
          <div>
            {!mobile && (
              <div
                className={`d-flex mb-4 w-100 ${
                  expanded
                    ? "justify-content-end"
                    : "justify-content-center rotated"
                }`}
              >
                <button>
                  <img
                    src={arrows}
                    alt=""
                    onClick={toggleSidebar}
                    aria-expanded={expanded}
                    aria-controls="collapse"
                    className="px-2"
                  />
                </button>
              </div>
            )}
            <div className={"sidebar-user-info"}>
              <Collapse in={!mobile ? expanded : true} dimension="width">
                <div>
                  <UserInfoHeader />
                </div>
              </Collapse>
            </div>
            {/* <Nav> */}
            {token ? (
              <>
                {isEmergency ? (
                  <NavLink
                    to="/auction"
                    activeClassName="active"
                    className="text-decoration-none dashboard-link"
                  >
                    <Nav.Item as="p">
                      <AuctionIcon className="icon" />
                      <Collapse
                        in={!mobile ? expanded : true}
                        dimension="width"
                      >
                        <span className="dashboard-text">{t("Charity")}</span>
                      </Collapse>
                    </Nav.Item>
                  </NavLink>
                ) : (
                  <NavLink
                    to="/auction"
                    activeClassName="active"
                    className="text-decoration-none dashboard-link"
                  >
                    <Nav.Item as="p">
                      <AuctionIcon className="icon" />
                      <Collapse
                        in={!mobile ? expanded : true}
                        dimension="width"
                      >
                        <span className="dashboard-text">{t("Auction")}</span>
                      </Collapse>
                    </Nav.Item>
                  </NavLink>
                )}
                <NavLink
                  to="/dashboard"
                  activeClassName="active"
                  className="text-decoration-none dashboard-link"
                >
                  <Nav.Item as="p">
                    <DashboardIcon className="icon" />
                    <Collapse in={!mobile ? expanded : true} dimension="width">
                      <span className="dashboard-text">{t("Dashboard")}</span>
                    </Collapse>
                  </Nav.Item>
                </NavLink>
                <NavLink
                  to="/content"
                  activeClassName="active"
                  className="text-decoration-none dashboard-link"
                >
                  <Nav.Item as="p">
                    <ContentIcon className="icon" />
                    <Collapse in={!mobile ? expanded : true} dimension="width">
                      <span className="dashboard-text">{t("Content")}</span>
                    </Collapse>
                  </Nav.Item>
                </NavLink>

                <NavLink
                  to="/wallet"
                  activeClassName="active"
                  className="text-decoration-none dashboard-link"
                >
                  <Nav.Item as="p">
                    <WalletIcon className="icon" />
                    <Collapse in={!mobile ? expanded : true} dimension="width">
                      <span className="dashboard-text">{t("Wallet")}</span>
                    </Collapse>
                  </Nav.Item>
                </NavLink>

                <NavLink
                  to="/companies"
                  activeClassName="active"
                  className="text-decoration-none dashboard-link"
                >
                  <Nav.Item as="p">
                    <CompanyIcon
                      style={{
                        stroke:
                          location.pathname === "/companies"
                            ? "white"
                            : "#ff5f15",
                        fill: "none",
                      }}
                      className="icon"
                    />
                    <Collapse in={!mobile ? expanded : true} dimension="width">
                      <span className="dashboard-text">{t("Company")}</span>
                    </Collapse>
                  </Nav.Item>
                </NavLink>

                <NavLink
                  to="/support"
                  activeClassName="active"
                  className="text-decoration-none dashboard-link"
                >
                  <Nav.Item as="p">
                    <SupportIcon className="icon" />
                    <Collapse in={!mobile ? expanded : true} dimension="width">
                      <span className="dashboard-text">{t("Support")}</span>
                    </Collapse>
                  </Nav.Item>
                </NavLink>
              </>
            ) : (
              <Link to="/auth" className="text-decoration-none">
                <Nav.Link as="div" className="ms-2 login-user">
                  {/* <User className="icon-small" /> */}
                  <small>{t("Sign in")}</small>
                </Nav.Link>
              </Link>
            )}
          </div>

          <div
            style={{
              paddingBottom: mobile ? "6rem" : "2rem",
            }}
          >
            <div className="dashboard-language-select">
              <SelectTranslation expand={!mobile ? expanded : true} />
            </div>
            <button className="d-flex dashboard-wrapper" onClick={logout}>
              <img src={logoutIcon} alt="" />
              <Collapse in={!mobile ? expanded : true} dimension="width">
                <span className="mx-3">{t("Log out")}</span>
              </Collapse>
            </button>
          </div>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    sessionId: state.authenticationReducer.sessionId,
  };
};

export default connect(mapStateToProps, null)(LeftSidebar);
