import React, { memo, useState, useEffect } from "react";
import logo from "@assets/images/INFOCOIN-LIMITED.svg";
import s from "./AuthContainer.module.scss";
import SignUp from "@components/Forms/SignUp";
import SignIn from "@components/Forms/SignIn";
import ActivateAccount from "@components/Forms/ActivateAccount";
import ResetPassword from "@components/Forms/ResetPassword";
import Invite from "@components/Forms/Invite";
import SelectTranslation from "@components/SelectTranslation";
import { Grid } from "@mui/material";
import AuthorizationIllustration from "@components/AuthIllustration";

import { DocumentContextProvider } from "./context/providers/documents";

import axios from "axios";

import { useLocation } from "react-router-dom";

//component
import img1 from "@assets/images/signInRightImg.png";
import img2 from "@assets/images/signUpRightImg.png";

export const STAGES = {
  SIGN_UP: "SIGH_UP",
  SIGN_IN: "SIGH_IN",
  AUTH: "AUTH",
  RESET: "RESET",
  INVITE: "INVITE",
};

const AuthContainer = () => {
  const [stage, setStage] = useState(STAGES.SIGN_IN);

  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const queryEmail = params.get("email");

  const queryCode = params.get("code");

  const onChangeStage = stage => setStage(stage);

  const [authData, setAuthData] = useState({
    email: "",
    name: "",
    country: "Ukraine",
    password: "",
    date_of_birth: "1990-01-01T00:00:00.000Z",
    confirmPassword: "",
    acceptPrivacy: true,
    acceptAgreement: true,
    code: "",
  });

  useEffect(() => {
    const initial = async () => {
      if (window.location.pathname === "/invite" && queryEmail && queryCode) {
        setAuthData(prev => ({ ...prev, email: queryEmail, code: queryCode }));

        setStage(STAGES.INVITE);
      } else {
        try {
          const res = await axios.get("https://get.geojs.io/v1/ip/geo.js", {
            dataType: "jsonp",
            jsonpCallback: "geoip",
          });

          const result = res?.request?.response;

          const country = JSON.parse(
            result.slice(6, result.length - 2),
          )?.country;

          country && setAuthData(prev => ({ ...prev, country }));
        } catch (error) {
          console.error(error);
        }
      }
    };

    initial();
  }, []);

  return (
    <React.Fragment>
      <Grid className={s.auth}>
        <Grid className={s.auth__container}>
          <Grid className={s.auth__header}>
            <Grid className={s.auth__header_logo}>
              <img src={logo} height="100%" />
            </Grid>
            <Grid>
              <SelectTranslation type="login" expand={true} />
            </Grid>
          </Grid>
          <Grid className={s.auth__content}>
            <Grid className={s.auth__content_form}>
              <DocumentContextProvider>
                {stage === STAGES.SIGN_IN && (
                  <SignIn
                    authData={authData}
                    setAuthData={setAuthData}
                    setStage={onChangeStage}
                    STAGES={STAGES}
                  />
                )}
                {stage === STAGES.SIGN_UP && (
                  <SignUp
                    setStage={onChangeStage}
                    setAuthData={setAuthData}
                    STAGES={STAGES}
                    authData={authData}
                  />
                )}
              </DocumentContextProvider>
              {stage === STAGES.AUTH && (
                <ActivateAccount
                  setStage={onChangeStage}
                  STAGES={STAGES}
                  authData={authData}
                />
              )}
              {stage === STAGES.RESET && (
                <ResetPassword setStage={onChangeStage} STAGES={STAGES} />
              )}
              {stage === STAGES.INVITE && (
                <Invite
                  defaultValue={{
                    email: authData.email,
                    code: authData.code,
                  }}
                />
              )}
            </Grid>
            {window.innerWidth > 1000 && (
              <Grid>
                <AuthorizationIllustration
                  img={stage !== STAGES.SIGN_UP ? img1 : img2}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid className={s.auth__modern} />
      </Grid>
    </React.Fragment>
  );
};

export default memo(AuthContainer);
