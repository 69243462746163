import {
  CLEAR_NEW_LOT,
  LOAD_CATEGORIES,
  LOAD_LOTS,
  SAVE_NEW_LOT,
  SELECT_CATEGORY,
  SET_ACTIVE_AUCTION,
  SET_AUCTIONS_ERROR,
  SET_LOT_TO_EDIT,
  SET_MIN_AMOUNT,
  LOAD_ALL_AUCTIONS,
  SET_DATE_AUCTION,
} from "../constants";
import {
  editLot,
  getAuctionLots,
  getAuctions,
} from "src/services/api/auctions";
import moment from "moment";
import { toast } from "react-toastify";
import { getMinAmount } from "@services/api/user";
import i18next from "i18next";

export const loadCategoriesAction = categories => ({
  type: LOAD_CATEGORIES,
  payload: categories,
});

export const loadLotsAction = lots => ({
  type: LOAD_LOTS,
  payload: lots,
});

export const loadAllAuctions = auctions => ({
  type: LOAD_ALL_AUCTIONS,
  payload: auctions,
});

export const selectCategoryAction = category => ({
  type: SELECT_CATEGORY,
  payload: category,
});

export const setDateAction = date => ({
  type: SET_DATE_AUCTION,
  payload: date,
});

export const setAuctionAction = id => ({
  type: SET_ACTIVE_AUCTION,
  payload: id,
});

export const setLotToEditAction = lot => ({
  type: SET_LOT_TO_EDIT,
  payload: lot,
});

export const getAuctionsAction = country => async dispatch => {
  try {
    const res = await getAuctions(country);

    dispatch(loadAllAuctions(res.data.auctions));

    const activeId = res.data.auctions.find(
      auction => auction.status === "active",
    ).id;

    dispatch(setAuctionAction(activeId));

    const response = await getAuctionLots(activeId);

    dispatch(loadLotsAction(response.data));
  } catch (e) {
    dispatch({
      type: SET_AUCTIONS_ERROR,
      payload: e.response?.data?.error || e.message,
    });
    toast.error(e.response?.data?.error || e.message);
  }
};

export const setSavedLotAction = lot => ({
  type: SAVE_NEW_LOT,
  payload: lot,
});

export const clearSavedLotAction = () => ({
  type: CLEAR_NEW_LOT,
});

export const getMinAmountAction = country => async (dispatch, getState) => {
  const userCountry = getState().user.user.country;
  try {
    const {
      data: { amount },
    } = await getMinAmount(country || userCountry);
    dispatch({
      type: SET_MIN_AMOUNT,
      payload: parseFloat(amount),
    });
  } catch (e) {
    toast.error(
      e.response.data.error ||
        i18next.t("Don`t get min amount, try create lot later"),
    );
  }
};

export const setLotStatusAction =
  ({ status, id }) =>
  async () => {
    try {
      await editLot(id, {
        status,
      });
    } catch (e) {
      toast.error(i18next.t("The error of update the lot"));
    }
  };
