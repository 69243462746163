import React from 'react';
import { Container } from 'react-bootstrap';

const Copyright = () => {
  return (
    <Container>
      <h4>Авторские права</h4>
    </Container>
  );
};

export default Copyright;
