import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Select from "@components/Form/Select";
import TextField from "@components/Form/TextField";
import { Grid } from "@mui/material";
import Button from "src/components/Button";
import { useCountries, useRegions } from "src/hooks";

import { EstimatedViewsComponent } from "./blocks/EstimatedViewsComponent";

import { Typography, InputGroup } from "src/views/blocks";

import { VideoFormPreview } from "../Preview";

import { InfoIcon } from "src/views/icons";

import { toast } from "react-toastify";

import { getVideoMetaData } from "src/utils";

import { VideoInput, Checkbox } from "src/views/inputs";
import * as Yup from "yup";

import {
  getVideoCountryCategoryDurations,
  getVideoDurationApi,
} from "src/services/api/video/video-api";
import { getCities } from "views/pages/VideosPage/blocks/VideoForm/api";
import { getEstimatedViews } from "src/services/api/video/video-api";
import { TooltipComponent } from "../../../Tooltip/Tooltip";
import { useFormik } from "formik";

import CountrySelect from "@components/Form/CountrySelect";

import classes from "@pages/Account/Account.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getGendersAction } from "@redux/content/contentActions";
import { gendersSelector } from "@redux/content/selectors";
import Loader from "@components/Loader";
import ScrollBar from "../../../ScrollBar";

const FirstStep = ({
  categories,
  companies,
  languages,
  data,
  onSubmit,
  isEdit,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [minDuration, setMinDuration] = useState(0);
  const [maxDuration, setMaxDuration] = useState(0);
  const genders = useSelector(gendersSelector);
  const gendersArray = genders.splice(3);

  const CreateVideoSchema = Yup.object().shape({
    title: Yup.string().required("A title is required"),
    description: Yup.string().required("A description is required"),
    isEdit: Yup.boolean(),
    video: Yup.mixed().when("isEdit", {
      is: false,
      then: Yup.mixed()
        .required("A video is required")
        .test(
          "DURATION",
          `The duration of the video must be at least ${minDuration} sec and maximum ${maxDuration} sec.`,
          async file => {
            if (file instanceof File) {
              const { duration } = await getVideoMetaData(file);

              if (Math.floor(duration) < minDuration) {
                return false;
              }

              if (Math.floor(duration) > maxDuration) {
                return false;
              }
            }

            return true;
          },
        ),
    }),
    is_all_country: Yup.boolean(),
    company_id: Yup.number().required("A company is required"),
    categories: Yup.array().test(
      "required",
      "A categories is required",
      value => value.length,
    ),
    regions: Yup.mixed().when("is_all_country", {
      is: false,
      then: Yup.array().test(
        "required",
        "A regions is required",
        value => value.length,
      ),
    }),
    email: Yup.string().email("Invalid email"),
    url: Yup.string().url("Invalid url"),
    age_min: Yup.number()
      .required("A min. age is required")
      .max(
        Yup.ref("age_max"),
        "The min. age must not be greater than the max. age",
      )
      .min(16, "The minimum age for viewing videos is 16 years old"),
    age_max: Yup.number()
      .required("A max. age is required")
      .min(16, "The minimum age for viewing videos is 16 years old"),
    genders: Yup.array().min(1, "One gender is required"),
  });

  const {
    values,
    setFieldValue,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    setFieldTouched,
    setSubmitting,
    isValid,
    dirty,
  } = useFormik({
    initialValues: { ...data, isEdit: !!isEdit },
    validationSchema: CreateVideoSchema,
    onSubmit: onSubmit,
  });

  const handlePublish = useCallback(() => {
    if (isValid) {
      setSubmitting(true);
    } else {
      toast.error(errors[Object.keys(errors)[0]]);
    }

    handleSubmit();
  }, [isValid, setSubmitting, handleSubmit, errors]);

  useEffect(() => {
    // to avoid bugs in the formik

    if (!values.title) {
      setFieldValue("title", "");
    }
  }, []);

  const countries = useCountries();
  const selectedCountry = countries.find(c => c.name === values.country);
  const regions = useRegions(selectedCountry ? selectedCountry.id : null);
  const [optionsCities, setOptionsCities] = useState([]);
  const [estimatedViews, setEstimatedViews] = useState({});
  const [isLoadingViews, setLoadingViews] = useState(false);

  const [categoriesDuration, setCategoriesDuration] = useState([]);

  const createTranslateContext = useCallback(number => {
    const string = number.toString();
    if (string.length === 1) {
      if (number === 0) {
        return "zero";
      }

      if (number === 1) {
        return "one";
      }

      if (number === 2 || number === 3 || number === 4) {
        return "few";
      }

      return "many";
    }

    if (
      Number(string[string.length - 1]) === 1 &&
      Number(string[string.length - 2]) !== 1
    ) {
      return "one";
    }
    if (
      (Number(string[string.length - 1]) === 2 &&
        Number(string[string.length - 2]) !== 1) ||
      (Number(string[string.length - 1]) === 3 &&
        Number(string[string.length - 2]) !== 1) ||
      (Number(string[string.length - 1]) === 4 &&
        Number(string[string.length - 2]) !== 1)
    ) {
      return "few";
    }
    if (Number(string[string.length - 1]) >= 5) {
      return "many";
    }
  }, []);

  const handleChangeAge = useCallback(
    (type, e) => {
      let age = e.target.value.replace(/[^0-9\.]/g, "").replace(/\./g, "");

      if (age.length === 4) {
        return;
      }

      setFieldValue(type, Number(age));
    },
    [setFieldValue],
  );

  const handleChangeIsAllCountry = useCallback(
    checked => {
      setFieldValue("is_all_country", checked);
      if (checked) {
        setOptionsCities([]);
        setFieldValue("regions", []);
        setFieldValue("cities", []);
      }
    },
    [setFieldValue],
  );

  const getDuration = useCallback(
    async (country, isChange = true) => {
      try {
        if (!isEdit) {
          const res = await getVideoDurationApi(country);

          if (res.status === 200) {
            const {
              data: { min_duration, max_duration },
            } = res;
            if (isChange) {
              setMinDuration(min_duration);
              setMaxDuration(max_duration);
            } else {
              return {
                min_duration,
                max_duration,
              };
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    [isEdit],
  );

  const handleChangeRegion = useCallback(
    async regions => {
      setOptionsCities([]);
      setFieldValue("regions", regions);

      if (!regions.length) {
        setFieldValue("cities", []);
        return;
      }

      function getOptions() {
        return regions.map(region => {
          return getCities(region.value);
        });
      }

      Promise.all(getOptions()).then(res => {
        const resultCities = res.flat();
        setOptionsCities(resultCities);
        values.cities.forEach(city => {
          const select = resultCities.find(c => c.id === city.value);
          if (!select) {
            setFieldValue(
              "cities",
              values.cities.filter(c => c.value !== city.value),
            );
          }
        });
      });
    },
    [values.cities, setFieldValue],
  );

  const handleChangeCategory = useCallback(
    category => {
      setFieldValue("categories", category);

      const resultPromise = category.map(c => {
        return getVideoCountryCategoryDurations(c.value).then(res => {
          if (res.data.length) {
            const dataObject = res.data[0];
            const categoryName = c.label;
            const result = { ...dataObject, category: categoryName };
            return [result];
          }
          return [];
        });
      });

      function findMinDurationNumber(arr) {
        return arr.reduce((a, b) => (a.min_duration < b.min_duration ? a : b))
          .min_duration;
      }

      function findMaxDurationNumber(arr) {
        return arr.reduce((a, b) => (a.max_duration > b.max_duration ? a : b))
          .max_duration;
      }

      return Promise.all(resultPromise).then(async data => {
        const result = data.flat();
        setCategoriesDuration(result);

        const countryDuration = await getDuration(values.country, false);

        if (result.length && countryDuration) {
          const resultMinDuration = findMinDurationNumber(result);
          const resultMaxDuration = findMaxDurationNumber(result);

          if (resultMinDuration > countryDuration.min_duration) {
            setMinDuration(resultMinDuration);
          } else {
            setMinDuration(countryDuration.min_duration);
          }

          if (resultMaxDuration < countryDuration.max_duration) {
            setMaxDuration(resultMaxDuration);
          } else {
            setMaxDuration(countryDuration.max_duration);
          }

          return {
            resultMinDuration,
            resultMaxDuration,
          };
        } else {
          await getDuration(values.country);
        }
      });
    },
    [setFieldValue, getDuration, values.country],
  );

  const handleChangeGoal = useCallback(
    e => {
      handleChange(e);

      if (e.target.value === "email") {
        setFieldValue("url", "");
      } else {
        setFieldValue("email", "");
      }
    },
    [handleChange, setFieldValue],
  );

  const handleChangeCountry = useCallback(
    async country => {
      setFieldValue("country", country.name);
      await getDuration(country);
      if (values.categories.length) {
        await handleChangeCategory(values.categories);
      }
    },
    [setFieldValue, getDuration, values.categories, handleChangeCategory],
  );

  useEffect(() => {
    if (selectedCountry && window.innerWidth > 900) {
      setLoadingViews(true);
      getEstimatedViews({
        country: selectedCountry.id,
        is_all_country: values.is_all_country,
        regions_ids: values.regions
          ? values.regions.map(region => region.value)
          : [],
        categories_ids: values.categories.length
          ? values.categories.map(category => category.value)
          : [],
        gender: values.gender,
        age_min: Number(values.age_min),
        age_max: Number(values.age_max),
      })
        .then(res => {
          setLoadingViews(false);
          setEstimatedViews(res.data);
        })
        .catch(() => {
          setLoadingViews(false);
        });
    }
  }, [
    values.categories,
    selectedCountry,
    values.regions,
    values.gender,
    values.age_min,
    values.age_max,
    values.is_all_country,
  ]);

  const RightAddonsCategoryComponent = useCallback(() => {
    const content = () => {
      return (
        <div>
          <div>
            {t(
              "Attention! Each category has a minimum and maximum video length limit!",
            )}
          </div>
          <div className="mt-1">
            {categoriesDuration.map((duration, i) => {
              return (
                <div className="mt-1" key={i}>
                  {t(duration.category) +
                    ": " +
                    t("secondsWithCount", {
                      count: duration.min_duration,
                      context: createTranslateContext(duration.min_duration),
                    }) +
                    " - " +
                    t("secondsWithCount", {
                      count: duration.max_duration,
                      context: createTranslateContext(duration.max_duration),
                    })}
                </div>
              );
            })}
          </div>
          <div className="mt-2">
            {t("Min. duration upload video")}:
            {" " +
              t("secondsWithCount", {
                count: minDuration,
                context: createTranslateContext(minDuration),
              })}
          </div>
          <div className="mt-1">
            {t("Max. duration upload video")}:
            {" " +
              t("secondsWithCount", {
                count: maxDuration,
                context: maxDuration,
              })}
          </div>
        </div>
      );
    };

    return (
      <div className="ml-2 d-inline-flex">
        <TooltipComponent
          content={content}
          className="d-flex align-items-center"
        >
          <InfoIcon size="small" />
        </TooltipComponent>
      </div>
    );
  }, [minDuration, maxDuration, categoriesDuration, t, createTranslateContext]);

  useEffect(() => {
    if (!minDuration && !maxDuration) {
      getDuration(values.country);
    }

    if (values.regions.length) {
      handleChangeRegion(values.regions);
    }

    if (values.categories.length) {
      handleChangeCategory(values.categories);
    }
    dispatch(getGendersAction()).then(genders => {
      if (genders.length > 0) {
        const def = genders.find(v => v.type === "male")?.id;
        if (def && !isEdit) {
          setFieldValue("genders", [def]);
        }
      }
    });
  }, []);

  return (
    <Grid container spacing={4} alignItems="stretch">
      <Grid item container md={6}>
        <ScrollBar className="scroll-content-form">
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <TextField
                warningLabel
                onChange={handleChange}
                value={values.title}
                touched={touched.title}
                error={errors.title}
                onBlur={handleBlur}
                fullWidth
                placeholder={t("Content Title")}
                label={t("Title")}
                name="title"
              />
            </Grid>
            <Grid item>
              <TextField
                warningLabel
                multiline={true}
                value={values.description}
                rows={5}
                type="textarea"
                fullWidth
                error={errors.description}
                touched={touched.description}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("Up to 500 characters")}
                label={t("Description")}
                name="description"
              />
            </Grid>
            {!isEdit && (
              <>
                <Grid item>
                  <VideoInput
                    warningLabel
                    value={values.video}
                    touched={touched.video}
                    error={errors.video}
                    onBlur={() => setFieldTouched("video")}
                    onChange={value => setFieldValue("video", value)}
                    label={t("Video")}
                    name="video"
                  />
                </Grid>
                <Grid
                  className="d-flex"
                  justifyContent="space-between"
                  paddingLeft="24px"
                  paddingY="4px"
                  marginLeft="4px"
                >
                  <Typography variant="caption2" align="left" color="grey">
                    {t("Supported file types")}: <span className="">AVI, MP4, MOV, WEBM, WMV</span>
                  </Typography>
                  <div>
                    <Typography variant="caption2" align="right" color="grey">
                      {t("Min duration")}:{" "}
                      <br/>
                      {minDuration
                        ? t("secondsWithCount", {
                            count: minDuration,
                            context: createTranslateContext(minDuration),
                          })
                        : t("Select country")}
                    </Typography>
                    <Typography variant="caption2" align="right" color="grey">
                      {t("Max duration")}:{" "}
                      <br/>
                      {maxDuration
                        ? t("secondsWithCount", {
                            count: maxDuration - 1,
                            context: createTranslateContext(maxDuration - 1),
                          })
                        : t("Select country")}
                    </Typography>
                  </div>
                </Grid>
              </>
            )}
            <Grid item>
              <Select
                warningLabel
                options={companies.map(company => {
                  const { description = "" } = company;

                  return {
                    label: company.title,
                    secondary:
                      description.length > 27
                        ? description.substring(0, 27) + "..."
                        : description,
                    value: company.id,
                  };
                })}
                fullWidth
                value={values.company_id}
                placeholder={t("Select company")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.company_id}
                label={t("Company")}
                touched={touched.company_id}
                name="company_id"
              />
            </Grid>
            <Grid item>
              <CountrySelect
                warningLabel
                value={values.country}
                error={errors.country}
                containerClassName={classes.selectContainer}
                onBlur={handleBlur}
                onChange={handleChangeCountry}
                withNameInValue
                placeholder={t("Select country")}
                label={t("Country of broadcast or show")}
                touched={touched.country}
                name="country"
              />
            </Grid>
            <Grid item paddingY="5px">
              <Checkbox
                label={t("All country")}
                name="is_all_country"
                onChange={e => handleChangeIsAllCountry(e.target.checked)}
                checked={values.is_all_country}
              />
            </Grid>
            {!values.is_all_country && (
              <>
                <Grid item>
                  <Select
                    warningLabel
                    fullWidth
                    isMulti={true}
                    options={regions.map(region => {
                      return {
                        label: region.name,
                        value: region.id,
                      };
                    })}
                    value={values.regions}
                    isSearchable={false}
                    onChange={value => handleChangeRegion(value)}
                    touched={touched.regions}
                    error={errors.regions}
                    placeholder={t("Select region")}
                    label={t("Region")}
                    name="regions"
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item>
                  <Select
                    fullWidth
                    isMulti={true}
                    options={optionsCities.map(city => {
                      return {
                        label: city.name,
                        value: city.id,
                      };
                    })}
                    value={values.cities}
                    isSearchable={false}
                    onChange={value => setFieldValue("cities", value)}
                    placeholder={t("Select city")}
                    label={t("City")}
                    name="cities"
                  />
                </Grid>
              </>
            )}
            <Grid item>
              <div className="d-flex">
                <div className="label warning-label">{t("Categories")}</div>
                {!isEdit && <RightAddonsCategoryComponent />}
              </div>
              <Select
                fullWidth
                isMulti={true}
                isSearchable={false}
                value={values.categories}
                touched={touched.categories}
                error={errors.categories}
                onChange={value => handleChangeCategory(value)}
                options={categories.map(category => {
                  return {
                    label: t(category.title),
                    value: category.id,
                  };
                })}
                placeholder={t("Select")}
                name="categories"
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item>
              <Select
                fullWidth
                value={values.speech_language}
                onChange={handleChange}
                touched={touched.speech_language}
                options={languages.map(l => {
                  return {
                    label: t(l.language),
                    value: l.language,
                  };
                })}
                placeholder={t("Choose language")}
                label={t("Content language")}
                name="speech_language"
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item>
              <Select
                fullWidth
                options={[
                  {
                    label: t("Following a link"),
                    value: "url",
                  },
                  {
                    label: t("Sending messages by mail"),
                    value: "email",
                  },
                ]}
                placeholder={t("Select")}
                label={t("Objectives of interaction")}
                name="goal"
                value={values.goal}
                onChange={handleChangeGoal}
                onBlur={handleBlur}
              />
            </Grid>
            {values.goal === "url" && (
              <Grid item>
                <TextField
                  value={values.url}
                  touched={touched.url}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.url}
                  fullWidth
                  type="url"
                  label={t("Link")}
                  name="url"
                />
              </Grid>
            )}
            {values.goal === "email" && (
              <Grid item>
                <TextField
                  value={values.email}
                  touched={touched.email}
                  onChange={handleChange}
                  error={errors.email}
                  onBlur={handleBlur}
                  fullWidth
                  label={t("E-mail")}
                  name="email"
                />
              </Grid>
            )}
            <Grid item>
              <InputGroup label={t("Age")}>
                <TextField
                  fullWidth
                  touched={touched.age_min}
                  value={values.age_min}
                  error={errors.age_min}
                  onChange={e => handleChangeAge("age_min", e)}
                  onBlur={handleBlur}
                  placeholder={t("Min age")}
                  name="age_min"
                />
                <TextField
                  fullWidth
                  touched={touched.age_max}
                  value={values.age_max}
                  onChange={e => handleChangeAge("age_max", e)}
                  placeholder={t("Max age")}
                  onBlur={handleBlur}
                  error={errors.age_max}
                  name="age_max"
                />
              </InputGroup>
            </Grid>
            <Grid container item className="gender-item">
              {genders.length ? (
                genders.map(gender => (
                  <Grid
                    item
                    sx={{
                      width: "50%",
                    }}
                  >
                    <Checkbox
                      className="video_checkbox_gender"
                      label={t(gender.title)}
                      name={gender.type}
                      checked={!!values.genders?.find(g => g === gender.id)}
                      onChange={e => {
                        const data = [...values.genders];
                        if (e.target.checked) {
                          data.push(gender.id);
                          setFieldValue("genders", data);
                        } else {
                          setFieldValue(
                            "genders",
                            data.filter(d => d !== gender.id),
                          );
                        }
                      }}
                    />
                  </Grid>
                ))
              ) : (
                <Loader />
              )}
              {errors.genders && (
                <div className={classes.error}>{errors.genders}</div>
              )}
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                onClick={handlePublish}
                size="large"
                fullWidth
                disabled={isSubmitting || !isValid || !dirty}
                loading={isSubmitting}
              >
                {isEdit ? t("Update") : t("Publish")}
              </Button>
            </Grid>
          </Grid>
        </ScrollBar>
      </Grid>
      <Grid item container md={6} className="video-right-content">
        <ScrollBar className="scroll-content-form">
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <VideoFormPreview
                values={values}
                isEdit={isEdit}
                companies={companies}
              />
            </Grid>
            <Grid item>
              <EstimatedViewsComponent
                isLoadingViews={isLoadingViews}
                estimatedViews={estimatedViews}
                categories={values.categories}
                country={values.country}
                t={t}
              />
            </Grid>
          </Grid>
        </ScrollBar>
      </Grid>
    </Grid>
  );
};

export { FirstStep };
