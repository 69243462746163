import { memo } from "react";

import s from "./index.module.scss";

import { withTranslation } from "react-i18next";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "../../../../views/blocks";
import React from "react";

import clsx from "clsx";

const RightMenuAccordion = ({
  t,
  title,
  text,
  onChange,
  openPanel,
  expanded,
  id,
}) => {
  return (
    <Accordion
      expanded={expanded === id}
      onChange={onChange(id)}
      className={s.accordion}
    >
      <AccordionSummary
        aria-controls={`panel-${id}`}
        id={`panel-${id}`}
        className={clsx({ [s.accordion__summary]: openPanel })}
        expandIcon={
          openPanel ? (
            <IconButton>
              <ExpandMoreIcon htmlColor="var(--primary-color)" />
            </IconButton>
          ) : null
        }
      >
        <Grid width="100%">
          <Typography
            className={clsx(s.title, {
              [s.title__open]: openPanel,
            })}
            variant="body2"
            weight="normal-bold"
          >
            {t(title)}
          </Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body2"
          component="span"
          className={s.text}
          dangerouslySetInnerHTML={{
            __html: t(text),
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default withTranslation()(memo(RightMenuAccordion));
