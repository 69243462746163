import React from "react";
import { Redirect, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

export const PublicRoute = ({ component: Components, ...rest }) => {
  const isAuthenticated = useSelector(
    state => state.authenticationReducer.isAuthentificated,
    shallowEqual,
  );

  const role = useSelector(state => state.user.user.role);
  if (isAuthenticated)
    return <Redirect to={role === "demo_user" ? "/content" : "/auction"} />;
  return <Route {...rest} render={props => <Components {...props} />} />;
};

export const PrivateRoute = ({ component: Components, ...rest }) => {
  const isAuthenticated = useSelector(
    state => state.authenticationReducer.isAuthentificated,
    shallowEqual,
  );

  if (!isAuthenticated) return <Redirect to={"/auth"} />;

  return <Route {...rest} render={props => <Components {...props} />} />;
};
