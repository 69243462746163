import React from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./rdr.scss";
import * as locales from "react-date-range/dist/locale";

import { useTranslation } from "react-i18next";

import { DateRange } from "react-date-range";

const nameMapper = {
  en: "enGB",
};

const RangeDayPicker = ({ value, onChange, maxDate }) => {
  const { i18n } = useTranslation();
  return (
    <div className="date-range-content">
      <DateRange
        showMonthArrow={true}
        showDateDisplay={false}
        currentColor={"#E2E2EA"}
        rangeColors={["#E2E2EA", "#E2E2EA", "#E2E2EA"]}
        locale={locales[nameMapper[i18n.language] || i18n.language]}
        maxDate={maxDate}
        direction="horizontal"
        showMonthAndYearPickers={false}
        months={window.innerWidth > 768 ? 2 : 1}
        ranges={[value]}
        onChange={onChange}
      />
    </div>
  );
};

export default RangeDayPicker;
