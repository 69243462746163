import React, {forwardRef} from "react";
import clsx from "clsx";

import {useThemeProps} from "src/hooks";

import {Typography} from "../Typography";

import "./index.scss";


const Button = forwardRef((props, ref) => {
    const {
        theme,
        component: Component = "button",
        className = "",
        children,
        disabled = false,
        bold = false,
        fullWidth = false,
        variant = "",
        color = "",
        weight,
        align = "center",
        size = "medium",
        leftAddons,
        rightAddons,
        ...rest
    } = useThemeProps(props);

    const modifications = clsx({
        "button--disabled": disabled,
        "button--full-width": fullWidth,
        [`button--variant-${variant}`]: !!variant,
        [`button--align-${align}`]: !!align,
        [`button--size-${size}`]: !!size,
        [`button--color-${color}`]: !!color
    });

    return (
        <Component
          {...rest}
          ref={ref}
          className={`button button--${theme} ${className} ${modifications}`}
          disabled={typeof Component !== "string" || Component === "button" ? disabled : undefined}>
            {leftAddons && (
                <span className="button__left-addons">
                    {leftAddons}
                </span>
            )}

            <Typography
              component="span"
              variant="body1"
              bold={bold}
              align={align}
              weight={weight}>
                {children}
            </Typography>

            {rightAddons && (
                <span className="button__right-addons">
                    {rightAddons}
                </span>
            )}
        </Component>
    );
});


export {Button};