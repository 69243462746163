import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { IconButton, Grid, Tooltip } from "@mui/material";

import UserInfoHeader from "@components/RightPanel/components/UserInfoHeader";

import RightPanelFaq from "@components/RightPanel/components/Faq";

import classes from "./RightPanel.module.scss";

import WestIcon from "@mui/icons-material/West";

const RightPanel = () => {
  const [openBar, setOpenBar] = useState(false);

  const [expanded, setExpanded] = useState(null);

  const { t } = useTranslation();

  const handleToggle = useCallback(() => {
    if (openBar) {
      setExpanded(null);

      localStorage.setItem("openBar", "blocked");
    } else {
      localStorage.removeItem("openBar", "blocked");
    }

    setOpenBar(open => !open);
  }, [openBar]);

  return (
    <Grid
      className={clsx(classes.container, {
        [classes.container__open]: openBar,
      })}
    >
      <UserInfoHeader openBar={openBar} />
      <Grid position="relative">
        <Tooltip title={openBar ? t("Close") : t("Open")}>
          <IconButton
            onClick={handleToggle}
            className={clsx(classes.toggle, {
              [classes.toggle__open]: openBar,
            })}
          >
            <WestIcon htmlColor="var(--primary-color)" />
          </IconButton>
        </Tooltip>
      </Grid>
      <RightPanelFaq
        setExpanded={setExpanded}
        expanded={expanded}
        open={openBar}
        handleToggle={() => setOpenBar(show => !show)}
      />
    </Grid>
  );
};

export default RightPanel;
