import { axiosInstance } from "@helpers/http/httpInstance";

export const fetchCreateCompany = async data => {
  return await axiosInstance.post(`api/company`, data);
};

export const createDemoCompany = async data => {
  return await axiosInstance.post(`demo/companies`, data);
};

export const fetchUpdateCompany = async (data, id) => {
  return await axiosInstance.put(`api/company/${id}`, data);
};

export const fetchDemoUpdateCompany = async (data, id) => {
  return await axiosInstance.put(`demo/companies/${id}`, data);
};

export const fetchDeleteCompany = async id => {
  return await axiosInstance.delete(`api/company/${id}`);
};

export const deleteDemoCompany = async id => {
  return await axiosInstance.delete(`demo/companies/${id}`);
};

export const getCompanies = async () => {
  return await axiosInstance.get(`api/companies`);
};

export const getDemoCompanies = async () => {
  return await axiosInstance.get(`demo/companies`);
};
