import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DefaultPlayer as Video } from "react-html5video";
import lodashGet from "lodash/get";
import Loader from "@components/Loader";
import { useRecord } from "src/hooks";
import Modal from "src/components/Modal";

const VideoField = props => {
  const { titleName, name, isAuction } = props;

  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const { record } = useRecord();

  const value = lodashGet(record, name);

  const handleOpen = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <React.Fragment>
      {!value && (
        <div>
          {isAuction ? (
            ""
          ) : (
            <div>
              <p className="mb-2">
                {t("Please wait while the video is loading")}
              </p>
              <Loader width={30} height={30} />
            </div>
          )}
        </div>
      )}

      {value && (
        <div className="position-relative" onClick={handleOpen}>
          <Video
            className="video-table"
            play={false}
            controls={["PlayPause"]}
          />
          <div
            className="position-relative"
            style={{
              width: "71px",
              height: "34px",
              margin: "0 auto",
            }}
            onClick={handleOpen}
          >
            <Video className="video-table" play={false} controls={false}>
              <source type="video/webm" src={value} />
              <track
                default
                label="English"
                kind="subtitles"
                srcLang="en"
                src={value}
              />
            </Video>

            <div className="video-overlay" />
          </div>
        </div>
      )}

      <div
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Modal
          show={isOpen}
          overlay={true}
          headerText={titleName ? t(titleName) : ""}
          contentClassName="video-modal"
          onHide={handleClose}
        >
          <Video
            className=""
            controls={["PlayPause", "Seek", "Time", "Fullscreen", "Volume"]}
          >
            <source type="video/webm" src={value} />

            <track
              default
              label="English"
              kind="subtitles"
              srcLang="en"
              src={value}
            />
          </Video>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export { VideoField };
