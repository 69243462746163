import Modal from "@components/Modal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useState } from "react";
import "../index.scss";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const tableNecessery = [
  {
    cookie: "cookielawinfo-checkbox-analytics",
    duration: "11 months",
    description:
      'This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category "Analytics".',
  },
  {
    cookie: "cookielawinfo-checkbox-functional",
    duration: "11 months",
    description:
      'The cookie is set by GDPR cookie consent to record the user consent for the cookies in the category "Functional".',
  },
  {
    cookie: "cookielawinfo-checkbox-necessary",
    duration: "11 month",
    description:
      'This cookie is set by GDPR Cookie Consent plugin. The cookies is used to store the user consent for the cookies in the category "Necessary".',
  },
  {
    cookie: "cookielawinfo-checkbox-others",
    duration: "11 month",
    description:
      'This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category "Other.',
  },
  {
    cookie: "cookielawinfo-checkbox-performance",
    duration: "11 month",
    description:
      'This cookie is set by GDPR Cookie Consent plugin. The cookie is used to store the user consent for the cookies in the category "Performance".',
  },
  {
    cookie: "viewed_cookie_policy	",
    duration: "11 month",
    description:
      "The cookie is set by the GDPR Cookie Consent plugin and is used to store whether or not user has consented to the use of cookies. It does not store any personal data.",
  },
];

const variantsCookie = [
  {
    name: "Functional",
    content:
      "Functional cookies help to perform certain functionalities like sharing the content of the website on social media platforms, collect feedbacks, and other third-party features.",
  },
  {
    name: "Performance",
    content:
      "Performance cookies are used to understand and analyze the key performance indexes of the website which helps in delivering a better user experience for the visitors.",
  },
  {
    name: "Analytics",
    content:
      "Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics the number of visitors, bounce rate, traffic source, etc.",
  },
  {
    name: "Advertisement",
    content:
      "Advertisement cookies are used to provide visitors with relevant ads and marketing campaigns. These cookies track visitors across websites and collect information to provide customized ads.",
  },
  {
    name: "Others",
    content:
      "Other uncategorized cookies are those that are being analyzed and have not been classified into a category as yet.",
  },
];

const ModalSettingsCookie = ({ show, setShow, t, handleSubmit }) => {
  const [showText, setShowText] = useState(false);
  const onToggleShowText = () => setShowText(!showText);
  const [enabled, setEnabled] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });
  const [expanded, setExpanded] = useState("");

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Modal
      dialogClassName="modal-cookie"
      show={show}
      onHide={setShow}
      headerText={t("Privacy Overview")}
    >
      <div className="cookie-modal-text">
        <p className={!showText ? "show" : ""}>
          {t(
            "This website uses cookies to improve your experience while you navigate through the website. Out of these, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may affect your browsing experience.",
          )}
        </p>
        <div onClick={onToggleShowText}>{t("Show more")}</div>
      </div>
      <div className="cookie-modal-content">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          className="cookie-modal-accardion"
        >
          <AccordionSummary
            className="cookie-modal-accardion-panel"
            expandIcon={<ArrowForwardIosIcon sx={{ fontSize: "0.9rem" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="container-panel">
              <p className="cookie-modal-accardion-panel-title">
                {t("Necessary")}
              </p>
              <p className="cookie-always">{t("Always Enabled")}</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="cookie-modal-accardion-content">
              <p className="cookie-modal-accardion-content-text">
                {t(
                  "Necessary cookies are absolutely essential for the website to function properly. These cookies ensure basic functionalities and security features of the website, anonymously.",
                )}
              </p>
              <div className="cookie-necessary-table">
                <ul className="cookie-necessary-table-head cookie-necessary-table-row">
                  <li>{t("Cookie")}</li>
                  <li>{t("Duration")}</li>
                  <li>{t("Description")}</li>
                </ul>
                {tableNecessery.map(row => {
                  const { cookie, duration, description } = row;
                  return (
                    <ul className="cookie-necessary-table-row">
                      <li>{t(cookie)}</li>
                      <li>{t(duration)}</li>
                      <li>{t(description)}</li>
                    </ul>
                  );
                })}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        {variantsCookie.map((v, i) => {
          const { name, content } = v;

          const hundleClick = e => {
            e.preventDefault();
            e.stopPropagation();
            setEnabled({ ...enabled, [i + 1]: !enabled[i + 1] });
          };
          return (
            <Accordion
              expanded={expanded === `panel${i + 2}`}
              onChange={handleChange(`panel${i + 2}`)}
              key={i + 1}
              className="cookie-modal-accardion"
            >
              <AccordionSummary
                className="cookie-modal-accardion-panel"
                expandIcon={<ArrowForwardIosIcon sx={{ fontSize: "0.9rem" }} />}
                aria-controls={`panel${i + 2}a-content`}
                id={`panel${i + 2}a-header`}
              >
                <div className="container-panel">
                  <p className="cookie-modal-accardion-panel-title">
                    {t(name)}
                  </p>
                  <div
                    className="container-accardion-checkbox"
                    onClick={e => hundleClick(e)}
                  >
                    <span>{enabled[i + 1] ? t("Enabled") : t("Disabled")}</span>
                    <div
                      className="accardion-checkbox"
                      style={{
                        justifyContent: enabled[i + 1]
                          ? "flex-end"
                          : "flex-start",
                        background: enabled[i + 1]
                          ? "var(--primary-color)"
                          : "#e3e1e8",
                      }}
                    >
                      <div />
                    </div>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="cookie-modal-accardion-content">
                  <p className="cookie-modal-accardion-content-text">
                    {t(content)}
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
      <div className="modal-cookie-submit">
        <button onClick={handleSubmit}>{t("SAVE & ACCEPT")}</button>
      </div>
    </Modal>
  );
};

export { ModalSettingsCookie };
