import React from "react";
import classes from "./MobileAuctionView.module.scss";
import VideoCell from "@components/VideosTable/components/VideoCell";
import { withTranslation } from "react-i18next";
import DeleteModal from "@components/DeleteModal";
import clsx from "clsx";
import { IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BudgetInput from "@pages/Auction/components/BudgetInput";
const MobileAuctionView = ({
  lots,
  t,
  handleDeleteLot,
  userId,
  isEmergency,
}) => {
  return (
    <div className={classes.container}>
      {lots.length > 0 ? (
        lots.map(
          lot =>
            !lot.isSeparator && (
              <div
                key={lot.id}
                className={clsx(classes.videoContainer, {
                  [classes.strangerLot]: userId !== lot.bidder_id,
                })}
              >
                <div className={classes.firstRow}>
                  <div>
                    <span style={{ fontWeight: "bold" }}>
                      №{lot.playlist_number}
                    </span>{" "}
                  </div>
                  <div>{lot.video?.title}</div>
                </div>
                {lot.bidder_id === userId && lot.video?.filepath_480_p && (
                  <VideoCell isMobile value={lot.video.filepath_480_p} />
                )}
                <div className={classes.budget}>
                  {!isEmergency && userId === lot.bidder_id ? (
                    <BudgetInput
                      id={lot.id}
                      videoId={lot.video.id}
                      amount={parseFloat(lot.amount)}
                    />
                  ) : (
                    <div>{parseFloat(lot.amount)} IC</div>
                  )}
                </div>
                {lot.bidder_id === userId && lot.budget && (
                  <div className={classes.propertyWithDesc}>
                    <span style={{ fontWeight: 600 }}>{t("Budget")}</span> :{" "}
                    {lot.budget}
                  </div>
                )}
                {lot.bidder_id === userId && lot.video?.description && (
                  <div className={classes.propertyWithDesc}>
                    <span style={{ fontWeight: 600 }}>{t("Description")}</span>{" "}
                    : {lot.video?.description}
                  </div>
                )}
                {lot.bidder_id === userId && lot.video?.company && (
                  <div className={classes.propertyWithDesc}>
                    <span style={{ fontWeight: 600 }}>{t("Company")}</span> :{" "}
                    {lot.video?.company?.title}
                  </div>
                )}
                {lot.bidder_id === userId && lot.expect_views && (
                  <div className={classes.propertyWithDesc}>
                    <span style={{ fontWeight: 600 }}>{t("Views")}</span> :{" "}
                    {lot.expect_views}
                  </div>
                )}
                <div className={classes.actions}>
                  {lot.bidder_id === userId && (
                    <div>
                      <DeleteModal
                        onSuccessDelete={() => handleDeleteLot(lot.id)}
                        trigger={
                          <IconButton>
                            <DeleteOutlineIcon
                              style={{ width: "30px", height: "30px" }}
                              color="error"
                            />
                          </IconButton>
                        }
                        question={t("Are you sure you want to delete the lot?")}
                      />
                    </div>
                  )}
                </div>
              </div>
            ),
        )
      ) : (
        <div
          style={{ height: "200px", justifyContent: "center" }}
          className={classes.videoContainer}
        >
          {t("There are no lots in this auction")}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(MobileAuctionView);
