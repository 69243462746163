import React, { memo } from "react";
import { Modal as ModalBase } from "react-bootstrap";
import classes from "./Modal.module.scss";
import clsx from "clsx";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const Modal = ({
  show,
  onHide,
  children,
  footer,
  dialogClassName,
  headerText = "",
  size = "lg",
  contentClassName,
  overlay,
  headerClassName,
  bodyClassName,
  closeButton = true,
  closeButtonClassName,
  backdrop = "static",
  ...props
}) => {
  return (
    <>
      <ModalBase
        show={show}
        onHide={onHide}
        size={size}
        backdrop={backdrop}
        contentClassName={clsx(classes.container, contentClassName)}
        dialogClassName={dialogClassName}
        backdropClassName={overlay && classes.modal_overlay}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        {...props}
      >
        <ModalBase.Header className={clsx(classes.header, headerClassName)}>
          {headerText}
          {closeButton && (
            <CloseRoundedIcon
              onClick={onHide}
              className={clsx(classes.close, closeButtonClassName)}
            />
          )}
        </ModalBase.Header>
        <ModalBase.Body className={bodyClassName}>{children}</ModalBase.Body>
        {footer && (
          <ModalBase.Footer className="flex-row-center-align">
            {footer}
          </ModalBase.Footer>
        )}
      </ModalBase>
    </>
  );
};

export default memo(Modal);
