//react
import React, { memo } from "react";

//components
import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import { Typography } from "src/views/blocks";
import DevicesIcon from "@mui/icons-material/Devices";

//style
import s from "../../Account.module.scss";
import moment from "moment";

//other
import { useTranslation } from "react-i18next";

const Sessions = ({ sessions }) => {
  const { t } = useTranslation();

  return (
    <Grid mt={4}>
      <Grid>
        <Typography variant="h5" weight="semi-bold">
          {t("Where you’re logged in")}
        </Typography>
        <Typography variant="body2" color="gray">
          {t(
            "We'll alert you, if there is any unusual activity on your account.",
          )}
        </Typography>
      </Grid>
      {sessions.map(session => (
        <Grid key={session.id} className={s.item} mt={2}>
          <Grid display="flex">
            <Grid>
              <DevicesIcon htmlColor="gray" />
            </Grid>
            <Grid ml={2}>
              <Typography variant="body2" weight="normal-bold">
                {session.client}
              </Typography>
              <Typography variant="body2" color="gray">
                {session.ip} -{" "}
                {moment(session.updated_at).format("DD.MM.YYYY - hh:mm")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default memo(Sessions);
