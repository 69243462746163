import {
  getLotStatisticInPast,
  getLotStatisticInPlaylist,
  getPastStatistic,
  getPlaylistStatistic,
  getVideosComplaints,
  getVideosCTR,
  getVideosInteractions,
  getVideosLikes,
  getVideosViews,
} from "src/services/api/dashboard";
import {
  SET_DASHBOARD_STATISTIC,
  SET_PAST_SELECTED,
  SET_PAST_STATISTIC,
  SET_PLAYLIST_SELECTED,
  SET_PLAYLIST_STATISTIC,
  SET_VIDEOS_COMPLAINTS,
  SET_VIDEOS_CTR,
  SET_VIDEOS_INTERACTIONS,
  SET_VIDEOS_LIKES,
  SET_VIDEOS_VIEWS,
} from "src/redux/dashboard/constants";
import { toast } from "react-toastify";
import i18next from "i18next";
import { getTransactionsByDate } from "@services/api/wallet";
import { updateVideo } from "@services/api/video/video-api";

export const getVideosLikesAction =
  ({ startTime, endTime }) =>
  async dispatch => {
    try {
      const response = getVideosLikes({ startTime, endTime });
      dispatch({
        type: SET_VIDEOS_LIKES,
        payload: response,
      });
    } catch (e) {
      toast.error(i18next.t("The error of getting videos likes"));
    }
  };

export const getVideosViewsAction =
  ({ startTime, endTime }) =>
  async dispatch => {
    try {
      const response = getVideosViews({ startTime, endTime });
      dispatch({
        type: SET_VIDEOS_VIEWS,
        payload: response,
      });
    } catch (e) {
      toast.error(i18next.t("The error of getting videos likes"));
    }
  };

export const getVideosCTRAction =
  ({ startTime, endTime }) =>
  async dispatch => {
    try {
      const response = getVideosCTR({ startTime, endTime });
      dispatch({
        type: SET_VIDEOS_CTR,
        payload: response,
      });
    } catch (e) {
      toast.error(i18next.t("The error of getting videos likes"));
    }
  };

export const getVideosInteractionsAction =
  ({ startTime, endTime }) =>
  async dispatch => {
    try {
      const response = getVideosInteractions({ startTime, endTime });
      dispatch({
        type: SET_VIDEOS_INTERACTIONS,
        payload: response,
      });
    } catch (e) {
      toast.error(i18next.t("The error of getting videos likes"));
    }
  };
export const getVideosComplaintsAction =
  ({ startTime, endTime }) =>
  async dispatch => {
    try {
      const response = getVideosComplaints({ startTime, endTime });
      dispatch({
        type: SET_VIDEOS_COMPLAINTS,
        payload: response,
      });
    } catch (e) {
      toast.error(i18next.t("The error of getting videos likes"));
    }
  };

export const getDashboardStatisticAction =
  ({ startTime, endTime }) =>
  async dispatch => {
    try {
      const [
        {
          value: { data: interactions },
        },
        {
          value: { data: complaints },
        },
        {
          value: { data: ctr },
        },
        {
          value: { data: views },
        },
        {
          value: { data: likes },
        },
        {
          value: { total: income },
        },
      ] = await Promise.allSettled([
        getVideosInteractions({ startTime, endTime }),
        getVideosComplaints({ startTime, endTime }),
        getVideosCTR({ startTime, endTime }),
        getVideosViews({ startTime, endTime }),
        getVideosLikes({ startTime, endTime }),
        getTransactionsByDate({ start: startTime, end: endTime }),
      ]);
      let viewsCount = 0;
      let likesCount = 0;
      let complainsCount = 0;
      if (views) {
        viewsCount = views.reduce(
          (count, value) => count + value.total_views_count,
          0,
        );
      }

      if (complaints) {
        complainsCount = complaints.reduce(
          (count, value) => count + value.video_check_lists.length,
          0,
        );
      }

      if (likes) {
        likesCount = likes.reduce(
          (count, value) => count + value.likes_count,
          0,
        );
      }

      dispatch({
        type: SET_DASHBOARD_STATISTIC,
        payload: {
          interactions: interactions ? { ...interactions } : {},
          complaints: complainsCount,
          ctr: ctr?.click_through_rate || 0,
          views: viewsCount,
          likes: likesCount,
          income: income,
        },
      });
    } catch (e) {
      toast.error(i18next.t("The error of getting statistic"));
    }
  };

export const getPlaylistStatisticAction =
  ({ limit, offset }) =>
  async dispatch => {
    try {
      const response = await getPlaylistStatistic({ limit, offset });
      console.log(response);
      dispatch({
        type: SET_PLAYLIST_STATISTIC,
        payload: response.data,
      });
      return response.data.lots;
    } catch (e) {
      toast.error(i18next.t("The error of getting playlist statistic"));
    }
  };

export const getLotStatisticInPlaylistAction = id => async dispatch => {
  try {
    const response = await getLotStatisticInPlaylist(id);
    dispatch({
      type: SET_PLAYLIST_SELECTED,
      payload: { ...response.data, id },
    });
  } catch (e) {
    toast.error(i18next.t("The error of getting lot statistic in playlist"));
  }
};

export const getPastStatisticAction =
  ({ limit, offset, startTime, endTime, country }) =>
  async dispatch => {
    try {
      const response = await getPastStatistic({
        limit,
        offset,
        start_time: startTime,
        end_time: endTime,
        country,
      });
      dispatch({
        type: SET_PAST_STATISTIC,
        payload: response.data,
      });
      return response.data.lots || [];
    } catch (e) {
      toast.error(i18next.t("The error of getting past statistic"));
    }
  };

export const getPastLotStatisticAction =
  ({ id, startTime, endTime, geo }) =>
  async dispatch => {
    try {
      const response = await getLotStatisticInPast({
        id,
        start_time: startTime,
        end_time: endTime,
        geo_detail: geo,
      });
      dispatch({
        type: SET_PAST_SELECTED,
        payload: { id, ...response.data },
      });
    } catch (e) {
      toast.error(i18next.t("The error of getting past lot statistic"));
    }
  };

export const setVideoStatusAction =
  ({ status, id }) =>
  async () => {
    try {
      await updateVideo({
        id,
        data: {
          status,
        },
      });
    } catch (e) {
      toast.error(i18next.t("The error of change status"));
    }
  };
