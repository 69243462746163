import { getCities, getCountries, getRegions } from "@services/api/language";
import { toast } from "react-toastify";
import {
  GET_CITIES,
  GET_CITIES_ERROR,
  GET_CITIES_SUCCESS,
  GET_COUNTRIES,
  GET_COUNTRIES_ERROR,
  GET_COUNTRIES_SUCCESS,
  GET_REGIONS,
  GET_REGIONS_ERROR,
  GET_REGIONS_SUCCESS,
} from "@redux/language/constants";

export const getCountriesAction = () => async dispatch => {
  dispatch({ type: GET_COUNTRIES });
  try {
    const { data } = await getCountries();
    dispatch({
      type: GET_COUNTRIES_SUCCESS,
      payload: data,
    });

    throw data;
  } catch (e) {
    dispatch({
      type: GET_COUNTRIES_ERROR,
      payload: e,
    });

    toast.error(e.message);

    return e;
  }
};
export const getRegionsAction = countryId => async dispatch => {
  dispatch({ type: GET_REGIONS });
  try {
    const { data } = await getRegions(countryId);
    dispatch({
      type: GET_REGIONS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_REGIONS_ERROR,
      payload: e,
    });
    toast.error(e.message);
  }
};
export const getCitiesAction = regionId => async dispatch => {
  dispatch({ type: GET_CITIES });
  try {
    const { data } = await getCities(regionId);
    dispatch({
      type: GET_CITIES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: GET_CITIES_ERROR,
      payload: e,
    });
    toast.error(e.message);
  }
};
