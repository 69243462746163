//React
import React, { memo, useCallback, useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";

//store
import { useSelector, connect } from "react-redux";
import {
  createCompanyAction,
  getCompaniesAction,
  getDemoCompaniesAction,
} from "@redux/companies/actions";
import {
  createDemoCompany,
  deleteDemoCompany,
  fetchCreateCompany,
  fetchDeleteCompany,
  fetchDemoUpdateCompany,
  fetchUpdateCompany,
} from "@services/api/compnaies";

//components
import { Container } from "react-bootstrap";
import CompanyItem from "./components/CompanyItem";
import Button from "@components/Button";
import Loader from "@components/Loader";
import Company from "@pages/Companies/components/Company";

//styled
import classes from "./Companies.module.scss";
import "intro.js/introjs.css";

//other
import clsx from "clsx";
import { deleteBase64Info } from "src/utils/files";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import { Typography } from "../../views/blocks";
import { introIds } from "@components/introLearning/classes";
import IntroCompanies from "@components/introLearning/companies";

const VIEWS = {
  MAIN: "MAIN",
  EDIT: "EDIT",
  CREATE: "CREATE",
};

const defaultData = {
  avatar: "",
  contact_person: "",
  vat_number: "",
  description: "",
  header_file: "",
  extensions: "",
  header_filename: "",
  itn_edrpou: "",
  legal_address: "",
  is_legal_entity: true,
  title: "",
  country: "",
};

const Companies = ({
  companies,
  is_tutorial_passed = true,
  getCompaniesAction,
  getDemoCompaniesAction,
  loading,
}) => {
  const { t } = useTranslation();

  const [selectedCompany, setSelectedCompany] = useState({});

  const [view, setView] = useState(VIEWS.MAIN);

  const role = useSelector(state => state.user.user.role);

  const handleChangeView = useCallback((company, type) => {
    setSelectedCompany({
      ...company,
      checkbox: company.is_legal_entity,
      header_file: company.header_filepath,
    });
    setView(type);
  }, []);

  const handleBack = useCallback(() => {
    handleChangeView({}, VIEWS.MAIN);

    if (role === "demo_user") {
      getDemoCompaniesAction();
    } else {
      getCompaniesAction();
    }
  }, [handleChangeView, VIEWS.MAIN, role]);

  const createCompany = useCallback(
    async values => {
      const data = {
        ...values,
        avatar: values.avatar ? deleteBase64Info(values.avatar) : "",
        header_file: values.header_file
          ? deleteBase64Info(values.header_file)
          : "",
      };

      try {
        const res =
          role === "demo_user"
            ? await createDemoCompany(data)
            : await fetchCreateCompany(data);

        if (res.status === 201) {
          toast.success(t("The company has been successfully established!"));
          handleBack();
        }
      } catch (e) {
        toast.error(t(e?.response?.data?.error));
      }
    },
    [
      createCompanyAction,
      createDemoCompany,
      deleteBase64Info,
      handleBack,
      toast,
      role,
      t,
    ],
  );

  const updateCompany = useCallback(
    async values => {
      const {
        avatar,
        extension,
        header_file,
        header_filename,
        is_legal_entity,
        checkbox,
      } = values;
      const data = {
        ...values,
        is_legal_entity: is_legal_entity === false ? checkbox : is_legal_entity,
        ...(avatar !== selectedCompany.avatar
          ? {
              avatar: avatar ? deleteBase64Info(avatar) : "delete",
              extension: extension ? extension : "delete",
            }
          : {}),
        ...(header_file !== selectedCompany.header_filepath
          ? {
              header_filepath: header_file
                ? deleteBase64Info(header_file)
                : "delete",
              header_filename: header_filename ? header_filename : "delete",
            }
          : {}),
      };

      if (header_file === selectedCompany.header_filepath) {
        delete data.header_filename;
        delete data.header_filepath;
      }

      delete data.header_file;

      try {
        const res =
          role === "demo_user"
            ? await fetchDemoUpdateCompany(data, data.id)
            : await fetchUpdateCompany(data, data.id);

        if (res.status === 200) {
          toast.success(t("The company has been successfully updated!"));
          handleBack();
        }
      } catch (e) {
        toast.error(t(e?.response?.data?.error));
      }
    },
    [
      fetchDemoUpdateCompany,
      fetchUpdateCompany,
      deleteBase64Info,
      selectedCompany,
      handleBack,
      toast,
      t,
    ],
  );

  const deleteCompany = useCallback(
    async id => {
      try {
        if (role === "demo_user") {
          await deleteDemoCompany(id);
        } else {
          await fetchDeleteCompany(id);
        }
        toast.success(t("The company has been successfully deleted!"));
        handleBack();
      } catch (e) {
        toast.error(t(e?.response?.data?.error));
      }
    },
    [fetchDeleteCompany, t, handleBack, toast, deleteDemoCompany],
  );

  useEffect(() => {
    if (companies.length < 1) {
      if (role === "demo_user") {
        getDemoCompaniesAction();
      } else {
        getCompaniesAction();
      }
    } //eslint-disable-next-line
  }, [companies.length, role]);

  return (
    <>
      <Container className={clsx(classes.companies, "companies")}>
        {view === VIEWS.EDIT && (
          <Company
            data={selectedCompany}
            deleteCompany={deleteCompany}
            isEdit={true}
            onSubmit={updateCompany}
            onBack={handleBack}
            is_tutorial_passed={is_tutorial_passed}
          />
        )}

        {view === VIEWS.CREATE && (
          <Company
            data={defaultData}
            onSubmit={createCompany}
            isEdit={false}
            onBack={handleBack}
          />
        )}

        {view === VIEWS.MAIN && (
          <Fragment>
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <h1 className={classes.pageTitle}>{t("Company")}</h1>
              <Button
                containerClassName={classes.btnContainer}
                className={classes.createBtn}
                id={introIds.companies.element2}
                onClick={() => handleChangeView({}, VIEWS.CREATE)}
              >
                {t("Create Company")}
              </Button>
            </div>
            {loading ? (
              <Loader className={classes.loader} width={120} height={120} />
            ) : (
              <Grid className={classes.companies__list} mt={2}>
                {companies.some(company => !company.country) && (
                  <Typography
                    variant="body1"
                    color="error"
                    weight="normal-bold"
                  >
                    {t("Attention! Enter the country in your company!")}
                  </Typography>
                )}
                <Grid
                  mt={2}
                  display="flex"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  {companies.map(company => (
                    <CompanyItem
                      key={company.id}
                      data={company}
                      onSubmit={() => handleChangeView(company, VIEWS.EDIT)}
                      id={
                        company.id === companies[0].id
                          ? introIds.companies.element1
                          : ""
                      }
                    />
                  ))}
                </Grid>

                {!companies.length && (
                  <Grid mt={4}>
                    <Typography
                      align="center"
                      variant="h4"
                      color="gray"
                      weight="normal-bold"
                    >
                      {t("You are not the owner of any company")}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
          </Fragment>
        )}
        {/*{companies.length*/}
        {/*  ? is_tutorial_passed && (*/}
        {/*      <IntroCompanies*/}
        {/*        onComplete={() => handleChangeView({}, VIEWS.CREATE)}*/}
        {/*      />*/}
        {/*    )*/}
        {/*  : ""}*/}
      </Container>
    </>
  );
};
const mapStateToProps = ({ companies, user }) => ({
  companies: companies.companies,
  loading: companies.loading,
  error: companies.error,
  is_tutorial_passed: user.user.is_tutorial_passed,
});

const mapDispatchToProps = {
  getCompaniesAction,
  getDemoCompaniesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Companies));
