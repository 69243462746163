import { combineReducers } from "redux";

// api
import { authenticationReducer } from "@redux/auth/reducer";
import languageReducer from "@redux/language/reducer";
import userReducer from "@redux/user/reducer";
import companiesReducer from "@redux/companies/reducer";
import { auctionReducer } from "@redux/auction/reducer";
import { emergencyReducer } from "@redux/emergency/reducer";
import walletReducer from "@redux/wallet/reducer";
import notificationReducer from "@redux/notifiactions/reducer";
import dashboardReducer from "@redux/dashboard/reducer";
import contentReduce from "@redux/content/reducer";

export const rootReducer = combineReducers({
  authenticationReducer,
  language: languageReducer,
  user: userReducer,
  auction: auctionReducer,
  companies: companiesReducer,
  emergency: emergencyReducer,
  wallet: walletReducer,
  notifications: notificationReducer,
  dashboard: dashboardReducer,
  content: contentReduce,
});
