import React, {
  isValidElement,
  useState,
  useMemo,
  useCallback,
  Children,
  memo,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} from "@mui/material";

import { DataTableHead } from "../DataTableHead";
import { DataTableRow } from "../DataTableRow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./index.scss";
import clsx from "clsx";

const DataTable = props => {
  const {
    contentComponent,
    emptyComponent: EmptyComponent,
    emptyComponentProps = {},
    getIsDisabled,
    selectable = false,
    isAuction,
    pagination = false,
    bulkSelect,
    children,
    selected = [],
    items = [],
    page = 0,
    perPage = 5,
    perPageOptions = [5, 10, 25],
    total = 0,
    orderBy,
    orderWay,
    onSelect,
    onPageChange,
    onPerPageChange,
    onOrderByChange,
    onOrderWayChange,
    withExpensive,
    className,
  } = props;

  const [isShowAll, setIsShowAll] = useState(false);

  const { t } = useTranslation();
  // const [] = useState(page);

  const colsCount = useMemo(() => {
    let colsCount = 0;

    if (selectable || bulkSelect) {
      colsCount++;
    }

    Children.map(children, child => {
      if (!isValidElement(child)) {
        return null;
      }

      colsCount++;
    });

    return colsCount;
  }, [selectable, bulkSelect, children]);

  const handleSelect = useCallback(
    (record, isSelected) => {
      if (selectable && bulkSelect) {
        let list;
        if (!isSelected) {
          list = [...selected].filter(v => v !== record.id);
        } else {
          list = [...selected, record.id];
        }

        if (onSelect) {
          onSelect(list);
        }
      } else if (selectable && isSelected) {
        if (onSelect) {
          onSelect([record.id]);
        }
      }
    },
    [selectable, bulkSelect, selected, onSelect],
  );

  const handlePageChange = useCallback(
    (e, page) => {
      if (onPageChange) {
        onPageChange(page);
      }
    },
    [onPageChange],
  );

  const handlePerPageChange = useCallback(
    e => {
      const {
        target: { value },
      } = e;

      if (onPerPageChange) {
        onPerPageChange(value);
      }
    },
    [onPerPageChange],
  );

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <TableContainer
        className={clsx(
          "data-table",
          withExpensive && "with-expensive",
          className,
        )}
        style={{
          ...(withExpensive && {
            overflowY: "hidden",
            maxHeight: isShowAll ? items.length * 89 + 56 + "px" : 330 + "px",
            height: isShowAll ? items.length * 89 + 56 + "px" : 330 + "px",
          }),
          ...(withExpensive &&
            items.length <= 3 && {
              height: items.length * 89 + 56 + "px",
            }),
          ...(items.length === 0 && {
            height: "unset",
          }),
        }}
      >
        <Table className="data-table__table" aria-labelledby="tableTitle">
          <DataTableHead
            contentComponent={contentComponent}
            selectable={selectable || bulkSelect}
            colsCount={colsCount}
            orderBy={orderBy}
            orderWay={orderWay}
            onOrderByChange={onOrderByChange}
            onOrderWayChange={onOrderWayChange}
            items={items}
          >
            {children}
          </DataTableHead>

          <TableBody>
            {items.map((record, index) => {
              return (
                <DataTableRow
                  key={record.id ? record.id : index}
                  isAuction={isAuction}
                  contentComponent={contentComponent}
                  selectable={selectable || bulkSelect}
                  colsCount={colsCount}
                  selected={
                    selected.filter(id => {
                      return id === record.id;
                    }).length > 0
                  }
                  disabled={getIsDisabled ? getIsDisabled(record) : false}
                  record={record}
                  onSelect={handleSelect}
                >
                  {children}
                </DataTableRow>
              );
            })}
            {items.length === 0 && (
              <TableRow>
                <TableCell className="none-table-items" colSpan={colsCount}>
                  {EmptyComponent && (
                    <EmptyComponent {...emptyComponentProps} />
                  )}

                  {!EmptyComponent && (
                    <Grid
                      sx={{ padding: "30px 20px 20px", fontWeight: "bold" }}
                      container
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>{t("There are no lots in this auction")}</Grid>
                    </Grid>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>

          <TableFooter>
            <TableRow>
              {pagination && (
                <TablePagination
                  colSpan={colsCount}
                  labelRowsPerPage={t("Rows per page")}
                  labelDisplayedRows={options => {
                    const { from, to, count } = options;

                    return `${from}-${to} ${t("of")} ${count}`;
                  }}
                  count={total}
                  page={page}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={perPageOptions}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handlePerPageChange}
                />
              )}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {withExpensive && items.length > 3 && (
        <div
          onClick={e => {
            e.stopPropagation();
            setIsShowAll(!isShowAll);
          }}
        >
          {!isShowAll ? (
            <ArrowDropDownIcon className={"arrow-button"} />
          ) : (
            <ArrowDropDownIcon className={"arrow-button arrow-up"} />
          )}
        </div>
      )}
    </div>
  );
};
export default memo(DataTable);
