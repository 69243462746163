import { toast } from "react-toastify";
import {
  ADD_NEW_INVOICE,
  LOADING_END,
  SET_AMOUNT_INVOICE,
  SET_BALANCE,
  SET_EXCHANGE_RATE,
  SET_HISTORY,
  SET_INVOICES,
  SET_LOADING,
  SET_PENDING_TRANSACTIONS,
  SET_TRANSACTIONS,
  SET_COMPANIES_WALLETS,
} from "@redux/wallet/constants";
import {
  createInvoices,
  getExchangeRate,
  getInvoices,
  getLastYearTransaction,
  getTransactions,
  getWallet,
} from "@services/api/wallet";
import moment from "moment";

export const getBalanceAction = () => async dispatch => {
  dispatch({
    type: SET_LOADING,
  });
  try {
    const response = await getWallet();
    if (response?.available_balance) {
      dispatch({
        type: SET_BALANCE,
        payload: {
          commonBalance: Number(response.available_balance.replace(" IC", "")),
          balance: Number(response.balance.replace(" IC", "")),
          wallets: response.companies_countries_wallets,
        },
      });
    }
  } catch (error) {
    toast.error(error.response?.data?.error || error.message);
    throw error;
  } finally {
    dispatch({
      type: LOADING_END,
    });
  }
};

export const getHistoryAction = () => async dispatch => {
  dispatch({
    type: SET_LOADING,
  });

  try {
    const response = await getLastYearTransaction();

    if (response?.transactions?.length >= 1) {
      const newSchedule = [
        {
          name: "January",
          sum: 0,
          id: 0,
        },
        {
          name: "February",
          sum: 0,
          id: 1,
        },
        {
          name: "March",
          sum: 0,
          id: 2,
        },
        {
          name: "April",
          sum: 0,
          id: 3,
        },
        {
          name: "May",
          sum: 0,
          id: 4,
        },
        {
          name: "June",
          sum: 0,
          id: 5,
        },
        {
          name: "July",
          sum: 0,
          id: 6,
        },
        {
          name: "August",
          sum: 0,
          id: 7,
        },
        {
          name: "September",
          sum: 0,
          id: 8,
        },
        {
          name: "October",
          sum: 0,
          id: 9,
        },
        {
          name: "November",
          sum: 0,
          id: 10,
        },
        {
          name: "December",
          sum: 0,
          id: 11,
        },
      ];

      response.transactions.forEach(transaction => {
        const currentMonth = moment(transaction.created_at).get("month");

        newSchedule[currentMonth].sum += parseFloat(transaction.sum);
      });

      dispatch({
        type: SET_HISTORY,
        payload: {
          history: newSchedule,
          hasHistory: response.transactions.length > 0,
        },
      });
    }
  } catch (error) {
    toast.error(error.response?.data?.error || error.message);
    throw error;
  } finally {
    dispatch({
      type: LOADING_END,
    });
  }
};

export const getExchangeRateAction = userCountry => async dispatch => {
  dispatch({
    type: SET_LOADING,
  });

  try {
    const response = await getExchangeRate(userCountry);
    if (response) {
      dispatch({
        type: SET_EXCHANGE_RATE,
        payload: {
          input: Number(response.find(ex => ex.type === "input").rate),
          output: Number(response.find(ex => ex.type === "output").rate),
        },
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.error || error.message);
    throw error;
  } finally {
    dispatch({
      type: LOADING_END,
    });
  }
};

export const getTransactionsAction = () => async dispatch => {
  dispatch({
    type: SET_LOADING,
  });

  try {
    const response = await getTransactions();

    dispatch({
      type: SET_TRANSACTIONS,
      payload: response,
    });
    return response;
  } catch (error) {
    toast.error(error.response?.data?.error || error.message);
    throw error;
  } finally {
    dispatch({
      type: LOADING_END,
    });
  }
};

export const getInvoicesAction = async dispatch => {
  dispatch({
    type: SET_LOADING,
  });

  try {
    const response = await getInvoices();

    dispatch({
      type: SET_INVOICES,
      payload: response || [],
    });
    return response;
  } catch (error) {
    toast.error(error.response?.data?.error || error.message);
    throw error;
  } finally {
    dispatch({
      type: LOADING_END,
    });
  }
};
export const createInvoiceAction = data => async dispatch => {
  try {
    const response = await createInvoices(data);
    dispatch({
      type: ADD_NEW_INVOICE,
      payload: response,
    });
    return response;
  } catch (error) {
    toast.error(error.response?.data?.error || error.message);
    throw error;
  } finally {
    dispatch({
      type: LOADING_END,
    });
  }
};

export const setPendingTransactionsAction = transactions => async dispatch => {
  dispatch({
    type: SET_PENDING_TRANSACTIONS,
    payload: transactions,
  });
};

export const setInvoiceInfoAction = amount => ({
  type: SET_AMOUNT_INVOICE,
  payload: amount,
});
