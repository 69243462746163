import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { PublicRoute, PrivateRoute } from "./Layout.js";

import BaseContainer from "src/containers/BaseContainer";
import AuthContainer from "src/containers/AuthContainer/index.js";
import Account from "src/pages/Account";
import Wallet from "src/pages/Wallet";
import Information from "src/components/Information/index.js";
import { AuthorProfile } from "src/components/AuthorProfile";
import LinkBrowsingHistory from "src/components/History/LinkBrowsingHistory.js";
import AuctionLink from "src/components/AuctionLink/AuctionLink.js";
import AuctionTable from "src/components/Auctions/AuctionTable.js";
import Auction from "src/pages/Auction";
import { Content } from "src/pages/Content";
import "./transitions.css";
import Companies from "src/pages/Companies";
import Support from "src/pages/Support";
import Charity from "src/pages/Charity";
import ApplicationContainer from "src/containers/ApplicationContainer";
import Dashboard from "src/pages/Dashboard";
import Invoice from "src/pages/Invoice";
import DemoErrorInfo from "@pages/DemoErrInfo/index.js";
import WalletDetails from "@pages/Wallet/components/Transaction/WalletDetails/WalletDetails.js";

import { useSelector } from "react-redux";
import SuccessAction from "@components/SuccessAction";
import FailPay from "@pages/FailPay";
import EditVideo from "@pages/EditVideo/index";

const AppRouter = () => {
  const role = useSelector(state => state.user.user.role);

  return (
    <>
      <Switch className="overflow-scroll">
        <PublicRoute path="/invite" component={() => <AuthContainer />} />
        <PublicRoute exact path="/auth" component={() => <AuthContainer />} />
        <Route
          exact
          path={`/success-pay`}
          component={() => (
            <div
              style={{
                height: "100vh",
                maxHeight: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SuccessAction text="You have successfully replenished the wallet" />
            </div>
          )}
        ></Route>
        <Route exact path={`/fail-pay`} component={() => <FailPay />}></Route>

        <ApplicationContainer>
          <PrivateRoute
            exact
            path="/history/:item"
            component={() => (
              <BaseContainer>
                {role && role !== "demo_user" ? (
                  <LinkBrowsingHistory />
                ) : (
                  <DemoErrorInfo />
                )}
              </BaseContainer>
            )}
          />

          <PrivateRoute
            exact
            path="/dashboard"
            component={() => (
              <BaseContainer>
                {role && role !== "demo_user" ? (
                  <Dashboard />
                ) : (
                  <DemoErrorInfo />
                )}
              </BaseContainer>
            )}
          />

          <PrivateRoute
            exact
            path="/invoice"
            component={() => (
              <BaseContainer>
                {role && role !== "demo_user" ? <Invoice /> : <DemoErrorInfo />}
              </BaseContainer>
            )}
          />
          <PrivateRoute
            exact
            path="/account"
            component={() => (
              <BaseContainer>
                <Account />
              </BaseContainer>
            )}
          />
          <PrivateRoute
            path="/wallet"
            component={() => (
              <BaseContainer>
                {role && role !== "demo_user" ? <Wallet /> : <DemoErrorInfo />}
              </BaseContainer>
            )}
          />
          <PrivateRoute
            exact
            path="/wallet-details"
            component={() => (
              <BaseContainer>
                {role && role !== "demo_user" ? (
                  <WalletDetails />
                ) : (
                  <DemoErrorInfo />
                )}
              </BaseContainer>
            )}
          />
          <PrivateRoute
            exact
            path="/auctionTable"
            component={() => (
              <BaseContainer>
                {role && role !== "demo_user" ? (
                  <AuctionTable />
                ) : (
                  <DemoErrorInfo />
                )}
              </BaseContainer>
            )}
          />
          <PrivateRoute
            exact
            path="/auction"
            component={() => (
              <BaseContainer>
                {role && role !== "demo_user" ? <Auction /> : <DemoErrorInfo />}
              </BaseContainer>
            )}
          />
          <PrivateRoute
            exact
            path="/content"
            component={() => (
              <BaseContainer>
                <Content />
              </BaseContainer>
            )}
          />
          <PrivateRoute
            exact
            path="/content/:id"
            component={() => (
              <BaseContainer>
                <EditVideo />
              </BaseContainer>
            )}
          />
          <PrivateRoute
            exact
            path="/auctions/:item"
            component={() => (
              <BaseContainer>
                {role && role !== "demo_user" ? (
                  <AuctionLink />
                ) : (
                  <DemoErrorInfo />
                )}
              </BaseContainer>
            )}
          />
          <PrivateRoute
            exact
            path="/auctionTable"
            component={() => (
              <BaseContainer>
                {role && role !== "demo_user" ? (
                  <AuctionTable />
                ) : (
                  <DemoErrorInfo />
                )}
              </BaseContainer>
            )}
          />
          <PrivateRoute
            exact
            path="/support"
            component={() => (
              <BaseContainer>
                <Support />
              </BaseContainer>
            )}
          />
          <PrivateRoute
            exact
            path="/companies"
            component={() => (
              <BaseContainer>
                <Companies />
              </BaseContainer>
            )}
          />
          <PrivateRoute
            exact
            path="/info"
            component={() => (
              <BaseContainer>
                <Information />
              </BaseContainer>
            )}
          />
          <PrivateRoute
            exact
            path="/profile/:author"
            component={() => (
              <BaseContainer>
                <AuthorProfile />
              </BaseContainer>
            )}
          />
          {/* <PrivateRoute
          exact
          path={["/dashboard","/"]}
          component={() => (
            <BaseContainer>
              <HomeContainer />
            </BaseContainer>
          )}
        /> */}
        </ApplicationContainer>

        <Redirect exact to="/auth" />
      </Switch>
    </>
  );
};

export default AppRouter;
