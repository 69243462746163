import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "MontBlanc",
    position: "relative",
    padding: "60px 40px 20px",
    overflow:"hidden"
  },
  section: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  topRightSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: "15px",
  },
  title: {
    fontFamily: "MontBlanc",
    color: "#1e4e79",
    fontWeight: "bold",
    fontSize: "20px",
    margin: 0,
  },
  rightTitle: {
    fontFamily: "MontBlanc",
    color: "#2e75b5",
    fontWeight: "bold",
    fontSize: "36px",
  },
  secondText: {
    fontWeight: 500,
    fontFamily: "MontBlanc",
    fontSize: "9px",
    width: "200px",
    textAlign: "right",
  },
  smallText: {
    fontWeight: 500,
    maxWidth: "200px",
    paddingLeft: "5px",
    fontFamily: "MontBlanc",
    fontSize: "10px",
  },
  tableHeader: {
    color: "white",
    fontFamily: "MontBlanc",
    fontWeight: 500,
    fontSize: "10px",
    display: "flex",
    width: "200px",
    flexWrap: "wrap",
    height: "20px",
    borderTopLeftRadius: "10px",
    backgroundColor: "#fe8b00",
    flexDirection: "row",
    alignItems: "center",
  },
  rightSmallTableHead: {
    textAlign: "center",
    color: "black",
    paddingTop: "5px",
    width: "50%",
    height: "20px",
  },
  billHead: {
    paddingTop: "5px",
    paddingLeft: "5px",
    backgroundColor: "#fe8b00",
    borderTopRightRadius: "10px",
    width: "200px",
    color: "black",
    fontFamily: "MontBlanc",
    fontWeight: 500,
    fontSize: "11px",
    height: "20px",
  },
  mainTableHead: {
    marginTop: "20px",
    backgroundColor: "#fe8b00",
    border: "1px solid black",
    color: "black",
    fontFamily: "MontBlanc",
    fontWeight: 500,
    fontSize: "11px",
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  mainTableHeadCell: {
    textAlign: "center",
    height: "20px",
    padding: "2px 2px",
  },
  row: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  dataCell: {
    paddingTop: "8px",
    fontSize: "9px",
    fontWeight: 500,
    fontFamily: "MontBlanc",
    borderBottom: "1px solid black",
    borderLeft: "1px solid black",
    paddingRight: "3px",
    color: "black",
    height: "42px",
  },
  lastCell: {
    flexGrow: 1,
    borderRight: "1px solid black",
  },
  leftFooterSection: {
    width: "270px",
  },
  thanks: {
    height: "20px",
    width: "100%",
    paddingTop: "3px",
    textAlign: "center",
    backgroundColor: "#fe8b00",
    color: "white",
    fontSize: "12px",
    fontWeight: "bold",
    fontFamily: "MontBlanc",
  },
  rightFooterSection: {
    border: "1px solid black",
    borderTop: "unset",
    display: "flex",
    flexDirection: "row",
  },
  totalType: {
    padding: "4px 6px",
    width: "100%",
    color: "black",
    fontSize: "9px",
    fontWeight: 500,
    fontFamily: "MontBlanc",
  },
  total: {
    padding: "0 6px",
    color: "black",
    fontSize: "11px",
    fontWeight: "bold",
  },
  totalValue: {
    paddingRight: "4px",
    fontWeight: 500,
    textAlign: "right",
  },
  questions: {
    marginTop: "30px",
    fontFamily: "MontBlanc",
    textAlign: "right",
    width: "100%",
    fontSize: "13px",
  },
  description: {
    display: "flex",
    fontWeight: "bold",
    flexDirection: "column",
    fontSize: "10px",
    height: "99px",
    color: "black",
    fontFamily: "MontBlanc",
    borderLeft: "1px solid black",
    borderBottom: "1px solid black",
  },
  logo: {
    width: "180px",
  },

  contactItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  contactImgContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "25px",
    marginLeft: "10px",
    height: "25px",
    backgroundColor: "#fe8b00",
    borderRadius: "20%",
  },
  contactImg: {
    width: "15px",
  },
  correct: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "MontBlanc",
    fontWeight: "bold",
    paddingLeft: "8px",
    width: "100%",
    fontSize: "9px",
  },
  footerImg: {
    position: "absolute",
    width: "120%",
    bottom: 0,
    left: 0,
  },
  footerText: {
    marginTop: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    fontFamily: "MontBlanc",
    fontSize: "9px",
  },
  bankInfo: {
    marginTop: "20px",
    paddingLeft: "10px",
    display: "flex",
    flexGrow: 1,
    fontWeight: "500",
    flexDirection: "column",
    fontFamily: "MontBlanc",
    fontSize: "9px",
  },
});
