import React, { memo, useCallback, useState } from "react";

import { Grid, IconButton, InputAdornment, Tooltip } from "@mui/material";
import s from "@components/Forms/index.module.scss";
import { Typography } from "../../../views/blocks";
import { useTranslation } from "react-i18next";
import Input from "@components/Forms/components/Input";
import DateSelect from "@components/Forms/components/DateGroup";
import { EyeLineIcon, EyeOffLineIcon } from "../../../views/icons";
import CountrySelect from "@components/Forms/components/CountrySelect";
import AuthCheckboxDocuments from "@components/Forms/components/CheckboxDocuments";
import SignupSchema from "@helpers/Formik/validation";
import { useFormik } from "formik";
import { AuthButton } from "@components/Forms/components/ui";
import Loader from "@components/Loader";
import { signUpAPI } from "@services/api/auth";
import { toast } from "react-toastify";

import { generate } from "generate-password";

import LockResetIcon from "@mui/icons-material/LockReset";


const SignUp = ({ authData, setStage, STAGES, setAuthData }) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() =>
    setShowPassword(show => !show),
  );

  const handleMouseDown = useCallback(event => event.preventDefault());

  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    touched,
    setFieldTouched,
    handleSubmit,
    isValid,
    isSubmitting,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    initialValues: authData,
    validationSchema: SignupSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const response = await signUpAPI(values);

        if (response.status === 201) {
          setStage(STAGES.AUTH);
          toast.warning(t("Check your email"));
        }

        setAuthData(values);
      } catch (error) {
        toast.error(
          t(error?.response?.data?.error || error?.response?.message || error),
        );

        if (
          error?.response?.data?.error === "user with this email already exist"
        ) {
          setStage(STAGES.SIGN_IN);
          setAuthData(prev => ({ ...prev, ...values }));
        }
      }

      setSubmitting(false);
    },
  });

  const handleChangeDateOfBirth = useCallback(date => {
    setFieldValue("date_of_birth", date);
  }, []);

  const handleGeneratePassword = useCallback(() => {
    const password = generate({
      length: 10,
      numbers: true,
      symbols: "!",
      uppercase: true,
      strict: true,
    });

    setShowPassword(true);
    setFieldValue("password", password);
  }, []);

  return (
    <Grid component="form" className={s.form}>
      <Grid item>
        <Typography variant="h2" bold>
          {t("Join to INFOCOIN!")}
        </Typography>
      </Grid>
      <Grid item mt={6}>
        <Input
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.email}
          error={t(errors.email)}
          type="email"
          label={t("Email")}
        />
      </Grid>
      <Grid item mt={2}>
        <Input
          name="name"
          type="text"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.name}
          error={t(errors.name)}
          label={t("Full name")}
        />
      </Grid>
      <Grid item mt={2}>
        <DateSelect
          value={values.date_of_birth}
          name="date_of_birth"
          label={t("Date of Birthday")}
          onBlur={() => setFieldTouched("date_of_birth", true)}
          onChange={handleChangeDateOfBirth}
          error={t(errors.date_of_birth)}
          touched={touched.date_of_birth}
        />
      </Grid>
      <Grid item mt={2}>
        <Input
          name="password"
          type={showPassword ? "text" : "password"}
          label={t("Password")}
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          endAdornment={
            <InputAdornment position="end">
              <Tooltip title={t("Generate password")}>
                <IconButton
                  onClick={handleGeneratePassword}
                  onMouseDown={handleMouseDown}
                >
                  <LockResetIcon />
                </IconButton>
              </Tooltip>
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDown}
                edge="end"
              >
                {showPassword ? (
                  <EyeLineIcon size="medsmall" />
                ) : (
                  <EyeOffLineIcon size="medsmall" />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <Typography
          variant="caption2"
          color={touched.password && errors.password ? "error" : "gray"}
          className="mt-1"
        >
          {t(
            "The password must consist of: at least 8 characters, a lowercase character, an uppercase character, a number and a special character (!,@,#,$,% etc.)",
          )}
        </Typography>
      </Grid>
      <Grid item mt={2}>
        <Input
          name="confirmPassword"
          type={showPassword ? "text" : "password"}
          label={t("Confirm password")}
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.confirmPassword}
          error={t(errors.confirmPassword)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDown}
                edge="end"
              >
                {showPassword ? (
                  <EyeLineIcon size="medsmall" />
                ) : (
                  <EyeOffLineIcon size="medsmall" />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item mt={2}>
        <CountrySelect
          label={t("Country")}
          value={values.country}
          onChange={setFieldValue}
          name="country"
        />
      </Grid>
      <AuthCheckboxDocuments
        onChange={setFieldValue}
        values={{
          acceptAgreement: values.acceptAgreement,
          acceptPrivacy: values.acceptPrivacy,
        }}
        errors={{
          acceptAgreement: errors.acceptAgreement,
          acceptPrivacy: errors.acceptPrivacy,
        }}
      />
      <Grid item mt={3}>
        <AuthButton
          fullWidth
          type="submit"
          size="large"
          onClick={handleSubmit}
          disabled={!isValid || isSubmitting}
        >
          {isSubmitting ? (
            <Loader color="white" width={31.5} height={31.5} />
          ) : (
            t("Register account")
          )}
        </AuthButton>
      </Grid>
      <Grid item mt={2}>
        <Typography variant="body2" align="center">
          {t("Already have an account?")}
          <Typography
            variant="body2"
            weight="normal-bold"
            color="primary"
            className="pointer hover-opacity"
            component="span"
            onClick={() => setStage(STAGES.SIGN_IN)}
          >
            &nbsp;
            {t("Sign In")}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default memo(SignUp);
