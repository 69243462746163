import { Steps, useSteps } from "react-step-builder";
import { Button } from "react-bootstrap";
import Payment from "@components/CreateLotForm/steps/Payment/Payment.js";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classes from "@pages/Auction/Auction.module.scss";
import { Link, useHistory } from "react-router-dom";
import ContentWithModal from "@pages/Content/components/ContentWithModal";
import { useTranslation } from "react-i18next";
import LoadVideoFromTemplateStep from "@components/CreateLotForm/steps/LoadVideoFromTemplateStep";
import { useFormik } from "formik";
import { setSavedLotAction } from "@redux/auction/actions";

const CreateLotModal = ({ country, hideEditModal }) => {
  const { next, current, jump } = useSteps();

  const history = useHistory();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const lotToEdit = useSelector(state => state.auction.lotToEdit, shallowEqual);
  const savedLot = useSelector(state => state.auction.savedLot, shallowEqual);
  const isEmergency = useSelector(
    state => state.emergency.isEmergency,
    shallowEqual,
  );
  const myContent = useSelector(
    state => state.emergency.myContent,
    shallowEqual,
  );
  const isMyContentChosen = useSelector(
    state => state.emergency.isMyVideoChosen,
    shallowEqual,
  );
  const minAmount = useSelector(state => state.auction.minAmount, shallowEqual);

  const validate = useCallback(
    values => {
      const errors = {};

      if (!values.amount) {
        errors.amount = "Field is required";
      } else if (parseFloat(values.amount) < parseFloat(minAmount)) {
        errors.amount = "Amount must be greater than minimal amount";
      }
      return errors;
    },
    [minAmount],
  );

  const initialValues = useMemo(() => {
    if (lotToEdit) {
      return {
        budget: parseFloat(lotToEdit.budget),
        amount: parseFloat(lotToEdit.amount),
        selectedVideo: {},
      };
    } else
      return {
        budget: 0,
        amount: parseFloat(minAmount),
        selectedVideo: {},
        step: 1,
        ...savedLot,
      };
  }, [lotToEdit, minAmount, savedLot]);

  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormik(
    {
      validate,
      initialValues,
    },
  );

  useEffect(() => {
    if (values.step && !lotToEdit) {
      jump(values.step);
    }
  }, [jump, lotToEdit, values.step]);

  useEffect(() => {
    !lotToEdit &&
      values.selectedVideo?.id &&
      dispatch(setSavedLotAction({ ...values, step: current }));
  }, [current, lotToEdit, values, dispatch]);

  const submitAndGoToNext = useCallback(() => {
    if (isMyContentChosen) {
      !lotToEdit && setFieldValue("step", 3);
      jump(3);
    } else {
      next();
      !lotToEdit && setFieldValue("step", 2);
    }
  }, [isMyContentChosen, jump, lotToEdit, next, setFieldValue]);

  const onAddVideo = useCallback(() => {
    history.push("/content");
  }, []);

  const onChangeSelectedVideos = useCallback(
    video => {
      setFieldValue("selectedVideo", video);

      if (lotToEdit) {
        submitAndGoToNext();
      }
    },
    [lotToEdit, setFieldValue, submitAndGoToNext],
  );

  return (
    <>
      <Steps>
        <ContentWithModal
          onChangeSelectedVideos={onChangeSelectedVideos}
          onAddVideo={onAddVideo}
          auctionCountry={country}
        />
        <LoadVideoFromTemplateStep
          closeModal={hideEditModal}
          setSelectedVideo={onChangeSelectedVideos}
          setFieldValue={setFieldValue}
          selectedVideo={values.selectedVideo}
        />
        <Payment
          country={country}
          values={values}
          setFieldValue={setFieldValue}
          selectedVideo={values.selectedVideo}
          hideEditModal={hideEditModal}
          errors={errors}
          touched={touched}
          setFieldTouched={setFieldTouched}
        />
      </Steps>
      <div
        style={{ marginTop: "10px" }}
        className="d-flex justify-content-center"
      >
        {isEmergency && !myContent.length && isMyContentChosen ? (
          <Button
            type="button"
            color="primary"
            variant="contained"
            className={classes.modalButton}
          >
            <Link to="/content">{t("Load Content")}</Link>
          </Button>
        ) : (
          current < 2 && (
            <Button
              type="button"
              color="primary"
              variant="contained"
              className={classes.modalButton}
              onClick={() => {
                submitAndGoToNext();
              }}
              disabled={!values.selectedVideo?.id}
            >
              {t("Next")}
            </Button>
          )
        )}
      </div>
    </>
  );
};

export default memo(CreateLotModal);
