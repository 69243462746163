import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import "@styles/components/HomeContainer.scss";
import "@styles/components/VideoSwiper.scss";
import arrowRight from "@assets/icons/arrow-right.svg";
import { ReactComponent as Share } from "@assets/icons/share.svg";
import { ReactComponent as Eye } from "@assets/icons/eye.svg";
// import { ReactComponent as Like } from "@assets/icons/heart.svg";

import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { stories } from "./stories";
import "./gt.js";
// import Geetest from 'react-geetest';
// import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import LinkBrowsingHistoryItem from "@components/History/LinkBrowsingHistoryItem";
import UnacceptableСontentModal from "./UnacceptableСontentModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import heart from "@assets/icons/heart-white.svg";
import share from "@assets/icons/share.svg";
import eye from "@assets/icons/eye.svg";

import ReCAPTCHA from "react-google-recaptcha";
import AuctionLink from "@components/AuctionLink/AuctionLink";

const VideoSwiper = ({ props }) => {
  const recaptchaRef = React.createRef();

  const swiperRef = useRef();
  const videoRef = useRef();
  const storiesData = stories;

  const [modalShow, setModalShow] = useState(false);
  const [modalCaptcha, setModalCaptcha] = useState(false);
  const [targetVideo, setTargetVideo] = useState("");

  const handleCaptcha = () => {
    setModalCaptcha(false);
  };

  const handlerToggleModal = (evt) => {
    evt.preventDefault();

    if (evt.target.id === evt.currentTarget.id) {
      setTargetVideo(evt?.target?.id);
      setModalShow(!modalShow);
    }
  };

  useEffect(() => {
    if (!modalCaptcha && videoRef.current) {
      videoRef.current.videoEl.play();
    }
  }, [modalCaptcha]);

  useEffect(() => {
    setTimeout(() => {
      videoRef.current?.videoEl.pause();
      setModalCaptcha(true);
    }, 1000 * Math.floor(Math.random() * (4 - 3 + 1) + 8)); // A random number between 3 and 4
  }, []);

  const [liked, setLiked] = useState(false);

  const toggleLike = () => {
    setLiked(!liked);
  };

  return (
    <>
      <Swiper
        spaceBetween={50}
        loop={false}
        slidesPerView={1}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        allowSlideNext={false}
        allowSlidePrev={false}
      // noSwiping={true}
      >
        {props === undefined &&
          storiesData?.map((i) => (
            <SwiperSlide key={i.id}>
              {({ isActive }) => (
                <div className="flex-column-align mob-padding">
                  <Row>
                    <Col
                      lg={10}
                      md={12}
                      sm={12}
                      xs={12}
                      className="flex-row-center-align info"
                    >
                      <div className="flex-row-center-align">
                        <Link
                          to={{
                            pathname: `${"/profile"}/${i.author.id}`,
                            state: i?.author,
                          }}
                        >
                          <img
                            src={i.author.avatar}
                            alt="profile avatar"
                            className="profile-icon"
                          />
                        </Link>
                        <div className="text-left">
                          <h2 className="title">
                            <Link
                              to={{
                                pathname: `${"/profile"}/${i.author.id}`,
                                state: i?.author,
                              }}
                            >
                              {i.author.name}
                            </Link>{" "}
                          </h2>
                          {/* <ul className="hashtag">
                            {i.author.tags.map((tag) => (
                              <li key={tag} className="hashtag-item">
                                {tag}
                              </li>
                            ))}
                          </ul> */}
                          <p className="hashtag">{i.author.description}</p>
                        </div>
                      </div>
                      {/* <img
                        className="icon-small ms-2:"
                        src={arrowRight}
                        alt=""
                      /> */}
                    </Col>
                  </Row>
                  {isActive ? (
                    <Video
                      // style={{marginLeft: "-3px"}}
                      ref={videoRef}
                      playsInline
                      autoPlay={true}
                      muted={false}
                      loop={false}
                      controls={["PlayPause", "Volume"]}
                      // poster="https:eba.com.ua/wp-content/uploads/2017/11/rbsport1_mar08_prev-1.jpg"
                      onPlay={() => { }}
                      onEnded={() => {
                        swiperRef.current.allowSlideNext = true;
                        swiperRef.current.slideNext();
                        swiperRef.current.allowSlideNext = false;
                        setTimeout(() => {
                          videoRef.current.videoEl.pause();
                          setModalCaptcha(true);
                        }, 1000 * Math.floor(Math.random() * 3 + 1));
                      }}
                    >
                      <source defer src={i.url} type="video/MP4" className="video" />
                      <Modal show={modalCaptcha}>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                          <h6 className="text-center">Подтвердите что вы здесь</h6>
                          <form
                            onSubmit={() => {
                              recaptchaRef.current.execute();
                            }}
                          >
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              // size="invisible"
                              // sitekey="6LckU0MdAAAAADWY8V4yEJlDd-ibaCxEw9g7LbtI"
                              sitekey="6Le-YIAdAAAAAIzDkgv1NzPW_41ldPH6TR9Ho0En"
                              onChange={handleCaptcha}
                            />
                          </form>
                        </Modal.Body>
                        {/* <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={handleCloseModal}
                          >
                            Close
                          </Button>
                          <Button variant="primary" onClick={handleConfirm}>
                            Confirm
                          </Button>
                        </Modal.Footer> */}
                      </Modal>
                      {/* <track label="English" kind="subtitles" srcLang="en" src="http:source.vtt" default /> */}
                    </Video>
                  ) : (
                    <Video
                      playsInline
                      autoPlay={false}
                      muted={true}
                      loop={false}
                      controls={["PlayPause", "Volume"]}
                      onProgress={async (data) => {
                        // if (
                        //   captchaConfirmed ||
                        //   data.playedSeconds < randomSecond
                        // ) {
                        //   return;
                        // }
                        // setPlaying(false);
                      }}
                    ></Video>
                  )}

                  <Row className="flex-row-center-align mt-2">
                    <Col
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      className="flex-start align-items-center text-left d-flex"
                    >
                      {localStorage.getItem("data-theme") === "light" ? (
                        <FontAwesomeIcon
                          icon={farHeart}
                          onClick={toggleLike}
                          className="me-4 ms-2 "
                          style={{
                            height: "35px",
                            width: "30px",
                            color: "#000",
                          }}
                        />
                      ) : (
                        <img
                          src={heart}
                          alt="heart-white"
                          style={{ height: "35px", width: "30px" }}
                        />
                      )}
                      {/* <FontAwesomeIcon icon={farHeart} onClick={toggleLike} className='me-4 ms-2 ' style={{height: '35px', width: '30px', color: changeColor}}/> */}

                      {/* <i className="far fa-heart"></i> */}
                      {localStorage.getItem("data-theme") === "light" ? (
                        <Share className="icon-small icon-hover" />
                      ) : (
                        <img
                          src={share}
                          alt="share-white"
                          style={{ height: "35px", width: "30px" }}
                        />
                      )}
                      {/* <Share className="icon-small icon-hover"/>
                      <img src={share} alt='share-white' style={{height: '35px', width: '30px'}}/> */}
                      {localStorage.getItem("data-theme") === "light" ? (
                        <Eye
                          className="icon-small icon-hover ms-4"
                          id={i.id}
                          // prop={}
                          onClick={handlerToggleModal}
                          style={{ width: "35px" }}
                        />
                      ) : (
                        <img
                          src={eye}
                          alt="eye-white"
                          style={{ height: "35px", width: "30px" }}
                        />
                      )}
                      {/* <Eye
                        className="icon-small icon-hover ms-4"
                        id={i.id}
                        // prop={}
                        onClick={handlerToggleModal}
                        style={{width: '35px'}}
                      /> */}
                      {/* <img src={eye} alt='eye-white' style={{height: '35px', width: '30px'}}/> */}

                      {/* <Like className="icon-small icon-hover ms-4" /> */}
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      {/* <img src={speaker} alt="" />  */}
                    </Col>
                  </Row>
                </div>
              )}
            </SwiperSlide>
          ))}

        {props !== undefined && (
          <LinkBrowsingHistoryItem props={props} arrowRight={arrowRight} />
        )}
        {props !== undefined && <AuctionLink props={props} arrowRight={arrowRight} />}
      </Swiper>
      {modalShow && (
        <UnacceptableСontentModal
          handlerToggleModal={handlerToggleModal}
          key={targetVideo}
          videoId={targetVideo}
        />
      )}
    </>
  );
};

export default VideoSwiper;
