import React, { memo, useEffect } from "react";

import { Grid } from "@mui/material";
import ReactCodeInput from "react-code-input";

import "./index.scss";

import { useTranslation } from "react-i18next";
import clsx from "clsx";

const CodeInput = ({ onChange, value, label, status = "" }) => {
  const { t } = useTranslation();

  const exceptThisSymbols = ["+", ",", "-"];

  const modification = clsx("code-input__input", {
    "code-input__input--four": value.length === 4,
    "code-input__input--error": status === "error",
    "code-input__input--success": status === "success",
  });
  useEffect(() => {
    const listener = event => {
      if (exceptThisSymbols.some(symbol => symbol === event.key)) {
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <Grid className="code-input">
      {label && <label className="code-input__label">{t(label)}</label>}
      <ReactCodeInput
        disabled={status === "disabled"}
        type="number"
        fields={4}
        inputMode="numeric"
        className={modification}
        onChange={onChange}
        value={value}
      />
    </Grid>
  );
};

export default memo(CodeInput);
