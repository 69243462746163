import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
import LastUsed from "locize-lastused";
import { locizePlugin } from "locize";
import { IS_PRODUCTION, LOCIZE_API_KEY, LOCIZE_PROJECT_ID } from "../config";
import languages from "src/data/languages.json";

const locizeOptions = {
  projectId: LOCIZE_PROJECT_ID,
  apiKey: !IS_PRODUCTION ? LOCIZE_API_KEY : undefined, // The API key should only be used in development, not in production. You should not expose your apps API key to production!!!
  referenceLng: "en",
};

i18n
  // i18next-locize-backend
  // loads translations from your project, saves new keys to it (saveMissing: true)
  // https://github.com/locize/i18next-locize-backend
  .use(Backend)
  // locize-lastused
  // sets a timestamp of last access on every translation segment on locize
  // -> safely remove the ones not being touched for weeks/months
  // https://github.com/locize/locize-lastused
  .use(LastUsed)
  // locize-editor
  // InContext Editor of locize
  .use(locizePlugin)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: !IS_PRODUCTION,
    fallbackLng: "en",
    supportedLngs: languages.map(country => country.iso_2),
    saveMissing: !IS_PRODUCTION && true, // you should not use saveMissing in production
    // keySeparator: false,
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
  });

export default i18n;
