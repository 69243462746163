import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Menu as MenuIcon } from "@mui/icons-material";

import fullLogo from "@assets/icons/admin-logo-full.svg";
import lessLogo from "src/assets/icons/admin-logo-less.svg";

import LeftSidebar from "src/components/LeftSidebar";

import "src/styles/components/HeaderContainer.scss";
import classes from "./MobileHeader.module.scss";


const MobileHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSidebarToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const onCloseMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div className={classes.container}>
      <MenuIcon
        className={classes.burger}
        onClick={handleSidebarToggle}
        height={40}
        width={40}
      />

      <Link className="d-flex" to="/auction">
        <Navbar.Brand
          style={{
            marginRight: 0
          }}>
          <img
            style={{
              width: "100%",
              height: "40px",
              justifySelf: "center"
            }}
            className={classes.logo}
            alt=""
            src={fullLogo} />
        </Navbar.Brand>
      </Link>

      <div className={clsx(classes.menu, { [classes.openMenu]: isOpen })}>
        <LeftSidebar onCloseMenu={onCloseMenu} mobile />
      </div>
    </div>
  );
};

export default MobileHeader;
