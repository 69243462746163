import {
  GET_CITIES,
  GET_CITIES_ERROR,
  GET_CITIES_SUCCESS,
  GET_COUNTRIES,
  GET_COUNTRIES_ERROR,
  GET_COUNTRIES_SUCCESS,
  GET_REGIONS,
  GET_REGIONS_ERROR,
  GET_REGIONS_SUCCESS,
} from "@redux/language/constants";
import { AUTH_LOGOUT } from "@redux/auth/constants";

const initialState = {
  countries: [],
  loading: false,
  error: null,
  regions: [],
  cities: [],
};

export default function languageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_COUNTRIES: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: action.payload,
        loading: false,
      };
    }
    case GET_COUNTRIES_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    case GET_CITIES: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CITIES_SUCCESS: {
      return {
        ...state,
        cities: action.payload,
        loading: false,
      };
    }
    case GET_CITIES_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    case GET_REGIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_REGIONS_SUCCESS: {
      return {
        ...state,
        regions: action.payload,
        loading: false,
      };
    }
    case GET_REGIONS_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    case AUTH_LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
}
