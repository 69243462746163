import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-accessible-accordion/dist/fancy-example.css";
import TextField from "src/components/Form/TextField";
import "src/styles/components/Content.scss";
import {
  addLot,
  getAuctionAudience,
  getAuctionLots,
} from "src/services/api/auctions";
import styles from "./Payment.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { clearSavedLotAction, loadLotsAction } from "src/redux/auction/actions";
import { toast } from "react-toastify";

import clsx from "clsx";
import { DefaultPlayer as Video } from "react-html5video";
import { useWallet } from "src/hooks";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "src/components/Button";
import Logo from "src/assets/icons/logo.png";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useSteps } from "react-step-builder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { countriesSelector } from "@redux/language/selectors";
import { Grid, Tooltip } from "@mui/material";
import { KeyboardBackspace } from "@mui/icons-material";
import { Typography } from "../../../../views/blocks";

const Payment = ({
  selectedVideo,
  country,
  values,
  setFieldValue,
  hideEditModal,
  errors,
  setFieldTouched,
  touched,
}) => {
  const dispatch = useDispatch();

  const [calculatingBudget, setCalculatingBudget] = useState(true);
  const activeAuctionId = useSelector(state => state.auction.activeAuctionId);

  const lotToEdit = useSelector(state => state.auction.lotToEdit);

  const countries = useSelector(countriesSelector);

  const isEmergency = useSelector(state => state.emergency.isEmergency);

  const [isEnoughMoney, setIsEnoughMoney] = useState(false);

  const [notEnough, setNotEnough] = useState(0);

  const [views, setViews] = useState(0);

  const minAmount = useSelector(state => state.auction.minAmount);

  const [submitted, setSubmit] = useState(false);

  const { t } = useTranslation();

  const { companyBalance } = useWallet();

  const balance = useMemo(() => {
    if (companyBalance) {
      const company = companyBalance
        .filter(c => c.country.name === country)
        ?.find(c => c.company.title === selectedVideo.company.title);
      return company ? parseFloat(company.available_balance) : 0;
    }
    return 0;
  }, [companyBalance, country, selectedVideo.company.title]);

  const getAudience = useCallback(async () => {
    try {
      const res = await getAuctionAudience({
        country: countries.find(c => c.name === country).id,
        is_all_country: true,
        cities_ids: selectedVideo.cities.map(c => c.id),
        regions_ids: selectedVideo.regions.map(c => c.id),
        categories_ids: selectedVideo.categories.map(c => c.id),
        genders: selectedVideo.genders.map(gender => gender.title),
        age_min: selectedVideo.audience.age_min,
        age_max: selectedVideo.audience.age_max,
      });

      if (res.data.audience) {
        setViews(res.data.audience);
        return res.data.audience;
      } else {
        setViews(0);
        return 0;
      }
    } catch (e) {
      toast.error(t("Error pf get audiance"));
      return 0;
    }

    setCalculatingBudget(false);

    //eslint-disable-next-line
  }, [countries, country, selectedVideo]);

  const { jump } = useSteps();

  const createNewLot = useCallback(async () => {
    if (balance === 0 || Number(values.budget) > parseFloat(balance)) {
      setIsEnoughMoney(true);
      setNotEnough(Number(values.budget) - parseFloat(balance));
      return;
    }
    await addLot({
      auction_id: Number(activeAuctionId),
      video_id: Number(selectedVideo.id),
      amount: String(values.amount),
      budget: String(values.budget),
    })
      .then(async () => {
        dispatch(clearSavedLotAction());
        toast.success(t("You are successfully added lot"));
        const response = await getAuctionLots(activeAuctionId);
        dispatch(loadLotsAction(response.data));
        setSubmit(true);
      })
      .catch(error => {
        toast.error(error.response.data.error);
      });
  }, [
    balance,
    values.budget,
    values.amount,
    selectedVideo.id,
    dispatch,
    t,
    activeAuctionId,
  ]);

  const getBudget = useCallback(
    (sum, viewsProps) => {
      const amount = Number(sum);

      if (viewsProps === 0 || amount === 0) {
        return setFieldValue("budget", 0);
      }

      const result =
        selectedVideo?.duration * Number(viewsProps) * Number(amount);

      setFieldValue("budget", parseFloat(Number(result).toFixed(6)));
    },
    [views, selectedVideo?.duration],
  );

  const handleInputChange = event => {
    const input = event.target.value;
    let newAmount = input.replace(/[^0-9.]/g, ".");
    if (newAmount.split("").filter(s => s === ".").length >= 2) {
      newAmount = newAmount.substring(0, newAmount.length - 1);
    }
    if (
      newAmount[newAmount.length - 1] !== "." &&
      newAmount[newAmount.length - 1] !== "0"
    ) {
      newAmount = +Number(newAmount).toFixed(6);
    }
    if (String(newAmount).length >= String(newAmount).indexOf(".") + 7) {
      const result = Number(String(newAmount).replace(/0*$/, ""));
      getBudget(result, views);
      setFieldValue("amount", result);
    } else {
      getBudget(newAmount, views);
      setFieldValue("amount", newAmount);
    }
  };

  const onBack = useCallback(() => {
    if (isEmergency) {
      setFieldValue("step", 2);
      jump(2);
    } else {
      setFieldValue("step", 1);
      jump(1);
    }
  }, [isEmergency, jump, setFieldValue]);

  const againCreateLot = useCallback(() => {
    setFieldValue("step", 1);
    jump(1);
    setSubmit(false);
  }, [setFieldValue, jump]);

  useEffect(() => {
    const initial = async () => {
      if (selectedVideo.id) {
        const result = await getAudience();

        setFieldValue("amount", minAmount);

        getBudget(minAmount, result);
      }
      setCalculatingBudget(false);
    };

    initial();
    //eslint-disable-next-line
  }, []);

  /* const updateCurrentLot = useCallback(async () => {
                                            try {
                                              if (values.amount < parseFloat(lotToEdit?.amount)) {
                                                toast.error("You cannot downsize");
                                                return;
                                              }
                                              if (Number(values.budget) > parseFloat(balance)) {
                                                setIsPayment(true);
                                                setNotEnough(Number(values.budget) - parseFloat(balance));
                                                return;
                                              }
                                
                                              await editLot(lotToEdit.id, {
                                                amount: String(values.amount),
                                                budget: String(values.budget),
                                              });
                                
                                              toast.success(t("You are successfully edited lot"));
                                              setSubmit(true);
                                              hideEditModal();
                                              const response = await getAuctionLots(activeAuctionId);
                                              dispatch(clearSavedLotAction());
                                              dispatch(loadLotsAction(response.data));
                                            } catch (e) {
                                              toast.error(e.response.data.error);
                                            }
                                          }, [values, lotToEdit, balance, t, hideEditModal, activeAuctionId, dispatch]);*/

  return isEnoughMoney ? (
    <div className={styles.notMoneyContainer}>
      <div className={styles.logo}>
        <img src={Logo} alt="INFOCOIN" />
      </div>
      <div className={styles.text}>
        {t("Your company does not have funds for the auction in {{country}}.", {
          country: country,
        })}
      </div>
      <div className={styles.secondText}>
        {t("Your available company balance")} {` : ${balance} IC`}
      </div>
      <div className={styles.secondText}>
        {notEnough ? (
          <>
            {" "}
            {t("Replenish the balance by")}{" "}
            {` ${Math.round(parseFloat(notEnough) * 100) / 100} IC`}
          </>
        ) : (
          t("Replenish the balance")
        )}
      </div>
      <CancelRoundedIcon className={styles.cancel} />
      <Link to={"/wallet"}>
        <Button>{t("Go to wallet")}</Button>
      </Link>
    </div>
  ) : (
    <>
      {submitted ? (
        <div className={styles.notMoneyContainer}>
          <div className={styles.logo}>
            <img src={Logo} alt="INFOCOIN" />
          </div>
          <div className={styles.text}>{t("Lot successfully created")}</div>
          <CheckCircleIcon className={styles.done} />
          <div className="d-flex">
            <Button onClick={againCreateLot} containerClassName="mr-1">
              {t("Add Lot")}
            </Button>
            <Button
              variant="gray"
              onClick={hideEditModal}
              containerClassName="ml-1"
            >
              {t("Close")}
            </Button>
          </div>
        </div>
      ) : (
        <>
          {!lotToEdit && (
            <Grid className={styles.back} onClick={onBack}>
              <KeyboardBackspace fontSize="small" />
              <Typography className="ml-1" color="primary" weight="normal-bold">
                {t("Back")}
              </Typography>
            </Grid>
          )}

          <div className={clsx(styles.containerVideoModal, "scrollingY")}>
            <div className={styles.topContainer}>
              <h2 className={styles.videoTitle}>
                {lotToEdit ? lotToEdit.video.title : selectedVideo?.title}
              </h2>
              <p className={styles.videoDescription}>
                {lotToEdit
                  ? lotToEdit.video.description
                  : selectedVideo?.description}
              </p>
              <div className={styles.videoContent}>
                <div className={styles.videoContent__containerVideo}>
                  {(selectedVideo?.filepath_480_p ||
                    lotToEdit?.video.filepath_480_p) && (
                    <Video
                      loop
                      width="100%"
                      height="100%"
                      muted
                      className={styles.videoContent__containerVideo_video}
                      controls={["PlayPause", "Seek", "Time", "Fullscreen"]}
                    >
                      <source
                        src={
                          selectedVideo?.filepath_480_p ||
                          lotToEdit?.video.filepath_480_p
                        }
                        type="video/webm"
                      />
                      <track
                        label="English"
                        kind="subtitles"
                        srcLang="en"
                        src="http://source.vtt"
                        default
                      />
                    </Video>
                  )}
                </div>
                <div className={styles.videoContent__containerBlock}>
                  <div className={styles.videoContent_block}>
                    <span>{minAmount}</span>
                    <p>
                      {t("Min.amount per second viewed by country")} - {country}
                    </p>
                  </div>
                  <Tooltip
                    open={views === 0}
                    placement="top"
                    title={t(
                      "There will be no views on your lot. Try changing your video and you will get more views!",
                    )}
                    arrow
                  >
                    <div
                      className={clsx(styles.videoContent_block, {
                        [styles.error_block]: views === 0,
                      })}
                    >
                      <span>{Number(views)}</span>
                      <p>{t("Number of views in this lot")}</p>
                    </div>
                  </Tooltip>
                  <div className={styles.videoContent_block}>
                    <span>{values?.budget}</span>
                    <p>{t("Budget in IC")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.buttonsContainer}>
              <div className={styles.buttonsContainer__footerText}>
                {t("Amount in IC for one second of unique viewing")}
                {":"}
              </div>
              <div className={styles.buttonsContainer__block}>
                <TextField
                  placeholder="IC"
                  variant="outlined"
                  type="budget"
                  name={"amount"}
                  value={values?.amount}
                  onChange={handleInputChange}
                  handleBlur={() => setFieldTouched("amount", true)}
                  errorStyle={{
                    position: "absolute",
                    bottom: "100%",
                    left: "2px",
                    width: "300px",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "2",
                    "-webkit-box-orient": "vertical",
                  }}
                  touched={touched.amount}
                  error={errors.amount}
                  id="amount-input-id"
                  className={styles.input}
                />
                <Button
                  containerClassName={clsx(
                    styles.buttonContainer,
                    styles.publish,
                  )}
                  className={styles.button}
                  disabled={errors.amount || calculatingBudget || views === 0}
                  loading={calculatingBudget}
                  onClick={() => !calculatingBudget && createNewLot()}
                >
                  {t("Add")}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Payment;
