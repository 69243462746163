const useThemeProps = props => {
  const theme = "light";

  return {
    theme,
    ...props,
  };
};

export { useThemeProps };
