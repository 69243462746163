import React, { forwardRef, useRef } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import "./index.scss";
import { useEffect } from "react";


const TextInput = forwardRef((props, ref) => {
    const {
        className = "",
        fullWidth = false,
        error = false,
        warningLabel = false,
        variant,
        label = "",
        labelProps = {},
        leftAddons,
        rightAddons,
        inputRef,
        helperText,
        ...rest
    } = props;

    const { t } = useTranslation();

    const modifications = clsx({
        "text-input--full-width": fullWidth,
        "text-input--error": error,
        [`text-input--variant-${variant}`]: !!variant
    });

    const wrapperRef = useRef()
    useEffect(() => {
        const {
            current
        } = wrapperRef

        if (current) {
            current.firstChild.nextSibling.onfocus = function () {
                current.style.boxShadow = 'rgba(255, 95, 21, 0.5) 0 0 0 0.2rem'
            }
            current.firstChild.nextSibling.onblur = function () {
                current.style.boxShadow = 'none'
            }
        }
    }, [wrapperRef])

    return (
        <div ref={ref} className={`text-input ${className} ${modifications}`}>
            {label && (
                <div
                    {...labelProps}
                    className={`text-input__label ${warningLabel ? 'text-input__label--warrning' : ''}`}>
                    {t(label)}
                </div>
            )}

            <div className="text-input__wrapper" ref={wrapperRef}>
                <div className="text-input__left-addons">
                    {leftAddons}
                </div>

                <input
                    type="text"
                    {...rest}
                    ref={inputRef}
                    className="text-input__input" />

                <div className="text-input__right-addons">
                    {rightAddons}
                </div>
            </div>

            {helperText && (
                <div className="text-input__helper-text">
                    {helperText}
                </div>
            )}
        </div>
    );
});


export { TextInput };