import React, { memo, useCallback } from "react";
import classes from "./MobileVideosView.module.scss";
import VideoCell from "@components/VideosTable/components/VideoCell";
import FlagCountry from "@components/FlagCountry";
import {
  mapStatuses,
  VIDEO_STATUS_NOT_VERIFIED,
  VIDEO_STATUS_REJECTED,
  VIDEO_STATUS_SENT_TO_VERIFICATION,
  VIDEO_STATUS_VERIFIED,
  VIDEO_STATUS_VERIFYING_POSTPONED,
} from "@redux/video";
import { withTranslation } from "react-i18next";
import DeleteModal from "@components/DeleteModal";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import NearMeIcon from "@mui/icons-material/NearMe";
import { useHistory } from "react-router-dom";
import {
  delDeleteVideo,
  DemoDeleteVideo,
  putDemoSentVerifedVideo,
  putSentVerifedVideo,
} from "@services/api/content";
import { toast } from "react-toastify";
import { getDemoMyVideosAction } from "@redux/emergency/actions";
import { getVideosAction } from "@redux/content/contentActions";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Checkbox from "@components/Checkbox";
const MobileVideosView = ({ videos, t, isModal, onSelect, selected }) => {
  const data = React.useMemo(() => videos, [videos]);
  const dispatch = useDispatch();

  const history = useHistory();
  const role = useSelector(state => state.user.user.role);

  const sendVerificationVideo = useCallback(
    data => {
      const requestData = {
        title: data.title,
        description: data.description,
        categories: data.categories,
        company_id: data.company.id,
        gender: data.gender,
        age_min: data.age_min,
        age_max: data.age_max,
        email: data.email,
        is_all_country: data.is_all_country,
        url: data.url,
        status: "sent_to_verification",
      };

      if (role === "demo_user") {
        putDemoSentVerifedVideo(data.id, requestData)
          .then(() => {
            toast.success(t("Video sent for review!"));
            dispatch(getDemoMyVideosAction());
          })
          .catch(error => {
            toast.error(t(error.message));
          });
      } else {
        putSentVerifedVideo(data.id, requestData)
          .then(() => {
            toast.success(t("Video sent for review!"));
            dispatch(getVideosAction());
          })
          .catch(error => {
            toast.error(t(error.message));
          });
      }
    },
    [dispatch, t, role],
  );

  const deleteVideo = useCallback(
    data => {
      if (role === "demo_user") {
        DemoDeleteVideo(data.id)
          .then(() => {
            toast.success(t("Video deleted successfully!"));
            dispatch(getDemoMyVideosAction());
          })
          .catch(error => {
            toast.error(t(error.message));
          });
      } else {
        delDeleteVideo(data.id)
          .then(() => {
            toast.success(t("Video deleted successfully!"));
            dispatch(getVideosAction());
          })
          .catch(error => {
            toast.error(t(error.message));
          });
      }
    },
    [dispatch, role, t],
  );
  return (
    <div className={classes.container}>
      {data.map(video => (
        <div className={classes.videoContainer}>
          <div className={classes.firstRow}>
            {isModal && (
              <Checkbox
                className={"auction-select-checkbox"}
                role="checkbox"
                value={selected === video.id}
                onChange={() => {
                  onSelect(video);
                }}
              />
            )}
            <div>{video.title}</div>
            <div>
              <span style={{ color: "grey" }}>ID:</span>
              <span style={{ fontWeight: "600" }}>{video.id}</span>
            </div>
          </div>
          <VideoCell isMobile value={video.filepath_480_p} />
          <div className={classes.description}>{video.description}</div>
          <div className={classes.country}>
            <FlagCountry className={classes.flag} code={"UA"} />
            <span>{t(video.country)}</span>
          </div>
          <div className={classes.propertyWithDesc}>
            <span style={{ fontWeight: 600 }}>{t("Company")}</span> :{" "}
            {video.company.title}
          </div>
          <div className={classes.propertyWithDesc}>
            <span style={{ fontWeight: 600 }}>{t("Genders")}</span> :{" "}
            {video.genders.map(g => t(g.title)).join(", ")}
          </div>
          <div
            className={clsx(classes.status, {
              [classes.statusVer]: video.status === VIDEO_STATUS_VERIFIED,
              [classes.statusNotVerify]:
                video.status === VIDEO_STATUS_NOT_VERIFIED ||
                video.status === VIDEO_STATUS_REJECTED,
              [classes.statusPostponed]:
                video.status === VIDEO_STATUS_VERIFYING_POSTPONED ||
                video.status === VIDEO_STATUS_SENT_TO_VERIFICATION,
            })}
          >
            {t(mapStatuses[video.status])}
          </div>
          <div className={classes.actions}>
            {!isModal && (
              <>
                <DeleteModal
                  onSuccessDelete={() => deleteVideo(video)}
                  trigger={
                    <DeleteForeverIcon
                      className={clsx(classes.action, "auction__action-del")}
                    />
                  }
                  question={t("Are you sure you want to delete the video?")}
                />
                <EditIcon
                  onClick={() => history.push(`/content/${video.id}`)}
                  className={classes.action}
                />
                {(video.status === VIDEO_STATUS_NOT_VERIFIED ||
                  video.status === VIDEO_STATUS_REJECTED) &&
                video.filepath_480_p ? (
                  <NearMeIcon
                    className={classes.action}
                    onClick={() => sendVerificationVideo(video)}
                  />
                ) : (
                  <NearMeIcon
                    className={clsx(classes.action, "auction__action-disabled")}
                  />
                )}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(withTranslation()(MobileVideosView));
