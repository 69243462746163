export const SET_LOADING = "SET_LOADING";
export const LOADING_END = "LOADING_END";

export const SET_BALANCE = "SET_BALANCE";

export const SET_COMMON_BALANCE = "SET_COMMON_BALANCE";

export const SET_COMPANIES_WALLETS = "SET_COMPANIES_WALLETS"

export const SET_HISTORY = "SET_HISTORY";

export const SET_EXCHANGE_RATE = "GET_HISTORY";

export const SET_TRANSACTIONS = "GET_TRANSACTIONS";

export const SET_PENDING_TRANSACTIONS = "SET_PENDING_TRANSACTIONS";

export const SET_INVOICES = "SET_INVOICES";

export const ADD_NEW_INVOICE = "ADD_NEW_INVOICE";

export const SET_AMOUNT_INVOICE = "SET_AMOUNT_INVOICE";
