import React from "react";
import Logo from "@assets/icons/logo.png";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import classes from "./FailPay.module.scss";
import clsx from "clsx";

const FailPay = ({ containerClassName }) => {
  const { t } = useTranslation();
  return (
    <div className={clsx(containerClassName, classes.container)}>
      <div className={classes.logo}>
        <img src={Logo} alt="Infocoin" />
      </div>
      <div className={classes.text}>
        {t("The error of replenishing the wallet")}
      </div>
      <CancelIcon className={classes.icon} />
    </div>
  );
};

export default FailPay;
