//react
import React, { memo, useCallback, Fragment } from "react";

//components
import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import { Typography } from "src/views/blocks";
import { toast } from "react-toastify";

//style
import s from "../../Account.module.scss";

//other
import { withTranslation } from "react-i18next";

import { onToggleNotifictions } from "../../../../hooks/useWbSc";

import { postNotifications, deleteNotifications } from "@services/api/user";

import NotificationsOffRoundedIcon from "@mui/icons-material/NotificationsOffRounded";

const dataNotifications = [
  {
    id: 677,
    message_type: "auction_active",
  },
  {
    id: 679,
    message_type: "content_moderation_status_changes",
  },
  {
    message_type: "low_company_country_wallet_balance",
    id: 689,
  },
  // {
  //   id: 680,
  //   message_type: "low_wallet_balance",
  // },
  {
    id: 691,
    message_type: "video_uploaded",
  },
  {
    id: 681,
    message_type: "service_notification",
  },
  {
    id: 688,
    message_type: "video_blocked",
  },
];

const dataFormatNameNotification = {
  auction_closed: "Closed auctions",
  auction_active: "Active auctions",
  video_blocked: "Video block message",
  content_moderation_status_changes: "Uploaded video moderation",
  low_wallet_balance: "Wallet balance running out",
  service_notification: "Support notification",
  low_company_country_wallet_balance: "Small balance in company wallet",
  video_uploaded: "Video uploaded",
};

const Notifications = ({ t, notifications, update, user }) => {
  const handleToggle = useCallback(
    async (message_type, delivery_method, value) => {
      try {
        if (delivery_method === "email") {
          if (value) {
            await postNotifications({ message_type });
          } else {
            await deleteNotifications(message_type);
          }
        }
        if (delivery_method === "websocket") {
          await onToggleNotifictions(message_type, value);
        }

        toast.success(t(`Notification ${!value ? "disabled!" : "enabled!"}`));
      } catch (err) {
        toast.error(t(err?.response?.data?.error || err?.message || err));
      }

      setTimeout(() => {
        update();
      }, 150);
    },
    [t],
  );

  return (
    <Grid container>
      <Grid>
        <Typography variant="h5" weight="semi-bold">
          {t("Notifications")}
        </Typography>
        <Typography variant="body2" color="gray">
          {t(
            "Here you can configure the push and the email of the notification.",
          )}
        </Typography>
      </Grid>
      {user.role !== "demo_user" ? (
        <Fragment>
          <Grid className={s.item} mt={2}>
            <Grid className={s.item__container}>
              <Grid className={s.item__label}>
                <Typography variant="body2" weight="normal-bold">
                  {t("Push notifications")}
                </Typography>
              </Grid>
              <Grid className={s.item__field}>
                <Grid display="flex" flexDirection="column">
                  {dataNotifications.map(notification => (
                    <FormControlLabel
                      key={notification.id}
                      label={
                        <Typography variant="body2">
                          {t(
                            dataFormatNameNotification[
                              notification.message_type
                            ],
                          )}
                        </Typography>
                      }
                      control={
                        <Checkbox
                          checked={notifications.some(
                            n =>
                              n.message_type === notification.message_type &&
                              n.delivery_method === "websocket",
                          )}
                          onChange={e =>
                            handleToggle(
                              notification.message_type,
                              "websocket",
                              e.target.checked,
                            )
                          }
                          sx={{
                            color: "gray",
                            "&.Mui-checked": {
                              color: "var(--primary-color)",
                            },
                          }}
                        />
                      }
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={s.item}>
            <Grid className={s.item__container}>
              <Grid className={s.item__label}>
                <Typography variant="body2" weight="normal-bold">
                  {t("Email notifications")}
                </Typography>
              </Grid>
              <Grid className={s.item__field}>
                <Grid display="flex" flexDirection="column">
                  {dataNotifications.map(notification => (
                    <FormControlLabel
                      key={notification.id}
                      label={
                        <Typography variant="body2">
                          {t(
                            dataFormatNameNotification[
                              notification.message_type
                            ],
                          )}
                        </Typography>
                      }
                      control={
                        <Checkbox
                          checked={notifications.some(
                            n =>
                              n.message_type === notification.message_type &&
                              n.delivery_method === "email",
                          )}
                          onChange={e =>
                            handleToggle(
                              notification.message_type,
                              "email",
                              e.target.checked,
                            )
                          }
                          sx={{
                            color: "gray",
                            "&.Mui-checked": {
                              color: "var(--primary-color)",
                            },
                          }}
                        />
                      }
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Grid className={s.item} mt={2}>
          <Typography color="error" weight="normal-bold">
            <NotificationsOffRoundedIcon fontSize="small" className="mr-1" />
            {t("Notification settings are not available for the demo user!")}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default withTranslation()(memo(Notifications));
