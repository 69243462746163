import React, { memo, useEffect, useMemo, useRef } from "react";
import { Client as MinioClient } from "minio";
import ContentTable from "src/pages/Content/components/ContentTable";

const ContentWithModal = ({
  onChangeSelectedVideos,
  onAddVideo,
  auctionCountry,
}) => {
  const minioClient = useRef({});

  const isModal = useMemo(
    () => !!onChangeSelectedVideos,
    [onChangeSelectedVideos],
  );

  useEffect(() => {
    minioClient.current = new MinioClient({
      endPoint: process.env.REACT_APP_END_POINT,
      port: Number(process.env.REACT_APP_PORT),
      useSSL: process.env.REACT_APP_USE_SSL === "true",
      accessKey: process.env.REACT_APP_ACCESS_KEY,
      secretKey: process.env.REACT_APP_SECRET_KEY,
    });
  }, []);

  return (
    <ContentTable
      auctionCountry={auctionCountry}
      onAddVideo={onAddVideo}
      isModal={isModal}
      onChangeSelectedVideos={onChangeSelectedVideos}
    />
  );
};

export default memo(ContentWithModal);
