export const OfferEN = `
<!DOCTYPE html>
<html>
<head>
\t<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
\t<title></title>
\t<meta name="generator" content="LibreOffice 7.4.2.3 (Linux)"/>
\t<meta name="author" content="Пользователь Windows"/>
\t<meta name="created" content="2022-05-19T08:55:00"/>
\t<meta name="changed" content="00:00:00"/>
\t<meta name="GrammarlyDocumentId" content="2aea1c2426e23879e0715344204a48f2cb558c0933466317d4632892ff630ca2"/>
\t<style type="text/css">
\t\t@page { size: 8.27in 11.69in; margin-left: 1.18in; margin-right: 0.59in; margin-top: 0.59in; margin-bottom: 0.69in }
\t\t@page p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
\t\t@page a:link { color: #0563c1; text-decoration: underline }
\t</style>
</head>
<body lang="en-US" link="#0563c1" vlink="#800000" dir="ltr"><p align="justify" style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>PUBLIC
AGREEMENT (PROPOSAL)</b></font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>ON
THE PLACEMENT OF USER CONTENT</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">This
Agreement (defined below) concluded by accepting this proposal, which
contains all the essential terms of the Agreement, by you, a legal
entity or individual, who: (i) accepts this Agreement online; or (ii)
is designated as &quot;Principal&quot; and signs the Agreement
(hereinafter - &quot;Customer&quot; / &quot;You&quot; / &quot;Principal&quot;)
and Infocoin Limited in the person of Director Michalis Michail
hereinafter referred to as the &quot;Agent&quot;, &quot;the Company&quot;)
(hereinafter referred to as the &quot;Parties&quot;, or each
separately as the &quot;Party&quot;). This Agreement governs your
participation in the Program (defined below).</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Terms
used in this agreement:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
&quot;Infocoin&quot; - an application and site, united under the name
Infocoin, which have a common functionality and belong to the company
Infocoin Limited;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
&quot;Confidential Information&quot; - information that is defined as
such in accordance with the Privacy Policy of our company;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
&quot;Privacy Policy&quot; - a document that is mandatory for all
users of the site and the Infocoin Services, which is posted on the
site at the link: (</font></font><a href="https://www.infocoin.online/docs/"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>https://www.infocoin.online/docs/</u></font></font></font></a><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">).
Section 7 of this agreement is not Privacy Policy, and refers to the
full Privacy Policy, which we are guided by, and which you should be
guided by, in your interaction with us;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
&quot;Placement company (companies)&quot; - means the placement of
user content and amount of funds. One company - one placement;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
&quot;Rules&quot; - means the rules of the Program posted at
(</font></font><a href="https://www.infocoin.online/docs/"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>https://www.infocoin.online/docs/</u></font></font></font></a><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">)
including the Privacy Policy posted at
(</font></font><a href="https://www.infocoin.online/docs/"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>https://www.infocoin.online/docs/</u></font></font></font></a><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">);</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
&quot;Infocoin Program&quot; - means an online content placement
program.</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>1.
Subject of the contract</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.1.
The Agent undertakes to take all necessary actions on behalf of the
Principal to place the Principal's user through the Infocoin system
on its own behalf, but at the expense of the Principal.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.2.
The Agent acquires rights and obligations by an agreement concluded
with a third party on his own behalf at the expense of the Principal.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.3.
Under an agreement entered into by the Agent with a third party on
behalf of and at the expense of the Principal, the rights and
obligations of the Principal arise.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.4.
The Agent's remuneration may vary depending on many factors. The
current amount of the Agent's remuneration is available in the
personal account of Infocoin and is valid only on the date of signing
the contract. The Agent's remuneration may change at any time, you
agree that you are sufficiently informed about such remuneration, if
it is at least posted in the personal account of Infocoin.</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>2.
Placement and targeting</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.1.
Infocoin Limited will make every effort to place the Principal's user
content, in accordance with the placement and targeting parameters
provided and selected by the Principal. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.2.
Infocoin Limited does not guarantee: (a) the pacing, location or
speed of finding any user content, or (b) the number (if any) of
clicks per link and the number of publications, conversions or clicks
on any content; posted on the site or in the Infocoin application or
in sections of such site / application.</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.
Rights and obligations of the parties</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.1.
The principal obligations:</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
pay the amount allocated by the Principal for one marketing company.
Payment processing by performing actions on the site in the Infocoin
system and transferring funds to the current bank account of Infocoin
Limited. Such obligations of the Agent shall be deemed to have been
fulfilled in full after the Agent has provided a report for the
relevant period or a report on the fulfillment of obligations under
certain agreements made by the Agent;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
not to conclude similar agreements with other persons;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
notify the Agent of any objections to the submitted report within 5
(five) days after its submission. If the objections are not submitted
within the specified period, the report shall be deemed accepted by
the Principal.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.2.
The principal rights:</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><a name="_heading=h.30j0zll"></a>
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>-
</b></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">before
the start of the broadcast of user content, contact the support
service via the link https://business.infocoin.online/support with a
request for a refund of funds reserved for the broadcast of the
relevant video;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
after the end of the broadcast period of the clip (after 24 hours),
contact the support service via the link
https://business.infocoin.online/support with a request to return the
balance of the amount reserved for the broadcast of the relevant
clip.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.3.
The agent obligations:</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
provide reports to the Principal as the Agreement is implemented, but
at least once a month. The necessary evidence of costs must be
attached to the Agent's report. All data from personal Infocoin
account and provided reports on Principal’s e-mail, are normal
evidence paying off money and providing services; </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
follow the instructions of the Principal concerning the agreements
and other obligations made by the Agent, if these instructions do not
contradict the requirements of the law;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
in the event of an appeal by the Principal for the reasons specified
in paragraph 3.2. of this Agreement, within 72 hours to review and
report on the results of consideration of such an appeal by sending a
letter to the Principal's e-mail;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
fulfill all obligations in good faith.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.4.
The agent rights:</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
in order to perform the contract to enter into a subagent agreement
with another person, remaining responsible for the actions of the
subagent before the Principal, unless the parties agree otherwise.
The Subagent is not entitled to enter into agreements with third
parties on behalf of the person who is the Principal under the agency
agreement, except in cases expressly provided by the subagent
agreement. The Agent is obliged to agree on the terms of the subagent
agreement with the Principal;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
unilaterally terminate this Agreement in cases of violation by the
Principal of the Rules of content placement, this Agreement, the laws
of the Republic of Cyprus, support for the actions of the Russian
Federation, denial of aggression against Ukraine.</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>4.
The action of force majeure</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.1.
Neither party shall be liable to the other party for delay or failure
to perform due to force majeure, which arose independently of the
will and desire of the parties and which could not have been foreseen
or avoided, including declared or actual war, public unrest,
epidemics, blockade, embargo, as well as earthquakes, floods, fires
and other natural disasters.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.2.
Party which is unable to fulfill its obligations due to force majeure
is obliged to immediately notify the other party of these
circumstances.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.3.
In connection with the circumstances of force majeure, the parties
must sign a protocol on termination of this Agreement or agree on
joint action to overcome the adverse effects of these circumstances.</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>5.
Cancel the placement of user’s content and refund</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.1.
Unless otherwise provided in the Rules or the Agreement (if any) or
other online interface that refers to the Terms of Content or this
Agreement, either Party may at any time terminate any placement of
user content prior to posting. The Principal may cancel the placement
of user content in advance by providing Infocoin Limited with a
written notice, including, but not limited to, an email to the
support service at </font></font><a href="https://ads.infocoin.online/support"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>https://business.infocoin.online/support</u></font></font></font></a><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.2.
The placement of user content ends shortly after cancellation. Any
other canceled user content placements may be published despite the
fact that all actions have been taken to cancel them, primarily due
to lack of time for response from the Agent, if such business are
published and reviewed, and therefore there are obligations to pay
from by the Agent, the Principal must pay in favor of Infocoin
Limited for such published user content.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.3.
In the event of a decision to return funds at the Principal's
request, in accordance with article 3.2. of this Agreement, the Agent
sends to the Principal's e-mail an act of reconciliation of mutual
settlements. The specified act is considered agreed upon by the
Principal in case of: i) signing with an electronic digital
signature; ii) performing the necessary actions in a personal
account) iii) sending a signed scan copy of such an act in response.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.4.
After agreeing on the reconciliation act, the Agent, by transferring
funds to the Principal's account from which the corresponding payment
was made, returns the funds to the Principal. All expenses related to
the transfer of funds are paid at the expense of the Principal.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.5.
The Principal's Agent shall refund funds within 60 calendar days. The
time of refund is affected by the speed of transaction processing by
the respective banks and/or payment systems, and in this regard, the
refund period may be extended.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.6.
Refund applications are not accepted during the broadcast of the
video.</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>6.
Statements and guarantees</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.1.
The Principal declares and warrants that (i) all information provided
by the Principal to Infocoin Limited is complete, accurate and up to
date; (ii) it has all the necessary rights to grant permission and
hereby grants Infocoin Limited and any Partners all such rights
necessary for Infocoin Limited and for any Partner (s) (in as the
case may be) for the use, hosting, caching, tracking, storage,
copying, modification, distribution, modification, reproduction,
publication, demonstration, transmission and distribution of the
Principal's user content (including any Contextual projects) (&quot;Use
of Content&quot;); and (iii) The use of the Content in accordance
with this Agreement, as well as the websites and / or landing pages
to which the Channel's user content channels are transmitted, shall
not: (a) violate or encourage violations of any legislation or
applicable regulatory or procedural norms; (b) infringe any
intellectual property rights of any third party or contain any
material that may harm, be abusive, obscene, threatening or
degrading.</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>7.
Confidentiality</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.1.
According to this Agreement, for the duration of this Agreement and
for two years after its termination, the Parties undertake not to
disclose Confidential Information of the other Party to third parties
without prior written (including shared by e-mail) consent except as
provided in this Agreement. The Principal is solely responsible for
the use by third parties of any of the Principal's online reports.
&quot;Confidential Information&quot; includes (i) the Principal's
passwords; (ii) user content (including Contextual Objects) prior to
publication; and (iv) any other Program Information or access to the
Technology prior to its public disclosure provided by Infocoin
Limited to the Principal and identified at the time of disclosure in
writing as confidential and / or official; (v) features of the
Program that are not public and are defined as &quot;Beta&quot; or
&quot;Trial Content&quot;. This Confidential Information does not
include information that has become public knowledge not because of a
breach by either Party or (a) that was self-developed without access
to the Confidential Information of the other Party; (b) lawfully
obtained from a third party; or (c) must be disclosed in accordance
with the law or a requirement of a public authority.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.2.
Infocoin may provide Confidential Information of Principal: (i) to
any third party posting the Content or to the relevant Principal as
appropriate; or (ii) to any Principal holding company or to any
Principal's subsidiary or to any subsidiary of the Principal's
holding company.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.3.
The Principal acknowledges and agrees that any information relating
to any invoice, credit card, invoicing and payment provided by the
Principal to Infocoin Limited may be provided by Infocoin Limited to
operating companies, on behalf of Infocoin Limited, exclusively for
the purposes of checking the balance on the accounts, making payments
in favor of Infocoin Limited and / or for servicing the Principal's
account.  </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.4.
This section of the agreement is not executive and refers to the
Privacy Policy, which we are guided by, and you accept that you
should also be guided by it in dealing with us.</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>8.
Term and termцination</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.1.
The term of this Agreement begins on the Date of signing (including
online by performing actions on the site) and lasts until the
termination of the Agreement or until the expiration date of the
agreement. As a general rule, the contract lasts 1 calendar year. The
Principal, subject to prior approval from Infocoin Limited by e-mail,
may extend this Agreement by providing e-mail, or by performing
online actions on our website may extend the validity of this
agreement for the next term in 1 calendar year, or for such other
period, which can be agreed by Infocoin Limited in writing.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.2.
These Terms are considered as a proposal to enter into an Agreement
in accordance with the laws of the Republic of Cyprus. The Agreement
is concluded at the time of acceptance of the Terms online, and which
is considered the Date of Acceptance, unless another Date of
Acceptance is specified in the Service Agreement (if any).</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.3.
This Agreement is concluded in writing by exchanging electronic
messages between the Parties, in the absence of the Agreement on the
provision of services, which was concluded earlier. At the request of
either Party, the Parties may prepare a paper copy of the Agreement.
To do this, a paper copy will be dated the Date of Acceptance, the
place of commission will be considered the city at the location of
Infocoin Limited.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.4.
For the purposes of applicable consumer protection law, this
Agreement, which is available online prior to the conclusion of the
Agreement, is considered as information about the services provided
by Infocoin Limited and / or Infocoin at a distance.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.5.
This Agreement may be terminated with immediate effect by either
Party by giving written notice (including without limitation
including notice by e-mail) to the other Party.</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>9.
Consequences of termination and cancellation of content placement</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">9.1.
In the event of expiration or termination of this Agreement: (i) in
the event of the Principal establishing new campaigns or in any other
way continuing to use the Program, he shall continue to be bound by
the obligations under this Agreement in the same way as the term of
this Agreement would not have expired or had not been terminated
(including without limitation, including the obligation to pay the
charges incurred in connection with the Program); (ii) until the
Principal has revoked its placement of User Content, the Principal
shall remain liable for the obligations under this Agreement and
shall pay the fees incurred in connection therewith; (iii) the
Principal shall continue to be responsible for the payment of any
amounts remaining unpaid as of the date of expiry or termination;
(iv) each Party shall return Confidential Information to the other
Party upon request; and (v) Infocoin Limited may, in its sole
discretion, block or delete any Principal account in the Program.</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>10.
Retreat</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">10.1.
The Parties have the right to assign or otherwise transfer their
rights and / or obligations under this Agreement only with the
written consent of the other Party (such consent may not be refused
or withheld without sufficient grounds), except that Infocoin Limited
has the right to assign its rights and / or obligations under this
Agreement to a third party. By signing this agreement, the Principal
agrees to the assignment of his rights and / or obligations to
Infocoin Limited.</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>11.
Dispute resolution procedure. Responsibilities of the parties</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.1.
All disputes or disagreements that arise between the parties under
this agreement or in connection with it, must be resolved through
negotiations.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.2.
In case of impossibility to resolve differences through negotiations
between the parties, disputes shall be considered in the relevant
court, in accordance with applicable law.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.3.
In case of breach of the terms of this Agreement, the violating party
is obliged to compensate the other party for damages, including lost
profits.</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>12.
Other provisions</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.1.
This Agreement may be concluded about several placement companies. In
relation to one placement campaign, the Parties may enter into
several successive Agreements, including as a result the full use of
the Budget under the Agreement.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.2.
The Parties agree that the information from the control panel or from
the account of the online system Infocoin, certified in writing by
Infocoin Limited and / or Infocoin, is appropriate evidence of the
conclusion or change of the terms of the Agreement.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.3.
By providing personal information under this Agreement, you consent
to the processing of Infocoin in accordance with the Privacy Policy
located at: (</font></font><a href="https://www.infocoin.online/docs/"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>https://www.infocoin.online/docs/</u></font></font></font></a><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">).</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.4.
This Agreement sets out all the terms agreed between the Parties with
respect to its subject matter and supersedes all previous agreements,
declarations and any kind of agreement between the Parties with
respect to its subject matter.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.5.
The Rules may be amended unilaterally at any time by Infocoin Limited
to reflect changes in how Infocoin ensures the overall commercial
viability of the Program. No amendment or modification of the
provisions of this Agreement shall be binding on the Parties prior to
confirmation online or unless it has been documented in writing and
clearly agreed by the parties in writing and bilaterally (including
without limitation including by clicking the mouse and accepting or
exchanging emails).</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.6.
No failure or delay in the exercise of any right or remedy under this
Agreement shall be deemed a waiver of such right or remedy, and no
one-time or partial exercise of any right or remedy under this
Agreement shall deprive the possibility of any subsequent exercise of
the right or remedy or the exercise of any other right or remedy. The
rights and remedies provided for in this Agreement are cumulative and
do not preclude the application of any rights or remedies provided by
law.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.7.
Invalidity, illegality or inability to perform of any provision of
this Agreement shall not entail invalidity or affect the further
legal force of any other provisions of this Agreement.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.8.
Nothing in this Agreement shall be construed to constitute a
partnership (joint partnership) or joint venture of any kind between
the Parties to the Agreement or to provide for an agency or
representative relationship between the Parties to achieve any
purpose of any kind. In this case, neither Party has the right and
authority to bind the other Party with obligations or to enter into a
contractual relationship on behalf of such Party or to create
obligations to a third party in any way and for any purpose.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.9.
Unless otherwise provided in this Agreement, all communications shall
be sent to the addresses specified in this Agreement (or online),
with a copy to the Legal Department by (i) sending by facsimile with
acknowledgment of receipt provided a copy is sent first class mail or
airmail; (ii) dispatch by courier service, such notifications being
deemed to have been received at the time of receipt; or (iii) by
e-mail.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.10.
The agent is a payer of corporate income tax in accordance with the
provisions of the laws of the Republic of Cyprus.</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>13.
Legal address and details of the Agent</b></font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>INFOCOIN
LIMITED</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Anexartisias
&amp; Athinon, </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">NORA
COURT, 2nd floor, Office 203, 3040, Limassol, Cyprus</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">e-mail:
 info@infocoin.online</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Michalis
Michail</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Director.</font></font></p>
</body>
</html>`;
