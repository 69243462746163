import React, {useCallback, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import "react-html5video/dist/styles.css";
import {Container} from "react-bootstrap";
import {Grid, IconButton} from '@mui/material'
import ContentWithModal from "@pages/Content/components/ContentWithModal";
import Button from "@components/Button";
import classes from "./Content.module.scss";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import clsx from "clsx";

import "./Content.css";
import {videosSelector} from "@redux/content/selectors";
import {Typography, VideoStepForm} from "../../views/blocks";
import {introIds} from "@components/introLearning/classes";

const VIEWS = {
    MAIN: 'MAIN',
    CREATE: 'CREATE',
    EDIT: 'EDIT'
}

export function Content() {
    const {videos, loading} = useSelector(videosSelector, shallowEqual);
    const {user} = useSelector(state => state.user, shallowEqual);
    const {t} = useTranslation();

    const [view, setView] = useState(VIEWS.MAIN)

    const [data, setData] = useState({
        country: user.country,
        categories: [],
        description: "",
        speech_language: "",
        email: "",
        goal: "",
        title: "",
        url: "",
        age_min: 16,
        cities: [],
        video: "",
        regions: [],
        age_max: 60,
        genders: [],
        is_all_country: true,
        company_id: undefined,
    });

    const [step, setStep] = useState(1);

    const addVideoToggle = useCallback(() => {
        setView(VIEWS.CREATE)
        // eslint-disable-next-line
    }, [VIEWS]);

    return (
        <Container className={clsx(classes.content, "content")}>
            {view === VIEWS.CREATE &&
                <Grid>
                    <Grid display='flex' alignItems='center'>
                        <IconButton onClick={() => setView(VIEWS.MAIN)}>
                            <ArrowBackIosNewIcon htmlColor='var(--primary-color)'/>
                        </IconButton>
                        <Typography variant='h2' color='primary' weight='semi-bold'
                                    inline>{t("Adding a video")}</Typography>
                    </Grid>
                    <Grid mt={4}>
                        <VideoStepForm
                            setData={setData}
                            step={step}
                            onClose={() => setView(VIEWS.MAIN)}
                            data={data}
                            setStep={setStep}
                        />
                    </Grid>
                </Grid>
            }
            {view === VIEWS.MAIN && <>
                <div className={classes.actions}>
                    {!loading && <h1 className="auction-page-title">{t("Content")}</h1>}

                    {videos?.length >= 0 && !loading && (
                        <Button
                            id={introIds.content.element1}
                            containerClassName={classes.buttonContainer}
                            onClick={addVideoToggle}
                        >
                            {t("Add Video")}
                        </Button>
                    )}
                </div>
                <ContentWithModal onAddVideo={addVideoToggle}/>
            </>
            }
            {/*{videos?.length >= 0 && !loading && <IntroContent />}*/}
        </Container>
    );
}
