//react
import React, { memo, Fragment, useCallback } from "react";
import { useHistory } from "react-router-dom";

//components
import {
  Grid,
  Avatar,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  Tooltip,
} from "@mui/material";
import Button from "@components/Button";
import CountrySelect from "@components/Form/CountrySelect";
import { TextInput } from "src/views/inputs";
import { Typography } from "src/views/blocks";
import PhoneInput from "@components/Form/PhoneInput";

import UploadAvatar from "@pages/Account/components/UploadAvatar";
import RegionSelect from "@components/Form/RegionSelect";
import CitySelect from "@components/Form/CitySelect";
import DaySelect from "@components/Form/DaySelect";

//icons
import { DeleteOutline, EmailOutlined } from "@mui/icons-material";

//style
import s from "../../Account.module.scss";

//other
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { ProfileUserSchema } from "@helpers/Formik/validation";
import {
  updateDemoUserAction,
  updateUserAction,
} from "@redux/user/actions/userActions";
import { connect } from "react-redux";
import { deleteBase64Info } from "@utils/files";

import { toast } from "react-toastify";
import DeleteModal from "@components/DeleteModal";

const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "not_selected", label: "Not selected" },
  { value: "not_binary", label: "Non-binary" },
];

const ProfileForm = ({
  user,
  updateUserAction,
  updateDemoUserAction,
  handleClickFullUser,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const {
    handleChange,
    values,
    handleBlur,
    touched,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    dirty,
    setFieldTouched,
    errors,
  } = useFormik({
    initialValues: {
      ...user,
      phone: user.phone
        ? user.phone[0] !== "+"
          ? `+${user.phone}`
          : user.phone
        : "",
    },
    validationSchema: ProfileUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const data = {
          ...values,
          avatar: deleteBase64Info(values.avatar),
        };

        if (user.role === "demo_user") {
          return updateDemoUserAction(data, t("Profile updated successfully!"));
        }

        await updateUserAction(data, t("Profile updated successfully!"));
      } catch (e) {
        console.error(e);
        toast.error(t(e?.response?.data?.error || e?.message));
      }
      setSubmitting(false);
    },
  });

  const handleChangeName = useCallback(
    e => {
      const {
        target: { value },
      } = e;

      if (/[-<>№=.,|/_+()?^!@#$&*%1234567890';:{}"]/.test(value)) {
        return;
      }

      setFieldValue("name", value);
    },
    [setFieldValue],
  );

  const handleChangeAvatar = useCallback((file, extension) => {
    setFieldValue("avatar", file);
    setFieldValue("extension", extension);
  }, []);

  return (
    <Grid container>
      <Grid>
        <Typography variant="h5" weight="semi-bold">
          {t("Profile")}
        </Typography>
        <Typography variant="body2" color="gray">
          {t("Update your photo and personal details here.")}
        </Typography>
      </Grid>
      <Grid className={s.item} mt={2}>
        <Grid className={s.item__container}>
          <Grid className={s.item__label}>
            <Typography variant="body2" weight="normal-bold">
              {t("Full name")}
            </Typography>
          </Grid>
          <Grid className={s.item__field}>
            <TextInput
              fullWidth
              value={values.name}
              name="name"
              onChange={handleChangeName}
              onBlur={handleBlur}
              error={!!errors.name}
              helperText={touched.name && errors.name}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={s.item}>
        <Grid className={s.item__container}>
          <Grid className={s.item__label}>
            <Typography variant="body2" weight="normal-bold">
              {t("Your photo")}
            </Typography>
            <Typography variant="body2" color="gray">
              {t("This will displayed on your profile.")}
            </Typography>
          </Grid>
          <Grid className={s.item__field}>
            <Grid display="flex" justifyContent="space-between">
              <Avatar
                alt="Avatar"
                src={values.avatar}
                sx={{ width: 64, height: 64 }}
              >
                {user.name[0]}
              </Avatar>
              <Grid display="flex">
                {values.avatar && values.avatar !== "delete" && (
                  <DeleteModal
                    onSuccessDelete={() =>
                      handleChangeAvatar("delete", "delete")
                    }
                    trigger={
                      <Grid mr={2} display="flex">
                        <Typography
                          color="gray"
                          variant="body2"
                          weight="semi-bold"
                          className="hover-opacity pointer"
                        >
                          {t("Delete")}
                        </Typography>
                      </Grid>
                    }
                    question={t("Are you sure you want to delete the avatar?")}
                  />
                )}
                <Grid display="flex">
                  <UploadAvatar
                    name="avatar"
                    label={
                      values.avatar && values.avatar !== "delete"
                        ? t("Update")
                        : t("Upload now")
                    }
                    onChange={handleChangeAvatar}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {user.country && (
        <Grid className={s.item}>
          <Grid className={s.item__container}>
            <Grid className={s.item__label}>
              <Typography variant="body2" weight="normal-bold">
                {t("Phone number")}
              </Typography>
            </Grid>
            <Grid className={s.item__field}>
              <PhoneInput
                value={values.phone}
                name="phone"
                error={touched.phone && errors.phone && t(errors.phone)}
                onChange={value => setFieldValue("phone", value)}
                country={values.country}
                onBlur={() => setFieldTouched("phone", true)}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid className={s.item}>
        <Grid className={s.item__container}>
          <Grid className={s.item__label}>
            <Typography variant="body2" weight="normal-bold">
              {t("Email")}
            </Typography>
          </Grid>
          <Grid className={s.item__field}>
            <TextInput
              fullWidth
              value={user.email}
              disabled
              leftAddons={
                <Fragment>
                  <EmailOutlined htmlColor="gray" />
                </Fragment>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={s.item}>
        <Grid className={s.item__container}>
          <Grid className={s.item__label}>
            <Typography variant="body2" weight="normal-bold">
              {t("Date of Birthday")}
            </Typography>
          </Grid>
          <Grid className={s.item__field}>
            <DaySelect
              value={values.date_of_birth}
              name="date_of_birth"
              onChange={date => setFieldValue("date_of_birth", date)}
              touched={touched.date_of_birth}
              error={t(errors.date_of_birth)}
              onBlur={() => setFieldTouched("date_of_birth", true)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid className={s.item}>
        <Grid className={s.item__container}>
          <Grid className={s.item__label}>
            <Typography variant="body2" weight="normal-bold">
              {t("Location")}
            </Typography>
          </Grid>
          <Grid className={s.item__field}>
            <Grid>
              <Typography color="gray" variant="body2" className="mb-1">
                {t("Country")}
              </Typography>
              <CountrySelect
                isSearchable={false}
                withNameInValue
                value={values.country}
                name="country"
                onChange={value => {
                  setFieldValue("city", "");
                  setFieldValue("region", "");
                  setFieldValue("country", value.name);
                }}
              />
            </Grid>
            <Grid mt={1}>
              <Typography color="gray" variant="body2" className="mb-1">
                {t("Region")}
              </Typography>
              <RegionSelect
                country={values.country}
                value={values.region}
                name="region"
                onChange={value => {
                  handleChange(value);
                  setFieldValue("city", "");
                }}
              />
            </Grid>
            <Grid mt={1}>
              <Typography color="gray" variant="body2" className="mb-1">
                {t("City")}
              </Typography>
              <CitySelect
                region={values.region}
                country={values.country}
                name="city"
                value={values.city}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={s.item}>
        <Grid className={s.item__container}>
          <Grid className={s.item__label}>
            <Typography variant="body2" weight="normal-bold">
              {t("Gender")}
            </Typography>
          </Grid>
          <Grid className={s.item__field}>
            <FormControl>
              <RadioGroup row name="row-radio-buttons-group">
                {genderOptions.map(gender => (
                  <FormControlLabel
                    key={gender.value}
                    sx={{
                      mr: 3,
                    }}
                    value={gender.value}
                    control={
                      <Radio
                        sx={{
                          color: "gray",
                          "&.Mui-checked": {
                            color: "var(--primary-color)",
                          },
                        }}
                        checked={values.gender === gender.value}
                        onChange={() => setFieldValue("gender", gender.value)}
                      />
                    }
                    label={
                      <Typography variant="body2">{t(gender.label)}</Typography>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={s.item}>
        <Grid className={s.item__container} display="flex">
          {user.role === "demo_user" && (
            <Button
              className={s.cancel}
              containerClassName={[s.containerButton, s.buttonFull].join(" ")}
              variant="outlined"
              onClick={handleClickFullUser}
            >
              {t("Become a full user")}
            </Button>
          )}
          <Grid display="flex" width="100%" justifyContent="flex-end">
            <Button
              className={s.cancel}
              containerClassName={s.containerButton}
              variant="gray"
              onClick={() => history.push("/auction")}
            >
              {t("Cancel")}
            </Button>
            <Button
              className={s.save}
              containerClassName={s.containerButton}
              variant="primary"
              loading={isSubmitting}
              disabled={isSubmitting || !dirty}
              onClick={handleSubmit}
            >
              {t("Save")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(null, { updateUserAction, updateDemoUserAction })(
  memo(ProfileForm),
);
