import store from "@redux/store";
import { authRefresh } from "@redux/auth/actions";
import { AUTH_LOGOUT } from "@redux/auth/constants";

export const clearTokenData = () => {
  const isRemember = localStorage.getItem("isRemember");
  if (isRemember === "true") {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("session_id");
  } else {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("session_id");
  }
  store.dispatch({ type: AUTH_LOGOUT });
};

export const setTokenData = (data, isRemember) => {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("session_id");

  sessionStorage.removeItem("token");
  sessionStorage.removeItem("refresh_token");
  sessionStorage.removeItem("session_id");

  localStorage.setItem("isRemember", isRemember);

  if (isRemember) {
    localStorage.setItem("token", data.token);
    localStorage.setItem("refresh_token", data.refresh_token);
    localStorage.setItem("session_id", data.session_id);
  } else {
    sessionStorage.setItem("token", data.token);
    sessionStorage.setItem("refresh_token", data.refresh_token);
    sessionStorage.setItem("session_id", data.session_id);
  }

  store.dispatch(authRefresh(data));
};

export const getTokenData = () => {
  const isRemember = localStorage.getItem("isRemember");
  if (isRemember === "true") {
    return {
      token: localStorage.getItem("token"),
      refreshToken: localStorage.getItem("refresh_token"),
      sessionId: localStorage.getItem("session_id"),
    };
  } else
    return {
      token: sessionStorage.getItem("token"),
      refreshToken: sessionStorage.getItem("refresh_token"),
      sessionId: sessionStorage.getItem("session_id"),
    };
};
