import React, { memo, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { getCitiesAction } from "@redux/language/actions";
import Select from "@components/Form/Select";

const CitySelect = ({
  country,
  region,
  regions,
  cities,
  value,
  onChange,
  error,
  name,
  touched,
  disabled,
  handleBlur,
  placeholder,
  getCitiesAction,
  ...props
}) => {
  const options = useMemo(
    () =>
      cities.map(region => ({
        value: region.name,
        label: region.name,
      })),
    [cities],
  );

  useEffect(() => {
    if (region && regions.length)
      if (regions.find(item => item.name === region)?.id)
        getCitiesAction(regions.find(item => item.name === region)?.id); //eslint-disable-next-line
  }, [region, regions, country]);

  return (
    <Select
      placeholder={placeholder}
      name={name}
      translateOptions={false}
      value={value}
      onChange={onChange}
      handleBlur={handleBlur}
      error={error}
      touched={touched}
      options={options}
      disabled={disabled}
      {...props}
    />
  );
};

const mapStateToProps = ({ language }) => ({
  cities: language.cities,
  regions: language.regions,
});
export default connect(mapStateToProps, { getCitiesAction })(memo(CitySelect));
