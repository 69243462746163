import React, { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Select, MenuItem, Collapse } from "@mui/material";
import countries from "data/languages.json";
import "./index.scss";

const SelectTranslation = ({ expand, type }) => {
  const { t, i18n } = useTranslation();

  const country = useMemo(() => {
    let country = countries.find(country => {
      return country.iso_2 === i18n.language;
    });

    if (!country) {
      country = countries[0];
    }

    return country;
  }, [i18n.language]);

  const handleChange = useCallback(
    e => {
      const {
        target: { value },
      } = e;

      i18n.changeLanguage(value);
    },
    [i18n],
  );

  return (
    <div className={type === 'login' ? 'auth-translation' : ''}>
      <Select
        variant="standard"
        className={`${type === 'login' ? 'auth-translation' : ''} select-translation select-translation--expanded`}
        disableUnderline
        IconComponent={!expand ? null : undefined}
        value={country ? country.iso_2 : ""}
        renderValue={() => (
          <div className=" select-translation__option select-translation__renderValue">
            <img
              className="select-translation__icon"
              alt=""
              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.icon}.svg`}
            />
            <Collapse
              in={expand}
              style={{
                width: !expand && 0,
              }}
            >
              <div className="select-translation__renderValue__label">
                {t(country.name)}
              </div>
            </Collapse>
          </div>
        )}
        onChange={handleChange}
      >
        {countries.map(country => (
          <MenuItem
            key={country.id}
            className="select-translation__option"
            value={country.iso_2}
          >
            <div className="select-translation__option">
              <img
                className="select-translation__icon"
                alt=""
                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.icon}.svg`}
              />
              <div className="select-translation__label">{country.name}</div>
            </div>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SelectTranslation;
