import {
  UPDATE_USER_SUCCESS,
  UPDATE_USER,
  GET_USER,
  GET_USER_SUCCESS,
} from "@redux/user/constants";

import { toast } from "react-toastify";
import {
  getUser,
  updateUser,
  getDemoUser,
  updateDemoUser,
} from "@services/api/user";

export const saveUserAction = data => {
  return {
    type: GET_USER_SUCCESS,
    payload: data,
  };
};

export const getDemoUserAction = () => async dispatch => {
  dispatch({
    type: GET_USER,
  });
  try {
    const response = await getDemoUser();
    dispatch({
      type: GET_USER_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || error.message);
    throw error;
  }
};

export const getUserAction = () => async dispatch => {
  dispatch({
    type: GET_USER,
  });
  try {
    const response = await getUser();
    dispatch({
      type: GET_USER_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    if (error.response.status !== 403) {
      toast.error(error.response?.data?.error || error.message);
    }
    throw error;
  }
};

export const updateUserAction = (data, text) => async dispatch => {
  try {
    const { data: user } = await updateUser(data);
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: user,
    });

    if (text) {
      toast.success(text);
    }
    return user;
  } catch (error) {
    throw error;
  }
};

export const updateDemoUserAction = (data, text) => async dispatch => {
  try {
    const { data: user } = await updateDemoUser(data);
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: user,
    });
    if (text) {
      toast.success(text);
    }
    return user;
  } catch (error) {
    throw error;
  }
};
