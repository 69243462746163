import { axiosInstance } from "@helpers/http/httpInstance";

export const getVideosLikes = async ({ startTime, endTime }) => {
  return await axiosInstance.get(`api/likes`, {
    params: {
      start_time: startTime,
      end_time: endTime,
    },
  });
};
export const getVideosViews = async ({ startTime, endTime }) => {
  return await axiosInstance.get(`api/views`, {
    params: {
      start_time: startTime,
      end_time: endTime,
    },
  });
};

export const getVideosCTR = async ({ startTime, endTime }) => {
  return await axiosInstance.get(`api/ctr`, {
    params: {
      start_time: startTime,
      end_time: endTime,
    },
  });
};
export const getVideosInteractions = async ({ startTime, endTime }) => {
  return await axiosInstance.get(`api/interactions`, {
    params: {
      start_time: startTime,
      end_time: endTime,
    },
  });
};

export const getVideosComplaints = async ({ startTime, endTime }) => {
  return await axiosInstance.get(`api/complained-videos`, {
    params: {
      start_time: startTime,
      end_time: endTime,
    },
  });
};

export const getPlaylistStatistic = ({ limit = 10, offset = 0 }) => {
  return axiosInstance.get(`/api/lots-in-playlist/statistic`, {
    params: {
      limit,
      offset,
    },
  });
};

export const getLotStatisticInPlaylist = async id => {
  return await axiosInstance.post(`/api/lots-in-playlist/statistic/detail`, {
    lot_ids: id,
  });
};

export const getLotStatisticInPast = async ({
  id,
  start_time,
  end_time,
  geo_detail,
}) => {
  return await axiosInstance.post(
    `/api/lots-in-closed-auctions/statistic/detail`,
    {
      lot_ids: id,
      geo_detail,
    },
    {
      params: {
        start_time,
        end_time,
      },
    },
  );
};

export const getPastStatistic = ({
  limit = 10,
  offset = 0,
  start_time,
  end_time,
  country,
}) => {
  return axiosInstance.get(`/api/lots-in-closed-auctions/statistic`, {
    params: {
      limit,
      start_time,
      end_time,
      offset,
      country,
    },
  });
};
