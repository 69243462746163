//react
import React, { memo, useState, useCallback } from "react";

//components
import {
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Typography } from "src/views/blocks";

//style
import s from "../../Account.module.scss";

import { release } from "../../../../data/release-notes";

import clsx from "clsx";

//other
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ReleaseNotes = () => {
  const { t } = useTranslation();

  const [quantum, setQuantum] = useState(3);

  const [data] = useState(JSON.parse(release).reverse());

  const handleToggle = useCallback(() => {
    if (quantum === 3) {
      setQuantum(data.length);
    } else {
      setQuantum(3);
    }
  }, [quantum]);

  return (
    <Grid container>
      <Grid>
        <Typography variant="h5" weight="semi-bold">
          {t("Release notes")}
        </Typography>
        <Typography variant="body2" color="gray">
          {t(
            "Here you can see all the updates and release notes for INFOCOIN. We try to make our platform user-friendly and as unique as possible.",
          )}
        </Typography>
      </Grid>
      <Grid mt={2} className={s.item}>
        {data.slice(0, quantum).map((r, key) => (
          <Accordion key={key} className={s.accordion}>
            <AccordionSummary
              expandIcon={
                <IconButton>
                  <ExpandMoreIcon htmlColor="var(--primary-color)" />
                </IconButton>
              }
            >
              <Grid>
                <Typography variant="body1" weight="normal-bold">
                  {r.slice(0, r.indexOf("|"))}
                </Typography>
                <Typography variant="body2" color="gray" weight="normal-bold">
                  {r.slice(r.indexOf("|") + 1, r.lastIndexOf("|"))}
                </Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: r.slice(r.lastIndexOf("|") + 1),
                }}
              ></Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
      <Grid className={s.all}>
        <IconButton
          size="small"
          className={clsx({
            [s.all__open]: quantum !== 3,
          })}
          onClick={handleToggle}
        >
          <ExpandMoreIcon htmlColor="white" size="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default memo(ReleaseNotes);
