import React, { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DataTable from "src/views/blocks/DataTable";
import "./index.scss";
import { RenderField, TooltipField, VideoField } from "src/views/fields";
import BudgetInput from "@pages/Auction/components/BudgetInput";
import { deleteLot, getAuctionLots } from "@services/api/auctions";
import { loadLotsAction } from "@redux/auction/actions";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DeleteModal from "@components/DeleteModal";

import { IconButton } from "@mui/material";
import MobileAuctionView from "@pages/Auction/components/MobileAuctionView";

let interval = null;

const AuctionTable = props => {
  const { data, isLoading } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const isEmergency = useSelector(state => state.emergency.isEmergency);

  const activeAuctionId = useSelector(
    state => state.auction.activeAuctionId,
    shallowEqual,
  );

  const handleDeleteLot = useCallback(
    async id => {
      await deleteLot(id)
        .then(() => {
          toast.success(t("Lot successfully removed!"));
        })
        .catch(err => {
          toast.error(t(err?.message || err));
        });
    },
    [t],
  );

  const {
    user: { id: userId },
  } = useSelector(state => state.user);

  useEffect(() => {
    if (!interval && !isLoading) {
      interval = setInterval(async () => {
        if (activeAuctionId) {
          const response = await getAuctionLots(activeAuctionId);
          dispatch(loadLotsAction(response.data));
        }
      }, 8000);
    }

    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, [activeAuctionId, isLoading]);

  return (
    <React.Fragment>
      <div className="desktop-auction">
        <DataTable
          isAuction
          items={data.map(item => {
            return {
              ...item,
              ...(item.bidder_id !== userId
                ? {
                    video: {},
                    views: "",
                  }
                : {}),
            };
          })}
        >
          <RenderField
            render={(record, playlistNumber = "") => {
              return (
                <div className="d-flex firstRow">
                  <div>№{playlistNumber}</div>
                  {record.bidder_id === userId && (
                    <div>
                      <DeleteModal
                        onSuccessDelete={() => handleDeleteLot(record.id)}
                        trigger={
                          <IconButton>
                            <DeleteOutlineIcon color="error" />
                          </IconButton>
                        }
                        question={t("Are you sure you want to delete the lot?")}
                      />
                    </div>
                  )}
                </div>
              );
            }}
            align="center"
            label="Lot"
            name="playlist_number"
          />

          <VideoField
            isAuction
            align="center"
            label="Video"
            titleName="Video"
            name="video.filepath_480_p"
          />

          <RenderField
            render={record => {
              return !isEmergency && userId === record.bidder_id ? (
                <BudgetInput
                  id={record.id}
                  videoId={record.video.id}
                  duration={record.video.duration}
                  views={record.expect_views}
                  amount={parseFloat(record.amount)}
                />
              ) : (
                <div>{parseFloat(record.amount)} IC</div>
              );
            }}
            align="center"
            label="Bid"
            name="amount"
          />
          <RenderField
            render={(record, budget) => {
              return (
                <React.Fragment>
                  {record.bidder_id === userId && `${budget}`}
                </React.Fragment>
              );
            }}
            align="center"
            label="Budget"
            name="budget"
          />
          <RenderField
            render={(record, value) => {
              return userId === record.bidder_id && value;
            }}
            align="center"
            label="Views"
            name="expect_views"
          />

          <RenderField
            render={(record, value) => {
              return userId === record.bidder_id && value;
            }}
            align="center"
            label="Company"
            name="video.company.title"
          />
          <RenderField
            render={record => {
              return record.video.title;
            }}
            align="center"
            label="Title"
            name="video.title"
          />

          <TooltipField
            align="center"
            translate
            label="More"
            name="video.description"
          />
        </DataTable>
      </div>
      <div className="mobile-auction">
        <MobileAuctionView
          lots={data.map(item => {
            return {
              ...item,
              ...(item.bidder_id !== userId
                ? {
                    video: {},
                    views: "",
                  }
                : {}),
            };
          })}
          handleDeleteLot={handleDeleteLot}
          userId={userId}
        />
      </div>
    </React.Fragment>
  );
};

export default AuctionTable;
