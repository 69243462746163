import {
  CLEAR_NEW_LOT,
  LOAD_CATEGORIES,
  LOAD_LOTS,
  SAVE_NEW_LOT,
  SELECT_CATEGORY,
  SET_ACTIVE_AUCTION, SET_AUCTION_HISTORY,
  SET_AUCTIONS_ERROR,
  SET_LOT_TO_EDIT, SET_MIN_AMOUNT, LOAD_ALL_AUCTIONS, SET_DATE_AUCTION
} from "./constants";
import { AUTH_LOGOUT } from "@redux/auth/constants";

const initialState = {
  categories: [],
  lots: [],
  selectedCategory: null,
  activeAuctionId: null,
  error: null,
  lotToEdit: null,
  minAmount: 0,
  date: new Date(),
  savedLot: {},
  allAuctions: [],
  auctionHistory: []
};

export const auctionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case LOAD_ALL_AUCTIONS:
      return {
        ...state,
        allAuctions: action.payload
      }
    case LOAD_LOTS:
      return {
        ...state,
        lots: action.payload,
        error: null,
      };
    case SELECT_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload,
      };
    case SET_DATE_AUCTION:
      return {
        ...state,
        date: action.payload
      }
    case SET_ACTIVE_AUCTION:
      return {
        ...state,
        activeAuctionId: action.payload,
      };
    case SET_LOT_TO_EDIT:
      return {
        ...state,
        lotToEdit: action.payload,
      };
    case SET_MIN_AMOUNT:
      return {
        ...state,
        minAmount: action.payload,
      };
    case AUTH_LOGOUT: {
      return initialState;
    }
    case SET_AUCTIONS_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case SAVE_NEW_LOT: {
      return {
        ...state,
        savedLot: {
          ...state.savedLot,
          ...action.payload,
        },
      };
    }
    case SET_AUCTION_HISTORY: {
      return {
        ...state,
        auctionHistory: action.payload
      }
    }
    case CLEAR_NEW_LOT: {
      return {
        ...state,
        savedLot: {},
      };
    }
    default:
      return state;
  }
};
