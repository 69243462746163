import {Grid} from "@mui/material";

import {useEffect, useMemo, useRef} from "react";

import {Typography} from "../../../../views/blocks";

import {withTranslation} from "react-i18next";
import RightMenuAccordion from "../Accordion";

import clsx from "clsx";

import s from "../../RightPanel.module.scss";

const messages = [
    {
        title: "Auction",
        text: `Auction is the main tab of a professional Infocoin account. It makes it possible to participate in the auction, view the statistics of past auctions.
    To participate in the auction, you must first upload the content in the “Content” tab
    `,
        id: 1,
        path: ["/auction"],
    },
    {
        title: "Dashboard",
        text: `The statistics tab shows the success of your promotion campaigns, over a period of one day to a year, on various indicators, including: Likes, views, complaints, CTR, interactions and the number of ICs transferred to viewer accounts.`,
        id: 2,
        path: ["/dashboard"],
    },
    {
        title: "Content",
        text: `The content tab allows you to preload videos by setting up the title, description, display settings and attach links to them for future transitions, so that by the time you win the auction, the video has been revised and approved by the moderator.
    The tab also displays already loaded content, and allows you to edit previously set settings. Even when the auction has passed - the content is still available in the content tab, you can easily edit the content settings (if necessary) and participate in the auction again.
    Company - Filter by company name if you are uploading on behalf of more than one person.
    Search - search for a specific company.
    By clicking on the arrow to the left of your video preview, you can edit the data attached to it (title, description, etc.).
    In the "Status" column, you can immediately delete the video if you no longer plan to post it.
    Add Video - Opens the dialog box for adding a video to your profile.
    Title - visible when the video is shown.
    Description - appears when the video is shown. Allows you to describe what the video is about.
    Category - targeting by categories. There may be several.
    The purpose of the Interaction is how to contact you.
    Country - geotargeting.
    Age - targeting the age of the viewer.
    Gender - targeting the gender of the viewer.
    Content language is important for targeting.
    `,
        id: 3,
        path: ["/content"],
    },
    {
        title: "Wallet",
        text: `Allows you to control the balance of your account to participate in upcoming auctions. You can use several payment options.
    Choose a company to replenish your account. Please check that the company details are entered correctly, this will allow you to send you the correct invoice.
    Country where content will be shown - the country to which your videos will be displayed.
    Wallet replenishment - select the amount by which you wish to replenish your account in order to participate in the auction.
    Payment system - choose a convenient payment method.
    In the table you can upload, filter by date and companies of the invoice.
    `,
        id: 4,
        path: ["/wallet"],
    },
    {
        title: "Support",
        text: `Contains a short FAQ that will help you better understand the service and its functionality. Also, from absolutely any tab, in the lower right corner, a communication button is available, initially a trained bot will try to answer your question, but the technologies are not perfect, therefore our operators are always ready to answer your questions.`,
        id: 5,
        path: ["/support"],
    },
    {
        title: "Company",
        text: `The tab contains the data of you as an individual, entrepreneur or legal entity, a photo (logo), a header and legal data, which are necessarily displayed under any information that you distribute among viewers. Please make sure that the entered data is true and honest, you have read the terms of use.
    By clicking on the gear in the upper right corner, you can make changes to your account`,
        id: 6,
        path: ["/companies"],
    },
];

const RightPanelFaq = ({t, open, expanded, setExpanded, handleToggle}) => {
    const pathname = window.location.pathname;

    const isAllowedOpenBar = !localStorage.getItem("openBar");

    const block = useRef(null)

    const isSomePathnameInMessage = useMemo(
        () => messages.some(({path}) => path.includes(pathname)),
        [pathname],
    );

    const initialMessages = useMemo(() => {
        if (isSomePathnameInMessage && isAllowedOpenBar) {
            return messages.sort((x, y) =>
                x.path.includes(pathname) ? -1 : y.path.includes(pathname) ? 1 : 0,
            );
        }
        return messages;
    }, [pathname, isAllowedOpenBar]);

    const handleChange = panel => (event, newExpanded) => {

        if (!open) {
            handleToggle();

            localStorage.removeItem("openBar");
        }

        block?.current?.scrollTo({top: 0})

        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        if (isSomePathnameInMessage && isAllowedOpenBar) {
            setExpanded(initialMessages[0].id);

            if (!open) {
                handleToggle();
            }
        } else {
            if (open) {
                setExpanded(null);

                handleToggle();
            }
        }
    }, [pathname]);

    return (
        <Grid mt={6}>
            {open ? (
                <Typography
                    variant="h4"
                    weight="semi-bold"
                    className={s.faqTitle}
                    align="center"
                >
                    {t("Frequently Asked Questions")}
                </Typography>
            ) : (
                <Typography variant="h4" weight="semi-bold" align="center">
                    {t("FAQ")}
                </Typography>
            )}
            <Grid ref={block} className={clsx(s.faq, "pro-sidebar-layout")}>
                <Grid mt={2}>
                    {initialMessages.map(faq => {
                        return (
                            <RightMenuAccordion
                                expanded={expanded}
                                onChange={handleChange}
                                openPanel={open}
                                {...faq}
                                key={faq.id}
                            />
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withTranslation()(RightPanelFaq);
