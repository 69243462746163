import React, {
  memo,
  useState,
  useCallback,
  Fragment,
  useMemo,
  useEffect,
} from "react";
import "./index.scss";
import Input from "react-phone-number-input/input";

import { Grid } from "@mui/material";

import PhoneSelect from "@components/Form/PhoneInput/select";

import { isValidPhoneNumber } from "react-phone-number-input/min";

import { connect } from "react-redux";
import { getCountriesAction } from "@redux/language/actions";

const PhoneInput = ({
  t,
  value,
  countries,
  getCountriesAction,
  onChange,
  country,
  error,
  ...rest
}) => {
  const [input, setInput] = useState(value);

  const [selected, setSelected] = useState("");

  const handleSelect = useCallback(select => {
    setSelected(select);

    setInput("");
  }, []);

  const handleChange = useCallback(
    value => {
      if (value) {
        setInput(value);

        if (onChange) {
          onChange(value);
        }
      }
    },
    [onChange],
  );

  const banExtraNumbers = useCallback(e => {
    const {
      target: { value },
    } = e;

    const isValid = isValidPhoneNumber(value);

    if (isValid) {
      e.preventDefault();
    }
  }, []);

  const options = useMemo(
    () =>
      // eslint-disable-next-line
      countries
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
        })
        .map(c => ({
          label: c.name,
          value: c.iso_2,
        })),
    [countries],
  );

  useEffect(() => {
    const initial = async () => {
      const data = await getCountriesAction();

      const initialSelect = data.find(c => c.name === country);

      setSelected({
        label: initialSelect.name,
        value: initialSelect.iso_2,
      });
    };

    if (!countries.length) {
      initial();
    } else {
      const initialSelect = countries.find(c => c.name === country);

      setSelected({
        label: initialSelect.name,
        value: initialSelect.iso_2,
      });
    }
  }, []);

  return (
    <Fragment>
      <Grid className="phone-input-container">
        {selected && (
          <PhoneSelect
            onChange={handleSelect}
            value={selected}
            options={options}
          />
        )}
        <Input
          value={input}
          international
          onKeyPress={banExtraNumbers}
          withCountryCallingCode={true}
          country={selected.value}
          onChange={handleChange}
          className="phone-input-container__input"
          {...rest}
        />
      </Grid>
      {error && <div className="phone-input-error">{error}</div>}
    </Fragment>
  );
};

const mapStateToProps = ({ language }) => ({
  countries: language.countries,
});

export default connect(mapStateToProps, { getCountriesAction })(
  memo(PhoneInput),
);
