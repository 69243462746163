import { axiosInstance } from "@helpers/http/httpInstance";

export const getAuctions = (country = "Ukraine") => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.get(`/api/auctions/${country}`);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const getAuctionLots = lotId => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.get(`/api/auctions/lots/${lotId}`);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const getAuctionHistory = async (start_time, end_time) => {
  return await axiosInstance.get(`/api/auctions/history?start_time=${start_time}&end_time=${end_time}`)
};
export const addLot = data => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.post(`api/auctions/lot`, data);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const createBudget = data => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.post(`/api/auctions/budget`, data);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const editLot = async (lotId, data) => {
  return axiosInstance.put(`/api/auctions/lot/${lotId}`, data);
};

export const deleteLot = async (id) => {
  return axiosInstance.delete(`/api/auctions/lots/${id}`);
}

export const getAuctionAudience = async (data) => {
  return axiosInstance.post(`/api/auctions/advance-audience`,data);
}