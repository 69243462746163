import React, { useCallback, useState } from "react";
import classes from "./BudgetInput.module.scss";
import { useFormik } from "formik";
import { TextInput } from "../../../../views/inputs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import * as Yup from "yup";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { editLot, getAuctionLots } from "src/services/api/auctions";
import { toast } from "react-toastify";
import { clearSavedLotAction, loadLotsAction } from "@redux/auction/actions";
import { useTranslation } from "react-i18next";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const BudgetInput = ({ id, amount, duration, views }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const minAmount = useSelector(state => state.auction.minAmount);

  const { t } = useTranslation();

  const activeAuctionId = useSelector(state => state.auction.activeAuctionId);

  const [editableBudget, setEditableBudget] = useState(false);

  const onBidClick = useCallback(() => {
    setEditableBudget(!editableBudget);
  }, [editableBudget]);

  const form = useFormik({
    initialValues: {
      amount: amount,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .min(minAmount, "Must be greater then past value")
        .required("Field is required"),
    }),
    onSubmit: async values => {
      if (values.amount !== amount) {
        setIsLoading(true);

        const budget = duration * values.amount * views;

        try {
          await editLot(id, {
            amount: String(values.amount),
            budget: String(budget),
          });
          toast.success(t("You are successfully edited lot"));
          const response = await getAuctionLots(activeAuctionId);
          setIsLoading(false);
          dispatch(clearSavedLotAction());
          dispatch(loadLotsAction(response.data));
        } catch (e) {
          toast.error(e?.response?.data?.error || e.message);
        }
      }
      setEditableBudget(false);
    },
  });

  const onArrowsClick = useCallback(
    async isAdd => {
      const num = Number(form.values.amount);

      const numOfSymb = Number.isInteger(num)
        ? 0
        : num.toString().split(".")[1].length;

      const newAmount = Number.isInteger(num)
        ? isAdd
          ? num + 1
          : num - 1
        : Number(isAdd ? num + 0.0001 : num - 0.0001).toFixed(
            numOfSymb > 4 ? numOfSymb : 4,
          );

      if (newAmount >= Number(minAmount)) {
        if (editableBudget) {
          form.setFieldValue("amount", newAmount);
        } else {
          form.setFieldValue("amount", newAmount);
          setIsLoading(true);

          const budget = duration * newAmount * views;

          try {
            await editLot(id, {
              amount: String(newAmount),
              budget: String(budget),
            });

            const response = await getAuctionLots(activeAuctionId);

            dispatch(loadLotsAction(response.data));
          } catch (e) {
            toast.error(t("You haven't enough money"));
            form.resetForm({ amount: amount });
          }

          setIsLoading(false);
        }
      }
    },
    [
      activeAuctionId,
      dispatch,
      duration,
      views,
      amount,
      editableBudget,
      form,
      id,
      minAmount,
      t,
      amount,
    ],
  );

  return (
    <div className={classes.container}>
      {editableBudget ? (
        <TextInput
          value={form.values.amount}
          onChange={event => {
            if (!Number.isNaN(event.target.value)) {
              form.handleChange(event);
            }
          }}
          type={"number"}
          name="amount"
          error={form.errors.amount}
          helperText={t(form.errors.amount)}
          toched
          className={classes.inputClass}
          variant="grey"
          rightAddons={
            <CheckCircleIcon
              className={clsx(classes.saveBtn, {
                [classes.validSave]: form.isValid,
              })}
              onClick={form.handleSubmit}
            />
          }
        />
      ) : (
        <div onClick={onBidClick}>{form.values.amount} IC</div>
      )}
      <div className={classes.arrowsContainer}>
        <ArrowDropUpIcon
          className={clsx(classes.arrow, {
            [classes.disabledArrow]: isLoading,
          })}
          onClick={() => {
            if (!isLoading) onArrowsClick(true);
          }}
        />
        <ArrowDropUpIcon
          className={clsx(classes.arrow, classes.downArrow, {
            [classes.disabledArrow]:
              Number(form.values.amount) <= minAmount || isLoading,
          })}
          onClick={() => {
            if (Number(form.values.amount) > minAmount && !isLoading)
              onArrowsClick(false);
          }}
        />
      </div>
    </div>
  );
};

export default BudgetInput;
