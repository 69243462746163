import Loader from "@components/Loader";
import React from "react";
export const EstimatedViewsComponent = ({
  isLoadingViews,
  estimatedViews,
  categories,
  country,
  t,
}) => {
  if (isLoadingViews) {
    return <Loader width={40} height={40} />;
  } else {
    return (
      estimatedViews &&
      (estimatedViews.category ||
        estimatedViews.country ||
        estimatedViews.region) && (
        <div className="mt-1">
          <div className="text-input__label">
            {t("Approximate number of views")}
          </div>
          <div className="estimated-wrapper">
            {estimatedViews && estimatedViews.category && (
              <div
                style={{
                  width: estimatedViews.country ? "65%" : "100%",
                  marginRight: estimatedViews.country ? "5px" : "0",
                }}
              >
                <p>
                  {t("Category")} -{" "}
                  {categories
                    .map(category => {
                      return category.label;
                    })
                    .join(", ")}
                </p>
                <span>{estimatedViews.category.total}</span>
              </div>
            )}
            {estimatedViews && estimatedViews.country && (
              <div
                style={{
                  width: estimatedViews.category ? "35%" : "100%",
                  marginLeft: estimatedViews.category ? "5px" : "0",
                }}
              >
                <p>{t(country)}</p>
                <span>{estimatedViews.country[country]}</span>
              </div>
            )}
            {estimatedViews && estimatedViews.region && (
              <div
                style={{
                  width: estimatedViews.category ? "65%" : "100%",
                  marginLeft: estimatedViews.category ? "5px" : "0",
                }}
              >
                <p>{t("Region")}</p>
                <span>{estimatedViews.region.total}</span>
              </div>
            )}
          </div>
        </div>
      )
    );
  }
};
