import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import lodashGet from "lodash/get";

import { useRecord } from "src/hooks";

const TooltipField = props => {
  const { translate, displayLength = 40, name } = props;

  const { t } = useTranslation();

  const { record } = useRecord();

  const value = lodashGet(record, name, "");
  const displayValue = translate ? t(value) : value;

  return (
    <div>
      {displayValue.length > displayLength && (
        <OverlayTrigger
          overlay={<Tooltip id="button-tooltip">{value}</Tooltip>}
          placement="right"
          delay={{ show: 250, hide: 400 }}
        >
          <button
            style={{
              textAlign: "center",
            }}
          >
            {displayValue.substring(0, displayLength)}...
          </button>
        </OverlayTrigger>
      )}

      {displayValue.length <= displayLength && displayValue}
    </div>
  );
};

export { TooltipField };
