export const OfferUK = `

<!DOCTYPE html>
<html>
<head>
\t<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
\t<title></title>
\t<meta name="generator" content="LibreOffice 7.4.2.3 (Linux)"/>
\t<meta name="author" content="Vika"/>
\t<meta name="created" content="2022-06-23T12:09:00"/>
\t<meta name="changed" content="00:00:00"/>
\t<meta name="GrammarlyDocumentId" content="9b0bac3af706d21a58b0e18dcb4394cc86131ff8d4e267294e84edc8b2f35a31"/>
\t<style type="text/css">
\t\t@page { size: 8.27in 11.69in; margin-left: 0.98in; margin-right: 0.59in; margin-top: 0.79in; margin-bottom: 1.08in }
\t\t@page p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
\t\t@page a:link { color: #0563c1; text-decoration: underline }
\t</style>
</head>
<body lang="uk-UA" link="#0563c1" vlink="#800000" dir="ltr"><p align="center" style="line-height: 100%; margin-bottom: 0in;">
<br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>ПУБЛІЧНИЙ
ДОГОВІР (ОФЕРТА)</b></font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>НА
РОЗМІЩЕННЯ КОРИСТУВАЦЬОГО КОНТЕНТУ</b></font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in;">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font>&nbsp;</font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Цей
Договір (визначений нижче) укладено
шляхом прийняття цієї пропозиції, що
містить усі істотні умови Договору,
вами, що виступає юридичною особою або
фізичною особою,  та яка: (і) приймає цей
Договір в режимі он-лайн; або (іі) визначена
як </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>&quot;Принципал&quot;</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
і підписує Договір (надалі - </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>&quot;Замовник&quot;</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
/ </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>&quot;Ви&quot;</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">/
</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>&quot;Принципал&quot;</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">)
і </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
в особі директора Міхаліса Михайла,
який діє на підставі Статуту (надалі -
</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>&quot;Агент&quot;,
&quot;Компанія&quot;</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">)
(надалі разом - &quot;</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>Сторони</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">&quot;,
або кожна окремо - </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>&quot;Сторона&quot;</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">).
Цей Договір регулює Вашу участь у
Програмі (визначеній нижче).</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>Терміни,
які використовуються в даному договорі:</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>&quot;Infocoin&quot;</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
– додаток та сайт, об’єднані назвою
Infocoin, які мають спільний функціонал і
відносяться до компанії </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
&quot;Конфіденційна інформація&quot; –
інформація, яка визначена як така,
відповідно до Політики Конфіденційності
нашої компанії;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>&quot;Політика
Конфіденційності&quot;</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
– документ, який є обов’язковим до
прийняття всіма користувачами сайту і
Послугами Infocoin, який розміщено на сайті
за посиланням: </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>(https://www.infocoin.online/docs/</u></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">).
Розділ 7 цього договору не є вичерпним,
і відсилає до повної Політики
Конфіденційності, якою керуємось ми, і
якою маєте керуватись ви, у своїй
взаємодії з нами;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>&quot;Компанія
(компанії) з розміщення&quot;</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
– мається на увазі розміщення
користувацького контенту і відповідної
суми коштів. Одна компанія – одне
розміщення;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
&quot;</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>Правила</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">&quot;
– означає правила Програми, які
розміщуються за адресою
</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>(https://www.infocoin.online/docs/</u></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">)&nbsp;включаючи
Правила Конфіденційності, які розміщуються
за адресою (</font></font></font><a href="https://www.infocoin.online/docs/"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>https://www.infocoin.online/docs/</u></font></font></font></a><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">);</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
&quot;</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>Програма
Infocoin</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">&quot;
– означає програму розміщення контенту
он-лайн.\t</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>1.
Предмет договору</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.1.
Агент зобов'язується за винагороду
робити з доручення Принципала всі
необхідні дії для розміщення користувацького
контенту Принципала через систему
Infocoin від свого імені, але за рахунок
Принципала.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.2.
Агент здобуває права й стає зобов'язаним
по угоді, укладеної із третьою особою
від свого імені за рахунок Принципала.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.3.
По угоді, укладеної Агентом із третьою
особою від імені й за рахунок Принципала,
права й обов'язки виникають у Принципала.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.4.
Винагорода Агента може змінюватись в
залежності від багатьох чинників.
Актуальна сума винагороди Агента
доступна в особистому кабінеті Infocoin і
діє лише на дату підписання договору.
Винагорода Агента може змінюватись
будь-коли, ви погоджуєтесь що достатнім
чином проінформовані про таку винагороду,
якщо вона як мінімум розміщена в
особистому кабінеті Infocoin.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>2.
Розміщення і таргетування</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.1.
</font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
докладатиме усіх зусиль для розміщення
користувацького контенту Принципала,
 у відповідності до параметрів розміщення,
наданих і обраних Принципалом.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.2.
</font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
не гарантує: (а) розміщення, місцеположення
або швидкість знаходження будь-якого
користувацького контенту, або (б)
кількість (якщо такі є) кліків за посилання
і число опублікувань, перетворень або
кліків стосовно будь-якого контенту,
розміщеного на сайті або в додатку
Infocoin або в розділах такого сайту/додатку.</font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in;">
<br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.
Права та обов'язки сторін</b></font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.1.
Принципал зобов'язаний:</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
сплатити суму виділену Принципалом на
одну компанію. Оплата здійснюється
шляхом виконання дій на сайті у системі
Infocoin і перерахування коштів на відповідний
рахунок компанії </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.
Такі зобов’язання Агента вважаються
виконаними в повному обсязі після
надання Агентом звіту за відповідний
період або звіту про виконання зобов'язань
по окремим зробленим Агентом угодам;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
не укладати аналогічних договорів з
іншими особами;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
повідомляти Агентові про наявні
заперечення по представленому звіту
протягом&nbsp; 5 (п’яти) днів після його
подання. У випадку якщо в зазначений
строк заперечення не будуть представлені
звіт вважається прийнятим Принципалом.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.2.
Принципал вправі:</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>-
</b></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">до
початку трансляції користувацького
контенту звернутись до служби підтримки
за посиланням </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u><a href="https://ads.infocoin.online/support">https://business.infocoin.online/support</a>
</u></font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">із
заявою про повернення грошових коштів,
зарезервованих під трансляцію відповідного
ролику;</font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
після завершення періоду трансляції
ролику (після сливу 24 годин), звернутись
до служби підтримки</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>
</b></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">за
посиланням </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u><a href="https://ads.infocoin.online/support">https://business.infocoin.online/support</a>
</u></font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">з
проханням повернути залишок несписаної
суми, яка зарезервована під трансляцію
відповідного ролика.</font></font></p>
<p style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.3.
Агент зобов'язаний:</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
надавати Принципалу звіти в міру
виконання Договору, але не рідше одного
разу на місяць. До звіту Агента повинні
бути прикладені необхідні докази витрат.
В</font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">сі
дані, що знаходяться в особистому
кабінеті Принципала, а також звіти, що
надсилатимуться на електронну пошту
Принципала є достатніми доказами витрати
коштів та надання послуг</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
виконувати вказівки Принципала, що
стосуються вчинених Агентом угод і
інших зобов’язань, якщо ці вказівки не
суперечать вимогам закону;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
у разі звернення Принципала з причин,
визначених п. 3.2. цього Договору, впродовж
72 годин розглянути та повідомити про
результати розгляду такого звернення
шляхом надсилання листа на електронну
пошту Принципала. </font></font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
виконувати всі зобов’язання сумлінно.</font></font></font></p>
<p style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.4.
Агент вправі:</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
з метою виконання договору укласти
субагентский договір з іншою особою,
залишаючись відповідальним за дії
субагента перед Принципалом, якщо
сторони не домовляться про інше. Субагент
не вправі укладати із третіми особами
угоди від імені особи, що є Принципалом
за агентським договором, за винятком
випадків, прямо передбачених субагентским
договором. Агент зобов'язаний погодити
умови субагентского договору із
Принципалом;</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">-
односторонньо розірвати даний Договір
у випадках порушення Принципалом Правил
розміщення контенту, цього Договору,
законів Республіки Кіпр, підтримки дій
Російської Федерації, заперечення
агресії проти України.</font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in;">
<br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>4.
Дія непереборної сили</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.1.
Жодна із сторін не несе відповідальності
перед іншою стороною за затримку або
невиконання зобов'язань, обумовлені
обставинами непереборної сили, що
виникли незалежно від волі й бажання
сторін і які не можна було передбачати
або уникнути, включаючи оголошену або
фактичну війну, громадські хвилювання,
епідемії, блокаду, ембарго, а також
землетрусу, повені, пожежі й інші стихійні
лиха.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.2.
Сторона яка не може виконати своїх
зобов'язань внаслідок дії непереборної
сили зобов'язана негайно сповістити
іншу сторону про зазначені обставини.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.3.
У зв'язку з виниклими обставинами
непереборної сили сторони повинні
підписати протокол про припинення дії
даного Договору або погодити спільні
дії по подоланню несприятливих наслідків
зазначених обставин.</font></font></font></p>
<p align="center" style="line-height: 100%; margin-left: -0.59in; margin-bottom: 0in">
<br/>

</p>
<p align="center" style="line-height: 100%; margin-left: -0.59in; margin-bottom: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>5.
Скасування розміщення користувацького
контенту </b></font></font></font>
</p>
<p align="center" style="line-height: 100%; margin-left: -0.59in; margin-bottom: 0in">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>та
повернення грошових коштів</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.1.
Якщо іншого не передбачено в Правилах
або Договорі (якщо такий є) або іншому
он-лайн інтерфейсі, що посилається на
Умови розміщення контенту або цей
Договір, будь-яка Сторона може в будь-який
час скасувати будь-яке розміщення
користувацького контенту до моменту
розміщення. Принципал може скасувати
розміщення користувацького контенту
завчасного надавши </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
письмове повідомлення, в тому числі, не
обмежуючись, електронний лист, звернувшись
до служби підтримки за посиланням
</font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u><a href="https://ads.infocoin.online/support">https://business.infocoin.online/support</a>.</u></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><a name="_heading=h.gjdgxs"></a>
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.2.
Розміщення користувацького контенту
припиняється невдовзі після скасування.
Будь-які інші скасовані розміщення
користувацького контенту можуть
публікуватися незважаючи на те, що було
виконано всі дії для їх скасування,
насамперед через брак часу для реакції
з боку Агента, якщо такі оголошення
опубліковуються і мають перегляди, а
відповідно й виникають зобов’язання
виплати з боку Агента, Принципал має
сплатити на користь </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
за такий опублікований користувацький
контент. </font></font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.3.
У разі прийняття рішення про повернення
грошових коштів за заявою Принципала,
відповідно до п.п.3.2. цього Договору,
Агент надсилає на електронну пошту
Принципала акт звіряння взаєморозрахунків.
Зазначений акт вважається узгодженим
Принципалом у разі: і) підписанням
електронним цифровим підписом; іі)
здійснення необхідних дій у особистому
кабінеті ііі) надсиланням у відповідь
підписаної скан-копії такого акту.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.4.
Після узгодження акту звіряння
взаєморозрахунків, Агент шляхом
перерахування грошових коштів на рахунок
Принципала, з якого було здійснено
відповідну оплату,  здійснює повернення
грошових коштів Принципалу. Всі витрати,
пов’язані з перерахуванням грошових
коштів сплачуються за рахунок Принципала.
</font></font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.5.
Повернення грошових коштів Агентом
принципалу здійснюється впродовж 60
календарних днів. На час повернення
грошових коштів впливає швидкість
обробки транзакцій відповідними банками
та/або платіжними системами, й у зв’язку
з цим, строк повернення грошових коштів
може бути збільшений.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.6.
Заявки на повернення грошових коштів
під час трансляції ролику не приймаються.</font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>6.Заяви
та гарантії</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.1.
Принципал заявляє та гарантує, що (i) вся
інформація, надана Принципалом для
</font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">,
є повною, точною і оновленою; (ii) він має
всі необхідні права, щоб надати дозвіл
і цим надає </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
і будь-яким Партнерам всі такі права,
що необхідні для </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
та для будь-якого Партнера (Партнерів)
(в залежності від випадку) на використання,
хостинг, розміщення в кеш-пам'яті,
відстеження, зберігання, копіювання,
зміну, розповсюдження, зміну формату,
відтворення, оприлюднення, демонстрацію,
передачу та поширення користувацького
контенту Принципала (включаючи будь-які
Контекстні об'єкти) («Використання
Контенту»); і (iii) Використання Контенту
у відповідності до цього Договору, а
також веб-сайтів та/або цільових сторінок,
на які виходять канали передачі з
користувацького контенту Принципала
не повинно: (a) порушувати або заохочувати
порушення будь-якого законодавства або
чинних регуляторних або процесуальних
норм; (б) порушувати будь-які права
інтелектуальної власності будь-якої
третьої особи або містити будь-який
матеріал, який може завдати шкоди, бути
образливим, непристойним, погрожуючим
або таким, що принижує гідність.</font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>7.
Конфіденційність</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.1.
Відповідно до цього Договору нижче на
строк дії цього Договору та впродовж
двох років після його припинення Сторони
зобов'язуються не розкривати Конфіденційну
інформацію іншої Сторони третім особам
без попередньої письмової (включаючи
переданої по електронній пошті) згоди
за винятком випадків, передбачених цим
Договором. Принципал несе виключну
відповідальність за використання
третіми особами будь-якої звітності
Принципала в режимі он-лайн. «Конфіденційна
Інформація» включає в себе (i) паролі
Принципала; (ii) користувацький контент
(у тому числі Контекстні об'єкти) до
моменту публікації; та (iv) будь-яку іншу
Програмну інформацію або доступ до
технології перед їх публічним
оприлюдненням, що були надані з боку
</font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
для Принципала і визначені на момент
розголошення в письмовій формі як
конфіденційного та/або службового
характеру; (v) характерні риси Програми,
які не є публічними і визначаються як
«Бета» або «Пробний контент». Ця
Конфіденційна інформація не включає в
себе інформацію, яка стала загальновідомою
не через порушення з боку будь-якої
Сторони або (а) яка була самостійно
розроблена без доступу до Конфіденційної
інформації іншої Сторони; (b) законно
отримана від третьої особи або (с) повинна
бути розголошена відповідно до закону
або вимоги органу державної влади. </font></font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.2.
Infocoin може надавати Конфіденційну
інформацію Принципала: (i) будь-якій
третій особі, що розмішує контент, або
відповідному Принципалу в разі
необхідності; або (ii) будь-якій холдинговій
компанії Принципала або будь-якому
дочірньому підприємству Принципала
або будь-якому дочірньому підприємству
холдингової компанії Принципала.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.3.
Принципал визнає й погоджується з тим,
що будь-яка інформація, що стосується
будь-якого рахунку, кредитної картки,
виставляння та оплати рахунків, яку
Принципал надає </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">,
може бути передана з боку </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
компаніям, які працюють за дорученням
</font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">,
винятково для цілей здійснення перевірки
залишку на рахунках, здійснення платежів
на користь </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
і/або для обслуговування рахунку
Принципала.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.4.
Цей розділ договору не є вичерпним і
відсилає до Політики Конфіденційності,
якою керуємось ми, і ви приймаєте те, що
ви маєте також нею керуватись у взаємодії
з нами.</font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>8.
Строк і припинення дії</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.1.
Перебіг строку дії цього Договору
розпочинається з Дати підписання (в
тому числі он-лайн шляхом виконання дій
на сайті) і триває, поки не буде припинений
Договір або поки не настане Дата
закінчення строку договору. За загальним
правилом договір триває 1 календарний
рік. Принципал, за умови отримання
попереднього погодження від </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
електронною поштою, може подовжити дію
цього Договору шляхом надання повідомлення
електронною поштою, або шляхом виконання
дій он-лайн на нашому сайті може продовжити
строк дії даного договору на наступний
строк у 1 календарний рік, або на такий
інший період, який може бути погоджений
</font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
в письмовій формі.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.2.
Ці Умови розглядаються як пропозиція
укласти Договір у відповідності до
законодавства Республіки Кіпр. Договір
є укладеним в момент прийняття Умов в
режимі он-лайн, і який розглядається як
Дата Прийняття, якщо інша Дата Прийняття
не визначена в Договорі про надання
послуг (якщо такий є).</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.3.
Цей Договір укладений в письмовій формі
шляхом обміну електронними повідомленнями
між Сторонами, за умови відсутності
Договору про надання послуг, який
укладено раніше. За запитом будь-якої
Сторони, Сторони можуть підготувати
паперову копію Договору. Для цього
паперова копія буде датована Датою
Прийняття, місцем вчинення буде вважатись
місто за місцезнаходженням </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.4.
Для цілей застосовного законодавства
про захист прав споживачів цей Договір,
що є доступним в режими он-лайн перед
укладанням Договору, розглядається як
інформація про послуги, що надаються
</font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
та/або Infocoin на відстані.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.5.
Цей Договір може бути розірваний з
невідкладними наслідками з боку будь-якої
Сторони шляхом надання письмового
повідомлення (у тому числі без обмежень
включаючи повідомлення електронною
поштою) іншій Стороні.</font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>9.
Наслідки припинення та анулювання
розміщення контенту</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">9.1.
У разі спливу строку дії або розірвання
цього Договору: (i) у разі створення
Принципалом нових кампаній або якщо
він будь-яким іншим чином продовжує
використовувати Програму, він надалі
залишається зобов'язаним за зобов'язаннями,
що передбачені цим Договором так само,
коли б строк дії цього Договору не
закінчився або коли б його не було б
розірвано (у тому числі без обмежень
включаючи зобов'язання щодо сплати
нарахувань, які були понесені у зв'язку
з Програмою); (ii) поки Принципал не скасує
свої розміщення користувацького
контенту, Принципал залишається надалі
зобов'язаним за зобов'язаннями, що
передбачені цим Договором, а також
сплачувати нарахування, що були понесені
у зв'язку з такими; (iii) Принципал надалі
несе відповідальність за сплату будь-яких
сум, що залишаються несплаченими станом
на дату закінчення строку дії або
розірвання; (iv) кожна Сторона на вимогу
повертає іншій стороні Конфіденційну
інформацію; а також (v) </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
на свій власний розсуд може заблокувати
або видалити будь-який рахунок Принципала
в Програмі. </font></font></font>
</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>10.
Відступлення</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">10.1.
Сторони мають право відступати або
будь-яким іншим чином передавати свої
права та/або зобов'язання відповідно
до цього Договору лише з письмової згоди
іншої Сторони (така згода не може бути
відхилена або затримана без достатніх
для цього підстав), за винятком того, що
</font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
має право відступити свої права та/або
зобов'язання відповідно до цього Договору
третій особі. Підписанням цього договору
Принципал погоджується на відступлення
</font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
своїх права та/або зобов'язань, яке може
виникнути. </font></font></font>
</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>11.
Порядок вирішення спорів. Відповідальність
сторін</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.1.
Усі суперечки або розбіжності, що
виникають між сторонами за даним
договором або у зв'язку з ним, вирішуються
шляхом переговорів.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.2.
У випадку неможливості вирішення
розбіжностей шляхом переговорів між
сторонами, суперечки підлягають розгляду
у відповідному суді, згідно норм діючого
законодавства.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.3.
При порушенні умов даного Договору
сторона, що порушила, зобов'язана
відшкодувати іншій стороні заподіяні
збитки, включаючи упущену вигоду.</font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in;">
<br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>12.
Інші положення</b></font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.1.
Цей Договір може бути укладений по
відношенню до кількох компаній з
розміщення. По відношенню до однієї
кампанії з розміщення Сторони можуть
укласти декілька послідовних Договорів,
включаючи як результат повне використання
Бюджету за Договором.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.2.
Сторони погоджуються, що інформація з
панелі управління або з облікового
запису он-лайн системи Infocoin, у письмовому
вигляді завірена </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
та/або Infocoin, є належним доказом укладення
або зміни умов Договору.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.3.
Надаючи персональні відомості в рамках
цього Договору, Ви погоджуєтесь на їх
обробку Infocoin у відповідності до Правил
Конфіденційності, що знаходяться за
адресою: </font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>(https://www.infocoin.online/docs/</u></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">).</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.4.
У цьому Договорі викладено всі умови,
погоджені між Сторонами стосовно його
предмета, і він заміщує собою всі
попередні договори, заяви та будь-якого
характеру домовленості між Сторонами
щодо його предмета.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.5.
Правила в будь-який час можуть бути
змінені </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Infocoin
Limited</font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
в односторонньому порядку з метою
відображення змін в тому, як Infocoin
забезпечує загальну комерційну
доцільність Програми. Жодне доповнення
або зміна положень цього Договору не
має зобов'язального характеру для Сторін
до підтвердження у системі он-лайн або
якщо їх не було оформлено письмовим
документом, який був чітко погоджений
сторонами в письмовій формі і в
двосторонньому порядку (у тому числі
без обмежень включаючи шляхом кліку
мишкою та акцепту або обміну електронним
повідомленнями).</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.6.
Жодне нездійснення та жодна затримка
у здійсненні будь-якого права або засобу
правового захисту відповідно до цього
Договору не вважатимуться відмовою від
такого права або засобу правового
захисту, а жодне разове або часткове
здійснення будь-якого права або засобу
правового захисту відповідно до цього
Договору не позбавлятиме можливості
будь-якого наступного здійснення права
або засобу правового захисту або
реалізації будь-якого іншого права або
засобу правового захисту. Права та
засоби правового захисту, передбачені
цим Договором, є кумулятивними й не
виключають можливості застосування
будь-яких прав або засобів правового
захисту, що передбачені законом.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.7.
Недійсність, незаконність або непридатність
для звернення до виконання будь-якого
положення цього Договору не тягне за
собою недійсності або не впливає на
подальшу юридичну силу будь-яких інших
положень цього Договору.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.8.
Жодне положення цього Договору не
тлумачиться як таке, що створює партнерство
(просте товариство) або спільне
підприємство будь-якого виду між
Сторонами Договору або не передбачає
виникнення між Сторонами агентських
або представницьких відносин для
досягнення будь-якої цілі будь-якого
характеру. При цьому жодна зі Сторін не
має права та повноважень пов'язувати
іншу Сторону зобов'язаннями або вступати
в договірні відносини від імені такої
Сторони чи створювати зобов'язання
перед третьою особою будь-яким чином
та для будь-якої потреби.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.9.
Якщо в цьому Договорі не обмовляється
інше, всі повідомлення повинні направлятися
на адреси, що зазначені у цьому Договорі
(або в системі он-лайн), з копією для
юридичного відділу шляхом (і) відправлення
факсимільним зв'язком з підтвердженням
одержання за умови надсилання копії
поштовим відправленням першого класу
або авіапоштою; (іі) відправлення
кур'єрськими службами, причому такі
повідомлення вважаються наданими в
момент їх отримання; або (ііі) електронною
поштою. </font></font></font>
</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.10.
Агент є платником податку на прибуток
підприємств згідно з нормами законодавства
Республіки Кіпр.</font></font></font></p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in;">
<br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in;">
<font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>13.
Юридична адреса й реквізити Агента</b></font></font></font></p>
<p align="center" style="line-height: 100%; margin-bottom: 0in;">
<br/>

</p>
<p align="center" style="line-height: 100%; margin-bottom: 0in;">
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>INFOCOIN
LIMITED</b></font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Anexartisias
&amp; Athinon, </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">NORA
COURT, 2nd floor, Office 203, 3040, Limassol, Cyprus</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">e-mail:
info@infocoin.online</font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Міхаліс
Михайло</font></font></font></p>
<p align="center" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Директор.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
</body>
</html>
`;
