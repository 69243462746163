import { useState, memo } from "react";
import { Container } from "react-bootstrap";
import "react-accessible-accordion/dist/fancy-example.css";
import "@styles/components/Support.scss";
import ModalDocuments from "@components/ModalDocuments";

import AskQuestions from "./components/AskQuestions";

import classes from "./Support.module.scss";
import clsx from "clsx";

import FaqMessage from "./components/FaqMessage";

import { withTranslation } from "react-i18next";

import { Typography } from "../../views/blocks";
import Button from "@components/Button";

import { Grid } from "@mui/material";

const messages = [
  {
    title: "What is IC",
    text: `IC is accrued to the Consumer of information while viewing information content in the application, a field confirming successful viewing.`,
    id: 1,
  },
  {
    title: "How many advertising campaigns can be run on the platform",
    text: `Unlimited amount.`,
    id: 2,
  },
  {
    title: "How long does an advertising campaign start after payment",
    text: `After paying for the “Lot” at the auction, your informational content will appear the next day from 00:00 to 23:59.`,
    id: 3,
  },
  {
    title: "Are get any funds for watching a video in the “History” section",
    text: `Information content that you have viewed is automatically placed in the “History” section, where you can view it again, interact with it or send a complaint. IC is not awarded for watching a video in the “History” section, as this is considered a repeated viewing, and IC is accrued only for one unique view.`,
    id: 4,
  },
];

const Support = ({ t }) => {
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);

  return (
    <Container className={clsx(classes.support)}>
      <h4 className="support-title">{t("Support")}</h4>
      <Grid mb={2}>
        <Typography variant="body2" weight="normal-bold">
          {t(
            "We sincerely appreciate your questions, feedback and suggestions. Your message will be forwarded to customer support. We will definitely answer you as soon as possible.",
          )}
        </Typography>
      </Grid>
      <Grid mb={2}>
        <Typography variant="body2" weight="normal-bold">
          {t("Send us an email")}:
        </Typography>
        <Typography
          className="text-decoration-underline"
          variant="body2"
          color="primary"
        >
          help@infocoin.online
        </Typography>
      </Grid>
      <div className="d-flex flex-column">
        {messages.map(mess => (
          <FaqMessage key={mess.id} title={t(mess.title)}>
            <Typography variant="body1" color="gray">
              {t(mess.text)}
            </Typography>
          </FaqMessage>
        ))}
        <FaqMessage title="Terms of Use, Privacy Policy and Public Agreement">
          <Grid display="flex">
            <Button
              className={classes.button}
              onClick={() => setOpenPrivacyModal(true)}
            >
              {t("Open documents")}
            </Button>
          </Grid>
        </FaqMessage>

        {/*<FaqMessage title="Ask Questions">*/}
        {/*  <AskQuestions />*/}
        {/*</FaqMessage>*/}
      </div>
      <ModalDocuments
        isSupport
        show={openPrivacyModal}
        setShow={setOpenPrivacyModal}
      />
    </Container>
  );
};

export default withTranslation()(memo(Support));
