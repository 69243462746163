import React, { memo, useCallback, useMemo } from "react";
import ReactSelect, { components } from "react-select";

/* styles */
import "./Select.scss";

import { ArrowDropDown } from "@mui/icons-material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const { DropdownIndicator } = components;

const Select = ({
  value,
  onChange,
  error,
  name,
  options,
  touched,
  warningLabel = false,
  isSearchable = true,
  isMulti = false,
  disabled,
  onBlur,
  placeholder,
  className,
  containerClassName,
  label,
  translateOptions = true,
  littleLabel,
  ...props
}) => {
  const { t } = useTranslation();

  const handleChange = value => {
    if (isMulti) {
      onChange(value);
    } else {
      onChange({ target: { value: value.value, name: name }, name });
    }
  };

  const selectOptions = useMemo(
    () =>
      options.map(option => ({
        ...option,
        label:
          translateOptions && option.label ? t(option.label) : option.label,
      })),
    [options, t, translateOptions],
  );

  const handleBlur = useCallback(
    e => {
      if (onBlur) {
        onBlur({
          ...e,
          target: {
            ...e.target,
            name,
          },
        });
      }
    },
    [onBlur, name],
  );

  return (
    <div className={containerClassName}>
      {label && (
        <div
          className={clsx(
            "label",
            littleLabel && "little-label",
            warningLabel && "warning-label",
          )}
        >
          {t(label)}
        </div>
      )}
      <ReactSelect
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: props => (
            <DropdownIndicator {...props}>
              <ArrowDropDown fill={"grey"} />
            </DropdownIndicator>
          ),
        }}
        id={name}
        isMulti={isMulti}
        name={name}
        isClearable={false}
        isSearchable={isSearchable}
        options={selectOptions}
        isDisabled={disabled}
        placeholder={placeholder ? t(placeholder) : t("Select")}
        value={selectOptions.find(option => option.value === value) || value}
        onChange={handleChange}
        onBlur={handleBlur}
        className={clsx("react-select", className)}
        classNamePrefix="select"
        {...props}
      />
      {touched && error && (
        <div
          className="ml-1"
          style={{
            color: "red",
            fontSize: "12px",
            marginTop: "3px",
            fontWeight: 500,
          }}
        >
          {t(error)}
        </div>
      )}
    </div>
  );
};

export default memo(Select);
