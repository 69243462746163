import React, { memo, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import { Grid, IconButton } from "@mui/material";

import s from "../index.module.scss";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Typography } from "../../../views/blocks";
import ResetPasswordEmailForm from "@components/Forms/ResetPassword/components/email";
import ResetPasswordCodeForm from "@components/Forms/ResetPassword/components/code";
import ResetPasswordPasswordForm from "@components/Forms/ResetPassword/components/password";

import { resetUserApi, setNewPasswordApi } from "@services/api/auth";

import { toast } from "react-toastify";

const initialValues = {
  code: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const ResetPassword = ({ setStage, STAGES }) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(0);

  const [data, setData] = useState(initialValues);

  const [resubmissions, setResubmissions] = useState(3);

  const sendingCode = useCallback(
    async email => {
      if (resubmissions !== 0) {
        return await resetUserApi({ email: email || data.email }).then(() =>
          setResubmissions(quantity => quantity - 1),
        );
      }
      throw new Error("The number of code resubmissions is limited!");
    },
    [resetUserApi, resubmissions],
  );

  const handleSubmit = useCallback(
    async (values, initialStep) => {
      setData(prev => ({ ...prev, ...values }));

      try {
        if (initialStep === 0) {
          await sendingCode(values.email);

          toast.warning(t("Check your email!"));
        }

        if (initialStep === 2) {
          await setNewPasswordApi({ ...data, ...values });

          toast.success(t("Password changed successfully"));

          return setStage(STAGES.SIGN_IN);
        }

        setStep(initialStep + 1);
      } catch (error) {
        if (initialStep === 2) {
          toast.error(t("Invalid Code"));

          setData(prev => ({ ...prev, code: "" }));

          return setStep(1);
        }

        return toast.error(
          t(error?.response?.data?.error || error?.message || error),
        );
      }
    },
    [t, sendingCode, setNewPasswordApi, data],
  );

  const handleBack = useCallback(() => {
    if (step === 0) {
      return setStage(STAGES.SIGN_IN);
    }
    setStep(prev => prev - 1);
  }, [step]);

  return (
    <Grid className={s.form}>
      {step !== 2 ? (
        <Grid item className={s.back}>
          <IconButton onClick={handleBack} className={s.back__button}>
            <ArrowBackIosNewIcon htmlColor="var(--primary-color)" />
          </IconButton>
          <Typography variant="h2" bold>
            {t("Reset password")}
          </Typography>
        </Grid>
      ) : (
        <Grid item>
          <Typography variant="h2" bold>
            {t("Create new password")}
          </Typography>
        </Grid>
      )}
      {step === 0 && (
        <ResetPasswordEmailForm
          onSubmit={handleSubmit}
          defaultEmail={data.email}
        />
      )}
      {step === 1 && (
        <ResetPasswordCodeForm
          defaultCode={data.code}
          onSubmit={handleSubmit}
          sendingCode={sendingCode}
        />
      )}
      {step === 2 && (
        <ResetPasswordPasswordForm
          onSubmit={handleSubmit}
          defaultValue={{
            password: data.password,
            confirmPassword: data.confirmPassword,
          }}
        />
      )}
    </Grid>
  );
};

export default memo(ResetPassword);
