import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "../../../../views/blocks/DataTable";
import { RenderField, VideoField } from "../../../../views/fields";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  pastLotStatisticSelector,
  pastStatisticSelector,
} from "@redux/dashboard/selectors";
import {
  getPastLotStatisticAction,
  getPastStatisticAction,
} from "@redux/dashboard/dashboardActions";
import classes from "./PastStatistic.module.scss";
import PieStatistic from "@pages/Dashboard/components/PieStatistic";
import { useTranslation } from "react-i18next";
import PeopleStatistic from "@pages/Dashboard/components/PeopleStatistic";
import StatItem from "@pages/Dashboard/components/PastStatistic/components/StatItem";
import { ReactComponent as Bet } from "src/assets/icons/auction-bet.svg";
import { ReactComponent as Like } from "src/assets/icons/like.svg";
import { ReactComponent as Ctr } from "src/assets/icons/ctr.svg";
//import { ReactComponent as Alert } from "src/assets/icons/alert-line.svg";
//import { ReactComponent as Play } from "src/assets/icons/play.svg";
import Loader from "@components/Loader";
import { countriesSelector } from "@redux/language/selectors";
import { getCountriesAction } from "@redux/language/actions";
import { ReactComponent as Interaction } from "@assets/icons/interactions.svg";
import RangeDayPicker from "@pages/Dashboard/components/RangeDayPicker";
import { Tooltip } from "@mui/material";
import { createAgeData, createTimeData } from "@utils/statisticData";
import clsx from "clsx";
import Button from "@components/Button";
import CountrySelect from "@components/Form/CountrySelect";
import Dropdown from "@components/Dropdown";
import { sortOptions } from "@pages/Dashboard/constants";
import MobilePastView from "@pages/Dashboard/components/PastStatistic/components/MobilePastView";

const PastStatistic = () => {
  const dispatch = useDispatch();

  const { data, count } = useSelector(pastStatisticSelector, shallowEqual);

  const { t } = useTranslation();

  const [dates, setDates] = useState({
    startDate: new Date(moment(new Date()).subtract(30, "d").toISOString()),
    endDate: new Date(moment(new Date()).subtract(2, "d").toISOString()),
    key: "selection",
  });

  const [loading, setLoading] = useState(true);

  const [selectedLots, setSelectedLots] = useState([]);

  const [statisticLoading, setStatisticLoading] = useState(false);

  const [countryFilter, setCountryFilter] = useState(undefined);

  const countries = useSelector(countriesSelector, shallowEqual);

  const [limit, setLimit] = useState(30);

  const handleDateRangeChange = useCallback(
    date => {
      setStatisticLoading(true);
      setSelectedLots([]);

      const startTime = moment(date.selection.startDate).add(3, "hour");
      const endTime = moment(date.selection.endDate).add(3, "hour");

      setDates({
        startDate: startTime.toDate(),
        endDate: endTime.toDate(),
        key: "selection",
      });

      dispatch(
        getPastStatisticAction({
          limit: limit === "full" ? count : limit,
          offset: 0,
          startTime: startTime.toDate().toISOString(),
          endTime: endTime.toDate().toISOString(),
          country: countryFilter,
        }),
      ).finally(() => {
        setStatisticLoading(false);
      });
    },
    [dispatch],
  );

  useEffect(() => {
    setLoading(true);

    if (!countries.length) {
      dispatch(getCountriesAction()).catch(() => {});
    }

    const startDateString = new Date(
      moment(new Date()).subtract(30, "day").toISOString(),
    ).toISOString();

    const endDateString = new Date(
      moment(new Date()).subtract(2, "day").toISOString(),
    ).toISOString();

    dispatch(
      getPastStatisticAction({
        limit,
        offset: 0,
        startTime: startDateString,
        endTime: endDateString,
      }),
    )
      .then(lots => {
        if (lots.length > 0) {
          setSelectedLots([lots[0].id]);

          dispatch(
            getPastLotStatisticAction({
              id: [lots[0].id],
              startTime: startDateString,
              endTime: endDateString,
              geo: "region",
            }),
          )
            .then(() => {
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      }); //eslint-disable-next-line
  }, []);

  const lot = useSelector(pastLotStatisticSelector, shallowEqual);

  const videos = useMemo(() => {
    if (lot.id) {
      const res = [];
      lot.id.forEach(id => {
        res.push(data.find(l => Number(l.id) === Number(id)));
      });
      return res;
    } else return [];
  }, [data, lot.id]);

  const country = useMemo(() => {
    if (videos[0]?.id && countries.length) {
      return countries.find(c => c.name === videos[0].auction.country);
    }
    return {};
  }, [countries, videos]);

  const ageData = useMemo(
    () => createAgeData(lot.age_audience),
    [lot.age_audience],
  );
  const timeData = useMemo(
    () => createTimeData(lot.views_by_time),
    [lot.views_by_time],
  );

  const onLotSelect = useCallback(
    id => {
      setSelectedLots(id);
      if (id.length > 0) {
        setStatisticLoading(true);

        const startDateString = moment(dates.startDate).toISOString();

        const endDateString = moment(dates.endDate).toISOString();

        dispatch(
          getPastLotStatisticAction({
            id: id,
            startTime: new Date(startDateString).toISOString(),
            endTime: new Date(endDateString).toISOString(),
            geo:
              id.length > 1 ? (countryFilter ? "region" : "country") : "region",
          }),
        )
          .then(() => {
            setStatisticLoading(false);
          })
          .catch(() => {
            setStatisticLoading(false);
          });
      }
    },
    [countryFilter, dates.endDate, dates.startDate, dispatch],
  );

  const onSortChange = useCallback(
    limit => {
      setLimit(limit);
      setLoading(true);

      const startTime = moment(dates.startDate);
      const endTime = moment(dates.endDate);

      dispatch(
        getPastStatisticAction({
          limit: limit === "full" ? count : limit,
          offset: 0,
          startTime: startTime.toDate().toISOString(),
          endTime: endTime.toDate().toISOString(),
          country: countryFilter,
        }),
      ).then(() => {
        setLoading(false);
      });
    },
    [count, countryFilter, dates.endDate, dates.startDate, dispatch],
  );
  const handleRangeDaysClick = days => () => {
    setLoading(true);
    setSelectedLots([]);
    setDates({
      startDate:
        days === 365
          ? new Date(moment().subtract(1, "year"))
          : new Date(moment().subtract(days, "days")),
      endDate: new Date(moment().subtract(2, "day")),
      key: "selection",
    });

    const startDateString =
      days === 365
        ? new Date(moment().subtract(1, "year")).toISOString()
        : new Date(moment().subtract(days, "days")).toISOString();

    const endDateString = new Date(moment().subtract(2, "day")).toISOString();

    dispatch(
      getPastStatisticAction({
        startTime: startDateString,
        endTime: endDateString,
        limit: limit === "full" ? count : limit,
        country: countryFilter,
      }),
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const onCountryChange = useCallback(
    country => {
      setCountryFilter(country?.name ? country?.name : null);
      setLoading(true);
      setSelectedLots([]);

      const startTime = moment(dates.startDate);
      const endTime = moment(dates.endDate);

      dispatch(
        getPastStatisticAction({
          limit: limit === "full" ? count : limit,
          offset: 0,
          startTime: startTime.toDate().toISOString(),
          endTime: endTime.toDate().toISOString(),
          country: country?.name ? country?.name : undefined,
        }),
      ).then(() => {
        setLoading(false);
      });
    },
    [count, dates.endDate, dates.startDate, dispatch, limit],
  );

  return loading ? (
    <Loader width={150} height={150} isPageLoader />
  ) : (
    <div>
      <div className={clsx(classes.mobile, classes.rangeContainer)}>
        <div className={classes.dayButtons}>
          <div className={classes.periodText}>
            <div
              style={{
                marginRight: "5px",
              }}
            >
              {t("Select period")}
            </div>{" "}
            <div>:</div>
          </div>
          <Button
            containerClassName={classes.rangeButton}
            className={classes.rangeInner}
            onClick={handleRangeDaysClick(8)}
          >
            {t("In_n_days_count", { count: 7 })}
          </Button>
          <Button
            containerClassName={classes.rangeButton}
            className={classes.rangeInner}
            onClick={handleRangeDaysClick(31)}
          >
            {t("In_n_days_count", { count: 30 })}
          </Button>
          <Button
            containerClassName={classes.rangeButton}
            className={classes.rangeInner}
            onClick={handleRangeDaysClick(61)}
          >
            {t("In_n_days_count", { count: 60 })}
          </Button>
          <Button
            containerClassName={classes.rangeButton}
            className={classes.rangeInner}
            onClick={handleRangeDaysClick(365)}
          >
            {t("For year")}
          </Button>
        </div>
        <RangeDayPicker
          value={dates}
          onChange={handleDateRangeChange}
          maxDate={new Date(moment().subtract(2, "d").toISOString())}
        />
      </div>
      <div className={classes.sortContainer}>
        <CountrySelect
          containerClassName={classes.countrySelect}
          withNameInValue
          onChange={onCountryChange}
          value={countryFilter}
          isClearable
        />
        <Dropdown value={limit} onSelect={onSortChange} options={sortOptions} />
      </div>
      <div className={classes.mobile}>
        <MobilePastView
          lots={data}
          onSelect={onLotSelect}
          selected={selectedLots}
        />
      </div>
      <div className={classes.desktop}>
        <DataTable
          bulkSelect
          selectable
          selected={selectedLots}
          onSelect={onLotSelect}
          items={data}
        >
          <RenderField
            render={(record, id = "") => {
              return (
                <div className="d-flex firstRow">
                  <div>{id}</div>
                </div>
              );
            }}
            align="center"
            label="Id"
            name="id"
          />
          <RenderField
            render={(record, playlistNumber = "") => {
              return <div>{playlistNumber}</div>;
            }}
            align="center"
            label="Place"
            name="playlist_number"
          />
          <RenderField
            render={record => {
              return record.video.title;
            }}
            align="center"
            label="Video title"
            name="video.title"
          />
          <VideoField
            isAuction={false}
            align="center"
            label="Preview"
            titleName="Video"
            name="video.filepath_480_p"
          />
          <RenderField
            render={record => {
              return record.auction.country;
            }}
            align="center"
            label="Country"
            name="auction.country"
          />
          <RenderField
            render={record => {
              return moment(record.updated_at).format("DD.MM.YYYY");
            }}
            align="center"
            label="Date of change"
            name="updated_at"
          />
          <RenderField
            render={record => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "23px 13px",
                  }}
                >
                  <Tooltip title={t("Expected views")}>
                    <div>
                      {record.expect_views < 1000
                        ? record.expect_views
                        : `${record.expect_views / 1000}K`}
                    </div>
                  </Tooltip>
                  <Tooltip title={t("Views")}>
                    <div style={{ marginLeft: "27px" }}>
                      {record.views < 1000
                        ? record.views
                        : `${record.views / 1000}K`}
                    </div>
                  </Tooltip>
                  <Tooltip title={t("Remaining views")}>
                    <div style={{ marginLeft: "27px" }}>
                      {record.reserve_views < 1000
                        ? record.reserve_views
                        : `${record.reserve_views / 1000}K`}
                    </div>
                  </Tooltip>
                </div>
              );
            }}
            isDashed
            align="center"
            label="Displays"
            name="views_in_prev_auction"
          />
        </DataTable>
      </div>

      <div className={classes.firstContainer}>
        <div className={classes.videoStatisticContainer}>
          <StatItem
            statistic={lot.max_auction_amount}
            label={t("First place bet")}
            Icon={Bet}
          />
          <StatItem
            statistic={lot.click_through_rate}
            label={"CTR"}
            Icon={Ctr}
          />
          <StatItem statistic={lot.likes_count} label={t("Like")} Icon={Like} />
          <StatItem
            statistic={
              lot.interactions_email_count + lot.interactions_url_count
            }
            label={t("Interaction")}
            Icon={Interaction}
          />
        </div>
        <div className={clsx(classes.desktop, classes.rangeContainer)}>
          <div className={classes.dayButtons}>
            <div className={classes.periodText}>
              <div
                style={{
                  marginRight: "5px",
                }}
              >
                {t("Select period")}
              </div>{" "}
              <div>:</div>
            </div>
            <Button
              containerClassName={classes.rangeButton}
              className={classes.rangeInner}
              onClick={handleRangeDaysClick(8)}
            >
              {t("In_n_days_count", { count: 7 })}
            </Button>
            <Button
              containerClassName={classes.rangeButton}
              className={classes.rangeInner}
              onClick={handleRangeDaysClick(31)}
            >
              {t("In_n_days_count", { count: 30 })}
            </Button>
            <Button
              containerClassName={classes.rangeButton}
              className={classes.rangeInner}
              onClick={handleRangeDaysClick(61)}
            >
              {t("In_n_days_count", { count: 60 })}
            </Button>
            <Button
              containerClassName={classes.rangeButton}
              className={classes.rangeInner}
              onClick={handleRangeDaysClick(365)}
            >
              {t("For year")}
            </Button>
          </div>
          <RangeDayPicker
            value={dates}
            onChange={handleDateRangeChange}
            maxDate={new Date(moment().subtract(2, "d").toISOString())}
          />
        </div>
      </div>
      {statisticLoading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.piesContainer}>
            {lot.audience && (
              <PieStatistic
                label={t("Gender")}
                data={[
                  { label: t("Men"), value: Math.round(lot.audience.male) },
                  { label: t("Women"), value: Math.round(lot.audience.female) },
                  {
                    label: t("Not binary"),
                    value: Math.round(lot.audience.not_binary || 0),
                  },
                ]}
              />
            )}
            {lot.age_audience && (
              <PieStatistic label={t("Age")} data={ageData} />
            )}
            {lot.views_by_time && (
              <PieStatistic label={t("Watch time")} data={timeData} />
            )}
          </div>
          {(lot.views_by_regions || lot.views_by_countries) && (
            <PeopleStatistic
              stats={
                selectedLots.length > 1
                  ? countryFilter
                    ? lot.views_by_regions
                    : lot.views_by_countries
                  : lot.views_by_regions
              }
              country={
                selectedLots.length > 1
                  ? countryFilter
                    ? countries.find(c => c.name === countryFilter)
                    : "World"
                  : country
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default PastStatistic;
