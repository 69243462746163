import React, {
  isValidElement,
  cloneElement,
  useState,
  useCallback,
  Children,
  useMemo,
} from "react";
import clsx from "clsx";
import { TableRow, TableCell, Collapse } from "@mui/material";

import Checkbox from "src/components/Checkbox";
import { AngleDownIcon } from "src/views/icons";
import { IconButton } from "../IconButton";
import { RecordProvider } from "../RecordContext";

import "./index.scss";
import { useSelector } from "react-redux";

const DataTableRow = props => {
  const {
    selectable,
    record = {},
    contentComponent: ContentComponent,
    colsCount = 1,
    selected = false,
    disabled = false,
    isAuction,
    children,
    onSelect,
  } = props;

  const {
    user: { id: userId },
  } = useSelector(state => state.user);

  const [isOpen, setOpen] = useState(false);

  const modifications = clsx({
    "data-table-row--open": isOpen,
    "data-table-row--disabled": disabled,
  });

  const handleToggle = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSelect = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      if (!disabled) {
        if (onSelect) {
          onSelect(record, !selected);
        }
      }
    },
    [disabled, onSelect, record, selected],
  );

  const empty = useMemo(() => {
    const empty = [];
    for (let i = 0; i < colsCount; i++) {
      empty.push(i);
    }
    return empty;
  }, [colsCount]);

  return record.isSeparator ? (
    <TableRow
      className={`data-table-row-separator`}
      hover={selectable}
      onClick={handleSelect}
    >
      {empty.map(() => {
        return <TableCell className="data-table-row-separator__cell" />;
      })}
    </TableRow>
  ) : (
    <RecordProvider value={{ type: "list", record }}>
      <TableRow
        className={`data-table-row ${modifications}`}
        hover={selectable}
        onClick={handleSelect}
      >
        {selectable && (
          <TableCell className="data-table-row__cell select_inTable">
            <Checkbox
              tabIndex={1}
              role="checkbox"
              disabled={disabled}
              value={selected}
              onChange={handleSelect}
            />
          </TableCell>
        )}
        {ContentComponent && (
          <TableCell className="data-table-row__cell asdf">
            <IconButton
              className="data-table-row__icon"
              color="primary"
              size="medium"
              icon={AngleDownIcon}
              onClick={handleToggle}
            />
          </TableCell>
        )}
        {Children.map(children, child => {
          if (!isValidElement(child)) {
            return null;
          }

          const {
            props: { name, align, isDashed },
          } = child;

          return (
            <TableCell
              key={`${record.id}${name}`}
              className={clsx("data-table-row__cell", {
                "data-table-row__cell__grey":
                  isAuction && userId !== record.bidder_id && !selectable,
                "data-table-dashed-cell": isDashed,
              })}
              align={align}
            >
              {cloneElement(child, child.props)}
            </TableCell>
          );
        })}
      </TableRow>

      {ContentComponent && (
        <TableRow className="data-table-row data-table-row--content">
          <TableCell
            className="data-table-row__cell zeroPadding"
            sx={{
              height: isOpen ? undefined : "0 !important",
            }}
            colSpan={colsCount + 1}
          >
            <Collapse
              className="data-table-row__data"
              unmountOnExit
              timeout="auto"
              in={isOpen}
            >
              <ContentComponent onClose={handleClose} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </RecordProvider>
  );
};

export { DataTableRow };
