import variant1 from "@assets/images/signInRightImg.png";
import variant2 from "@assets/images/signUpRightImg.png";
import React, { memo } from "react";
import { Grid } from "@mui/material";
import { Typography } from "../../views/blocks";

import { ReactSVG } from "react-svg";

import monitorIcon from "@assets/icons/monitor-icon.svg";
import touchIcon from "@assets/icons/touch-icon.svg";
import userIcon from "@assets/icons/auth-user-icon.svg";

import s from "./index.module.scss";

import { useTranslation } from "react-i18next";

const AuthorizationIllustration = ({ img }) => {
  const { t } = useTranslation();

  return (
    <Grid className={s.illustration}>
      <Grid className={s.illustration__block}>
        <Grid item>
          <ReactSVG src={monitorIcon} />
        </Grid>
        <Grid item ml={2}>
          <Typography variant="body2">
            {t("Pay only for what your audience watches.")}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={s.illustration__block}>
        <Grid item>
          <ReactSVG src={touchIcon} />
        </Grid>
        <Grid item ml={2}>
          <Typography variant="body2">
            {t(
              "The platform controls viewing status through FaceID, TouchID or CAPTCHA.",
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={s.illustration__block}>
        <Grid item>
          <ReactSVG src={userIcon} />
        </Grid>
        <Grid item ml={2}>
          <Typography variant="body2">
            {t(
              "100% engagement through targeting settings such as age, location and consumer interests.",
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={s.illustration__block}>
        <img src={img} width="100%" />
      </Grid>
    </Grid>
  );
};

export default memo(AuthorizationIllustration);
