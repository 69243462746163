import React from 'react'
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { Document, Page, Text, View, StyleSheet, Font, Image, PDFViewer } from '@react-pdf/renderer';
import Logo from '@assets/images/LogoInfoCoin.png'

import MontBlanc from "@assets/fonts/MontBlanc/MONT-BLANC-REGULAR.OTF";
import MontBlancB from "@assets/fonts/MontBlanc/MONT-BLANC-BOLD.OTF";
import MontBlancS from "@assets/fonts/MontBlanc/MONT-BLANC-SEMIBOLD.OTF";
import MontBlancL from "@assets/fonts/MontBlanc/MONT-BLANC-LIGHT.OTF";
import PoeMonospace from "@assets/fonts/PoeMonospace.ttf";

Font.register(
    {
        family: "MontBlanc",
        src: MontBlanc,
        fonts: [
            { src: MontBlanc },
            { src: MontBlancS, fontWeight: 500 },
            { src: MontBlancB, fontWeight: "bold" },
            { src: MontBlancS, fontWeight: 500 },
            { src: MontBlancL, fontWeight: "light" }
        ]
    }
);
Font.register({ family: "PoeMonospace", src: PoeMonospace })

const ModalPDFReport = ({
    show,
    onHide,
    userInfo,
    dataTable,
    viewStartDate,
    viewEndDate,
    prevBalance,
    totalBalance,
    currentCredit,
    currentDebit }) => {

    // Create styles
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'col',
            backgroundColor: '#E4E4E4',
        },
        logo: {
            width: "595px",
            height: '140px',
            padding: '5px'
        },
        pageBody: {
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        title: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            //width: '100%',
            fontFamily: "MontBlanc",
            //color: "#1e4e79",
            //fontWeight: "500",
            fontSize: "14px",
            margin: 0,
        },
        titleSection: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            marginBottom: '2px'
        },
        titleRightSection: {
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
            marginBottom: '2px'
        },
        titleRow: {
            display: "flex",
            flexDirection: "row",
        },
        titleHeader_100: {
            backgroundColor: "#fe8b00",
            borderTopLeftRadius: "10px",
            width: "100px",
            textAlign: "right",
            fontWeight: 500,
            fontFamily: "MontBlanc",
            paddingRight: '2px',
            fontSize: "9px",
        },
        titleHeader_150: {
            backgroundColor: "#fe8b00",
            borderTopLeftRadius: "10px",
            width: "135px",
            textAlign: "right",
            fontWeight: 500,
            fontFamily: "MontBlanc",
            paddingRight: '2px',
            fontSize: "9px",
        },
        titleField: {
            marginLeft: '3px',
            fontWeight: 400,
            fontFamily: "MontBlanc",
            fontSize: "9px",
        },
        titleMonoField: {
            width: "70px",
            fontFamily: "PoeMonospace",
            textAlign: "right",
            fontSize: "10px",
        },
        tableHeader: {
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#fe8b00",
            borderTopLeftRadius: "10px",
            marginTop: "5px",
            fontWeight: 500,
            fontFamily: "MontBlanc",
            fontSize: "9px",
        },
        tableHeadField: {
            textAlign: "center"
        },
        tableRow: {
            display: "flex",
            flexDirection: "row",
            marginTop: "2px",
        },
        tableRowField: {
            textAlign: "center"
        }
    });

    // Create Document Component
    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View >
                    <Image style={styles.logo} src={Logo} />
                </View>
                <View style={styles.pageBody}>
                    <View style={styles.title}>
                        <Text>Wallet report</Text>
                        <Text style={styles.titleField}>from {moment(viewStartDate).format('DD.MM.YYYY')} to {moment(viewEndDate).format('DD.MM.YYYY')}</Text>
                    </View>
                    <View style={styles.titleSection}>
                        <View style={styles.titleRow}>
                            <Text style={styles.titleHeader_100}>User e-mail:</Text>
                            <Text style={styles.titleField}>{userInfo.email}</Text>
                        </View>
                        <View style={styles.titleRow}>
                            <Text style={styles.titleHeader_150}>Balance as of {moment(viewEndDate).format('DD.MM.YYYY')}:</Text>
                            <Text style={styles.titleMonoField}>{totalBalance.toFixed(4)}</Text>
                        </View>
                    </View>
                    <View style={styles.titleSection}>
                        <View style={styles.titleRow}>
                            <Text style={styles.titleHeader_100}>User id:</Text>
                            <Text style={styles.titleField}>{userInfo.id}</Text>
                        </View>
                        <View style={styles.titleRow}>
                            <Text style={styles.titleHeader_150}>Total debit for the period:</Text>
                            <Text style={styles.titleMonoField}>{currentDebit.toFixed(4)}</Text>
                        </View>
                    </View>
                    <View style={styles.titleRightSection}>
                        <View style={styles.titleRow}>
                            <Text style={styles.titleHeader_150}>Total credit for the period:</Text>
                            <Text style={styles.titleMonoField}>{`-${currentCredit.toFixed(4)}`}</Text>
                        </View>
                    </View>
                    <View style={styles.titleRightSection}>
                        <View style={styles.titleRow}>
                            <Text style={styles.titleHeader_150}>Balance as of {moment(viewStartDate).format('DD.MM.YYYY')}:</Text>
                            <Text style={styles.titleMonoField}>{prevBalance.toFixed(4)}</Text>
                        </View>
                    </View>
                    <View style={styles.tableHeader}>
                        <View style={styles.tableHeadField}>
                            <Text style={{ width: '30px' }}>id</Text>
                        </View>
                        <View style={styles.tableHeadField}>
                            <Text style={{ width: '130px' }}>Date</Text>
                        </View>
                        <View style={styles.tableHeadField}>
                            <Text style={{ width: '300px' }}>Payment detail</Text>
                        </View>
                        <View style={styles.tableHeadField}>
                            <Text style={{ width: '100px', textAlign: "right" }}>Sum</Text>
                        </View>
                    </View>
                    {dataTable.map((trans, key) => {
                        return (
                            <View key={key} style={styles.tableRow}>
                                <View style={styles.tableRowField}>
                                    <Text style={{ width: '30px', fontWeight: 400, fontFamily: "MontBlanc", fontSize: "9px" }}>{trans.id_tr}</Text>
                                </View>
                                <View style={styles.tableRowField}>
                                    <Text style={{ width: '130px', fontFamily: "PoeMonospace", fontSize: "9px" }}>{moment(trans.date_tr).format("DD MMM YYYY HH:mm:ss")}</Text>
                                </View>
                                <View style={styles.tableRowField}>
                                    <Text style={{ width: '300px', fontWeight: 400, fontFamily: "MontBlanc", fontSize: "9px", textAlign: "left" }}>{trans.details_tr}</Text>
                                </View>
                                <View >
                                    <Text style={{ width: '100px', fontFamily: "PoeMonospace", fontSize: "9px", textAlign: "right" }}>{(Number.parseFloat(trans.summ_tr)).toFixed(4)}</Text>
                                </View>
                            </View>
                        )
                    })}
                </View>
            </Page>
        </Document >
    );

    // console.log(dataTable)

    return (
        <Modal
            show={show}
            onHide={onHide}
            size={'xl'}
        >
            <Modal.Header closeButton>
                <Modal.Title>Wallet Report export to .pdf</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PDFViewer style={{ height: "1200px", width: "100%" }}>
                    <MyDocument />
                </PDFViewer>
            </Modal.Body>
        </Modal>
    )
}

export default ModalPDFReport