import React, { createContext, useState, useCallback, useMemo } from "react";
import ModalDocuments from "@components/ModalDocuments";
import PublicAgreementModal from "@components/PublicAgreementModal/PublicAgreementModal";

// create context
const DocumentsContext = createContext();

const DocumentContextProvider = ({ children }) => {
  const [showModalDocuments, setShowModal] = useState(false);
  const [showPublic, setShowPublic] = useState(false);

  const setShowModalDocuments = useCallback(e => {
    setShowModal(show => !show);
  }, []);

  const setShowModalDocumentsPublic = useCallback(e => {
    setShowPublic(show => !show);
  }, []);

  const contextValue = useMemo(
    () => ({
      setShowModalDocuments,
      setShowModalDocumentsPublic,
    }),
    [],
  );

  return (
    <DocumentsContext.Provider value={contextValue}>
      {children}
      <ModalDocuments
        show={showModalDocuments}
        setShow={setShowModalDocuments}
      />
      <PublicAgreementModal
        show={showPublic}
        setShow={setShowModalDocumentsPublic}
      />
    </DocumentsContext.Provider>
  );
};

export { DocumentsContext, DocumentContextProvider };
