import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import { PDFViewer, usePDF } from "@react-pdf/renderer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { userSelector } from "@redux/user/selectors";
import { useWallet } from "../../hooks";
import classes from "./invoce.module.scss";
import { createInvoiceAction } from "@redux/wallet/actions";
import { amountInvoicesSelector } from "@redux/wallet/selectors";
import Button from "@components/Button";
import { useTranslation } from "react-i18next";
import { fileToBase64 } from "@utils/fileToBase64";
import { deleteBase64Info } from "@utils/files";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import SuccessAction from "src/components/SuccessAction";
import Loader from "src/components/Loader";
import { getCountriesAction } from "src/redux/language/actions";
import { countriesSelector } from "src/redux/language/selectors";
//import StripeModal from "src/components/PaymentForms/Stripe";
import { axiosInstance } from "@helpers/http/httpInstance";
import { getBankInfo, getNextInvoiceId } from "@services/api/wallet";
//import PayPal from "@components/PaymentForms/PayPal";
//import CorefyPay from "@components/PaymentForms/Corefy";
import { v4 as uuidv4 } from "uuid";
import MyDocument from "src/pages/Invoice/Document";
import { useExternalScript } from "../../hooks/useScripts";
import { COREFY_API, COREFY_KEY } from "../../config";

const Invoice = () => {
  const { t } = useTranslation();

  const [dataSubmitSuccess, setDataSubmitSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  const [invoiceId, setInvoiceId] = useState("");

  const [bankInfo, setBankInfo] = useState({});

  const user = useSelector(userSelector, shallowEqual);
  const history = useHistory();

  const countries = useSelector(countriesSelector);

  const {
    amount: amountForInvoice,
    company,
    country,
    type,
    paymentSystem,
  } = useSelector(amountInvoicesSelector);

  const { exchangeRate, updateTransactions } = useWallet();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!countries.length) {
      dispatch(getCountriesAction());
    }

    (async function () {
      try {
        const res = await getNextInvoiceId();
        setInvoiceId(res.invoice_id);
        const bankRes = await getBankInfo(country);
        setBankInfo(bankRes.data);
      } catch (e) {
        console.log(e);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const taxPercent = useMemo(() => {
    if (country === "Cyprus") {
      return 0.19;
    }
    if (company.vat_number) {
      return 0;
    }
    return countries.find(c => c.name === country)?.vat_percentage;
  }, [company, countries, country]);

  const [instance, update] = usePDF({
    document: (
      <MyDocument
        amount={amountForInvoice * exchangeRate.input}
        invoiceId={invoiceId}
        tax={taxPercent}
        user={user}
        company={company ? company : {}}
        bankInfo={bankInfo}
      />
    ),
  });

  /* const [isPayment, setIsPayment] = useState(false);

  const { addPendingTransaction } = useWallet();*/

  const paymentId = useMemo(() => uuidv4(), []);

  const {
    id: userId,
    email: userEmail,
    name: userName,
    phone: userPhone,
  } = useSelector(userSelector);

  const state = useExternalScript(
    "https://unpkg.com/@paycore/payment-widget-js@0.1.96/dist/paymentWidget.umd.min.js",
    () => {},
  );

  useEffect(() => {
    update();
  }, [invoiceId, update]);

  const onConfirm = useCallback(
    paymentId => async () => {
      setLoading(true);
      const file = deleteBase64Info(await fileToBase64(instance.blob));
      try {
        await dispatch(
          createInvoiceAction({
            payment_system: type,
            payment_id: paymentId,
            amount_eur: parseFloat(amountForInvoice * exchangeRate.input),
            invoice_id: invoiceId,
            country_id: countries.find(c => c.name === country).id,
            ic_amount: amountForInvoice.toString(),
            filename: `${user.id}-${invoiceId}.pdf`,
            vat_sum: amountForInvoice * exchangeRate.input * taxPercent,
            file,
            company_id: company.id,
          }),
        );

        if (type === "BankInvoice" || type === "Stripe" || type === "PayPal") {
          toast.success(t("The invoice was created, check the email for paid"));
        }
        updateTransactions();
        setLoading(false);
        setDataSubmitSuccess(true);
      } catch (e) {
        toast.error("Error of creating invoice");
        setLoading(false);
      }
    },
    [
      amountForInvoice,
      countries,
      company?.id,
      country,
      dispatch,
      exchangeRate.input,
      instance.blob,
      invoiceId,
      t,
      taxPercent,
      type,
      updateTransactions,
      user.id,
    ],
  );

  const onCorefyPay = useCallback(async () => {
    setLoading(true);
    try {
      const file = deleteBase64Info(await fileToBase64(instance.blob));
      await axiosInstance.post("/api/corefy/payment", {
        amount_eur:
          amountForInvoice * exchangeRate.input +
          amountForInvoice * exchangeRate.input * taxPercent,
        file: file,
        payment_id: paymentId,
        filename: `${user.id}-${invoiceId}.pdf`,
        vat_sum: amountForInvoice * exchangeRate.input * taxPercent,
        ic_amount: String(amountForInvoice),
        country_id: countries.find(c => c.name === country).id,
        company_id: company.id,
      });
      window.payment_widget.init({
        public_key: COREFY_KEY,
        base_url: COREFY_API,
        reference_id: paymentId.toString(),
        amount: parseFloat(
          (
            amountForInvoice * exchangeRate.input +
            amountForInvoice * exchangeRate.input * taxPercent
          ).toFixed(2),
        ),
        currency: "EUR",
        target: "redirect",
        description: "Pay",
        metadata: {
          user_id: userId.toString(),
        },
        customer: {
          reference_id: userId.toString(),
          email: userEmail.toString(),
          name: userName.toString(),
          phone: userPhone.toString(),
        },
      });
      setLoading(false);
      window.payment_widget.close();
      window.location.assign(`${window.location.origin}/wallet`);
    } catch (e) {
      setLoading(false);
      toast.error(t("Error of create corefy payment"));
    }
  }, [
    amountForInvoice,
    company.id,
    countries,
    country,
    exchangeRate.input,
    instance.blob,
    invoiceId,
    paymentId,
    t,
    taxPercent,
    user.id,
    userEmail,
    userId,
    userName,
    userPhone,
  ]);

  const onCreditCard = useCallback(async () => {
    /* if (paymentSystem === "Stripe") {
      setLoading(true);
      const file = deleteBase64Info(await fileToBase64(instance.blob));
      axiosInstance
        .post("/api/stripe/pi", {
          amount_eur:
            amountForInvoice * exchangeRate.input +
            amountForInvoice * exchangeRate.input * taxPercent,
          file: file,
          filename: `${user.id}-${invoiceId}.pdf`,
          vat_sum: amountForInvoice * exchangeRate.input * taxPercent,
          ic_amount: String(amountForInvoice),
        })
        .then(res => {
          setClientSecret(res.data.client_secret);
          setLoading(false);
          updateTransactions();
          setIsPayment(true);
        })
        .catch(e => {
          console.log(e);
        });
    }
    if (paymentSystem === "PayPal") {
      setIsPayment(true);
    }*/
    if (paymentSystem === "Corefy") {
      await onCorefyPay();
    }
  }, [paymentSystem, onCorefyPay]);

  /*const onSuccess = useCallback(
    ({ id, created, amount, withTransaction = true }) => {
      if (withTransaction) {
        addPendingTransaction({
          id: id,
          created_at: new Date(created),
          amount: amount,
        });
      }
      setDataSubmitSuccess(true);
      toast.success(
        t("Money will be credited to the balance in the next 5 minutes"),
      );
      updateTransactions();
      setIsPayment(false);
    },
    [addPendingTransaction, t, updateTransactions],
  );*/

  return amountForInvoice ? (
    dataSubmitSuccess ? (
      <div className={classes.container}>
        <SuccessAction text={"The invoice was created"} />
        <Link to={"/wallet"}>
          <Button>{t("Go to wallet")}</Button>
        </Link>
      </div>
    ) : (
      <>
        <div className={classes.container}>
          {(loading || state === "loading") && (
            <div className={classes.loadingContainer}>
              <Loader width={150} height={150} />
            </div>
          )}
          <div className={classes.pdf}>
            <PDFViewer
              style={{
                height: "1230px",
                width: "100%",
              }}
              className={classes.frame}
              showToolbar={false}
            >
              <MyDocument
                amount={amountForInvoice * exchangeRate.input}
                invoiceId={invoiceId}
                tax={taxPercent}
                user={user}
                company={company ? company : {}}
                bankInfo={bankInfo}
              />
            </PDFViewer>
          </div>
          <div className={classes.buttons}>
            <Button
              containerClassName={classes.confirm}
              disabled={state !== "ready" || loading}
              onClick={() =>
                type === "CreditCard" ? onCreditCard() : onConfirm()()
              }
            >
              {t("Pay")}
            </Button>
            <Button
              containerClassName={classes.cancel}
              onClick={() => history.goBack()}
            >
              {t("Cancel")}
            </Button>
          </div>
          {/*          {paymentSystem === "Stripe" && (
            <StripeModal
              amount={
                amountForInvoice * exchangeRate.input +
                amountForInvoice * exchangeRate.input * taxPercent
              }
              clientSecret={clientSecret}
              onSuccess={onSuccess}
              setIsPayment={setIsPayment}
              open={isPayment}
            />
          )}*/}
          {/* {paymentSystem === "PayPal" && (
            <PayPal
              amount={
                amountForInvoice * exchangeRate.input +
                amountForInvoice * exchangeRate.input * taxPercent
              }
              setIsPayment={setIsPayment}
              countryId={countries.find(c => c.name === country)?.id}
              companyId={company.id}
              isValid={true}
              file={instance.blob}
              fileName={`${user.id}-${invoiceId}.pdf`}
              onSuccess={onSuccess}
              userId={user.id}
              tax={amountForInvoice * exchangeRate.input * taxPercent}
              open={isPayment}
            />
          )}*/}
        </div>
      </>
    )
  ) : (
    <div className={classes.container} style={{ justifyContent: "center" }}>
      <h1 className={classes.title}>
        {t("You can create invoice from wallet")}
      </h1>
      <Link to={"/wallet"}>
        <Button>{t("Go to wallet")}</Button>
      </Link>
    </div>
  );
};

export default Invoice;
