import { useEffect } from "react";
import { clearTokenData, getTokenData, setTokenData } from "@utils/tokens";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authorizedAction, authPending, notAuth } from "@redux/auth/actions";
import { refreshToken } from "@services/api/auth";
import { getUser, getDemoUser } from "@services/api/user";
import { saveUserAction } from "@redux/user/actions/userActions";
import { toast } from "react-toastify";
import { getEmergencyAction } from "@redux/emergency/actions";
import i18n from "src/translates";
import {
  getCompaniesAction,
  getDemoCompaniesAction,
} from "@redux/companies/actions";
export const useAppInit = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { token } = getTokenData();

  useEffect(() => {
    const isRemember = localStorage.getItem("isRemember");

    const isDemoUser = !!localStorage.getItem("demo-user");

    if (!i18n.language || i18n.language === "en-US") i18n.changeLanguage("en");
    if (
      location.pathname === "/success-pay" ||
      location.pathname === "/fail-pay"
    ) {
      dispatch(notAuth());
    } else if (token && isRemember !== "false") {
      dispatch(authPending());

      refreshToken()
        .then(({ data }) => {
          setTokenData(data, isRemember === "true");

          if (isDemoUser) {
            getDemoUser()
              .then(response => {
                dispatch(saveUserAction(response.data));

                dispatch(getDemoCompaniesAction());

                dispatch(authorizedAction(data.session_id));

                if (
                  location.pathname !== "/auth" &&
                  location.pathname !== "/" &&
                  location.pathname !== "/invite"
                )
                  history.push(location.pathname);
                else {
                  isDemoUser
                    ? history.push("/content")
                    : history.push("/auction");
                }
              })
              .catch(error => {
                dispatch(notAuth());

                toast.error(error.message);
              });
          } else {
            getUser()
              .then(response => {
                dispatch(saveUserAction(response.data));

                dispatch(getCompaniesAction());

                dispatch(getEmergencyAction()).then(isEmergency => {
                  dispatch(authorizedAction(data.session_id));

                  if (
                    location.pathname !== "/auth" &&
                    location.pathname !== "/" &&
                    location.pathname !== "/invite"
                  )
                    history.push(location.pathname);
                  else {
                    history.push(isEmergency ? "/charity" : "/auction");
                  }
                });
              })
              .catch(error => {
                dispatch(notAuth());

                toast.error(error.message);
              });
          }
        })
        .catch(error => {
          dispatch(notAuth());
        });
    } else {
      clearTokenData();

      dispatch(notAuth());

      if (location.pathname !== "/invite") {
        history.push("/auth");
      }
    } //eslint-disable-next-line
  }, []);
};
