import React, { memo, useCallback, useState } from "react";

import { Grid, IconButton, InputAdornment } from "@mui/material";
import Input from "@components/Forms/components/Input";
import { Typography } from "../../../../../views/blocks";
import { AuthButton } from "@components/Forms/components/ui";
import { useFormik } from "formik";
import { resetPasswordSchema } from "@helpers/Formik/validation";

import { useTranslation } from "react-i18next";

import Loader from "@components/Loader";
import { EyeLineIcon, EyeOffLineIcon } from "../../../../../views/icons";

const ResetPasswordPasswordForm = ({ defaultValue, onSubmit }) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() =>
    setShowPassword(show => !show),
  );

  const handleMouseDown = useCallback(event => event.preventDefault());

  const {
    values,
    touched,
    isValid,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: defaultValue,
    validationSchema: resetPasswordSchema(2),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      onSubmit(values, 2);
    },
  });

  return (
    <Grid component="form">
      <Grid item mt={6}>
        <Input
          label={t("Password")}
          name="password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDown}
                edge="end"
              >
                {showPassword ? (
                  <EyeLineIcon size="medsmall" />
                ) : (
                  <EyeOffLineIcon size="medsmall" />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item mt={2}>
        <Input
          label={t("Confirm password")}
          name="confirmPassword"
          type={showPassword ? "text" : "password"}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.confirmPassword}
          value={values.confirmPassword}
          error={t(errors.confirmPassword)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDown}
                edge="end"
              >
                {showPassword ? (
                  <EyeLineIcon size="medsmall" />
                ) : (
                  <EyeOffLineIcon size="medsmall" />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <Typography
          variant="caption2"
          color={touched.password && errors.password ? "error" : "gray"}
          className="mt-1"
        >
          {t(
            "The password must consist of: at least 8 characters, a lowercase character, an uppercase character, a number and a special character (!,@,#,$,% etc.)",
          )}
        </Typography>
      </Grid>

      <Grid item mt={15}>
        <AuthButton
          fullWidth="100%"
          onClick={handleSubmit}
          disabled={!isValid || isSubmitting}
        >
          {isSubmitting ? (
            <Loader color="white" width={31.5} height={31.5} />
          ) : (
            t("Submit")
          )}
        </AuthButton>
      </Grid>
    </Grid>
  );
};

export default memo(ResetPasswordPasswordForm);
