import React, { memo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "../../../../views/blocks";

import s from "./FaqMessage.module.scss";

import clsx from "clsx";

const FaqMessage = ({ title, children, className }) => {
  return (
    <Accordion className={clsx(s.accordion, className)}>
      <AccordionSummary
        expandIcon={
          <IconButton>
            <ExpandMoreIcon htmlColor="var(--primary-color)" />
          </IconButton>
        }
      >
        <Grid width="100%">
          <Typography variant="body1" weight="normal-bold">
            {title}
          </Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default memo(FaqMessage);
