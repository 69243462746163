//react
import React, {
  memo,
  useMemo,
  useState,
  useEffect,
  Fragment,
  useCallback,
} from "react";

//store
import { connect } from "react-redux";

//style
import s from "./Account.module.scss";

//components
import { Container, Grid } from "@mui/material";
import { Typography } from "../../views/blocks";
import SwitchToggle from "@components/SwitchToogle";
import Notifications from "@pages/Account/components/Notifications";
import ReleaseNotes from "@pages/Account/components/ReleaseNotes";
import Sessions from "./components/Other";
//forms
import ProfileForm from "./components/ProfileForm";
import PasswordForm from "./components/PasswordForm";

//other
import { useTranslation } from "react-i18next";
import { getSessions } from "@services/api/auth";
import { getNotifications, postDemoFullUser } from "@services/api/user";
import { toast } from "react-toastify";
import Select from "@components/Form/Select";

const Account = ({ user }) => {
  const { t } = useTranslation();

  const options = useMemo(
    () => [
      { value: 1, label: t("Profile") },
      {
        value: 2,
        label: t("Password"),
      },
      {
        value: 3,
        label: t("Notifications"),
      },
      {
        value: 4,
        label: t("Release notes"),
      },
    ],
    [t],
  );

  const [sessions, setSessions] = useState([]);

  const [notifications, setNotifications] = useState([]);

  const [activeToggle, setActiveToggle] = useState({
    value: 1,
    activeIndex: 0,
  });

  const selectValue = useMemo(
    () => options.find(option => option.value === activeToggle.value),
    [activeToggle],
  );

  const updateNotifications = async () =>
    await getNotifications().then(({ data }) => setNotifications(data));

  useEffect(() => {
    if (user.role !== "demo_user") {
      if (!sessions.length) {
        getSessions().then(({ data }) => setSessions(data));
      }

      if (!notifications.length) {
        updateNotifications();
      }
    }
  }, []);

  const onToggleFirstNavigation = useCallback(() => {
    setActiveToggle({
      value: 1,
      activeIndex: 0,
    });
  }, []);

  const handleClickFullUser = useCallback(async () => {
    if (!user.country || !user.region || !user.city) {
      return toast.warning(
        t(
          "To become a full user, you need to fill in your location, namely - Country, Region and City",
        ),
      );
    }

    try {
      await postDemoFullUser();

      localStorage.removeItem("demo-user");
      window.location.reload();
    } catch (e) {
      toast.error(t(e?.response?.data?.error || e?.message));
    }
  }, [t]);

  return (
    <Container className={s.container}>
      <Typography className={s.title}>{t("Settings")}</Typography>
      <Grid className={s.content} mt={4}>
        {window.innerWidth > 1010 ? (
          <SwitchToggle
            options={options}
            onChange={(value, index) =>
              setActiveToggle({ value, activeIndex: index })
            }
            value={activeToggle.value}
            activeIndex={activeToggle.activeIndex}
          />
        ) : (
          <Select
            isSearchable={false}
            className={s.select_navigation}
            value={selectValue}
            onChange={value => setActiveToggle(value.target)}
            options={options}
          />
        )}
        <Grid item mt={4}>
          {activeToggle.value === 1 && (
            <ProfileForm
              user={user}
              handleClickFullUser={handleClickFullUser}
            />
          )}
          {activeToggle.value === 2 && (
            <Fragment>
              <PasswordForm onToggleFirstNavigation={onToggleFirstNavigation} />
              {user.role !== "demo_user" && <Sessions sessions={sessions} />}
            </Fragment>
          )}

          {activeToggle.value === 3 && (
            <Notifications
              notifications={notifications}
              update={updateNotifications}
              user={user}
            />
          )}
          {activeToggle.value === 4 && <ReleaseNotes />}
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = ({ user }) => ({
  user: user.user,
});

export default connect(mapStateToProps, null)(memo(Account));
