import React from "react";
import clsx from "clsx";

import "./index.scss";


const Icon = (props) => {
    const {
        component: Component = "span",
        className,
        color,
        size = "medium",
        mask = false,
        background = false,
        ...rest
    } = props;

    const modifications = clsx({
        "icon--mask": mask,
        "icon--background": background,
        [`icon--size-${size}`]: size,
        [`icon--color-${color}`]: color
    });

    return (
        <Component
          {...rest}
          className={`icon ${className} ${modifications}`} />
    );
};


export {Icon};