import {createContext} from "react";


const Context = createContext({
    type: "",
    record: {}
});

const {
    Provider,
    Consumer
} = Context;


export {
    Context as RecordContext,
    Provider as RecordProvider,
    Consumer as RecordConsumer
};