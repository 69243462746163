import { useEffect, useState } from "react";

export const useExternalScript = (url, onLoad) => {
  let [state, setState] = useState(url ? "loading" : "idle");

  useEffect(() => {
    if (!url) {
      setState("idle");
      return;
    }
    let script = document.querySelector(`script[src="${url}"]`);

    const handleScript = e => {
      setState(e.type === "load" ? "ready" : "error");
      if (e.type === "load") {
        onLoad();
      }
    };

    if (!script) {
      script = document.createElement("script");
      script.type = "application/javascript";
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener("load", handleScript);
      script.addEventListener("error", handleScript);
    }
    if (script) {
      handleScript({ type: "load" });
    }

    script.addEventListener("load", handleScript);
    script.addEventListener("error", handleScript);

    return () => {
      script.removeEventListener("load", handleScript);
      script.removeEventListener("error", handleScript);
    };
  }, [url]);

  return state;
};
