import React, { memo, useEffect, useMemo, useState } from "react";
import { useSteps } from "react-step-builder";
import classes from "./LoadVideoFromTemplateStep.module.scss";
import { useFormik } from "formik";
import CompanySelect from "@components/Form/CompanySelect";
import Button from "@components/Button";
import { useTranslation } from "react-i18next";
import { LoadVideoFromTemplateSchema } from "@helpers/Formik/validation";
import TextField from "../../../Form/TextField";
import Checkbox from "@components/Checkbox";
import Select from "@components/Form/Select";
import { getVideoCategories } from "@services/api/video/video-api";
import CountrySelect from "@components/Form/CountrySelect";
import RegionSelect from "@components/Form/RegionSelect";
import CitySelect from "@components/Form/CitySelect";
import { toast } from "react-toastify";
import { connect, useDispatch, useSelector } from "react-redux";
import { createVideoFromTemplateAction } from "@redux/video/actions";
import Logo from "@assets/icons/logo.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { removeEmptyFromObject } from "@utils/obj";
import { Collapse } from "@mui/material";

const ages = [];

for (let i = 1; i < 100; i++) {
  ages.push({ value: i, label: i });
}

const goalOptions = [
  {
    value: "link",
    label: "Following a link",
  },
  {
    value: "email",
    label: "Sending messages by mail",
  },
];

const genderOptions = [
  { label: "Male", value: "male" },
  { value: "female", label: "Female" },
  { value: "all", label: "All" },
];

const LoadVideoFromTemplateStep = ({
  selectedVideo,
  setSelectedVideo,
  closeModal,
  setFieldValue: motherSetField,
  companies,
}) => {
  const { next, prev } = useSteps();

  const lotToEdit = useSelector(state => state.auction.lotToEdit);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [charityCategory, saveCategory] = useState([]);

  const SuccessCreatedVideoEl = useMemo(
    () => (
      <div className={classes.successContainer}>
        <div className={classes.logo}>
          <img src={Logo} alt="Infocoin" />
        </div>
        <div className={classes.text}>{t("Video sent to moderation")}</div>
        <div className={classes.secondText}>
          {t("You can see video in your content")}
        </div>
        <CheckCircleIcon className={classes.done} />
        <Button onClick={closeModal} containerClassName={classes.button}>
          {t("Close")}
        </Button>
      </div>
    ), //eslint-disable-next-line
    [],
  );

  const [successCreatedVideo, setSuccessCreatedVideo] = useState(false);

  const isMyContentChosen = useSelector(
    state => state.emergency.isMyVideoChosen,
  );

  useEffect(() => {
    if (isMyContentChosen) {
      next();
      !lotToEdit && motherSetField("step", 3);
    } //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getVideoCategories()
      .then(response => {
        saveCategory(
          response.data.find(category => category.title === "Charity"),
        );
      })
      .catch(() => {});
    setFieldValue("company_id", companies.length >= 1 ? companies[0].id : "");
    //eslint-disable-next-line
  }, []);

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    isValid,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      editable: false,
      company_id: "",
      title: selectedVideo.title || "",
      description: selectedVideo.description || "",
      country: selectedVideo.country || "",
      city: selectedVideo.city || "",
      region: selectedVideo.region || "",
      age_min: selectedVideo.age_min || 8,
      age_max: selectedVideo.age_max || 60,
    },
    onSubmit: async values => {
      if (charityCategory.id) {
        setSubmitting(true);

        const data = !values.editable
          ? {
              company_id: values.company_id,
              video_template_id: selectedVideo.id,
              category_id: charityCategory.id,
            }
          : {
              ...values,
              video_template_id: selectedVideo.id,
              category_id: charityCategory.id,
            };

        delete data.editable;

        try {
          const newVideo = await dispatch(
            createVideoFromTemplateAction(removeEmptyFromObject(data)),
          );

          setSubmitting(false);

          if (newVideo.status === "verified") {
            setSelectedVideo(newVideo);
            motherSetField("step", 3);
            next();
          } else {
            setSuccessCreatedVideo(true);
          }
        } catch (e) {
          toast.error(e?.response?.data?.error || t("Something went wrong"));
        }
      } else toast.error(t("Charity category didnt eat"));
    },
    validationSchema: LoadVideoFromTemplateSchema,
  });

  return successCreatedVideo ? (
    SuccessCreatedVideoEl
  ) : (
    <form onSubmit={handleSubmit}>
      <CompanySelect
        value={values.company_id}
        touched={touched.company_id}
        name="company_id"
        label="Company"
        handleBlur={handleBlur}
        onChange={handleChange}
        error={errors.company_id}
      />
      <Checkbox
        value={values.editable}
        labelStyle={{ marginLeft: 0 }}
        sx={{ marginTop: "10px" }}
        name="editable"
        label="Click if you want to edit another fields of lot, but video go to moderation"
        onChange={e => {
          setFieldValue("editable", e.target.checked);
        }}
      />
      <Collapse in={values.editable}>
        <div className={classes.container}>
          <div>
            <TextField
              label="Content Title"
              type="title"
              name="title"
              disabled={!values.editable}
              touched={touched.title}
              error={errors.title}
              handleBlur={() => setFieldTouched("title", true, true)}
              value={values.editable ? values.title : selectedVideo.title}
              onChange={handleChange}
              sx={{
                marginBottom: "18px",
              }}
            />
            <TextField
              className={classes.description}
              multiline
              rows={4}
              maxRows={4}
              disabled={!values.editable}
              label="Description"
              handleBlur={handleBlur}
              error={errors.description}
              touched={touched.description}
              name="description"
              value={
                values.editable ? values.description : selectedVideo.description
              }
              onChange={handleChange}
            />
            <div className={classes.ageLabel}>{t("Age")}</div>
            <div className="age-info">
              <Select
                placeholder="Min age"
                handleBlur={handleBlur}
                value={values.age_min}
                disabled={!values.editable}
                translateOptions={false}
                containerClassName={classes.selectContainer}
                name="age_min"
                options={ages}
                onChange={handleChange}
                endAdornment
              />
              <div className="divider2" />
              <Select
                value={values.age_max}
                placeholder="Max age"
                name="age_max"
                disabled={!values.editable}
                translateOptions={false}
                handleBlur={handleBlur}
                options={ages}
                containerClassName={classes.selectContainer}
                onChange={handleChange}
                endAdornment
              />
            </div>
            {((errors.age_max && touched.age_max) ||
              (errors.age_min && touched.age_min)) && (
              <span className="red-error mt-2">
                {errors.age_max || errors.age_min}
              </span>
            )}
          </div>
          <div>
            <div>
              <Select
                id="outlined-select-currency"
                name="goal"
                containerClassName={classes.selectContainer}
                label="Objectives of interaction"
                value={values.goal}
                disabled={!values.editable}
                handleBlur={handleBlur}
                onChange={event => {
                  setFieldValue("email", "");
                  setFieldValue("url", "");
                  handleChange(event);
                }}
                options={goalOptions}
                error={errors.goal}
                touched={touched.goal}
              />
            </div>
            {values.goal === "email" && (
              <TextField
                name="email"
                label="E-mail"
                fullWidth
                disabled={!values.editable}
                value={values.editable ? values.email : selectedVideo.email}
                touched={touched.email}
                onChange={handleChange}
                handleBlur={handleBlur}
                error={errors.email}
                sx={{
                  marginBottom: "18px",
                }}
              />
            )}
            {values.goal === "link" && (
              <TextField
                label="Link"
                variant="outlined"
                type="url"
                name="url"
                disabled={!values.editable}
                handleBlur={handleBlur}
                value={values.editable ? values.url : selectedVideo.url}
                error={errors.url}
                touched={touched.url}
                onChange={handleChange}
                sx={{
                  marginBottom: "18px",
                }}
              />
            )}
            <CountrySelect
              className="country"
              name="country"
              value={values.editable ? values.country : selectedVideo.country}
              onChange={value => {
                setFieldValue("country", value.name);
                setFieldValue("region", "");
                setFieldValue("city", "");
              }}
              disabled={!values.editable}
              onBlur={handleBlur}
              error={errors.country}
              label="Country"
            />
            {errors.country && touched.country && (
              <div className="red-error mt-2">{errors.country}</div>
            )}
            <RegionSelect
              country={values.country}
              name="region"
              value={values.editable ? values.region : selectedVideo.region}
              onChange={handleChange}
              disabled={!values.editable}
              handleBlur={handleBlur}
              label="Region"
              error={errors.region}
              touched={touched.region}
            />
            <CitySelect
              name="city"
              region={values.region}
              value={values.editable ? values.city : selectedVideo.city}
              onChange={handleChange}
              handleBlur={handleBlur}
              disabled={!values.editable}
              label="City"
              error={errors.city}
              touched={touched.city}
            />
            <Select
              label="Gender"
              name="gender"
              value={values.gender}
              onChange={handleChange}
              disabled={!values.editable}
              handleBlur={handleBlur}
              containerClassName={classes.selectContainer}
              error={errors.gender}
              touched={touched.gender}
              options={genderOptions}
            />
          </div>
        </div>
      </Collapse>

      <div className={classes.buttons}>
        <Button
          containerClassName={classes.button}
          type="button"
          onClick={() => prev()}
        >
          {t("Back")}
        </Button>
        <Button
          containerClassName={classes.button}
          type="submit"
          disabled={!isValid}
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          {t("Save video")}
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = ({ companies }) => ({
  companies: companies.companies,
});

export default connect(mapStateToProps, null)(memo(LoadVideoFromTemplateStep));
