import React, { useState, useCallback, memo, Fragment } from "react";
import { Steps } from "intro.js-react";
import { connect } from "react-redux";
import { updateUserAction } from "@redux/user/actions/userActions";

import { introIds } from "../classes";

import { useTranslation, withTranslation } from "react-i18next";
import { Typography } from "../../../views/blocks";

const IntroCompanies = ({ t, onComplete }) => {
  const intro = {
    initialStep: 0,
    stepsEnabled: true,
    options: {
      nextLabel: t("Next"),
      prevLabel: t("Back"),
      doneLabel: t("Next step"),
      disableInteraction: true,
      exitOnOverlayClick: false,
    },
    steps: [
      {
        element: `#${introIds.companies.element1}`,
        intro: (
          <Fragment>
            <Typography variant="h5" weight="normal-bold" align="center">
              {t(
                "Now you can work with the platform, namely, to advertise your product or service for money.",
              )}
            </Typography>
            <Typography
              variant="body1"
              color="gray"
              align="center"
              className="mt-2"
            >
              {t(
                "After registration, the first company from an individual is automatically created.",
              )}
            </Typography>
          </Fragment>
        ),
      },
      {
        element: `#${introIds.companies.element2}`,
        position: "left",
        intro: (
          <Fragment>
            <Typography variant="h5" weight="normal-bold" align="center">
              {t("You need to fill out your company information.")}
            </Typography>
            <Typography
              variant="body1"
              color="gray"
              align="center"
              className="mt-2"
            >
              {t(
                "You will see you can advertise on the platform both as an individual and a legal entity.",
              )}
            </Typography>
          </Fragment>
        ),
      },
    ],
  };

  const handleClose = useCallback(async () => {
    if (intro.stepsEnabled) {
      // const data = { ...user, is_tutorial_passed: true };
      // delete data.phone;
      // await updateUserAction(data);
    }
  }, [intro.stepsEnabled]);

  const handleSubmit = useCallback(() => {
    // setIntro(intro => ({
    //   ...intro,
    //   stepsEnabled: false,
    // }));
    onComplete();
  }, []);

  return (
    <Steps
      enabled={intro.stepsEnabled}
      steps={intro.steps}
      initialStep={intro.initialStep}
      options={intro.options}
      onExit={handleClose}
      onComplete={handleSubmit}
    />
  );
};

const mapStateToProps = ({ user }) => ({
  user: user.user,
});

const mapDispatchToProps = {
  updateUserAction,
};

export default withTranslation()(memo(IntroCompanies));
