import React from 'react';
import { Container } from 'react-bootstrap';

const TermsUse = () => {
  return (
    <Container className="m-mob">
      <h4>Условия пользования</h4>
    </Container>
  );
};

export default TermsUse;
