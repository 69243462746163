import React, { memo } from "react";

import { Card, CardHeader, IconButton, Avatar, Tooltip } from "@mui/material";
import { Typography } from "../../../../views/blocks";
import s from "./index.module.scss";
import { useTranslation } from "react-i18next";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
const CompanyItem = ({ data, onSubmit, id }) => {
  const { avatar, title, country } = data;

  const { t } = useTranslation();

  return (
    <Card className={s.companyItem} id={id}>
      <CardHeader
        avatar={
          <Avatar aria-label="company-avatar" src={avatar}>
            {title[0].toUpperCase()}
          </Avatar>
        }
        title={
          <Typography
            className={s.companyItem__title}
            variant="body1"
            weight="normal-bold"
          >
            {title}
          </Typography>
        }
        subheader={
          <Typography variant="body2" color={country ? "gray" : "error"}>
            {country ? country : t("Country not specified!")}
          </Typography>
        }
        action={
          <Tooltip title={t("Go to company editing")}>
            <IconButton
              onClick={onSubmit}
              aria-label="go to edit"
              className={s.companyItem__button}
            >
              <EastRoundedIcon />
            </IconButton>
          </Tooltip>
        }
      />
    </Card>
  );
};

export default memo(CompanyItem);
