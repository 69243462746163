import { Container, Grid } from "@mui/material";

import { memo } from "react";

import { withTranslation } from "react-i18next";

import logo from "assets/images/logo-light.png";

import s from "./index.module.scss";

import { Link } from "react-router-dom";

import { Typography } from "../../views/blocks";

const DemoErrorInfo = ({ t }) => {
  return (
    <Container className={s.container}>
      <Typography className={s.title}>
        {t("The page is not available")}
      </Typography>
      <Grid item mt={4}>
        <Typography variant="h4" weight="normal-bold">
          {t(
            "This page is not available to you because you are not a full user",
          )}
        </Typography>
        <Typography
          component="span"
          variant="body2"
          color="gray"
          weight="normal-bold"
        >
          {t("To become a full user go to")}
        </Typography>
        <Link to="/account">
          <Typography
            component="span"
            variant="body2"
            color="primary"
            className="pointer hover-opacity"
            weight="normal-bold"
          >
            {" "}
            {t("settings")}
          </Typography>
        </Link>
      </Grid>
    </Container>
  );
};

export default withTranslation()(memo(DemoErrorInfo));
