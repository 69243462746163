import {
  GET_VIDEOS,
  GET_VIDEOS_ERROR,
  GET_VIDEOS_SUCCESS,
  GET_VIDEOS_CATEGORIES,
  GET_VIDEOS_SPEECH_LANGUAGES,
  GET_GENDERS,
} from "@redux/content/constants";

import { AUTH_LOGOUT } from "@redux/auth/constants";

const initialState = {
  videos: {
    items: [],
    loading: false,
    error: false,
  },
  speech_languages: [],
  categories: [],
  genders: [],
};

export default function contentReduce(state = initialState, action = {}) {
  switch (action.type) {
    case GET_VIDEOS:
      return {
        ...state,
        videos: {
          ...state.videos,
          loading: true,
        },
      };
    case GET_VIDEOS_SUCCESS:
      return {
        ...state,
        videos: {
          items: action.payload,
          loading: false,
          error: false,
        },
      };
    case GET_VIDEOS_ERROR:
      return {
        ...state,
        videos: {
          items: [],
          loading: false,
          error: action.payload,
        },
      };
    case GET_VIDEOS_SPEECH_LANGUAGES:
      return {
        ...state,
        speech_languages: action.payload,
      };
    case GET_VIDEOS_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case GET_GENDERS:
      return {
        ...state,
        genders: action.payload,
      };
    case AUTH_LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
}
