import React, { useState, memo } from "react";
import Modal from "src/components/Modal";
import Button from "src/components/Button";
import { withTranslation } from "react-i18next";
import classes from "./DeleteModal.module.scss";

import { Grid, IconButton } from "@mui/material";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Typography } from "../../views/blocks";

const DeleteModal = ({ onSuccessDelete, trigger, question, t }) => {
  const [deleteModal, setDeleteModal] = useState(false);

  const onButtonClick = e => {
    e.stopPropagation();
    e.preventDefault();
    setDeleteModal(true);
  };

  const btn = React.cloneElement(trigger, {
    onClick: onButtonClick,
  });

  return (
    <>
      {btn}
      <Modal
        size="small"
        contentClassName={classes.container}
        show={deleteModal}
        onHide={e => {
          e.stopPropagation();
          e.preventDefault();
          setDeleteModal(false);
        }}
      >
        <Grid className={classes.content}>
          <Grid display="flex" width="100%" justifyContent="center">
            <IconButton
              sx={{
                background: "#ffc5c5",
              }}
            >
              <DeleteOutlinedIcon
                htmlColor="#EE1717"
                sx={{
                  width: "4rem",
                  height: "4rem",
                }}
              />
            </IconButton>
          </Grid>
          <Grid mt={2}>
            <Typography variant="h5" weight="semi-bold" align="center">
              {t("Confirm deletion.")}
            </Typography>
            <Typography
              variant="body2"
              color="gray"
              weight="normal-bold"
              align="center"
            >
              {question}
            </Typography>
          </Grid>
          <Grid className={classes.buttons}>
            <Button
              containerClassName={classes.buttons__cancel}
              variant="gray"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setDeleteModal(false);
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              containerClassName={classes.buttons__delete}
              variant="delete"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setDeleteModal(false);
                onSuccessDelete();
              }}
            >
              {t("Delete")}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default withTranslation()(memo(DeleteModal));
