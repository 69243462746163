export const PrivacyPolicyDE = `
<!DOCTYPE html>
<html>
<head>
\t<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
\t<title></title>
\t<meta name="generator" content="LibreOffice 7.4.2.3 (Linux)"/>
\t<meta name="author" content="Пользователь Windows"/>
\t<meta name="created" content="2022-12-05T09:52:00"/>
\t<meta name="changedby" content="Пользователь Windows"/>
\t<meta name="changed" content="2022-12-09T20:00:00"/>
\t<meta name="AppVersion" content="15.0000"/>
\t<meta name="GrammarlyDocumentId" content="6de672806029a85490989968ecee0359ca557a73d490d74c87355838f9bc0658"/>
\t<style type="text/css">
\t\t@page { size: 8.27in 11.69in; margin-left: 1.18in; margin-right: 0.49in; margin-top: 0.49in; margin-bottom: 0.59in }
\t\t@page p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
\t\t@page a:link { color: #0563c1; text-decoration: underline }
\t</style>
</head>
<body lang="uk-UA" link="#0563c1" vlink="#800000" dir="ltr"><p align="center" style="line-height: 108%; margin-bottom: 0.11in">
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><b>Datenschutzrichtlinie
von Infocoin Limited</b></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Infocoin
Limited nimmt den Schutz Ihrer Personendaten sehr ernst. Derzeit
unternehmen wir alle Anstrengungen, um die EU-Verordnung 2016/679 –
Allgemeine Datenschutzverordnung (nachfolgend DSGVO), die die
weltweit höchsten Normen für den Schutz vertraulicher Informationen
festlegt, zu erfüllen. Diese Datenschutzrichtlinie beschreibt Daten,
die wir sammeln, warum wir sie sammeln, wie wir sie verwenden und an
wen wir sie weitergeben können. Darüber hinaus legt sie fest,
welche Rechte Nutzer als betroffene Personen haben und wie diese
genutzt werden können.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Diese
Datenschutzrichtlinie gilt für alle Fälle der von uns
durchgeführten Datenverarbeitung im Zusammenhang mit der
Bereitstellung unserer Dienste durch Websites, mobile Versionen von
Websites (im Folgenden „Website“), mobile Anwendungen für IOS
und Android (im Folgenden „Anwendung“). und andere
Internetportale, die Eigentum der Marke INFOCOIN sind, von der Marke
INFOCOIN verwaltet sind oder unter der Marke INFOCOIN handeln oder zu
deren Infocoin Limited Zugang gewährt, die dazu durch den
einheitlichen Ressourcenindex Infocoin.online identifiziert sind (und
alle anderen Websites auf unserer Domain mit jeder Subdomain).</span></font></font></p>
<p lang="de-DE" align="justify" style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><b>1.
Wer wir sind</b></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Unsere
Gesellschaft, Infocoin Limited, ein in der Republik Zypern
registriertes Unternehmen (Registrierungsnummer: HE 433674), wurde
unter der Adresse: Anexartisias &amp; Athinon, NORA COURT, 2nd floor,
Office 203, 3040 Limassol, Cyprus registriert und ist der Bediener
der Personendaten.</span></font></font></p>
<p lang="de-DE" align="justify" style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><b>2.
Begriffe, die wir in dieser Datenschutzrichtlinie verwenden</b></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>Personendaten</u></span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">
– alle Informationen, die sich direkt oder indirekt auf eine
identifizierte oder identifizierbare natürliche Person beziehen.
Wenn wir in der Lage sind, eine Person oder ihr Gerät zu
identifizieren, gelten alle Informationen in unserem Besitz, die sich
auf solche Person beziehen, als Personendaten.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>Der
Bediener der Personendaten</u></span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">
ist eine Person/Organisation, die die Zwecke und Mittel der
Verarbeitung der Personendaten bestimmt. Beispielsweise fungiert
unsere Gesellschaft als Bediener der Personendaten und verarbeitet
personenbezogene Kundendaten für die unten in dieser
Datenschutzrichtlinie dargelegten Zwecke. Bei der Nutzung unserer
Website oder Anwendung übermitteln wir Ihre Personendaten an eine
Person, die Material zum Posten auf unserer Plattform bereitstellt
und die ihrerseits die Personendaten des Kunden für ihre eigenen
Zwecke verwendet. Alle Bediener der Personendaten sind verpflichtet,
Personendaten auf rechtmäßige Weise zu verwenden. Sie können ihre
Datenschutzrichtlinien auf ihren eigenen Websites lesen. Nachfolgend
finden Sie allgemeine Informationen zu den Bedienern der
Personendaten, an die wir Ihre Personendaten weitergeben können.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>Der
Bearbeiter der Personendaten</u></span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">
ist ein Dritter, eine Person, die nur hilft, die vom Bediener der
Personendaten festgelegten Ziele zu erreichen. Als Bediener der
Personendaten nehmen wir die Dienstleistungen von Drittunternehmen in
Anspruch und betrauen sie mit bestimmten Arten von Tätigkeiten, die
wir nicht unabhängig ausüben und dafür Gründe haben (z. B.
aufgrund eines wirtschaftlichen Vorteils). Der Bearbeiter der
Personendaten darf Ihre Personendaten nur gemäß unseren
schriftlichen Anweisungen verarbeiten.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>Drittländer</u></span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">
sind Länder, auf die die Rechtsordnung der DSGVO nicht ausdehnt.
Derzeit verstehen wir unter Drittstaaten alle Länder außerhalb des
Europäischen Wirtschaftsraums.</span></font></font></p>
<p lang="de-DE" align="justify" style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><b>3.
Personendaten, die wir sammeln</b></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wir
erhalten Ihre Personendaten direkt von Ihnen, wenn Sie uns diese zur
Verfügung stellen, oder wir erheben sie selbst, wenn Sie unsere
Dienste nutzen oder mit uns interagieren. Je nach Zweck der
Verarbeitung können wir die folgenden Kategorien von Personendaten
verarbeiten.</span></font></font></p>
<table width="640" cellpadding="7" cellspacing="0">
\t<col width="141"/>

\t<col width="470"/>

\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kategorie
\t\t\tvon Personendaten</span></font></font></p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Beschreibung</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Identifizierungsinformationen</span></font></font></p>
\t\t\t<p lang="de-DE" align="justify" style="orphans: 0; widows: 0"><br/>

\t\t\t</p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Informationen,
\t\t\tdie verwendet werden, um Sie als natürliche Person zu
\t\t\tidentifizieren, wie z. B. Vorname, Nachname, Geschlecht,
\t\t\tNationalität, Rechnungsadresse, Geburtsdatum, oder um Sie als
\t\t\tRechtsperson zu identifizieren – Name, Form,
\t\t\tRegistrierungsnummer usw., sowie eine künstliche
\t\t\tOnline-Kennzeichnung, die von uns in den Systemen von Infocoin
\t\t\tLimited erstellt wurde.</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kontaktinformationen</span></font></font></p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Informationen,
\t\t\tdie verwendet werden, um Sie zu kontaktieren, wie Ihre
\t\t\tE-Mail-Adresse oder Telefonnummer.</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Ihr
\t\t\tVerhalten während der Online-Interaktion mit uns</span></font></font></p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Ihr
\t\t\tVerhalten auf unserer Website und in unserer Anwendung, d. h. was
\t\t\tSie besucht haben, wie lange Sie dort geblieben sind, worauf Sie
\t\t\tgeklickt haben usw. Wir verfolgen auch Ihre Reaktion auf E-Mails
\t\t\tund Nachrichten, die wir Ihnen senden, z. B. ob Sie sie öffnen
\t\t\toder auf darin enthaltene Links klicken.</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Geräte-
\t\t\tund Netzwerk-Metadaten</span></font></font></p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Informationen
\t\t\tüber das Gerät, mit dem Sie auf unsere Website zugreifen, oder
\t\t\tdas Gerät, auf dem unsere Anwendung installiert ist; Metadaten zu
\t\t\tIhrer Netzwerkverbindung und daraus abgeleitete Informationen. Zu
\t\t\tdiesen Informationen gehören unter anderem: Daten zu Ihrem
\t\t\tBetriebssystem, Webbrowser, Bildschirmauflösung, IP-Adresse usw.</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Informationen
\t\t\tzu Ihrem Profil</span></font></font></p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Einstellungen
\t\t\tund andere Daten, die Sie erstellen, wenn Sie Ihr Infocoin-Konto
\t\t\tverwenden, wie Benachrichtigungen, Suchverlauf, bestimmte
\t\t\tEinstellungen, Profilbilder, die Kontodaten, die Sie zum Anmelden
\t\t\tbei Infocoin verwenden, wie Ihre E-Mail-Adresse und Ihr Passwort
\t\t\t(die wir nicht in einem unverschlüsselter Form speichern), Ihre
\t\t\tausgewählten Kategorien und andere im Infocoin-Konto gespeicherte
\t\t\tDaten.</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Zahlungsinformationen</span></font></font></p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Informationen,
\t\t\tdie Sie uns für den Abzug von Kosten zur Verfügung stellen. Dies
\t\t\tsind in der Regel Zahlungskartendaten. Wir speichern solche Daten
\t\t\tniemals unverschlüsselt.</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Informationen
\t\t\tüber Ihre Anfragen und die Interaktion mit ihnen</span></font></font></p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Alle
\t\t\tMitteilungen zwischen Ihnen und Infocoin Limited im Zusammenhang
\t\t\tmit Ihren Anfragen (z. B. Supportanfragen) sowie Metadaten und
\t\t\tNotizen, die von unseren Moderations- und Supportmitarbeitern
\t\t\terstellt wurden.</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="141" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Ihre
\t\t\tEinstellungen</span></font></font></p>
\t\t</td>
\t\t<td width="470" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Einige
\t\t\tIhrer Einstellungen, die sich auf die Website oder Anwendung
\t\t\tbeziehen: zum Beispiel, Spracheinstellungen, Währungseinstellungen
\t\t\tusw. sowie Cookie-Einstellungen.</span></font></font></p>
\t\t</td>
\t</tr>
</table>
<p lang="de-DE" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p lang="de-DE" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><b>4.
Zwecke der Datenbenutzung</b></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>Zwecke
der Benutzung von Benutzerdaten.</u></span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">
Jedes Mal, wenn Sie unsere Website oder Anwendung besuchen,
verarbeiten wir Ihre Personendaten für die folgenden Zwecke.</span></font></font></p>
<table width="640" cellpadding="7" cellspacing="0">
\t<col width="96"/>

\t<col width="222"/>

\t<col width="128"/>

\t<col width="136"/>

\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Titel</span></font></font></p>
\t\t</td>
\t\t<td width="222" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Beschreibung</span></font></font></p>
\t\t</td>
\t\t<td width="128" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kategorie
\t\t\tvon Personendaten</span></font></font></p>
\t\t</td>
\t\t<td width="136" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Rechtliche
\t\t\tGrundlage</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Gewährleistung
\t\t\tdes Funktionierens der Website und der Anwendung</span></font></font></p>
\t\t</td>
\t\t<td width="222" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wir
\t\t\tverarbeiten Ihre Personendaten, um Ihnen die Funktionalität
\t\t\tunserer Website und Anwendung bereitzustellen.</span></font></font></p>
\t\t</td>
\t\t<td width="128" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Ihr
\t\t\tVerhalten während der Online-Interaktion mit uns;</span></font></font></p>
\t\t\t<p align="justify" style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Geräte-
\t\t\tund Netzwerk-Metadaten;</span></font></font></p>
\t\t\t<p align="justify" style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Ihre
\t\t\tEinstellungen.</span></font></font></p>
\t\t</td>
\t\t<td width="136" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Absatz
\t\t\tf Par. 1 Artikel 6 – zum Zwecke der Verwirklichung der
\t\t\tgesetzlichen Interessen von Infocoin (Gewährleistung der
\t\t\tFunktionalität des Produkts).</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Entwicklung
\t\t\tneuer Produkte, Bereitstellung von Service und Support,
\t\t\tGeschäftsentwicklung</span></font></font></p>
\t\t</td>
\t\t<td width="222" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wir
\t\t\tsind bestrebt, unsere Produkte und Dienstleistungen kontinuierlich
\t\t\tzu verbessern. Dazu benötigen wir genaue Daten über Ihre
\t\t\tInteraktion mit uns. Daher erheben wir Daten über Ihre Geräte-
\t\t\tund Netzwerkkennzeichnungen sowie über Ihr Verhalten auf unserer
\t\t\tWebsite und in unserer Anwendung. Wir analysieren all diese Daten
\t\t\tund verwenden sie, um unsere Funktionen und Prozesse zu erstellen
\t\t\toder zu ändern. Wir verwenden Daten auch, um unser Geschäft zu
\t\t\tentwickeln. Jedes Mal, wenn wir eine Geschäftsentscheidung
\t\t\ttreffen müssen, untersuchen wir die Daten, die vom wichtigsten
\t\t\tTeil unseres Geschäfts – unseren Kunden – generiert werden.</span></font></font></p>
\t\t</td>
\t\t<td width="128" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Ihr
\t\t\tVerhalten während der Online-Interaktion mit uns;</span></font></font></p>
\t\t\t<p align="justify" style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Geräte-
\t\t\tund Netzwerk-Metadaten.</span></font></font></p>
\t\t</td>
\t\t<td width="136" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Absatz
\t\t\tf Par. 1 Artikel 6 – zum Zwecke der Verwirklichung der
\t\t\tgesetzlichen Interessen von Infocoin (Entwicklung und Verbesserung
\t\t\tvon Produkten, Dienstleistungen, Geschäft).</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Infocoin-Konto</span></font></font></p>
\t\t</td>
\t\t<td width="222" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wenn
\t\t\tSie ein Infocoin-Konto erstellen, verarbeiten wir Ihre
\t\t\tPersonendaten, um Ihnen alle seine Funktionen gemäß den
\t\t\tNutzungsbedingungen bereitzustellen. Die Menge der verarbeiteten
\t\t\tPersonendaten variiert je nachdem, welche Daten Sie speichern</span></font></font></p>
\t\t</td>
\t\t<td width="128" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kontaktinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kontaktinformationen;</span></font></font></p>
\t\t\t<p align="justify" style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Identifizierungsinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Zahlungsinformationen.</span></font></font></p>
\t\t</td>
\t\t<td width="136" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Absatz
\t\t\tb Par. 1 Artikel 6– die Notwendigkeit, einen Vertrag
\t\t\tabzuschließen und erfüllen (Nutzungsbedingungen).</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Direktmarketing</span></font></font></p>
\t\t</td>
\t\t<td width="222" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wir
\t\t\tverarbeiten personenbezogene Kundendaten für
\t\t\tDirektmarketingzwecke, um die relevantesten Angebote für Kunden
\t\t\tauszuwählen und die Marketingeffektivität zu maximieren (was das
\t\t\tVersenden von Angeboten per E-Mail und damit verbundene
\t\t\tInformationsverarbeitungsaktivitäten umfasst). Wir tun dies, wenn
\t\t\tSie sich für den Erhalt unserer Angebote angemeldet haben (auf
\t\t\tder Grundlage Ihrer Zustimmung) oder wenn Sie unsere Dienste
\t\t\tgenutzt und unsere Angebote nicht abgelehnt haben (nicht von
\t\t\tunseren Angeboten abgemeldet haben). Zusätzlich zu Ihren
\t\t\tKontaktdaten speichern wir auch solche Daten wie Ihre
\t\t\tTransaktionshistorie, Vorlieben und andere Daten über Ihre
\t\t\tInteraktionen mit uns, die uns helfen, Kunden zu segmentieren und
\t\t\tAngebote zu personalisieren. Basierend auf der Feedback- und
\t\t\tInteraktionshistorie können wir mit einem individuellen, speziell
\t\t\tfür ihn ausgewählten Angebot auf den Kunden zugehen.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Darüber
\t\t\thinaus können wir E-Mails an Kunden senden, die ihre Daten
\t\t\tangeben. Wir speichern die in unserer Datenbank erfassten
\t\t\tPersonendaten nur 30 Tage lang.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wir
\t\t\tgeben unter keinen Umständen die Kontaktinformationen des Kunden
\t\t\tohne sein Wissen an andere Betreiber von Personendaten weiter und
\t\t\tkontaktieren Kunden nur mit solchen Angeboten, die mit unserer
\t\t\tHaupttätigkeit zusammenhängen.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Damit
\t\t\tSie unsere Angebote nicht verpassen, versenden wir zusätzlich zu
\t\t\tDirektnachrichten Push-Benachrichtigungen durch unsere Website und
\t\t\tAnwendung (mit Ihrer Zustimmung).</span></font></font></p>
\t\t</td>
\t\t<td width="128" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Ihr
\t\t\tVerhalten während der Online-Interaktion mit uns;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kontaktinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Geräte-
\t\t\tund Netzwerk-Metadaten;</span></font></font></p>
\t\t\t<p align="justify" style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Identifizierungsinformationen
\t\t\t(wenn sie in den Feldern eingetragen sind);</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kontaktinformationen
\t\t\t(wenn sie in den Feldern eingetragen sind);</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Ihre
\t\t\tEinstellungen.</span></font></font></p>
\t\t</td>
\t\t<td width="136" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Absatz
\t\t\tf Par. 1 Artikel 6 – zum Zwecke der Verwirklichung der
\t\t\tgesetzlichen Interessen von Infocoin (Direktmarketing).</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Absatz
\t\t\ta Par. 1 Artikel 6  – Zustimmung.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Der
\t\t\tKunde kann den Erhalt von Direktmarketing-Benachrichtigungen
\t\t\tjederzeit ablehnen oder seine Zustimmung widerrufen und den
\t\t\tAbonnementstatus überprüfen, indem er zum Ende jeder erhaltenen
\t\t\tE-Mail anschaut. </span></font></font>
\t\t\t</p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Onlinewerbung</span></font></font></p>
\t\t</td>
\t\t<td width="222" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Um
\t\t\tIhnen bessere Angebote zu unterbreiten und die Effektivität
\t\t\tunserer Marketingaktivitäten zu maximieren, zeigen wir außerdem
\t\t\tauf der Website (Infocoin.online) und den Websites von
\t\t\tDrittbedienern Werbung an, die auf der Grundlage Ihrer
\t\t\tPersonendaten für Sie am besten geeignet ist. Daher ist es
\t\t\twahrscheinlich, dass Sie Infocoin-Werbung auf anderen Ressourcen
\t\t\tim Internet sehen werden.</span></font></font></p>
\t\t</td>
\t\t<td width="128" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Ihr
\t\t\tVerhalten während der Online-Interaktion mit uns;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kontaktinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Geräte-
\t\t\tund Netzwerk-Metadaten;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Ihre
\t\t\tEinstellungen.</span></font></font></p>
\t\t</td>
\t\t<td width="136" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Absatz
\t\t\tf Par. 1 Artikel 6 – zum Zwecke der Verwirklichung der
\t\t\tgesetzlichen Interessen von Infocoin (Direktmarketing).</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Marketinganalyse</span></font></font></p>
\t\t</td>
\t\t<td width="222" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Zur
\t\t\tallgemeinen Verbesserung unserer Marketingkampagnen führen wir
\t\t\tAnalysen durch, die es uns ermöglichen, die Effektivität von
\t\t\tKampagnen und ihre Auswirkungen auf Werbeleistungsindikatoren zu
\t\t\tverfolgen. Wir analysieren auch Ihre Interaktion mit Infocoin und
\t\t\tsenden Ihnen Angebote, die für Sie relevant sind.</span></font></font></p>
\t\t</td>
\t\t<td width="128" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Ihr
\t\t\tVerhalten während der Online-Interaktion mit uns;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Geräte-
\t\t\tund Netzwerk-Metadaten.</span></font></font></p>
\t\t</td>
\t\t<td width="136" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Absatz
\t\t\tf Par. 1 Artikel 6 – zum Zwecke der Verwirklichung der
\t\t\tgesetzlichen Interessen von Infocoin (Direktmarketing).</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Begründung
\t\t\tund Geltendmachung von Ansprüchen, Klagen und Schutz davor</span></font></font></p>
\t\t</td>
\t\t<td width="222" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wir
\t\t\tspeichern Ihre Personendaten mindestens 4 Jahre ab dem Zeitpunkt,
\t\t\tan dem Sie unsere Website oder Anwendung nutzen, um Ansprüche
\t\t\toder Klagen im Zusammenhang mit Ihrer Nutzung der Website oder
\t\t\tAnwendung unter Verstoß gegen unsere Nutzungsbedingungen oder
\t\t\tunter Verstoß gegen einige geltende gesetzliche Verpflichtungen
\t\t\tzu ermöglichen, sowie um sich gegen von Ihnen erhobene Ansprüche
\t\t\toder Klagen verteidigen zu können.</span></font></font></p>
\t\t</td>
\t\t<td width="128" style="border: 1px solid #000000; padding: 0in 0.08in"><p align="justify" style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Identifizierungsinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Ihr
\t\t\tVerhalten während der Online-Interaktion mit uns;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Geräte-
\t\t\tund Netzwerk-Metadaten;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kontaktinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kontaktinformationen
\t\t\t(wenn sie in den Feldern eingetragen sind);</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Ihre
\t\t\tEinstellungen.</span></font></font></p>
\t\t</td>
\t\t<td width="136" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Absatz
\t\t\tf Par. 1 Artikel 6 – zum Zwecke der Verwirklichung der
\t\t\tgesetzlichen Interessen von Infocoin (Schutz unserer gesetzlichen
\t\t\tRechte und Interessen).</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="96" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Schutz
\t\t\tvon Informationen</span></font></font></p>
\t\t</td>
\t\t<td width="222" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wir
\t\t\tmüssen uns vor verschiedenen Bedrohungen schützen, die
\t\t\tversuchen, Schwachstellen in unserem Sicherheitssystem auszunutzen
\t\t\tund unserem Geschäft zu schaden. Hierfür müssen wir teilweise
\t\t\tPersonendaten unserer Nutzer verarbeiten.</span></font></font></p>
\t\t</td>
\t\t<td width="128" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Ihr
\t\t\tVerhalten während der Online-Interaktion mit uns;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Geräte-
\t\t\tund Netzwerk-Metadaten.</span></font></font></p>
\t\t</td>
\t\t<td width="136" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Absatz
\t\t\tf Par. 1 Artikel 6 – zum Zwecke der Verwirklichung der
\t\t\tgesetzlichen Interessen von Infocoin (Sicherheit).</span></font></font></p>
\t\t</td>
\t</tr>
</table>
<p lang="de-DE" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>Zwecke
der Verwendung der Daten von Postenden.</u></span></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">
Wenn Sie Dienstleistungen bestellen, verarbeiten wir Ihre
Personendaten weiterhin für die oben genannten Zwecke. Die zu diesen
Zwecken verarbeiteten Personendaten umfassen Informationen über Ihre
Platzierung von dem Content, die Teilnahme an der Auktion,
Informationen über Ihre Anfragen und Informationen im Zusammenhang
mit dem Austausch von Nachrichten zwischen uns. Darüber hinaus
verarbeiten wir Ihre Personendaten für die folgenden Zwecke.</span></font></font></p>
<table width="641" cellpadding="7" cellspacing="0">
\t<col width="106"/>

\t<col width="232"/>

\t<col width="124"/>

\t<col width="122"/>

\t<tr valign="top">
\t\t<td width="106" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-right: -0.76in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Titel</span></font></font></p>
\t\t</td>
\t\t<td width="232" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Beschreibung</span></font></font></p>
\t\t</td>
\t\t<td width="124" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kategorie
\t\t\tvon Personendaten</span></font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Rechtliche
\t\t\tGrundlage</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="106" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Hosting
\t\t\tvon Content und Diensten</span></font></font></p>
\t\t</td>
\t\t<td width="232" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Der
\t\t\tHauptgrund für unsere Erhebung und Verwendung Ihrer Personendaten
\t\t\tbesteht darin, einen Vertrag mit Ihnen abzuschließen und die
\t\t\tbestellten Dienstleistungen weiter zu erbringen. Abhängig vom
\t\t\tUmfang der Benutzung unserer Dienste durch den Kunden verarbeiten
\t\t\twir Personendaten nach Bedarf, die durch den Abschluss und die
\t\t\tErfüllung von Vereinbarungen über die Erbringung von
\t\t\tDienstleistungen vorgegeben sind, die durch die Nutzung der
\t\t\tWebsite-Funktionalität, das Drücken der entsprechenden Tasten
\t\t\tund das Ausfüllen von Informationsblöcken abgeschlossen wurden.
\t\t\tBei der Auftragsausfertigung wird dem Kunden eine Anfrage um
\t\t\tdirekte Zustimmung zur Verarbeitung der angegebenen Personendaten
\t\t\tzugesandt. Der Kunde hat jederzeit das Recht, seine Zustimmung zu
\t\t\twiderrufen, indem er den Support-Service unter den folgenden Links
\t\t\tkontaktiert: </span></font></font><font><u><a href="https://business.infocoin.online/support"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">https://business.infocoin.online/support</span></font></font></a></u></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">
\t\t\t</span></font></font><font><u><a href="https://view.infocoin.online/help"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">https://view.infocoin.online/help</font></font></a></u></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">.
\t\t\tBitte beachten Sie gleichzeitig, dass wir nach dem Widerruf der
\t\t\tZustimmung zur Verarbeitung von Personendaten dem Kunden keine
\t\t\tweitere Unterstützung im Zusammenhang mit aktuellen
\t\t\tDienstleistungen anbieten können. Falls erforderlich, verarbeiten
\t\t\twir Ihre Personendaten, um einen Vertrag mit Ihnen abzuschließen
\t\t\tund die bestellten Dienstleistungen zu erbringen, manchmal können
\t\t\tPersonendaten von Drittbedienern verarbeitet werden, vorbehaltlich
\t\t\taller genannten Verpflichtungen bezüglich ihrer Aufbewahrung und
\t\t\tSpeicherung, unter unserer sensiblen Kontrolle und mit
\t\t\tschriftlicher Zustimmung. Eine vollständige Liste von
\t\t\tDrittbedienern von Personendaten, an die wir personenbezogene
\t\t\tKundendaten weitergeben können, kann auf schriftliche Anfrage zur
\t\t\tVerfügung gestellt werden.</span></font></font></p>
\t\t</td>
\t\t<td width="124" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Identifizierungsinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kontaktinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Informationen
\t\t\tzu Ihrer Bestellung (і);</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Informationen
\t\t\tzu den bereitgestellten Dokumenten;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Zahlungsinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Dokumente,
\t\t\tdie für die Erbringung von Dienstleistungen, die Dritten
\t\t\tanvertraut wurden, erforderlich sind;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Lizenzen
\t\t\tund andere mit der Bestellung verbundene Dokumente.</span></font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Absatz
\t\t\tb Par. 1 Artikel 6– die Notwendigkeit, einen Vertrag
\t\t\tabzuschließen und erfüllen.</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="106" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Betrugsprävention</span></font></font></p>
\t\t</td>
\t\t<td width="232" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Um
\t\t\tbetrügerische Rückbuchungen zu vorbeugen, können wir, wenn ein
\t\t\tKunde eine betrügerische Überweisung von Geldern über sein
\t\t\tBankkonto meldet, seine Sozial-Netzwerke-Informationen überprüfen,
\t\t\tum den Kunden auf eine Verbindung zu der Person zu überprüfen,
\t\t\tdie die Content-Hosting-Dienste oder andere Dienste bestellt hat,
\t\t\tum sicherzustellen, dass dies der Fall ist kein Versuch, Gelder,
\t\t\tdie für Content-Hosting-Dienste und andere Dienste ausgegeben
\t\t\twurden, in betrügerischer Weise zurückzufordern. Wir verarbeiten
\t\t\tnur die begrenzten Informationen, die zur Überprüfung des Kunden
\t\t\tfür die Kontaktaufnahme mit der Person, die die
\t\t\tContent-Hosting-Dienste oder andere Dienste bestellt hat, sowie
\t\t\tzur Überprüfung der Veröffentlichung von Daten, die sich auf
\t\t\tden konkreten Fall beziehen, von diesem Kunden erforderlich sind.</span></font></font></p>
\t\t</td>
\t\t<td width="124" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Identifizierungsinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kontaktinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Informationen
\t\t\tzu Ihrer Bestellung (і);</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Informationen
\t\t\tzu Reisedokumenten;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Zahlungsinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Ihr
\t\t\tVerhalten bei der Online-Interaktion mit uns;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Geräte-
\t\t\tund Netzwerkinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Öffentliche
\t\t\tInformationen im Zusammenhang mit Ihrer Bestellung.</span></font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Absatz
\t\t\tf Par. 1 Artikel 6 –– zum Zwecke der Verwirklichung der
\t\t\tgesetzlichen Interessen von Infoсoin und der E-Commerce- und
\t\t\tE-Werbebranche im Allgemeinen (Betrugsprävention).</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="106" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kundenunterstützung</span></font></font></p>
\t\t</td>
\t\t<td width="232" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kundenunterstützung
\t\t\tist ein wichtiger Bestandteil unserer Dienstleistungen. Um Kunden
\t\t\tdie von ihnen gewünschten Dienstleistungen zu erbringen, erfassen
\t\t\twir alle Informationen im Rahmen der Kommunikation mit Kunden über
\t\t\talle Kanäle, einschließlich E-Mail, Chat und Telefon.</span></font></font></p>
\t\t</td>
\t\t<td width="124" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Identifizierungsinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kontaktinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Informationen
\t\t\tzu Ihrer Bestellung (і);</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Informationen
\t\t\tzu Reisedokumenten;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Informationen
\t\t\tzu Ihre Anfragen und Interaktionen mit uns.</span></font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Absatz
\t\t\tb Par. 1 Artikel 6– die Notwendigkeit, einen Vertrag
\t\t\tabzuschließen und erfüllen.</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="106" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Begründung
\t\t\tund Geltendmachung von Ansprüchen und Klagen sowie Schutz vor
\t\t\tAnsprüchen und Klagen</span></font></font></p>
\t\t</td>
\t\t<td width="232" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wir
\t\t\tspeichern und verarbeiten personenbezogene Daten von Kunden zum
\t\t\tZweck der Begründung, Geltendmachung von Ansprüchen und Schutz
\t\t\tgegen ihnen. Bei jeder Nutzung der Webseite speichern wir alle
\t\t\trelevanten Daten für den Fall möglicher Ansprüche oder Klagen,
\t\t\tdie von Ihnen oder uns erhoben werden können, insbesondere im
\t\t\tRahmen von Gerichtsverfahren und sonstigen Verfahren sowie bei der
\t\t\tGeltendmachung oder dem Verkauf von Rechten auf die uns von Ihnen
\t\t\tabgetretenen Forderungen für mindestens 4 Jahre ab Erstellung des
\t\t\tentsprechenden Auftrages. Wenn wir Datenschutzanfragen von Kunden
\t\t\terhalten, speichern wir zu diesem Zweck auch alle vom Kunden
\t\t\tbereitgestellten Daten und alle Informationen im Zusammenhang mit
\t\t\tder Bearbeitung der Anfrage.</span></font></font></p>
\t\t</td>
\t\t<td width="124" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Identifizierungsinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kontaktinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Informationen
\t\t\tzu Ihrer Bestellung (і);</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Zahlungsinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Ihr
\t\t\tVerhalten bei der Online-Interaktion mit uns;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Geräte-
\t\t\tund Netzwerkinformationen;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Öffentliche
\t\t\tInformationen im Zusammenhang mit Ihrer Bestellung;</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Informationen
\t\t\tzu Ihre Anfragen und Interaktionen mit uns.</span></font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Absatz
\t\t\tf Par. 1 Artikel 6 – zum Zwecke der Verwirklichung der
\t\t\tgesetzlichen Interessen von Infocoin (Schutz unserer gesetzlichen
\t\t\tRechte).</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="106" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Erfüllung
\t\t\tgesetzlicher Verpflichtungen</span></font></font></p>
\t\t</td>
\t\t<td width="232" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Gegebenenfalls
\t\t\tmüssen wir bestimmte personenbezogene Kundendaten verarbeiten, um
\t\t\tdie unserer Gesellschaft auferlegten gesetzlichen Verpflichtungen
\t\t\tzu erfüllen. Wir brauchen für eine solche Verarbeitung keine
\t\t\tZustimmung des Kunden, da dies eine rechtliche Notwendigkeit ist.
\t\t\tZu diesem Zweck verarbeiten wir die Identifikations- und
\t\t\tKontaktinformationen jedes Kunden und Informationen über die
\t\t\tdurchgeführten Transaktionen. Die wichtigsten Normen, die uns
\t\t\tdazu verpflichten, die genannten Maßnahmen zu ergreifen, sind in
\t\t\tden folgenden Gesetzen enthalten: Bürgerliches Gesetzbuch,
\t\t\tKonsumentenschutzgesetz, Abgabenordnung, insbesondere in Bezug auf
\t\t\tdie Mehrwertsteuer und die Steuerberichterstattung. Wenn ein Kunde
\t\t\tuns eine Anfrage zur Ausübung von Rechten in Bezug auf seine
\t\t\tPersonendaten sendet, bitten wir ihn, bestimmte Personendaten
\t\t\tbereitzustellen und diese dann zu verarbeiten, um geltendes Recht
\t\t\teinzuhalten.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Für
\t\t\tden Fall, dass die für unsere Beziehung zu Ihnen geltenden
\t\t\tGesetze die Erhebung solcher Daten nicht direkt vorschreiben,
\t\t\twerden wir sie nicht erheben.</span></font></font></p>
\t\t</td>
\t\t<td width="124" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Informationen
\t\t\tzu Ihrer Bestellung (і);</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Informationen
\t\t\tzu Ihre Anfragen und Interaktionen mit uns.</span></font></font></p>
\t\t</td>
\t\t<td width="122" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Absatz
\t\t\tc Par. 1 Artikel 6 — die Notwendigkeit, rechtliche
\t\t\tVerpflichtungen zu erfüllen, die für Infocoin gelten.</span></font></font></p>
\t\t</td>
\t</tr>
</table>
<p lang="de-DE" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><b>5.
An wen und warum wir Ihre Personendaten weitergeben</b></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>-
Weitergabe von Personendaten an andere Bediener von Personendaten.</u></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">In
einigen Fällen geben wir Personendaten an Dritte weiter, die sie für
ihre eigenen Zwecke verwenden.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Daher
können personenbezogene Kundendaten an bestimmte Dienstleister,
Content-Hosts und Werbetreibende, einschließlich Drittländern,
weitergegeben werden.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Alle
diese Personen verwenden Personendaten in Übereinstimmung mit ihren
Datenschutzrichtlinien (veröffentlicht auf ihrer Website) oder,
falls sie keine eigene haben, in Übereinstimmung mit unseren
Standards für die Sicherheit und Verwendung von Personendaten. Die
Weitergabe von Daten erfolgt gemäß den geltenden
Personendaten-Vorschriften. Wenn Sie der Einstellung von Cookies
zustimmen, übermitteln wir einige Ihrer Daten zu Marketingzwecken an
unsere Partner.</span></font></font></p>
<p lang="de-DE" align="justify" style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<table width="660" cellpadding="7" cellspacing="0">
\t<col width="427"/>

\t<col width="203"/>

\t<tr valign="top">
\t\t<td width="427" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kategorie
\t\t\tdes Empfängers der Personendaten</span></font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Beispiele</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="427" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Content-Postende</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Zur
\t\t\tErfüllung eigener Zwecke, wie z. B. Targeting, werden Ihre
\t\t\tPersonendaten entsprechend verarbeitet</span></font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Eine
\t\t\tPerson, die dem Content auf der Infocoin-Plattform veröffentlicht</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="427" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Andere
\t\t\tDienstleister</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Im
\t\t\tZusammenhang mit der Hauptaktivität der Website,
\t\t\tDrittorganisationen, die Ihre Personendaten in Übereinstimmung
\t\t\tmit ihren Datenschutzrichtlinien sowie in Übereinstimmung mit den
\t\t\tanerkannten Normen der internationalen Gesetzgebung verarbeiten</span></font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Dienstleister</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="427" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Betreiber
\t\t\tfür den Empfang und die Verarbeitung von Zahlungen</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in; background: #f8f9fa">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Die
\t\t\tAusführung eines Zahlungsauftrags auf einer Website ist nur der
\t\t\tAnfang einer langen Kette technischer Vorgänge, die erforderlich
\t\t\tsind, um eine Zahlung auf einem (Bank-)Konto zu erhalten. Die
\t\t\tInformationen müssen verschiedene Zahlungsanbieter und
\t\t\tAcquiring-Banken durchlaufen. Alle diese Personen handeln als
\t\t\tBetreiber von Personendaten, da sie diese Daten vollständig
\t\t\tkontrollieren.</span></font></font></p>
\t\t\t<p lang="de-DE" style="orphans: 0; widows: 0"><br/>

\t\t\t</p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">PayPal</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="427" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Kommunikationsplattformen
\t\t\tund soziale Netzwerke</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Um
\t\t\tIhnen Mitteilungen zu unseren Diensten zu senden oder Ihnen zu
\t\t\termöglichen, sich über Plattformen von Drittanbietern in Ihr
\t\t\tInfocoin-Konto einzuloggen, müssen wir Ihre Personendaten an den
\t\t\tAnbieter der gewählten Plattform übermitteln.</span></font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Facebook
\t\t\tIreland Ltd.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Google
\t\t\tIreland Limited</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="427" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Personen,
\t\t\tdie juristische Dienstleistungen erbringen</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wir
\t\t\tkönnen die Dienste anderer Unternehmen nutzen, um unsere
\t\t\tgesetzlichen Rechte zu schützen und Ansprüche geltend zu machen.</span></font></font></p>
\t\t</td>
\t\t<td width="203" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Rechtsanwälte,
\t\t\tInkassobüros</span></font></font></p>
\t\t</td>
\t</tr>
</table>
<p lang="de-DE" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 100%; margin-bottom: 0in; border: none; padding: 0in">
<font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>-
Weitergabe von Informationen an Verarbeiter von Personendaten.</u></span></font></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Es
gibt eine Vielzahl von Tätigkeiten, die für unsere Gesellschaft
notwendig sind und die wir nicht alleine durchführen können. Um sie
zu erfüllen, nutzen wir Dienste von Drittpartnern, an die wir die
Personendaten von Kunden weitergeben, da sie oft sie streng bedürfen.
Gleichzeitig behält unsere Gesellschaft in solchen Fällen den
Status des Bedieners von Personendaten und diese Drittpartner handeln
als Verarbeiter von Personendaten.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Deshalb
können sie Personendaten nur in Übereinstimmung mit unseren Zwecken
verarbeiten, und wir sind immer für die Informationen
verantwortlich. Sie dürfen die Daten keinesfalls für eigene Zwecke
oder entgegen unserer Zustimmung verwenden.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Darüber
hinaus arbeiten wir nur mit solchen Partnern zusammen, die uns
ausreichende Garantien für die Erfüllung der gesetzlichen
Anforderungen und die vollständige Sicherheit der
Kundeninformationen geben.</span></font></font></p>
<table width="640" cellpadding="7" cellspacing="0">
\t<col width="295"/>

\t<col width="316"/>

\t<tr valign="top">
\t\t<td width="295" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Empfängerkategorie</span></font></font></p>
\t\t</td>
\t\t<td width="316" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Beispiele</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="295" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Personen,
\t\t\tdie Vollstreckungsdienste erbringen.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Bei
\t\t\tdem Schutz unserer Interessen in Streitfällen können wir die
\t\t\tDienste Dritter in Anspruch nehmen, um Ihre Rechte und Interessen
\t\t\tzu schützen.</span></font></font></p>
\t\t</td>
\t\t<td width="316" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Rechtsanwälte,
\t\t\tInkassobüros</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="295" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Online-Werbedienste.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Dank
\t\t\tdieser Dienste können wir Ihnen persönliche Werbung im Internet
\t\t\tanzeigen.</span></font></font></p>
\t\t</td>
\t\t<td width="316" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Facebook
\t\t\tIreland Ltd. (Facebook for Business)</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Google
\t\t\tBusiness</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Criteo
\t\t\tSA</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">CityBusiness
\t\t\tMedia, LLc.</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="295" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Analytische
\t\t\tWerkzeuge.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wir
\t\t\tverwenden Datenanalyse- und Protokollierungssoftwaretools von
\t\t\tDrittbedienern, um einen umfassenden Überblick über unseren
\t\t\tKundenstamm zu gewährleisten und den Kundenfreundlichkeit unserer
\t\t\tDienstleistungen zu verbessern.</span></font></font></p>
\t\t</td>
\t\t<td width="316" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Exponea
\t\t\ts.r.o.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Facebook
\t\t\tIreland Ltd.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Google
\t\t\tLLC.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Keboola
\t\t\ts.r.o.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">GoodData
\t\t\tCorporation.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Datadog
\t\t\tInc.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Logmole</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Loggly,
\t\t\tInc. (SolarWinds Worldwide, LLC)</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Functional
\t\t\tSoftware, Inc. dba Sentry</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Smartsupp.com,
\t\t\ts.r.o.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Mapbox,
\t\t\tInc.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">FullStory,
\t\t\tInc.</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="295" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0; margin-bottom: 0.11in">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Software-Entwicklung.</span></font></font></p>
\t\t\t<p style="orphans: 0; widows: 0"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wir
\t\t\tgeben Ihre Personendaten manchmal an unsere Entwickler weiter, die
\t\t\tbeim Aufbau unserer Technologien helfen.</span></font></font></p>
\t\t</td>
\t\t<td width="316" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wir
\t\t\tarbeiten derzeit mit vielen Softwareentwicklern zusammen. Wir
\t\t\tgeben ihre Personendaten nicht weiter, um ihr Recht auf
\t\t\tVertraulichkeit zu beachten.</span></font></font></p>
\t\t</td>
\t</tr>
</table>
<p lang="de-DE" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><b>6.
Dauer der Speicherung von Personendaten</b></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">In
der Regel verarbeiten wir Ihre Personendaten, bis wir sie nicht mehr
für die in dieser Datenschutzerklärung genannten Zwecke verwenden
müssen. Wir verarbeiten Ihre Personendaten normalerweise für die
gesetzliche Verjährungsfrist, die in der Regel 3 Jahre beträgt,
zuzüglich eines weiteren Jahres aufgrund der Zeitreserve, die durch
die Verzögerung bei der Zustellung von Mitteilungen und unsere
zusätzlichen Maßnahmen erforderlich ist.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Um
gesetzlichen Verpflichtungen zu erfüllen, verarbeiten wir Ihre
Personendaten während den gesetzlich vorgeschriebenen Zeitraum,
beispielsweise während der 10-jährigen Speicherungsfrist für
Konten.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Zum
Zwecke der Führung des Infocoin-Kontos eines Kunden bewahren wir
Personendaten während 5 Jahre ab dem Datum der letzten von Ihnen
während der Führung des Kontos vorgenommenen Aktion auf.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Zum
Zweck personalisierter Angebote senden wir regelmäßig E-Mails an
Kunden, die jeweils eine einfache und klare Möglichkeit bieten, sich
von dieser Art der Verarbeitung abzumelden. Wir speichern und
verwenden die Personendaten des Kunden zu diesem Zweck, bis Sie sich
vom Newsletter abmelden.</span></font></font></p>
<p lang="de-DE" align="justify" style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><b>7.
Zugriff auf Ihre Personendaten und deren Kontrolle erhalten</b></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wir
sind daran interessiert sicherzustellen, dass Kunden jederzeit die
Kontrolle über ihre Personendaten haben. Kunden haben bestimmte
Rechte. Kunden können unter bestimmten Bedingungen:</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
sich umfassende Informationen darüber bekommen, welche Daten wir
verarbeiten und eine Kopie davon erhalten;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
uns eine Anfrage zur Löschung Ihrer Daten zu senden;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
die uns vorliegenden Daten zu berichtigen, wenn darin Fehler
festgestellt werden;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
die Datenverarbeitung einschränken;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
der Verarbeitung widersprechen;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
Ihre Personendaten in einem allgemein akzeptierten maschinenlesbaren
Format erhalten und selbst verwalten.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Um
Ihre in diesem Abschnitt angegebenen Rechte auszuüben, können Sie
den Support-Service unter den folgenden Links kontaktieren:
</span></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>https://business.infocoin.online/support
oder </u></span></font></font></font><font><u><a href="https://view.infocoin.online/help"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">https://view.infocoin.online/help</font></font></a></u></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Bitte
beachten Sie, dass wir zum Schutz von Personendaten nur Anfragen
bearbeiten, die von dem Konto gesendet werden, in dem die bei der
Anmeldung verwendete E-Mail-Adresse angegeben ist. Wenn Sie Ihre
E-Mail-Adresse ändern möchten, bitten wir Sie um zusätzliche
Informationen, um sicherzustellen, dass der Kunde tatsächlich der
Eigentümer der Personendaten ist.</span></font></font></p>
<p lang="de-DE" align="justify" style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>-
Zugriff auf Ihre persönlichen Daten</u></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Sie
können jederzeit eine Anfrage an den Support-Service über die
folgenden Links senden: </span></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>https://business.infocoin.online/support</u></span></font></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">
</span></font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">oder
</span></font></font><font><u><a href="https://view.infocoin.online/help"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">https://view.infocoin.online/help</font></font></a></u></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">,
um herauszufinden, ob wir Ihre Personendaten verarbeiten und um
folgende Informationen zu erhalten:</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
die Zwecke, für die wir Ihre Personendaten verarbeiten;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
Kategorien der Personendaten, die wir verarbeiten;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
eine Liste von Dritten, an die wir Ihre Personendaten übermitteln,
insbesondere wenn sich die Dritten in Drittländern befinden;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
Dauer der Verarbeitung Ihrer Personendaten oder ihrer Speicherung;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
Ihre Rechte als betroffene Person gemäß der DSGVO
(Datenschutz-Grundverordnung);</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
Ihr Recht, Klagen an Aufsichtsbehörden einzureichen;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
Wo wir Ihre Daten erhalten haben, wenn wir sie nicht direkt von Ihnen
erhalten haben;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
alle Informationen zur automatisierten Entscheidungsfindung (wenn
vorhanden), die für Sie gelten könnten;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
wann die Daten an ein Drittland übermittelt wurden und welche
Sicherheitsmaßnahmen gemäß der DSGVO angewendet werden;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wir
stellen Ihnen auf Anfrage auch eine vollständige Kopie aller von uns
verarbeiteten Personendaten zur Verfügung. Die erste Kopie der Daten
wird kostenlos zur Verfügung gestellt. Für Nachkopien wird jedoch
eine Gebühr zur Deckung der Verwaltungskosten erhoben.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Sie
können auch verlangen, dass Daten in einem weit verbreiteten
Datenübertragungsformat bereitgestellt werden.</span></font></font></p>
<p lang="de-DE" align="justify" style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>-
Löschung von Personendaten</u></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Sie
haben auch das Recht auf vollständige Löschung der Personendaten
(genau gesagt auf ihre vollständige und unabänderliche
Anonymisierung), wenn einer der folgenden Punkte auf Sie zutrifft:</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
Wir benötigen Ihre Personendaten nicht mehr für die in dieser
Datenschutzrichtlinie angegebenen Zwecke;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
Sie haben der Verarbeitung von Personendaten gemäß Artikel 21 der
DSGVO erfolgreich widersprochen, und wir haben keine anderen Zwecke
für die Verarbeitung Ihrer Personendaten;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
Wir haben Ihre Personendaten rechtswidrig verarbeitet, oder</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">es
besteht eine rechtlich verbindliche Verpflichtung, die uns
verpflichtet, Ihre Personendaten zu löschen;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Sie
haben jedoch kein Recht, die Löschung der Personendaten zu
verlangen, wenn die Verarbeitung erforderlich ist für:</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
Ausübung des Rechts auf freie Meinungsäußerung und Information;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
Erfüllung einer rechtlich bindenden Verpflichtung, die uns
verpflichtet, Personendaten zu speichern;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
Wir benötigen Personendaten zur Erhebung und Einziehung von Klagen
sowie zum Schutz von Ansprüchen.</span></font></font></p>
<p lang="de-DE" align="justify" style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>-
Berichtigung von Personendaten</u></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wenn
Sie der Meinung sind, dass Ihre Personendaten, die wir verarbeiten,
falsch sind, können Sie uns dies mitteilen, und wir werden unser
Bestes tun, um sie zu korrigieren.</span></font></font></p>
<p lang="de-DE" align="justify" style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>-
Einschränkungen bei der Verarbeitung Ihrer Personendaten</u></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Unter
bestimmten Voraussetzungen schränken wir die Verarbeitung Ihrer
Personendaten ein. Das bedeutet, dass wir sicherstellen, dass sie zu
keinem anderen Zweck als der Archivierung oder Verlagerung in ein
sicheres Archiv verarbeitet werden. Sie haben das Recht, eine
Einschränkung zu verlangen, wenn:</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><br/>
-
Sie haben die Richtigkeit Ihrer Personendaten bestritten (wir werden
sie nach der Lösung des Problems weiter verarbeiten);</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
wir Ihre Personendaten rechtswidrig verarbeitet haben, Sie aber statt
Löschung die Einschränkung der Verarbeitung verlangt haben;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
der einzige verbleibende Zweck der Verarbeitung Ihrer Personendaten
die Geltendmachung und Erhebung und Einziehung von Klagen sowie deren
Schutz ist;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
Sie lehnen die Datenverarbeitung gemäß Artikel 21 Absatz 1 der
DSGVO ab und wir prüfen die Berechtigung Ihrer Anfrage.</span></font></font></p>
<p lang="de-DE" align="justify" style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>-
Widerspruch gegen die Verarbeitung Ihrer Personendaten</u></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Sie
können jedem Zweck widersprechen, für den wir Ihre Personendaten
aufgrund der gesetzlichen Begründung des rechtlichen Interesses
verarbeiten. Wenn Sie der Datenverarbeitung zu Marketingzwecken
widersprechen, werden wir die Verwendung Ihrer Personendaten für
diesen Zweck unverzüglich einstellen.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wenn
Sie einem anderen Zweck auf der Grundlage eines berechtigten
Interesses widersprechen, werden wir die Verarbeitung Ihrer
Personendaten für diesen Zweck einstellen, es sei denn, wir können
nachweisen, dass unsere berechtigten Gründe für die Verarbeitung
Vorrang vor Ihren persönlichen Interessen, Rechten und Freiheiten
haben.</span></font></font></p>
<p lang="de-DE" align="justify" style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><span lang="de-DE"><br/>
</span><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>-
Gewährleistung der Übertragung Ihrer Personendaten</u></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Schließlich
haben Sie das Recht, Ihre verarbeiteten Personendaten (auf der
Grundlage einer Zustimmung oder der Notwendigkeit für den Abschluss
eines Vertrags oder der Notwendigkeit zur Erfüllung der
Verpflichtungen daraus) in einem allgemein verbreiteten und
maschinenlesbaren Format zu erhalten, und Sie haben das Recht, diese
Daten an andere Bediener von Personendaten nach Ihrer Wahl zu
übertragen.</span></font></font></p>
<p lang="de-DE" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><b>8.
Cookie-Dateien und ähnliche Technologien</b></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Cookies
sind kleine Textdateien, die auf Ihrem Gerät platziert werden und es
uns ermöglichen, bestimmte Informationen über Sie für verschiedene
Zwecke zu speichern, z.B. Website- und Anwendungsfunktionalität,
Produktentwicklung, Serviceverbesserung und Geschäftsentwicklung,
Bereitstellung eines Infocoin-Kontos, Online-Werbung,
Marketinganalysen, Informationssicherheit, Betrugsprävention.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Grundsätzlich
verwendet (oder verwenden kann) unsere Website drei Arten von
Cookies:</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
Cookies, die für den Betrieb der Website und die Bereitstellung von
Diensten erforderlich sind (sie können nicht deaktiviert werden);</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
sogenannte „operative“ Cookies, die verwendet werden, um
statistische Daten zu erhalten, um unsere Dienste zu verbessern;</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">-
Cookies, die zu Marketingzwecken verwendet werden.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Cookies,
die zum Erhalt statistischer Daten und zu Marketingzwecken verwendet
werden, können über die Einstellungen deaktiviert werden.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Unter
</span></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>www.aboutcookies.org</u></span></font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">,
</span></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>www.allaboutcookies.org</u></span></font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">
oder </span></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>www.civicuk.com/cookie-control/browser-settings</u></span></font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">
erfahren Sie mehr über Cookies, einschließlich deren Verwaltung
oder Löschung. Bitte beachten Sie jedoch, dass einige Funktionen
unserer Website möglicherweise nicht funktionieren, wenn Cookies
gelöscht werden.</span></font></font></p>
<table width="640" cellpadding="7" cellspacing="0">
\t<col width="224"/>

\t<col width="386"/>

\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Cookies</span></font></font></p>
\t\t</td>
\t\t<td width="386" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Ziel</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">_business.infocoin.online</span></font></font></p>
\t\t</td>
\t\t<td width="386" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Die
\t\t\tDateien, die für die Verwaltung, Statistiken und andere für
\t\t\tunsere Entwickler wichtige Werte erforderlich sind</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">_view.infocoin.online</span></font></font></p>
\t\t</td>
\t\t<td width="386" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Die
\t\t\tDateien, die um die Leistung der Architektur, die Leistung des
\t\t\tCodes und anderer für unsere Entwickler notwendigen Werte
\t\t\tanzuzeigen, erforderlich sind</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">helpcrunch-widget</span></font></font></p>
\t\t</td>
\t\t<td width="386" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Mit
\t\t\tdem SameSite-Attribut des HTTP Set-Cookie-Antwortheaders können
\t\t\tSie angeben, ob Ihre Cookies auf die besuchte Website, Dritte oder
\t\t\tUnterdomains der Website beschränkt werden sollen.</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">_ga</span></font></font></p>
\t\t</td>
\t\t<td width="386" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Benutzeridentifikation
\t\t\t(Google Analytics 4)</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">_gid</span></font></font></p>
\t\t</td>
\t\t<td width="386" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Benutzeridentifikation
\t\t\t(Google Analytics 4)</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">_ga_&lt;container-id&gt;</span></font></font></p>
\t\t</td>
\t\t<td width="386" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Sitzungsstatus
\t\t\tspeichern (Google Analytics 4)</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">_ga</span></font></font></p>
\t\t</td>
\t\t<td width="386" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Benutzeridentifikation
\t\t\tUniversal Analytics (analytics.js)</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">_gid</span></font></font></p>
\t\t</td>
\t\t<td width="386" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Benutzeridentifikation
\t\t\tUniversal Analytics (analytics.js)</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">_gat</span></font></font></p>
\t\t</td>
\t\t<td width="386" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Begrenzt
\t\t\tdie Anzahl der Anfragen. Wenn die Google Analytics-Unterstützung
\t\t\tmit Google Tag Manager implementiert wird, erhält die Datei den
\t\t\tNamen _dc_gtm_&lt;property-id&gt;</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">AMP_TOKEN</span></font></font></p>
\t\t</td>
\t\t<td width="386" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">enthält
\t\t\tein Token, mit dem die Client-ID vom AMP-Dienst abgerufen werden
\t\t\tkann</span></font></font></p>
\t\t</td>
\t</tr>
\t<tr valign="top">
\t\t<td width="224" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">_gac_&lt;property-id&gt;</span></font></font></p>
\t\t</td>
\t\t<td width="386" style="border: 1px solid #000000; padding: 0in 0.08in"><p style="orphans: 0; widows: 0">
\t\t\t<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Enthält
\t\t\tInformationen über die Gesellschaft des Benutzers. Nach dem
\t\t\tHerstellen einer Verbindung zwischen dem Google Analytics- und dem
\t\t\tGoogle Ads-Kontos erhalten die auf der Website platzierten Google
\t\t\tAds-Conversion-Tags Daten aus der Cookie-Datei.</span></font></font></p>
\t\t</td>
\t</tr>
</table>
<p lang="de-DE" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><b>9.
Übermittlung von Daten außerhalb des Europäischen Wirtschaftsraums</b></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Bei
Bedarf können wir Ihre Personendaten außerhalb des Europäischen
Wirtschaftsraums übermitteln. Dies geschieht, wenn ein Kunde Werbung
aus einem Drittland kaufen möchte oder eine der Dienstleistungen aus
einem Drittland bestellt. Die Notwendigkeit, Kundeninformationen an
solche Dritten zu übermitteln, ist selbstverständlich, da ohne sie
die Erbringung von Dienstleistungen unmöglich wäre. Wir können
Ihre Personendaten auch außerhalb des Europäischen Wirtschaftsraums
an Bediener von Personendaten in Drittländern übermitteln.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Bei
der Übermittlung von Daten in Länder, in denen wir uns nicht auf
ein angemessenes Schutzniveau gemäß Artikel 45 der DSGVO und
angemessene Sicherheitsmaßnahmen gemäß Artikel 46 der DSGVO
verlassen können, übermitteln wir Personaldaten auf der Grundlage
der in Absatz b Par. 1 Artikel 49 der DSGVO-Verordnung. Alle
Dienstleister verwenden Personendaten in Übereinstimmung mit ihren
Datenschutzrichtlinien (die auf ihren Websites veröffentlicht sind)
oder, falls sie keine solchen Richtlinien haben, in Übereinstimmung
mit unserer Datenschutzrichtlinie. Die Weitergabe der Personendaten
an andere Dienstleister erfolgt gemäß den geltenden
Personendaten-Vorschriften.</span></font></font></p>
<p lang="de-DE" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><b>10.
Beschwerden an Aufsichtsbehörden</b></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Datenschutz
ist ein ernstes Thema, und die Ausübung der Regeln ist eine ziemlich
große Herausforderung. Niemand ist perfekt und wir können Fehler
machen. Wenn Sie feststellen, dass wir Ihre Personendaten missbraucht
haben, kontaktieren Sie uns bitte zunächst: Wir werden unser Bestes
tun, um das Problem zu lösen. Sie können uns jederzeit bei Fragen
zum Datenschutz kontaktieren, indem Sie den Support-Service unter den
folgenden Links kontaktieren:
</span></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>https://business.infocoin.online/support</u></span></font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">
oder </span></font></font><font><u><a href="https://view.infocoin.online/help"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">https://view.infocoin.online/help</font></font></a></u></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">,
oder schreiben Sie an unser Büro, deren Adresse auf der Website
angegeben ist.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Gleichzeitig
haben Sie unter allen Umständen das Recht, Beschwerden bei den
Aufsichtsbehörden einzureichen. Eine Person aus der Europäischen
Union kann eine Beschwerde bei der Behörde in dem EU-Mitgliedstaat,
in dem sie lebt, in dem EU-Mitgliedstaat, in dem sie arbeitet, und in
dem EU-Mitgliedstaat, in dem der Verstoß stattgefunden hat,
einreichen.</span></font></font></p>
<p lang="de-DE" align="justify" style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><b>11.
So können Sie uns kontaktieren und Ihre Rechte ausüben</b></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Um
alle Fragen im Zusammenhang mit Vertraulichkeit und Datenschutz zu
klären oder Rechte in Bezug auf Ihre Personendaten auszuüben,
können Sie den Support-Service unter den folgenden Links
kontaktieren: </span></font></font><font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>https://business.infocoin.online/support</u></span></font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">
oder </span></font></font><font><u><a href="https://view.infocoin.online/help"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">https://view.infocoin.online/help</font></font></a></u></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">.</span></font></font></p>
<p lang="de-DE" align="justify" style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><b>12.
Sonderbedingungen für Personen mit Wohnsitz in bestimmten Ländern</b></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Unabhängig
von Ihrem Wohnort garantieren wir den Schutz Ihrer Personendaten.
Diese Datenschutzrichtlinie enthält bestimmte Bedingungen, die sich
von anderen Bedingungen unterscheiden oder zusätzlich zu diesen
gelten und für Personen mit Wohnsitz in bestimmten Ländern und
Staaten gelten können, vorbehaltlich der Bedingungen dieser
Datenschutzrichtlinie.</span></font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><u>-
Für Personen mit Wohnsitz in Kalifornien</u></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Diese
besonderen Bestimmungen gelten für Sie, wenn Sie in Kalifornien
leben.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wir
halten uns an das Kalifornien-Konsumentenpersonendatenschutzgesetz
(California Consumer Privacy Act, im Folgenden CCPA), der die
höchsten Privatsphäre-Unantastbarkeit- und Datenschutzstandards in
den Vereinigten Staaten festlegt. Informationen zu unserer
Verarbeitung Ihrer Personendaten können Sie jederzeit in dieser
Datenschutzerklärung nachlesen.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Sie
haben das Recht, Auskunft darüber zu verlangen, wie wir Ihre
Personendaten verarbeiten, und eine Kopie davon zu erhalten. Während
eines Zeitraums von 12 Monaten stellen wir die ersten beiden Kopien
der Daten kostenlos zur Verfügung. Sie haben auch das Recht, die
Löschung der Personendaten zu verlangen. Weitere Informationen zu
Ihren Rechten und deren Ausübung finden Sie in dieser
Datenschutzrichtlinie (Abschnitte „Zugriff auf Ihre Personendaten
und deren Kontrolle erhalten“ und „So können Sie uns
kontaktieren und Ihre Rechte ausüben “).</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wenn
Sie sich entscheiden, eines Ihrer Rechte auszuüben, müssen Sie Ihre
Identität verifizieren, indem Sie beispielsweise bestätigen, dass
Sie der Inhaber Ihrer E-Mail-Adresse sind. Wenn Sie Ihre Rechte durch
einen bevollmächtigten Vertreter ausüben, benötigen wir Ihre
schriftliche Zustimmung und einen Nachweis Ihrer Identität und der
Identität des Vertreters. Unter keinen Umständen werden wir Sie
diskriminieren oder benachteiligen, weil Sie eines Ihrer Rechte
ausgeübt haben.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Als
Gegenleistung für das Abonnement von Marketingmitteilungen können
wir Ihnen möglicherweise finanzielle Belohnungen in Form von
Coupons, Kreditpunkten oder anderen Formen von Rabatten anbieten.
Einzelheiten zu den jeweiligen Anreizen werden Ihnen als Teil des
Abonnementangebots präsentiert. Wir betrachten den geschätzten Wert
Ihres Abonnements als gleich dem Wert des bereitgestellten Anreizes,
basierend auf unserer Berechnung der Zusatzkosten. Nachdem Sie unser
Angebot angenommen haben, können Sie es jederzeit ohne Einschränkung
Ihrer Rechte widerrufen. Weitere Informationen finden Sie in dieser
Datenschutzerklärung (im Abschnitt „Zwecke der Datenbenutzung“).</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Wir
verkaufen Ihre Personendaten nicht im Sinne des CCPA.</span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Darüber
hinaus können Sie sich bei allen Fragen im Zusammenhang mit der
Verarbeitung Ihrer Personendaten und der Ausübung Ihrer Rechte in
Bezug auf Personendaten an unseren Datenschutzbeauftragten wenden.</span></font></font></p>
<p lang="de-DE" align="justify" style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE"><b>13.
Änderungen in dieser Datenschutzrichtlinie</b></span></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><span lang="de-DE">Die
Methoden der Arbeit mit Personendaten können sich im Zuge der
Entwicklung unseres Geschäfts ändern. Wenn solche Änderungen
eintreten, werden wir diese Datenschutzrichtlinie aktualisieren,
damit sie den Grundsätzen der Transparenz entsprechen kann. Diese
Regeln können jederzeit ohne Vorankündigung geändert werden.</span></font></font></p>
<p lang="de-DE" align="justify" style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><a name="_GoBack"></a>
<font face="Times New Roman, serif"><font size="2" style="font-size: 10pt"><span lang="de-DE"><u>Diese
Ausgabe der Datenschutzrichtlinie gilt ab dem 09.12.2022.</u></span></font></font></p>
</body>
</html>
`;
