const prepareAxiosError = (res, defaultMessage = "Something went wrong") => {
    const {
        data: {
            error: message = defaultMessage
        } = {}
    } = res || {};

    return {
        status: "ERROR",
        message
    };
};


export {prepareAxiosError};