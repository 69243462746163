import React, { memo } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import classes from "./PieStatistic.module.scss";
import { useTranslation } from "react-i18next";

const COLORS = [
  "#541690",
  "#623EB7",
  "#3881FF",
  "#00A4C9",
  "#00c9a4",
  "#86c900",
  "#bcc900",
  "#fabe09",
];

const PieStatistic = ({ data, label = "" }) => {
  return (
    <div className={classes.pieContainer}>
      <div
        className={classes.percentContainer}
        style={{
          padding: data.length >= 5 ? "17px 15px" : undefined,
        }}
      >
        {data.map(({ label, value }, index) => (
          <div
            key={label + value}
            className={classes.percent}
            style={{ width: `calc(100% / ${data.length})` }}
          >
            <div className={classes.name}>{label}</div>
            <div
              className={classes.value}
              style={{
                fontSize: data.length >= 5 ? "12px" : "15px",
              }}
            >
              {value}%
            </div>
            <div
              className={classes.colorPoint}
              style={{ backgroundColor: COLORS[index % COLORS.length] }}
            />
          </div>
        ))}
      </div>
      <div style={{ width: "100%", height: "250px", position: "relative" }}>
        <div className={classes.label}>{label}</div>
        <ResponsiveContainer maxWidth={350} maxHeight={300}>
          <PieChart>
            <Pie
              data={data}
              innerRadius={50}
              outerRadius={85}
              fill="#8884d8"
              paddingAngle={0.1}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Cell />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
export default memo(PieStatistic);
