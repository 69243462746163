export const walletBalanceSelector = ({ wallet: { balance } }) => balance;

export const walletCommonBalanceSelector = ({ wallet: { commonBalance } }) =>
  commonBalance;

export const walletCompanyBalanceSelector = ({ wallet: { wallets } }) =>
  wallets;

export const walletHistorySelector = ({ wallet: { history } }) => history;
export const walletExchangeSelector = ({ wallet: { exchangeRate } }) =>
  exchangeRate;

export const walletLoadingSelector = ({ wallet: { loading } }) => loading;

export const walletTransactionsSelector = ({ wallet: { transactions } }) =>
  transactions;

export const walletPendingTransactionsSelector = ({
  wallet: { pendingTransactions },
}) => pendingTransactions;

export const invoicesSelector = ({ wallet: { invoices } }) => invoices;

export const amountInvoicesSelector = ({ wallet: { invoiceInfo } }) =>
  invoiceInfo;
