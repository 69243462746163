import React, { forwardRef, memo } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import "./index.scss";

const ScrollBar = forwardRef((props, ref) => {
  const { className = "", children } = props;

  return (
    <PerfectScrollbar
      ref={ref}
      {...props}
      className={`scroll-bar ${className}`}
    >
      {children}
    </PerfectScrollbar>
  );
});

export default memo(ScrollBar);
