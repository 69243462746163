const getVideoMetaData = (file) => {
    return new Promise((resolve) => {
        const video = document.createElement("video");

        video.preload = "metadata";

        video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);

            return resolve(video);
        };

        video.src = URL.createObjectURL(file);
    });
};


export {getVideoMetaData};