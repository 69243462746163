import React, { memo, useMemo, useState } from "react";
import dropdown from "./Dropdown.module.scss";

function Dropdown({ options, onSelect, value }) {
  const [isOpen, setIsOpen] = useState(false);
  const handleOptionClick = option => {
    setIsOpen(false);
    onSelect(option.value);
  };

  const handleLabelClick = () => {
    setIsOpen(!isOpen);
  };
  const label = useMemo(() => {
    return options.find(option => option.value === value)?.label;
  }, [options, value]);

  return (
    <div className={dropdown.dropdown}>
      <div className={dropdown["dropdown-label"]} onClick={handleLabelClick}>
        <span className={dropdown["dropdown-label-icon"]}>☰</span>
        <span className={dropdown["dropdown-label-text"]}>{label}</span>
      </div>
      <div
        className={`${dropdown["dropdown-options"]} ${
          isOpen ? dropdown["dropdown-options--open"] : ""
        }`}
      >
        {options &&
          options.map(option => (
            <div
              key={option.value}
              className={dropdown["dropdown-options-option"]}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
      </div>
    </div>
  );
}
export default memo(Dropdown);
