import React, { memo, useCallback } from "react";

import { Grid } from "@mui/material";
import { Typography } from "../../../../../views/blocks";
import { AuthButton } from "@components/Forms/components/ui";
import { useFormik } from "formik";
import { resetPasswordSchema } from "@helpers/Formik/validation";

import { useTranslation } from "react-i18next";

import CodeInput from "@components/Forms/components/CodeInput";

import { toast } from "react-toastify";

const ResetPasswordCodeForm = ({ defaultCode, onSubmit, sendingCode }) => {
  const { t } = useTranslation();

  const { values, setFieldValue, isValid, handleSubmit } = useFormik({
    initialValues: { code: defaultCode },
    validationSchema: resetPasswordSchema(1),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      onSubmit(values, 1);
    },
  });

  const handleResend = useCallback(async () => {
    try {
      await sendingCode(undefined);

      toast.success(t("Code resubmitted"));
    } catch (error) {
      toast.error(t(error?.response?.data?.error || error?.message || error));
    }
  }, [sendingCode, t, toast]);

  return (
    <Grid component="form">
      <Grid item mt={6}>
        <CodeInput
          onChange={code => setFieldValue("code", code)}
          value={values.code}
          label="Two-Factor Authentication"
        />
      </Grid>
      <Grid item>
        <Typography variant="caption2" color="gray">
          {t("A message with a verification code has been send to your email.")}
        </Typography>
        <Typography variant="caption2" color="gray">
          {t("Enter the code to continue.")}
        </Typography>
      </Grid>
      <Grid item mt={4}>
        <Typography
          variant="caption1"
          color="primary"
          className="pointer hover-opacity"
          weight="normal-bold"
          onClick={handleResend}
        >
          {t("Didn`t get a verification code")}
        </Typography>
      </Grid>

      <Grid item mt={15}>
        <AuthButton fullWidth="100%" onClick={handleSubmit} disabled={!isValid}>
          {t("Verify")}
        </AuthButton>
      </Grid>
    </Grid>
  );
};

export default memo(ResetPasswordCodeForm);
