import React, { useMemo, useRef, useCallback } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import "./index.scss";

const VideoInput = props => {
  const {
    fullWidth = false,
    error,
    helperText,
    warningLabel,
    label,
    name,
    touched,
    value,
    onBlur,
    onChange,
  } = props;

  const { t } = useTranslation();

  const src = useMemo(() => {
    if (value && value instanceof File) {
      return URL.createObjectURL(value);
    }

    return "";
  }, [value]);

  const videoRef = useRef(null);

  const modifications = clsx({
    "video-input--full-width": fullWidth,
    "video-input--error": error,
    "video-input--filled": !!value && value instanceof File,
  });

  const handleVideoMouseEnter = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  const handleVideoMouseLeave = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  }, [onBlur]);

  const handleFocus = useCallback(() => {}, []);

  const handleChange = useCallback(
    async e => {
      const {
        target: {
          files: [file],
        },
      } = e;
      if (onBlur) {
        onBlur();
      }

      if (onChange) {
        onChange(file);
      }

    },
    [onChange, onBlur],
  );

  return (
    <div className={`video-input ${modifications}`}>
      {label && (
        <div
          className={`video-input__label ${
            warningLabel ? "warning-label" : ""
          }`}
        >
          {t(label)}
        </div>
      )}

      <div className="video-input__wrapper">
        <input
          id="video-upload"
          className="video-input__input"
          type="file"
          placeholder={t("Choose video...")}
          accept="video/mp4, .mp4"
          name={name}
          onMouseEnter={handleVideoMouseEnter}
          onMouseLeave={handleVideoMouseLeave}
          onFocus={handleFocus}
          onChange={handleChange}
        />
        <label
          htmlFor="video-upload"
          style={{
            color: !src ? "#666" : "black",
            fontSize: !src ? "16px" : "14px",
          }}
        >
          {!src ? t("Choose video...") : value.name}
          <span
            onClick={e => {
              if (src) {
                onChange("");
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          >
            {!src ? t("Browse") : t("Delete")}
          </span>
        </label>
      </div>

      {touched && error && <div className="video-message-error">{t(error)}</div>}

      {helperText && (
        <div className="video-input__helper-text">{helperText}</div>
      )}
    </div>
  );
};

export { VideoInput };
