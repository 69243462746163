import {
  AUTH_LOGIN,
  AUTHORIZED,
  AUTH_SIGN_UP,
  AUTH_REFRESH,
  PENDING,
  NOT_AUTH,
  AUTH_LOGOUT,
  SAVE_USER,
  AUTH_STAGE,
  AUTH_TIMECODE,
  AUTH_REGISTR_INFO,
  AUTH_DISABLED_RESETCODE
} from "@redux/auth/constants";

import { STAGES } from "@containers/AuthContainer";

const initialState = {
  isAuthentificated: false,
  loaded: false,
  pending: true,
  sessionId: null,
  auth: {
    stage: STAGES.SIGN_IN,
    seconds: 600,
    registrationInfo: {},
    disabledButton: false
  }
};

export function authenticationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case AUTH_LOGIN:
      return {
        ...state,
        sessionId: action.data.session_id,
      };

    case AUTHORIZED:
      return {
        ...state,
        isAuthentificated: true,
        sessionId: action.payload,
        pending: false,
        loaded: true,
      };
    case AUTH_SIGN_UP:
      return {
        ...state,
        isAuthentificated: false,
        pending: false,
      };
    case AUTH_REFRESH:
      return {
        ...state,
        sessionId: action.data.session_id,
        token: action.data.token,
      };
    case PENDING:
      return {
        ...state,
        pending: true,
        loaded: false,
      };
    case NOT_AUTH:
      return {
        ...state,
        pending: false,
        loaded: false,
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        pending: false,
        loaded: false,
        isAuthentificated: false,
      };
    case SAVE_USER:
      return {
        ...state,
        user: action.data,
      };
    case AUTH_STAGE:
      return {
        ...state,
        auth: {
          ...state.auth,
          stage: action.data
        }
      };
    case AUTH_TIMECODE:
      return {
        ...state,
        auth: {
          ...state.auth,
          seconds: action.data
        }
      };
    case AUTH_REGISTR_INFO:
      return {
        ...state,
        auth: {
          ...state.auth,
          registrationInfo: action.data
        }
      };
    case AUTH_DISABLED_RESETCODE:
      return {
        ...state,
        auth: {
          ...state.auth,
          disabledButton: action.data
        }
      };
    default:
      return state;
  }
}
