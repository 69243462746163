import {useCallback} from "react"
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";


const useShowMessage = () => {
    const {t} = useTranslation();

    return useCallback((res) => {
        const {
            status,
            message
        } = res || {};

        if(message) {
            // eslint-disable-next-line default-case
            switch(status) {
                case "OK":
                    toast.success(t(message));
                    break;

                case "ERROR":
                    toast.error(t(message));
                    break;
            }
        }
    }, [t]);
};


export {useShowMessage};