import shortid from 'shortid';

export const reasons = [
  { id: shortid.generate(), reason: 'Причина 1' },
  { id: shortid.generate(), reason: 'Причина 2' },
  { id: shortid.generate(), reason: 'Причина 3' },
  { id: shortid.generate(), reason: 'Причина 4' },
  { id: shortid.generate(), reason: 'Причина 5' },
  { id: shortid.generate(), reason: 'Причина 6' },
  { id: shortid.generate(), reason: 'Причина 7' },
];
