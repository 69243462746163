import React from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import { Typography } from "../../../../views/blocks";

import DoneIcon from "@mui/icons-material/Done";

const SuccessVerify = () => {
  const { t } = useTranslation();

  return (
    <Grid display="flex" flexDirection="column" alignItems="center" py={4}>
      <Grid item>
        <Typography variant="h2" bold align="center">
          {t("Welcome to INFOCOIN!")}
        </Typography>
      </Grid>
      <Grid item mt={6}>
        <DoneIcon htmlColor="green" sx={{ fontSize: "7rem" }} />
      </Grid>
      <Grid item mt={5}>
        <Typography color="gray" variant="h5" align="center">
          {t("You will now be redirected to the auction page")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SuccessVerify;
