import { useCallback, useMemo } from "react";
import Modal from "@components/Modal";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Typography } from "../../../../../../blocks";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { fileToBase64 } from "@utils/fileToBase64";
import { toast } from "react-toastify";

export const ModalUpload = ({
  name = "header",
  show,
  onHide,
  onBlur = () => {},
  onChange,
  settings,
}) => {
  const { t } = useTranslation();

  const { FILE_SIZE, SUPPORTED_FORMATS, RECOMMENDED_SIZE } = settings;

  const imageDimensions = file =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = img;
        resolve({ width, height });
      };
      if (file) {
        img.src = URL.createObjectURL(file);
      }
    });

  const handleChange = useCallback(
    async e => {
      const {
        target: {
          files: [file],
        },
      } = e;

      const { width, height } = await imageDimensions(file);

      if (SUPPORTED_FORMATS) {
        const typeFile = file.name.split(".")[file.name.split(".").length - 1];

        if (!SUPPORTED_FORMATS.includes(typeFile.toUpperCase())) {
          return toast.error(t("Incorrect file type"));
        }
      }

      if (FILE_SIZE) {
        if (width > FILE_SIZE.w || height > FILE_SIZE.h) {
          return toast.error(
            t("Max image size is") +
              ` ${FILE_SIZE.w}x${FILE_SIZE.h}px, ` +
              t("your uploaded image is") +
              ` ${width}x${height}px`,
          );
        }
      }

      if (RECOMMENDED_SIZE) {
        if (width !== RECOMMENDED_SIZE.w || height !== RECOMMENDED_SIZE.h) {
          toast.warning(
            t("Recommended image size") +
              ` - ${RECOMMENDED_SIZE.w}x${RECOMMENDED_SIZE.h}px`,
          );
        }
      }

      if (onChange) {
        onChange(file);
      }
      onHide();
    },
    [
      onChange,
      fileToBase64,
      onHide,
      FILE_SIZE,
      RECOMMENDED_SIZE,
      SUPPORTED_FORMATS,
    ],
  );

  const accept = useMemo(
    () =>
      SUPPORTED_FORMATS.map(format => {
        return `.${format.toLowerCase()}`;
      }).join(", "),
    [SUPPORTED_FORMATS],
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="default"
      dialogClassName="dialog-upload-header"
    >
      <Grid className="content-upload-header">
        <label className="header-label" htmlFor={`${name}-id`}>
          <CloudUploadOutlinedIcon fontSize="large" />
          <Typography variant="h5" weight="normal-bold">
            {t("Click to upload")}
          </Typography>
          {SUPPORTED_FORMATS && (
            <Typography variant="body1" color="gray">
              {SUPPORTED_FORMATS.join(", ")}
            </Typography>
          )}
          {RECOMMENDED_SIZE && (
            <Typography variant="body1" color="gray" align="center">
              {t("Recommended size")} - ({RECOMMENDED_SIZE.w}x
              {RECOMMENDED_SIZE.h}px)
            </Typography>
          )}
          {FILE_SIZE && (
            <Typography variant="body1" color="gray">
              ({t("max")}. {FILE_SIZE.w}x{FILE_SIZE.h}px)
            </Typography>
          )}
        </label>
        <input
          type="file"
          className="header-input"
          id={`${name}-id`}
          name={name}
          onChange={handleChange}
          onBlur={onBlur}
          accept={accept}
        />
      </Grid>
    </Modal>
  );
};
