import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { ModalSettingsCookie } from "./Modal";
const Cookie = () => {
  const [isShowModal, setShow] = useState(false);

  const onToggleModal = () => setShow(!isShowModal);

  const { t } = useTranslation();
  const conteinerButton = document.querySelector(".cookie-container-buttons");
  useEffect(() => {
    if (conteinerButton) {
      if (document.querySelector(".cookie-settings-button") === null) {
        const confirmButton = document.getElementById("rcc-confirm-button");
        const settingButton = document.createElement("button");
        settingButton.innerHTML = t("Cookie Settings");
        settingButton.classList.add("cookie-button");
        settingButton.classList.add("cookie-settings-button");
        conteinerButton.insertBefore(settingButton, confirmButton);
        settingButton.onclick = function () {
          onToggleModal();
        };
      }
    }
    // eslint-disable-next-line
  }, [conteinerButton]);

  const handleSubmit = () => {
    document.getElementById("rcc-confirm-button").click();
  };
  return (
    <CookieConsent
      enableDeclineButton
      style={{
        display: !isShowModal ? "flex" : "none",
      }}
      disableStyles
      contentClasses="cookie-content"
      buttonClasses="cookie-accept-button cookie-button"
      buttonWrapperClasses="cookie-container-buttons"
      containerClasses="cookie-container"
      declineButtonClasses="cookie-decline-button cookie-button"
      buttonText={t("Accept All")}
      declineButtonText={t("Reject All")}
    >
      <div>
        {t(
          'We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept All”, you consent to the use of ALL the cookies. However, you may visit "Cookie Settings" to provide a controlled consent.',
        )}
      </div>
      <ModalSettingsCookie
        show={isShowModal}
        handleSubmit={handleSubmit}
        setShow={onToggleModal}
        t={t}
      />
    </CookieConsent>
  );
};

export default Cookie;
