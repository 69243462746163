const colorByNumber = (number, startColor, endColor) => {
  let P = 1 - number,
    [a, b, c, d] = startColor.split(","),
    [e, f, g, h] = endColor.split(","),
    x = d || h,
    j = x
      ? "," +
        (!d
          ? h
          : !h
          ? d
          : Math.round((parseFloat(d) * P + parseFloat(h) * number) * 1000) / 1000 + ")")
      : ")";
  return (
    "rgb" +
    (x ? "a(" : "(") +
    Math.round(
      parseInt(a[3] == "a" ? a.slice(5) : a.slice(4)) * P +
        parseInt(e[3] == "a" ? e.slice(5) : e.slice(4)) * number,
    ) +
    "," +
    Math.round(parseInt(b) * P + parseInt(f) * number) +
    "," +
    Math.round(parseInt(c) * P + parseInt(g) * number) +
    j
  );
};

export default colorByNumber;
