import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./styled.scss";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Box, Grid, Tooltip } from "@mui/material";
import { OverlayTrigger, Tooltip as BTooltip } from "react-bootstrap";
import moment from "moment";

import { getCompanies, getDemoCompanies } from "src/services/api/compnaies";
import { toast } from "react-toastify";
import {
  getDemoMyVideosAction,
  getReadyVideosAction,
  setIsMyVideoChosenAction,
} from "src/redux/emergency/actions";
import {
  mapStatuses,
  VIDEO_STATUS_NOT_VERIFIED,
  VIDEO_STATUS_REJECTED,
  VIDEO_STATUS_SENT_TO_VERIFICATION,
  VIDEO_STATUS_VERIFIED,
  VIDEO_STATUS_VERIFYING_POSTPONED,
} from "src/redux/video";

import Loader from "src/components/Loader";
import { Autocomplete, TextInput } from "src/views/inputs";
import "../Content.css";
import DeleteModal from "@components/DeleteModal";
import VideosTable from "@components/VideosTable";
import Checkbox from "@components/Checkbox";
import {
  delDeleteVideo,
  DemoDeleteVideo,
  putDemoSentVerifedVideo,
  putSentVerifedVideo,
} from "@services/api/content";
import VideoCell from "@components/VideosTable/components/VideoCell";
import NearMeIcon from "@mui/icons-material/NearMe";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { videosSelector } from "@redux/content/selectors";
import {
  getDemoVideosAction,
  getVideosAction,
} from "@redux/content/contentActions";
import MobileVideosView from "@components/VideosTable/components/MobileVideosView";
import ScrollBar from "../../../views/blocks/ScrollBar";

const Container = memo(({ isModal, children }) =>
  isModal ? (
    <ScrollBar
      style={{ height: "60vh" }}
      options={{
        suppressScrollX: true,
        suppressScrollY: window.innerWidth > 768,
      }}
    >
      {children}
    </ScrollBar>
  ) : (
    <Box sx={{ width: "90%", margin: "auto" }}>{children}</Box>
  ),
);

const ContentTable = ({ isModal, onChangeSelectedVideos, auctionCountry }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const readyContent = useSelector(
    state => state.emergency.readyContent,
    shallowEqual,
  );
  const lotToEdit = useSelector(state => state.auction.lotToEdit, shallowEqual);
  const userCountry = useSelector(
    state => state.user.user.country,
    shallowEqual,
  );
  const role = useSelector(state => state.user.user.role, shallowEqual);
  const { isMyVideoChosen: isMyContentChosen } = useSelector(
    state => state.emergency,
    shallowEqual,
  );
  const { videos, loading } = useSelector(videosSelector, shallowEqual);

  const isEmergency = useSelector(
    state => state.emergency.isEmergency,
    shallowEqual,
  );

  const [selected, setSelected] = useState(lotToEdit?.video?.id);

  const [companies, setCompanies] = useState([]);
  const [activeCompany, setActiveCompany] = useState("");
  const [search, setSearch] = useState("");

  const renderPurpose = useCallback(record => {
    if (record.email) {
      return record.email;
    }
    if (record.url) {
      return record.url;
    }
    return "";
  }, []);

  const sendVerificationVideo = useCallback(
    data => {
      const requestData = {
        title: data.title,
        description: data.description,
        categories: data.categories,
        company_id: data.company.id,
        gender: data.gender,
        age_min: data.age_min,
        age_max: data.age_max,
        email: data.email,
        is_all_country: data.is_all_country,
        url: data.url,
        status: "sent_to_verification",
      };

      if (role === "demo_user") {
        putDemoSentVerifedVideo(data.id, requestData)
          .then(() => {
            toast.success(t("Video sent for review!"));
            dispatch(getDemoMyVideosAction());
          })
          .catch(error => {
            toast.error(t(error.message));
          });
      } else {
        putSentVerifedVideo(data.id, requestData)
          .then(() => {
            toast.success(t("Video sent for review!"));
            dispatch(getVideosAction());
          })
          .catch(error => {
            toast.error(t(error.message));
          });
      }
    },
    [dispatch, t, role],
  );

  const deleteVideo = useCallback(
    data => {
      if (role === "demo_user") {
        DemoDeleteVideo(data.id)
          .then(() => {
            toast.success(t("Video deleted successfully!"));
            dispatch(getDemoMyVideosAction());
          })
          .catch(error => {
            toast.error(t(error.message));
          });
      } else {
        delDeleteVideo(data.id)
          .then(() => {
            toast.success(t("Video deleted successfully!"));
            dispatch(getVideosAction());
          })
          .catch(error => {
            toast.error(t(error.message));
          });
      }
    },
    [dispatch, role, t],
  );

  const rows = useMemo(() => {
    const arrayToShow = isMyContentChosen ? videos : readyContent;
    return arrayToShow
      .map(item => {
        return {
          ...item,
          purpose: `${item.url} / ${item.email}`,
          date: `${moment(item.created_at).format("D MMM Y")}`,
          audience: {
            age_max: item.age_max,
            age_min: item.age_min,
            country: item.country,
            region: item.region,
            city: item.city,
            gender: item.gender,
          },
        };
      })
      .filter(item => {
        const { company } = item;

        if (activeCompany) {
          return company.id === activeCompany;
        }

        return true;
      })
      .filter(item => {
        const { title, description, company } = item;

        if (search) {
          const rr =
            `${title} ${description} ${company.title} ${company.description}`
              .toLowerCase()
              .trim();

          return search
            .toLowerCase()
            .trim()
            .split(/\s/)
            .reduce((exist, part) => {
              return exist || rr.indexOf(part) >= 0;
            }, false);
        }

        return true;
      })
      .filter(item =>
        isModal
          ? item.status === VIDEO_STATUS_VERIFIED &&
            item.country === auctionCountry
          : true,
      );
  }, [
    isMyContentChosen,
    videos,
    readyContent,
    activeCompany,
    search,
    isModal,
    auctionCountry,
  ]);

  const handleSelectVideo = useCallback(
    video => {
      if (video.id) {
        setSelected(video.id);

        if (isModal && onChangeSelectedVideos) {
          onChangeSelectedVideos(video);
        }
      }
    },
    [isModal, onChangeSelectedVideos],
  );

  useEffect(() => {
    (async () => {
      try {
        if (role === "demo_user") {
          const res = await getDemoCompanies();

          if (res.status === 200) {
            const { data: companies } = res;

            setCompanies(companies);
          }
        } else {
          const res = await getCompanies();

          if (res.status === 200) {
            const { data: companies } = res;

            setCompanies(companies);
          }
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (role === "demo_user") {
      dispatch(getDemoVideosAction());
      if (isEmergency && isModal) dispatch(getReadyVideosAction(userCountry));

      if (history.location.pathname.includes("content")) {
        dispatch(setIsMyVideoChosenAction(true));
      }
    } else {
      dispatch(getVideosAction());
      if (isEmergency && isModal) dispatch(getReadyVideosAction(userCountry));

      if (history.location.pathname.includes("content")) {
        dispatch(setIsMyVideoChosenAction(true));
      }
    } //eslint-disable-next-line
  }, []);

  const columns = useMemo(() => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
        maxWidth: 50,
        flexWidth: "50px",
        isSmall: true,
        Cell: cell => {
          return (
            <div
              style={{
                fontWeight: "bold",
              }}
            >
              {cell.value}
            </div>
          );
        },
      },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: cell => moment(cell.value).format("DD.MM.YYYY"),
      },
      {
        Header: "Preview",
        isFullColumn: true,
        flexWidth: "120px",
        accessor: "filepath_480_p",
        disableSortBy: true,
        Cell: cell => (
          <VideoCell value={cell.value} row={cell.row} titleName={"Video"} />
        ),
      },
      {
        Header: "Title",
        accessor: "title",
        isFullColumn: true,
        flexWidth: "120px",
        sortType: "basic",
        Cell: ({ value: title }) => {
          return title.length > 20 ? (
            <Tooltip title={title}>
              <span style={{ width: "100%", overflow: "hidden" }}>
                {title.substring(0, 20)}...
              </span>
            </Tooltip>
          ) : (
            <span style={{ width: "100%", overflow: "hidden" }}>{title}</span>
          );
        },
      },
      {
        Header: "Description",
        accessor: "description",
        sortType: "basic",
        Cell: ({ value: description }) => {
          return (
            <React.Fragment>
              {description.length > 20 && (
                <OverlayTrigger
                  overlay={
                    <BTooltip id="button-tooltip">{description}</BTooltip>
                  }
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                >
                  <button style={{ width: "100%", overflow: "hidden" }}>
                    {description.substring(0, 20)}...
                  </button>
                </OverlayTrigger>
              )}
              {description.length <= 20 && (
                <span style={{ width: "100%", overflow: "hidden" }}>
                  {description}
                </span>
              )}
            </React.Fragment>
          );
        },
      },
      {
        Header: "Gender",
        accessor: "genders",
        Cell: ({ value }) => {
          if (isModal) {
            return value.map(gender => t(gender.title)[0]).join(", ");
          } else
            return value?.length
              ? value.map(gender => t(gender.title)).join(", ")
              : "";
        },
      },
      {
        Header: "Country",
        accessor: role === "demo_user" ? "country.name" : "country",
        sortType: "basic",
      },
      {
        Header: "Company",
        accessor: "company.title",
        sortType: "basic",
      },
      {
        Header: "Categories",
        accessor: "categories",
        Cell: ({ value }) => {
          const renderCategories =
            value && value?.length
              ? value.map(category => t(category.title)).join(", ")
              : "";
          return (
            <div>
              {value && value?.length < 4 ? (
                renderCategories
              ) : (
                <OverlayTrigger
                  overlay={
                    <BTooltip id="button-tooltip">{renderCategories}</BTooltip>
                  }
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                >
                  <button>{renderCategories.substring(0, 35)}...</button>
                </OverlayTrigger>
              )}
            </div>
          );
        },
      },
      {
        Header: "Interactions",
        accessor: "purpose",
        Cell: ({ row: { original } }) => {
          return (
            <Tooltip
              title={
                original.url === undefined && original.email === undefined
                  ? t("Not chosen")
                  : renderPurpose(original)
              }
            >
              <div style={{ cursor: "pointer" }}>
                {original.url && t("Url")}
                {original.email && t("Email")}
                {original.url === undefined &&
                  original.email === undefined &&
                  t("Not chosen")}
              </div>
            </Tooltip>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        isSmall: true,
        flexWidth: "90px",
        sortType: "basic",
        Cell: ({ value: status, row: { original } }) => {
          const title = t(mapStatuses[status]);

          return (
            <>
              <Tooltip title={title}>
                <span
                  className={clsx("auction__status-cell", {
                    "auction__status-cell-ver":
                      status === VIDEO_STATUS_VERIFIED,
                    "auction__status-cell-not-verify":
                      status === VIDEO_STATUS_NOT_VERIFIED ||
                      status === VIDEO_STATUS_REJECTED,
                    "auction__status-cell-postponed":
                      status === VIDEO_STATUS_VERIFYING_POSTPONED ||
                      status === VIDEO_STATUS_SENT_TO_VERIFICATION,
                  })}
                ></span>
              </Tooltip>
            </>
          );
        },
      },
    ];
    if (isModal) {
      columns.unshift({
        Header: "",
        id: "select",
        isSmall: true,
        flexWidth: 50,
        Cell: ({ row }) => {
          return (
            <Checkbox
              className={"auction-select-checkbox"}
              role="checkbox"
              value={selected === row.original.id}
              onChange={() => {
                handleSelectVideo(row.original);
              }}
            />
          );
        },
      });
    } else {
      columns.splice(1, 0, {
        Header: "Actions",
        accessor: "status",
        id: "action",
        Cell: ({ value, row: { original } }) => (
          <div className={"auctions__actions-container content-table-actions"}>
            {!isModal && (
              <DeleteModal
                onSuccessDelete={() => deleteVideo(original)}
                trigger={
                  <Tooltip title={t("Delete", { context: "action" })}>
                    <DeleteForeverIcon
                      className={"auction__action auction__action-del"}
                    />
                  </Tooltip>
                }
                question={t("Are you sure you want to delete the video?")}
              />
            )}
            <Tooltip title={t("Edit", { context: "action" })}>
              <EditIcon
                onClick={() => history.push(`/content/${original.id}`)}
                className={"auction__action"}
              />
            </Tooltip>
            <Tooltip title={t("Send to verification", { context: "action" })}>
              {(value === VIDEO_STATUS_NOT_VERIFIED ||
                value === VIDEO_STATUS_REJECTED) &&
              original.filepath_480_p ? (
                <NearMeIcon
                  className={`auction__action`}
                  onClick={() => sendVerificationVideo(original)}
                />
              ) : (
                <NearMeIcon className={`auction__action-disabled`} />
              )}
            </Tooltip>
          </div>
        ),
      });
    }
    return columns; //eslint-disable-next-line
  }, [isModal, selected]);

  if (loading) {
    return <Loader isPageLoader width={150} height={150} />;
  }

  // if (!rows.length) {
  //   return (
  //     <h3 className="text-center py-4 auction__no_add_video">
  //       {isMyContentChosen ? (
  //         <>
  //           {t("You don't have any videos uploaded yet.")}
  //           <Button className="auction__add_video" onClick={onAddVideo}>
  //             {t("Add Video")}
  //           </Button>
  //         </>
  //       ) : (
  //         <>{t("The moderator has not added a video yet.")}</>
  //       )}
  //     </h3>
  //   );
  // }

  return (
    <Container isModal={isModal}>
      {isModal && (
        <>
          <h5>
            {t("First step - Select verified video by country")}:{" "}
            {auctionCountry}
          </h5>
        </>
      )}

      <Grid
        container
        sx={{ marginTop: 0, marginBottom: isModal && "10px" }}
        spacing={3}
        justifyContent="flex-end"
      >
        <Grid className="grid-item-nav" item md={4} xl={3}>
          <Autocomplete
            fullWidth
            options={companies.map(company => {
              const { description = "" } = company;

              return {
                label: company.title,
                secondary:
                  description.length > 27
                    ? description.substring(0, 27) + "..."
                    : description,
                value: company.id,
              };
            })}
            label="Company"
            placeholder={t("Company name")}
            value={activeCompany}
            onChange={(e, categoryId) => {
              setActiveCompany(categoryId);
            }}
          />
        </Grid>

        <Grid className="grid-item-nav" item md={4} xl={3}>
          <TextInput
            fullWidth
            label="Search"
            placeholder={t("Name, description, company name")}
            value={search}
            onChange={e => {
              const {
                target: { value },
              } = e;

              setSearch(value);
            }}
          />
        </Grid>
      </Grid>
      <div className="content-table">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="desktop-table-container">
              <VideosTable
                height={isModal ? "250px" : undefined}
                videos={rows}
                columns={columns}
              />
            </div>
            <div className={"mobile-table-container"}>
              <MobileVideosView
                selected={selected}
                onSelect={handleSelectVideo}
                videos={rows}
                isModal={isModal}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default memo(ContentTable);
