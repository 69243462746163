import { axiosInstance } from "@helpers/http/httpInstance";
import { toast } from "react-toastify";
import moment from "moment";
import { t } from "i18next";

export const getWallet = async () => {
  try {
    const { data } = await axiosInstance.get("api/wallet");
    return data;
  } catch (e) {
    toast.error("Error of get wallet");
  }
};

export const getExchangeRate = async (country = "Ukraine") => {
  try {
    const { data } = await axiosInstance.get(`api/ic-rates/${country}`);
    return data;
  } catch (e) {
    toast.error("Error of get exchange rate");
  }
};

export const getLastYearTransaction = async () => {
  const today = moment().utc(true).toISOString();

  const yearAgo = moment()
    .utc(true)
    .subtract(1, "year")
    .set({ hour: 0, seconds: 3, minutes: 0 })
    .toISOString();

  try {
    const { data } = await axiosInstance.get(
      `/api/transactions/history?type=income&start_time=${yearAgo}&end_time=${today}&trxs=${true}`,
    );
    return data;
  } catch (e) {
    toast.error("Error of get history");
  }
};
export const getTransactionsHistory = async () => {
  const oldDate = new Date(null).toISOString();
  const today = new Date().toISOString();
  return await axiosInstance.get(`/api/transactions/history?type=income&start_time=${oldDate}&end_time=${today}&trxs=${true}`).then(res => {
    if (res.data.transactions === undefined) {
      return [];
    } else return res
  })
}

export const getTransactionsHistoryByTypeByPeriod = async (type, startDate, endDate, trxs) => {
  return await axiosInstance.get(`/api/transactions/history?type=${type}&start_time=${startDate}&end_time=${endDate}&trxs=${trxs}`).then((responce) => {
    // console.log('History ', type, ' ==>', responce)
    return responce
  })
}

export const getTransactionsHistoryEveryDayByPeriod = async (type, startDate, endDate) => {
  return await axiosInstance.get(`/api/transactions/history/date?type=${type}&start_time=${startDate}&end_time=${endDate}`).then((responce) => {
    return responce
  })
}

export const getPaymentSystemTransaction = async () => {

  const startDate = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString()
  const endDate = new Date().toISOString()


  return await axiosInstance.get(`/api/transactions/user?start_time=${startDate}&end_time=${endDate}`).then((responce) => {
    return responce
  })
}

export const getTransactions = async () => {

  const oldDate = new Date(null).toISOString()

  let endPrevisionPeriod = new Date()
  endPrevisionPeriod.setUTCDate(1)
  endPrevisionPeriod.setUTCHours(0, 0, 0, 0)
  endPrevisionPeriod = endPrevisionPeriod.toISOString()

  let startCurrentPeriod = new Date()
  startCurrentPeriod.setUTCDate(1)
  startCurrentPeriod.setUTCHours(0, 0, 0, 1)
  startCurrentPeriod = startCurrentPeriod.toISOString()

  const today = new Date().toISOString()

  return Promise.all([
    getTransactionsHistoryByTypeByPeriod('income', startCurrentPeriod, today, true),
    getTransactionsHistoryByTypeByPeriod('recipient', startCurrentPeriod, today, true),
    getTransactionsHistoryEveryDayByPeriod('sender', startCurrentPeriod, today),
    getTransactionsHistoryByTypeByPeriod('income', oldDate, endPrevisionPeriod, false),
    getTransactionsHistoryByTypeByPeriod('recipient', oldDate, endPrevisionPeriod, false),
    getTransactionsHistoryByTypeByPeriod('sender', oldDate, endPrevisionPeriod, false),
    getPaymentSystemTransaction(),
  ]).then((res) => {
    return ({
      dateTransactions: {
        startPrevPeriod: oldDate,
        endPrevPeriod: endPrevisionPeriod,
        startCurrPeriod: startCurrentPeriod,
        endCurrPeriod: today,
      },
      walletTransactions: {
        incomeCurrent: res[0].data,
        recipientCurrent: res[1].data,
        senderCurrent: res[2].data,
        incomePrevision: res[3].data,
        recipientPrevision: res[4].data,
        senderPrevision: res[5].data
      },
      paymentSystemTransactions: {
        income: res[6].data
      },
    })
  }).catch(e => {
    toast.error("Error of get history");
  })
};

export const getTransactionsIdCompany = async id => {
  try {
    const { data } = await axiosInstance.get(`/api/invoices/${id}`);
    return data;
  } catch (e) {
    toast.error("Error of get history");
  }
};

export const getTransactionsByDate = async ({ start, end }) => {
  try {
    const { data } = await axiosInstance.get(`/api/transactions/history?type=income&start_time=${start}&end_time=${end}&trxs=true`);
    return data;
  } catch (e) {
    toast.error("Error of get history");
  }
};

export const getInvoices = async () => {
  try {
    const { data } = await axiosInstance.get(`/api/invoices`);
    return data;
  } catch (e) {
    toast.error("Error of get invoices");
  }
};
export const createInvoices = async invoice => {
  try {
    const { data } = await axiosInstance.post(`/api/invoices`, invoice);
    return data;
  } catch (e) {
    toast.error("Error of get invoices");
  }
};

export const getNextInvoiceId = async () => {
  try {
    const { data } = await axiosInstance.get(`/api/invoices/next-id`);
    return data;
  } catch (e) {
    toast.error("Error of get invoices");
  }
};

export const getPaymentSystems = async () => {
  try {
    return await axiosInstance.get(`/api/payment-systems`);
  } catch (e) {
    toast.error("Error of get invoices");
  }
};

export const createPaypalOrder = async data => {
  try {
    return await axiosInstance.post(`/api/pay-pal/order`, data);
  } catch (e) {
    toast.error(t("Error of create paypal order"));
  }
};

export const getUserInfo = async () => {
  return await axiosInstance.get(`/api/user`).then((responce) => {
    return responce
  })
}

export const getBankInfo = (country) => {
  return axiosInstance.get(`/api/bank-details/${country}`)
}

export const getLotInPlaylist = () => {
  return axiosInstance.get(`/api/lots-in-playlist/statistic?limit=10000&offset=0`)
}

export const getDeletedLot = (startDate, endDate) => {
  return axiosInstance.get(`/api/lots-in-closed-auctions/statistic?limit=10000&offset=0&start_time=${startDate}&end_time=${endDate}`)
}

export const getCCWTransactionByDate = (startDate, endDate) => {
  return axiosInstance.get(`/api/ccw-transactions/history/date?start_time=${startDate}&end_time=${endDate}`)
}

export const getCCWTransactions = () => {
  return axiosInstance.get(`/api/ccw-transactions`)
}