import {
  AUTHORIZED,
  AUTH_SIGN_UP,
  AUTH_REFRESH,
  PENDING,
  NOT_AUTH,
  AUTH_LOGOUT,
  AUTH_STAGE,
  AUTH_TIMECODE,
  AUTH_REGISTR_INFO,
  AUTH_DISABLED_RESETCODE,
} from "@redux/auth/constants";
import { loginAPI } from "@services/api/auth";
import { setTokenData } from "@utils/tokens";
import {
  getUserAction,
  getDemoUserAction,
} from "@redux/user/actions/userActions";
import {
  createFirstCompanyAction,
  createDemoFirstCompanyAction,
} from "@redux/companies/actions";
import { clearTokenData } from "@utils/tokens";
import { toast } from "react-toastify";
import { t } from "i18next";
import { getEmergencyAction } from "@redux/emergency/actions";

export const authorizedAction = sessionId => {
  return {
    type: AUTHORIZED,
    payload: sessionId,
  };
};

export const authLogout = (data = {}) => {
  return {
    type: AUTH_LOGOUT,
    data,
  };
};

export const authSignUp = (data = {}) => {
  return {
    type: AUTH_SIGN_UP,
    data,
  };
};

export const authRefresh = (data = {}) => {
  return {
    type: AUTH_REFRESH,
    data,
  };
};

export const authPending = (data = {}) => {
  return {
    type: PENDING,
    data,
  };
};

export const notAuth = (data = {}) => {
  return {
    type: NOT_AUTH,
    data,
  };
};

export const authStages = data => {
  return {
    type: AUTH_STAGE,
    data,
  };
};

export const loginDemoUserAction = data => async dispatch => {
  try {
    const response = await loginAPI(data);
    if (response.data?.token) {
      setTokenData(
        response.data,
        typeof data.remember === "boolean" ? data.remember : true,
      );

      await dispatch(authPending());

      await dispatch(getDemoUserAction());
      await dispatch(createDemoFirstCompanyAction());
      await dispatch(authorizedAction(response.data.session_id));
      toast.success(t("You succeed login"));
      localStorage.setItem("demo-user", "true");
    }
  } catch (error) {
    toast.error(error?.response?.data?.error || error?.message || error);
    return error.response.data;
  }
};

export const registerUserAction = data => async dispatch => {
  try {
    const response = await loginAPI(data);

    if (response.data?.token) {
      setTokenData(
        response.data,
        typeof data.remember === "boolean" ? data.remember : true,
      );
    }
    await dispatch(authPending());

    await dispatch(getUserAction());

    await dispatch(createFirstCompanyAction());

    const isEmergency = await dispatch(getEmergencyAction());

    await dispatch(authorizedAction(response.data.session_id));

    toast.success(t("You succeed login"));

    return isEmergency;
  } catch (error) {
    if (error?.response?.status !== 403) {
      toast.error(t(error?.response?.data?.error || error?.message || error));
    }
  }
};

export const loginUserAction = data => async dispatch => {
  try {
    const response = await loginAPI(data);

    if (response.data?.token) {
      setTokenData(
        response.data,
        typeof data.remember === "boolean" ? data.remember : true,
      );

      await dispatch(authPending());

      const user = await dispatch(getUserAction());

      if (user.role === "user") {
        toast.error(t("You are already a consumer of information"));

        return clearTokenData();
      }

      toast.success(t("You succeed login"));

      const isEmergency = await dispatch(getEmergencyAction());

      await dispatch(authorizedAction(response.data.session_id));

      return isEmergency;
    }
  } catch (error) {
    if (error?.response?.status !== 403) {
      toast.error(t(error?.response?.data?.error || error?.message || error));
      return error?.response?.data;
    }

    if (error?.response?.status === 403) {
      if (error?.request?.responseURL?.includes("api/user")) {
        return dispatch(loginDemoUserAction(data));
      }
    }

    return error?.response?.data;
  }
};
