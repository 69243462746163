import React, { useCallback } from "react";
import { ToastContainer } from "react-toastify";

import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";

import "react-toastify/dist/ReactToastify.css";
import "./Toast.scss";

const Toast = () => {
  const icon = useCallback(({ type }) => {
    switch (type) {
      case "success":
        return <CheckCircleRoundedIcon className="toastIcon" />;
      case "error":
        return <CancelRoundedIcon className="toastIcon" />;
      case "warning":
        return <CircleNotificationsRoundedIcon className="toastIcon" />;
      default:
        return false;
    }
  }, []);

  return (
    <ToastContainer
      position="top-right"
      theme="colored"
      closeButton={false}
      autoClose={4000}
      hideProgressBar
      icon={icon}
    />
  );
};

export default Toast;
