import "./index.scss";
import { useTranslation } from "react-i18next";

const TooltipComponent = ({ content, children, className, bottom }) => {
  const { t } = useTranslation();

  return (
    <div className={`tooltip-container ${className}`}>
      {children}
      <div className={`tooltip-content ${bottom && "bottom-content"}`}>
        {content()}
      </div>
    </div>
  );
};

export { TooltipComponent };
