import {
  SET_IS_COUNTRY_EMERGENCY,
  SET_IS_MY_VIDEO_CHOSEN,
  SET_LOADING,
  SET_MY_CONTENT,
  SET_READY_CONTENT,
} from "@redux/emergency/constants";
import {
  getRecommendedVideo,
  getVideoWithStatus,
  getDemoVideoWithStatus
} from "@services/api/video/video-api";
import { toast } from "react-toastify";
import { getCountryStatus } from "@services/api/language";

export const setIsEmergencyAction = bool => ({
  type: SET_IS_COUNTRY_EMERGENCY,
  payload: bool,
});

export const setIsMyVideoChosenAction = bool => ({
  type: SET_IS_MY_VIDEO_CHOSEN,
  payload: bool,
});

export const getDemoMyVideosAction = () => async dispatch => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    const response = await getDemoVideoWithStatus();
    const data = response.data.sort((a, b) => b.id - a.id)
    dispatch({
      type: SET_MY_CONTENT,
      payload: data,
    });
  } catch (e) {
    toast.error(e?.response?.data?.error || e.message);
  } finally {
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};

export const getMyVideosAction = () => async dispatch => {
  try {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    const response = await getVideoWithStatus();
    const data = response.data.sort((a, b) => a.id -  b.id)
    dispatch({
      type: SET_MY_CONTENT,
      payload: data,
    });
  } catch (e) {
    toast.error(e?.response?.data?.error || e.message);
  } finally {
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  }
};

export const getReadyVideosAction = country => async dispatch => {
  try {
    const response = await getRecommendedVideo(country);
    dispatch({
      type: SET_READY_CONTENT,
      payload: response.data,
    });
  } catch (e) {
    toast.error(e.response.data.error);
  }
};

export const getEmergencyAction = () => async (dispatch, getState) => {
  const {
    user: {
      user: { country },
    },
  } = getState();

  try {
    const {
      data: { active: isEmergency },
    } = await getCountryStatus(country);

    dispatch(setIsEmergencyAction(isEmergency));
    return isEmergency;
  } catch (e) {
    toast.error(e.response.data.error);
  }
};
