export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_SIGN_UP = "AUTH_SIGN_UP";
export const AUTH_REFRESH = "AUTH_REFRESH";
export const PENDING = "AUTH_PENDING";
export const NOT_AUTH = "NOT_AUTH";
export const SAVE_USER = "SAVE_USER";
export const AUTHORIZED = "AUTHORIZED";
export const AUTH_STAGE = "AUTH_STAGE";
export const AUTH_TIMECODE = "AUTH_TIMECODE";
export const AUTH_REGISTR_INFO = "AUTH_REGISTR_INFO";
export const AUTH_DISABLED_RESETCODE = 'AUTH_DISABLED_RESETCODE';


