export const TermsOfUseUK = `
<!DOCTYPE html>
<html>
<head>
\t<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
\t<title></title>
\t<meta name="generator" content="LibreOffice 7.4.2.3 (Linux)"/>
\t<meta name="author" content="Пользователь Windows"/>
\t<meta name="created" content="2022-05-10T11:54:00"/>
\t<meta name="changed" content="00:00:00"/>
\t<meta name="GrammarlyDocumentId" content="a0145496c6966264545cfcc3907bd020bc3c8c667c03b1fe1e962bd140219a0a"/>
\t<style type="text/css">
\t\t@page { size: 8.27in 11.69in; margin-left: 1.18in; margin-right: 0.59in; margin-top: 0.79in; margin-bottom: 0.69in }
\t\t@page p { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }
\t\t@page a:link { color: #0563c1; text-decoration: underline }
\t</style>
</head>
<body lang="uk-UA" link="#0563c1" vlink="#800000" dir="ltr"><p style="line-height: 108%; margin-bottom: 0.11in">
<br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>УГОДА
КОРИСТУВАЧА </b></font></font>
</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><a name="_heading=h.1fob9te"></a>
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">РЕДАКЦІЯ
ДІЄ ВІД 05.01.2023 РОКУ</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">БУДЬ-ЛАСКА,
УВАЖНО ПРОЧИТАЙТЕ ДАНІ ПРАВИЛА ТА УМОВИ
КОРИСТУВАННЯ</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Ласкаво
просимо на Infocoin.online! Дана Угода користувача
визначає умови для доступу та використання
інтернет-сайтів, мобільних версій
інтернет-сайтів, мобільних додатків та
інших інтернет-порталів, що належать,
керуються, діють під торговельною маркою
Infocoin або доступ до яких надає Infocoin
Limited (як визначено нижче) час від часу,
що відносяться (а) до електронних ресурсів
Infocoin Limited, включаючи, але не обмежуючись
такими: інтернет-версія та версія,
оптимізована для мобільних пристроїв,
сайтів, що ідентифікуються за допомогою
уніфікованого покажчика ресурсу
&quot;Infocoin.online&quot; та мобільні та інші
додатки проекту Infocoin (далі - &quot;Платформа
Infocoin &quot;) Цей документ є юридично
зобов'язуючим угодою між Вами як
користувачем (користувачами) Платформи
Infocoin (надалі &quot;Ви&quot;, &quot;Ваш&quot;,
&quot;Користувач&quot;) та юридичною особою
Infocoin Limited, які укладають угоду та
визначеною відповідно до пункту 2.1 нижче
(надалі &quot;ми&quot;, &quot;наш&quot; або Infocoin
Limited).</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>1.
Застосування та прийняття умов</b></font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.1
Умови, що містяться в цьому документі,
регламентують доступ та використання
Вами Платформи Infocoin та послуг, програмного
забезпечення, додатків для IOS та Android та
продуктів (з використанням Сайту, який
включає платформи, зазначені у пункті
7.1 Умов) компанії Infocoin Limited (такі послуги,
програмне забезпечення та продукти
надалі спільно називаються &quot;Послуги&quot;),
поряд з Політикою конфіденційності (як
визначено в пункті 3.3 нижче), а також
поряд з іншими правилами та Політиками,
які Infocoin Limited та/або її афілійовані
компанії можуть періодично публікувати.
Цей документ та інші подібні правила
та Політики надалі спільно називаються
&quot;Умови&quot;. При використанні або при
здійсненні доступу до Сайту та &quot;Послуг&quot;
Ви погоджуєтесь прийняти та зобов'язуєтесь
дотримуватися Умов. Просимо Вас не
користуватися та не здійснювати доступ
до Послуг та Сайту, якщо Ви не приймаєте
всі Умови.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.2
Ви не можете використовувати та
здійснювати доступ до Послуг або Сайту
та не можете погоджуватися з Умовами,
якщо (а) Ваш вік не дозволяє укладати
договір з Infocoin Limited, або (б) Вам заборонено
користуватися будь-якими з Послуг за
законами будь-якої країни/регіону,
включаючи країну/регіон, резидентом
якої Ви є або, перебуваючи в якій Ви
користуєтеся або здійснюєте доступ до
Послуг та Сайту.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.3
Infocoin Limited може будь-якої миті змінити
будь-які Умови, опублікувавши відповідні
змінені та доповнені Умови на Сайті.
Продовжуючи використовувати або
здійснювати доступ до Послуг або Сайту,
Ви підтверджуєте свою згоду із зміненими
та доповненими Умовами.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.4
Якщо Infocoin Limited опублікувала або надала
переклад англомовної редакції Умов, Ви
погоджуєтесь з тим, що переклад надається
тільки для зручності, і з тим, що англомовна
редакція регламентує Ваше використання
Послуг або Сайту та доступ до них.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.5
У деяких випадках Ви повинні укладати
окрему угоду (або угоди) онлайн або за
межами Інтернету, з Infocoin Limited або іншою
афілійованою особою Infocoin Limited, на надання
будь-яких Послуг (або частини Послуг)
(далі - &quot;Додаткова угода&quot;). У випадку,
якщо положення Умов та Додаткових угод
не відповідають або суперечать один
одному, пріоритет надається Додатковим
Угодам лише у частині, що регламентує
надання відповідних Послуг (або частини
Послуг), що надаються за такими Додатковими
Угодами.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.6
Умови не можуть бути змінені або доповнені
жодним іншим чином, крім у письмовій
формі уповноваженою посадовою особою
Infocoin Limited з наступним розміщенням на
Сайті.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.7
При використанні або при здійсненні
доступу до Сайту та &quot;Послуг&quot; Ви
надаєте згоду на обробку персональних
даних, відповідно до умов Регламенту
ЄС 2016/679 – Загального регламенту по
захисту даних (General Data Protection Regulation, надалі
– GDPR). Безпосередньо у Політиці
конфіденційності (https://www.infocoin.online/docs)
вказані дані, які ми збираємо, причини,
через які ми їх збираємо, способи їх
використання та сторони, яким ми можемо
їх розкрити.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1.8
На момент реєстрації, задля виконання
Угоди користувача та Політики
Конфіденційності ви маєте бути у віці
не менше 16 років.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>2.
Надання послуг та інформація правового
характеру</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.1.
Ви укладаєте угоду з Infocoin Limited, компанією,
зареєстрованою в Республіці Кіпр (
реєстраційний номер: HE 433674). Оскільки
послуги або деякі з Послуг можуть
підтримуватися та надаватися афілійованими
компаніями Infocoin Limited, Infocoin Limited  може
доручити виконання деяких Послуг своїм
афілійованим особам.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.2.
Ви повинні зареєструватися як учасник
Платформи Infocoin для отримання доступу
та використання деяких Послуг та
Сервісів. Надалі, Infocoin Limited залишає за
собою право без попереднього повідомлення
обмежити доступ або використання певних
Послуг, частини Послуги або будь-якої
функції Сайту для Користувача або
поставити в залежність від інших умов,
які Infocoin Limited може ввести на власний
розсуд.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.3
Послуги (або будь-яка частина наданої
Послуги) можуть відрізнятися залежно
від регіону та країни. Infocoin Limited не
гарантує і не заявляє, що конкретна
Послуга, властивість або функція Послуги,
або схожа на тип і розширена Послуга
буде доступна Користувачам або конкретному
Користувачу. Infocoin Limited може на свій
розсуд обмежити, скасувати або створити
різні рівні доступу та використання, а
також функціонал будь-яких Послуг (або
будь-якої частини послуги, що надається)
по відношенню до різних користувачів.
</font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.4
У цих Умовах під «Платними Користувачами»
розуміються Користувачі, які замовляють
послуги з розміщення контенту та всі
інші особи, які здійснюють комерційну
діяльність на Сайті. Мінімальна вартість
розміщення однієї секунди контенту
дорівнює 0.0012423 Євро. Infocoin Limited може
створити, змінити, покращити, запровадити
умови, призупинити або скасувати надання
будь-якої Послуги (або будь-якої частини
Послуги) без попереднього повідомлення,
крім випадків надання платної Послуги,
коли такі зміни не суттєво негативно
впливатимуть на можливість Платних
Користувачів користуватися такою
Послугою. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.5
Деякі Послуги (або їх частина) можуть
надаватися афілійованими особами
Infocoin Limited від імені та за дорученням
Infocoin Limited.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">2.6
Послуги надаються за умов «як є» (as is).
Infocoin Limited не надає жодних гарантій щодо
безпомилкової та безперебійної роботи
Сайту або Послуг, відповідності Сайту
або Послуг конкретним цілям та очікуванням
Користувача.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3.
Умови для всіх користувачів</b></font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.1
Як необхідна умова Вашого доступу та
використання Платформи Infocoin та Послуг,
Ви підтверджуєте дотримання всіх чинних
законів та нормативних правових актів
під час використання та здійснення
доступу до Сайту та Послуг.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.2
Ви підтверджуєте, що Ви (а) не копіюватимете,
відтворюватимете, завантажуватимете,
повторно публікуватимете, продаватимете,
розповсюджуватимете або перепродуватимете
будь-які Послуги або будь-яку інформацію,
текст, зображення, графіки, відеозаписи,
звуки, директорії, файли, бази даних або
переліки, і т.д., доступні на Сайті
(Контент Сайту), і (б) Ви не будете
копіювати, відтворювати, завантажувати,
збирати або будь-яким іншим методом
використовувати Контент Сайту для цілей
ведення бізнесу, що конкурує з бізнесом
Infocoin Limited або комерційно використовувати
Контент Сайту. Систематичний пошук
Контенту Сайту на Сайтах для створення
або складання, прямо або опосередковано,
колекції, накопичення, баз даних,
директорій (використовуючи роботи,
шпигунські програми, автоматичні
пристрої або вручну) без письмового
дозволу Infocoin Limited заборонено. Використання
будь-якого контенту або даних на Сайтах
з будь-якою метою, що прямо не дозволена
в Умовах, заборонено.  </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.3
Ви зобов'язані прочитати такі документи,
що регулюють захист та використання
особистої інформації про Користувачів,
що міститься у Infocoin Limited та наших
афілійованих осіб:</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">а)
для всіх Користувачів, які використовують
або здійснюють доступ до Сайту чи Послуг,
що належить до Infocoin Limited – Політика
конфіденційності Infocoin Limited;</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">б)
для всіх Користувачів, які використовують
або здійснюють доступ до Сайту чи Послуг,
що належить до Infocoin – Угода користувача
Infocoin Limited;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.4
Infocoin Limited може дозволяти Користувачам
мати доступ до контенту, продуктів та
послуг, які пропонуються третіми
сторонами через гіперпосилання (у формі
текстових посилань, банерів, каналів
та інших), API, або в іншому вигляді, що
ведуть на веб-сайти третіх сторін. Ви
попереджені про те, що повинні прочитати
умови використання таких сайтів та/або
Політики конфіденційності, перш ніж
користуватися сайтами. Ви розумієте,
що Infocoin Limited не контролює такі сайти
третіх сторін, не переглядає такі сайти,
і не несе відповідальності та зобов'язань
перед будь-ким за такі сайти, або будь-який
контент, продукти чи послуги, які можуть
надаватися на таких сайтах або з
використанням таких сайтів.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.5
Ви підтверджуєте, що не будете здійснювати
жодних дій, які можуть призвести до
порушення цілісності комп'ютерних
систем та мереж Infocoin Limited та/або будь-яких
Користувачів, а також не матимете
несанкціонований доступ до таких
комп'ютерних систем або мереж.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3.6
Завантажуючи або відображаючи будь-яку
інформацію, контент або дані (далі
спільно - &quot;Користувацький контент&quot;)
на Платформі Infocoin, або надаючи будь-який
Користувацький Контент Infocoin Limited або
її представникам, у ступеню, дозволеному
застосовним законодавством, Ви надаєте
безвідкличний, безстроковий, повсюдний,
безоплатний, що передається (через
численні ступені) дозвіл Infocoin Limited
відображати, пересилати, розповсюджувати,
відтворювати, публікувати, дублювати,
адаптувати, модифікувати, перекладати,
створювати похідні дані, та будь-яким
іншим способом використовувати будь-який
або весь Контент користувача в будь-якій
формі, мультимедіа та технології, відомі
або на даний момент невідомі будь-яким
чином та для будь-яких цілей, які можуть
бути вигідні для Infocoin Limited, функціонування
Платформи Infocoin, надання будь-яких Послуг
та/або бізнесу Користувача. Ви підтверджуєте
та гарантуєте Infocoin Limited, що Ви маєте всі
права та повноваження для надання такого
дозволу та Контент користувача та
використання такого Контенту користувача
(включаючи похідні результати
інтелектуальної діяльності) Infocoin Limited
та/або її афілійованими особами на
підставі такого дозволу вільно від
будь-яких обмежень та не порушує Прав
третіх осіб (як цей термін визначено у
пункті 5.4 Умов). У максимальній мірі,
дозволеній законом, Ви відмовляєтеся
від свого права вимагати примусового
виконання ваших прав інтелектуальної
власності на контент користувача щодо
Infocoin Limited та/або її афілійованих осіб,
правонаступників або субліцензіатів
у зв'язку з використанням такого Контенту
користувача у зв'язку з наданням Послуг.
Інформація, захищена відповідно до норм
законодавства про захист персональних
даних, використовуватиметься і
зберігатиметься виключно відповідно
до вимог цих законів. </font></font>
</p>
<p style="line-height: 108%; margin-bottom: 0.11in"> 
</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>3</b></font></font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>1</b></font></font></sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>.</b></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
</font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>Умови
для Учасників, які переглядають
Користувацький контент</b></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.1
Учасники, які зареєструються як Глядачі,
</font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">мають
змогу отримати винагороду через </font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Платформу
Infocoin</font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">,
в результаті перегляду</font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">
Користувацького контенту. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.2
Учасник, який зареєструється на Платформі
Infocoin</font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">,
для подальшого отримання винагороди,
повинен переглянути обраний контент
та виконати необхідні дії для підтвердження
перегляду і верифікації особи.</font></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font></font><font color="#000000"><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.3
Здійснення одночасного перегляду
контенту Глядачем більш ніж з одного
електронного пристрою не можливий.  </font></font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font></font><font color="#000000"><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.4
У разі виявлення у Користувацькому
контенті ознак передбачених п.п. 5.5 цієї
Угоди, Глядач має звернутись з відповідним
зверненням до служби підтримки сайту
за посиланням </font></font></font><a href="https://view.infocoin.online/help"><font color="#0563c1"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>https://view.infocoin.online/help</u></font></font></font></a><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.
 </font></font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font></font><font color="#000000"><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.5
Кожен Глядач має право зареєструвати
тільки один унікальний акаунт. Infocoin
Limited забороняє створення більше одного
акаунту Глядачем. У разі створенням
одним Глядачем декількох акаунтів,
Infocoin Limited має право видалити такі акаунти,
керуючись умовами п.п. 6.2. цієї Угоди.</font></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">3</font></font></font><font color="#000000"><sup><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">1</font></font></sup></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.6
Стосовно інших умов, прав та обов’язків,
що не визначені цим Розділом, Глядачі
керуються цією Угодою Користувача,
чинним міжнародним законодавством,
</font></font></font><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">застосовними
законами та нормативними правовими
актами у їхній відповідній юрисдикції</font></font><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.</font></font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>4.
Облікові записи</b></font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.1
Користувач повинен бути зареєстрований
на Платформі Infocoin для отримання доступу
або використання деяких Послуг
(Зареєстрований Користувач також має
назву &quot;Учасник&quot;). За винятком
спеціального дозволу Infocoin Limited, один
Користувач може  зареєструвати лише
один обліковий запис Користувача.
Infocoin Limited має право скасувати або видалити
обліковий запис Користувача, якщо
Infocoin Limited має причини вважати, що
користувач має одночасно зареєстровані
або контролює два та більше облікові
записи. Надалі, Infocoin Limited може відхилити
запит Користувача на реєстрацію з
будь-якої причини.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.2
Під час реєстрації на Платформи Infocoin,
Infocoin Limited має призначити обліковий запис
та створити ідентифікаційний номер
Учасника та пароль (зазвичай ідентифікаційний
номер це адреса електронної пошти, а
пароль має бути обраний зареєстрованим
Користувачем під час реєстрації). </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.3
Комбінація ідентифікаційного номера
Учасника та пароля є унікальною для
одного облікового запису. Кожен Учасник
несе повну відповідальність за
конфіденційність та збереження його
ідентифікаційного номера Учасника та
пароля для всіх видів використання та
діяльності, що відбувається під Вашим
обліковим записом (незалежно від того,
дозволені такі види використання чи
діяльності Учасником чи ні). Учасник не
може ділитися, передати або дозволити
використання його ідентифікаційного
номера Учасника або пароля будь-якій
іншій особі, навіть усередині компанії
Учасника (якщо застосовується). Учасник
зобов'язується негайно повідомляти
Infocoin Limited, про те, що, за його впевненістю,
відбувається несанкціоноване використання
його пароля або облікового запису, або
відбувається будь-яке інше порушення
безпеки облікового запису.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.4
Учасник підтверджує, що будь-яке
використання Платформи Infocoin та Послуг,
а також діяльність, що здійснюється під
його обліковим записом (включаючи без
обмежень розміщення будь-якої інформації
про компанію та продукти, натискання
кнопок підтвердження згоди на будь-які
Додаткові Угоди або правила, підписка
або оплата будь-яких послуг, відсилання
електронних листів, використовуючи
обліковий запис електронної пошти або
розсилаючи СМС, або соціальні мережі)
буде розцінюватися як санкціонована
Учасником. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.5
Учасник усвідомлює, що передача його
облікового запису іншим особам, або
дозвіл використання безліччю користувачів
за межами його компанії (якщо застосовно)
користуватися його обліковим записом
(спільно, &quot;множинне використання&quot;)
може завдати непоправної шкоди Infocoin
Limited або іншим користувачам Платформи
Infocoin. Учасник зобов'язується відшкодувати
Infocoin Limited, її афілійованим особам,
керівництву, співробітникам, агентам
та представникам будь-які збитки та
збитки (включаючи, але не обмежуючись
недоотримані доходи), понесені в
результаті множинного використання
його облікового запису. Учасник також
підтверджує, що у разі множинного
використання його облікового запису
або порушення з вини Учасника збереження
даних вашого облікового запису, Infocoin
Limited не несе відповідальності за будь-які
збитки та збитки, понесені через таке
порушення та має право призупинити або
видалити обліковий запис Учасника без
згоди Учасника.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.6
Однією з Послуг, що надаються всім
Учасникам на Платформи Infocoin, є забезпечення
захисту облікових записів Учасників
від шахрайства, втрати контролю над
обліковим записом без волі Учасників
та вчинення з використанням облікових
записів протиправних дій, що порушують
ці Умови та права Учасників, включаючи,
крім іншого, облікового запису для
створення недостовірного трафіку на
Платформи Infocoin, здійснення транзакцій
без волі Учасників тощо. (Далі – «Послуга
Антифрод»). В рамках Послуги Антифрод
ми зобов'язуємося забезпечувати
періодичний моніторинг дій, що здійснюються
з обліковим записом Учасника, з метою
виявлення потенційних загроз для безпеки
облікового запису Учасника на Платформи
Infocoin. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.7
У разі виявлення потенційних загроз
для облікового запису в рамках Послуги
Антифрод ми зобов'язуємося:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.7.1
Обмежити для облікового запису Учасника
функцію здійснення транзакцій (покупок)
на Платформи Infocoin негайно (протягом
доби), як тільки нам стане відомо про
потенційну загрозу. Це обмеження не
вплине на транзакції (купівлі), здійснені
до запровадження обмеження. Для вирішення
питання про заперечення та скасування
подібних транзакцій (покупок), якщо вони
були скоєні зловмисниками, Учасник
повинен зв'язатися з нами;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.7.2
Зв'язатися з Учасником і вжити заходів,
що залежать від нас, для того, щоб
переконатися, що його обліковий запис
не перейшов під контроль зловмисників
або шкідливого програмного забезпечення.
В рамках Послуги Антифрод наші фахівці
можуть, виходячи з конкретних обставин,
вступити до електронного листування з
Учасником, зв'язатися з ним за контактними
даними, наданими Учасником під час
реєстрації (а також під час використання
облікового запису) та/або запитати, щоб
особа, яка контролює обліковий запис,
підтвердила, що вона є Учасником, надавши
скан/фото будь-якого офіційного документа,
що підтверджує особу;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.7.3
Розглянути можливість зняття обмежень
на здійснення транзакцій (покупок) для
облікового запису Учасника за результатами
заходів, викладених у п.4.7.2 Умов;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.8
Ми зобов'язуємось зберігати конфіденційність
усієї інформації, отриманої від Учасника
в результаті заходів, зазначених у
п.4.7.2 Умов, самого факту обмеження для
облікового запису Учасника функції
здійснення транзакцій (покупок), а також
методики проведення моніторингу в
рамках Послуги Антифрод та результатів
такого моніторингу.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.9
Ми зобов'язуємося забезпечувати
оперативне реагування на запити
правоохоронних органів, що надходять
щодо Учасника, розміщеного ним контенту
та облікового запису.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.10.
Учасник зобов'язується сприяти нам під
час проведення заходів, зазначених у
п.4.7.2 Умов, у тому числі надавати повні,
достовірні та актуальні відомості,
запитані у нього в рамках Послуги
Антифрод, відповідно до наших вказівок.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.11
У разі ненадання відомостей, запитаних
згідно з п.4.7.2 Умов, протягом 30 днів з
дня запиту обліковий запис Учасника
може бути видалений або повністю
заблокований. У разі повідомлення нам
недостовірних відомостей у відповідь
на наші запити, обліковий запис може
бути видалений або повністю заблокований
у будь-який час за нашим рішенням. У разі
повідомлення недостовірних відомостей
ми розцінимо це як обставину, яка вказує
на можливе протиправне захоплення
облікового запису, що може бути підставою
для вжиття встановлених Умовами заходів,
а також для звернення до компетентних
правоохоронних органів.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">4.12
Ми докладаємо всіх зусиль для забезпечення
кібербезпеки облікових записів. Тим не
менш, ми не даємо гарантії, що внаслідок
надання Послуги Антифрод треті особи
не зможуть протиправно використати
обліковий запис, у тому числі не зможуть
зашкодити Учаснику. У зв'язку з цим
Учасник повинен проявляти пильність
під час роботи з обліковим записом,
утримуватися від введення пароля на
чужих пристроях, використання облікового
запису в людних місцях, збереження
пароля на незахищених носіях та вчинення
інших дій, що створюють загрозу безпеці.
Щоб уникнути сумнівів, всі обмеження
відповідальності, встановлені в розділі
8 цих Умов у повному обсязі, застосовуються
до Послуги Антифрод.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>5.
Обов'язки Учасників</b></font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.1
Кожен Платний Користувач представляє,
гарантує та підтверджує, що (а) він має
всі повноваження для згоди з Умовами,
видачу дозволу та для виконання
зобов'язань за цією Угодою; (б) він
використовує та здійснює доступ до
Платформи Infocoin та Послуг лише з метою
ведення бізнесу , та (в) для Платних
Користувачів, які є юридичними особами,
адреса, надана під час реєстрації, є
основним місцезнаходженням підприємства.
Для цілей цього положення, філія або
додатковий офіс не може розглядатися
як окреме підприємство, і Ваше основне
місцезнаходження буде розглядатися як
головний офіс.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.2
Платний Користувач зобов'язаний надавати
інформацію, дані про компанію, бізнес
або продукти/послуги як частину процесу
реєстрації на Платформі Infocoin або
використання та доступу до будь-яких
Послуг або облікового запису. Кожен
Платний Користувач представляє, гарантує
та підтверджує, що (а) така інформація
та дані, представлені в процесі реєстрації
або протягом використання Платформи
Infocoin або Послуг, є правдивою, точною,
актуальною та повною, та (б) ви будете
підтримувати та оперативно змінювати
всю інформацію та дані, щоб вони залишалися
правдивими, точними, актуальними та
повними.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.3
Ставши Учасником, Ви даєте згоду на
включення контактної інформації про
вас до нашої бази даних та дозволяєте
Infocoin Limited та нашим афілійованим особам
та іншим чином використовувати вашу
особисту інформацію відповідно до
Політики конфіденційності.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.4
Кожен Учасник представляє, гарантує та
підтверджує, що (а) він несе повну
відповідальність за отримання всіх
необхідних сторонніх дозволів щодо
будь-якого Контенту користувача, який
він представляє, завантажує або
відображає; (б) будь-який Контент
користувача, який він представляє,
завантажує або відображає, не порушує
авторські права, патенти, торговельні
марки, фірмові найменування, торгові
секрети або будь-які інші особисті або
майнові права будь-якої третьої сторони
(&quot;Права третіх осіб&quot;); та (в) він має
право і повноваження на продаж, купівлю,
торгівлю, розповсюдження або експорт
або пропозицію до продажу, торгівлю,
розповсюдження або експорт продукції
або послуг, описаних у Контенті користувача
і такий продаж, купівля, торгівля,
розповсюдження або експорт або пропозиція
не порушують будь-які Права третіх осіб;
(г) Ви та Ваші афілійовані особи не є
об'єктом будь-яких торгових обмежень,
санкцій або інших правових обмежень,
запроваджених якоюсь країною, міжнародною
організацією чи юрисдикцією, а також
не брали участь у дистанційному продажу
контрафактної чи піратської продукції;
(д) якщо Контент користувача містить
зображення інших осіб, крім Вас, то Ви
підтверджуєте, що отримали згоду даних
осіб на використання їх зображень
відповідно до умов цієї Угоди. Якщо ці
особи є неповнолітніми відповідно до
застосовного законодавства, Ви
підтверджуєте, що отримали згоду їхніх
законних представників на використання
їх зображення відповідно до умов цієї
Угоди, необхідної відповідно до чинного
законодавства; (e) якщо Контент користувача
містить Ваше зображення, то Ви
підтверджуєте, що надаєте згоду на
використання Вашого зображення відповідно
до умов цієї Угоди.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.5
Кожен Учасник представляє, гарантує та
підтверджує, що Користувацький Контент,
який він представляє, завантажує або
відображає, має бути:</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(а)
правдивим, точним, повним та правомірним;</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(б)
не бути хибним, що вводить в оману чи
оманливим;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(в)
не містить інформації, яка є дискредитуючою,
наклепницькою, загрозливою або
переслідуючою, непристойною, неприйнятною,
нападницькою, наступальною, явно
сексуальною або шкідливою для
неповнолітніх;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(г)
не містить інформації, яка є дискримінаційною
або сприяє дискримінації за ознакою
раси, статі, релігії, національності,
інвалідності, сексуальної орієнтації
чи віку;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">д)
не порушує Угоду Користувача, інші умови
або будь-які застосовні Додаткові угоди;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">е)
не порушує будь-які застосовні закони
та правила (у тому числі, без обмеження,
що регулюють експортний контроль, захист
прав споживачів, захист авторських
прав, недобросовісну конкуренцію,
надмірне необґрунтоване завищення цін
або неправдиву рекламу) або просуває
будь-які заходи, які можуть порушити
будь-які застосовні закони та правила;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(ж)
не містить прямих або опосередкованих
посилань на будь-які інші веб-сайти, які
містять будь-який контент, який може
порушувати ці Умови.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.6
Кожен Учасник також представляє, гарантує
та підтверджує, що зобов'язується:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">а)
здійснювати діяльність на Платформи
Infocoin відповідно до будь-яких застосовних
законів та правил;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">б)
проводити свої ділові операції сумлінно;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">в)
здійснювати діяльність відповідно до
Умов та будь-яких застосовних Додаткових
угод;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">г)
не використовувати Послуги або Платформу
Infocoin, щоб обдурити будь-яку особу чи
організацію (у тому числі, без обмеження,
не здійснювати продаж крадених речей,
використання вкрадених кредитних/дебетових
карток);</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">д)
не видавати себе за іншу особу чи
організацію, не спотворювати себе чи
свою приналежність до будь-якої особи
чи організації;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">е)
не займатися розсилкою спаму чи фішингу;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">ж)
не допускати будь-яких інших протиправних
дій (у тому числі, без обмежень, ті, які
будуть кримінальним злочином, призведуть
до цивільної відповідальності, тощо)
або заохочувати будь-які протиправні
дії;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">з)
не намагатися копіювати, відтворювати,
експлуатувати або експропріювати різні
фірмові каталоги, бази даних та списки
Infocoin Limited;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">і)
не розповсюджувати будь-які комп'ютерні
віруси або інші руйнівні пристрої та
коди, що ушкоджують, перешкоджають,
перехоплюють або експропріюють будь-які
програмні або апаратні системи, дані
або особисту інформацію;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(k)
не брати участь у будь-яких схемах, які
можуть підірвати цілісність даних,
систем або мереж, використовуваних
Infocoin Limited та/або будь-яким користувачем
Платформи Infocoin або отримати несанкціонований
доступ до таких даних, систем та мереж;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">л)
не брати участь у будь-яких заходах, які
б змусили Infocoin Limited та її афілійовані
особи нести будь-яку відповідальність.
</font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.7
Учасник не може користуватися Платформою
Infocoin, Послугами та обліковим записом
для участі в діяльності, схожій або
ідентичній з бізнесом майданчика
електронної рекламації Infocoin Limited.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.8
Учасник погоджується надати всю необхідну
інформацію, дані та підтвердження, та
надавати всю необхідну допомогу та
співпрацю, необхідні для надання Infocoin
Limited послуг з оцінки порушення Умов
та/або обробки скарг будь-якого Учасника
та/або Послуги Антифрод. Якщо Учасник
не виконав ці вимоги, що спричинило
затримку, призупинення або скасування
надання Послуги, Infocoin Limited. не зобов'язана
ні продовжувати термін надання відповідної
Послуги, ні нести відповідальність за
будь-які збитки або збитки, що випливають
з такої затримки, зупинення або скасування,
або пов'язані з ними.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.9
Учасник визнає та погоджується з тим,
що Infocoin Limited зберігає за собою право,
але не зобов'язана активно контролювати
або здійснювати будь-який редакційний
контроль за змістом будь-яких повідомлень,
даних або інформації (включаючи Контент
користувача), створеної, отриманої або
доступної через Послуги або Платформу
Infocoin. Infocoin Limited не підтримує, не перевіряє
або іншим чином не сертифікує зміст
будь-якого ролика, контенту або інші
дані або інформацію (включаючи Контент
користувача), створені, надані, розміщені
або іншим чином зроблені будь-яким
Учасником. Кожен Учасник несе
відповідальність за зміст своїх
повідомлень і може бути притягнутий до
юридичної відповідальності або
відповідальності за їх коментарі або
інші дані або інформацію.  </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.10
Платний Користувач визнає та погоджується
з тим, що Платформою Infocoin та Послугами
(на правах Платного Користувача) можуть
користуватися організації (зокрема
індивідуальні підприємці) та їхні
представники, або фізичні особи, з метою
ведення бізнесу.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.11
Учасник визнає та погоджується з тим,
що кожен Учасник несе персональну
відповідальність за ознайомлення із
застосовними законами та нормативними
правовими актами у їхній відповідній
юрисдикції для підтвердження, щодо
використання та здійснення доступу до
Платформи Infocoin та Послуг ведеться у
відповідність до них.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.12.
Цим Учасник підтверджує, що він ознайомився
з цією Угодою та погоджується (і/або
отримав відповідну письмову згоду своїх
законних представників (якщо застосовно)
та Infocoin Limited, вправі вимагати документальне
підтвердження цієї згоди) з тим, що
Infocoin Limited може зв'язуватися з Вами поштою,
електронною поштою, телефоном, надсилати
смс, повідомлення електронною поштою,
push-повідомлення, а також за допомогою
інших засобів зв'язку, в т.ч. але не
обмежуючись, через соціальні мережі (в
т.ч. але не обмежуючись Linkedin, Facebook),
месенджери( в т.ч., але не обмежуючись
Viber, Whatsapp, Telegram), з метою, включаючи, але
не обмежуючись, маркетингу, реклами,
вивчення думки, просування Платформи
Infocoin та Послуг. Цей Учасник також дає
згоду на те, що Infocoin Limited з метою направлення
рекламно-інформаційних, сервісних,
інших повідомлень Infocoin Limited вправі
залучати третіх осіб, у т.ч.,але не
обмежуючись, різні компанії, що надають
відповідні послуги за направленням
рекламно-інформаційних, сервісних та
інших повідомлень, мобільні оператори,
месенджери, різні соціальні мережі,
платформи, інші компанії, що надають
подібні послуги.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.13
Кожен Учасник запевняє, гарантує та
погоджується з тим, що Він та Його
афілійовані особи дотримуються чинного
законодавства та регулювання (включаючи,
але не обмежуючись, застосовне
законодавство та регулювання щодо
безпеки товарів, прав інтелектуальної
власності, конфіденційності даних,
захисту споживачів, недобросовісної
конкуренції, ціноутворення, неналежної
реклами середовища, здоров'я та безпеки,
антикорупційне законодавство та
законодавство про протидію легалізації
грошових коштів та фінансуванню
тероризму);</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.14
Кожен Учасник також запевняє, гарантує
та погоджується щодо Платформи Infocoin,
інтернет-версії та версії, оптимізованої
для мобільних пристроїв Платформи
Infocoin, що ідентифікуються за допомогою
уніфікованого покажчика ресурсів
Infocoin.online та відповідного мобільного
додатка Infocoin що: </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(а)
він та його афілійовані особи не є
об'єктом будь-яких торгових обмежень,
санкцій або інших правових обмежень,
запроваджених або запропонованих до
введення будь-якою країною, міжнародною
організацією чи юрисдикцією (&quot;Відповідні
суб'єкти&quot;);</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(б)
ні він, ні будь-яка з його материнських
компаній або афілійованих осіб (якщо
застосовно), прямо чи опосередковано,
не укладає угоди або не надає жодних
коштів, товарів чи послуг відповідним
суб'єктам; </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">(в)
він зобов'язується у будь-який час
дотримуватись усіх застосовних законів
та правил експортного контролю та
санкцій щодо товарів, послуг, програмного
забезпечення та технологій при
використанні Послуг, включаючи резолюції
про санкції, закони та правила, прийняті
та застосовані Радою Безпеки ООН,
Китайською Народною Республікою,
Сполученими Штатами Америки та будь-якою
іншою країною.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">Якщо
в якийсь момент часу він не виконає
жодної з вищезгаданих вимог, йому слід
негайно припинити користуватися
Послугами та Платформою Infocoin.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">5.15.
Якщо Infocoin Limited обґрунтовано вважає, що
будь-яка з ваших дій та/або бездіяльність
порушує або загрожує порушенням будь-яких
застосовних законів та правил, Infocoin
Limited може на свій власний розсуд у
будь-який час вжити заходів, які визнає
необхідними у світлі відповідних
обставин, у тому числі, але не обмежуючись,
припинити надання Вам усіх або деяких
Послуг, а також окремих функцій Сайту,
закрити відповідні розміщення та
припинити дію Вашого облікового запису,
залишаючи за собою всі права, які може
мати стосовно будь-яких дій або поведінки
її учасників, що не відповідають вимогам
законодавства. Ви погоджуєтесь що такі
дії компанії не є правомірними.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>6.
Порушення учасників</b></font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.1
Infocoin Limited залишає за собою право на
власний розсуд видалити, змінити або
відхилити будь-який Контент користувача
(частково або повністю), який ви
представляєте, завантажуєте або
відображаєте на Платформі Infocoin який ми
обґрунтовано вважаємо незаконним, що
порушує Умови, а також який може призвести
до притягнення Infocoin Limited або афілійованих
осіб до відповідальності або порушує
Права третіх осіб або може зашкодити
інтересам наших учасників, третіх осіб
або Infocoin Limited або її афілійованих осіб
або який тим чи іншим чином вважається
недоречним, на власний розсуд Infocoin
Limited.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.2
Якщо будь-який Учасник порушує будь-які
Умови або Infocoin Limited має розумні підстави
вважати, що будь-який Учасник порушує
будь-які Умови, то Infocoin Limited має право
застосовувати такі заходи реагування,
які вважає за доречне, включаючи, без
обмеження: (а) призупинити або видалити
обліковий запис Учасника та будь-які
облікові записи, пов'язані з таким
обліковим записом, які Infocoin Limited  визначить
на власний розсуд. У цьому випадку
Infocoin Limited не нестиме відповідальності
за втрати або збитки, що виникли через
таке зупинення або видалення; (б) обмежити,
призупинити або припинити підписку на
будь-яку Послугу, доступ до будь-якої
Послуги, або поточне чи майбутнє
використання будь-якої Послуги; (в)
видалити розміщену інформацію або інший
Контент користувача, які Учасник надав,
опублікував або відображає, або накласти
обмеження на кількість інформації, яку
Учасник може розмістити або відобразити,
або на кількість іншого Контенту
Користувача який Учасник може розміщувати
або відображати; (г) накласти інші
обмеження на використання Учасником
будь-яких властивостей або функцій
будь-якої Послуги, як Infocoin Limited вважатиме
доречним на власний розсуд; та (д) будь-які
інші заходи виправлення, впливи або
штрафи, які Infocoin Limited вважатиме доречним
на власний розсуд.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.3
Без обмеження спільності положень Умов,
Учасник буде вважатися таким, що порушує
Умови в будь-якій з наступних обставин:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">а)
за скаргою або претензією від будь-якої
третьої сторони, якщо Infocoin Limited має
розумні підстави вважати, що такий
Учасник має намір або фактично не виконує
свій контракт з третьою стороною,
включаючи, без обмеження, недопоставку
Учасником, який є постачальником товарів
або послуг за допомогою Платформи
Infocoin та Послуг, будь-якого товару чи
послуги на замовлення такої третьої
сторони після отримання покупної ціни,
або під час постачання товарів такий
Учасник фактично порушив умови та
положення, зазначені у контракті,
укладеній з цією третьою стороною.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">б)
Infocoin Limited має розумні підстави підозрювати,
що такий Учасник використав крадені
кредитні картки або іншу неправдиву
інформацію, що вводить в оману, в будь-якій
угоді з контрагентом,</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">в)
Infocoin Limited має розумні підстави підозрювати,
що будь-яка інформація, що надається
Учасником, не є актуальною чи повною
або є хибною, неточною або вводить в
оману;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">г)
Infocoin Limited вважає, що дії Учасника можуть
призвести до втрат або фінансової
відповідальності організації Infocoin
Limited або афілійованих осіб або будь-яких
інших користувачів.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.4
Infocoin Limited залишає за собою право повною
мірою співпрацювати з державними чи
регулюючими органами, правоохоронними
органами, приватними детективами та/або
постраждалими третіми особами у
розслідуванні будь-якого підозрюваного
кримінального чи цивільного правопорушення.
Крім того, в межах застосовного
законодавства, Infocoin Limited може розкрити
контактну інформацію про особу Учасника
та/або інформацію про його обліковий
запис (облікові записи), транзакції або
дії, вчинені на Платформі Infocoin або за
допомогою Платформи Infocoin, на вимогу
органу влади, регулюючих або правоохоронних
органів, або потерпілої третьої особи,
або в результаті виклику до суду чи
іншої судової справи. Infocoin Limited не несе
відповідальності за збитки або результати,
що випливають з такого розкриття, і
Учасник зобов'язується не вдаватися до
будь-яких дій та від подання позову
проти Infocoin Limited через таке розкриття.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.5
Infocoin Limited може у будь-який час на свій
розсуд, накласти обмеження, призупинити
або припинити використання даним
Учасником будь-яких Послуг або Платформи
Infocoin, не несучи відповідальності перед
Учасником, якщо Infocoin Limited отримав
повідомлення, що Учасник порушує будь-яку
угоду або зобов'язання з будь-якою
афілійованою особою Infocoin Limited і таке
порушення тягне за собою чи існують
обґрунтовані підозри залучення у
вчинення нечесних чи шахрайських дій.
Infocoin Limited зберігає за собою право, але
не зобов'язане досліджувати таке
порушення чи вимагати підтвердження
від Учасника.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.6
Кожен Учасник зобов'язується відшкодувати
Infocoin Limited, афілійованим особам, директорам,
співробітникам, агентам та представникам,
а також уберегти від можливих втрат,
збитків, вимог та зобов'язань (у тому
числі судових витрат у повному обсязі),
які можуть виникнути від подання,
публікації або показу будь-якого Контенту
користувача, через використання або
доступ до Платформи Infocoin або Послуг,
або від порушення Умов або будь-яких
Додаткових угод.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">6.7
Кожен Учасник погоджується з тим, що
Infocoin Limited  не несе і не повинне нести
відповідальності перед ним або будь-ким
за будь-який Контент користувача або
інші дані, передані через Платформу
Infocoin за допомогою Послуг, у тому числі
шахрайські, що не відповідають дійсності,
що вводять в оману, неточні, наклепницькі,
або незаконні дані і що ризик збитку
від такого контенту Користувача та
інших даних повністю покладається на
кожного Учасника. Infocoin Limited залишає за
собою право власним коштом прийняти на
себе винятковий захист та контроль
будь-якої справи, яка підлягає компенсації
Учасником і в цьому випадку Учасники
повинні співпрацювати з Infocoin Limited,
погоджуючись на застосування будь-яких
доступних засобів захисту.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>7.
Угоди   </b></font></font>
</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.1
Через Сайт Infocoin Limited надає електронні
веб-платформи для обміну інформацією
між особами, які розміщують контент та
іншими учасниками. Infocoin Limited додатково
надає електронну веб-платформу проведення
угод для Учасників з розміщення,
погодження, укладення угод, управління
та виконання замовлень для надання
послуг з розміщення контенту в Інтернеті
за допомогою Сайту, адаптованої версії
для мобільних телефонів та додатків
відповідно до Договору про надання
послуг. Тим не менш, ні з яких послуг,
Infocoin Limited не представляє ні продавців,
ні покупців з конкретних угод. Infocoin
Limited не контролює і не несе відповідальності
за якість, безпеку, законність або
доступність продуктів або послуг, що
розміщуються на Сайті, або здатність
продавців завершити продаж або здатність
покупців завершити покупку. До складу
Послуг включаються всі функціональні
можливості Сайту, якими Ви можете
скористатися. Також до складу послуг
включається направлення та надання
персоналізованої маркетингової та
рекламної інформації. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.2
Користувачі цим повідомляються, що
можуть мати місце ризиковані переходи
за посиланнями та повідомленнями,
розміщеними з метою фішингу, або іншими
корисливими цілями. Infocoin Limited використовує
кілька методів для перевірки точності
певної інформації від Платних Користувачів,
які надають її нам під час реєстрації
для оплати Послуг на Сайті. Тим не менш,
через складність ідентифікації
користувачів через Інтернет, Infocoin
Limited не може і не підтверджує передбачувану
особу кожного користувача (у тому числі
без обмеження, Платних Користувачів).
Ми рекомендуємо Вам використовувати
різні засоби, а також здоровий глузд,
щоб оцінити, з ким ви маєте справу.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.3
Користувачі, які використовують або
здійснюють доступ до Платформи Infocoin
або Послуг, несуть ризики переходу за
будь-якими посиланнями, а також укладення
будь-яких угод купівлі-продажу та інших
угод у зв'язку з використанням сайтів
за посиланнями. Всі, хто використовують
або здійснюють доступ до Сайту або
Послуг повністю беруть на себе всі
ризики відповідальності або будь-якої
шкоди у зв'язку з переходами за посиланнями
та подальшою діяльністю, що стосуються
продукції або послуг, які є предметом
угод поза ведення Infocoin Limited. Приклади
таких ризиків включають, але не обмежуються
наступним: ненадання продукції та
послуг, шахрайські схеми, незадовільна
якість товару, невідповідність технічним
характеристикам, дефектні чи небезпечні
продукти, незаконні продукти, затримка
або невиконання доставки чи оплати,
невірні розрахунки вартості, порушення
гарантії, порушення контракту інциденти,
ризики того, що виробництво, імпорт,
експорт, розповсюдження, пропозиція,
показ, придбання, продаж та/або використання
продукції чи послуг, можуть порушувати
або можуть бути заявлені як порушуючі
права третіх осіб, та ризик того, що
Користувач може понести витрати на
захист або інші витрати у зв'язку з
порушенням прав третіх осіб, або у
зв'язку з будь-якими претензіями з боку
будь-якої Сторони, яка має право на
захист, або на компенсацію у зв'язку з
порушенням їх прав унаслідок пред'явлення
вимог чи претензій та позовів таких
третіх осіб . Такі ризики також включають
ризики вимог від споживачів, інших
покупців, кінцевих користувачів продукції
або інших третіх осіб, які стверджують,
що постраждали від використання Вами
сторонніх сайтів або Послуг при переході
за рекламними посиланнями. Усі вищезгадані
ризики називаються як &quot; Ризики
сторонніх угод &quot;. Infocoin Limited не несе
відповідальності за будь-які збитки,
претензії, зобов'язання, витрати, шкоду,
незручності, перерви у веденні бізнесу
або витрати будь-якого роду, які можуть
виникнути в результаті або у зв'язку з
будь-якими ризиками сторонніх угод.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.4
Користувачі на Платформі Infocoin несуть
повну відповідальність за визначення
та виконання умов угод, укладених на
Сторонніх сайтах, за допомогою або в
результаті використання Платформи
Infocoin або Послуг, у тому числі без
обмеження, за умови щодо оплати, повернень,
гарантії, доставки, страхування, зборів,
податків, титулів, ліцензій, штрафів,
дозволів, обробки, транспортування та
зберігання, з урахуванням будь-яких
додаткових зобов'язань, передбачених
будь-яким укладеним договором.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.5
Користувач зобов'язується надати всю
інформацію та дані, які можуть бути
потрібні Infocoin Limited, у зв'язку з тим, що
Ваші угоди проводяться на або через
використання Платформи Infocoin або Послуг.
Infocoin Limited має право призупинити або
видалити обліковий запис будь-якого
Користувача, якщо Користувач не може
забезпечити необхідну інформацію та
дані. У цьому випадку Infocoin Limited не нестиме
відповідальності за втрати або збитки,
що виникли через таке зупинення або
видалення або у зв'язку з ним. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">7.6
У разі виникнення спірної ситуації
будь-якого Користувача, такі Користувачі
зобов'язуються звільнити Infocoin Limited (і
наших агентів, афілійованих осіб,
директорів, посадових осіб та
співробітників) від усіх претензій,
вимог, дій, процедур, витрат та збитків
(включаючи, без обмежень, будь-які
фактичні, спеціальні, випадкові або
непрямі збитки), що виникають з або у
зв'язку з такою спірною ситуацією чи
угодою.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>8.
Обмеження відповідальності </b></font></font>
</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.1
МАКСИМАЛЬНОЮ МІРОЮ, ДОЗВОЛЕНОЮ ЗАКОНОМ,
ПОСЛУГИ, ЯКІ НАДАЄ Infocoin Limited НА АБО ЧЕРЕЗ
ПЛАТФОРМУ Infocoin АБО ПОСЛУГИ, НАДАЮСТЬСЯ
&quot;ЯК Є&quot;, &quot;ЯК ДОСТУПНО&quot; ТА &quot;ЗІ
ВСІМА ПОМИЛКАМИ&quot;. Infocoin Limited ВІДМОВЛЯЄТСЬЯ
ВІД БУДЬ-ЯКИХ ГАРАНТІЙ, ЯВНИХ ЧИ ЯКІ
МОЖУТЬ МАТИСЯ НА УВАЗІ, ВКЛЮЧАЮЧИ, АЛЕ
НЕ ОБМЕЖУЮЧИСЬ, БУДЬ-ЯКІ ГАРАНТІЇ СТАНУ,
ЯКОСТІ, ДОВГОВІЧНОСТІ, ПРОДУКТИВНОСТІ,
ТОЧНОСТІ, НАДІЙНОСТІ, КОМЕРЦІЙНОЇ ЦІНИ.
ВСІ ТАКІ ГАРАНТІЇ, ПРЕДСТАВЛЕННЯ, УМОВИ,
ОБОВ'ЯЗКИ СПРАВЖНІМ ВИКЛЮЧАЮТЬСЯ.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.2
МАКСИМАЛЬНОЮ МІРОЮ, ДОЗВОЛЕНОЮ ЗАКОНОМ,
Infocoin Limited НЕ ДАЄ ЖОДНИХ ГАРАНТІЙ ЩОДО
ДОСТОВІРНОСТІ, ТОЧНОСТІ, ПРАВИЛЬНОСТІ,
НАДІЙНОСТІ, ЯКОСТІ, СТАБІЛЬНОСТІ,
НЕПОВНОТИ АБО БЕЗПЕРЕВНОСТІ. Infocoin
Limited НЕ ГАРАНТУЄ, ЩО ВИРОБНИЦТВО, ІМПОРТ,
ЕКСПОРТ, РОЗПОДІЛ, ПРОПОЗИЦІЯ, ВІДОБРАЖЕННЯ,
КУПІВЛЯ, ПРОДАЖ ТА/АБО ВИКОРИСТАННЯ
ПРОДУКЦІЙ ЧИ ПОСЛУГ, ЯКІ ВІДОБРАЖЕНІ
НА САЙТІ, НЕ БУДЕ ПОРУШУВАТИ ЖОДНИХ
ПРАВ. Infocoin Limited НЕ ДАЄ НІЯКИХ ЗАЯВ АБО
ГАРАНТІЙ БУДЬ-ЯКОГО РОДУ ЩОДО БУДЬ-ЯКОГО
ПРОДУКТУ АБО ПОСЛУГ, ПРОПОНОВАНИХ АБО
НАДАНИХ НА САЙТІ ТА СТОРОННІХ САЙТАХ. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.3
Будь-які дані, завантажені або іншим
чином отримані через Платформу Infocoin
або Послуги, створюються на розсуд
кожного Користувача, і кожен Користувач
несе одноосібну відповідальність за
будь-яку шкоду своїй комп'ютерній системі
або комп'ютерній системі Infocoin Limited, а
також за будь-яку втрату даних, які
можуть виникнути внаслідок завантаження
будь-яких таких даних. Ніяка інформація
або порада, у усній чи письмовій формі,
отримані будь-яким Користувачем від
Infocoin Limited або через Платформу Infocoin, не
створюють жодних гарантій, які прямо
не вказані тут. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.4
Сайт може рекламувати послуги або
продукти Користувачів, які надаються
незалежними третіми сторонами. Жодних
гарантій або заяв не зроблено щодо таких
послуг чи продукції. Infocoin Limited та
афілійовані особи не несуть відповідальності
за будь-які такі послуги або продукти.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.5
Кожен Користувач погоджується з тим,
що зобов'язаний відшкодувати Infocoin
Limited, афілійованим особам, директорам,
посадовим особам та співробітникам
будь-які та всі збитки, а також звільнити
зазначених осіб від претензій,
відповідальності (у тому числі судових
витрат у повному обсязі), які можуть
виникнути в результаті використання
або здійснення таким Користувачем
доступу до Платформи Infocoin або Послуг
(включаючи, але не обмежуючись наданням,
розміщенням або відображенням інформації
такого Користувача або Контенту
Користувача на Сайтах) або від вашого
порушення будь-якої з Умов, у тому числі
запевнень і гарантій. Кожен Користувач
цим погоджується звільнити Infocoin Limited,
афілійованих осіб, директорів, посадових
осіб та співробітників від будь-яких
та всіх збитків, претензій, відповідальності
(в тому числі судові витрати на основі
повного відшкодування), які можуть
виникнути внаслідок порушення Користувачем
запевнень та гарантій, зроблених
Користувачем на адресу Infocoin Limited, у тому
числі, але не обмежуючись тими, що
викладені у пункті 5 цього Договору.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.6
Кожен Користувач цим погоджується
звільнити Infocoin Limited, афілійованих осіб,
директорів, посадових осіб та співробітників
від будь-яких та всіх збитків, претензій,
відповідальності (у тому числі судових
витрат у повному обсязі), які можуть
виникнути, прямо чи опосередковано,
внаслідок яких-небудь претензій,
заявлених позивачами Прав третіх осіб
або інших третіх сторін, пов'язаних з
послугами, продуктами, контентом
користувача або іншою інформацією, що
пропонуються або відображаються на
Сайті. Кожен користувач цього погоджується
з тим, що Infocoin Limited не несе зобов'язань
і не несе жодної відповідальності перед
ним щодо будь-яких даних, розміщених
іншими, у тому числі дискредитуючих або
незаконних даних, і що ризик збитків
від таких даних повністю покладається
на кожного Користувача. Infocoin Limited залишає
за собою право, за свій рахунок, взяти
на себе винятковий захист і контроль
будь-якого питання, яке підлягає
компенсації йому, і в цьому випадку він
повинен співпрацювати з Infocoin Limited,
затверджуючи будь-які засоби захисту.
</font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.7
Infocoin Limited не несе відповідальності за
будь-які, прямі, непрямі, штрафні,
випадкові або непрямі збитки або збитки
будь-якого роду (включаючи, але не
обмежуючись збитками у зв'язку із втратою
прибутку чи заощаджень, переривання
бізнесу, втрати інформації), понесені
внаслідок укладання контракту, недбалості,
правопорушення, за правом справедливості
чи іншим чином, або будь-які інші збитки,
пов'язані з будь-якою з наступних дій:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">а)
використання чи неможливості
використовувати Платформу Infocoin чи
Послуги;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">б)
будь-який дефект даних, інформації чи
послуг, придбаних чи отриманих від
Користувача або будь-якою іншою третьою
стороною через Платформу Infocoin чи Послуги;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">в)
порушення прав третіх осіб або претензії
або вимоги до виробництва, імпорту,
експорту, розповсюдження, пропозиції,
демонстрації, придбання, продажу та/або
використання продукції або послуг
Користувача, що рекламуються або
відображаються на Платформі Infocoin або
через надання Послуг, які можуть
порушувати або можуть бути заявлені як
такі, що порушують права третіх осіб;
або претензії будь-якої сторони,
пов'язаної із захистом прав,</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">г)
несанкціонований доступ третіх осіб
до даних або особистої інформації
будь-якого Користувача;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">д)
заяви чи дії будь-якого Користувача
Платформи Infocoin або Послуг;</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">е)
інші дії, пов'язані з Платформою Infocoin
або Послугами, що виникають, у тому числі
через недбалість.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.8
Незважаючи на будь-які з вищезазначених
положень, сукупна відповідальність
Infocoin Limited, наших співробітників, агентів,
афілійованих осіб, представників або
осіб, які діють від нашого імені щодо
кожного Користувача за всіма вимогами,
що випливають з використання або
здійснення доступу до Платформи Infocoin
або Послуг протягом будь-якого календарного
року, має бути обмежено більшою із сум
сплаченої Користувачем на рахунок
Infocoin Limited за використання або доступ до
Платформи Infocoin або Послуг протягом
календарного місяця, але не більше ніж
дозволеної відповідним законодавством.
Попереднє положення не виключає вимоги
від Користувача доказів фактичної
шкоди. Усі претензії, що виникають у
зв'язку з використанням Платформи
Infocoin або Послуг, мають бути подані
протягом шести (6) місяців з дати виникнення
підстави для позову або більш тривалого
періоду, якщо це передбачено відповідно
до чинного законодавства, що регулює
ці Умови.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">8.9
Обмеження та відмова від відповідальності
перед Вами відповідно до цих Умов
застосовуються максимальною мірою,
дозволеною законом і застосовуються
незалежно від того, чи попереджений
Користувач Infocoin Limited або мав знати про
можливість виникнення таких збитків.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>9.
Форс-мажор</b></font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">9.1
За жодних обставин Infocoin Limited не несе
відповідальності та не зобов'язане
виплачувати компенсацію будь-якого
характеру за будь-які збитки, що виникли
внаслідок недоступності, незручностей
або збоїв у роботі служб, систем, Платформи
Infocoin або затримки чи ненадання Послуг
з таких причин: (а) відключення системи
на технічне обслуговування; (б) неможливість
передачі даних внаслідок збоїв у роботі
терміналів зв'язку або телекомунікаційного
обладнання; (в) відмова систем та
неможливість виконання своїх функцій
у зв'язку з форс-мажорними обставинами,
включаючи, але не обмежуючись, тайфуни,
землетруси, цунамі, повені, відключення
електроенергії, пожежі, бурі, війни,
політичні хвилювання, страйки, нестача
робочої сили матеріалів, заворушення,
повстання, цивільні заворушення,
терористичні акти, війни, вибухи, стихійні
лиха, урядові дії, акти національних чи
іноземних судів чи трибуналів, невиконання
зобов'язань третіми особами; або (г)
призупинення чи затримка надання послуг
або збої в роботі систем з причин, що не
залежать від Infocoin Limited, таких як хакерські
або кібератаки, технічні коригування
або збої в роботі телекомунікаційного
обладнання, оновлення веб-сайту, збої
у третіх сторін або будь-яке призупинення
або переривання логістичних або ділових
операцій (включаючи, але не обмежуючись
цим, затримки чи переривання відновлення
роботи або операції з розпорядження
будь-якого урядового органу), у разі
національного чи регіонального поширення
епідемії чи пандемії.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>10.
Права на інтелектуальну власність</b></font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">10.1
Infocoin Limited є єдиним власником або законним
ліцензіатом усіх прав та інтересів на
Платформу Infocoin та контенту Платформи
Infocoin. Платформа Infocoin та контент Платформи
Infocoin втілюють комерційну таємницю та
інші права інтелектуальної власності,
що охороняються відповідно до міжнародних
законів про охорону авторського права
та інших законів. Всі назви, права
власності та права на інтелектуальну
власність на Платформу Infocoin та контент
сайту повинні залишитися з Infocoin Limited,
афілійованих осіб або ліцензіарів, якщо
такі є. Усі права, заявлені відповідно
до Умов або Infocoin Limited справжнім захищені.
</font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">10.2
&quot;Infocoin&quot;, &quot;Infocoin&quot; та відповідні
значки та логотипи є зареєстрованими
торгівельними знаками або товарними
або сервісними знаками Infocoin Limited або її
афілійованих осіб, у різних юрисдикціях
та захищені відповідно до чинного
авторського права, законодавства про
торгівельні знаки та іншими законами
про право власності. Несанкціоноване
копіювання, зміна, використання або
публікація цих знаків суворо заборонено
та є протиправними діями. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">10.3
Infocoin Limited може залучити незалежну третю
сторону для надання Платформи Infocoin або
Послуг (наприклад, постачальників для
аутентифікації та перевірки послуг).
Ви не можете використовувати будь-які
торговельні марки, сервісні знаки або
логотип таких незалежних третіх осіб
без попередньої письмової згоди з боку
таких осіб. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">10.4
Найбільшою мірою, допустимою чинним
законодавством, усі права, права власності
та інтереси на всі похідні результати
інтелектуальної діяльності, створені
Infocoin Limited та/або її афілійованими особами
з використанням Контенту користувача
відповідно до Умов, належать Infocoin
Limited, і можуть бути вільно передані
Infocoin Limited (у тому числі, за ліцензійною
угодою) будь-якій третій особі або її
афілійованим особам.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>11.
Сповіщення </b></font></font>
</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.1
Усі юридичні повідомлення або вимоги
на адресу Infocoin повинні бути зроблені в
письмовій формі та надіслані до Infocoin
Limited особисто, кур'єром або рекомендованим
листом на ім’я Директора за адресою:
Anexartisias &amp; Athinon, NORA COURT, 2nd floor, Office 203, 3040
Limassol, Cyprus</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><a name="_heading=h.gjdgxs"></a>
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">До
уваги Юридичного відділу. Усі юридичні
повідомлення або вимоги на адресу
Infocoin Limited повинні бути зроблені в
письмовій формі та надіслані до Infocoin
Limited особисто, кур'єром або рекомендованим
листом за адресою: Anexartisias &amp; Athinon, NORA
COURT, 2nd floor, Office 203, 3040 Limassol, Cyprus. Повідомлення
мають бути чинними на момент отримання
Infocoin Limited будь-яким із вищезазначених
способів.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.2
Усі юридичні повідомлення або вимоги
на адресу Користувача повинні бути
чинними, якщо або доставлені особисто,
кур'єром, рекомендованою поштою, факсом
або електронною поштою за останньою
відомою адресою, факсом або електронною
поштою, зазначеною Користувачем Infocoin,
або шляхом розміщення такого повідомлення
або вимоги на Сайті, у розділі, який
публічно доступний, без оплати.
Повідомлення Користувача визнається
отриманим таким користувачем, якщо і
коли:</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">а)
Infocoin Limited може продемонструвати, що це
повідомлення або вимога, будь то у
фізичному чи електронному вигляді, було
надіслано такому Користувачеві, або</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">б)
відразу після розміщення Infocoin Limited
такого повідомлення на Сайті у розділах,
які доступні публічно без оплати.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">11.3
Ви погоджуєтесь, що всі угоди, сповіщення,
вимоги, інформацію та інші комунікації,
які Infocoin Limited надсилає Вам в електронному
вигляді задовольняють вимогам закону,
і такі повідомлення повинні вважатися
надісланими у письмовій формі.</font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><b>12.
Загальні положення</b></font></font></p>
<p style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.1
З урахуванням будь-яких Додаткових
угод, Умови є повною угодою між вами та
Infocoin Limited та регулюють використання
Вами Платформи Infocoin та Послуг, замінюючи
будь-які попередні письмові або усні
домовленості щодо змісту цього документа.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.2
Infocoin Limited і ви є незалежними сторонами,
і жодне з відносин: агентство, партнерство,
спільне підприємство, працівник-роботодавець
або франшизодавець-франшизоодержувач
не передбачається і не створюється
відповідно до Умов.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.3
Якщо будь-яке положення Умов буде визнано
недійсним або нездійсненним, таке
положення має бути видалене, а решта
положень залишаються чинними і будуть
дотримуватися.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.4
Заголовки застосовуються лише з
довідковою метою і жодним чином не
визначають, обмежують, тлумачать або
описують зміст відповідного пункту.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.5
Відмова Infocoin Limited виконати будь-яке
право або бездіяльність щодо будь-якого
порушення вами відповідно до Умов, не
означає відмови від цього права, і не
означає відмови від права Infocoin Limited
діяти відповідним чином за наступних
або подібних порушень. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.6
Infocoin Limited має право передати Умови (в
тому числі всі наші права, титули, вигоди,
інтереси та зобов'язання та обов'язки
в Умовах будь-якій фізичній або юридичній
особі (в тому числі будь-якій афілійованій
особі Infocoin Limited). Ви не можете  призначити,
в цілому або частково, Умови будь-якій
особі чи організації. </font></font>
</p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.7
ЦІ УМОВИ (УГОДА) РЕГУЛЮЮТЬСЯ ДІЮЧИМ
ЗАКОНОДАВСТВОМ, ЯКЩО ВИ УКЛАДЕТЕ УГОДУ,
У ВІДПОВІДНОСТІ З ПУНКТОМ 2.1. СТОРОНИ
ЦІЄЇ УГОДИ ВИЗНАЮТЬ, ЩО СУДИ РЕСПУБЛІКИ
КІПР МАЮТЬ ВИКЛЮЧНУ КОМПЕТЕНЦІЮ СТОСОВНО
ВИРІШЕННЯ СПОРІВ МІЖ НАМИ ЯК СУБ’ЄКТАМИ
УГОДИ.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.8.
Якщо у вас є будь-які коментарі щодо
Послуг, які ми вам надаємо, ви можете
звернутися до офісу компанії письмово,
або електронною поштою </font></font><a href="mailto:help@infocoin-online.email"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>help@</u></font></font></font></a><a href="mailto:help@infocoin-online.email"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>i</u></font></font></a><a href="mailto:help@infocoin-online.email"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>nfocoi</u></font></font></font></a><a href="mailto:help@infocoin-online.email"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>n.</u></font></font></a><a href="mailto:help@infocoin-online.email"><font color="#000000"><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt"><u>online</u></font></font></font></a><font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">,
або заповнивши спеціальну форму
зворотного зв'язку на Сайті з будь-яким
таким зворотним зв'язком або питаннями.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><a name="_heading=h.30j0zll"></a>
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">12.9
Факт видалення вашого облікового запису
означає розірвання цих Умов, укладених
між Вами та нами, в односторонньому
позасудовому порядку.</font></font></p>
<p align="justify" style="line-height: 108%; margin-bottom: 0.11in"><br/>
<br/>

</p>
<p style="line-height: 100%; margin-top: 0.08in; margin-bottom: 0.08in">
<font face="Times New Roman, serif"><font size="3" style="font-size: 12pt">.</font></font></p>
</body>
</html>`;
