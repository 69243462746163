import React from "react";
import classes from "./PendingAction.module.scss";
import Logo from "@assets/icons/logo.png";
import { useTranslation } from "react-i18next";
import SyncIcon from '@mui/icons-material/Sync';

const PendingAction = ({text = "Pending of payment"}) => {
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <img src={Logo} alt="Infocoin" />
      </div>
      <div className={classes.text}>{t(text)}</div>
      <SyncIcon className={classes.pending}/>
    </div>
  );
};

export default PendingAction;
