import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { DoneIcon } from "src/views/icons";

import "./index.scss";

const Checkbox = props => {
  const {
    className = "",
    variant,
    error = false,
    helperText,
    label,
    size = "xl",
    checked,
    value,
    ...rest
  } = props;

  const { t } = useTranslation();

  const modifications = clsx({
    "checkbox--checked": checked,
    "checkbox--error": error,
    [`checkbox--variant-${variant}`]: !!variant,
  });

  return (
    <label className={`checkbox ${modifications} ${className}`}>
      <input
        {...rest}
        className="checkbox__input"
        type="checkbox"
        checked={checked}
        value={value}
      />

      <span className={`checkbox__box checkbox__box--${size}`}>
        <DoneIcon
          className="checkbox__icon"
          size={size === "xl" ? "medium" : "small"}
        />
      </span>

      {label && <span className="checkbox__label">{t(label)}</span>}
    </label>
  );
};

export { Checkbox };
