import React, { memo, useEffect, useMemo } from "react";
import Select, { components } from "react-select";

/* styles */
import "./index.scss";
import "./fullWidthOvverideStyles.scss";

import { getCountriesAction } from "@redux/language/actions";
import { connect } from "react-redux";
import { ArrowDropDown } from "@mui/icons-material";
import FlagCountry from "@components/FlagCountry";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Typography } from "../../../views/blocks";

const { Option, SingleValue, DropdownIndicator } = components;

const CustomOption = ({ data: { iso_2, name }, ...props }) => {
  return (
    <Option {...props} isSelected={props.selectProps?.value?.name === name}>
      <FlagCountry code={iso_2} />
      <Typography
        variant="body2"
        color={props.selectProps?.value?.name === name ? "white" : "black"}
      >
        {name}
      </Typography>
    </Option>
  );
};

const CustomSingleValue = ({
  data: { iso_2, name },
  withNameInValue,
  ...props
}) => {
  return (
    <SingleValue {...props}>
      <FlagCountry code={iso_2} />
      {withNameInValue && (
        <Typography variant="body2" color="black">
          {name}
        </Typography>
      )}
    </SingleValue>
  );
};

const CountrySelect = ({
  value,
  name,
  menuPlacement = "bottom",
  label,
  touched,
  error,
  onChange,
  onBlur,
  placeholder,
  warningLabel = false,
  countries,
  fullWidth = true,
  getCountriesAction,
  disabled,
  className,
  withNameInValue = false,
  littleLabel = false,
  isSearchable = true,
  containerClassName,
  isClearable,
}) => {
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      // eslint-disable-next-line
      countries
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
        })
        .map(option => ({
          ...option,
          label: option.label,
        })),
    [countries],
  );

  useEffect(() => {
    if (!countries.length) {
      getCountriesAction();
    } //eslint-disable-next-line
  }, []);
  return (
    <div className={containerClassName}>
      {label && (
        <div
          className={`countryLabel ${littleLabel && "little-label"} ${
            warningLabel ? "warning-label" : ""
          }`}
        >
          {t(label)}
        </div>
      )}
      <Select
        name={name}
        menuPlacement={menuPlacement}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: props => (
            <DropdownIndicator {...props}>
              <ArrowDropDown fill={"grey"} />
            </DropdownIndicator>
          ),
          Option: CustomOption,
          SingleValue: props => (
            <CustomSingleValue {...props} withNameInValue={withNameInValue} />
          ),
        }}
        isClearable={isClearable}
        isDisabled={disabled}
        options={options}
        filterOption={(candidate, input) => {
          return candidate.data.name
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        placeholder={placeholder ? placeholder : t("Select")}
        value={countries.find(country => country.name === value)}
        onBlur={e => {
          onBlur &&
            onBlur({
              ...e,
              target: {
                ...e.target,
                name,
              },
            });
        }}
        onChange={onChange}
        isSearchable={isSearchable}
        className={clsx("language-select", { fullWidth: fullWidth }, className)}
        classNamePrefix="language-option"
      />
      {touched && error && (
        <div
          className="ml-1"
          style={{
            color: "red",
            fontSize: "12px",
            marginLeft: "4px",
            marginTop: "3px",
            fontWeight: 500,
          }}
        >
          {t(error)}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ language }) => ({
  countries: language.countries,
});

export default connect(mapStateToProps, { getCountriesAction })(
  memo(CountrySelect),
);
