import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete as MuiAutocomplete,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import clsx from "clsx";

import { IconButton } from "src/views/blocks";
import { AngleDownIcon, CloseIcon } from "src/views/icons";

import { TextInput } from "../TextInput";

import "./index.scss";

const Autocomplete = props => {
  const {
    getIcon,
    options = [],
    variant,
    fullWidth = false,
    translate = false,
    error = false,
    withClear = true,
    readOnly = false,
    helperText,
    cursor,
    isMulti,
    containerClassName,
    warningLabel,
    label,
    placeholder,
    value = "",
    onChange,
    ...rest
  } = props;

  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const [selectValue, setSelectValue] = useState(value);
  const [inputValue, setInputValue] = useState("");
  const ref = useRef(null);

  const option = useMemo(() => {
    const option = options.find(option => {
      return option.value === selectValue;
    });

    if (option) {
      return option;
    }

    return null;
  }, [options, selectValue]);

  const modifications = clsx({
    "auto-select--open": isOpen,
    "auto-select--filled": !!option,
  });

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleToggle = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const handleChange = useCallback(
    (e, option) => {
      if (option) {
        setSelectValue(option.value);
      }

      if (onChange) {
        if (option) {
          onChange(e, option.value);
        }
      }
    },
    [onChange],
  );

  const handleClear = useCallback(
    e => {
      setInputValue("");
      setSelectValue("");

      if (onChange) {
        onChange(e, "");
      }
    },
    [onChange],
  );

  const handleInputChange = useCallback((e, inputValue) => {
    setInputValue(inputValue);
  }, []);

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  return (
    <MuiAutocomplete
      {...rest}
      ref={ref}
      className={`auto-select ${modifications} ${containerClassName}`}
      classes={{
        paper: "auto-select-paper",
      }}
      open={isOpen}
      getOptionLabel={option => `${option.label}`}
      renderOption={(props, option) => {
        const { label = "", secondary = "" } = option;

        return (
          <ListItem {...props}>
            {getIcon && <ListItemAvatar>{getIcon(option)}</ListItemAvatar>}

            <ListItemText
              primary={translate ? t(label) : label}
              secondary={translate ? t(secondary) : secondary}
            />
          </ListItem>
        );
      }}
      renderInput={props => {
        const {
          InputProps: { ref },
          inputProps: { ref: inputRef, ...inputProps },
        } = props;

        return (
          <TextInput
            {...inputProps}
            warningLabel={warningLabel}
            ref={ref}
            style={{
              cursor: cursor && cursor
            }}
            inputRef={inputRef}
            readOnly={readOnly}
            leftAddons={
              <React.Fragment>{getIcon && getIcon(option)}</React.Fragment>
            }
            rightAddons={
              <React.Fragment>
                {withClear && (
                  <IconButton
                    className="auto-select__clear"
                    type="button"
                    icon={CloseIcon}
                    size="small"
                    onClick={handleClear}
                  />
                )}

                <IconButton
                  className="auto-select__angle"
                  type="button"
                  icon={AngleDownIcon}
                  size="small"
                  onClick={handleToggle}
                />
              </React.Fragment>
            }
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            fullWidth={fullWidth}
            variant={variant}
            label={label}
          />
        );
      }}
      options={options}
      inputValue={inputValue}
      value={option}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handleChange}
      onInputChange={handleInputChange}
    />
  );
};

export { Autocomplete };
