import { shallowEqual, useSelector } from "react-redux";
import { useMemo } from "react";

export function useData(isShowSeparator) {
  const auctionLots = useSelector(state => state.auction.lots, shallowEqual);
  const userId = useSelector(state => state.user.user.id, shallowEqual);
  const category = useSelector(
    state => state.auction.selectedCategory,
    shallowEqual,
  );

  return useMemo(() => {
    let newData = [...auctionLots];

    if (auctionLots) {
      const userLots = auctionLots.filter(val => val.bidder_id === userId);

      const sortedAll = auctionLots.sort(
        (a, b) => parseFloat(a.playlist_number) - parseFloat(b.playlist_number),
      );

      if (userLots.length >= 1) {
        newData = [];
        userLots.forEach(lot => {
          const userLotIndex = sortedAll.findIndex(v => v.id === lot.id);
          newData.push(lot);
          if (
            sortedAll[userLotIndex + 1] &&
            sortedAll[userLotIndex + 1].bidder_id !== userId &&
            newData.findIndex(v => v.id === sortedAll[userLotIndex + 1].id) ===
              -1
          )
            newData.push(sortedAll[userLotIndex + 1]);
          if (
            sortedAll[userLotIndex + 2] &&
            sortedAll[userLotIndex + 2].bidder_id !== userId &&
            newData.findIndex(v => v.id === sortedAll[userLotIndex + 2].id) ===
              -1
          )
            newData.push(sortedAll[userLotIndex + 2]);
          if (
            sortedAll[userLotIndex - 2] &&
            sortedAll[userLotIndex - 2].bidder_id !== userId &&
            newData.findIndex(v => v.id === sortedAll[userLotIndex - 2].id) ===
              -1
          )
            newData.push(sortedAll[userLotIndex - 2]);
          if (
            sortedAll[userLotIndex - 1] &&
            sortedAll[userLotIndex - 1].bidder_id !== userId &&
            newData.findIndex(v => v.id === sortedAll[userLotIndex - 1].id) ===
              -1
          )
            newData.push(sortedAll[userLotIndex - 1]);
        });
        return newData.sort(
          (a, b) =>
            parseFloat(a.playlist_number) - parseFloat(b.playlist_number),
        );
      } else {
        if (isShowSeparator && sortedAll.length > 10) {
          newData = [
            ...sortedAll.slice(0, 5),
            { isSeparator: true, category_id: 0 },
            ...sortedAll.slice(sortedAll.length - 5),
          ];
        } else newData = auctionLots;
      }
    }

    if (newData) {
      return newData.filter(item => {
        if (!category) {
          return item.category_id === 0;
        }
        return item.video.category.id === category.id;
      });
    }
    return [];
  }, [auctionLots, category, isShowSeparator, userId]);
}
