import { memo } from "react";
import { Grid } from "@mui/material";

import { withTranslation } from "react-i18next";

import SupportSchema from "@helpers/Formik/validation";
import { TextInput } from "../../../../views/inputs";

const AskQuestions = ({ t }) => {
  return (
    <Grid>
      <TextInput />
    </Grid>
  );
};

export default withTranslation()(memo(AskQuestions));
