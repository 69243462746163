export const SET_VIDEOS_LIKES = "SET_VIDEOS_LIKES";
export const SET_VIDEOS_VIEWS = "SET_VIDEOS_VIEWS";
export const SET_VIDEOS_CTR = "SET_VIDEOS_CTR";
export const SET_VIDEOS_INTERACTIONS = "SET_VIDEOS_INTERACTIONS";
export const SET_VIDEOS_COMPLAINTS = "SET_VIDEOS_COMPLAINTS";
export const SET_DASHBOARD_STATISTIC = "SET_DASHBOARD_STATISTIC";
export const SET_PLAYLIST_STATISTIC = "SET_PLAYLIST_STATISTIC";
export const SET_PLAYLIST_SELECTED = "SET_PLAYLIST_SELECTED";
export const SET_PAST_SELECTED = "SET_PAST_SELECTED";
export const SET_PAST_STATISTIC = "SET_PAST_STATISTIC";

