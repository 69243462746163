import { AUTH_LOGOUT } from "@redux/auth/constants";
import {
  SET_DASHBOARD_STATISTIC,
  SET_PAST_SELECTED,
  SET_PAST_STATISTIC,
  SET_PLAYLIST_SELECTED,
  SET_PLAYLIST_STATISTIC,
} from "@redux/dashboard/constants";

const initialState = {
  interactions: {},
  complaints: 0,
  ctr: 0,
  views: 0,
  likes: 0,
  loading: false,
  playlist: [],
  past: [],
  playlistSelectedLot: {},
  pastSelectedLot: {},
};

export default function dashboardReducer(
  state = initialState,
  { type, payload },
) {
  switch (type) {
    case SET_DASHBOARD_STATISTIC:
      return {
        ...state,
        ...payload,
      };
    case SET_PLAYLIST_STATISTIC:
      return {
        ...state,
        playlist: payload.lots || [],
        playlistCount: payload.lots_count,
      };
    case SET_PLAYLIST_SELECTED:
      return {
        ...state,
        playlistSelectedLot: payload,
      };
    case SET_PAST_SELECTED:
      return {
        ...state,
        pastSelectedLot: payload,
      };
    case SET_PAST_STATISTIC:
      return {
        ...state,
        past: payload.lots || [],
        pastCount: payload.lots_count,
      };

    case AUTH_LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
}
