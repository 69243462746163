import { axiosInstance } from "src/helpers/http/httpInstance";

export const getCities = async id => {
  return await axiosInstance.get(`/auth/cities/${id}`).then(res => res.data);
};

export const getRegions = async id => {
  return await axiosInstance.get(`/auth/regions/${id}`).then(res => res.data);
};

export const getCitiesData = id => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = axiosInstance.get(`/auth/cities/${id}`);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};
