import React, { memo } from "react";
import clsx from "clsx";
import classes from "./Button.module.scss";
import Loader from "@components/Loader";

const Button = ({
  onClick,
  disabled,
  className,
  variant = "primary",
  containerClassName,
  children,
  loading,
  size = "large",
  ...props
}) => {
  return (
    <div className={clsx(containerClassName, classes.container)}>
      <button
        onClick={onClick}
        disabled={disabled}
        className={clsx(
          classes.button,
          className,
          {
            [classes.disabled]: disabled,
          },
          classes[`variant-${variant}`],
          classes[`size-${size}`],
        )}
        {...props}
      >
        {loading ? <Loader color="white" width={20} height={20} /> : children}
      </button>
    </div>
  );
};

export default memo(Button);
