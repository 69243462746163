import React from "react";
import { styled } from "@mui/material/styles";
import MuiCheckbox from "@mui/material/Checkbox";
import { Done } from "@mui/icons-material";
import { FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";

const BpIcon = styled("span")({
  borderRadius: 3,
  width: 20,
  height: 20,
  backgroundColor: "#6C6C6C",
  backgroundImage: "#6C6C6C",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "#6C6C6C",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "#6C6C6C",
  },
});

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#FF5F15",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "input:hover ~ &": {
    backgroundColor: "#FF5F15",
  },
});

const Checkbox = ({
  name,
  value,
  onChange,
  disabled,
  label,
  labelStyle,
  style,
  checkedStyle,
  iconProps,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      disabled={disabled}
      checked={value}
      name={name}
      value={value}
      onChange={onChange}
      {...props}
      control={
        <MuiCheckbox
          disableRipple
          inputProps={{ "aria-label": "controlled" }}
          color="default"
          checkedIcon={
            <BpCheckedIcon style={checkedStyle}>
              <Done
                style={{ color: "white", width: 16, heigth: 16 }}
                {...iconProps}
              />
            </BpCheckedIcon>
          }
          icon={<BpIcon style={style} />}
        />
      }
      label={
        label ? (
          <div
            style={{
              marginLeft: "4px",
              color: "#6C6C6C",
              userSelect: "none",
              ...labelStyle,
            }}
          >
            {typeof label === "string" ? t(label) : label}
          </div>
        ) : (
          <div />
        )
      }
    />
  );
};

export default Checkbox;
