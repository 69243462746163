export const API_URL = process.env.REACT_APP_API;
export const WS_URL = process.env.REACT_APP_WS_API;
export const LIQ_PAY_PUBLIC = process.env.REACT_APP_LIQ_PAY_PUBLIC;
export const LIQ_PAY_PRIVATE = process.env.REACT_APP_LIQ_PAY_PRIVATE;
export const LOCIZE_PROJECT_ID = process.env.REACT_APP_LOCIZE_PROJECT_ID;
export const LOCIZE_API_KEY = process.env.REACT_APP_LOCIZE_API_KEY;
export const IS_PRODUCTION = process.env.REACT_APP_IS_PRODUCTION === "true";
export const MERCHANT_PASS = process.env.REACT_APP_MERCHANT_PASS ;
export const MERCHANT_ID = process.env.REACT_APP_MERCHANT_ID;
export const PAYPAL_ID = process.env.REACT_APP_PAYPAL_ID ;
export const PAYPAL_SECRET = process.env.REACT_APP_PAYPAL_SECRET;
export const PAYPAL_TOKEN_URL = process.env.REACT_APP_TOKEN_PAYPAL_URL;
export const STRIPE_KEY = process.env.REACT_APP_STRIPE;
export const COREFY_KEY = process.env.REACT_APP_COREFY;
export const COREFY_API = process.env.REACT_APP_COREFY_API;
